import Vue from 'vue';
import api from '@/http/api';
import { format, formatISO, subDays, startOfDay, endOfDay, startOfMinute } from 'date-fns';
import { HoleDrillPileStatus } from '@/domain/hole/HoleDrillPileStatus.enum';
import { getStabilizationFields } from '@/utils/pointFieldLicenceHelper';
import FilterDrillHoleViewModel from './filterDrillHoleViewModel';
import { metadataFilterStoreMixin } from '@/store/filterModule/metadataFilter.store.mixin';
import { metadataFilterFactory } from '@/store/filterModule/metadataFilterFactory';
import { createFilter } from '@/domain/filters/filterModelFactory.ts';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const filtersToExcludeFromMetadata = ['unit', 'unit_account_name', 'unit_type', 'reference_model', 'date', 'time', 'drillPileStatus', 'APLNoSync'];

const filterDrillHoleModule = {
  state: {
    ...metadataFilterStoreMixin.state,
    isLoading: false,
    isExportLoading: false,
    // todo: this is hardcoded end date to take
    // all the mock points for the mock model
    // change in the future
    endDate: null,
    startDate: null,
    selectedRefModel: null,
    selectedUnitTypes: [],
    selectedDrillHoleStatuses: Object.values(HoleDrillPileStatus).map((s) => {
      return {
        label: s,
        type: s,
        isSelected: false
      };
    }),
    // list of unit uuids selected by the implicit unit name filter
    selectedUnitUuids: [],
    queryOngoing: false,
    resultDrillHoleSet: [],
    metadataFields: [],
  },
  getters: {
    ...metadataFilterStoreMixin.getters,
    filters(state, getters) {
      const filters = [];
      const selectedDPStatuses = state.selectedDrillHoleStatuses.filter(s => s.isSelected);
      if (state.selectedUnitTypes.length) filters.push(createFilter(FilterModelNames.UnitType, state.selectedUnitTypes));
      if (state.selectedUnitUuids.length) filters.push(createFilter(FilterModelNames.UnitUuid, state.selectedUnitUuids));
      if (state.selectedRefModel) filters.push(createFilter(FilterModelNames.RefmodelUuid, [state.selectedRefModel.value]));
      if (selectedDPStatuses.length) filters.push(createFilter(FilterModelNames.DrillPileStatus, selectedDPStatuses.map(s => s.type)));
      return [...filters, ...getters.addedMetadataFilters.map(f => f.getModel())];
    },
    isLoading(state) {
      return state.isLoading;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    selectedRefModel(state) {
      return state.selectedRefModel;
    },
    selectedUnitTypes(state) {
      return state.selectedUnitTypes;
    },
    selectedDrillHoleStatuses(state) {
      return state.selectedDrillHoleStatuses.map(s => {
        return {
          // FIXME: this will not be extracted correctly - gettext cannot deal with dynamic strings
          label: $gettext(s.label),
          type: s.type,
          isSelected: s.isSelected
        };
      });
    },
    selectedUnitNames(state, getters, rootState, rootGetters) {
      const units = rootGetters['app/allUnits'];
      return units.filter(u => state.selectedUnitUuids.includes(u.uuid));
    },
    availableUnitNames(state, getters, globalState, globalGetters) {
      const units = globalGetters['app/allUnits'];
      return units.filter((u) => {
        if (state.selectedUnitTypes.length === 0 || state.selectedUnitTypes[0] === '') return true;
        return state.selectedUnitTypes.includes(u.entityType);
      });
    },
    queryOngoing(state) {
      return state.queryOngoing;
    },
    resultDrillHoleSet(state) {
      return state.resultDrillHoleSet;
    },
    isExportLoading(state) {
      return state.isExportLoading;
    }
  },
  mutations: {
    ...metadataFilterStoreMixin.mutations,
    changeQueryOngoing(state, payload) {
      state.queryOngoing = payload;
    },
    changeResultDrillHoleSet(state, drillHoleSet) {
      state.resultDrillHoleSet = drillHoleSet;
    },
    changeSelectedRefModel(state, refModel) {
      if (refModel) {
        state.selectedRefModel = refModel;
        state.selectedRefModel.value = refModel.uuid;
      } else {
        state.selectedRefModel = null;
      }
    },
    changeSelectedUnitTypes(state, types) {
      state.selectedUnitTypes = types;
    },
    changeSelectedDrillHoleStatuses(state, statuses) {
      state.selectedDrillHoleStatuses = statuses;
    },
    changeSelectedDrillHoleStatus(state, { index, value }) {
      state.selectedDrillHoleStatuses[index].isSelected = value;
    },
    changeUnitNames(state, value) {
      state.selectedUnitUuids = value;
    },
    deleteMetadataFilters(state, payload) {
      state.addedMetadataFilters.splice(payload.index, 1);
    },
    changeFilterFromValue(state, payload) {
      const filter = state.addedMetadataFilters[payload.index];
      filter.changeFilterValue(payload.value);
    },
    changeFilterToValue(state, payload) {
      const filter = state.addedMetadataFilters[payload.index];
      filter.changeFilterValue(null, payload.value);
    },
    changeFilterComparator(state, payload) {
      const filter = state.addedMetadataFilters[payload.index];
      filter.changeFilterComparator(payload.comparator);
    },
    changeEnumFilterValues(state, payload) {
      const filter = state.addedMetadataFilters[payload.index];
      filter.changeFilterValues(payload.values);
      filter.addFilterComparatorsForEachValue(payload.values.length, 'equals');
    },
    clearFilters(state) {
      state.selectedRefModel = null;
      state.selectedUnitTypes = [];
      state.selectedUnitUuids = [];
      state.selectedDrillHoleStatuses = state.selectedDrillHoleStatuses.map((i) => {
        return {
          ...i,
          isSelected: false
        };
      });
      state.addedMetadataFilters = [];
      state.startDate = startOfDay(subDays(new Date(Date.now()), 1));
      state.endDate = endOfDay(new Date(Date.now()));
      state.resultDrillHoleSet = [];
    },
    changeStartDate(state, date) {
      state.startDate = date;
    },
    changeEndDate(state, date) {
      state.endDate = date;
    },
    setIsExportLoading(state, isExportLoading) {
      state.isExportLoading = isExportLoading;
    },
    setStartDate(state, date) {
      state.startDate = date;
    },
    setEndDate(state, date) {
      state.endDate = date;
    }
  },
  actions: {
    ...metadataFilterStoreMixin.actions,
    async initializeFilters(context, fields) {
      context.dispatch('initializeMetadataFilters', { fields, excludedFilters: filtersToExcludeFromMetadata });
      context.commit('setStartDate', startOfDay(subDays(new Date(Date.now()), 1)));
      context.commit('setEndDate', startOfMinute(endOfDay(new Date(Date.now()))));
    },
    addMetadataFilter(context, data) {
      let addedFilter;
      const { fieldName, displayName, dataType } = data.filter;
      if (data.isRange) addedFilter = metadataFilterFactory.createRangeFilter(fieldName, displayName, dataType);
      else addedFilter = metadataFilterFactory.createSingleFilter(fieldName, displayName, dataType);
      context.commit('addMetadataFilter', addedFilter);
    },
    updateSelectedUnitTypes(context, types) {
      if (types.length > 0) {
        const unitNames = context.state.selectedUnitUuids.filter((u) => {
          const unit = context.rootGetters['app/allUnits'].find(i => i.uuid === u);
          return (types.includes(unit.entityType));
        });
        context.commit('changeUnitNames', unitNames);
      }
      context.commit('changeSelectedUnitTypes', types);
    },
    clearSelectedDrillHoleStatuses(context) {
      const selectedDrillHoleStatuses = context.state.selectedDrillHoleStatuses.map((i) => {
        return {
          ...i,
          isSelected: false
        };
      });
      context.commit('changeSelectedDrillHoleStatuses', selectedDrillHoleStatuses);
    },
    async filterDrillHoles(context) {
      context.dispatch('applyDrillHoleSet', { drillHoleSet: {} });
    },
    async applyDrillHoleSet(context) {
      try {
        context.commit('changeQueryOngoing', true);
        const holeSet = await api.filterHoles(
          context.rootGetters.projectUuid,
          formatISO(context.getters.startDate),
          formatISO(context.getters.endDate),
          context.getters.filters,
          true,
          getStabilizationFields()
        );
        if (holeSet.count > 0) {
          const language = gettext.current;
          const resultDrillHoleSet = new FilterDrillHoleViewModel(holeSet, language);
          context.commit('changeResultDrillHoleSet', [resultDrillHoleSet]);
          await context.dispatch('map/addEntities', { entities: [resultDrillHoleSet.data] }, { root: true });
        } else {
          context.commit('changeResultDrillHoleSet', []);
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Selected hole set cannot be rendered.') }, { root: true });
      } finally {
        context.commit('changeQueryOngoing', false);
      }
    },
    async exportPoints(context, extension) {
      try {
        const { projectUuid } = context.rootGetters;
        const [appliedHoleSet] = context.getters.resultDrillHoleSet;
        const { originTimeRange, originFilters } = appliedHoleSet.data;
        const fileName = `ConX_Drill_Asbuilts_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        context.commit('setIsExportLoading', true);
        await api.exportHoles(
          projectUuid,
          formatISO(originTimeRange.from),
          formatISO(originTimeRange.to),
          originFilters,
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExportLoading', false);
      }
    }
  },
};

export default filterDrillHoleModule;