<template>
  <div class="cx-performance-monitor" @click="showDetails = !showDetails">
    <cx-point-performance-monitor class="cx-performance-monitor__points" v-show="showDetails"/>
    <cx-frame-rate-monitor v-show="showDetails" class="cx-performance-monitor__frame-rate"/>
    <div :class="{'cx-performance-monitor--smooth': isSmooth, 'cx-performance-monitor--choppy': !isSmooth}">
      <cx-spinner
        v-if="isLoadingTiles"
        class="cx-performance-monitor__tile-loading-spinner"
        :size="8" :width="1" :indeterminate="true"/>
      <i v-show="!isLoadingTiles" class="material-icons cx-performance-monitor__icon">brightness_1</i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CxFrameRateMonitor from '@/app/notifications/CxFrameRateMonitor';
import CxPointPerformanceMonitor from '@/app/notifications/CxPointPerformanceMonitor';
import CxSpinner from '@/components/CxSpinner.vue';

export default {
  name: 'CxPerformanceMonitor',
  components: {
    CxFrameRateMonitor,
    CxPointPerformanceMonitor,
    CxSpinner
  },
  data() {
    return {
      showDetails: true,
      isHovered: false,
    };
  },
  computed: {
    ...mapGetters('map', ['lastFramesPerSecond', 'arePointsPerformant', 'isLoadingTiles']),
    isSmooth() {
      return !this.lastFramesPerSecond.warn && this.arePointsPerformant;
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-performance-monitor {
  .flex(row, flex-end, center);
  cursor: pointer;
  font-size: @appFontSize;
  margin: 1px 10px 0 25px;

  &__tile-loading-spinner {
    margin-bottom: 1px;
  }

  &__icon {
    font-size: 8px;
    text-align: center;
  }

  &--smooth &__icon {
    color: @smoothPerformanceColor;
  }

  &--choppy &__icon {
    color: @warningColor;
  }

  .cx-performance-monitor__points {
    width: 90px;
  }
  .cx-performance-monitor__frame-rate {
    width: 40px;
  }
}
</style>
