import { v4 as uuidv4 } from 'uuid';
import { isNil, uniqBy } from 'lodash';
import { formatISO } from 'date-fns';
import api from '@/http/api';
import { getStabilizationFields } from '@/utils/pointFieldLicenceHelper';
import { PointPerformanceLimitExceeded } from '@/store/mapModule/map.points.errors';
import { fetchPointDataStoreMixin } from '@/store/detailsModule/fetchPointData.store.mixin';
import { createFilter } from '@/domain/filters/filterModelFactory';
import { createPointSet } from '@/domain/pointSet/PointSetFactory';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const getFilteringParams = (selectedDrillPattern, projectStartTime) => {
  const referenceModelFilter = createFilter(FilterModelNames.RefmodelUuid, [selectedDrillPattern.uuid]);
  return {
    filters: [referenceModelFilter],
    startTime: projectStartTime,
    endTime: new Date(Date.now())
  };
};

const fetchDrillHoles = async (projectUuid, filteringParams) => {
  const holeSet = await api.filterHoles(
    projectUuid,
    formatISO(filteringParams.startTime),
    formatISO(filteringParams.endTime),
    filteringParams.filters,
    true,
    getStabilizationFields()
  );
  if (!holeSet || !holeSet.count) return null;
  return holeSet;
};

const getPointSetFromHoleSet = (holeSet) => {
  if (!holeSet.drillHoles) return null;
  const holePoints = uniqBy(holeSet.drillHoles.flatMap(h => h.pointSet.points), 'uuid');
  if (!holePoints.length) return null;

  const pointSet = createPointSet({
    uuid: uuidv4(),
    points: [...holePoints],
    totalCount: holePoints.length,
    originFilters: holeSet.originFilters,
    originTimeRange: holeSet.originTimeRange
  });
  return pointSet;
};

const detailsDrillPatternModule = {
  state: {
    isExporting: false,
    asBuiltCount: 0,
    ...fetchPointDataStoreMixin.state
  },
  getters: {
    isDrillPatternLabelsVisible(state, getters, rootState, rootGetters) {
      if (!getters.selectedDrillPattern) return false;
      return rootGetters['map/isDrillPatternLabelsActive'](getters.selectedDrillPattern.uuid);
    },
    selectedDrillPattern(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (selectedEntity && selectedEntity.entityType === RefmodelTypes.DrillPattern) {
        return selectedEntity;
      }
      return null;
    },
    holeSet(state, getters, rootState, rootGetters) {
      if (!getters.selectedDrillPattern) return null;
      const holeSets = rootGetters['map/entityChildren'](getters.selectedDrillPattern.uuid, DrillHoleSetType);
      if (!holeSets.length) return null;
      if (holeSets.length > 1) { throw new Error('More than one hole set detected'); }
      return holeSets[0];
    },
    pointSet(state, getters, rootState, rootGetters) {
      if (!getters.selectedDrillPattern) return null;
      const pointSets = rootGetters['map/entityChildren'](getters.selectedDrillPattern.uuid, PointSetType);
      if (!pointSets.length) return null;
      if (pointSets.length > 1) { throw new Error('More than one point set detected'); }
      return { ...pointSets[0] };
    },
    filteringParams(state, getters, rootState, rootGetters) {
      const projectStartTime = rootGetters['app/projectStartDate'];
      return getFilteringParams(getters.selectedDrillPattern, projectStartTime);
    },
    holesVisible(state, getters, rootState, rootGetters) {
      return !!getters.holeSet && !rootGetters['map/hiddenHoleSets'].includes(getters.holeSet.uuid);
    },
    showingHoles2D(state, getters, rootState, rootGetters) {
      if (!getters.holeSet) return false;
      const setVisibility = rootGetters['map/entityVisibility'](getters.holeSet.uuid);
      if (!setVisibility || !setVisibility.visibilityMode) return null;
      return setVisibility.visibilityMode['2D'];
    },
    showingHoles3D(state, getters, rootState, rootGetters) {
      if (!getters.holeSet) return false;
      const setVisibility = rootGetters['map/entityVisibility'](getters.holeSet.uuid);
      if (!setVisibility || !setVisibility.visibilityMode) return null;
      return setVisibility.visibilityMode['3D'];
    },
    showingAsBuilts(state, getters, rootState, rootGetters) {
      if (!getters.pointSet) return false;
      const setVisibility = rootGetters['map/entityVisibility'](getters.pointSet.uuid);
      if (!setVisibility || !setVisibility.visibilityMode) return null;
      return setVisibility.visibilityMode.default;
    },
    drillPatternVisible(state, getters, rootState) {
      if (getters.selectedDrillPattern) return !rootState.map.hiddenRefmodels.includes(getters.selectedDrillPattern.uuid);
      return false;
    },
    holeIds(state, getters) {
      return getters.holeSet.drillHoles.map(h => h.uuid);
    },
    isExporting(state) {
      return state.isExporting;
    },
    asBuiltCount(state) {
      return state.asBuiltCount;
    },
    ...fetchPointDataStoreMixin.getters
  },
  mutations: {
    setIsExporting(state, isExporting) {
      state.isExporting = isExporting;
    },
    setAsBuiltCount(state, count) {
      state.asBuiltCount = count;
    },
    ...fetchPointDataStoreMixin.mutations
  },
  actions: {
    ...fetchPointDataStoreMixin.actions,
    async loadHoles(context, { showOnMap }) {
      let result;
      const { projectUuid } = context.rootGetters;
      const params = context.getters.filteringParams;
      const holeSet = await fetchDrillHoles(projectUuid, params);
      // warning - no holes to display for this drill pattern
      if (holeSet) {
        context.dispatch('map/addEntities', {
          entities: [holeSet],
          parentUid: context.getters.selectedDrillPattern.uuid,
          options: { showOnMap },
        }, { root: true });
      }
      return result;
    },
    async removeHoles(context) {
      if (context.getters.holeSet) { context.dispatch('map/removeEntityFamily', { uuids: [context.getters.holeSet.uuid] }, { root: true }); }
    },
    /**
     * Toggles visibility of hole set
     * Will load hole set if there is none
     * While switching off 2D view, we switch off also 3D view
     * 3D view is not restored after 2D view is switched on
     */
    async toggleHoles2DVisibility(context) {
      const set2DVisibility = (show2D, show3D) => {
        const show3Dcomputed = isNil(show3D) ? context.getters.showingHoles3D : show3D;
        context.dispatch('map/setEntityVisibility', {
          uuid: context.getters.holeSet.uuid,
          isVisible: true,
          options: { '2D': show2D, '3D': show3Dcomputed }
        }, { root: true });
      };
      if (context.getters.showingHoles2D) {
        set2DVisibility(false, false);
      } else if (!context.getters.holeSet) await context.dispatch('loadHoles', { showOnMap: { '2D': true } });
      else set2DVisibility(true);
    },
    /**
     * Toggles visibility of holes in 3D
     * available only if 2D view is already switched on
     */
    async toggleHoles3DVisibility(context) {
      await context.dispatch('map/setEntityVisibility', {
        uuid: context.getters.holeSet.uuid,
        isVisible: true,
        options: { '3D': !context.getters.showingHoles3D, '2D': context.getters.showingHoles2D }
      }, { root: true });
    },
    async toggleDrillPatternVisibility(context) {
      const isVisible = !context.getters.drillPatternVisible;
      await context.dispatch('map/setEntityVisibility', { uuid: context.getters.selectedDrillPattern.uuid, isVisible }, { root: true });
    },
    async toggleDrillPatternLabelsVisibility(context) {
      if (!context.getters.selectedDrillPattern) return;
      await context.dispatch('map/toggleDrillPatternLabels', { uuid: context.getters.selectedDrillPattern.uuid }, { root: true });
    },
    /**
     * Toggles visibility of measured points
     * Will load point set if there is none
     */
    async toggleAsBuiltVisibility(context) {
      const isVisible = !context.getters.showingAsBuilts;
      const loadedPointSet = await context.dispatch('getLoadedPointSet');
      if (loadedPointSet) {
        await context.dispatch('map/setEntityVisibility', { uuid: loadedPointSet.uuid, isVisible }, { root: true });
        await context.dispatch('map/colorPointSets', { sets: [loadedPointSet.uuid], useColor: isVisible }, { root: true });
      }
    },
    /**
     * Checks if there is a point set loaded to the map.
     * If there is one, this point set is returned.
     * If not, a new point set is asynchronously loaded to the map and then returned.
     * @param {*} context
     * @return {Object<PointSet>} existing or newly loaded point set.
     */
    async getLoadedPointSet(context) {
      const existingPointSet = context.getters.pointSet;
      if (existingPointSet) return existingPointSet;
      const newPointSet = await context.dispatch('loadPoints', { showOnMap: true })
        .catch(e => context.dispatch('notifications/error', { exception: e, message: $gettext('Could not load as builts for drill pattern.') }), { root: true });
      return newPointSet;
    },
    async loadPoints(context) {
      const { projectUuid } = context.rootGetters;
      const { filteringParams, selectedDrillPattern } = context.getters;
      let { holeSet } = context.state;
      if (!holeSet) holeSet = await fetchDrillHoles(projectUuid, filteringParams);
      const pointSet = getPointSetFromHoleSet(holeSet);
      if (pointSet && pointSet.count) {
        await context.dispatch('map/addEntities', {
          entities: [pointSet],
          parentUid: selectedDrillPattern.uuid,
          options: { useContextColor: true }
        }, { root: true })
          .catch((reason) => {
            if (reason instanceof PointPerformanceLimitExceeded) context.commit('setAsBuiltCount', holeSet.pointSet.count);
          });
        await context.dispatch('map/colorPointSets', { sets: [pointSet.uuid], useColor: true }, { root: true });
      } else {
        throw new Error(`There are no as builts for drill pattern ${selectedDrillPattern.name} (uuid: ${selectedDrillPattern.uuid})`);
      }
    },
    async removePoints(context) {
      if (context.getters.pointSet) { context.dispatch('map/removeEntityFamily', { uuids: [context.getters.pointSet.uuid] }, { root: true }); }
    },
    async handleCameraFlyTo(context) {
      await context.dispatch('map/setCameraToEntity', context.getters.selectedDrillPattern.uuid, { root: true });
    },
    async exportPoints(context, extension) {
      try {
        context.commit('setIsExporting', true);
        const { selectedDrillPattern } = context.getters;
        const referenceModelFilter = createFilter(FilterModelNames.RefmodelUuid, [selectedDrillPattern.uuid]);
        const projectStartTime = context.rootGetters['app/projectStartDate'];
        const now = new Date(Date.now());
        const fileName = `ConX_${selectedDrillPattern.name.replace(/,/g, '_')}_${formatISO(now)}.${extension}`;
        await api.exportMeasuredPoints(
          context.rootState.parentContext.project_uuid,
          formatISO(projectStartTime),
          formatISO(now),
          [referenceModelFilter],
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExporting', false);
      }
    },

    async showPointsData(context) {
      const { selectedDrillPattern } = context.getters;
      context.dispatch('showGridData', selectedDrillPattern);
    }
  }
};


export default detailsDrillPatternModule;