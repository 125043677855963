import { Histograms } from '@/domain/Histograms';

interface HistogramsResponseData {
  measured_points_fields: {
    count: number,
    histograms: Record<string, { type: string, display_name: string }>
  }
}

const mapHistogramFields = (histograms: HistogramsResponseData, groupName: string): Histograms | [] => {
  if (!histograms || !histograms[groupName]) return [];
  const histogramFields = {};
  const histogramList = histograms[groupName].histograms;
  Object.keys(histogramList).forEach((key) => {
    if (histogramList[key].display_name === null || histogramList[key].display_name === undefined) {
      histogramList[key].display_name = key;
    }
    const keyName = key.charAt(0).toLowerCase() + key.slice(1);
    histogramFields[keyName] = {
      fieldName: key,
      label: histogramList[key].display_name,
      type: histogramList[key].type
    };
  });
  return histogramFields;
};


const mapHistograms = (histograms: HistogramsResponseData): {pointFields: Histograms | [], stabilizationFields: Histograms | []} => {
  const pointFields = mapHistogramFields(histograms, 'measured_points_fields');
  const stabilizationFields = mapHistogramFields(histograms, 'holes_fields');
  return { pointFields, stabilizationFields };
};
const histogramMapper = {
  mapHistograms
};
export default histogramMapper;