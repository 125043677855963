
const detailsRefmodelShellModule = {
  state: {
    // represents state of Cut & Fill / Progress Analysis switcher for chart
    chartContent: 'CxCumulativeChart',
    // represents state of Cut & Fill / Progress Analysis switcher for metrics
    metricsContent: 'CxCumulativeMetrics',
    // represents state of Cut & Fill / Progress Analysis switcher for overlay controls
    analysisContent: 'cutfill', // [ 'cutfill' / 'progress' ]
    // the percantage to dsplay on a spinner
    spinnerValue: 0
  },
  getters: {
    showSpinner(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      return rootGetters['cancelable/isLoading']({ actionType: 'detailsRefmodel/setData', sourceUid: getters.selectedRefModel.uuid });
    },
    spinnerValue(state) {
      return state.spinnerValue;
    },
    /**
     * No data banner is shown when there is no points for the given refmodel
     */
    showChartEmptyError(state, getters) {
      const { volumes } = state;
      return volumes && volumes.every(v => v.isEmpty) && !getters.showSpinner;
    },
    /**
     * Displayed when all time intervals have error
     */
    showChartCalculationError(state, getters) {
      const { volumes } = state;
      return volumes && volumes.every(v => v.isErrorous) && !getters.showSpinner && !getters.showNoData;
    },
    /**
     * Displayed when all time intervals have cut and fill values === 0
     */
    showChartPlotError(state, getters) {
      const { volumes } = state;
      return volumes && volumes.every(v => !v.isPlottable) && !getters.showSpinner && !getters.showNoData && !getters.showChartError;
    },

    chartContent(state) {
      return state.chartContent;
    },
    metricsContent(state) {
      return state.metricsContent;
    },
    analysisContent(state) {
      return state.analysisContent;
    },
    initialSurfaceEnabled(state, getters) {
      return !getters.showNoData;
    }
  },
  mutations: {
    setChartContent(state, payload) {
      state.chartContent = payload;
    },
    setMetricsContent(state, payload) {
      state.metricsContent = payload;
    },
    setAnalysisContent(state, payload) {
      state.analysisContent = payload;
    },
    setSpinnerValue(state, value) {
      state.spinnerValue = value;
    }
  },
  actions: {
    async switchCutFillProgressDetails(context, details) {
      if (context.getters.selectedRefModel) {
        const { uuid } = context.getters.selectedRefModel;
        await context.dispatch('cancelable/cancelActions', { sourceUid: uuid }, { root: true });
      }
      if (details === 'cutfill') {
        context.commit('setChartContent', 'CxCumulativeChart');
        context.commit('setMetricsContent', 'CxCumulativeMetrics');
        context.commit('setAnalysisContent', details);
        if (context.getters.isCutFillActive) context.dispatch('detailsRefmodel/changeCutFillActive', { isActive: false });
        if (context.getters.isSurfaceActive) context.dispatch('detailsRefmodel/changeSurfaceActive', { isActive: false });
      } else if (details === 'progress') {
        context.commit('setChartContent', 'CxPointInTimeChart');
        context.commit('setMetricsContent', 'CxPointInTimeMetrics');
        context.commit('setAnalysisContent', details);
        if (context.getters.isProgressSurfaceActive) context.dispatch('changeProgressSurfaceActive', { isActive: false });
      }
    },
  }
};

export default detailsRefmodelShellModule;