import { format } from 'date-fns';
import _ from 'lodash';
import datetimehelper from '@/utils/datetimehelper';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const getCutTotal = (initialVolumes) => {
  if (!initialVolumes || _.isNil(initialVolumes.cutVolume) || initialVolumes.error) return null;
  return _.round(initialVolumes.cutVolume);
};

const getFillTotal = (initialVolumes) => {
  if (!initialVolumes || _.isNil(initialVolumes.fillVolume) || initialVolumes.error) return null;
  return _.round(initialVolumes.fillVolume);
};

const calculateTotalTarget = (initialVolumes) => {
  if (!initialVolumes || _.isNil(initialVolumes.cutVolume) || _.isNil(initialVolumes.fillVolume) || initialVolumes.error) return null;
  const initialVolume = _.round(initialVolumes.cutVolume) + _.round(initialVolumes.fillVolume);
  return initialVolume;
};

const calculateComplete = (totalTarget, cutVolume, fillVolume) => {
  if (_.isNil(cutVolume) || _.isNil(fillVolume)) return null;
  if (_.isNil(totalTarget)) return null;
  const totalVolume = _.round(cutVolume) + _.round(fillVolume);
  const completeVolume = totalTarget - totalVolume;
  return completeVolume;
};

const calculateCompletionRatio = (initialVolumes, cutVolume, fillVolume) => {
  const initialVolume = calculateTotalTarget(initialVolumes);
  if (_.isNil(initialVolume)) return null;
  const completeVolume = calculateComplete(initialVolume, cutVolume, fillVolume);
  if (_.isNil(completeVolume)) return null;
  let completionRatio = null;
  if (initialVolume !== 0) {
    completionRatio = completeVolume / initialVolume;
  }
  return completionRatio;
};

const calculateCompletionPercent = (initialVolumes, cutVolume, fillVolume) => {
  const ratio = calculateCompletionRatio(initialVolumes, cutVolume, fillVolume);
  if (_.isNil(ratio)) return null;
  return _.round(ratio * 100);
};

const getLabel = (intervalUnit, timestamp) => {
  let label = '';
  switch (intervalUnit) {
    case 'hours': {
      label = format(timestamp, 'HH:mm');
      break;
    }
    case 'days': {
      label = format(timestamp, 'dd MMM');
      break;
    }
    case 'months': {
      label = format(timestamp, 'MMM yy');
      break;
    }
    case 'years': {
      label = format(timestamp, 'yyyy');
      break;
    }
    default: {
      label = format(timestamp, 'HH:MM');
      break;
    }
  }
  return label;
};

const detailsRefmodelChartModule = {
  state: {
  },
  getters: {
    intervals(state) {
      if (!state.volumes) return [];
      return state.volumes.map(v => v.timestamp);
    },

    /**
     * Creates x domain for a bar chart
     * @return {Array} {  timestamp, label } where timestamp
     */
    domain(state) {
      const { intervalUnit, selectedRefModel, volumes } = state;
      if (!selectedRefModel || !selectedRefModel.uuid) return [];
      if (!volumes || volumes.length < 1) return [];

      return volumes.map((v) => {
        const label = getLabel(intervalUnit, v.timestamp);
        return {
          timestamp: v.timestamp,
          label
        };
      });
    },

    /*
    * Return array of objects used to plot the bar chart
    */
    volumeSeries(state) {
      const { selectedRefModel, volumes, intervalUnit, initialVolumes } = state;
      if (!selectedRefModel || !selectedRefModel.uuid) return [];
      if (!volumes || volumes.length < 1) return [];

      const plotData = volumes
        .filter(v => v.pointSet && v.pointSet.points.length > 0)
        .map((v) => {
          const label = getLabel(intervalUnit, v.timestamp);

          const completionPercent = calculateCompletionPercent(initialVolumes, v.cutVolume, v.fillVolume);
          return {
            uuid: v.uuid,
            timestamp: v.timestamp,
            date: v.timestamp,
            label,
            cut: v.cutVolume,
            fill: v.fillVolume,
            totalProgressRatio: _.isNil(completionPercent) ? $gettext('no data') : `${completionPercent}%`
          };
        });
      return plotData;
    },

    /**
     * Create view model to display pie charts
     */
    pieChartSeries(state, getters) {
      const { selectedVolumeData } = getters;
      if (!selectedVolumeData) return [];
      const {
        cutVolume,
        fillVolume,
      } = selectedVolumeData;

      const displayCut = _.round(cutVolume);
      const displayFill = _.round(fillVolume);

      const remainingVolume = displayCut + displayFill;
      return [
        {
          label: 'cut',
          value: remainingVolume ? _.round(displayCut / remainingVolume * 100, 0) : 0,
          data: selectedVolumeData
        },
        {
          label: 'fill',
          value: remainingVolume ? _.round(displayFill / remainingVolume * 100, 0) : 0,
          data: selectedVolumeData
        }
      ];
    },

    /**
   * Creates view model to display metrics panel for a selected bar
   */
    selectedVolumeMetrics(state, getters) {
      const { initialVolumes } = state;
      const { selectedVolumeData } = getters;
      if (!selectedVolumeData) return {};
      const {
        cutVolume,
        fillVolume,
        timestamp,
      } = selectedVolumeData;

      const noData = $gettext('no data');
      const displayCut = _.round(cutVolume);
      const displayFill = _.round(fillVolume);
      const remainingVolume = displayCut + displayFill;
      const netVolume = displayCut - displayFill;
      const initialVolume = calculateTotalTarget(initialVolumes);
      const totalComplete = calculateComplete(initialVolume, cutVolume, fillVolume);

      return {
        cutVolume: _.isNil(cutVolume) ? noData : _.round(cutVolume),
        fillVolume: _.isNil(fillVolume) ? noData : _.round(fillVolume),
        remainingVolume,
        netVolume,
        totalTarget: _.isNil(initialVolume) ? noData : _.round(initialVolume), // in theory rounding here is obsolete
        totalComplete: _.isNil(totalComplete) ? noData : _.round(totalComplete),
        timestamp: datetimehelper.format(timestamp, datetimehelper.LOCALE_DATE_TIME_FORMAT),
      };
    },

    /**
   * Creates view model to display metrics panel for a progress analysis
   */
    selectedVolumesDelta(state, getters) {
      const { initialVolumes } = state;
      const { selectedRange } = getters;
      if (!selectedRange || !selectedRange.from || !selectedRange.to) return null;

      const { from, to } = selectedRange;

      const noData = $gettext('no data');
      const cutTarget = getCutTotal(initialVolumes);
      const fillTarget = getFillTotal(initialVolumes);
      const fillDelta = _.round(from.fillVolume) - _.round(to.fillVolume);
      const cutDelta = _.round(from.cutVolume) - _.round(to.cutVolume);
      const totalDelta = fillDelta + cutDelta;
      const totalTarget = calculateTotalTarget(initialVolumes);
      const completionPercent = totalTarget ? _.round((totalDelta / totalTarget) * 100) : null;

      return {
        cutDelta,
        fillDelta,
        cutFrom: _.round(from.cutVolume, 0),
        cutTo: _.round(to.cutVolume, 0),
        fillFrom: _.round(from.fillVolume, 0),
        fillTo: _.round(to.fillVolume, 0),
        totalDelta,
        cutTarget: _.isNil(cutTarget) ? noData : cutTarget,
        fillTarget: _.isNil(fillTarget) ? noData : fillTarget,
        totalTarget: _.isNil(totalTarget) ? noData : totalTarget,
        completionPercent: _.isNil(completionPercent) ? noData : `${completionPercent}%`,
        fromSelectedVolume: from,
        toSelectedVolume: to
      };
    },
  },
  actions: {
    async selectBar(context, selectedVolume) {
      // const { uuid } = context.getters.selectedRefModel;
      // await context.dispatch('cancelable/cancelActions', { sourceUid: uuid }, { root: true });
      await context.dispatch('setSelectedSet', selectedVolume);
    },
    async selectRange(context, payload) {
      // const { uuid } = context.getters.selectedRefModel;
      // await context.dispatch('cancelable/cancelActions', { sourceUid: uuid }, { root: true });
      await context.commit('setSelectedRange', payload);
    }
  }
};

export default detailsRefmodelChartModule;