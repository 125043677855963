<template>
  <div class="cx-enumerative-filter">
    <cx-combobox
      :items="items"
      :value="filter.values"
      :input="onInput"
      :label="label"
      isChips
      isTextInput
      isDropdown/>
    <cx-button flat icon="close" @click="onRemove" class="cx-enumerative-filter__close-icon"/>
  </div>
</template>

<script>
import CxCombobox from '@/components/cxCombobox/CxCombobox';
import CxButton from '@/components/CxButton';

export default {
  name: 'CxMetadataChipsFilter',

  components: {
    CxCombobox,
    CxButton
  },

  props: {
    filter: Object,
    index: Number,
    label: String,
    // called when filter should be removed
    onRemoveFilter: Function,
    // called when filter value changed { filter, values<Array> }
    onValueChange: Function,
  },
  computed: {
    items() {
      if (!this.filter) return [];
      const { availableValues } = this.filter;
      return availableValues.map((o) => {
        return {
          id: o,
          name: o,
          isChipSelected: this.filter.values.includes(o)
        };
      });
    },
  },
  methods: {
    onInput(values) {
      this.onValueChange({ filter: this.filter, values });
    },
    onRemove() {
      this.onRemoveFilter(this.filter);
    }
  },
};
</script>

<style lang="less">
@import '../../../../common';

  .cx-enumerative-filter {
    .flex(row, flex-start, flex-start);
    align-items: center;
    overflow: none;

    .cx-icon {
      i {
        font-size: @majorFontSize;
      }
    }

    .cx-combobox {
      width: 100%;
      position: static;

      .cx-combobox__chips--container {
        margin-left: 0;
      }

      .cx-combobox__dropdown {
        margin-left: 0;
      }
    }
    .cx-enumerative-filter__close-icon {
      margin-left: 10px;
    }
  }
</style>
