import { format, formatISO } from 'date-fns';
import api from '@/http/api';
import { fetchPointDataStoreMixin } from '@/store/detailsModule/fetchPointData.store.mixin';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';

const detailsHoleSetModule = {
  state: {
    ...fetchPointDataStoreMixin.state,
    isExporting: false
  },
  mutations: {
    ...fetchPointDataStoreMixin.mutations,
    setIsExporting(state, isExporting) {
      state.isExporting = isExporting;
    }
  },
  getters: {
    ...fetchPointDataStoreMixin.getters,
    selectedHoleSet(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (selectedEntity && selectedEntity.entityType === DrillHoleSetType) {
        return selectedEntity;
      }
      return null;
    },
    isExporting(state) {
      return state.isExporting;
    }
  },
  actions: {
    ...fetchPointDataStoreMixin.actions,
    async exportPoints(context, extension) {
      try {
        context.commit('setIsExporting', true);
        const { selectedHoleSet } = context.getters;
        if (!selectedHoleSet.originFilters) {
          throw new Error('Can\'t Export Set - no origin filters');
        }
        const fileName = `ConX_Drill_Asbuilts_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        await api.exportHoles(
          context.rootState.parentContext.project_uuid,
          formatISO(selectedHoleSet.originTimeRange.from),
          formatISO(selectedHoleSet.originTimeRange.to),
          selectedHoleSet.originFilters,
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExporting', false);
      }
    },
    async showPointsData(context) {
      const { selectedHoleSet } = context.getters;
      context.dispatch('showGridData', selectedHoleSet);
    }
  }

};

export default detailsHoleSetModule;