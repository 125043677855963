import _ from 'lodash';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import * as d3 from 'd3';
import calc from './calc.helper';

const RANGE_SELECTION_LAYER_CLASS = 'range-selection-layer';
const HANDLE_CLASS = 'select-handle';
const HANDLE_LINE_CLASS = 'select-handle_line';
const HANDLE_CAPTION1_CLASS = 'select-handle_caption1';
const HANDLE_CAPTION1_TEXT_CLASS = 'select-handle_caption1_text';
const RANGE_AREA_CLASS = 'range-area';

const TRANSITION_DURATION = 500;

/**
 * Renders rage selection area and handles
 */
export default class D3ChartRangeSelectionLayer {

  layer;
  svg;
  scaleX;
  scaleY;
  visualProps = {
    barWidth: null,
    size: null,
    margin: null,
    caption1: {
      width: 43,
      height: 18,
      margin: { top: 3 },
      textMargin: { top: 12, left: 5 }
    },
    caption2: {
      width: 35,
      height: 16,
      margin: { top: 25 },
      textMargin: { top: 10, left: 5 }
    },
    captionDeltaThreshold: 60,
    captionDeltaWidth: 70
  };
  data;
  fromElement;
  toElement;

  constructor(svg, style, onRangeSelected) {
    this.visualProps.barWidth = style.barWidth;
    this.visualProps.size = style.size;
    this.visualProps.margin = style.margin;
    this.svg = svg;
    this.onRangeSelected = onRangeSelected;
  }

  helpers = {
    /**
     * Helper method that calculates position for are handle
     * @param {Date} start start date of a range
     * @param {Date} end end data of a range
     * @returns {Object} { x, y }
     */
    _calculateAreaHandlePosition: (start, end) => {
      const { scaleX } = this;
      // const { margin } = this.visualProps;
      const w = 30;
      const startX = (scaleX(end) + scaleX(start)) / 2 - w / 2;
      const startY = -12;
      return { x: startX, y: startY };
    },

    /**
     * Calculates attributes of captions dependant on proximity of selected bars.
     * Captions can be rendered merged when two bars are close to each other, or separately
     */
    _calculateCaptions: (fromElement, toElement) => {
      const { scaleX } = this;
      const { captionDeltaThreshold, captionDeltaWidth } = this.visualProps;
      const captionWidth = this.visualProps.caption1.width;

      const result = {
        mode: 'merged', // if detached - we have to sepatate captions, if attached captions are mergerd
        from: { x: null, width: null, text: '', textX: null },
        to: { x: null, width: null, text: '', textX: null },
      };

      const captionDelta = scaleX(toElement.data.date) - scaleX(fromElement.data.date);
      if (captionDelta < captionDeltaThreshold) {
        result.mode = 'separate';
        const barsMiddle = (scaleX(toElement.data.date) + scaleX(fromElement.data.date)) / 2;
        result.from.textX = barsMiddle;
        result.from.x = barsMiddle - (captionDeltaWidth / 2);
        result.from.width = captionDeltaWidth;
        result.from.text = `${fromElement.data.label} - ${toElement.data.label}`;

        result.to.textX = calc.clcXMiddle(scaleX, toElement.data.date);
        result.to.x = calc.clcXMiddle(scaleX, toElement.data.date) - (captionWidth / 2);
        result.to.text = '';
        result.to.width = 0;

      } else {
        result.from.textX = calc.clcXMiddle(scaleX, fromElement.data.date);
        result.from.x = calc.clcXMiddle(scaleX, fromElement.data.date) - (captionWidth / 2);
        result.from.text = `${fromElement.data.label}`;
        result.from.width = captionWidth;

        result.to.textX = calc.clcXMiddle(scaleX, toElement.data.date);
        result.to.x = calc.clcXMiddle(scaleX, toElement.data.date) - (captionWidth / 2);
        result.to.text = `${toElement.data.label}`;
        result.to.width = captionWidth;
      }
      return result;
    }
  }

  moveToFront() {
    this.layer.moveToFront();
  }

  /**
   * Plots this layer. Call when data changes
   * @param {*} scaleX function to calculate x from date
   * @param {*} scaleY function to calculate y from value
   * @param {*} data chart data
   */
  plot(scaleX, scaleY, data) {
    this.layer = this.svg
      .append('g')
      .attr('class', RANGE_SELECTION_LAYER_CLASS);
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.data = data;
  }

  /**
   * Call to render selected range on this layer
   * @param {} fromElement element on the start of the range
   * @param {*} toElement element on the end of the range
   */
  selectRange(fromElement, toElement) {
    this.fromElement = fromElement;
    this.toElement = toElement;
    this._renderHandle(fromElement, '-from');
    this._renderHandle(toElement, '-to');
    this._renderArea(fromElement, toElement);
    this._renderAreaHandle(fromElement, toElement);
    this._renderCaptions(fromElement, toElement);
  }

  /**
   * SDK for dragging elements on this layer.
   * @returns { dragStarted, dragFromHandle, dragToHandle, dragArea, dragEnd }
   * Object - returns funcions that can be called to react on drag events
   */
  DRAG_INIT() {
    let fromHandle;
    let fromHandleLine;
    let fromHandleCaption;
    let fromHandleCaptionText;
    let toHandle;
    let toHandleLine;
    let toHandleCaption;
    let toHandleCaptionText;
    let rangeArea;
    let rangeAreaHandle;
    const self = this;

    const dragStarted = () => {
      const { layer } = self;
      fromHandle = d3.select(`.${HANDLE_CLASS}-from`);
      fromHandleLine = fromHandle.select(`.${HANDLE_LINE_CLASS}`);
      fromHandleCaption = layer.select(`.${HANDLE_CAPTION1_CLASS}-from`);
      fromHandleCaptionText = layer.select(`.${HANDLE_CAPTION1_TEXT_CLASS}-from`);

      toHandle = d3.select(`.${HANDLE_CLASS}-to`);
      toHandleLine = toHandle.select(`.${HANDLE_LINE_CLASS}`);
      toHandleCaption = layer.select(`.${HANDLE_CAPTION1_CLASS}-to`);
      toHandleCaptionText = layer.select(`.${HANDLE_CAPTION1_TEXT_CLASS}-to`);

      rangeArea = layer.select(`.${RANGE_AREA_CLASS}`);
      rangeAreaHandle = layer.select(`.${RANGE_AREA_CLASS}-handle`);
    };

    const dragFromHandle = (fromElement, toElement) => {
      const { scaleX } = self;
      const c = self.helpers._calculateCaptions(fromElement, toElement);
      const x = calc.clcXMiddle(scaleX, fromElement.data.date);
      fromHandle.raise()
        .attr('x1', x)
        .attr('x2', x);
      fromHandleLine.raise()
        .attr('x1', x)
        .attr('x2', x);
      fromHandleCaption.raise()
        .attr('x', c.from.x)
        .attr('width', c.from.width);
      fromHandleCaptionText.raise()
        .text(c.from.text)
        .attr('x', c.from.textX);

      toHandleCaption.raise()
        .attr('x', c.to.x)
        .attr('width', c.to.width);
      toHandleCaptionText.raise()
        .text(c.to.text)
        .attr('x', c.to.textX);
    };

    const dragToHandle = (fromElement, toElement) => {
      const { scaleX } = self;
      const c = self.helpers._calculateCaptions(fromElement, toElement);
      const x = calc.clcXMiddle(scaleX, toElement.data.date);
      toHandle.raise()
        .attr('x1', x)
        .attr('x2', x);
      toHandleLine.raise()
        .attr('x1', x)
        .attr('x2', x);
      fromHandleCaption.raise()
        .attr('x', c.from.x)
        .attr('width', c.from.width);
      fromHandleCaptionText.raise()
        .text(c.from.text)
        .attr('x', c.from.textX);
      toHandleCaption.raise()
        .attr('x', c.to.x)
        .attr('width', c.to.width);
      toHandleCaptionText.raise()
        .text(c.to.text)
        .attr('x', c.to.textX);
    };

    const dragArea = (fromElement, toElement) => {
      const { scaleX } = this;
      const rangeX = calc.clcXMiddle(scaleX, fromElement.data.date);
      const rangeWidth = scaleX(toElement.data.date) - scaleX(fromElement.data.date);
      const handlePosition = self.helpers._calculateAreaHandlePosition(fromElement.data.date, toElement.data.date);
      rangeArea
        .attr('x', rangeX)
        .attr('width', rangeWidth);
      rangeAreaHandle.raise()
        .attr('x', handlePosition.x)
        .attr('y', handlePosition.y);
    };

    const dragEnd = () => {

    };

    return { dragStarted, dragFromHandle, dragToHandle, dragArea, dragEnd };
  }

  /**
   * Renders a handle for selected bar
   * @param {} element element for which we want to render handle
   * @param {*} elementType 'from' if we want to render handle on start of the area and 'to' otherwise
   */
  _renderHandle(element, elementType) {
    const { scaleX } = this;
    const { size, margin } = this.visualProps;
    const x = d => calc.clcXMiddle(scaleX, d.data.date);
    const yStart = margin.top;
    const yEnd = size.height - (margin.bottom);
    const handleElements = this.layer.selectAll(`.${HANDLE_CLASS}${elementType}`).data([element]);

    const transition = d3.transition().duration(TRANSITION_DURATION);
    const linesToUpdate = handleElements.select(`.${HANDLE_LINE_CLASS}`).transition(transition);

    linesToUpdate
      .attr('x1', d => x(d))
      .attr('x2', d => x(d))
      .attr('y1', yStart)
      .attr('y2', yEnd);
    const createdHandleElements = handleElements
      .enter()
      .append('g')
      .attr('class', `${HANDLE_CLASS} ${HANDLE_CLASS}${elementType}`)
      .attr('id', d => `${HANDLE_CLASS}-${d.data.uuid}`);
    createdHandleElements
      .append('line')
      .attr('class', `${HANDLE_LINE_CLASS}`)
      .attr('x1', d => x(d))
      .attr('x2', d => x(d))
      .attr('y1', yStart)
      .attr('y2', yEnd);
    handleElements.exit().remove();
  }


  /**
     * Render caption for start and end elements. Caption can be in two modes - merged when selected bars are
     * close to each other and they merge into one, or separate - when bars are far enaugh and we can render them separately.
     * When rendered merged, the 'from' elements caption renders wide and 'to' elements caption is hidden underneath
     * @param {} fromElement element on range start
     * @param {*} toElement element on range end
     */
  _renderCaptions(fromElement, toElement) {
    const { size } = this.visualProps;
    const { margin, height } = this.visualProps.caption1;
    const transition = d3.transition().duration(TRANSITION_DURATION);

    const renderData = [{ start: fromElement.data.date, end: toElement.data.date }];
    const caption = this.layer.selectAll(`.${HANDLE_CAPTION1_CLASS}-from`).data(renderData);
    const textElement = this.layer.selectAll(`.${HANDLE_CAPTION1_TEXT_CLASS}-from`).data(renderData);

    const y = (size.height + margin.top) - this.visualProps.margin.bottom;
    const textY = y + height / 2.0 + 4;
    const c = this.helpers._calculateCaptions(fromElement, toElement);

    caption
      .transition(transition)
      .attr('x', c.from.x)
      .attr('width', c.from.width);

    caption
      .enter()
      .append('rect')
      .attr('class', `${HANDLE_CAPTION1_CLASS} ${HANDLE_CAPTION1_CLASS}-from`)
      .attr('width', c.from.width)
      .attr('height', height)
      .attr('x', c.from.x)
      .attr('y', y);

    textElement
      .transition(transition)
      .text(c.from.text)
      .attr('x', c.from.textX)
      .attr('y', textY);

    textElement
      .enter()
      .append('text')
      .attr('class', `${HANDLE_CAPTION1_TEXT_CLASS} ${HANDLE_CAPTION1_TEXT_CLASS}-from`)
      .text(c.from.text)
      .attr('x', c.from.textX)
      .attr('y', textY);

    textElement.exit().remove();
    caption.exit().remove();

    this._renderToCaption(toElement, c);
  }

  /**
     * Render caption on the end of a range
     * @param {} toElement element on a range end
     * @param {*} c calculations results for a caption
     */
  _renderToCaption(toElement, c) {
    const { size } = this.visualProps;
    const { margin, height } = this.visualProps.caption1;
    const transition = d3.transition().duration(TRANSITION_DURATION);

    const renderData = [{ end: toElement.data.date }];
    const caption = this.layer.selectAll(`.${HANDLE_CAPTION1_CLASS}-to`).data(renderData);
    const textElement = this.layer.selectAll(`.${HANDLE_CAPTION1_TEXT_CLASS}-to`).data(renderData);

    const y = (size.height + margin.top) - this.visualProps.margin.bottom;
    const textY = y + height / 2.0 + 4;

    caption
      .attr('width', c.to.width)
      .transition(transition)
      .attr('x', c.to.x);
    caption
      .enter()
      .append('rect')
      .attr('class', `${HANDLE_CAPTION1_CLASS} ${HANDLE_CAPTION1_CLASS}-to`)
      .attr('width', c.to.width)
      .attr('height', height)
      .attr('x', c.to.x)
      .attr('y', y);
    textElement
      .transition(transition)
      .attr('x', c.to.textX)
      .attr('y', textY)
      .text(c.to.text);
    textElement
      .enter()
      .append('text')
      .attr('class', `${HANDLE_CAPTION1_TEXT_CLASS} ${HANDLE_CAPTION1_TEXT_CLASS}-to`)
      .text(c.to.text)
      .attr('x', c.to.textX)
      .attr('y', textY);

    textElement.exit().remove();
    caption.exit().remove();
  }

  /**
     * Renders selected area
     * @param {*} fromElement element on the range start
     * @param {*} toElement element on the range end
     */
  _renderArea(fromElement, toElement) {
    const { scaleX } = this;
    const { size, margin } = this.visualProps;
    const transition = d3.transition().duration(TRANSITION_DURATION);


    const renderData = [{ start: fromElement.data.date, end: toElement.data.date }];
    const rangeArea = this.layer.selectAll(`.${RANGE_AREA_CLASS}`).data(renderData);

    rangeArea
      .transition(transition)
      .attr('x', d => scaleX(d.start))
      .attr('width', d => scaleX(d.end) - scaleX(d.start));

    rangeArea
      .enter()
      .append('rect')
      .attr('y', margin.top)
      .attr('height', size.height - margin.bottom - margin.top)
      .attr('class', RANGE_AREA_CLASS)
      .attr('x', d => scaleX(d.start))
      .attr('width', d => scaleX(d.end) - scaleX(d.start));
    rangeArea.exit().remove();
  }

  /**
     * Renders handle for the seelected area
     * @param {*} fromElement element on the range start
     * @param {*} toElement element on the range end
     */
  _renderAreaHandle(fromElement, toElement) {
    const transition = d3.transition().duration(TRANSITION_DURATION);
    const renderData = [{ start: fromElement.data.date, end: toElement.data.date }];
    const rangeAreaHandle = this.layer.selectAll(`.${RANGE_AREA_CLASS}-handle`).data(renderData);
    const position = this.helpers._calculateAreaHandlePosition(renderData[0].start, renderData[0].end);
    rangeAreaHandle
      .transition(transition)
      .attr('x', position.x)
      .attr('y', position.y);

    rangeAreaHandle
      .enter()
      .append('svg:image')
      .attr('xlink:href', 'drag-bracket.svg')
      .attr('width', 30)
      .attr('height', 30)
      .attr('x', position.x)
      .attr('y', position.y)
      .attr('class', `${RANGE_AREA_CLASS}-handle`);
    rangeAreaHandle.exit().remove();

    // area handle is draggable
    const dragEvents = this._initializeAreaHandleDrag(rangeAreaHandle);
    this.layer.call(d3.drag().on('start', dragEvents.dragStarted));
  }

  /**
     * Initialized drag events for moving area handle
     */
  _initializeAreaHandleDrag() {

    let closestTo = null;
    let closestFrom = null;
    let toElementX = null;
    let fromElementX = null;
    let dragEvents = {};
    const dragInit = this.DRAG_INIT.bind(this);
    let newFromElement = null;
    let newToElement = null;

    const dragging = (event) => {
      const { subject, x } = event;
      const deltaX = x - subject.x;
      const deltaTo = this.scaleX.invert(toElementX + deltaX);
      const deltaFrom = this.scaleX.invert(fromElementX + deltaX);
      closestTo = calc.closestDate(deltaTo, this.data);
      closestFrom = calc.closestDate(deltaFrom, this.data);
      const toDiff = differenceInHours(_.last(this.data[0]).data.timestamp, deltaTo);
      const fromDiff = differenceInMinutes(deltaFrom, _.head(this.data[0]).data.timestamp);
      // only drag if we are not past firts or last element of a chart
      if (toDiff >= 0 && fromDiff >= 0) {
        newFromElement = closestFrom.d;
        newToElement = closestTo.d;
        dragEvents.dragFromHandle(closestFrom.d, closestTo.d);
        dragEvents.dragToHandle(closestFrom.d, closestTo.d);
        dragEvents.dragArea(closestFrom.d, closestTo.d);
      }
    };
    const dragCompleted = () => {
      dragEvents.dragEnd();
      if (newFromElement && newToElement) this.onRangeSelected(newFromElement, newToElement);
    };
    const dragStarted = (event) => {
      const { scaleX, toElement, fromElement } = this;
      event.on('drag', dragging.bind(this)).on('end', dragCompleted.bind(this));
      dragEvents = dragInit();
      toElementX = scaleX(toElement.data.date);
      fromElementX = scaleX(fromElement.data.date);
      dragEvents.dragStarted();
    };
    return { dragStarted };
  }

}
