const handleParentContext = (initializeAppHandler, projectUuid) => {
  function isEmbedded() {
    return window.name === 'Ui2iFrame';
  }

  function getDevMockProjectUuid() {
    return projectUuid || process.env.VUE_APP_MOCK_PROJECT_UUID;
  }

  function initializeParentContext(context) {
    initializeAppHandler(context);
  }

  function isOriginSafe(event) {
    const originString = (process.env.VUE_APP_TRUSTED_ORIGIN_DOMAIN);
    return event.origin.indexOf(originString) !== -1;
  }

  function hasMessageProjectUuid(event) {
    return event.data?.project_uuid;
  }

  function handleParentWindowMessage(event) {
    if (isOriginSafe(event) && hasMessageProjectUuid(event)) {
      const parentContext = event.data;
      initializeParentContext(parentContext);
    }
  }

  if (isEmbedded()) {
    window.addEventListener('message', handleParentWindowMessage);
  } else {
    // console.info('UI2 - standalone mode - using mock PROJECT_UUID');
    const project_uuid = getDevMockProjectUuid();
    const parentContext = { project_uuid };
    initializeParentContext(parentContext);
  }
};

export default handleParentContext;