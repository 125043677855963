import waffleFlagService from '@/services/waffleFlag.service';
import { mapGetters } from 'vuex';

const CxWaffleFlagMixin = {
  computed: {
    ...mapGetters('app', ['projectInitialized']),
    isOddjobEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isOddjobEnabled();
    },
    isSurfaceToModelComparisonEnabled() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.isSurfaceToModelComparisonEnabled();
    },
    showSurfacelogDeltaHeightLegendConfigurationUi2() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.showSurfacelogDeltaHeightLegendConfigurationUi2();
    },
    showSurfacelogVolumeCalculations() {
      if (!this.projectInitialized) return false;
      return waffleFlagService.showSurfacelogVolumeCalculations();
    }
  },
};

export default CxWaffleFlagMixin;
