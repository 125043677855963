import _ from 'lodash';
import { defer } from '@/utils/defer';
import * as Cesium from 'cesium';

/**
 * This makes functions raise Cesium Events.
 * It also returns those Events in an object keyed by their names.
 * @param actions {Object} An object with member functions.
 * @returns {{actions: {}, events}} The wrapped functions and the events the fire.
 */
const wrapMapActions = (store) => {
  const events = {};
  const wrapWithEvent = (f) => {
    const event = new Cesium.Event();
    events[f.name] = event;
    return async (context, payload) => {
      context.cesiumPromise = defer();
      context.self = store;
      // bind store methods to the context
      if (context.self.methods) {
        Object.assign(context.self, _.mapValues(context.self.methods, m => m.bind(context.self)));
      }
      event.raiseEvent({ context, payload });
      const viewerReady = store.rootState ? store.rootState.viewerReady : store.state.viewerReady;
      await viewerReady;
      return f(context, payload);
    };
  };

  const wrappedActions = _.mapValues(store.actions, a => wrapWithEvent(a));
  return { actions: wrappedActions, events };
};

/**
 * This bootstraps cesium store and its modules.
 * It attaches the pluggable interface for Vue modules in cesiumStore's mapInterface.
 */
const bootstrapper = {
  mapInterface: {},
  prepareinterface(store) {
    const cesiumInterface = wrapMapActions(store);
    store.mapEvents = cesiumInterface.events;
    store.actions = cesiumInterface.actions;
    _.merge(this.mapInterface, store.actions);
  },
  prepareStore(store, root) {
    if (store.modules) {
      _.forOwn(
        store.modules,
        (module, moduleName) => {
          this.prepareStore(module, root);
          store.state[moduleName] = module.state;
        }
      );
    }
    store.rootState = root.state;
    root.dispatch.then((dispatch) => { store.dispatch = dispatch; });
    this.prepareinterface(store);
  },
  /**
   * Call this guy on your cesium store and get your mapInterface ready for plugging.
   * @param cesiumStore
   */
  bootstrap(cesiumStore) {
    this.prepareStore(cesiumStore, cesiumStore);
    cesiumStore.mapInterface = this.mapInterface;
  },
};

export default bootstrapper;
