<template>
  <div class="cx-horizontal-property-panel">
    <cx-horizontal-property
      v-for="(item, index) in items"
      :key="index"
      :fieldName="item.fieldName"
      :value="item.value"
      :unit="item.unit"/>
  </div>
</template>

<script>
import CxHorizontalProperty from '@/components/CxHorizontalProperty';

export default {
  name: 'CxHorizontalPropertyPanel',
  components: { CxHorizontalProperty },
  props: {
    // { fieldName, value, unit }
    items: Array
  }
};
</script>

<style lang="less">
@import '../common';

.cx-horizontal-property-panel {
  width: @holeHorizontalPropertyWidth;
  display: flex;
  flex-direction: column;
  font-size: @appFontSize;
  color: @textColorLowlight;
}
</style>