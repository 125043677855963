import _ from 'lodash';
import sethelper from '@/utils/sethelper';
import { PointPerformanceLimitExceeded } from '@/store/mapModule/map.points.errors';
import { withColor } from '@/domain/entityMixins';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { gettext } from '@/translations/gettext.setup';

const { $gettext, gettextInterpolate } = gettext;

const pointPerformanceThreshold = 90000; // the performance warning limit of points that can be loaded on 3D map
const pointPerformanceLimit = 100000; // the performance hard limit of points that can be loaded on 3D map


const mapPointsStore = {
  state: {
    // all points loaded on the map
    loadedPoints: {},
    hiddenPointSets: [],
    // temp sets are not managable by the Object Explorer (for example ref models ones)
    tempPointSets: [],
    performancePointCount: 0,
  },
  getters: {
    /**
     * Return list of all, unique points, that are loaded on the map
     */
    allPoints(state) {
      const allPoints = Object.keys(state.loadedPoints)
        .filter(k => state.loadedPoints[k] !== null);
      const allPointsFlat = _.flatMap(allPoints, k => state.loadedPoints[k].points)
        .filter(p => p.uuid);
      return _.uniqBy(allPointsFlat, p => p.uuid);
    },
    allSets(state) {
      const sets = Object.keys(state.loadedPoints)
        .filter(k => state.loadedPoints[k] !== null);
      return _.flatMap(sets, k => state.loadedPoints[k]);
    },
    /**
     * All points sets, that can be managable by the Object Explorer
    */
    persistentSets(state) {
      const persistentSets = Object.keys(state.loadedPoints)
        .filter(k => state.loadedPoints[k] !== null)
        .filter(k => !state.tempPointSets.includes(k));
      return _.flatMap(persistentSets, (k) => {
        return state.loadedPoints[k];
      });
    },
    /**
     * All points from point sets that can be manageable by the Object Explorer.
     */
    persistentPoints(state, getters) {
      return _.flatMap(getters.persistentSets, set => state.loadedPoints[set.uuid].points);
    },
    /**
    * All unique visible points
    */
    visiblePoints(state) {
      const visiblePoints = Object.keys(state.loadedPoints)
        .filter(k => state.loadedPoints[k] !== null)
        .filter(k => !state.hiddenPointSets.includes(k));
      const flatVisibilePoints = _.flatMap(visiblePoints, k => state.loadedPoints[k].points);
      return _.uniqBy(flatVisibilePoints, p => p.uuid);
    },
    visibleSets(state) {
      const visibleSets = Object.keys(state.loadedPoints)
        .filter(k => state.loadedPoints[k] !== null)
        .filter(k => !state.hiddenPointSets.includes(k));
      return _.flatMap(visibleSets, k => state.loadedPoints[k]);
    },
    performancePointCount(state) {
      return state.performancePointCount;
    },
    arePointsPerformant(state) {
      return state.performancePointCount < pointPerformanceThreshold;
    },
    maxLoadablePointsCount() {
      return pointPerformanceLimit;
    },
    wouldExceedPerformanceLimit: state => (newPoints) => {
      const loadedPoints = state.performancePointCount;
      const maxLoadablePoints = pointPerformanceLimit;
      const pointsAfterLoadCount = newPoints + loadedPoints;
      return pointsAfterLoadCount > maxLoadablePoints;
    }
  },
  mutations: {
    addLoadedPoints(state, data) {
      data.forEach((d) => {
        const newDisplayedPoints = { ...state.loadedPoints };
        newDisplayedPoints[d.uuid] = d;
        state.loadedPoints = newDisplayedPoints;
      });
    },
    removeLoadedPoints(state, setIds) {
      setIds.forEach((setId) => {
        const newDisplayedPoints = { ...state.loadedPoints };
        delete newDisplayedPoints[setId];
        state.loadedPoints = newDisplayedPoints;
      });
    },
    togglePointSetsVisibility(state, { setIds, isHidden }) {
      if (isHidden === true) {
        state.hiddenPointSets = state.hiddenPointSets.concat(setIds);
      }
      if (isHidden === false) {
        state.hiddenPointSets = state.hiddenPointSets.filter(s => !setIds.includes(s));
      }
    },
    addTempPointSet(state, { uuids }) {
      state.tempPointSets = state.tempPointSets.concat(uuids);
    },
    removeTempPointSets(state, uuids) {
      state.tempPointSets = state.tempPointSets.filter(u => !uuids.includes(u));
    },
    setPointCount(state, pointCount) {
      if (Number.isInteger(pointCount)) state.performancePointCount = pointCount;
    }
  },
  actions: {
    async checkPointLimitExceeded(context, newPoints) {
      return context.getters.wouldExceedPerformanceLimit(newPoints);
    },
    async updatePerformancePointCount(context, { pointCount }) {
      context.commit('setPointCount', pointCount);
    },
    /**
     * Adds points sets to loadedPoints object
     * and displays newly added points on the map
     * or hides them right away
     * @param {Array} sets list of sets to be added
     * @param {Boolean} showOnMap if false sets will be added to the hidden
     *                            set list and will not be displayes on the map
     */
    async loadPoints(context, {
      sets,
      showOnMap,
      isPersistent = true,
      useContextColor
    }) {
      const setCount = sets.reduce((count, set) => count + set.count, 0);
      const canAddPoints = !await context.dispatch('checkPointLimitExceeded', setCount);
      if (canAddPoints) {
        const setUuids = sets.map(s => s.uuid);
        await context.dispatch('viewer/loadPoints', { pointSets: sets, show: showOnMap, useContextColor })
          .then(() => {
            context.commit('addLoadedPoints', sets);
            if (!showOnMap) {
              context.commit('togglePointSetsVisibility', { setIds: setUuids, isHidden: true });
            }
            if (!isPersistent) {
              context.commit('addTempPointSet', { uuids: setUuids });
            }
          });
      } else {
        const title = $gettext('Unable to load %{count} points because they exceed the performance limit (%{max}).');
        context.dispatch('warning', {
          message: gettextInterpolate(title, { count: setCount, max: context.getters.maxLoadablePointsCount })
        });
        throw new PointPerformanceLimitExceeded();
      }
      return true;
    },
    /**
     * Removes point sets from loadedPoints object
     * and from cesium view. If point set was selected, it is deselected
     * If point set id was in the hidden list, it is removed from there
     * @param {Array} setUuids list of set ids to be removed
     * @param {Promise} notifier defer to be resolved whtogglePointSetVisibilityen done
     */
    async unloadPoints(context, { setUuids, notifier }) {
      if (!setUuids || setUuids.length < 1) { return; }
      const allPointsToUnload = _.flatMap(setUuids, s => context.state.loadedPoints[s].points);
      await context.dispatch('hidePoints', { setIds: setUuids });
      context.commit('removeLoadedPoints', setUuids);
      context.commit('removeTempPointSets', setUuids);
      context.commit('togglePointSetsVisibility', { setIds: setUuids, isHidden: false });
      const pointsToRemove = sethelper.subtractSets(
        // all points from sets to hide
        allPointsToUnload,
        // all leftover sets with points
        context.getters.allPoints
      );
      // remove sets from visible
      await context.dispatch('viewer/removePoints', { points: pointsToRemove });
      if (notifier) notifier.resolve();
    },
    /**
     * Shows a particular point set on the map
     * @param {String} setId Id of the point set that we want to show on the map
     */
    async showPoints(context, { setId }) {
      if (context.state.loadedPoints[setId]) {
        // do not want to show points without uuid, which are points from initial model
        const pointsToShow = context.state.loadedPoints[setId].points.map(p => p.uuid).filter(p => !!p);
        await context.dispatch('viewer/showHideAsBuiltPoint', { points: pointsToShow, show: true });
        context.commit('togglePointSetsVisibility', { setIds: [setId], isHidden: false });
      }
    },
    async hidePoints(context, { setIds, pointsToHide = null }) {
      context.commit('togglePointSetsVisibility', { setIds, isHidden: true });
      const pointsUuids = pointsToHide ||
        sethelper.subtractSets(
          // all points from sets to hide
          _.flatMap(setIds, s => context.state.loadedPoints[s].points),
          // all visible points apart from ones from the sets, as we've already toggled
          // the set visibility
          context.getters.visiblePoints
        );
      await context.dispatch('viewer/showHideAsBuiltPoint', { points: pointsUuids, show: false });
    },
    async colorPointSets(context, { sets, useColor }) {
      let pointColors = _.flatMap(sets, s => _.get(context.state.loadedPoints, [s, 'points']));
      if (useColor) pointColors = pointColors.map(withColor);
      pointColors = pointColors.filter(p => !!p).reduce((acc, p) => _.set(acc, p.uuid, p.color), {});
      if (!useColor) context.dispatch('viewer/colorPoints', { pointColors, color: 'default' });
      else context.dispatch('viewer/colorPoints', { pointColors });
    },
    /**
     * Checks if there is a selectedEntity and if the entity has same same id as given
     * it will be deselected, if selectedEntity is point
     * and it is in the pointUuids list, it will be deselected
     * @param {Array} uuids list of ids of point sets to be deselected
     */
    async deselectPointsAndPointSets(context, { uuids }) {
      const { selectedEntity } = context.state;
      if (
        selectedEntity
        && selectedEntity.entityType === PointSetType
        && uuids.find(f => selectedEntity.uuid === f)) {
        context.dispatch('viewer/deselect');
      }
      const pointsFromSets = _.flatMap(uuids, s => context.state.loadedPoints[s].points);
      const allotherPoints = _.flatMap(context.getters.visibleSets
        .filter(s => !uuids.includes(s.uuid)), f => f.points);
      const pointsToBeDeselected = sethelper.subtractSets(pointsFromSets, allotherPoints);
      if (selectedEntity && pointsToBeDeselected.includes(selectedEntity.uuid)) {
        context.dispatch('viewer/deselect');
      }
    },
    async warning() {
      // subscribed to by the notification plugin
    }
  },
};

export default mapPointsStore;