<template>
  <div class="cx-scale-legend">
    <div class="cx-scale-legend__title">
      <slot name="title"></slot>
    </div>
    <div class="cx-scale-legend__unitText">
      <slot name="unit"></slot>
    </div>
    <div class="cx-scale-legend__gradient">
      <div class="cx-scale-legend__gradient__scale">
        <div v-for="(item, index) in scale" :key="`height-slope-${index}`" class="cx-scale-legend__scale-number">
          {{ item }}
        </div>
      </div>
      <div
        class="cx-scale-legend__gradient__image"
        :style="this.imageCSS"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxScaleLegend',
  props: {
    scale: { type: Array, required: true },
    gradientImage: { type: String, required: true }
  },
  computed: {
    imageCSS() {
      return { backgroundImage: `url("${this.gradientImage}")` };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-scale-legend {
  width: @overlayControlLegendWidth;
  height: @overlayControlLegendHeight;
  .flex(column, flex-start, center);

  &__title {
    pointer-events: auto;
    font-size: 7px;
    padding: 9px 0 3px 0;
    color: @textColorHighlight;
    text-align: center;
  }

  &__unitText {
    pointer-events: auto;
    font-size: 7px;
    padding: 0 0 10px 0;
    color: @textColorLowlight;
  }

  &__gradient {
    display: flex;
    justify-content: center;
    gap: 9px;
    height: @overlayControlLegendGradientHeight;
    width: 60%;

    &__image {
      pointer-events: auto;
      border-radius: 2px;
      height: inherit;
      width: 12px;
      background-size: cover;
    }

    &__scale, &__dash {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__scale {
      font-size: 7px;
      opacity: 0.5;
      list-style-type: none;
      text-align: right;

      .cx-scale-legend__scale-number {
        line-height: 1.9;
      }
    }

    &__dash {
      font-weight: bold;
      font-size: 7px;
      opacity: 0.5;
      list-style-type: none;
      text-align: right;
    }
  }
}
</style>
