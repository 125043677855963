<template>
  <cx-simple-popup :isOpened="isOpened" :offsetRatio="{ y: 0.4, x: 0.5}" :popupId="popupId" class="cx-sos-popup" :closeButtonDisabled="spinSendButton">
    <!-- SOS template  -->
    <div class="cx-simple-popup-container" v-if="!displayErrorScreen">
      <div class="sos-icon">SOS</div>
      <div class="sos-text">{{ translations.sosText }}</div>
    </div>
    <template #actions>
      <div class="cx-sos-popup__actions" v-if="!displayErrorScreen">
        <cx-button @click="sendSosMessage" :loading="spinSendButton" variantPrimary>
          <span>{{ translations.sendMessageText }}</span>
        </cx-button>
      </div>
      <div class="cx-sos-popup__actions" v-if="displayErrorScreen">
        <cx-button @click="sendSos" :loading="spinSendButton" variantSecondary>
          <span>{{ translations.resendMessageText }}</span>
        </cx-button>
      </div>
    </template>

    <!-- error template -->
    <template #title>
      <div v-if="displayErrorScreen">
        <div class="title-container">
          <div class="circle"></div>
          <cx-icon icon="mdi-block-helper" :size="20"></cx-icon>
          <span>{{ translations.sendSosHeaderText }}</span>
        </div>
      </div>
    </template>
    <div class="cx-simple-popup-container error-container" v-if="displayErrorScreen">
      <div class="sos-error-text">{{ translations.sendSosText }}</div>
      <div>{{ translations.sendSosTestPlease }}</div>
    </div>
  </cx-simple-popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxSimplePopup from '@/components/CxSimplePopup';
import CxButton from '@/components/CxButton';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxSosPopup',
  components: {
    CxSimplePopup,
    CxButton,
    CxIcon
  },
  props: {
    popupId: { type: String, default: 'popup-awareness-sos' },
    isOpened: { type: Boolean }
  },
  computed: {
    ...mapGetters('sos', ['displayErrorScreen', 'spinSendButton']),
    translations() {
      return {
        sosText: this.$gettext('Are you sure you want to send an SOS to all machines?'),
        sendMessageText: this.$gettext('Send Message'),
        sendSosHeaderText: this.$gettext('Message failed'),
        sendSosText: this.$gettext('Sending an SOS message failed.'),
        sendSosTestPlease: this.$gettext('Please try again or contact support center.'),
        resendMessageText: this.$gettext('Resend Message')
      };
    }
  },
  methods: {
    ...mapActions('sos', ['sendSos']),
    ...mapActions('popup', ['closePopup']),
    async sendSosMessage() {
      await this.sendSos();
      this.closePopup(this.popupId);
    }
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-sos-popup {

   .cx-sos-popup__actions {
     width: 100%;
     display: flex;
     justify-content: flex-end;
     margin-right: 10px;
   }

  .cx-button {
    margin-left: 10px;
  }

  .title-container {
    display: flex;
    .cx-icon {
      transform: rotate(360deg);
      color: #252a35;
    }
    span {
      margin-left: 20px;
    }
    .circle {
      background-color: @errorAlertColor;
      border:1px solid transparent;
      height:40px;
      border-radius:50%;
      width:40px;
      position: absolute;
      left: 20px;
      top: 16px;
    }
  }

  .cx-simple-popup-container.error-container {
    margin-left: 40px;
  }

  .sos-icon {
    background-color: @errorAlertColor;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 20px;
  }

  .sos-text {
    margin-bottom: 20px;
  }

}

</style>