export class Hole {
  /**
   * Creates a new 3D representation of a hole.
   * @param drillHoleData {DrillHole}: Data used to generate geometries.
   * @param billboard {Billboard}: The 2D billboard representation of the hole.
   * @param lines {Array<Polyline>}: The 3D representation line part of the umbrella.
   * @param circles {Array<Primitive>}: The 3D representation circle outline part of the umbrella.
   */
  constructor(drillHoleData, billboard, lines, circles) {
    this.drillHoleData = drillHoleData;
    this.billboard = billboard;
    this.lines = [...lines];
    this.circles = [...circles];
  }

  /**
   * Return the hole's id object. This is the id object used by primitives.
   * @returns {Object}
   */
  get id() {
    return this.billboard.id;
  }

  /**
   * Return the hole's unique identifier.
   */
  get uid() {
    return this.drillHoleData.uuid;
  }

  get name() {
    return this.drillHoleData.holeName;
  }

  get points() {
    return this.drillHoleData.points;
  }

  get pointIds() {
    return this.drillHoleData.points.map(p => p.uuid);
  }

  /**
   * Show / Hide the billboard representation.
   * @param show
   */
  showHide2D(show) {
    this.billboard.show = show;
  }

  /**
   * Show / Hide the umbrella representation.
   * @param show
   */
  showHide3D(show) {
    this.lines.forEach((line) => { line.show = show; });
    this.circles.forEach((circle) => { circle.show = show; });
  }

  /**
   * Add set information to the hole's primitives.
   * @param setId
   */
  addSet(setId) {
    this.billboard.id.holeSets.add(setId);
  }

  /**
   * Remove set information from the hole's primitives.
   * @param setId
   */
  removeSet(setId) {
    this.billboard.id.holeSets.delete(setId);
  }

  /**
   * Return true if hole belongs to set.
   * @returns {boolean}
   */
  get belongsToSet() {
    return !!this.billboard.id.holeSets.size;
  }

  /**
   * The Hole's position is considered to be it's billboard position because this is the clamped to terrain position.
   * @returns {Cartesian3}
   */
  get position() {
    return this.billboard.position;
  }
}