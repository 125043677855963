import { OperationContext } from '@/domain/OperationContext.enum';

const CxOperationContextMixin = {
  computed: {
    earthmovingContext() {
      if (this.context === OperationContext.Earthmoving) return true;
      return false;
    },
    stabilizationContext() {
      if (this.context === OperationContext.Stabilization) return true;
      return false;
    },
  },
};

export default CxOperationContextMixin;