import { createFieldMixin } from '@/viewModel/fieldFactory';
import { createValueMixin } from '@/viewModel/valueFactory';
import { withIcon, withContext } from '@/domain/entityMixins';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export class PointDetailsViewModel {

  _point;
  constructor(point) {
    this._point = withContext(point);
    this.createField = createFieldMixin(point);
    this.createValue = createValueMixin(point);
  }

  get subtitle() {
    const field = $gettext('Type: Measured Point');
    const value = field;
    const icon = 'cx_as_built';
    return { field, value, icon };
  }

  get targetHoleAngle() {
    return this.createField('targetHoleAngle');
  }
  get gnss() {
    const gnss = this.createValue('gnsscq3d');
    const field = $gettext('GNSS Coordinate Quality');
    const { value, unit } = gnss;
    const icon = 'gps_fixed';
    return { field, value, icon, unit };
  }
  get drillPileDepth() {
    return this.createField('drillPileDepth');
  }
  get equipmentType() {
    const equipmentType = this.createValue('equipmentType');
    const field = $gettext('Equipment Type');
    const value = equipmentType.displayValue;
    const icon = 'info';
    return { field, value, icon };
  }
  get unitType() {
    const unitType = this.createValue('unitType');
    const field = $gettext('Unit Type');
    const value = unitType.displayValue;
    const { icon } = withIcon({ entityType: this._point.unitType });
    return { field, value, icon };
  }
  get jobName() {
    const jobName = this.createValue('jobName');
    const field = $gettext('Job Name');
    const value = jobName.displayValue;
    const icon = 'cx_jobs';
    return { field, value, icon };
  }
  get deltaHeight() {
    return this.createValue('deltaHeight');
  }
  get northing() {
    return this.createField('northing');
  }
  get easting() {
    return this.createField('easting');
  }
  get latitude() {
    return this.createField('latitude');
  }
  get longitude() {
    return this.createField('longitude');
  }
  get height() {
    return this.createField('height');
  }
  get ellipsoidHeight() {
    return this.createField('ellipsoidHeight');
  }
  get pointCode() {
    return this.createField('pointCode');
  }
  get comment() {
    return this.createField('comment');
  }
  get coordinateSystem() {
    const coordinateSystem = this.createValue('coordinateSystem');
    const field = $gettext('Coordinate System');
    const value = coordinateSystem.displayValue;
    const icon = 'map';
    return { field, value, icon };
  }
  get heightOffset() {
    return this.createField('heightOffset');
  }
  get heightOffsetType() {
    return this.createField('heightOffsetType');
  }
  get sideDistance() {
    return this.createField('sideDistance');
  }
  get targetHoleHeading() {
    return this.createField('targetHoleHeading');
  }
  get actualToolAngle() {
    return this.createField('actualToolAngle');
  }
  get actualToolHeading() {
    return this.createField('actualToolHeading');
  }
  get toolDiameter() {
    return this.createField('toolDiameter');
  }
  get pileHeight() {
    return this.createField('pileHeight');
  }
  get drillPileDepthSource() {
    return this.createField('drillPileDepthSource');
  }
  get logTime() {
    const logTime = this.createValue('logTime');
    const field = $gettext('Log Time');
    const value = logTime.displayValue;
    const icon = 'access_time';
    return { field, value, icon };
  }
  get lastUpdatedAt() {
    const lastUpdatedAt = this.createValue('lastUpdatedAt');
    const field = $gettext('Last updated at');
    const value = lastUpdatedAt ? lastUpdatedAt.displayValue : null;
    const icon = 'access_time';
    return { field, value, icon };
  }
  get isDiscarded() {
    const isDiscarded = this.createValue('isDiscarded');
    const field = 'isDiscarded';
    const value = isDiscarded._value ? $gettext('Point deleted') : null;
    const icon = 'priority_high';
    return { field, value, icon };
  }
  get context() {
    return this._point.context;
  }
  get panelSerialNumber() {
    return this.createField('panelSerialNumber');
  }
  get station() {
    return this.createField('station');
  }
  get cutFill() {
    return this._point.cutFill;
  }
  get color() {
    return this._point.color;
  }
  get name() {
    return `${this._point.pointName}`;
  }
  get unitName() {
    return this._point.unitName;
  }
  get drillPileStatus() {
    return this.createField('drillPileStatus');
  }
  get referenceModelName() {
    if (!this._point) return '';
    return this._point.referenceModelName;
  }
}
