<template>
  <div class="cx-chip" v-cx-tooltip="tooltip" :class="applyClass">
    <cx-icon v-if="isIcon" :icon="item.icon" class="cx-chip__icon cxu-center-v" :size="13"/>
    <div class="cx-chip__caption" @mouseenter="toggleTooltip(true)" @mouseleave="toggleTooltip(false)">
      <span class="cx-chip__caption__title">{{ item.name }}</span>
      <span v-if="isSubtitle" class="cx-chip__caption__subtitle">{{ item.subtitle }}</span>
    </div>
    <cx-button :disabled="disabled" flat icon="close" class="cx-chip__close cxu-center-v" @click="onRemove" :iconSize="9"/>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import CxButton from '@/components/CxButton';

export default {
  name: 'CxChip',

  components: {
    CxIcon,
    CxButton
  },

  props: {
    item: Object,
    isIcon: Boolean,
    isSubtitle: Boolean,
    isTooltip: Boolean,
    input: Function,
    disabled: Boolean,
    loading: Boolean
  },

  data() {
    return {
      isTooltipVisible: false
    };
  },

  computed: {
    tooltip() {
      const { item, isTooltipVisible } = this;
      return {
        text: item.tooltip,
        show: item.tooltip && isTooltipVisible,
        position: 'top-left',
      };
    },
    applyClass() {
      if (this.disabled) {
        return 'cx-chip--disabled';
      }
      if (this.loading) {
        return 'cx-chip--loading';
      }
      return '';
    }
  },

  methods: {
    toggleTooltip(isVisible) {
      if (this.isTooltip) this.isTooltipVisible = isVisible;
    },
    onRemove({ event }) {
      if (!this.disabled && !this.loading) {
        event.stopPropagation();
        const { id } = this.item;
        this.input({ id, isChipSelected: false });
      }
    }
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-chip {
  margin: 2px 3px;
  min-height: @inputHeight;
  padding: 5px 10px;
  background-color: @filterListBgColor;
  border-radius: @inputBorderRadius;
  cursor: auto;
  .cxu-center-v();

  .cx-chip__icon {
    opacity: 0.3;
    pointer-events: none;
    color: @textColor;
  }

  .cx-chip__caption {
    .flex(column, flex-start, flex-start);
    padding: 0 8px 0;

    .cx-chip__caption__title {
      color: @textColorHighlight;
      font-size: @appFontSize;
      padding-top: 2px;
    }

    .cx-chip__caption__subtitle {
      font-size: @appFontSize;
    }
  }

  &--disabled {
    .cx-chip__caption {
      opacity: 0.3;
    }

    .cx-chip__close {
      opacity: 0.6;
    }
  }

  &--loading {
    visibility: hidden;

    .cx-chip__close {
      opacity: 0;
    }
  }

}
</style>