<template>
  <div class="cx-select" :class="applySelectClass" v-click-outside="closeMenu" @keydown="onKeyDown">
    <!-- Menu Label -->
    <slot name="label">
      <label class="cx-select__label" v-if="label">{{ label }}</label>
    </slot>
    <!-- Menu Activator -->
    <div
      class="cx-select__activator"
      :class="applyActivatorClass"
      @click="toggleMenu"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      v-cx-tooltip="activatorTooltip">
      <slot name="activator">
        <cx-button
          variantDropdown
          :disabled="disabled"
          :loading="loading">
          {{ activatorText }}
          <template #after-content>
            <cx-icon
              :disabled="disabled || null"
              icon="more_horiz"/>
          </template>
        </cx-button>
      </slot>
    </div>

    <!-- Menu -->
    <cx-popper v-if="isMenuOpened" :options="popperOptions">
      <!-- Menu Item -->
      <div class="cx-select__menu" ref="menu">
        <div
          class="cx-select__menu__item"
          :class="applyMenuItemClass(item)"
          v-for="(item, index) in items"
          :key="index"
          @click="toggleSelection(item)">
          <slot name="menu-item" :item="item">
            <div
              class="cx-select__menu__item__body"
              v-cx-tooltip="{
                text: item.title,
                show: refModelsTooltips[item.id],
              }">
              <div
                class="cx-select__menu__item__body__title">
                <cx-checkbox v-if="multiselect" :checked="isItemSelected(item.id)"/>
                <span
                  class="cx-select__menu__item__body__title__text">
                  {{ item.title }}
                </span>
              </div>
              <span class="cx-select__menu__item__body__subtitle" :class="{ 'apply-checkbox-margin': multiselect }" v-if="item.subtitle">{{ item.subtitle }}</span>
            </div>
          </slot>
        </div>
        <!-- Menu Item Placeholder -->
        <slot name="no-results-placeholder" v-if="noDataPlaceholder && !items.length">
          <span class="cx-select__menu__item--placeholder">{{ noDataPlaceholder }}</span>
        </slot>
      </div>
    </cx-popper>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import CxPopper from '@/components/CxPopper';
import CxCheckbox from '@/components/CxCheckbox';
import CxIcon from '@/components/CxIcon';
import CxTooltipMixin from '@/components/CxTooltipMixin';
import maxSize from 'popper-max-size-modifier';
import applyMaxSize from '@/components/menuComponents/popperApplyMaxSize.modifier';
import sameWidth from '@/components/menuComponents/popperSameWidth.modifier';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export default {
  name: 'CxSelect',
  components: {
    CxButton,
    CxPopper,
    CxCheckbox,
    CxIcon
  },
  mixins: [CxTooltipMixin],
  props: {
    items: {
      type: Array,
      required: true,
      validator(items) {
        if (!items) return false;
        if (items.some(i => !i.id) || items.some(i => !i.title)) return false;
        return true;
      }
    },
    selectedItemIds: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    multiselect: { type: Boolean, default: false },
    label: { type: String },
    activatorPlaceholder: { type: String },
    noDataPlaceholder: { type: String, default: $gettext('No data') },
    activatorClassName: { type: String },
    menuItemClassName: { type: String },
    popperOptions: {
      type: Object,
      default() {
        return {
          placement: 'bottom-start',
          modifiers: [
            maxSize,
            applyMaxSize,
            sameWidth,
            { name: 'offset', options: { offset: [0, 3] } }
          ]
        };
      }
    }
  },
  emits: ['update:selectedItemIds', 'click'],
  data() {
    return {
      isMenuOpened: false,
      activeItem: null,
      refModelsTooltips: {},
    };
  },
  computed: {
    activatorTooltip() {
      return {
        ...this.tooltipOptions,
        position: 'top-left',
        offsetTop: -5
      };
    },
    activatorText() {
      if (this.selectedItemIds.length) {
        return this.items
          .filter(i => this.selectedItemIds.includes(i.id))
          .map(i => i.title)
          .join(', ');
      }
      if (this.multiselect) return this.activatorPlaceholder || this.$gettext('Select items...');
      return this.activatorPlaceholder || this.$gettext('Select item...');
    },
    applySelectClass() {
      return {
        'cx-select--disabled': this.disabled,
        'cx-select--loading': this.loading
      };
    },
    applyActivatorClass() {
      return {
        'cx-select__activator--disabled': this.disabled,
        'cx-select__activator--loading': this.loading,
        'cx-select__activator--placeholder': !this.selectedItemIds.length,
        [this.activatorClassName]: !!this.activatorClassName
      };
    }
  },
  methods: {
    isItemSelected(itemId) {
      return this.selectedItemIds.includes(itemId);
    },
    toggleSelection(item) {
      const { multiselect, selectedItemIds, isItemSelected } = this;
      const isSelected = isItemSelected(item.id);
      let newSelection;
      if (multiselect) {
        newSelection =
          isSelected ?
            [...selectedItemIds.filter(i => i !== item.id)] :
            [...selectedItemIds, item.id];
      } else newSelection = isSelected ? [] : [item.id];
      this.$emit('update:selectedItemIds', newSelection);
      this.$emit('click', item.id);
      if (!multiselect) this.closeMenu();
    },
    toggleMenu() {
      if (this.disabled || this.loading) return;
      this.isMenuOpened = !this.isMenuOpened;
      if (this.isMenuOpened && this.$refs.menu) this.$refs.menu.focus();
    },
    closeMenu() {
      this.isMenuOpened = false;
      this.activeItem = null;
    },
    onKeyDown(e) {
      if (!this.items || this.disabled || this.loading) return;
      const [firstItem] = this.items;
      const lastItem = this.items[this.items.length - 1];
      if (e.key === 'Enter') {
        if (!this.isMenuOpened) this.isMenuOpened = true;
        else {
          if (!this.activeItem) return;

          const item = this.items.find(i => i.id === this.activeItem.id);
          if (item) this.toggleSelection(item);

          if (!this.multiselect) this.closeMenu();
        }
        e.preventDefault();
      }
      if (e.key === 'Escape') this.closeMenu();
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        if (!this.isMenuOpened) this.isMenuOpened = true;
        const activeItemIndex = this.items.findIndex(i => i === this.activeItem);
        if (e.key === 'ArrowUp') this.activeItem = activeItemIndex <= 0 ? lastItem : this.items[activeItemIndex - 1];
        else this.activeItem = activeItemIndex === this.items.length - 1 ? firstItem : this.items[activeItemIndex + 1];
      }
    },
    applyMenuItemClass(item) {
      return {
        'cx-select__menu__item--selected': this.isItemSelected(item.id),
        'cx-select__menu__item--hovered': this.activeItem ? this.activeItem.id === item.id : false,
        [this.menuItemClassName]: !!this.menuItemClassName
      };
    },
  }
};
</script>

<style lang="less">
@import '../common';

.cx-select {
  width: 100%;

  .cx-select__label {
    color: @textColorLowlight;
    margin-left: 10px;
  }

  .cx-select__activator {
    width: inherit;
    height: @inputHeight;
    cursor: pointer;

    &:not(&:first-child) {
      margin-top: 5px;
    }

    &--disabled, &--loading {
      cursor: default;
    }

    &--placeholder {
      .cx-button__caption__text {
        color: @textColorLowlight;
      }
    }

    .cx-button {
      width: 100%;
      height: 100%;

      .cx-button__caption {
        display: flex;
        justify-content: space-between;
        padding: 7px 12px 7px 12px;

        .cx-button__caption__text {
          .cut-overflow();
        }
      }

      .cx-icon {
        text-rendering: optimizeLegibility;
      }
    }
  }

  .cx-popper {
    background-color: @dropdownBackgroundColor;
    border-radius: @inputBorderRadius;
    padding-right: 2px;

    ::-webkit-scrollbar-thumb {
      background-color: @scrollThumbHoverColorLight;
      box-shadow: inset -1px -1px 0px @scrollThumbHoverColorLight, inset 1px 1px 0px @scrollThumbHoverColorLight;
      border-right: 1px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: @scrollThumbColor;
      box-shadow: inset -1px -1px 0px @scrollThumbColor, inset 1px 1px 0px @scrollThumbColor;
    }

    .cx-select__menu {
      width: inherit;
      background-color: @dropdownBackgroundColor;
      border-radius: @inputBorderRadius;
      padding: 8px;
      box-shadow: @dropdowBoxShadow;

      .cx-select__menu__item {
        cursor: pointer;
        border-top: 2px solid @dropdownBackgroundColor;
        border-bottom: 2px solid @dropdownBackgroundColor;
        border-radius: @inputBorderRadius;
        padding: 0 8px;

        &:not(.cx-select__menu__item--selected) {
          &:hover {
            background-color: @inputBackgroundHover;
            color: @textColorHighlight;
          }

          &--hovered {
            background-color: @inputBackgroundHover;
            color: @textColorHighlight;
          }
        }

        &--selected {
          background-color: @dropdownActiveItem;
        }

        &--hovered {
          background-color: @inputBackgroundHover;
          color: @textColorHighlight;
        }

        &--placeholder {
          color: @textColorDisabled;
          padding: 8px;
        }

        .cx-select__menu__item__body {
          width: 100%;
          height: @cxOptionSize;
          display: flex;
          justify-content: center;
          flex-direction: column;

          .cx-select__menu__item__body__title {
            display: flex;
            align-items: center;
            align-content: center;
            flex-direction: row;

            .cx-checkbox {
              width: @cxOptionCheckboxWidth;
              display: flex;
              margin-right: 8px;
            }

           .cx-select__menu__item__body__title__text {
            .cut-overflow();
            }
          }


          .cx-select__menu__item__body__subtitle {
            color: @textColorLowlight;
            .cut-overflow();

            &.apply-checkbox-margin {
              margin-left: 21px;
            }
          }
        }
      }
    }
  }
}
</style>