<template>
  <div class="cx-units-view">
    <transition :name="applyAnimations('filter1')">
      <cx-filter-title
        v-show="isAnimationReady"
        :title="translations.title"/>
    </transition>
    <transition :name="applyAnimations('filter2')">
      <div class="cx-unit-view__results">
        <cx-dynamic-list
          multiselect
          v-show="isAnimationReady"
          :items="listItems"
          :selectedItems="selectedItems"
          :disabledItems="disabledItems"
          @update:selectedItems="handleSelectionChanged"
          :header="translations.header"
          :itemSize="37">
          <template #default="{ item, selected, disabled}">
            <cx-list-item
              :item="item"
              :selected="selected"
              :disabled="disabled"
              :warning="item.warning">
              <template #before-content="{}">
                <cx-icon
                  :icon="item.icon"
                  :size="15"
                  class="cxu-color-faded"></cx-icon>
              </template>
            </cx-list-item>
          </template>
          <template #header-append="{}">
            <cx-warning :warning="translations.warning" v-if="displayWarning"/>
          </template>
          <template #placeholder>
            <cx-label :label="translations.noResults" class="cx-point-view__filters__result-list__placeholder"/>
          </template>
          <template #items-append>
            <div class="cx-unit-view__results-gap"></div>
          </template>
        </cx-dynamic-list>
      </div>
    </transition>
    <transition :name="applyAnimations('action')">
      <cx-filter-action-panel
        v-show="isAnimationReady"
        :onClearFiltersClicked="clearFilters"
        :onApplyClicked="applyUnits"
        :isDisabled="!isSomeResultSelected"
        :queryOngoing="isQueryOngoing"/>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxFilterTitle from '../common/CxFilterTitle';
import CxFilterActionPanel from '../common/CxFilterActionPanel';
import CxDynamicList from '@/components/list/CxDynamicList';
import CxWarning from '@/components/CxWarning';
import CxListItem from '@/components/list/CxListItem';
import CxIcon from '@/components/CxIcon';
import CxLabel from '@/components/CxLabel';

export default {
  name: 'CxFilterUnitView',
  components: {
    CxFilterTitle,
    CxFilterActionPanel,
    CxDynamicList,
    CxListItem,
    CxWarning,
    CxIcon,
    CxLabel
  },
  mixins: [CxAnimationMixin],
  computed: {
    ...mapGetters('filterUnits', ['units', 'isQueryOngoing']),
    isSomeResultSelected() {
      return this.units.some(i => i.isResultSelected);
    },
    listItems() {
      return this.units.map(r => ({
        id: r.id,
        title: r.name,
        warning: r.isResultWarning,
        icon: r.icon,
        tooltip: r.name
      }));
    },
    selectedItems() {
      return this.units.filter(m => m.isResultSelected);
    },
    disabledItems() {
      return this.units.filter(m => !m.isResultSelectable);
    },
    displayWarning() {
      return this.units.some(i => !i.isResultSelectable);
    },
    translations() {
      return {
        title: this.$gettext('Units'),
        header: this.$gettext('Results'),
        warning: this.$gettext('Units without location'),
        noResults: this.$gettext('No results'),
      };
    }
  },
  methods: {
    ...mapActions('filterUnits', ['changeSelectedFilterResults', 'applyUnits', 'clearFilters']),
    handleSelectionChanged(selectedItems) {
      this.changeSelectedFilterResults(selectedItems.map(i => i.id));
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-units-view {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: scroll;
  padding: @inputSpace 10px 0 0;
  font-size: @appFontSize;

  .cx-unit-view__results {
    padding-top: 25px;
    flex: 1 0;
    overflow: hidden;
    .cx-unit-view__results-gap{
      height: 100px;
    }
  }

}
</style>