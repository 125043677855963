import { OperationContext } from '@/domain/OperationContext.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { RefmodelTypes } from '../refmodels/RefmodelTypes.enum';

const withContext = (entity) => {
  if (!entity || Object.hasOwnProperty.call(entity, 'context') || !!entity.context) return entity;
  const { entityType } = entity;
  let context = OperationContext.Earthmoving;

  if (entityType === RefmodelTypes.DrillPattern
    || entityType === UnitTypes.DrillerType
    || entityType === UnitTypes.PilerType
    || entityType === PointTypes.StabilizationPointType
    || entityType === StabilizationTypes.DrillHole
    || entityType === StabilizationTypes.PileHole
    || entityType === DrillHoleSetType) {
    context = OperationContext.Stabilization;
  }
  return { ...entity, context };
};

export { withContext };