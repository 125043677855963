import histogramApi from '@/http/histogram.api';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const filterPointModule = {
  state: {
    isExportLoading: false
  },
  actions: {
    async fetchPointMetadataHistograms(context) {
      // fetch available metadata fields for measured point filtering panel
      try {
        const histograms = await histogramApi.getMetadataHistogram(context.rootGetters.projectUuid);
        context.dispatch('filterDrillHole/initializeFilters', histograms.stabilizationFields);
        context.dispatch('filterMeasuredPoint/initializeFilters', histograms.pointFields);
      } catch (e) {
        const translatedText =
          $gettext('An error occured during fetching metadata fields for measured point filters, please try again later');
        context.dispatch('notifications/error', { exception: e, message: translatedText }, { root: true });
      }
    }
  },
};

export default filterPointModule;