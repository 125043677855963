import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { RefModel } from '@/domain/refmodels/RefModel';
import { VisualizationSourceType } from '@/domain/refmodels/VisualizationSourceType.enum';
import { TerrainModel, CoordinateFile, RoadLine, Stringline, VolumeDescription, CrossFall, HelperFile, DrillPattern } from '@/domain/refmodels/File.types';

type CreateRefmodelParams = {
  uuid: string,
  name: string,
  path: string,
  createdAt: Date | null,
  updatedAt: Date | null,
  sourceType: string | null,
  sourceName: string | null,
  gltfSize: number | null,
  pointCount: number | null,
  lineCount: number | null,
  surfaceCount: number | null
}

const createDefaultRefmodel = (params: CreateRefmodelParams): RefModel => {
  return {
    ...params,
    entityType: RefmodelTypes.DefaultRefmodelType,
    visSourceType: VisualizationSourceType.Gltf,
  };
};

const createCoordinateFile = (params: CreateRefmodelParams): CoordinateFile => {
  return {
    ...params,
    entityType: RefmodelTypes.CoordinateFileType,
    visSourceType: VisualizationSourceType.Gltf,

  };
};

const createRoadLine = (params: CreateRefmodelParams): RoadLine => {
  return {
    ...params,
    entityType: RefmodelTypes.RoadLineType,
    visSourceType: VisualizationSourceType.Gltf,

  };
};

const createTerrainModel = (params: CreateRefmodelParams): TerrainModel => {
  return {
    ...params,
    entityType: RefmodelTypes.TerrainModelType,
    visSourceType: VisualizationSourceType.Gltf,

  };
};

const createStringlineModel = (params: CreateRefmodelParams): Stringline => {
  return {
    ...params,
    entityType: RefmodelTypes.StringlineModelType,
    visSourceType: VisualizationSourceType.Gltf,
  };
};

const createVolumeDescriptionModel = (params: CreateRefmodelParams): VolumeDescription => {
  return {
    ...params,
    entityType: RefmodelTypes.VolumeDescriptionModelType,
    visSourceType: VisualizationSourceType.Gltf,
  };
};

const createCrossFallModel = (params: CreateRefmodelParams): CrossFall => {
  return {
    ...params,
    entityType: RefmodelTypes.CrossFallModelType,
    visSourceType: VisualizationSourceType.Gltf,
  };
};

const createHelperFile = (params: CreateRefmodelParams & {resourceUrl: string | null}): HelperFile => {
  return {
    ...params,
    entityType: RefmodelTypes.HelperFileType,
    visSourceType: VisualizationSourceType.Url,
    resourceUrl: params.resourceUrl
  };
};

const createDrillPattern = (params: CreateRefmodelParams): DrillPattern => {
  return {
    ...params,
    entityType: RefmodelTypes.DrillPattern,
    visSourceType: VisualizationSourceType.DrillPatternJson,
  };
};


export {
  createDefaultRefmodel,
  createCoordinateFile,
  createRoadLine,
  createTerrainModel,
  createStringlineModel,
  createVolumeDescriptionModel,
  createCrossFallModel,
  createHelperFile,
  createDrillPattern
};