import { v4 as uuidv4 } from 'uuid';
import { AwarenessEvent } from '@/domain/awareness/AwarenessEvent';
import { AwarenessSetOriginFilters } from '@/domain/awareness/AwarenessSetOriginFilters';
import { OriginTimeRange } from '@/domain/OriginTimeRange';
import { AwarenessEventSet } from './AwarenessEventSet';
import { AwarenessEventSetType } from './AwarenessEventSetType';
import { gettext } from '@/translations/gettext.setup';

const { $ngettext } = gettext;

const awarenessEventSetFactory = ({
  count,
  totalCount,
  exceedsPerformanceLimit,
  events,
  originFilters,
  originTimeRange
}: {
  count: number,
  totalCount: number,
  exceedsPerformanceLimit: boolean,
  events: AwarenessEvent[],
  originFilters: AwarenessSetOriginFilters[],
  originTimeRange: OriginTimeRange,
}): AwarenessEventSet => {
  const defaultName = $ngettext('awareness event', 'awareness events', count);
  return {
    uuid: uuidv4(),
    name: `${count} ${defaultName}`,
    events,
    entityType: AwarenessEventSetType,
    count,
    totalCount,
    exceedsPerformanceLimit,
    originFilters,
    originTimeRange,
  };
};

export { awarenessEventSetFactory };
