<template>
  <div class="cx-dynamic-list">
    <cx-list
      :items="items"
      :selectedItems="selectedItems"
      :disabledItems="disabledItems"
      :loading="loading"
      :placeholder="placeholder"
      :disabled="disabled"
      :multiselect="multiselect"
      :header="header"
      @update:selectedItems="handleUpdate">
      <template #header-append><slot name="header-append"><div class="header__append"></div></slot></template>
      <template #item-panel="{isItemSelected, isItemDisabled, toggleSelection }">
        <div class="cx-dynamic-list__items">
          <DynamicScroller
            :items="items"
            :itemSize="itemSize"
            :minItemSize="itemSize">
            <template #default="{ item, active }">
              <DynamicScrollerItem :item="item" :active="active">
                <div @click="toggleSelection(item)">
                  <slot :item="item" :selected="isItemSelected(item.id)" :disabled="isItemDisabled(item.id)">
                    <cx-list-item
                      :item="item"
                      :key="item.id"
                      :selected="isItemSelected(item.id)"
                      :disabled="isItemDisabled(item.id)"
                      :tooltip="item.tooltip"
                      :css="itemCss"
                      :titleCssClass="itemTitleCssClass"
                      :subtitleCssClass="itemSubtitleCssClass"/>
                  </slot>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
          <slot name="items-append"></slot>
        </div>
      </template>
      <template #placeholder>
        <slot name="placeholder"></slot>
      </template>
    </cx-list>
  </div>
</template>

<script>
import CxList from '@/components/list/CxList';
import CxListItem from '@/components/list/CxListItem';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';

/**
  Renders list of items with virtualization support
  By default allow single selection
  Pass multiselect prop to turn on multi selection support
  Is based on CxList
*/
export default {
  name: 'CxDynamicList',
  components: {
    CxList,
    CxListItem,
    DynamicScroller,
    DynamicScrollerItem
  },
  props: {
    // items to display in the list
    // needs to be an object with at least id property { id }
    items: { validator(value) {
      if (!value || !Array.isArray(value)) return false;
      if (value.some(v => !v.id)) return false;
      return true;
    } },
    // set to true to allow select multiple items
    multiselect: { type: Boolean, default: false },
    // array of selected items
    selectedItems: { type: Array, default: () => { return []; } },
    // array of disabled items
    disabledItems: { type: Array, default: () => { return []; } },
    // size of the item that will be virtualized in the list
    itemSize: { type: Number, default: 37 },

    // flag for disabled state
    disabled: { type: Boolean },
    // text to display when list is empty
    placeholder: { type: String },
    // flag for loading state
    loading: { type: Boolean },
    // text to display in the header
    header: { type: String },

    // css classes for list item
    itemTitleCssClass: { type: String },
    itemSubtitleCssClass: { type: String },
    itemCss: { type: [String, Object] }
  },
  emits: ['update:selectedItems'],
  methods: {
    handleUpdate(selectedItems) {
      this.$emit('update:selectedItems', selectedItems);
    }
  },
};
</script>

<style lang="less">
@import '../../common';

.cx-dynamic-list {
  height: 100%;
  .cx-simple-list {
    height: 100%;
  }
 .cx-dynamic-list__items {
    overflow: scroll;
  }

}


</style>