<template>
  <div class="cx-chart-error">
    <div class="cx-chart-error__image"></div>
    <span class="cx-chart-error__text">{{ text }}</span>
  </div>
</template>

<script>

export default {
  name: 'CxChartError',
  props: {
    text: {
      type: String,
    },
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-chart-error {
  .flex(column, center, center);
  border: 1px solid @detailsNoDataBorderColor;
  border-radius: @inputBorderRadius;
  width: calc(100% - 20px);
  margin-bottom: 20px;
  flex: 1;

  .cx-chart-error__image {
    background-image: url('../../../../public/chart-error.svg');
    background-size: contain;
    opacity: 0.2;
    margin-bottom: 30px;
    .size(@detailsNoDataImgSize, @detailsNoDataImgSize);
  }

  .cx-chart-error__text {
    opacity: 0.3;
  }
}

</style>