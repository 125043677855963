<template>
  <div class="cx-tab-control">
    <!-- tabs title -->
    <div :class="setTitleClass">
      <div
        v-for="tab in renderTabs"
        :class="{ 'selected': tab.isSelected, [tab.titleClass]: !!tab.titleClass, 'cx-tab-title': !tab.titleClass }"
        :key="tab.id"
        @click="selectTab(tab.id)">
        <div class="flex-1" v-if="tab.title">{{ tab.title }}</div>
        <slot :name="`tab-title-slot-${tab.id}`" v-if="!tab.title"></slot>
      </div>
      <div class="cx-tab-title-tools" v-if="this.$slots['tab-title-slot-tools']">
        <slot name="tab-title-slot-tools"></slot>
      </div>
    </div>
    <!-- tab content -->
    <div class="cx-tab-content-panel">
      <div v-if="keepAlive" class="cx-tab-content-panel keepalive">
        <keep-alive>
          <component
            :class="{ [selectedTab.contentClass]: !!selectedTab.contentClass, 'cx-tab-content': !selectedTab.contentClass }"
            :is="selectedTab.component"
            v-bind="selectedTab.props"/>
        </keep-alive>
      </div>
      <div class="cx-tab-content-panel nokeepalive" v-if="!keepAlive">
        <component
          :class="{ [selectedTab.contentClass]: !!selectedTab.contentClass, 'cx-tab-content': !selectedTab.contentClass }"
          :is="selectedTab.component"
          v-bind="selectedTab.props"/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
/**
 *  Tabbed Content Control
 */
export default {
  name: 'CxTabControl',
  components: {
  },
  data() {
    return {
      dataSelectedTabId: this.selectedTabId || _.get(this.tabs[0], 'id')
    };
  },
  props: {
    /**
     * [{
        id, // Required. Id of the tab, needs to be uniqe per application.
        title, // Title of the tab. If not provided, slot named tab-title-slot-{tab.id} will be rendered, allowing user to put own html
        component, // Required. Name of the component that will be rendered when tab selected. Remember to declare your component globally to be able to display it!
        props, // props to pass to currently selected component.
        titleClass, // css class will be applied to the rendered title instead of the common style - see cx-tab-title.
        contentClass // css class will be applied to the rendered content instead of the common style - see cx-tab-content.
      }]
    */
    tabs: { type: Array, required: true },
    // if not set, first tab will be selected
    selectedTabId: String,
    // fired when selected tab changes
    onTabSelected: Function,
    // if true - tab content will be kept in the app memory
    keepAlive: { type: Boolean, default: false }
  },
  computed: {
    selectedTab: {
      get() { return this.tabs.find(t => t.id === this.dataSelectedTabId); },
      set(tabId) { this.dataSelectedTabId = tabId; }
    },
    renderTabs() {
      return this.tabs.map(t => ({ ...t, isSelected: t.id === this.selectedTab.id, titleClass: t.titleClass || '', contentClass: t.contentClass || '' }));
    },
    setTitleClass() {
      if (this.tabs.length < 2) {
        return 'cx-tab-title-panel cx-tab-title-panel--single';
      }
      return 'cx-tab-title-panel';
    }
  },
  methods: {
    selectTab(tabId) {
      this.selectedTab = tabId;
      if (this.onTabSelected) this.onTabSelected(this.selectedTab);
    }
  },
};
</script>

<style lang="less">
@import '../common';

@titleBgColor: lighten(rgb(37, 42, 53), 1%);
@titleHoverColor: @objectExplorerItemHoverBGColor;

.cx-tab-control {
  width: 100%;
  height: auto;

  .cx-tab-title-panel {
    width: 100%;
    display: flex;
    flex: 1;

    .flex-1 {
      flex: 1 1 auto;
    }

    .cx-tab-title-tools {
      margin: auto;
      margin-right: 30px;
      font-size: 12px;
    }

    .cx-tab-title {
      text-align: center;
      display: flex;
      align-content: center;
      align-items: center;
      font-size: 20px;
      color: @textColorLowlight;
      width: 100%;
      padding-bottom: 5px;
      border-bottom: 3px solid transparent;

      &.selected {
        color: @textColor;
        border-bottom: 3px solid @textColor;
      }

      &:hover {
         cursor: pointer;
      }
    }

    &.cx-tab-title-panel--single {
        .cx-tab-title {
          width: auto;
          text-align: start;

          &.selected {
            color: @textColorLowlight;
            border-bottom: none;
            padding-bottom: 3px;
          }

          &:hover {
            cursor: default;
          }
        }
    }
  }

  .cx-tab-content-panel {
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-size: 12px;
      color: @textColor;

    .cx-tab-content {
      width: 100%;
      background-color: @baseBackgroundColor;
      height: 100%;
    }
  }

}

</style>