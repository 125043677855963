import * as Cesium from 'cesium';
import { CUT_FILL_TOLERANCE } from '@/utils/constants';

const mapConstants = {
  shaders: {
    contour: 'useContour',
    heightMap: 'useHeightMap',
    slopeMap: 'useSlopeMap'
  },
  shaderDefault: {
    surfaceColor: [0.0, 0.5, 0.0, 1.0],
    pointsColor: [0.0, 0.0, 1.0, 1.0],
    linesColor: [0.0, 0.0, 1.0, 1.0],
    useContour: false,
    contourDefault: {
      tolerance: 0.2,
      rampValue: 0.05,
      stepSize: 1.0,
      contourColor: [1.0, 1.0, 1.0, 1, 0]
    }
  },
  cutFillDefault: {
    tolerance: CUT_FILL_TOLERANCE,
    gradeColor: [0.356, 0.831, 0.6, 1.0],
    cutColor: [0.9607, 0.384, 0.384, 1.0],
    fillColor: [0.176, 0.611, 0.811, 1.0]
  },
  /**
   * @property {Number} cameraPercentageChanged The percentage of map camera change
   * needed to raise the @event change of the camera. The value is defined inside of [0, 1] range.
   */
  cameraPercentageChanged: 0,
  /**
   * @property {Number} cameraDefaultHeight The height [meters] that camera will be raised against the terrain
   * after reorienting it towards North cardinal direction.
   */
  cameraDefaultHeight: 250,
  /**
   *
   * @property {Object} cameraFlyToDefaultOptions Default options used by Cesium camera to fly to given destination
   */
  cameraFlyToDefaultOptions: {
    duration: 0.35,
    easingFunction: Cesium.EasingFunction.LINEAR_NONE
  },
};

export default mapConstants;