import { parseISO } from 'date-fns';
import { PointSet } from '@/domain/pointSet/PointSet';


export class BarChartVolume {
    uuid: string
    timestamp: Date
    pointSet: PointSet
    cutVolume: number
    fillVolume: number
    error: string | null

    constructor(uuid: string, timestamp: string, pointSet: PointSet, cutVolume: number, fillVolume: number, error: string | null = null) {
      this.uuid = uuid;
      this.timestamp = parseISO(timestamp);
      this.pointSet = pointSet;
      this.cutVolume = cutVolume;
      this.fillVolume = fillVolume;
      this.error = error;
    }

    // both cut and fill are 0
    get isPlottable() {
      return !!this.cutVolume || !!this.fillVolume;
    }
    // error instead of data
    get isErrorous() {
      return !!this.error;
    }
    // no points in point Set
    get isEmpty() {
      return !this.pointSet || this.pointSet.points.length < 1;
    }
}
