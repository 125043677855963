<template>
  <div
    class="cx-selector-warning"
    v-if="active"
    :style="style"
    v-cx-tooltip="tooltip"
    @mouseenter="isTooltip=true"
    @mouseleave="isTooltip=false">
    <cx-icon icon="priority_high"/>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxSelectorWarning',
  components: {
    CxIcon
  },
  props: {
    active: Boolean,
    zIndex: String,
    top: String,
    right: String,
    bottom: String,
    left: String,
    tooltipText: String
  },
  data() {
    return {
      isTooltip: false
    };
  },
  computed: {
    style() {
      const { position, zIndex } = this;
      const { top, right, bottom, left } = position;
      return { top, right, bottom, left, zIndex };
    },
    position() {
      const { top, right, bottom, left } = this;
      return {
        top: top ? `${top}px` : 'none',
        right: right ? `${right}px` : 'none',
        bottom: bottom ? `${bottom}px` : 'none',
        left: left ? `${left}px` : 'none'
      };
    },
    tooltip() {
      return {
        text: this.tooltipText,
        show: this.isTooltip,
        position: 'top-right'
      };
    }
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-selector-warning {
  position: absolute;
  .size(@cxWarningSize, @cxWarningSize);
  background-color: @highlightActiveColor;
  border-radius: @inputBorderRadius;
  .flex(column, center, center);
}
</style>