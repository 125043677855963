import { convert } from '@/utils/measurementUnitHelper';

const getModelAttributeBoundingBox = (gltf) => {
  const attributeName = 'TEXCOORD_0';
  const arrayAccessorBoundingBox = [];
  if (!gltf.meshes) return null;
  // For each mesh we get the accessor's index holding the attributename
  gltf.meshes.forEach((mesh) => {
    if (mesh.primitives[0].attributes[attributeName] !== undefined) { // we only support one primitive
      const indexAttribute = mesh.primitives[0].attributes[attributeName];
      arrayAccessorBoundingBox.push(indexAttribute);
    }
  });
  // We calculate the merged bounding box of all accessors
  if (!arrayAccessorBoundingBox.length) return null;
  const firstIndex = arrayAccessorBoundingBox[0];
  const boundingBoxes = {
    min: gltf.accessors[firstIndex].min,
    max: gltf.accessors[firstIndex].max
  };
  arrayAccessorBoundingBox.shift();
  arrayAccessorBoundingBox.forEach((accessorIndex) => {
    const minBB = gltf.accessors[accessorIndex].min;
    const maxBB = gltf.accessors[accessorIndex].max;
    let index;
    for (index = 0; index < minBB.length; index += 1) {
      boundingBoxes.min[index] = Math.min(minBB[index], boundingBoxes.min[index]);
      boundingBoxes.max[index] = Math.max(maxBB[index], boundingBoxes.max[index]);
    }
  });
  return boundingBoxes;
};

/**
 * Calculate min max local height to be able to normalize height in shaders
 * @param gltf
 * @returns {[undefined, undefined]|null}
 */
const getMinMaxHeight = (gltf) => {
  try {
    const boundingBoxes = getModelAttributeBoundingBox(gltf);
    if (!boundingBoxes) return null;
    return [boundingBoxes.min[0], boundingBoxes.max[0]];
  } catch (e) {
    return null;
  }
};

const withMinMaxHeight = (entity, gltf) => {
  const minMaxHeight = getMinMaxHeight(gltf);
  const convertMinMaxHeight = (unit) => minMaxHeight.map(x => convert(x).from('m').to(unit));
  return { ...entity, minMaxHeight, convertMinMaxHeight };
};

export { withMinMaxHeight };
