<template>
  <div
    class="cx-option"
    :class="{ active: isActive }"
    @click="onSelect">
    <cx-checkbox
      :checked="isSelected"
      @update:checked="(value) => $emit('update:isSelected', value)"/>
    <div class="cx-option__label">
      <label class="cx-option__label__text" :for="item.name">{{ item.name }}</label>
      <span class="cx-option__label__subtitle" v-if="item.subtitle">{{ item.subtitle }}</span>
    </div>
  </div>
</template>

<script>
import CxCheckbox from '@/components/CxCheckbox';

export default {
  name: 'CxOption',
  components: {
    CxCheckbox
  },
  props: {
    item: Object,
    label: String,
    isSelected: Boolean,
    isActive: Boolean,
    input: Function,
  },
  emits: ['update:isSelected'],
  methods: {
    onSelect() {
      this.input({ id: this.item.id, isChipSelected: !this.isSelected });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-option {
  .size(100%, @cxOptionSize);
  .flex(row, flex-start, center);
  border-radius: @inputBorderRadius;
  padding-right: 15px;
  cursor: pointer;

  .cx-checkbox {
    width: @cxOptionCheckboxWidth;
    pointer-events: none;
    padding-left: 5px;
    display: flex;
    font-size: @appFontSize;
    padding-bottom: 2px;
  }

  .cx-option__label {
    .flex(column, center, flex-start);
    padding-left: 15px;

    .cx-option__label__text {
      color: @textColor;
      cursor: pointer;
      .flex(row, flex-start, center);
    }

    .cx-option__label__subtitle {
      color: @textColorDisabled;
      font-size: 10px;
    }
  }

  &:hover, &.active {
    background-color: @inputBackgroundHover;
  }
}
</style>