<template>
  <div
    class="cx-badge"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    v-cx-tooltip="applyTooltip">
    <cx-icon :icon="icon" v-if="icon" :size="iconSize"/>
    <span class="cx-badge__caption">{{ text }}</span>
    <slot></slot>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxBadge',
  components: {
    CxIcon
  },
  data() {
    return {
      isHovered: false,
    };
  },
  props: {
    text: String,
    icon: String,
    iconSize: {
      type: Number,
      default: 15
    },
    tooltip: String,
  },
  computed: {
    applyTooltip() {
      return {
        text: this.tooltip,
        show: this.isHovered && !!this.tooltip
      };
    },
  },
};
</script>

<style lang="less" scoped>
  @import '../common';

  .cx-badge {
    .flex(row, space-around, center);
    color: @textColorDisabled;
    font-size: @appFontSize;
    border-radius: @inputBorderRadius;
    cursor: default;
  }
</style>