<template>
  <div class="cx-ref-model-name-filters">
    <cx-combobox
      :items="items"
      :value="selectedItems"
      :input="input"
      :label="placeholder"
      isChips
      isTextInput
      isDropdown
      isIcon
      isTooltip
      ref="chips"/>
  </div>
</template>

<script>
import CxCombobox from '@/components/cxCombobox/CxCombobox';

export default {
  name: 'CxRefModelNameFilters',
  components: {
    CxCombobox,
  },
  props: {
    items: Array,
    input: Function,
  },
  computed: {
    selectedItems() {
      return this.items
        .filter(i => i.isChipSelected)
        .map(i => i.id);
    },
    placeholder() {
      return this.$gettext('Reference Model');
    }
  },
  methods: {
    /**
     * Clears combobox/chips textbox.
     */
    clearChipsInput() {
      this.$refs.chips.onReset();
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-ref-model-name-filters {
  .cx-combobox {
    padding: 10px 0;

    .cx-options {
      max-height: 65vh;
    }
  }
}
</style>