import detailsUnitMessageModule from './detailsUnit.message.store';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';

const detailsUnitModule = {
  state: {
    ...detailsUnitMessageModule.state,
    analysisTabs: null,
    // analysis tab that is currently active
    activeAnalysisTab: null
  },
  getters: {
    ...detailsUnitMessageModule.getters,
    availableAnalysisTabs(state) {
      return state.analysisTabs;
    },
    activeAnalysisTab(state) {
      return state.activeAnalysisTab;
    },
    selectedUnit(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (selectedEntity && Object.values(UnitTypes).includes(selectedEntity.entityType)) {
        return selectedEntity;
      }
      return null;
    }
  },
  mutations: {
    ...detailsUnitMessageModule.mutations,
    setActiveAnalysisTab(state, payload) {
      state.activeAnalysisTab = payload;
    }
  },
  actions: {
    ...detailsUnitMessageModule.actions,
    async handleCameraFlyToUnit(context) {
      if (!context.getters.selectedUnit) return;
      await context.dispatch('map/setCameraToEntity', context.getters.selectedUnit.uuid, { root: true });
    }
  },

};

export default detailsUnitModule;