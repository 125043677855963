<template>
  <div class="cx-cumulative-metrics">
    <cx-metrics-panel>
      <template #title>
        {{ cutFillTitle }}
      </template>
      <template #subtitle>
        {{ selectedVolumeMetrics.timestamp }}
      </template>
      <template #content>
        <div class="cx-cumulative-metrics__content__volumes__container">
          <div class="container-left">
            <!-- cut chart -->
            <div class="chart-row">
              <cx-pie-chart
                :plotData="pieChartSeries"
                seriesClass="series__cut"
                type="cut"/>
              <div class="grid-item chart-volume" @mouseover="showCutTooltip=true" @mouseleave="showCutTooltip=false">
                <div class="volume__title" v-translate>Cut Volume</div>
                <cx-display-number
                  :value="selectedVolumeMetrics.cutVolume"
                  :className="{'volume__volume': true, 'volume__volume--cut': true, 'downsize-font': downsizeFont}"/>
              </div>
            </div>
            <!-- fill chart -->
            <div class="chart-row">
              <cx-pie-chart
                :plotData="pieChartSeries"
                seriesClass="series__fill"
                type="fill"/>
              <div class="grid-item chart-volume" @mouseover="showFillTooltip=true" @mouseleave="showFillTooltip=false">
                <div class="volume__title" v-translate>Fill Volume</div>
                <cx-display-number
                  :value="selectedVolumeMetrics.fillVolume"
                  :className="{'volume__volume': true, 'volume__volume--fill': true, 'downsize-font': downsizeFont}"/>
              </div>
            </div>
          </div>
          <div class="container-right">
            <div class="grid-item grid-item--separator"></div>
            <div class="grid-item grid-item--sidebar" @mouseover="showRemainingTooltip=true" @mouseleave="showRemainingTooltip=false">
              <div class="volume__title" v-translate>Remaining</div>
              <cx-display-number
                :value="selectedVolumeMetrics.remainingVolume"
                :className="{'volume__volume': true, 'downsize-font': downsizeFont}"/>
            </div>
          </div>
        </div>
      </template>
      <div>
        <slot name="footer">
          <cx-total
            :left="totalsData.left"
            :right="totalsData.right">
            <template #unit_lef><cx-measure-unit v-if="displayUnitLeft"/></template>
            <template #unit_right><cx-measure-unit/></template>
            <span v-translate><slot name="left_lower">Complete/Total</slot></span>
            <span v-translate><slot name="right_lower">Net Volume</slot></span>
          </cx-total>
        </slot>
      </div>
    </cx-metrics-panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CxTotal from './components/CxTotal';
import CxPieChart from './components/pieChart/CxPieChart.vue';
import CxDisplayNumber from './components/CxDisplayNumber';
import CxMeasureUnit from './components/CxMeasureUnit';
import CxMetricsPanel from './CxMetricsPanel';

const maxCharactersNumner = 14;

export default {
  name: 'CxCumulativeMetrics',
  data: () => {
    return {
      showFillTooltip: false,
      showCutTooltip: false,
      showRemainingTooltip: false
    };
  },
  components: {
    CxPieChart,
    CxMetricsPanel,
    CxTotal,
    CxDisplayNumber,
    CxMeasureUnit
  },
  computed: {
    ...mapGetters('detailsRefmodel', ['pieChartSeries', 'selectedVolumeMetrics']),
    downsizeFont() {
      const maxCutFillDigits = Math.max(
        Math.round(this.selectedVolumeMetrics.fillVolume).toString().length,
        Math.round(this.selectedVolumeMetrics.cutVolume).toString().length
      );
      const remainingDigits = Math.round(this.selectedVolumeMetrics.remainingVolume).toString().length;
      if (maxCutFillDigits + remainingDigits > maxCharactersNumner) return true;
      return false;
    },
    cutFillTitle() {
      return this.$gettext('Cut & Fill');
    },
    totalsData() {
      const { totalComplete, totalTarget, netVolume } = this.selectedVolumeMetrics;
      const complete = totalComplete;
      const target = totalTarget;
      const net = netVolume;
      return {
        left: [complete, target],
        right: net
      };
    },
    displayUnitLeft() {
      const { totalComplete, totalTarget } = this.selectedVolumeMetrics;
      return totalComplete || totalTarget;
    }
  },
};
</script>

  <style lang="less">
    @import '../../../common';

    .cx-cumulative-metrics__content__volumes__container {
    margin-top: 5px;
    display: flex;

    .container-left {
    flex: 1 1 auto;
    max-width: @detailsCumulativeMetricsContainerMaxWidth;
    padding-right: 5px;

    .chart-row {
    display: flex;
    .cx-pie-chart {
    flex: 1 1 auto;
    }
    .chart-volume {
    flex: 0 1 auto;
    padding-left: 10px;
    }
    }
    .chart-row:nth-child(1) {
    margin-bottom: @inputSpace;
    }
    .chart-row:nth-child(2) {
    margin-top: @inputSpace;
    }
    }
    .container-right {
    flex: 1 1 auto;
    max-width: @detailsCumulativeMetricsContainerMaxWidth;
    display: flex;

    }

    .grid-item {
    font-size: @appFontSize;
    color: @textColor;
    place-self: center;

    &--sidebar {
    place-self: center;
    justify-self: right;
    margin-bottom: 3px;
    }

    &--separator {
    background-color: @textColorDisabled;
    flex: 0 0 1px;
    place-self: center;
    .size(@detailsRefModelMetricsSeparatorWidth, 100%);
    margin: 0 15px 0 0;
    }

    &--separator:after {
    color: @textColorDisabled;
    content: '\25B8';
    position: relative;
    top: 40%;
    font-size: 20px;
    font-weight: bold;
    margin-left: -4px;
    }

    .volume__title {
    //margin-top: 10px;
    color: @textColorDisabled;
    }

    .volume__volume {
    font-size: @h3FontSize;
    color: @textColorHighlight;

    .volume__volume--unit {
    opacity: 0.6;
    //font-weight: @headerFontWeight;
    }
    }

    .downsize-font {
    font-size: @majorFontSize;
    }

    .volume__volume--cut {
    color: @cutColor;
    }

    .volume__volume--fill {
    color: @fillColor;
    }

    .volume__details {
    margin-top: 3px;
    }

    }
    }

    .cx-cumulative-metrics__content__volumes__label {
    color: @textColor;
    }

    .cx-cumulative-metrics__content__volumes__net {
    background-color: @detailsCumulativeMetricsNetBGColor;
    width: @detailsCumulativeMetricsNetWidth;
    padding: 8px;
    margin-top: 10px;
    border-radius: 2px;
    color: @textColor;
    text-align: center;
    font-size: @h3FontSize;
    }

    .cx-analysis__panel--spinner {
    margin: 100px auto auto;
    }
  </style>