import { AwarenessEvent } from '@/domain/awareness/AwarenessEvent';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { AwarenessEventValue } from './enums/AwarenessEventValue.enum';
import { DetectedZoneFilterDisplayValue } from './enums/DetectedZoneFilterDisplayValue.enum';

const awarenessEventFactory = ({
  uuid,
  name,
  value,
  timestamp,
  detectedZone,
  longitude,
  latitude,
  height,
  locationType,
  unitName,
  unitType,
  position,
  distance,
  anchorId,
  opponentId,
  equipmentId
}: {
  uuid: string,
  name?: string,
  value?: AwarenessEventValue | null,
  timestamp?: Date | null,
  detectedZone?: DetectedZoneFilterDisplayValue,
  longitude?: number | null,
  latitude?: number | null,
  height?: number | null,
  locationType?: string | null,
  unitName?: string | null,
  unitType: UnitTypes,
  position?: string | null,
  distance?: number | null,
  anchorId?: number | null,
  opponentId?: number | null,
  equipmentId?: number | null
}): AwarenessEvent => {
  return {
    uuid,
    entityType: AwarenessEventType,
    name,
    value: value || null,
    timestamp: timestamp || null,
    detectedZone,
    longitude: longitude || null,
    latitude: latitude || null,
    height: height || null,
    locationType: locationType || null,
    unitName: unitName || null,
    unitType,
    position: position || null,
    distance: distance || null,
    anchorId: anchorId || null,
    opponentId: opponentId || null,
    equipmentId: equipmentId || null
  };
};

export { awarenessEventFactory };