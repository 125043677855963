<template>
  <div
    class="cx-object-explorer-control"
    :class="{ active: active, disabled:disabled }"
    @click="toggle">
    <div
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
      v-cx-tooltip="{
        text: tooltipTitle,
        show: tooltip,
        offsetLeft: 7,
        offsetTop: -10
      }"
      class="cxu-center-v">
      <cx-toggle
        icon="mdi-eye"
        :iconSize="19"
        :disabled="disabled"
        :active="active"
        :loading="areMapObjectsLoading"
        variantSecondary
        class="cx-object-explorer-control__cxToggle"/>
    </div>
  </div>
</template>

<script>
import CxToggle from '@/components/CxToggle';
import { mapGetters } from 'vuex';

export default {
  name: 'CxObjectExplorerControl',

  components: {
    CxToggle
  },

  props: {
    active: Boolean,
    disabled: Boolean,
    toggle: Function
  },

  data() {
    return {
      tooltip: false,
    };
  },
  computed: {
    ...mapGetters('map', ['areMapObjectsLoading']),
    tooltipTitle() {
      return this.areMapObjectsLoading ? this.$gettext('Object Explorer (loading objects...)') : this.$gettext('Object Explorer');
    }
  },

  methods: {
    showTooltip() {
      this.tooltip = true;
    },

    hideTooltip() {
      this.tooltip = false;
    },
  },
};
</script>

<style lang="less">
@import '../../common';

.cx-object-explorer-control {
  cursor: pointer;
  pointer-events: auto;
  .size(@inputHeight, @inputHeight);
  border-radius: @inputBorderRadius;
  background-color: @objectExplorerClosedBGColor;
  background-size: contain;
  .flex(row, center, center);

  &.disabled {
    cursor: default;
  }

  .cx-object-explorer-control__cxToggle {
    margin-bottom: 1px;
  }
}
</style>