import storage from '../../utils/localStorage';
import { saveStateMapping } from './save.state.mapping';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const mapStateKeyV1 = (projectUuid, version) => {
  if (!projectUuid || !version) throw new Error('Can\'t save app state - projectUuid and version are required');
  return `cx:mapState:v1:${projectUuid}`;
};

const mapStateKey = (projectUuid) => {
  if (!projectUuid) throw new Error('Can\'t save app state - projectUuid and version are required');
  return `cx:mapState:${projectUuid}`;
};


const saveStateService = {
  storeAppState: (projectUuid, appData, version = null) => {
    try {
      const appStateVersion = version || process.env.VUE_APP_STATE_VERSION;
      storage.storeItem(mapStateKey(projectUuid), { ...appData, appStateVersion });
    } catch {
      throw new Error($gettext('Unable to save application state - make sure browser local storage is enabled or contact customer support.'));
    }
  },

  restoreAppState: (projectUuid, version = null) => {
    try {
      const appStateVersion = version || process.env.VUE_APP_STATE_VERSION;
      const key = mapStateKey(projectUuid);
      const v1Key = mapStateKeyV1(projectUuid, appStateVersion);
      let mapState = null;
      if (storage.checkItem(key)) {
        mapState = storage.restoreItem(key);
      } else if (storage.checkItem(v1Key)) {
        // map v1->v2
        mapState = saveStateMapping.mapStateVersion(storage.restoreItem(v1Key));
        // remove v1 key from local storage
        storage.removeItem(v1Key);
      }
      return mapState;
    } catch {
      throw new Error($gettext('Unable to restore application state - make sure browser local storage is enabled or contact customer support.'));
    }
  },
};

export default saveStateService;