<template>
  <transition :name="applyAnimations('drawer')">
    <div class="cx-filter-panel" :class="applyClass" v-if="isFilterPanelOpened">
      <component
        class="cx-filter-panel__view"
        :is="filterPanelContent"/>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { filterPanelTypes } from '@/store/shellModule/shell.store';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxFilterUnitView from '@/app/filterPanel/unit/CxFilterUnitView';
import CxFilterRefModelView from '@/app/filterPanel/refmodel/CxFilterRefModelView';
import CxAsBuiltFilterContainer from '@/app/filterPanel/asbuilts/CxAsBuiltFilterContainer';
import CxFilterAwarenessEventView from '@/app/filterPanel/awarenessEvents/CxFilterAwarenessEventView';

export default {
  name: 'CxFilterPanel',
  components: {
    CxFilterUnitView,
    CxFilterRefModelView,
    CxAsBuiltFilterContainer,
    CxFilterAwarenessEventView,
  },
  mixins: [CxAnimationMixin],
  computed: {
    ...mapGetters('shell', ['filterPanelContent', 'isFilterPanelOpened']),
    applyClass() {
      if (this.isFilterPanelOpened) {
        if (this.filterPanelContent && this.filterPanelContent === filterPanelTypes.dashboard) return 'cx-filter-panel--small';
        return 'cx-filter-panel--big';
      }
      return '';
    },
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-filter-panel {
  position: relative;
  background-color: @filterDetailsBGColor;
  z-index: @filterPanelZI;
  margin-left: @filterMenuWidth;
  height: 100vh;
  padding: 10px 0 0 0;

  .cx-filter-panel__view {
    // TODO: move common filter view styles here
    display: flex;
    flex-direction: column;

    .cx-filter-action-panel {
      position: absolute;
      bottom: 30px;
      left: 55px;
      width: 80%;
    }
  }

  &.cx-filter-panel--small {
    width: @filterPanelCompactedWidth;
  }

  &.cx-filter-panel--big {
    width: @filterPanelFullWidth;
    padding: 10px 0 0 15px;
  }
}
</style>