<template>
  <div class="cx-filter-action-panel">
    <cx-button
      v-if="isClearFiltersVisible"
      class="cx-filter-action-panel__button"
      variantSecondary
      @click="onClearFiltersClicked">
      {{ clearFitlersText }}
    </cx-button>
    <slot></slot>
    <cx-button
      class="cx-filter-action-panel__button"
      variantPrimary
      :loading="queryOngoing"
      :disabled="isDisabled"
      :tooltip="applyTooltip"
      @click="onApplyClicked">
      {{ applyText ? applyText : applyTextDefault }}
    </cx-button>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';

export default {
  name: 'CxFilterActionPanel',
  components: {
    CxButton
  },
  props: {
    onClearFiltersClicked: Function,
    onApplyClicked: Function,
    onSaveAsClicked: Function,
    isDisabled: Boolean,
    queryOngoing: Boolean,
    isClearFiltersVisible: {
      type: Boolean,
      default: true
    },
    applyText: String,
    applyTooltip: String
  },
  computed: {
    clearFitlersText() {
      return this.$gettext('Clear Filters');
    },
    clearMapText() {
      return this.$gettext('Clear Map');
    },
    applyTextDefault() {
      return this.$gettext('Apply');
    },
  }
};
</script>

<style lang="less" scoped>
@import '../../../common';

.cx-filter-action-panel {
  .flex(row, space-between, center);
  padding: 15px;
  background-color: @filterActionPanelBGColor;
  border-radius: @inputBorderRadius;

  .cx-filter-action-panel__button {
    text-align: center;
    margin: 5px;
  }
}
</style>