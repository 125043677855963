/**
 * This makes a promise that you can resolve or reject from the outside. Very useful.
 * @returns {Promise}
 */
export const defer = () => {
  let res;
  let rej;
  const promise = new Promise((resolve, reject) => { res = resolve; rej = reject; });
  promise.resolve = res;
  promise.reject = rej;
  return promise;
};
