// all entity types treated like a default refmodel
export enum RefmodelTypes {
  DefaultRefmodelType = 'defaultRefmodel',
  HelperFileType = 'helperFile',
  CoordinateFileType = 'coordinateFile',
  TerrainModelType = 'terrainModel',
  RoadLineType = 'roadLine',
  StringlineModelType = 'stringlineModel',
  VolumeDescriptionModelType = 'volumeDescriptionModel',
  CrossFallModelType = 'crossFallModel',
  DrillPattern = 'drillPattern'
}