import { enGB } from 'date-fns/locale';
import {
  toDate, format as dfFormat,
  startOfHour, startOfDay, startOfMonth, startOfYear,
  addHours, addDays, addMonths, addYears,
  subSeconds, subHours, subDays, subMonths, subYears,
  differenceInHours, differenceInDays, differenceInMonths, differenceInYears,
  parseISO, isValid, startOfWeek, subWeeks, endOfWeek, endOfMonth, endOfDay
} from 'date-fns';

const LOCALE_DATE_FORMAT = 'P';
const LOCALE_DATE_TIME_FORMAT = 'P p';
const LOCALE_TIME_FORMAT = 'p';

/**
 * Formats dates using date-fns.format using enGB as locale
 * @param {Date|Number} date
 * @param {String} formatString
 */
const format = (date, formatString) => dfFormat(date, formatString, { locale: enGB });

/**
 * Helper method that will generate time intervals between startDate and stopDate
 * @param {Date} startDate
 * @param {Date} stopDate
 * @param {Number} delta interval value
 * @param {String} unit interval unit: ['seconds','minutes','days','months','years']
 * @param {Number} slots maximum number of intervals that we want to generate
 */
const _generateIntervals = (startDate, stopDate, delta, unit, slots) => {
  const intervals = [];
  // tweaked for ITS-4827
  let date = subSeconds(toDate(stopDate), 1);
  const subs = {
    hours: subHours,
    days: subDays,
    months: subMonths,
    years: subYears
  };
  while (intervals.length <= slots && date > startDate) {
    intervals.push(toDate(date));
    date = subs[unit](date, delta);
  }
  return intervals.reverse();
};

const calculateTimeIntervals = (endTime, startTime, slots = 33, include = true) => {
  const start = toDate(startTime);
  const end = toDate(endTime);

  const hourStart = startOfHour(start);
  const hourEnd = startOfHour(addHours(end, 1));
  const hourDiff = differenceInHours(hourEnd, hourStart) + include; // we want to include the range
  if (hourDiff <= slots) {
    return {
      intervals: _generateIntervals(hourStart, hourEnd, 1, 'hours', hourDiff),
      unit: 'hours'
    };
  }

  const dayStart = startOfDay(start);
  const dayEnd = addDays(startOfDay(end), 1);
  const dayDiff = differenceInDays(dayEnd, dayStart) + include;
  if (dayDiff <= slots) {
    return {
      intervals: _generateIntervals(dayStart, dayEnd, 1, 'days', dayDiff),
      unit: 'days'
    };
  }

  const monthStart = startOfMonth(start);
  const monthEnd = addMonths(startOfMonth(end), 1);
  const monthDiff = differenceInMonths(monthEnd, monthStart) + include;
  if (monthDiff <= slots) {
    return {
      intervals: _generateIntervals(monthStart, monthEnd, 1, 'months', monthDiff),
      unit: 'months'
    };
  }

  const yearStart = startOfYear(start);
  const yearEnd = addYears(startOfYear(end), 1);
  const yearDiff = differenceInYears(yearEnd, yearStart) + include;
  return {
    intervals: _generateIntervals(yearStart, yearEnd, 1, 'years', yearDiff),
    unit: 'years'
  };
};

const mapDate = (isoDate) => {
  if (!isoDate) return null;
  const result = parseISO(isoDate);
  if (!isValid(result)) return null;
  return result;
};

const getYesterdayTimeRange = (baseDate = new Date()) => {
  return {
    from: startOfDay(baseDate),
    to: endOfDay(baseDate)
  };
};

const getLastWeekTimeRange = (baseDate = new Date()) => {
  return {
    from: subWeeks(startOfWeek(baseDate, { weekStartsOn: 1 }), 1),
    to: subWeeks(endOfWeek(baseDate, { weekStartsOn: 1 }), 1)
  };
};

const getLastMonthTimeRange = (baseDate = new Date()) => {
  return {
    from: startOfMonth(subMonths(baseDate, 1)),
    to: endOfMonth(subMonths(baseDate, 1))
  };
};

const getThisMonthTimeRange = (baseDate = new Date()) => {
  return {
    from: startOfMonth(baseDate),
    to: endOfDay(baseDate)
  };
};

const datetimehelper = {
  format,
  mapDate,
  calculateTimeIntervals,
  getYesterdayTimeRange,
  getLastWeekTimeRange,
  getLastMonthTimeRange,
  getThisMonthTimeRange,
  LOCALE_DATE_FORMAT,
  LOCALE_TIME_FORMAT,
  LOCALE_DATE_TIME_FORMAT,
};

export default datetimehelper;
