import * as d3 from 'd3';

export default class SvgPieChart {

  constructor(elementId, size, margin, radius, innerRadius, seriesClass, type) {
    this.size = { ...size };
    this.margin = { ...margin };
    this.elementId = elementId;
    this.radius = radius;
    this.innerRadius = innerRadius;
    this.seriesClass = seriesClass;
    this.type = type;
    this.createChart();
  }

  setTextOffset(d) {
    const number = parseInt(d);
    if (number < 10) return 6;
    if (number < 100) return 0;
    return -10;
  }

  createChart() {
    const svg = d3.select(this.elementId)
      .append('svg')
      .attr('width', this.size.width)
      .attr('height', this.size.height)
      .append('g')
      .attr('class', this.seriesClass)
      .attr('transform', `translate(${this.radius},${this.radius})`);
    this.chartArea = svg;
    const arcs = d3.arc()
      .outerRadius(this.radius)
      .innerRadius(this.innerRadius);
    this.arcs = arcs;
  }

  updateData(data) {
    this.data = data;
    this.percentage = this.data.filter(m => m.label === this.type).map(d => `${d.value}%`);
    const pieAngles = d3.pie()
      .sort(null)
      .value((d) => { return d.value; })(this.data);
    this.pieAngles = pieAngles;
  }

  render() {
    const pie = this.chartArea.selectAll('.slice').data(this.pieAngles);
    pie.enter()
      .append('g')
      .attr('class', (d, i) => { return `slice slice_${i}`; })
      .append('path')
      .attr('class', 'pie_arc')
      .transition()
      .duration(700)
      .attrTween('d', (d) => {
        const interpolation = d3.interpolate(0, d.endAngle);
        return (t) => {
          d.endAngle = interpolation(t);
          return this.arcs(d);
        };
      });

    pie.select('.pie_arc')
      .transition()
      .duration(700)
      .attrTween('d', (d) => {
        const interpolation = d3.interpolate(0, d.endAngle);
        return (t) => {
          d.endAngle = interpolation(t);
          return this.arcs(d);
        };
      });

    pie.exit().remove();

    const percentTextElement = this.chartArea.selectAll('.percentage--text').data(this.percentage);
    percentTextElement.enter()
      .append('text')
      .attr('class', 'percentage--text')
      .attr('x', (d) => { return this.setTextOffset(d); })
      .attr('y', 0)
      .attr('dy', '0.36em')
      .attr('dx', '-0.8em') // font-size??
      .text(d => d);
    percentTextElement
      .text(d => d)
      .attr('x', (d) => { return this.setTextOffset(d); });
    percentTextElement.exit().remove();
  }

}