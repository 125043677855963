<template>
  <div class="cx-message-popup-actions">
    <cx-button
      v-if="!acknowledged && !showWarning"
      :disabled="!canSend"
      :loading="awaiting"
      variantPrimary
      @click="$emit('send')">
      <span>{{ sendButtonText }}</span>
    </cx-button>
    <cx-button
      v-if="showWarning"
      variantSecondary
      @click="$emit('cancel')">
      <span>{{ translations.cancel }}</span>
    </cx-button>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';

export default {
  name: 'CxMessagePopupActions',
  components: { CxButton },
  props: {
    canSend: { type: Boolean, required: true },
    awaiting: { type: Boolean, required: true },
    acknowledged: { type: Boolean, required: true },
    failed: { type: Boolean, required: true },
    showWarning: { type: Boolean, required: true }
  },
  emits: ['send', 'cancel'],
  computed: {
    sendButtonText() {
      if (this.failed) return this.translations.resend;
      return this.translations.send;
    },
    translations() {
      return {
        send: this.$gettext('Send Message'),
        resend: this.$gettext('Resend Message'),
        cancel: this.$gettext('Cancel')
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-message-popup-actions {
  margin-right: 10px;
}
</style>