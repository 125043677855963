import * as Cesium from 'cesium';
import _ from 'lodash';

export const cartographicCalculations = {
  addOffsetToCartographic(cartographic, latMeters, lonMeters) {
    const earthsRadius = 6371000;
    const deltaLat = latMeters / earthsRadius;
    const deltaLon = lonMeters / earthsRadius / Math.cos(cartographic.latitude);
    return new Cesium.Cartographic(
      cartographic.longitude + deltaLon,
      cartographic.latitude + deltaLat,
      cartographic.height
    );
  },
  addOffsetToCartesian(cartesian, latMeters, lonMeters) {
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    const cartographicOffset = this.addOffsetToCartographic(cartographic, latMeters, lonMeters);
    return Cesium.Cartographic.toCartesian(cartographicOffset);
  },
  /**
   * Verifies if objects have coordinates and computes cartographics from objects
   * @param {Array} objects The objects to translate to cartographics
   */
  computeCartographics(objects) {
    const cartographics = objects
      .filter((item) => {
        return item.lon && item.lat;
      })
      .map((object) => {
        return Cesium.Cartographic.fromDegrees(
          object.lon,
          object.lat,
          object.altitude
        );
      });
    return cartographics;
  },

  /**
   * Calculates rectangular offset of a cartographic collection
   * @param {Array} cartographics The objects to calculate offset of
   */
  calculateOffsetFromCartographics(cartographics) {
    const rectangle = Cesium.Rectangle.fromCartographicArray(cartographics);
    return rectangle;
  },

  /**
   * Calculates rectangular offset of a single object with geographic coordinates
   * @param object {Object} object The single object with geographic coords to calculate offset of
   * @param offset {Number} the offset
   */
  calculateOffsetFromDegrees(object, offset = 0.0004, offsetSouth = offset) {
    if (!object.lon || !object.lat) return null;

    const west = object.lon - offset;
    const south = object.lat - offsetSouth;
    const east = object.lon + offset;
    const north = object.lat + offset;
    return Cesium.Rectangle.fromDegrees(west, south, east, north);
  },

  /**
   * Calculates rectangular offset of a single Cartesian object
   * @param {Object} object The single Cartesian object to calculate offset of
   * @param {Object} offset the offset
   */
  calculateOffsetFromCartesian(object, offset = 0.0004, offsetSouth = offset) {
    const cartographic = Cesium.Cartographic.fromCartesian(object);
    const degrees = {
      lon: Cesium.Math.toDegrees(cartographic.longitude),
      lat: Cesium.Math.toDegrees(cartographic.latitude)
    };
    return this.calculateOffsetFromDegrees(degrees, offset, offsetSouth);
  },

  /**
  * Calculates offset of a rectangle
  * @param {Object} boundary The rectangle to calculate offset of
  */
  calculateOffsetFromRectangle(boundary) {
    let OFFSET = 0.0004; // Basic side offset for the edges of calculated rectangle
    const OFFSET_FACTOR = 1600000; // Factor scaling the offset

    if (!boundary.west || !boundary.east || !boundary.south || !boundary.north) {
      return null;
    }

    if (boundary.height) {
      OFFSET /= (boundary.height * OFFSET_FACTOR);
    }

    boundary.west -= OFFSET;
    boundary.south -= OFFSET;
    boundary.east += OFFSET;
    boundary.north += OFFSET;

    return boundary;
  },

  /**
   * Calculates rectangular offset of a single object
   * or returns the object if it is already a rectangle
   * @param object {Object} object The single object with Cartesian coordinates
   * @param offset {Object} the offset
   * or edge geographic coords to verify and potentially calculate offset of
   */
  calculateOffset(object, offset = 0.0004, offsetSouth = offset) {
    const coordsKeys = Object.keys(object);
    let result = null;
    if (coordsKeys.length === 4) {
      result = object;
    } else if (coordsKeys.length === 3) {
      result = this.calculateOffsetFromCartesian(object, offset, offsetSouth);
    }
    return result;
  },

  boundaryToRectangle(boundary) {
    const minCartesian = new Cesium.Cartesian3(boundary.min[0], boundary.min[1], boundary.min[2]);
    const maxCartesian = new Cesium.Cartesian3(boundary.max[0], boundary.max[1], boundary.max[2]);
    const minCarto = Cesium.Cartographic.fromCartesian(minCartesian);
    const maxCarto = Cesium.Cartographic.fromCartesian(maxCartesian);
    const east = Math.max(maxCarto.longitude, minCarto.longitude);
    const west = Math.min(maxCarto.longitude, minCarto.longitude);
    const north = Math.max(maxCarto.latitude, minCarto.latitude);
    const south = Math.min(maxCarto.latitude, minCarto.latitude);
    return new Cesium.Rectangle(west, south, east, north);
  },

  /**
   * Converts cartesian coordinates to cartographic coordinates described by 2D angles and altitude.
   * @param {Object<Cesium.Cartesian3>} cartesian to be transformed to cartographic object.
   * @param {Object<Cesium.Ellipsoid>} ellipsoid World 3D model, a context of transformation.
   * @returns {Object<Cesium.Cartographic>} defined by longitude [radians], latitude [radians] and height [meters].
   */
  getCartographicFromCartesian3(cartesian, ellipsoid) {
    return Cesium.Cartographic.fromCartesian(cartesian, ellipsoid);
  },

  /**
   * Converts ellipsoid coordinates described by 2D angles and altitude to cartesian coordinates.
   * @param {Object<Cesium.Cartographic>} cartographic to be transformed to cartesian object.
   * @param {Object<Cesium.Ellipsoid>} ellipsoid World 3D model, a context of transformation.
   * @returns {Object<Cesium.Cartesian3>} defined by cartesian 3D coordinates (x, y, z).
   */
  getCartesian3FromCartographic(cartographic, ellipsoid) {
    const { longitude, latitude, height } = cartographic;
    return Cesium.Cartesian3.fromRadians(longitude, latitude, height, ellipsoid);
  },

  /**
   * Gets cartographic definition of point defined by intersection of central camera ray on some vertical level and the ellipsoid surface.
   * @param {Number} centralRayVerticalPosition vertical position of the central camera ray,
   * where 0 is top and 1 is bottom of the inner window height.
   * @param {Object<Cesium.Camera>} camera representing 3D map view.
   * @param {Object<Cesium.Ellipsoid>} ellipsoid World 3D model, a context of camera view.
   * @returns {Object<Cesium.Cartographic>} defined by longitude [radians], latitude [radians] and height [meters]
   * or {null} if there is no intersecting point.
   */
  getViewCenterRayIntersectingPoint(centralRayVerticalPosition, camera, ellipsoid) {
    const windowTopVerticalPosition = 0;
    const windowBottomVerticalPosition = 1;
    if (_.inRange(centralRayVerticalPosition, windowTopVerticalPosition, windowBottomVerticalPosition)) {
      const viewCenterPixel = {
        x: window.innerWidth * 0.5,
        y: window.innerHeight * centralRayVerticalPosition
      };
      const viewCenter = new Cesium.Cartesian2(viewCenterPixel.x, viewCenterPixel.y);
      const viewCenterRay = camera.getPickRay(viewCenter);
      const intersection = Cesium.IntersectionTests.rayEllipsoid(viewCenterRay, ellipsoid);
      const intersectingPoint = intersection ? Cesium.Ray.getPoint(viewCenterRay, intersection.start) : null;
      return intersectingPoint ? this.getCartographicFromCartesian3(intersectingPoint, ellipsoid) : null;
    }
    return null;
  },

  /**
   * Uses camera's pitch to calculate a window inner height fraction
   * in order to estimate best view center ray intersection point
   * @param {Object<Cesium.Camera>} camera
   * @returns {Number} window inner height fraction
   */
  getCentralRayVerticalPosition(camera) {
    const { pitch } = camera;
    const A = 0.25;
    const B = 1;
    return A * pitch + B;
  }
};
