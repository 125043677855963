import _ from 'lodash';
import { Surface } from '@/3dapi/entities/surface';
import * as Cesium from 'cesium';
import { gltfUtils } from './gltfUtils/gltfUtils';


const loadCutFill = (uuid, gltf, scene, surfaceCollection) => {
  return new Promise((resolve) => {
    const gltfModel = new Cesium.Model({
      id: {
        name: 'Cut/Fill Map',
        uuid,
        cxType: 'differenceModel'
      },
      gltf,
      scene,
      minimumPixelSize: 128,
      shadows: Cesium.ShadowMode.RECEIVE_ONLY,
      scale: 1,
    });
    surfaceCollection.add(gltfModel);
    scene.requestRender();
    Cesium.when(gltfModel.readyPromise).then((model) => {
      model.id.position = new Cesium.ConstantPositionProperty(model.boundingSphere.center);
      resolve(model);
      const nodeCut = model.getNode('Cut');
      nodeCut.show = false;
      const nodeFill = model.getNode('Fill');
      nodeFill.show = false;
    });
  });
};

const loadDiff = (uuid, gltf, scene, surfaceCollection) => {
  return new Promise((resolve) => {
    const gltfModel = new Cesium.Model({
      id: {
        name: 'Cut Fill Volumes',
        uuid,
        cxType: 'Cut Fill Volumes'
      },
      gltf,
      scene,
      minimumPixelSize: 128,
      shadows: Cesium.ShadowMode.RECEIVE_ONLY,
      scale: 1,
    });
    // state.surfaces[uuid] = new Surface(uuid, '', [gltfModel]);
    surfaceCollection.add(gltfModel);
    scene.requestRender();
    Cesium.when(gltfModel.readyPromise).then((model) => {
      model.id.position = new Cesium.ConstantPositionProperty(model.boundingSphere.center);
      resolve(model);
      const nodeCutFill = model.getNode('Cut');
      nodeCutFill.show = false;
    });
  });
};

const surfaceStore = {
  state: {
    /**
     * This PrimitiveCollection holds all the refmodel primitives.
     */
    _surfaceCollection: null,
    surfaceCollection(viewer) {
      if (!this._surfaceCollection) {
        const { primitives } = viewer.scene;
        this._surfaceCollection = primitives.add(new Cesium.PrimitiveCollection());
      }
      return this._surfaceCollection;
    },
    /**
     * map of surfaces uuid - surface
     */
    surfaces: {}
  },
  actions: {
    /**
     * Load a asBuilt surface
     * @param uuid of the surface to show
     * @param gltf
     * @param show
     */
    async loadAsBuiltSurface(context, { uuid, gltf, show }) {
      const { viewer } = context.self.rootState;
      const { state } = context.self;
      const { scene } = viewer;
      gltfUtils.initAsBuiltSurface(gltf);
      if (!gltf) {
        return true;
      }
      return new Promise((resolve) => {
        const color = Cesium.Color.fromAlpha(Cesium.Color.PALEGOLDENROD, 0.9);
        const gltfModel = new Cesium.Model({
          id: {
            name: 'Asbuilt Model',
            id: uuid,
            cxType: 'asBuiltSurface',
          },
          gltf,
          show,
          scene,
          color,
          minimumPixelSize: 128,
          shadows: Cesium.ShadowMode.RECEIVE_ONLY,
          scale: 1,
        });

        state.surfaces[uuid] = new Surface(uuid, 'surface', [gltfModel]);
        state.surfaceCollection(viewer).add(gltfModel);
        scene.requestRender();
        Cesium.when(gltfModel.readyPromise).then((model) => {
          model.id.position = new Cesium.ConstantPositionProperty(model.boundingSphere.center);
          resolve(uuid);
        });
      });
    },
    /**
     * Loads a cut fill model
     * @param uuid of the surface to show
     * @param gltf
     * @param show
     */
    async loadCutFillSurface(context, { uuid, gltf, show }) {
      const { viewer } = context.self.rootState;
      const { scene } = context.self.rootState.viewer;
      const { state } = context.self;
      const surfaceCollection = state.surfaceCollection(viewer);

      if (!show || !gltf) {
        return true;
      }

      gltfUtils.initDiffModel(gltf);
      return new Promise((resolve) => {
        loadCutFill(uuid, gltf, scene, surfaceCollection).then((gltfModel) => {
          state.surfaces[uuid] = new Surface(uuid, 'cutFill', [gltfModel]);
          resolve(true);
        });
      });
    },

    /**
     * Loads progress analysis cut-fill surface
     *  @param uuid of the surface to show
     *  @param gltf
     *  @param show
     */
    async loadDiffSurface(context, { uuid, gltf, show }) {
      const { viewer } = context.self.rootState;
      const { scene } = viewer;
      const { state } = context.self;
      const surfaceCollection = state.surfaceCollection(viewer);

      if (!show || !gltf) {
        return true;
      }
      gltfUtils.initCutFillModel(gltf[0]);
      gltfUtils.initDiffModel(gltf[1]);
      return new Promise((resolve) => {
        const promises = [
          loadCutFill(uuid, gltf[1], scene, surfaceCollection),
          loadDiff(uuid, gltf[0], scene, surfaceCollection)
        ];
        Promise.all(promises).then((model1s) => {
          state.surfaces[uuid] = new Surface(uuid, 'diffModel', model1s);
          resolve(true);
        });
      });

    },

    /**
     * Will hide surface from 3d map
     * @param {*} uuid of the surface to hide
     */
    hideSurface(context, { uuid }) {
      const { state } = context.self;
      state.surfaces[uuid].hide();
    },
    /**
     * Will show surface on 3d map
     * @param {*} uuid of the surface to show
     */
    showSurface(context, { uuid }) {
      const { state } = context.self;
      state.surfaces[uuid].show();
    },
    /**
     * Will remove the asbuild surface from the 3dmap
     * @param {*} uuid of the surface to remove
     */
    async removeSurface(context, { uuid }) {
      const { state } = context.self;
      const { viewer } = context.self.rootState;
      const surfaceCollection = state.surfaceCollection(viewer);
      const surface = state.surfaces[uuid];
      if (surface) surface.geometry.forEach(g => surfaceCollection.remove(g));
      state.surfaces = _.omit(state.surfaces, [uuid]);
    },
  },
  events: {
  },
  methods: {
  },
};

export default surfaceStore;