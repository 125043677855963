<template>
  <div class="cx-object-explorer-header">
    <cx-badge
      icon="cx_unit"
      :iconSize="12"
      :text="entityCount.units.caption"
      :tooltip="entityCount.units.tooltip"/>
    <cx-badge
      icon="cx_ref_model"
      :iconSize="12"
      :text="entityCount.files.caption"
      :tooltip="entityCount.files.tooltip"/>
    <cx-badge
      icon="cx_as_built"
      :iconSize="12"
      :text="entityCount.points.caption"
      :tooltip="entityCount.points.tooltip"/>
    <cx-badge
      id="cx-object-explorer-header__hole-counter"
      v-if="hasStabilizationLicence"
      icon="radio_button_unchecked"
      :iconSize="12"
      :text="entityCount.holes.caption"
      :tooltip="entityCount.holes.tooltip"/>
    <cx-badge
      id="cx-object-explorer-header__awareness-events"
      v-if="hasAwarenessLicence"
      icon="warning"
      :iconSize="12"
      :text="entityCount.events.caption"
      :tooltip="entityCount.events.tooltip"/>
    <cx-badge
      id="cx-object-explorer-header__surfacelogs"
      icon="cx_points"
      :iconSize="12"
      :text="entityCount.surfacelogs.caption"
      :tooltip="entityCount.surfacelogs.tooltip"/>
    <cx-button
      id="cx-object-explorer-header__delete-all"
      icon="delete"
      :iconSize="16"
      :tooltip="{ text: entityCount.deleteAll.tooltip, offsetTop: -5 }"
      variantSecondary
      @click="deleteAll"/>
  </div>
</template>m

<script>
import { sumBy } from 'lodash';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { mapGetters, mapActions } from 'vuex';
import CxBadge from '@/components/CxBadge';
import CxButton from '../../components/CxButton';
import CxLicenceMixin from '../../mixins/CxLicence.mixin';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';

export default {
  name: 'CxObjectExplorerHeader',
  components: {
    CxBadge,
    CxButton,
  },
  mixins: [
    CxLicenceMixin, CxWaffleFlagMixin
  ],
  computed: {
    ...mapGetters('map', ['objectExplorerEntities']),
    entityCount() {
      const entitiesOfType = (entityType) => this.objectExplorerEntities.filter(i => i.entityType === entityType);
      const entitiesInTypes = (types) => this.objectExplorerEntities.filter(i => types.includes(i.entityType));
      const entityCountViewModel = {
        units: {
          caption: entitiesInTypes(Object.values(UnitTypes)).length.toString(),
          tooltip: this.$gettext('Units')
        },
        files: {
          caption: (entitiesInTypes(Object.values(RefmodelTypes)).length).toString(),
          tooltip: this.$gettext('Files')
        },
        points: {
          caption: sumBy(entitiesOfType(PointSetType), 'count').toString(),
          tooltip: this.$gettext('Measured Points')
        },
        holes: {
          caption: sumBy(entitiesOfType(DrillHoleSetType), 'count').toString(),
          tooltip: this.$gettext('Holes')
        },
        deleteAll: {
          tooltip: this.$gettext('Delete All')
        },
        events: {
          caption: sumBy(entitiesOfType(AwarenessEventSetType), 'count').toString(),
          tooltip: this.$gettext('Awareness Events')
        },
        surfacelogs: {
          caption: entitiesInTypes(SurfacelogType).length.toString(),
          tooltip: this.$gettext('Surface Logs')
        }
      };
      return entityCountViewModel;
    }
  },
  methods: {
    ...mapActions('map', ['removeEntityFamily']),
    deleteAll() {
      const uuids = this.objectExplorerEntities.filter(i => i.level === 0).map(i => i.uuid);
      this.removeEntityFamily({ uuids });
    }
  }
};
</script>

<style lang="less">
    @import '../../common';

    .cx-object-explorer-header {
      .flex(row, space-between, center);

      .cx-badge, .cx-button {
        .size(100%, auto);
        .flex(row, space-around, center);
        padding: 10px;
        height: 32px;
        margin: 0 2px;

        .cx-badge__caption, .cx-button__caption {
          min-width: 20px;
          text-align: center;
        }
      }

      .cx-badge {

        .cx-badge__caption {
          padding-top: 1px;
        }
      }

      .cx-button {
        width: 42px;
      }
    }
</style>