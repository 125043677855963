<template>
  <div
    class="cx-dh-scale-legend"
    :style="this.legendHeight">
    <div class="cx-dh-scale-legend__title">
      <slot name="title"></slot>
    </div>
    <div class="cx-dh-scale-legend__unitText">
      <slot name="unit"></slot>
    </div>
    <div>
      <div class="cx-dh-scale-legend__gradient">
        <div class="cx-dh-scale-legend__gradient__scale">
          <div v-for="(item, index) in scale" :key="index" class="cx-dh-scale-legend__scale-number">
            <div class="cx-dh-scale-legend-intervalText">
              {{ legendIntervals(index, scale.length, item.value) }}
            </div>
            <div
              :style="{
                width: '12px',
                height: '14px',
                backgroundColor: item.color}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxDhScaleLegend',
  props: {
    scale: { type: Array, required: true },
  },
  methods: {
    legendIntervals(index, length, value) {
      if (index === 0) return ` ${value} <`;
      if (index === length - 1) return `< ${this.scale[index - 1].value}`;
      return `${this.scale[index].value} - ${this.scale[index - 1].value}`;
    }
  },
  computed: {
    legendHeight() {
      return { height: `${this.scale.length * 14 + 50}px` };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-dh-scale-legend {
  width: @overlayControlLegendWidth;
  .flex(column, flex-start, center);

  &__title {
    pointer-events: auto;
    font-size: 7px;
    padding: 9px 0 3px 0;
    color: @textColorHighlight;
    text-align: center;
  }

  &__unitText {
    pointer-events: auto;
    font-size: 7px;
    padding: 0 0 10px 0;
    color: @textColorLowlight;
  }

  &__gradient {
    display: flex;
    justify-content: center;
    gap: 9px;
    height: @overlayControlLegendGradientHeight;
    width: 100%;

    &__scale {
      list-style-type: none;
      text-align: right;
      font-size: 7px;
      .cx-dh-scale-legend__scale-number {
        display: flex;
        gap: 5px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.9;
      }
    }
  }
}
   .cx-dh-scale-legend-intervalText {
        opacity: 0.5;
  }

</style>
