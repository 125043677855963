<template>
  <div class="cx-hole-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon icon="brightness_1" :class="iconClassName" :size="9"/>
      <span class="map-overlay__title__name">{{ title }}</span>
    </div>
    <div class="map-overlay__subtitles">
      <span class="map-overlay__subtitles__unit">{{ unitName }}</span>
      <span class="map-overlay__subtitles__log-time">{{ logTime }}</span>
      <span class="map-overlay__subtitles__analysisData">{{ analysisData }}</span>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { createValue } from '@/viewModel/valueFactory';
import licenceService from '@/services/licence.service';
import { withColor } from '@/domain/entityMixins';

export default {
  name: 'CxHoleTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {

    iconClassName() {
      if (!this.hoveredEntity) return '';
      const coloredEntity = withColor(this.hoveredEntity);
      return `map-overlay__title__icon ${coloredEntity.color}`;
    },
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.holeName;
    },
    unitName() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.unit;
    },
    logTime() {
      if (!this.hoveredEntity) return '';
      return createValue(this.hoveredEntity, 'logTime').displayValue;
    },
    analysisData() {
      if (!this.hoveredEntity) return '';
      // holes can't be displayed without licence ?
      if (licenceService.hasStabilizationLicence()) return createValue(this.hoveredEntity, 'drillPileDepth').displayValue;
      return '';
    }
  },
};
</script>

<style lang="less">

</style>