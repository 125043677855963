<template>
  <div class="cx-metrics">
    <div class="cx-metrics__title">
      <span><slot name="title"></slot></span>
      <div class="cx-metrics__subtitle"><slot name="subtitle"></slot></div>
    </div>
    <div class="cx-metrics__content"><slot name="content"></slot></div>
    <div class="cx-metrics__total"><slot name="footer"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'CxMetricsPanel'
};
</script>

<style lang="less">
@import '../../../common';

.cx-metrics {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: @detailsMetricsHeight;
  padding-top: 8px;
  padding-right: 50px;
  padding-left: 40px;
}

.cx-metrics__title {
  flex: 0 0 40px;
  font-size: @appFontSize;
  color: @textColorHighlight;
}

.cx-metrics__content {
  flex: 1 1;
  font-size: @appFontSize;
  color: @textColorHighlight;
}

.cx-metrics__total {
  flex: 0 0 55px;
  font-size: @appFontSize;
  color: @textColorHighlight;
}

.cx-metrics__subtitle {
  font-size: @appFontSize;
  color: @textColorDisabled;
}

</style>