import api from '@/http/api';
import { flatMap, uniqBy } from 'lodash';

const fetchGeoJson = async (eventSet, projectUuid) => {
  return new Promise((res, rej) => {
    try {
      api.getEventsVisualizationGeojson(eventSet, projectUuid).then((geojson) => {
        res({
          geojson,
          setUuid: eventSet.uuid
        });
      });
    } catch (e) { rej(e); }
  });
};

const fetchAuthorizingProxy = async (projectUuid) => {
  return new Promise((res, rej) => {
    try {
      api.getWmsUrl(projectUuid).then((url) => {
        res(url);
      });
    } catch (e) { rej(e); }
  });
};


const mapAwarenessEventStore = {
  state: {
    // all awareness events loaded on the map
    loadedAwarenessEventSets: {},
    // ids of hidden awareness events
    hiddenAwarenessEventSets: []
  },
  getters: {
    loadedAwarenessEventSets(state) {
      const sets = Object.keys(state.loadedAwarenessEventSets);
      return flatMap(sets, k => state.loadedAwarenessEventSets[k]);
    },
    loadedAwarenessEvents(state, getters) {
      const allEvents = flatMap(getters.loadedAwarenessEventSets, k => state.loadedAwarenessEventSets[k.uuid].events);
      return uniqBy(allEvents, e => e.uuid);
    }
  },
  mutations: {
    addAwarenessEvents(state, data) {
      data.forEach((d) => {
        const newDisplayedSet = { ...state.loadedAwarenessEventSets };
        newDisplayedSet[d.uuid] = d;
        state.loadedAwarenessEventSets = newDisplayedSet;
      });
    },
    removeAwarenessEvents(state, data) {
      data.forEach((awarenessEventSet) => {
        const newSet = { ...state.loadedAwarenessEventSets };
        delete newSet[awarenessEventSet.uuid];
        state.loadedAwarenessEventSets = newSet;
      });
    },
    showAwarenessEventSet(state, uuid) {
      state.hiddenAwarenessEventSets = state.hiddenAwarenessEventSets.filter(e => e !== uuid);
    },
    hideAwarenessEventSet(state, uuid) {
      state.hiddenAwarenessEventSets = [...state.hiddenAwarenessEventSets, uuid];
    },
    hideAwarenessEventSets(state, awarenessSetUuids) {
      state.hiddenAwarenessEventSets = [...state.hiddenAwarenessEventSets, ...awarenessSetUuids];
    }
  },
  actions: {
    async loadAwarenessEvents(context, { entities, showOnMap }) {
      context.commit('addAwarenessEvents', entities);
      if (!showOnMap) context.commit('hideAwarenessEventSets', entities.map(e => e.uuid));
      const geoJsonPromises = entities.map(eventSet => fetchGeoJson(eventSet, context.rootGetters.projectUuid));
      const geoJsons = await Promise.all(geoJsonPromises);
      const eventPromises = geoJsons.map(result => context.dispatch('viewer/loadEvents', { ...result, showOnMap }));
      await Promise.all(eventPromises);
    },
    async removeAwarenessEvents(context, { entities }) {
      if (!entities || entities.length < 1) { return; }
      context.commit('removeAwarenessEvents', entities);
      const promises = entities.map(e => {
        return context.dispatch('viewer/removeEventSet', { setUuid: e.uuid });
      });
      await Promise.all(promises);
    },
    async showAwarenessEventSet(context, { uuid }) {
      context.commit('showAwarenessEventSet', uuid);
      context.dispatch('viewer/toggleEventSetVisibility', { setUuid: uuid, showOnMap: true });
    },
    async hideAwarenessEventSet(context, { uuid }) {
      context.commit('hideAwarenessEventSet', uuid);
      context.dispatch('viewer/toggleEventSetVisibility', { setUuid: uuid, showOnMap: false });
    },
    async toggleAwarenessEventSetClustering(context, { uuid, shouldCluster }) {
      await context.dispatch('viewer/toggleAwarenessEventSetClustering', { setUuid: uuid, shouldCluster });
    },
    async toggleAwarenessEventSetHeatmap(context, { uuid, show }) {
      const eventSet = context.getters.loadedAwarenessEventSets.find(as => as.uuid === uuid);
      const geoserverUrl = await fetchAuthorizingProxy(context.rootGetters.projectUuid);
      await context.dispatch('viewer/toggleAwarenessEventSetHeatmap', { setUuid: uuid, show, filters: eventSet, geoserverUrl });
    },
    async deselectAwarenessEventSet(context, { uuids }) {
      if (context.state.selectedEntity && uuids.find(f => context.state.selectedEntity.uuid === f)) {
        await context.dispatch('viewer/deselect');
      }
    }
  }
};

export default mapAwarenessEventStore;