import sls from '@/http/sls.axios.instance';
import { downloadFileFromUrl } from '@/http/common/download.service';
import { parseISO, formatISO } from 'date-fns';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { AxiosResponse } from 'axios';
import { SignalKPI } from '@/domain/surfacelog/SignalKPI';
import { MeasurementUnit } from '@/domain/MeasurementUnit';
import { reverseMapMeasurementUnit } from './measurementUnit.mapper';
import { PointCodesType } from '@/domain/surfacelog/PointCodesType';

enum TOOLEDGE_SUPPORTED_UNIT_TYPES {
  BULLDOZER = 'BULLDOZER',
  CTLSKIDSTEER = 'CTL-SKID-STEER',
  EXCAVATOR = 'EXCAVATOR',
  EXCAVATOR_WHEELED = 'EXCAVATOR-WHEELED',
  GRADER = 'GRADER',
  SNOWGROOMER = 'SNOW-GROOMER',
  WHEELLOADER = 'WHEEL-LOADER'
}

const get_tooledge_unit_types_as_query_params = (): string => {
  return Object.values(TOOLEDGE_SUPPORTED_UNIT_TYPES).map((unit_type) => `unit_types=${encodeURIComponent(unit_type)}`).join('&');
};


const getSurfaceHashID = async (projectUuid, request) => {
  const url = `projects/${projectUuid}/surface/export`;
  const result: AxiosResponse<{ url: URL }> = await sls.post(url, request);
  return new URL(result.data.url);
};


const mapRasterKPI = (signals: any): Record<string, SignalKPI> => {
  const result = signals.reduce((acc, singleSignal) => {
    acc[singleSignal.signal] = singleSignal.raster_kpi;
    return acc;
  }, {});
  return result;
};

const exportSurface = async (surfaceUrl: URL, withKPI: boolean = false) => {
  const POLL_INTERVAL = 1000;
  return new Promise<{ url: string, signals: Record<string, SignalKPI> }>((res, rej) => {
    let result: AxiosResponse<{ resource_url: string, signal_raster_kpi: any }>;
    const poll = async () => {
      try {
        result = await sls.get(surfaceUrl.pathname);
        if (result.status === 200 && 'resource_url' in result.data) {
          const url = result.data.resource_url;
          const signals = withKPI ? mapRasterKPI(result.data.signal_raster_kpi) : {};
          res({ url, signals });
        } else setTimeout(() => { poll(); }, POLL_INTERVAL);
      } catch (exception: any) {
        rej(exception);
      }
    };
    setTimeout(() => { poll(); }, POLL_INTERVAL);
  });
};

const getSurfacelogVisualization = async (
  projectUuid: string,
  startDate: string,
  endDate: string,
  refmodelPaths: string[],
  pointCodes: PointCodesType,
  cellsize: number,
  units: { name: string }[],
  targetReferenceModelPath: string | undefined,
): Promise<{ url: string, signals: Record<string, SignalKPI> }> => {

  const request = {
    project_uuid: projectUuid,
    output_type: 'wms',
    start_time: startDate,
    end_time: endDate,
    reference_model_paths: refmodelPaths,
    target_reference_model_path: targetReferenceModelPath,
    cellsize,
    unit_names: units.map(u => u.name),
    point_codes: pointCodes.map(p => p.point_code),
    unit_types: Object.values(TOOLEDGE_SUPPORTED_UNIT_TYPES),
    only_vibrated_areas: false,
    over_refmodel_only: false,
    requested_signals: ['H', 'dH', 'PC']
  };

  const surfaceUrl = await getSurfaceHashID(projectUuid, request);
  const exportedSurface = await exportSurface(surfaceUrl, true);
  return exportedSurface;
};

const exportSurfacelog = async ({ projectUuid, output, startDate, endDate, refmodelPaths, targetReferenceModelPath, cellSize, units, measurementUnit, fileName, pointCodes }:
  {
    projectUuid: string,
    output: string
    startDate: string,
    endDate: string,
    refmodelPaths: string[],
    targetReferenceModelPath: string | undefined,
    cellSize: number,
    units: { name: string }[],
    measurementUnit: MeasurementUnit,
    fileName: string,
    pointCodes: PointCodesType,
  }): Promise<void> => {
  const request = {
    project_uuid: projectUuid,
    output_type: output,
    start_time: startDate,
    end_time: endDate,
    reference_model_paths: refmodelPaths,
    target_reference_model_path: targetReferenceModelPath,
    cellsize: cellSize,
    unit_names: units.map(u => u.name),
    measurement_unit: reverseMapMeasurementUnit(measurementUnit),
    point_codes: pointCodes.map(p => p.point_code),
    unit_types: Object.values(TOOLEDGE_SUPPORTED_UNIT_TYPES),
    only_vibrated_areas: false,
    over_refmodel_only: false,
    requested_signals: ['H', 'dH', 'PC']

  };
  const surfaceUrl = await getSurfaceHashID(projectUuid, request);
  const exportedSurface = await exportSurface(surfaceUrl);
  downloadFileFromUrl(exportedSurface.url, fileName);
};


const getRefmodels = async (projectUuid: string): Promise<{ name: string; path: string; refmodelPath: string; }[]> => {
  const url = `projects/${projectUuid}/surface/refmodels?${get_tooledge_unit_types_as_query_params()}`;
  const response = await sls.get(url);

  const mapRefmodel = (refmodel: { reference_model_path: string; }) => {
    const path = refmodel.reference_model_path;
    const splitPath = path.split('/');
    return {
      name: splitPath.pop(),
      path: splitPath.join('/') || '/',
      refmodelPath: path
    };
  };
  return response.data.map((s) => mapRefmodel(s));
};

const getPointCodes = async (
  projectUuid: string
): Promise<PointCodesType> => {
  const pointCodesUrl = `/projects/${projectUuid}/surface/point-codes`;
  const response = await sls.get(pointCodesUrl);
  return response.data;
};


const filterSurface = async (
  projectUuid: string,
  refModelFullPaths: string[],
  startDate: Date,
  endDate: Date,
  units: { name: string }[],
  pointCodes: PointCodesType
): Promise<boolean> => {
  const url = `projects/${projectUuid}/surface/search`;
  const request = {
    project_uuid: projectUuid,
    reference_model_paths: refModelFullPaths,
    start_time: formatISO(startDate),
    end_time: formatISO(endDate),
    unit_names: units.map(u => u.name),
    point_codes: pointCodes.map(p => p.point_code),
    unit_types: Object.values(TOOLEDGE_SUPPORTED_UNIT_TYPES),
  };
  const result = await sls.post(url, request);
  const { success } = result.data;
  return success;
};


const getActiveDays = async (
  projectUuid: string,
  refmodelFullPaths: string[] | undefined,
  pointCodes: PointCodesType = []
): Promise<Date[]> => {
  let url = `projects/${projectUuid}/surface/activedays?${get_tooledge_unit_types_as_query_params()}`;
  const refmodelPathParams = refmodelFullPaths ? refmodelFullPaths.map(refmodel => ({ key: 'reference_model_paths', value: refmodel })) : [];
  const params = [...refmodelPathParams,
  ...pointCodes.map(p => ({ key: 'point_codes', value: p.point_code }))]
    .filter(e => !!e.value)
    .map(e => `${e.key}=${encodeURIComponent(e.value ? e.value : '')}`).join('&');
  if (params.length) url += `&${params}`;
  const response = await sls.get(url);
  return response.data.map(r => parseISO(r.active_day));
};

const getSurfacelogUnits = async (
  projectUuid: string,
  refmodelFullPaths: string[] | undefined,
  pointCodes: PointCodesType = []
): Promise<{ name: string; type: UnitTypes; }[]> => {
  let url = `projects/${projectUuid}/surface/units?${get_tooledge_unit_types_as_query_params()}`;
  const refmodelPathParams = (refmodelFullPaths) ? refmodelFullPaths.map(refmodel => ({ key: 'reference_model_paths', value: refmodel })) : [];
  const params = [...refmodelPathParams, ...pointCodes.map(p => ({ key: 'point_codes', value: p.point_code }))]
    .filter(e => !!e.value).map(e => `${e.key}=${encodeURIComponent(e.value ? e.value : '')}`).join('&');
  if (params.length) url += `&${params}`;
  const response = await sls.get(url);

  return response.data.map(r => ({
    name: r.unit_name,
    type: r.unit_type
  }));
};


export const surfacelogApi = {
  getSurfacelogVisualization,
  getRefmodels,
  getPointCodes,
  filterSurface,
  getActiveDays,
  getSurfacelogUnits,
  exportSurfacelog,
};
