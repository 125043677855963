import FilterEntityViewModel from '../../filterEntityViewModel';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';

export default class FilterDrillHoleViewModel extends FilterEntityViewModel {

  _entityType;
  constructor(entity, locale) {
    super(entity, locale);
    this._entityType = DrillHoleSetType;
  }

  get isResultSelectable() {
    return false;
  }

  get isResultVisible() {
    return true;
  }
}