<template>
  <div class="cx-vertical-property">
    <div class="cx-vertical-property__fieldName">{{ fieldName }}</div>
    <div class="cx-vertical-property__value" :class="{'cx-vertical-property__value--no-data': !value && value !== 0 }">
      {{ filteredValue }}
      <span v-if="value || value === 0">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
import noDataFilter from './filters/noData.filter';

export default {
  name: 'CxVerticalProperty',
  props: {
    fieldName: String,
    value: [String, Number],
    unit: String
  },
  computed: {
    filteredValue() {
      return noDataFilter(this.value);
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-vertical-property {
  font-size: @appFontSize;
  overflow: hidden;
  word-break: break-all;

  .cx-vertical-property__fieldName {
    color: @textColorLowlight;
  }
  .cx-vertical-property__value {
    color: @textColor;
    &.cx-vertical-property__value--no-data {
      color: @textColorLowlight;
    }
    span {
      color: @textColorLowlight;
    }
  }
}

</style>