
const mapHoverStore = {
  state: {
    hoveredEntity: null,
    hoverTooltipPosition: null,
  },
  getters: {
    hoveredEntity(state) {
      return state.hoveredEntity;
    },
    hoverTooltipPosition(state) {
      return state.hoverTooltipPosition;
    }
  },
  mutations: {
    setHoveredEntity(state, entity) {
      state.hoveredEntity = entity;
    },
    setHoverTooltipPosition(state, entity) {
      state.hoverTooltipPosition = entity;
    }
  },
  actions: {
    async handleEntityHover(context, { uuid }) {

      const oldHoveredEntity = context.getters.hoveredEntity;
      // entity deselected
      if (!uuid) {
        if (oldHoveredEntity) {
          context.commit('setHoveredEntity', null);
          context.dispatch('apiEntityHoverChanged', { newValue: null, oldValue: oldHoveredEntity });
        }
        return;
      }
      // search for selected entity in loaded things
      const entity = context.getters.allLoadedEntities.find(l => l.uuid === uuid);
      if (!entity) {
        // throw new Error(`hovered ${type}: ${uuid} does not exist in loaded objects on map.`);
        context.commit('setHoveredEntity', null);
        context.dispatch('apiEntityHoverChanged', { newValue: null, oldValue: oldHoveredEntity });
        return;
      }
      if (oldHoveredEntity && oldHoveredEntity.uuid === entity.uuid) {
        return;
      }
      context.commit('setHoveredEntity', entity);
      context.dispatch('apiEntityHoverChanged', { newValue: entity, oldValue: oldHoveredEntity });
    },


    /**
     * Shows tooltip
     * ! Works both on the hover and selection stores!
     * @param {} context
     * @param {*} type - hover or select, depending on which tooltip to show / hide
     */
    async showTooltip(context, { position, type }) {
      // hide tooltip
      if (!position) {
        if (type === 'hover') context.commit('setHoverTooltipPosition', null);
        if (type === 'select') context.commit('setSelectTooltipPosition', null);
        return;
      }

      // show tooltip
      const bottom = position.y;
      const left = position.x;
      if (type === 'hover') context.commit('setHoverTooltipPosition', { bottom, left });
      if (type === 'select') context.commit('setSelectTooltipPosition', { bottom, left });
    },


    async apiEntityHoverChanged() {
      // api call to notify other stores
    },
  }
};

export default mapHoverStore;