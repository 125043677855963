import Vue from 'vue';
import FilterRefModelViewModel from './filterRefModelViewModel';
import FilterDrillPatternViewModel from './filterDrillPatternViewModel';
import licenceService from '@/services/licence.service';
import { OperationContext } from '@/domain/OperationContext.enum';
import { withExceedsLimits } from '@/domain/entityMixins';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;


const filterRefmodelModule = {
  state: {
    refModels: [],
    isQueryOngoing: false,
  },
  getters: {
    refModels(state) {
      return state.refModels;
    },
    isQueryOngoing(state) {
      return state.isQueryOngoing;
    }
  },
  mutations: {
    setRefModels(state, refModels) {
      state.refModels = refModels;
    },
    setIsQueryOngoing(state, isQueryOngoing) {
      state.isQueryOngoing = isQueryOngoing;
    }
  },
  actions: {
    /**
     * Initializes reference model collection and sets
     * @property {Boolean} isChipSelected
     * @property {Boolean} isResultVisible
     * @property {Boolean} isResultSelected
     * to be reactive in terms of Vue reactivity system.
     * @param {*} context
     * @param {*} availableRefModels collection the view model is made of.
     */
    async initializeRefModelFilters(context, availableRefModels) {
      const language = gettext.current;
      const { hasStabilizationLicence } = licenceService;
      const refModels = availableRefModels.map((r) => {
        return hasStabilizationLicence() && r.context === OperationContext.Stabilization ?
          new FilterDrillPatternViewModel(r, language) :
          new FilterRefModelViewModel(withExceedsLimits(r), language);
      });
      context.commit('setRefModels', refModels);
    },
    /**
     * Basing on name filter input, shows only filter-passing results
     * and enforces results selection on all of them.
     * @param {*} context
     * @param {Array<String>} selectedNameIds represent names selected using combobox/chips filter.
     */
    async changeNameFilterOutput(context, selectedNameIds) {
      context.getters.refModels.forEach((i) => { i.isChipSelected = selectedNameIds.includes(i.id); });
      if (selectedNameIds.length) {
        context.dispatch('changeVisibleFilterResults', selectedNameIds);
        context.dispatch('changeSelectedFilterResults', selectedNameIds);
      } else {
        context.dispatch('changeVisibleFilterResults', context.getters.refModels.map(i => i.id));
        context.dispatch('changeSelectedFilterResults', []);
      }
    },
    /**
     * Visibilitates filter results passing filter preselection.
     * @param {*} context
     * @param {Array<String>} visibleResultIds represent results to be visibilitated.
     */
    async changeVisibleFilterResults(context, visibleResultIds) {
      context.getters.refModels.forEach((i) => { i.isResultVisible = visibleResultIds.includes(i.id); });
    },
    /**
     * Selects given results.
     * @param {*} context
     * @param {Array<String>} selectedResultIds represent results to be selected.
     */
    async changeSelectedFilterResults(context, selectedResultIds) {
      context.getters.refModels.forEach((i) => { i.isResultSelected = selectedResultIds.includes(i.id); });
    },
    /**
     * Clears the filters by showing all the results, reseting name filter and deselecting all the results.
     * @param {*} context
     */
    async clearFilters(context) {
      context.dispatch('changeNameFilterOutput', []);
      context.dispatch('changeVisibleFilterResults', context.getters.refModels.map(i => i.id));
    },
    /**
     * Applies selected reference models to be loaded on the map
     * @constant {Array} selectedRefModels list of reference model uuids selected from the filtering panel
     * @param {*} context
     */
    async applyRefModels(context) {
      try {
        context.commit('setIsQueryOngoing', true);
        const selectedRefModels = context.getters.refModels
          .filter(i => i.isResultSelected)
          .map(i => i.id);
        const entities = context.rootGetters['app/allModels'].filter(i => selectedRefModels.includes(i.uuid));
        await context.dispatch('map/addEntities', { entities }, { root: true });
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Selected reference models cannot be rendered.') }, { root: true });
      } finally {
        context.commit('setIsQueryOngoing', false);
        context.dispatch('clearFilters');
      }
    }
  }
};

export default filterRefmodelModule;