<template>
  <div class="cx-avatar">
    <div
      @mouseenter="() => { mouseHoveredOnImg = true }"
      @mouseleave="() => { mouseHoveredOnImg = false }"
      @click="cameraFlyTo">
      <slot>
        <img
          class="cx-avatar__img"
          :class="applyClass"
          :src="source"
          v-if="source"/>
      </slot>
    </div>
    <div
      class="cx-avatar__overlay"
      :class="applyClass"
      @mouseenter="() => { mouseHoveredOnImg = true }"
      @mouseleave="() => { mouseHoveredOnImg = false }"
      @click="cameraFlyTo">
      <cx-button
        flat
        icon="location_searching"
        class="cx-avatar__overlay__location"
        :class="applyClass"
        @click="cameraFlyTo">
        {{ translations.locate }}
      </cx-button>
    </div>
  </div>
</template>

<script>
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxButton from '@/components/CxButton';

export default {
  name: 'CxAvatar',
  components: {
    CxButton
  },
  mixins: [CxAnimationMixin],
  props: {
    source: String,
    isReduced: {
      type: Boolean,
      default: false
    },
    handleCameraFlyTo: Function,
  },
  data() {
    return {
      mouseHoveredOnImg: false
    };
  },
  computed: {
    applyClass() {
      const animateImage = this.applyAnimations('image');
      const animateOverlay = this.applyAnimations('overlay');
      const animateLocation = this.applyAnimations('location');
      return {
        'cx-avatar--opened': this.mouseHoveredOnImg,
        'cx-avatar--no-licence': this.isReduced,
        [animateImage]: true,
        [animateOverlay]: true,
        [animateLocation]: this.mouseHoveredOnImg
      };
    },
    translations() {
      return {
        locate: this.$gettext('Locate')
      };
    }
  },
  methods: {
    cameraFlyTo() {
      if (this.handleCameraFlyTo) this.handleCameraFlyTo();
    }
  },
};

</script>

<style lang="less">
@import '../../../common';

.cx-avatar {
  position: relative;
  top: -24px;
  left: 50px;

  .cx-avatar__img {
    position: absolute;
    width: @detailsStatsAvatarWidth;
    border-radius: @inputBorderRadiusRounded;
    cursor: pointer;
    background-color: #373c47;
    height: 150px;
  }


  .cx-avatar__overlay {
    position: absolute;
    top: 151px;
    .size(@detailsAvatarOverlayWidth, @detailsAvatarOverlayHeightClosed);
    background-color: @detailsBackgroundColor;
    border-radius: 0px 0px 8px 8px;

    &.cx-avatar--opened {
      top: 111px;
      height: @detailsAvatarOverlayHeightOpen;
      cursor: pointer;
    }

    &.cx-avatar--no-licence {
      bottom: calc(@detailsTitleContentHeight - 2px);
    }

    .cx-avatar__overlay__location {
      color: @textColor;
      font-size: @appFontSize;
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      text-align: center;

      &.cx-avatar--opened {
        opacity: 1;
      }

      .cx-avatar__overlay__location__text {
        display: inline-block;
        height: @detailsStatsAvatarTextWidth;
      }
    }
  }
}
</style>