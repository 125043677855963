<template>
  <div class="cx-kpi-card">
    <div class="cx-kpi-card__content">
      <div class="cx-kpi-card__value">
        <h1 v-if="!loading">{{ value }}</h1>
        <div class="cx-kpi-card__error" v-if="!loading && error"> {{ translations.errorText }}</div>
      </div>
      <cx-spinner v-if="loading" :size="49.5"/>
      <div class="cxu-d-flex">
        <cx-icon :icon="icon" :size="25"/>
        <h3>{{ title.toUpperCase() }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import CxSpinner from '@/components/CxSpinner';


export default {
  name: 'CxKpiCard',
  props: {
    icon: { type: String },
    value: { type: [String, Number] },
    title: { type: String },
    loading: { type: Boolean },
    error: { type: Boolean },
  },
  components: {
    CxIcon,
    CxSpinner
  },
  computed: {
    translations() {
      return {
        errorText: this.$gettext('Error fetching data'),
      };
    }
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-kpi-card {
  height: 150px;
  width: 300px;
  border-radius: 4px;
  background-color: @bgColor3;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  text-align: center;

  .cx-kpi-card__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 1 1 auto;
    gap: 8px;
  }

  .cx-kpi-card__value{
    color: @redZone;
  }

  h1 {
    font-size: 33px;
    max-width: 200px;
    .cut-overflow();
  }
  h3 {
    padding-top: 3px;
  }

  .cx-icon {
    padding-right: 5px;
    color: @textColorDisabled;
  }

}

</style>