<template>
  <div
    v-if="arePointsLoaded" class="cx-point-performance-monitor"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    v-cx-tooltip="{ text: tooltipText, show: isHovered, position: 'bottom-center', offsetLeft: -50, maxWidth: 220 }">
    <div>
      <span :class="{'cx-point-performance-monitor__counter--smooth': isSmooth, 'cx-point-performance-monitor__counter--choppy': !isSmooth}">{{ performancePointCount }}</span>
      <span> /{{ maxPointsLimit }}</span>
      <cx-icon icon="cx_as_built" class="cx-point-performance-monitor__icon"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxPointPerformanceMonitor',
  components: {
    CxIcon
  },
  data() {
    return {
      showDetails: true,
      isHovered: false,
    };
  },
  computed: {
    ...mapGetters('map', ['arePointsPerformant', 'performancePointCount', 'maxLoadablePointsCount']),
    isSmooth() {
      return this.arePointsPerformant;
    },
    arePointsLoaded() {
      return this.performancePointCount > 0;
    },
    maxPointsLimit() {
      let result = this.maxLoadablePointsCount;
      if (result > 1000) {
        result = `${result / 1000}k`;
      }
      return result;
    },
    tooltipText() {
      return this.isSmooth ?
        this.$gettext('The number of loaded points is well below limit.') :
        this.$gettext('The number of loaded points is close to the limit. Consider removing unnecessary points from the map.');
    }
  },
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-point-performance-monitor {
  cursor: pointer;
  margin-right: 8px;
  font-size: @appFontSize;

  &__counter--smooth {
    color: @smoothPerformanceColor;
  }

  &__counter--choppy {
    color: @warningColor;
  }

  &__icon{
    padding-left: 3px;
  }

  .cx-point-performance-monitor__icon {
    margin-left: 5px;
    display: inline-block;
    vertical-align: text-top;
  }
}

</style>