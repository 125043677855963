import _ from 'lodash';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import api from '@/http/api';
import { VisualizationSourceType } from '@/domain/refmodels/VisualizationSourceType.enum';
import { withMinMaxHeight } from '@/domain/entityMixins';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const mapRefmodelStore = {
  state: {
    // reference models loaded on the Object Explorer
    loadedRefmodels: [],
    // reference models hidden in the Object Explorer
    hiddenRefmodels: [],
    // shaders on reference model - would be neat if we wanted refmodel to stay colored after removing it from the map
    refmodelShaders: {}, // { uuid: [<shaderType>]},
    refmodelOpacities: {} // { uuid: opacity }
  },
  getters: {
    /**
     * All refmodels available on the map
     */
    loadedRefmodels(state) {
      return state.loadedRefmodels;
    },
    /**
     * All refmodels that are hidden from view
     */
    hiddenRefmodels(state) {
      return state.hiddenRefmodels;
    },

    refmodelShaders(state) {
      return state.refmodelShaders;
    },
    refmodelOpacities(state) {
      return state.refmodelOpacities;
    }
  },
  mutations: {
    addLoadedRefmodels(state, refmodels) {
      state.loadedRefmodels = [...state.loadedRefmodels, ...refmodels];
    },
    removeRefModelFromLoaded(state, uuid) {
      state.loadedRefmodels = state.loadedRefmodels.filter(r => r.uuid !== uuid);
    },
    hideRefModel(state, uuid) {
      state.hiddenRefmodels.push(uuid);
    },
    hideRefModels(state, uuids) {
      state.hiddenRefmodels = [...state.hiddenRefmodels, ...uuids];
    },
    showRefModel(state, uuid) {
      state.hiddenRefmodels = state.hiddenRefmodels.filter(r => r.uuid === uuid);
    },
    showRefModels(state, uuids) {
      state.hiddenRefmodels = state.hiddenRefmodels.filter(uuid => !uuids.includes(uuid));
    },
    setRefmodelShaders(state, { uuid, shaders }) {
      state.refmodelShaders = { ...state.refmodelShaders, [uuid]: shaders };
    },
    removeRefmodelShaderType(state, { uuid }) {
      state.refmodelShaders = _.omit(state.refmodelShaders, [uuid]);
    },
    setRefModelOpacity(state, { uuid, opacity }) {
      state.refmodelOpacities = { ...state.refmodelOpacities, [uuid]: opacity };
    }
  },
  actions: {
    /**
     * Fetch reference model gltf, load it to the map and extend with MinMaxHeight
     * @param context
     * @param refModel
     * @param showOnMap
     * @returns The reference model extended with its MinMaxHeight
     */
    async fetchRefModelGltf(context, { refModel, showOnMap }) {
      const { uuid, name } = refModel;
      try {
        const gltfData = await api.getReferenceModelGltf(uuid);
        const refModelWithHeight = withMinMaxHeight({ ...refModel }, gltfData);
        if (gltfData && gltfData.error) throw new Error(`${name}: ${gltfData.error.details}`);
        const data = {
          uuid,
          name,
          gltf: gltfData,
          show: showOnMap,
          minMaxHeight: refModelWithHeight.minMaxHeight,
        };
        await context.dispatch('viewer/loadReferenceModel', { ...data });
        return refModelWithHeight;
      } catch (e) {
        await context.dispatch('notifications/error', {
          exception: e,
          message: `${$gettext('An error occured while fetching model:')} ${name}`
        }, { root: true });
        return null;
      }
    },
    // -------------------- REFERENCE MODELS ----------------------------
    /**
     * Fetches Gltf and shows refmodels on the map
     * @param {*} context
     * @param {*} entities refmodels to load
     * @returns {Boolean} true if all entities has been loaded sucesfully. False otherwise
     */
    async loadRefModels(context, { entities, showOnMap }) {
      const gltfLoadedSuccess = await context.dispatch('loadGltfRefmodels', { entities, showOnMap });
      const dataSourceLoadedSuccess = await context.dispatch('loadDataSources', { entities, showOnMap });
      if (!showOnMap) context.commit('hideRefModels', entities.map(r => r.uuid));
      return gltfLoadedSuccess && dataSourceLoadedSuccess;
    },

    async loadGltfRefmodels(context, { entities, showOnMap }) {
      const refmodelsToAdd = entities
        .filter(e => !context.getters.loadedRefmodels.find(l => l.uuid === e.uuid))
        .filter(r => r.visSourceType === VisualizationSourceType.Gltf);
      const gltfList = refmodelsToAdd.map(rm => context.dispatch('fetchRefModelGltf', { refModel: rm, showOnMap }));
      const gltfPromises = await Promise.allSettled(gltfList);
      const validatedGltfRefmodelData = gltfPromises.filter(gp => gp.status === 'fulfilled').map(gp => gp.value);
      context.commit('addLoadedRefmodels', validatedGltfRefmodelData);
      validatedGltfRefmodelData.forEach((r) => { context.commit('setRefmodelShaders', { uuid: r.uuid, shaders: [] }); });
      return gltfPromises.every(i => i.status === 'ok');
    },
    async loadDataSources(context, { entities, showOnMap }) {
      const kmzRefmodels = entities.filter(r => r.visSourceType === VisualizationSourceType.Url);
      const kmzPromises = kmzRefmodels.map(k => context.dispatch('viewer/setDataSource', { ...k, show: showOnMap }));
      const kmzRefmodelData = await Promise.all(kmzPromises);
      const validatedKmzRefmodelData = kmzRefmodelData.filter(rm => rm.status === 'ok').map(rm => rm.data);
      context.commit('addLoadedRefmodels', validatedKmzRefmodelData);
      validatedKmzRefmodelData.forEach((r) => { context.commit('setRefmodelShaders', { uuid: r.uuid, shaders: [] }); });
      return kmzRefmodelData.every(i => i.status === 'ok');
    },
    /**
     * Removes reference model from the loaded list
     * If was selected, it is deselected
     * If was in the hidden list, it is removed from there
     * @param {Object} entity reference model to deselect
     */
    async unloadRefModel(context, { entity }) {
      // remove from loadedRefmodels
      context.commit('removeRefModelFromLoaded', entity.uuid);
      // remove from hidden if so
      context.commit('showRefModel', entity.uuid);
      // remove from selected and hide them
      await context.dispatch('viewer/removeReferenceModel', { uuid: entity.uuid });
      // remove all shaders from refmodel
      context.commit('removeRefmodelShaderType', { uuid: entity.uuid });
    },

    /**
     * Displays reference models from the list
     * and displays an alert if one or more of models cant be rendered
     * if refModelList is empty all reference models
     * @param {Array} refModelList list of reference models to display
     * @param {Boolean} syncObjectExplorer if true, hiddenRefModels will be updated
     * @param {Boolean} useSelected if true refModelList param is ignored, and the action is
     * executed on the selected reference model if available
     */
    async mapShowRefmodels(context, { refModelList, syncObjectExplorer = true, useSelected = false }) {
      let refModels = refModelList;
      if (useSelected === true
        && context.getters.selectedEntity
        && Object.values(RefmodelTypes).includes(context.getters.selectedEntity.entityType)) {
        refModels = [context.getters.selectedEntity];
      }
      if (syncObjectExplorer) {
        context.commit('showRefModels', refModels.map(r => r.uuid));
      }
      context.dispatch('map/viewer/showRefModels', { show: refModels.map(m => m.uuid), }, { root: true });
    },
    /**
     * Hides all refmodels from the list and updated the hiddenRefModels variable
     * @param {Array} refModelList list of rfmodels to hide
     * @param {Boolean} syncObjectExplorer if true, hiddenRefModels list will be updated
     * @param {Boolean} useSelected if true refModelList param is ignored, and the action is
     * executed on the selected reference model if available
     */
    async mapHideRefModels(context, { refModelList, syncObjectExplorer = true, useSelected = false }) {
      let refModels = refModelList || [];
      if (useSelected === true
        && context.getters.selectedEntity
        && Object.values(RefmodelTypes).includes(context.getters.selectedEntity.entityType)) {
        refModels = [context.getters.selectedEntity];
      }
      if (syncObjectExplorer) {
        context.commit('hideRefModels', refModelList.map(r => r.uuid));
      }
      const refmodels = refModels.map(m => m.uuid);
      await context.dispatch('map/viewer/hideRefModels', { hide: refmodels }, { root: true });
    },
    /**
     * Checks if there is a selectedEntity and if the entity has same same id as given
     * it will be deselected
     * @param {*} id the id of a entity to deselect
     */
    async deselectRefmodels(context, { uuids }) {
      if (context.state.selectedEntity && uuids.find(f => context.state.selectedEntity.uuid === f)) {
        await context.dispatch('viewer/deselect');
      }
    },
    async changeRefModelOpacity(context, { uuid, opacity }) {
      await context.dispatch('map/viewer/changeRefModelOpacity', { uuid, opacity }, { root: true });
      await context.commit('setRefModelOpacity', { uuid, opacity });
    },
    /**
     * Turns on a symmetric difference of currently active and passed as parameter shaders.
     * @param context
     * @param uuid: Reference model uuid.
     * @param shaders {String[]}: A list of strings with shaders to turn on.
     */
    async toggleShader(context, { uuid, shaders }) {
      const currentRefmodelShaders = context.state.refmodelShaders[uuid];
      if (!currentRefmodelShaders) throw new Error(`Can't find shader for ${uuid} refmodel`);
      const useShaders = _.xor(shaders, currentRefmodelShaders);
      context.commit('setRefmodelShaders', { uuid, shaders: useShaders });
      await context.dispatch('map/viewer/toggleShader', { uuid, shaders: useShaders }, { root: true });
    },

    /**
     * Process reference model data to initialize geocoder
     * @param {*} context
     * @param {Array< {uuid, name, path, entityType}>} refmodels
     */
    async initializeRefmodelGeocoderData(context, refmodels) {
      context.dispatch('viewer/initializeRefmodelGeocoderData', refmodels);
    }
  }
};

export default mapRefmodelStore;