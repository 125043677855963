<template>
  <div class="cx-unit-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon :icon="icon" class="map-overlay__title__icon cxu-color-highlight" :size="12"/>
      <span class="map-overlay__title__name">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { withIcon } from '@/domain/entityMixins';

export default {
  name: 'CxUnitTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.name;
    },
    icon() {
      if (!this.hoveredEntity) return '';
      return withIcon(this.hoveredEntity).icon;
    }
  },
};
</script>

<style lang="less">

</style>