/**
 * @file Contains definitions of CZML properties.
 * @author Jan Wąsak
 */
import _extend from 'lodash/assignIn';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';

import { Cartographic, UnitQuaternion, UnitActivity } from '@/utils/czml/value';

/**
 * Represents a CZML Property.
 * Properties are the building blocks of Packets.
 */
class Property {
  constructor(name) {
    if (_isNil(name)) throw new Error('Property name cannot be nil.');
    this.name = name;
    this.czml = {};
  }
  toJSON() {
    return this.czml;
  }
}
class InterpolatableProperty extends Property {
  constructor(
    name,
    epoch,
    interpolationAlgorithm,
    interpolationDegree,
    forwardExtrapolationType,
    forwardExtrapolationDuration,
    backwardExtrapolationType,
    backwardExtrapolationDuration
  ) {
    super(name);
    _extend(this.czml, {
      epoch,
      interpolationAlgorithm,
      interpolationDegree,
      forwardExtrapolationType,
      forwardExtrapolationDuration,
      backwardExtrapolationType,
      backwardExtrapolationDuration
    });
  }
}

/**
 * Represents a CZML Color Property.
 * TODO: refactor
 */
class Color extends Property {
  constructor(red, green, blue, alpha, rgbaf = false) {
    super('color');
    const props = [red, green, blue, alpha];
    if (rgbaf) {
      if (props.some(prop => prop < 0 || prop > 1)) throw new Error(`${props} are not in rgbaf format.`);
      this.rgbaf = props;
    } else {
      if (props.some(prop => prop < 0 || prop > 255)) throw new Error(`${props} are not in rgba format.`);
      this.rgba = props;
    }
  }
}

class Orientation extends Property {
  constructor(X, Y, Z, W) {
    super('orientation');
    _extend(this.czml, {
      unitQuaternion: new UnitQuaternion(X, Y, Z, W).value()
    });
  }
}

class Position extends InterpolatableProperty {
  constructor({
    epoch,
    interpolationAlgorithm,
    interpolationDegree,
    forwardExtrapolationType,
    forwardExtrapolationDuration,
    backwardExtrapolationType,
    backwardExtrapolationDuration
  }) {
    super(
      'position',
      epoch,
      interpolationAlgorithm,
      interpolationDegree,
      forwardExtrapolationType,
      forwardExtrapolationDuration,
      backwardExtrapolationType,
      backwardExtrapolationDuration
    );
    _extend(this.czml, {
      cartographicDegrees: [],
      cartographicRadians: []
    });
  }
  cartographicRadians({
    longitude, latitude, height, time = null
  } = {}) {
    const cartographic = new Cartographic(longitude, latitude, height, time);
    this.czml.cartographicRadians = this.czml.cartographicRadians.concat(cartographic.value());
    return this.czml.cartographicRadians;
  }
  cartographicDegrees({
    longitude, latitude, height, time = null
  } = {}) {
    const cartographic = new Cartographic(longitude, latitude, height, time);
    this.czml.cartographicDegrees = this.czml.cartographicDegrees.concat(cartographic.value());
    return this.czml.cartographicDegrees;
  }
}

/**
 * Represents a CZML Point Property.
 */
class Point extends Property {
  constructor(show = true, pixelSize = 1.0, color = null, outlineColor = null) {
    super('point');
    _extend(this.czml, {
      show,
      pixelSize,
      color,
      outlineColor,
    });
  }

  color(color = null) {
    if (color) {
      if (color instanceof Color) { this.color = color; } else { throw new Error('Cannot assign a non Color;'); }
    }
    return this.color;
  }
}


/**
 * Represents a CZML Clock Property.
 */
class Clock extends Property {
  constructor(interval, currentTime, multiplier = 60, range = 'LOOP_STOP', step = 'SYSTEM_CLOCK_MULTIPLIER') {
    super('clock');
    _extend(this.czml, {
      interval,
      currentTime,
      multiplier,
      range,
      step,
    });
  }
}

/**
 * Represents a CZML Model Property
 */
class Model extends Property {
  constructor({
    show,
    gltf,
    scale = 1.0,
    minimumPixelSize = 0.0,
    maximumScale,
    incrementallyLoadTextures = true,
    runAnimations = true,
    shadows = 'ENABLED',
    heightReference = 'CLAMP_TO_GROUND',
    // silhouetteColor = 'red',
    // silhouetteSize = 0.0,
    // color = 'white',
    colorBlendMode = 'HIGHLIGHT',
    colorBlendAmount = '0.5',
    distanceDisplayCondition,
  }) {

    super('model');
    _extend(this.czml, {
      show,
      gltf,
      scale,
      minimumPixelSize,
      maximumScale,
      incrementallyLoadTextures,
      runAnimations,
      shadows,
      heightReference,
      // silhouetteColor,
      // silhouetteSize,
      // color,
      colorBlendMode,
      colorBlendAmount,
      distanceDisplayCondition,
    });
  }
}


/**
* Represents CZML CustomProperty of CxActivity.
*/
class CxActivity extends Property {
  constructor(timestamp) {
    super('CxActivity');
    this.timestamp = timestamp;
    this.value();
    _extend(this.czml, []);
  }

  value() {
    const interval = new UnitActivity(this.timestamp);
    this.czml = [this.czml].concat(interval.value()).filter(i => !_isEmpty(i));
    return this.czml;
  }
}

export { Property, Color, Clock, Point, Model, Position, Orientation, CxActivity };
