/** Detected zone as reported from PA system.
 * API-wise, any string is accepted, to support other, future zone names.
 */
export enum DetectedZoneFilterValue {
  RedZone = 'RedZone',
  YellowZone = 'YellowZone',
  GreenZone = 'GreenZone',
  CabinZone = 'CabinZone',
  NotInDangerZone = 'NotInDangerZone',
  UndefinedZone = 'Undefined'
}