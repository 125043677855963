import { format, formatISO } from 'date-fns';
import api from '@/http/api';
import { fetchPointDataStoreMixin } from '@/store/detailsModule/fetchPointData.store.mixin';
import { createFilter } from '@/domain/filters/filterModelFactory';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;
const SOURCE_UUID = 'hole-details-panel-source';

const detailsHoleModule = {
  state: {
    ...fetchPointDataStoreMixin.state,
    isExporting: false,
    pointsMetadata: null,
  },
  mutations: {
    ...fetchPointDataStoreMixin.mutations,
    setIsExporting(state, isExporting) {
      state.isExporting = isExporting;
    },
    setPointsMetadata(state, payload) {
      state.pointsMetadata = payload;
    },
  },
  getters: {
    ...fetchPointDataStoreMixin.getters,
    selectedEntity(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (!selectedEntity || !Object.values(StabilizationTypes).includes(selectedEntity.entityType)) return null;
      return selectedEntity;
    },
    selectedHole(state, getters) {
      if (getters.selectedEntity) {
        const hole = getters.selectedEntity;
        return { ...hole, pointSet: state.pointsMetadata || hole.pointSet };
      }
      return null;
    },
    isExporting(state) {
      return state.isExporting;
    },
    isFetchingData(sate, getters, rootState, rootGetters) {
      if (!getters.selectedHole) return false;
      return rootGetters['cancelable/isLoading']({ actionType: 'detailsHole/fetchPointData', sourceUid: SOURCE_UUID });
    },
  },
  cancelableActions: {
    /**
     * Fetches all available metadata for given point id
     * @param {} context
     * @param {*} cancelToken
     */
    async fetchPointData(context, { cancelToken }) {
      const uuids = context.getters.selectedEntity.pointSet.points.map(p => p.uuid);
      const uuidFilter = createFilter(FilterModelNames.Id, uuids);

      const { projectUuid } = context.rootGetters;
      const projectStartDate = context.rootGetters['app/projectStartDate'];
      const endDate = new Date(Date.now());
      if (cancelToken.canceled) return;
      try {
        const pointSet = await api.filterMeasurePoints(
          projectUuid,
          formatISO(projectStartDate),
          formatISO(endDate),
          [uuidFilter],
          true,
          ['all']
        );
        context.commit('setPointsMetadata', pointSet);
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Error has occured - can\'t fetch point metadata') }, { root: true });
      }
    },
  },
  actions: {
    ...fetchPointDataStoreMixin.actions,
    async handleCameraFlyTo(context) {
      await context.dispatch('map/setCameraToEntity', context.getters.selectedHole.uuid, { root: true });
    },
    async exportPoints(context, extension) {
      try {
        context.commit('setIsExporting', true);
        // should we get the origin filters somewhere else?
        const { setUid } = context.getters.selectedHole;
        // find the parent set to get origin time range
        const { originTimeRange } = context.rootGetters['map/loadedDHSets'].find(s => s.uuid === setUid);
        if (!originTimeRange) throw new Error('Can\'t Export Hole Data - Origin Time Range not found');

        // construct uuid filter
        const uuids = context.getters.selectedHole.pointSet.points.map(p => p.uuid);
        const uuidFilter = createFilter(FilterModelNames.Id, uuids);

        const name = `ConX_Drill_Asbuilts_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        await api.exportMeasuredPoints(
          context.rootGetters.projectUuid,
          formatISO(originTimeRange.from),
          formatISO(originTimeRange.to),
          [uuidFilter],
          name,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExporting', false);
      }
    },
    async handleSelection(context) {
      // clear point metadata, not to display old data to the user
      context.commit('setPointsMetadata', null);
      // cancel all actions that are curently fetching any point data and disregard it's results
      await context.dispatch('cancelable/cancelActions', { sourceUid: SOURCE_UUID }, { root: true });
      // start fetching new data - display spinner
      await context.dispatch('fetchPointData', { sourceUid: SOURCE_UUID });
    },
    async showPointsData(context) {
      const { selectedHole } = context.getters;
      context.dispatch('showGridData', selectedHole);
    }
  }

};

export default detailsHoleModule;