<template>
  <div class="cx-metadata-single-filter">
    <div class="cx-metadata-single-filter__container" id="metadata-single-filter">
      <n-cx-select
        id="metadata-single-filter-select"
        class="cxv-select cxv-select--metadata-single-filter"
        :items="comparatorsToSelect"
        :selectedItemIds="[filter.comparators[0]]"
        @update:selectedItemIds="handleComparatorChanged">
      </n-cx-select>
      <cx-input
        id="metadata-single-filter-input"
        class="cx-input--metadata-single-filter"
        :modelValue="filter.values[0]"
        :label="filter.displayName"
        :type="filter.dataType"
        :validationRules="rules"
        @update:modelValue="handleValueChanged"
        ref="value">
      </cx-input>
    </div>
    <cx-button flat icon="close" @click="removeItem"/>
  </div>
</template>
<script>
import NCxSelect from '@/components/NCxSelect.vue';
import CxInput from '@/components/CxInput';
import CxButton from '@/components/CxButton';
import validatonRules from '@/components/validationRules';
import { helpers, maxLength } from '@vuelidate/validators';

const { required } = validatonRules();

export default {

  name: 'CxMetadataSingleFilter',
  components: {
    NCxSelect,
    CxInput,
    CxButton
  },
  props: {
    // DynamicFilterSingleVM object
    filter: Object,
    // function to be called to remove the filter
    onRemoveFilter: Function,
    // index of applied filter element
    index: Number,
    // called when filter value changed { filter, values<Array> }
    onValueChange: Function,
    // called when filter comparator changed { filter, comparators<Array> }
    onComparatorChange: Function
  },
  mounted() {
    // focus on the value field when mounted
    const el = this.$refs.value;
    const inp = el.$el.querySelector('input');
    if (inp) {
      this.$nextTick(() => inp.focus());
    }
  },
  computed: {
    comparatorsToSelect() {
      return this.filter.availableComparators.map(comparator => ({ id: comparator, title: comparator }));
    },
    rules() {
      return {
        modelValue: {
          $autoDirty: true,
          required,
          maxLength: helpers.withMessage(this.$gettext('Max 10 characters'), maxLength(10)),
        }
      };
    }
  },
  methods: {
    removeItem() {
      this.onRemoveFilter(this.filter);
    },
    handleComparatorChanged(comparator) {
      const isComparatorSelected = comparator.length;
      if (isComparatorSelected) {
        this.onComparatorChange({ filter: this.filter, comparators: [comparator[0]] });
      }
    },
    handleValueChanged(value) {
      if (this.filter.dataType === 'number') value = parseFloat(value);
      this.onValueChange({ filter: this.filter, values: [value] });
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-metadata-single-filter {
  width: 100%;
  display: flex;
  text-align: left;
  align-items: baseline;
  gap: 5px;

  .cx-icon {
    i {
      font-size: @majorFontSize;
    }
  }

  .cx-metadata-single-filter__container {
    width: 100%;
    display: flex;

    .cxv-select--metadata-single-filter {
      width: 80px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;
    }

    .cx-input--metadata-single-filter {
      width: 100%;
      margin-left: 5px;
    }

    &--multi-value {
      .cx-select {
        width: 100%;
      }
    }
  }
}
</style>