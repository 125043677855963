import http from '@/http/axios.instance';
import awarenessMessageMapper, { SendMessageResponse } from '@/http/awareness/awarenessMessage.mapper';
import { AwarenessMessageStatus } from '@/domain/awareness/enums/AwarenessMessageStatus.enum';

const sendMessage: (projectUuid: string, unitUuids: string[], message: string) => Promise<{unitId: string, messageId: string}[]> =
async (projectUuid, unitUuids, message) => {
  const url = `/api/accounts/v1/accounts/${projectUuid}/message/create/`;
  const payload = {
    type: 'general_alarm',
    content: message,
    unit_ids: unitUuids
  };
  const response = await http.post<SendMessageResponse>(url, payload);
  return awarenessMessageMapper.mapMessages(response.data);
};

const getMessageStatus: (messageId: string, abortController: AbortController) => Promise<AwarenessMessageStatus> =
 async (messageId, abortController) => {
   const url = `/api/messaging/v1/${messageId}/status/`;
   const response = await http.get(url, {
     signal: abortController.signal
   });
   return response.data.status as AwarenessMessageStatus;
 };


const waitForMessageStatus: (messageId: string, awaitedStatus: AwarenessMessageStatus, abortController: AbortController) => Promise<boolean>
 = async (messageId, awaitedStatus, abortController) => {
   const pollingInterval = 10000;
   let messageStatus: AwarenessMessageStatus;

   return new Promise((resolve, reject) => {
     const poll = async () => {
       try {
         messageStatus = await getMessageStatus(messageId, abortController);
       } catch (e) {
         reject(e);
         return;
       }
       if (messageStatus === awaitedStatus) { resolve(true); return; }
       if (abortController.signal.aborted) { resolve(false); return; }
       setTimeout(() => poll(), pollingInterval);
     };
     poll();
   });
 };

const sendSos: (projectUuid: string) => void =
  async (projectUuid) => {
    const url = `/api/accounts/v1/accounts/${projectUuid}/message/create/`;
    const payload = {
      type: 'SOS',
      content: 'sos'
    };
    await http.post(url, payload);
  };

const messageApi = {
  sendSos,
  sendMessage,
  waitForMessageStatus
};

export default messageApi;