<template>
  <div class="cx-map-info-box">
    <div class="cx-map-info-box__tabs">
      <span
        class="cx-map-info-box__tabs__tab"
        :class="isTabSelected(instruction.id)"
        v-for="(instruction, key) in instructions"
        :key="key"
        @click="selectTab(instruction.id)">
        {{ instruction.caption() }}
      </span>
    </div>
    <cx-map-instruction
      v-if="activeInstruction"
      :instruction="activeInstruction"/>
  </div>
</template>

<script>
import CxMapInstruction from './CxMapInstruction';

export default {
  name: 'CxMapInfoBox',

  components: {
    CxMapInstruction
  },

  props: {
    instructions: Array
  },

  data() {
    return {
      activeInstruction: this.instructions[0]
    };
  },

  methods: {
    selectTab(id) {
      this.activeInstruction = this.instructions.find(i => i.id === id);
    },

    isTabSelected(id) {
      return this.activeInstruction.id === id ? 'active' : '';
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-info-box {
  .size(@mapInstructionWidth, auto);
  .flex(column, flex-start, flex-start);
  position: absolute;
  top: 34px;
  right: -42px;
  background-color: @overlayControlBackgroundColorInactive;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;
  padding: 20px;

  .cx-map-info-box__tabs {
    .size(45%, auto);
    .flex(row, space-between, center);
    padding-bottom: 20px;

    .cx-map-info-box__tabs__tab {
      color: @textColorDisabled;
      font-size: @h3FontSize;
      font-weight: @headerFontWeight;
      cursor: pointer;

      &:hover {
        &:not(.active) {
          color: @textColorLowlight;
        }
      }

      &.active {
        color: @textColor;
      }
    }
  }
}
</style>