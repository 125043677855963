<template>
  <div class="cx-secondary-data-panel">
    <div class="cx-secondary-data-panel__title" v-if="title">
      {{ title }}
    </div>
    <div v-for="item of data" :key="`cx-secondary-data-panel-${item.key}`">
      <div class="item cxu-center-v" v-if="item.value">
        <div class="item-content" v-if="!item.values" :class="{'is-discarded': item.field === 'isDiscarded'}">
          <cx-icon :icon="item.icon" :size="15" :class="getColorScheme(item.icon)"/>
          <span :class="item.labelClass">{{ item.value }}</span>
          <span v-if="item.unit" class="cx-secondary-data-panel__measurement-unit">&nbsp;{{ item.unit }}</span>
        </div>
        <div class="item-content item-content--edited-values" v-if="item.edited && item.edited.length">
          <div v-for="(subItem, index) of item.edited" :key="index" class="item-content__value">
            <span :class="subItem.labelClass" v-if="subItem.value">{{ subItem.field }}</span>
            <span :class="subItem.labelClass">{{ subItem.value }}</span>
            <span v-if="subItem.unit" class="cx-secondary-data-panel__measurement-unit">&nbsp;{{ subItem.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CxSecondaryDataPanel',
  components: {
    CxIcon
  },
  props: {
    // empty value will be displayed in gray when there is no information. If not prvided 'no data' will be displayed instead
    // {icon, key, class, value, noDataText}
    secondaryData: Array,
    title: String
  },
  computed: {
    data() {
      return this.secondaryData.map((d) => {
        const key = uuidv4();
        if (!d.icon) {
          return {
            ...d,
            labelClass: 'noicon',
            key
          };
        }
        return { ...d, key };
      });
    }
  },
  methods: {
    getColorScheme(icon) {
      return icon === 'warning' ? 'cxu-color-warning' : 'cxu-color-faded';
    }
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-secondary-data-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 200px;

  .cx-secondary-data-panel__title {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
    color: @textColor;
  }

  .item {
    margin-bottom: 8px;
    margin-right: 30px;
    flex-direction: column;
    align-items: flex-start;

    .item-content {
      display: flex;
      align-items: center;

      &--edited-values {
        align-items: flex-start;
        flex-direction: column;
        max-height: 128px;
        overflow-y: auto;
        width: 100%;

        .item-content__value {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          margin-bottom: 8px;

          span {
            font-size: @minorFontSize;
            margin-left: 24px;
          }
        }
      }

      &.is-discarded {
        * {
          color: @highlightRedColor;
        }

        .cx-icon i {
          font-size: 24px !important;
        }

        span {
          margin-left: 0px;
        }
      }
    }
  }

  .cx-icon {
    display: inline-block;

    &.customIcon {
      .size(@customIconSize, @customIconSize);
      opacity: 0.3;
    }

  }
  span {
    display: inline-block;
    font-size: @appFontSize;
    vertical-align: text-top;
    margin-left: 8px;
    color: @textColor;

     &.noicon {
      margin-left: 24px;
     }
     &.noinfo {
       color: @textColorLowlight;
     }
     &.cx-secondary-data-panel__measurement-unit {
       margin-left: 0px;
       color: @textColorLowlight;
       display:inline-block;
     }
  }
}

</style>