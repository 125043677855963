import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';

const unselectableEntityTypes = [
  RefmodelTypes.RoadLineType,
  RefmodelTypes.StringlineModelType,
  RefmodelTypes.CrossFallModelType,
  RefmodelTypes.HelperFileType,
  RefmodelTypes.CoordinateFileType
];

const withCanSelect = (entity) => {
  const { entityType } = entity;
  if (Object.hasOwnProperty.call(entity, 'canSelect') || !entityType) return entity;
  const canSelect = !unselectableEntityTypes.includes(entityType);
  return { ...entity, canSelect };
};

export { withCanSelect };