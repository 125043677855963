import { v4 as uuidv4 } from 'uuid';
/**
 * Token passed to actions
 */
export class CancelToken {

  _canceled = false;

  constructor() {
    this._canceled = false;
  }
  get canceled() {
    return this._canceled;
  }
}

/**
 * Source of cancelToken, keeps it's token and can set it canceled
 */
export class CancelTokenSource {

  _cancelToken;

  token() {
    if (!this._cancelToken) this._cancelToken = new CancelToken();
    return this._cancelToken;
  }
  cancel() {
    this._cancelToken._canceled = true;
  }
}

/**
 * Represents an action, that can be cancelled, by using cancelTokenSource
 */
export class CancellableAction {
  uid;
  sourceUid;
  actionType;
  cancelTokenSource;
  cancelToken;

  constructor(uid, sourceUid, actionType) {
    this.sourceUid = sourceUid || uuidv4();
    this.actionType = actionType;
    this.cancelTokenSource = new CancelTokenSource();
    this.cancelToken = this.cancelTokenSource.token();
    this.uid = uid || uuidv4();
  }
}

/**
 * Returns a function, that calls original action, passing to it additional
 * cancelToken parameter. Payload of a new action has to contain one of parameters: sourceUid or uid
 * Multiple actions can have the same sourceUid, so the user is able to cancel all actions with the same sourceUid,
 * hovever uid should be unique for the action
 * @param {} action a function that we want to call as cancelable
 * @param {} actionType name of the action, that will be dispatched
 */
const cancelableAction = (action, actionType, moduleNamespace) => {
  return async (context, payload) => {
    let result = null;
    if (!payload) throw new Error(`Cancellable action ${actionType} must have a sourceUid or uid`);
    const { sourceUid, uid } = payload;
    if (!sourceUid || uid) throw new Error(`Cancellable action ${actionType} must have a sourceUid or uid`);
    // adding action to the cancellable store
    const req = new CancellableAction(uid, sourceUid, `${moduleNamespace}/${actionType}`);
    context.commit('cancelable/addActionRequest', req, { root: true });
    // there is no catch here as we let the error slip to the code that called the action
    // so it can be handled there.
    // todo: catch and log error
    try {
      result = await action(context, { ...payload, cancelToken: req.cancelToken });
    } finally {
      // after action is complete, remove request from cancellable list
      // we need also to take it down if action has thrown an error
      context.commit('cancelable/completeActionRequest', req, { root: true });
    }
    return result;
  };
};

/**
 * For all cancelableActions in given module creates an action with the same name
 * that can be called using context.dispatch.
 * Canellable action will be passed cancelToken param to check for cancelation status
 * @param {*} module that actions we want to register as cancelables
 */
const registerCancelableActions = (module, moduleNamespace) => {
  const cancelables = {};
  if (!module.cancelableActions) return {};
  Object.keys(module.cancelableActions).forEach((key) => {
    const action = module.cancelableActions[key];
    cancelables[key] = cancelableAction(action, key, moduleNamespace);
  });
  return cancelables;
};

/**
 * Creates actions from module cancelableActions object
 * @param {Object} module module to check for cancelableActions
 * @param {String} moduleNamespace registered namespace of the module
 */
const registerCancelableModule = (module, moduleNamespace) => {
  return {
    ...module,
    actions: { ...module.actions, ...registerCancelableActions(module, moduleNamespace) }
  };
};

/*
/ Cancelable store module
*/
const cancelableStoreModule = {
  state: {
    // map of pending cancellable requests
    pendingActions: []
  },
  mutations: {
    /**
     * Adds pending cancellable request to the map
     * @param {CancellableRequest} request
     */
    addActionRequest(state, request) {
      state.pendingActions.push(request);
    },
    completeActionRequest(state, request) {
      state.pendingActions = state.pendingActions.filter(r => r.uid !== request.uid);
    },
    completeActionRequests(state, requestToCancel) {
      state.pendingActions = state.pendingActions.filter(r => !requestToCancel.some(rtc => rtc === r));
    }
  },
  getters: {
    /**
     * True if pendingActions contains action with a given sourceUid or/and actionType
     */
    isLoading: state => ({ sourceUid, actionType }) => {
      if (!sourceUid && !actionType) throw new Error('Required Parameter: sourceUid or uid');
      const sourcedRequest = sourceUid ? state.pendingActions.some(r => r.sourceUid === sourceUid) : true;
      const typedRequest = actionType ? state.pendingActions.some(r => r.actionType === actionType) : true;
      return sourcedRequest && typedRequest;
    }
  },
  actions: {
    /**
     * Sets cancelToken for ongoing cancelable actions. One of parameters (sourceUid, uid) needs to be present
     * If sourceUid is present, uid param is ignoredbundleRenderer.renderToString
     * @param {String} sourceUid uid of the source of action
     * @param {String} uid uuid of the action
     */
    cancelActions(context, { sourceUid, uid }) {
      if (!sourceUid && !uid) throw new Error('Required Parameter: sourceUid or uid');
      const requestToCancel = sourceUid ?
        context.state.pendingActions.filter(r => r.sourceUid === sourceUid) :
        context.state.pendingActions.filter(r => r.uid === uid);
      requestToCancel.forEach((r) => {
        r.cancelTokenSource.cancel();
      });
      context.commit('completeActionRequests', requestToCancel);
    }
  }
};

export { cancelableStoreModule, registerCancelableModule };