import * as d3 from 'd3';
import calc from './calc.helper';

const HANDLE_CLASS = 'hover-handle';
const HANDLE_LINE_CLASS = 'hover-handle_line';
const HANDLE_CAPTION1_CLASS = 'hover-handle_caption1';
const HANDLE_CAPTION1_TEXT_CLASS = 'hover-handle_caption1_text';
const HANDLE_CAPTION2_CLASS = 'hover-handle_caption2';
const HANDLE_CAPTION2_TEXT_CLASS = 'hover-handle_caption2_text';

/**
 * Additional layer to rener hover
 */
export default class D3ChartHoverLayer {

  // d3 element for the data layer
  layer;
  hoverAreaLayer;
  hoverElementsLayer;

  visualProps = {
    transition: d3.transition().duration(2500),
    barWidth: null,
    size: null,
    margin: null,
    hoverAreaMargin: 1,
    caption1: {
      width: 43,
      height: 18,
      margin: { top: 3 },
      textMargin: { top: 12, left: 5 }
    },
    caption2: {
      width: 35,
      height: 16,
      margin: { top: 25 },
      textMargin: { top: 10, left: 5 }
    }
  }

  svg;
  scaleX;
  scaleY;

  constructor(svg, style) {
    this.visualProps.barWidth = style.barWidth;
    this.visualProps.size = style.size;
    this.visualProps.margin = style.margin;
    this.svg = svg;

  }

  moveToFront() {
    this.layer.moveToFront();
  }

  plot(chartData, scaleX, scaleY) {
    this.layer = this.svg
      .append('g')
      .attr('class', 'hover-layer');

    this.scaleX = scaleX;
    this.scaleY = scaleY;
  }

  /**
   * Call to render hover over element
   */
  hover(element) {
    const baseClass = 'chart__barChart--bar';
    d3.selectAll(`#bar--chart${element.data.uuid}`).attr('class', `${baseClass} hover`);
    this._renderHandle(element);
  }

  /**
   * Call to remove hover from element
   */
  dehover(element) {
    const baseClass = 'chart__barChart--bar';
    d3.selectAll(`#bar--chart${element.data.uuid}`).attr('class', baseClass);
    this._renderHandle(null);
  }

  _renderHandle(element) {
    const { scaleX } = this;
    const { size, margin } = this.visualProps;

    const x = d => calc.clcXMiddle(scaleX, d.data.date);
    const yStart = margin.top;
    const yEnd = size.height - (margin.bottom);
    const handleElements = this.layer.selectAll(`.${HANDLE_CLASS}`).data(element ? [element] : []);

    const handlesToUpdate = handleElements.select(`.${HANDLE_LINE_CLASS}`);
    handlesToUpdate
      .attr('x1', d => x(d))
      .attr('x2', d => x(d))
      .attr('y1', yStart)
      .attr('y2', yEnd);
    const createdHandleElements = handleElements
      .enter()
      .append('g')
      .attr('class', `${HANDLE_CLASS}`);
    createdHandleElements
      .append('line')
      .on('click', this._handleMouseClick)
      .attr('class', `${HANDLE_LINE_CLASS}`)
      .attr('x1', d => x(d))
      .attr('x2', d => x(d))
      .attr('y1', yStart)
      .attr('y2', yEnd);
    handleElements.exit().remove();

    this._renderCaption1(handleElements, createdHandleElements);
    this._renderCaption2(handleElements, createdHandleElements);
  }

  _renderCaption1(handleElements, createdHandleElements) {
    const { scaleX } = this;
    const { size } = this.visualProps;
    const { width, margin, height } = this.visualProps.caption1;

    const x = d => calc.clcXMiddle(scaleX, d.data.date) - (width / 2.0);
    const y = (size.height + margin.top) - this.visualProps.margin.bottom;
    const textX = d => calc.clcXMiddle(scaleX, d.data.date);
    const textY = y + height / 2.0 + 4;

    const captionElementsUpdate = handleElements.select(`.${HANDLE_CAPTION1_CLASS}`);
    captionElementsUpdate
      .attr('x', d => x(d))
      .attr('y', y);
    createdHandleElements
      .append('rect')
      .attr('class', HANDLE_CAPTION1_CLASS)
      .attr('width', width)
      .attr('height', height)
      .attr('x', d => x(d))
      .attr('y', y);
    const textElementsUpdate = handleElements.select(`.${HANDLE_CAPTION1_TEXT_CLASS}`);
    textElementsUpdate
      .text(d => d.data.label)
      .attr('x', d => textX(d))
      .attr('y', textY);

    createdHandleElements
      .append('text')
      .attr('class', HANDLE_CAPTION1_TEXT_CLASS)
      .text((d) => { return d.data.label; })
      .attr('x', d => textX(d))
      .attr('y', textY);
  }

  _renderCaption2(handleElements, createdHandleElements) {
    const { scaleX } = this;
    const { size } = this.visualProps;
    const { width, margin, height } = this.visualProps.caption2;

    const x = d => calc.clcXMiddle(scaleX, d.data.date) - (width / 2.0);
    const y = (size.height + margin.top) - this.visualProps.margin.bottom;
    const textX = d => calc.clcXMiddle(scaleX, d.data.date);
    const textY = y + height / 2.0 + 4;

    const caption2Update = handleElements.select(`.${HANDLE_CAPTION2_CLASS}`);
    caption2Update
      .attr('x', d => x(d))
      .attr('y', y);

    createdHandleElements
      .append('rect')
      .attr('class', HANDLE_CAPTION2_CLASS)
      .attr('width', width)
      .attr('height', height)
      .attr('x', d => x(d))
      .attr('y', y);

    const textUpdate = handleElements.select(`.${HANDLE_CAPTION2_TEXT_CLASS}`);
    textUpdate
      .text(d => `${d.data.totalProgressRatio}`)
      .attr('x', d => textX(d))
      .attr('y', textY);

    createdHandleElements
      .append('text')
      .attr('class', HANDLE_CAPTION2_TEXT_CLASS)
      .text(d => `${d.data.totalProgressRatio}`)
      .attr('x', d => textX(d))
      .attr('y', textY);
  }

}