<template>
  <div class="cx-message-popup-title">
    <div class="cx-message-popup-title__icon" :class="{ 'cx-message-popup-title__icon--error': isError }">
      <cx-icon :icon="icon" :size="20"/>
    </div>
    <span class="cx-message-popup-title__text">{{ title }}</span>
  </div>
</template>

<script>
import { AwarenessMessageStatusIcon } from '@/domain/awareness/enums/AwarenessMessageStatusIcon.enum';
import { AwarenessMessageStatus } from '@/domain/awareness/enums/AwarenessMessageStatus.enum';
import { AwarenessMessageErrorStatuses } from '@/domain/awareness/enums/AwarenessMessageErrorStatuses.enum';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxMessagePopupTitle',
  components: { CxIcon },
  props: {
    unit: { type: Object },
    status: { type: String },
    showWarning: { type: Boolean, required: true }
  },
  computed: {
    icon() {
      if (this.showWarning) return 'mdi-help';
      return AwarenessMessageStatusIcon[this.status];
    },
    isError() {
      return Object.values(AwarenessMessageErrorStatuses).includes(this.status);
    },
    title() {
      if (this.showWarning) return this.translations.warning;
      const machine = this.unit ? this.unit.name : this.translations.machine;
      const headline = this.translations[this.status];
      return this.isError ? headline : `${headline} ${machine}`;
    },
    translations() {
      return {
        machine: this.$gettext('machine'),
        warning: this.$gettext('Are you sure you want to close this popup?'),
        [AwarenessMessageStatus.Unsent]: this.$gettext('Send message to'),
        [AwarenessMessageStatus.Sending]: this.$gettext('Sending message to'),
        [AwarenessMessageStatus.Sent]: this.$gettext('Sending message to'),
        [AwarenessMessageStatus.Received]: this.$gettext('Waiting for acknowledgement of'),
        [AwarenessMessageStatus.Acknowledged]: this.$gettext('Message has been acknowledged by'),
        [AwarenessMessageStatus.SendingError]: this.$gettext('Sending failed'),
        [AwarenessMessageStatus.ReceptionError]: this.$gettext('Sending failed'),
        [AwarenessMessageStatus.AcknowledgementError]: this.$gettext('Sending failed')
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-message-popup-title {
    display: flex;
    align-items: center;

    .cx-message-popup-title__icon {
      display: flex;
      justify-content: center;
      min-height: 40px;
      min-width: 40px;
      background-color: @textColorHighlight;
      border: 1px solid transparent;
      border-radius: 50%;

      &--error {
        background-color: @errorAlertColor;
      }

      .cx-icon {
        color: #252a35;
      }
    }

    .cx-message-popup-title__text {
      margin: 0 100px 0 20px;
    }
  }
</style>