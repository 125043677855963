<template>
  <div
    v-if="isLastFramesPerSecond" class="cx-frame-rate-monitor"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    v-cx-tooltip="{ text: tooltipText, show: isHovered, position: 'bottom-center', offsetLeft: -50, maxWidth: 220 }">
    <div class="cx-frame-rate-monitor__counter" :class="{'cx-frame-rate-monitor__counter--smooth': isSmooth, 'cx-frame-rate-monitor__counter--choppy': !isSmooth}">
      {{ lastFramesPerSecond.fps }}
    </div> FPS
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CxFrameRateMonitor',
  data() {
    return {
      showDetails: true,
      isHovered: false,
    };
  },
  computed: {
    ...mapGetters('map', ['lastFramesPerSecond']),
    isSmooth() {
      return !this.lastFramesPerSecond.warn;
    },
    isLastFramesPerSecond() {
      const { fps } = this.lastFramesPerSecond;
      return Number.isFinite(fps);
    },
    tooltipText() {
      return this.isSmooth ?
        this.$gettext('Rendering performance is fine.') :
        this.$gettext('Rendering performance is worse than usual. Consider removing unnecessary objects from the map.');
    }
  },
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-frame-rate-monitor {
  .flex(row, space-between, center);
  cursor: pointer;
  padding-right: 8px;

  &__counter{
    margin-right: 3px;
  }
  &__counter--smooth {
    color: @smoothPerformanceColor;
  }

  &__counter--choppy {
    color: @warningColor;
  }
}

</style>