import cesiumStore from '@/3dapi/cesium.store';

const viewerStoreModule = {
  state: {},
  getters: {},
  actions: {
    ...cesiumStore.mapInterface,
    async initialize(context) {

      const enableTerrain = context.rootState.app.mapTerrain;

      await cesiumStore.initialize('map', enableTerrain, context);
      context.dispatch('initializeInfoBox');
      context.dispatch('initializeCompass');
      context.dispatch('handlePicking');
    },
  },
  mutations: {}
};

export default viewerStoreModule;