import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { Unit } from '@/domain/units/Unit';

const metadataWarning = 'No reference model selected';

const createUnit = ({
  uuid,
  entityType,
  name,
  createdAt,
  updatedAt,
  lat,
  lon,
  timestamp,
  altitude,
  activeRefmodelName,
  note
}: {
  uuid: string,
  entityType: UnitTypes,
  name: string,
  createdAt: Date | null,
  updatedAt: Date | null,
  lat?: number,
  lon?: number,
  timestamp?: number,
  altitude?: number,
  activeRefmodelName: string | null,
  note?: string
}): Unit => {
  return {
    uuid,
    entityType: entityType || UnitTypes.DefaultUnitType,
    name,
    createdAt,
    updatedAt,
    lat,
    lon,
    timestamp,
    altitude,
    activeRefmodelName,
    note
  };
};

export {
  createUnit,
  metadataWarning
};