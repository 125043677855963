import _ from 'lodash';
import { createPointSet } from '@/domain/pointSet/PointSetFactory';
import unitMapper from '@/http/unit.mapper';
import { createEarthmovingPoint } from '@/domain/point/PointFactory';
import { v4 as uuidv4 } from 'uuid';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { BarChartVolume } from '@/domain/point/BarChartVolume.class';
import { ChartVolumes } from '@/domain/point/ChartVolumes';
import { EarthmovingPoint } from '@/domain/point/EarthmovingPoint';
import { Point } from '@/domain/point/Point';

export interface Coordinates {
  Lat: number,
  Long: number,
  H: number,
  timestamp: string
}

interface EarthmovingPointResponse {
  _id: string,
  Lat: number,
  Long: number,
  H: number,
  HeightDistance: number,
  timestamp: string,
  last_updated_at: string | null,
  LogName: string,
  equipment_type: string,
  unit_type: UnitTypes,
  UnitType: UnitTypes
}

export interface DiffModelResponse {
  result: {
    diffmodel: {
      area_unit: string,
      calc_type: string,
      error: string,
      results: any[],
      volume_unit: string
    },
    result_type: string,
    error?: string
  }
}

const mapPointsFromStandardToNeh = (points: Coordinates[] | Point[]): Coordinates[] => {
  const coordinatesList: Coordinates[] = [];
  points.forEach((point) => {
    coordinatesList.push({
      Lat: point.Lat || point.latitude,
      Long: point.Long || point.longitude,
      H: point.H || point.ellipsoidHeight,
      timestamp: point.logTime,
    });
  });
  return coordinatesList;
};

// Data returned from this function is consumed directly by Cesium map
const mapVolumeDiffResponse = (diffModel: DiffModelResponse): any => {
  if (!diffModel || !diffModel.result) { throw new Error('No diff model returned!'); }
  if (diffModel.result.diffmodel.error) { throw new Error(diffModel.result.error); }
  if (!(diffModel.result.diffmodel.results[0].output)) { throw new Error('No diff model returned!'); }
  return diffModel.result.diffmodel.results[0];
};

const mapTargettedPoint = (point: EarthmovingPointResponse): EarthmovingPoint => {
  return createEarthmovingPoint({
    uuid: point._id,
    latitude: point.Lat,
    longitude: point.Long,
    ellipsoidHeight: point.H,
    deltaHeight: point.HeightDistance,
    lastUpdatedAt: point.last_updated_at,
    logTime: point.timestamp,
    pointName: point.LogName,
    equipmentType: point.equipment_type,
    unitType: unitMapper.mapUnitTypeToEntityType(point.unit_type || point.UnitType)
  });
};

interface VolumeChartDataResponse {
  cut_volume: number,
  fill_volume: number,
  error: string | null,
  targeted_points: EarthmovingPointResponse[],
}

type VolumeChartDataProgressResponse = {
  progress: number
} & Record<string, VolumeChartDataResponse>

export interface VolumeChartResponse {
  chart_data: VolumeChartDataProgressResponse,
  error: string | null,
  result_type: string
}

export interface ChartProgress {
  data: VolumeChartResponse,
  progress: number
}


const mapChartVolumes = (chartVolumes: Record<string, VolumeChartDataResponse>) => {
  const volumeCalculations = Object.keys(chartVolumes).map((k) => {
    const { targeted_points, cut_volume, fill_volume, error } = chartVolumes[k];
    const uuid = uuidv4();
    const points = targeted_points.map(p => mapTargettedPoint(p));
    const pointSet = createPointSet({ uuid, name: null, points, totalCount: points.length, originFilters: null, originTimeRange: null });
    const data = new BarChartVolume(uuid, k, pointSet, cut_volume, fill_volume, error);
    return data;
  });
  return _.sortBy(volumeCalculations, v => v.timestamp);
};

const mapAsyncChartVolumes = (response: VolumeChartResponse): ChartVolumes | ChartProgress => {
  let data: BarChartVolume[] | VolumeChartResponse = response;
  let { progress } = response.chart_data;
  if (response.result_type === 'volume-chart') {
    data = mapChartVolumes(response.chart_data);
    progress = 1;
    return { data, progress } as ChartVolumes;
  }
  return { data, progress } as ChartProgress;
};


export const volumeMapper = {
  mapVolumeDiffResponse,
  mapPointsFromStandardToNeh,
  mapChartVolumes,
  mapAsyncChartVolumes
};
