import _zipWith from 'lodash/zipWith';
import { FilterModel } from '@/domain/filters/FilterModel';
import { Operators } from '@/domain/filters/Operators.enum';

export const filterMapper = {
  /**
 * Maps filterModel and metadataFilterModel to the api paramter
 */
  map: (filters: FilterModel[]): {field: string, operator: Operators, values: {value: boolean | number | string, comparator: string}[]}[] => {
    return filters.map(f => {
      return {
        field: f.fieldName,
        operator: f.operator,
        values: _zipWith(f.values as (boolean | number | string)[], f.comparators, (value, comparator) => { return { value, comparator }; })
      };
    });
  }
};
