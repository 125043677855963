import messageApi from '@/http/awareness/awarenessMessage.api';
import { AwarenessMessageStatus } from '@/domain/awareness/enums/AwarenessMessageStatus.enum';

const abortController = new AbortController();

const detailsUnitMessageModule = {
  state: {
    message: '',
    messageId: null,
    messageStatus: AwarenessMessageStatus.Unsent,
  },
  getters: {
    message(state) {
      return state.message;
    },
    messageId(state) {
      return state.messageId;
    },
    messageStatus(state) {
      return state.messageStatus;
    }
  },
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    setMessageId(state, messageId) {
      state.messageId = messageId;
    },
    setMessageStatus(state, messageStatus) {
      state.messageStatus = messageStatus;
    }
  },
  actions: {
    async sendMessage(context) {
      context.commit('setMessageStatus', AwarenessMessageStatus.Sending);
      const { projectUuid } = context.rootGetters;
      const { message, selectedUnit } = context.getters;
      const unitUuids = [selectedUnit.uuid];
      try {
        const sentMessages = await messageApi.sendMessage(projectUuid, unitUuids, message);
        const [sentMessage] = sentMessages;
        context.commit('setMessageId', sentMessage.messageId);
        context.commit('setMessageStatus', AwarenessMessageStatus.Sent);
      } catch (error) {
        context.commit('setMessageStatus', AwarenessMessageStatus.SendingError);
      }
      await context.dispatch('confirmMessageReception');
      await context.dispatch('confirmMessageAcknowledgement');
    },
    async confirmMessageReception(context) {
      const { messageId } = context.getters;
      const awaitedStatus = AwarenessMessageStatus.Received;
      try {
        const isMessageReceived = await messageApi.waitForMessageStatus(messageId, awaitedStatus, abortController);
        if (isMessageReceived) context.commit('setMessageStatus', awaitedStatus);
      } catch (error) {
        context.commit('setMessageStatus', AwarenessMessageStatus.ReceptionError);
      }
    },
    async confirmMessageAcknowledgement(context) {
      const { messageId } = context.getters;
      const awaitedStatus = AwarenessMessageStatus.Acknowledged;
      try {
        const isMessageAcknowledged = await messageApi.waitForMessageStatus(messageId, awaitedStatus, abortController);
        if (isMessageAcknowledged) context.commit('setMessageStatus', awaitedStatus);
      } catch (error) {
        context.commit('setMessageStatus', AwarenessMessageStatus.AcknowledgementError);
      }
    },
    async finalizeMessagePopup(context) {
      if (abortController) abortController.abort();
      context.commit('setMessage', '');
      context.commit('setMessageId', null);
      context.commit('setMessageStatus', AwarenessMessageStatus.Unsent);
    },
  }
};

export default detailsUnitMessageModule;