import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const navigationInstructions = [
  {
    id: 'mouse',
    caption() {
      return $gettext('Mouse');
    },
    content: [
      {
        action() {
          return $gettext('Pan View');
        },
        hint() {
          return $gettext('Left Click + Drag');
        },
        iconUrl: 'instructions/mouse-pan-view.png'
      },
      {
        action() {
          return $gettext('Zoom View');
        },
        hint() {
          return $gettext('Right Click + Drag or Mouse Wheel Scroll');
        },
        iconUrl: 'instructions/mouse-zoom-view.png'
      },
      {
        action() {
          return $gettext('Rotate View');
        },
        hint() {
          return $gettext('Middle Click + Drag or CTRL + Left/Right Click + Drag');
        },
        iconUrl: 'instructions/mouse-rotate-view.png'
      }
    ]
  },
  {
    id: 'touch',
    caption() {
      return $gettext('Touch');
    },
    content: [
      {
        action() {
          return $gettext('Pan View');
        },
        hint() {
          return $gettext('One Finger Drag');
        },
        iconUrl: 'instructions/touch-pan-view.png'
      },
      {
        action() {
          return $gettext('Zoom View');
        },
        hint() {
          return $gettext('Two Finger Pinch');
        },
        iconUrl: 'instructions/touch-zoom-rotate-view.png'
      },
      {
        action() {
          return $gettext('Tilt View');
        },
        hint() {
          return $gettext('Two Finger Drag, Same Direction');
        },
        iconUrl: 'instructions/touch-tilt-view.png'
      },
      {
        action() {
          return $gettext('Rotate View');
        },
        hint() {
          return $gettext('Two Finger Drag, Opposite Direction');
        },
        iconUrl: 'instructions/touch-zoom-rotate-view.png'
      }
    ]
  }
];

export default navigationInstructions;