<template>
  <div
    class="cx-object"
    :class="applyClass"
    @mouseover="setHover(true)"
    @mouseout="setHover(false)">
    <cx-button
      flat
      v-if="!isLoading"
      :icon="icons.visibilityIcon"
      @click="toggleVisibility"
      :iconSize="12"
      :tooltip="{
        text: visbilityTooltip,
        animationDelay: 0.7,
        offsetTop: -5
      }"
      :class="{...status, '--eye-closed': !isVisible}"
      class="variant-oe-eye"/>
    <cx-spinner v-else class="cx-object__spinner" :size="12" :width="1"/>
    <cx-button
      flat
      :icon="icons.selectionIcon"
      @click="setSelection"
      :iconSize="12"
      :class="status"
      class="variant-oe"
      :tooltip="{
        text: selectionTooltip,
        animationDelay: 0.7,
        offsetTop: -5
      }"
      colorScheme="cxu-color-faded"
      :disabled="!object.canSelect"/>
    <cx-button
      flat
      :icon="icons.flyTo"
      @click="setCameraToEntity(object.uuid)"
      :iconSize="12"
      :class="status"
      class="variant-oe"
      :tooltip="{
        text: translations.flyTo,
        animationDelay: 0.7,
        offsetTop: -5
      }"
      :disabled="!object.canFlyTo"/>
    <div class="cx-object__icons__child--track" v-if="object.level"></div>
    <cx-icon
      class="cx-object__icons__type"
      :icon="icons.typeIcon"
      :size="12"
      :interactive="null"/>
    <span
      class="cx-object__name"
      :class="status"
      @mouseover="isNameHovered = true"
      @mouseout="isNameHovered = false"
      v-cx-tooltip="{
        text: object.path,
        show: object.path && isNameHovered,
        position: 'top-left',
        offsetTop: -5,
        maxWidth: 200,
        animationDelay: 0.7
      }">
      {{ object.name }}
    </span>
    <div class="cx-object__delete-icon" :class="{ 'active': isHovered }">
      <cx-button
        flat
        v-if="!isLoading"
        icon="delete"
        @click="remove"
        :iconSize="13"
        :class="status"
        class="variant-oe"
        :tooltip="{
          text: translations.delete,
          animationDelay: 0.7,
          offsetTop: -5
        }"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxLicenceMixin from '../../mixins/CxLicence.mixin';
import CxIcon from '@/components/CxIcon';
import CxButton from '@/components/CxButton';
import iconHelper from '@/viewModel/iconHelper';
import { withIcon } from '@/domain/entityMixins';
import CxSpinner from '@/components/CxSpinner.vue';

export default {
  name: 'CxObject',
  data: () => {
    return {
      animationClass: 'newItemBgAnimation',
      useAnimation: true,
      isNameHovered: false,
    };
  },
  props: {
    object: Object
  },
  components: {
    CxIcon,
    CxButton,
    CxSpinner
  },
  mounted() {
    setTimeout(() => {
      this.useAnimation = false;
    }, 1500);
  },
  mixins: [CxAnimationMixin, CxLicenceMixin],
  computed: {
    ...mapGetters('map', ['selectedEntity', 'hoveredEntity', 'hiddenEntities', 'mapObjectsLoading']),
    ...mapGetters('shell', ['isFilterPanelOpened']),
    isVisible() {
      return !this.hiddenEntities.some(e => e === this.object.uuid);
    },
    isLoading() {
      return this.mapObjectsLoading.includes(this.object.uuid);
    },
    isSelected() {
      if (!this.selectedEntity) return false;
      return this.object.uuid === this.selectedEntity.uuid;
    },
    isHovered() {
      if (!this.hoveredEntity) return false;
      return this.object.uuid === this.hoveredEntity.uuid;
    },
    icons() {
      const { entityType, type, icon } = withIcon(this.object);
      return {
        visibilityIcon: this.isVisible ? 'mdi-eye' : 'mdi-eye-off',
        selectionIcon: this.isSelected ? 'mdi-checkbox-marked-circle' : 'mdi-brightness-1',
        typeIcon: icon || iconHelper.getIcon(entityType, type),
        flyTo: 'location_searching',
      };
    },
    visbilityTooltip() {
      return this.isVisible ? this.$gettext('Hide') : this.$gettext('Show');
    },
    selectionTooltip() {
      return this.isSelected ? this.$gettext('Deselect') : this.$gettext('Select');
    },
    status() {
      return {
        selected: this.isSelected,
        hovered: this.isHovered,
      };
    },
    applyClass() {
      const animation = this.applyAnimations('addNewItem');
      return {
        'cx-object--selected': this.isSelected,
        'cx-object--hovered': this.isHovered,
        [animation]: this.useAnimation
      };
    },
    translations() {
      return {
        flyTo: this.$gettext('Fly to'),
        delete: this.$gettext('Delete')
      };
    }
  },
  methods: {
    ...mapActions('map', [
      'setEntityVisibility',
      'setEntitySelected',
      'setEntityHovered',
      'removeEntityFamily',
      'setCameraToEntity',
    ]),
    toggleVisibility() {
      this.setEntityVisibility({
        uuid: this.object.uuid,
        options: { '2D': !this.isVisible, '3D': false },
        isVisible: !this.isVisible
      });
    },
    setSelection() {
      if (!this.isSelected) {
        this.setEntitySelected({
          uuid: this.object.uuid,
          type: this.object.entityType
        });
      } else {
        this.setEntitySelected({
          uuid: null,
          type: null
        });
      }
    },
    setHover(isObject) {
      if (isObject) {
        this.setEntityHovered({
          uuid: this.object.uuid,
          type: this.object.entityType
        });
      } else {
        this.setEntityHovered({
          uuid: null,
          type: null
        });
      }
    },
    remove() {
      this.removeEntityFamily({ uuids: [this.object.uuid] });
    }
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-object {
  width: 100%;
  padding: 5px 7px;
  margin-left: 3px;
  border-radius: @inputBorderRadius;
  display: flex;
  font-size: @appFontSize;

  &__spinner {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
  }

  &.cx-object--hovered {
    background-color: @objectExplorerItemHoverBGColor;
    background-size: cover;
  }

  &.cx-object--selected {
    background-color: @objectExplorerItemSelectionBGColor;
    background-size: cover;

    &.hovered {
      background-color: @objectExplorerItemSelectionHoverBGColor;
      background-size: cover;
    }
  }

  .cx-button {
    padding: 0px 4px;
    &.variant-oe {
      color: @iconColor;

      &[disabled] {
        color: darken(@iconColor, 20%);
      }
      &:hover:not([disabled]):not(.cx-button--loading):not(.flat) {
        color: lighten(@iconColor, 8%) !important;
      }
      &.selected {
        color: @textColorHighlight;
      }
    }

   &.variant-oe-eye {
      color: @textColorHighlight;

      &.--eye-closed {
        color: @textColorLowlight;
      }

      &[disabled] {
        color: darken(@iconColor, 20%);
      }
      &:hover:not([disabled]):not(.cx-button--loading):not(.flat) {
        color: lighten(@iconColor, 8%) !important;
      }
      &.selected {
        color: @textColorHighlight;
      }
    }


  }


  .cx-object__icons__type {
    padding: 0px 4px;
    color: @iconColor;
    &.selected,
    &.visibility {
      color: @objectExplorerIconActiveColor;
    }
  }

  .cx-object__name {
    width: 100%;
    color: @objectExplorerIconBaseColor;
    padding-left: 5px;
    font-size: @appFontSize;
    padding-top: 2px;
    .cut-overflow();
  }

  .cx-object__delete-icon {
    width: @objectExplorerItemDeleteIconWidth;
    opacity: 0;
    display: flex;

    .cx-icon {
      padding: 0 0 0 8px;
    }

    &.active {
      opacity: 1;
    }
  }

  .cx-object__icons__child--track {
    background-image: url('../../../public/dotts.png');
    .size(@objectExplorerItemChildTrackSize, @objectExplorerItemChildTrackSize);
    padding-left: 10px;
    margin-left: 9px;
    opacity: 0.3;
  }
}
</style>