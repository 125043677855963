import translationService from '@/services/translation.service';
import datetimehelper from '@/utils/datetimehelper';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export class RefModelDetailsViewModel {
  _refModel;
  constructor(refModel) {
    this._refModel = { ...refModel };
  }

  get avatar() {
    return 'avatars/pattern1-avatar.jpg';
  }
  get subtitle() {
    const refModelType = translationService.get(this._refModel.entityType);
    const prefix = $gettext('Type:');
    const field = `${prefix} ${refModelType}`;
    const value = field;
    const icon = 'cx_ref_model';
    return { field, value, icon };
  }
  get name() {
    const { name } = this._refModel;
    return name || '';
  }
  get tooltip() {
    const { path, name } = this._refModel;
    if (path) return `${path}\\${name}`;
    return `${name}`;
  }
  get source() {
    const { sourceType, sourceName } = this._refModel;
    const field = {
      user: $gettext('Source: User'),
      unit: $gettext('Source: Unit'),
      integrator: $gettext('Source: Integrator'),
      noSource: $gettext('Source')
    };
    const caption = {
      noSource: $gettext('no data')
    };
    const icon = {
      sourceType: {
        user: 'account_circle',
        unit: 'cx_unit',
        integrator: 'computer'
      },
      noSource: 'not_interested'
    };
    if (!sourceType && !sourceName) {
      return {
        field: field.noSource,
        value: caption.noSource,
        icon: icon.noSource
      };
    }
    if (!sourceType && sourceName) {
      return {
        field: field.noSource,
        value: sourceName,
        icon: icon.noSource
      };
    }
    if (sourceType && !sourceName) {
      return {
        field: field[sourceType.toLowerCase()],
        value: caption.noSource,
        icon: icon.sourceType[sourceType.toLowerCase()]
      };
    }
    return {
      field: field[sourceType.toLowerCase()],
      value: sourceName,
      icon: icon.sourceType[sourceType.toLowerCase()]
    };
  }
  get createdTime() {
    const { createdAt } = this._refModel;
    const field = $gettext('Created At');
    const caption = {
      value: `${datetimehelper.format(createdAt, datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
      noData: $gettext('no data')
    };
    const icon = 'mdi-check-circle';
    return {
      field,
      value: createdAt ? caption.value : caption.noData,
      icon
    };
  }
  get updatedTime() {
    const { updatedAt } = this._refModel;
    const field = $gettext('Updated At');
    const caption = {
      value: `${datetimehelper.format(updatedAt, datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
      noData: $gettext('no data')
    };
    const icon = 'cached';
    return {
      field,
      value: updatedAt ? caption.value : caption.noData,
      icon
    };
  }
  get machineCount() {
    return this._refModel.machineAssociations ? this._refModel.machineAssociations.length : 0;
  }
}
