import FilterEntityViewModel from '../filterEntityViewModel';

export default class FilterUnitViewModel extends FilterEntityViewModel {

  _entityType;
  constructor(entity, locale) {
    super(entity, locale);
    this._entityType = entity._entityType;
  }
  /**
  * Defined by posession of geographic coordinates (longitude and latitude)
  * Is selectable only if has coordinates.
  */
  get isResultSelectable() {
    const { lon, lat } = this._entity;
    return Number.isFinite(lon) && Number.isFinite(lat);
  }

  get isResultVisible() {
    return true;
  }

  get isResultWarning() {
    return !this.isResultSelectable;
  }
}