import { canBeInitialSurfaceMap, canCalculateVolumesMap } from '@/domain/entityMixins/refmodel.behavior.config';

export const withCanBeInitialSurface = (entity) => {
  if (!entity.entityType) return entity;
  const canBeInitialSurface = canBeInitialSurfaceMap[entity.entityType] || false;
  return Object.assign(Object.create(Object.getPrototypeOf(entity)), entity, { canBeInitialSurface });
};

export const withCanCalculateVolumes = (entity) => {
  if (!entity.entityType) return entity;
  const canCalculateVolumes = canCalculateVolumesMap[entity.entityType] || false;
  return Object.assign(Object.create(Object.getPrototypeOf(entity)), entity, { canCalculateVolumes });
};