<template>
  <div class="cx-event-details-panel" v-if="selectedEvent">
    <!-- STATS -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            :handleCameraFlyTo="handleCameraFlyToAwarenessEvent">
            <cx-icon :icon="selectedEvent.icon" :size="90" class="cx-avatar__icon cxu-center-h" :class="selectedEvent.color"/>
          </cx-avatar>
        </transition>
      </template>
      <template #title>
        <!-- TITLE -->
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="eventData.unitName"
            :subtitle="subtitle"
            :subtitleIcon="selectedEvent.icon"
            :subtitleIconTooltip="subtitle"
            :isLoading="isFetchingData">
          </cx-title-panel>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel v-if="isAnimationReady"/>
        </transition>
      </template>
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <transition :name="applyAnimations('analysis')">
      <div class="cx-cmn-dp__analysisPanel">
        <transition :name="applyAnimations('analysis')">
          <cx-metadata-panel
            v-show="isAnimationReady"
            :metadata="eventProperties"/>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { withIcon, withColor } from '@/domain/entityMixins';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { round } from 'lodash';
import CxAvatar from '@/app/detailsPanel/common/CxAvatar';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxTitlePanel from '@/app/detailsPanel/common/CxTitlePanel';
import CxStatsPanel from '@/app/detailsPanel/common/CxStatsPanel';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import datetimehelper from '@/utils/datetimehelper';
import translationService from '@/services/translation.service';
import { createField } from '@/viewModel/fieldFactory';
import CxMetadataPanel from '../common/CxMetadataPanel.vue';
import CxIcon from '@/components/CxIcon.vue';
import { AlarmTypeFilterDisplayValue } from '@/domain/awareness/enums/AlarmTypeFilterDisplayValue.enum';

const avatarMap = {
  [AlarmTypeFilterDisplayValue.TagAlarm]: 'avatars/backhoe.png',
  [AlarmTypeFilterDisplayValue.VehicleAlarm]: 'avatars/driller.png',
  [AlarmTypeFilterDisplayValue.PanicAlarm]: 'avatars/piler.png',
  [AlarmTypeFilterDisplayValue.Obstacle]: 'avatars/trimming.png',
  [AlarmTypeFilterDisplayValue.Overspeed]: 'avatars/excavator.png',
  [AlarmTypeFilterDisplayValue.TagOk]: 'avatars/roller.png',
  [AlarmTypeFilterDisplayValue.TagOk]: 'avatars/surveyor.png',
  [AlarmTypeFilterDisplayValue.AvoidanceAlarm]: 'avatars/bulldozer.png',
  [AlarmTypeFilterDisplayValue.CollisionAlarm]: 'avatars/bulldozer.png',
  [AlarmTypeFilterDisplayValue.PersonAlarm]: 'avatars/backhoe.png'
};

export default {
  name: 'CxAwarenessEventDetailsPanel',
  components: {
    CxAvatar,
    CxRelatedEntitiesPanel,
    CxTitlePanel,
    CxStatsPanel,
    CxMainDataPanel,
    CxMetadataPanel,
    CxIcon
  },
  mixins: [CxLicenceMixin, CxAnimationMixin],
  data() {
    return {
      isFetchingData: false,
      eventData: {}
    };
  },
  watch: {
    selectedEvent: {
      immediate: true,
      handler() {
        this.eventData = {};
        this.fetchData();
      }
    }
  },
  computed: {
    ...mapGetters('map', ['selectedEntity']),
    selectedEvent() {
      if (this.selectedEntity && this.selectedEntity.entityType === AwarenessEventType) {
        return withIcon(withColor(this.selectedEntity));
      }
      return null;
    },
    subtitle() {
      return this.$gettext('Type: Awareness Event');
    },
    mainData() {
      const { unitType, name, timestamp } = this.eventData;
      const result = [
        {
          value: unitType,
          icon: withIcon({ entityType: unitType }).icon || ''
        },
        {
          value: translationService.get(name),
          icon: withIcon({ entityType: this.selectedEntity.entityType, name }).icon || ''
        },
        {
          value: timestamp ? `${datetimehelper.format(timestamp, datetimehelper.LOCALE_DATE_TIME_FORMAT)}` : '',
          icon: 'access_time'
        },
      ];
      return result;
    },
    entityAvatar() {
      return avatarMap[this.selectedEvent.name];
    },
    eventProperties() {
      const longitude = createField(this.eventData, 'longitude');
      const latitude = createField(this.eventData, 'latitude');
      const height = createField(this.eventData, 'height');
      return [
        {
          fieldName: this.$gettext('Alarm Status'),
          value: translationService.get(this.eventData.value)
        },
        {
          fieldName: 'Distance',
          value: this.eventData.distance ? round(this.eventData.distance, 2).toFixed(2) : null,
          unit: 'm'
        },
        {
          fieldName: this.$gettext('Detected Zone'),
          value: translationService.get(this.eventData.detectedZone),
        },
        {
          fieldName: this.$gettext('Anchor Position'),
          value: translationService.get(this.eventData.position)
        },
        {
          fieldName: this.$gettext('Anchor Id'),
          value: this.eventData.anchorId
        },
        {
          fieldName: this.$gettext('Opponent Id'),
          value: translationService.get(this.eventData.opponentId)
        },
        { ...latitude },
        { ...longitude },
        { ...height },
      ];
    }
  },
  methods: {
    ...mapActions('detailsAwarenessEvent', ['fetchEventData']),
    ...mapActions('map', ['setCameraToEntity']),
    async handleCameraFlyToAwarenessEvent() {
      await this.setCameraToEntity(this.selectedEvent.uuid);
    },
    async fetchData() {
      this.isFetchingData = true;
      this.eventData = await this.fetchEventData({ uuid: this.selectedEvent.uuid });
      this.isFetchingData = false;
    }
  },
};
</script>

<style lang="less">
@import '../../../common';

.cx-event-details-panel {
  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-avatar__icon {
    position: absolute;
    width: @detailsStatsAvatarWidth;
    border-radius: @inputBorderRadiusRounded;
    cursor: pointer;
    background-color: #373c47;
    height: 150px;
  }
}

</style>