<template>
  <div
    class="cx-checkbox cxu-center-v"
    :class="{ 'cx-checkbox--small': !checked, 'cx-checkbox--disabled': disabled, 'cx-checkbox--checked': checked }"
    @click="onIconClicked"
    :disabled="disabled ? true : null"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    v-cx-tooltip="tooltipOptions">
    <cx-icon class="cx-checkbox__icon" :size="iconSize" :icon="icon"/>
    <slot><span v-if="label" class="cx-checkbox__label" :class="labelClass">{{ label }}</span></slot>
  </div>
</template>


<script>
import CxIcon from '@/components/CxIcon';
import CxTooltipMixin from '@/components/CxTooltipMixin';

export default {
  name: 'CxCheckbox',
  components: {
    CxIcon
  },
  mixins: [CxTooltipMixin],
  props: {
    checked: { type: Boolean, required: true },
    label: { type: String },
    disabled: { type: Boolean },
    labelClass: { type: [String, Object] }
  },
  emits: ['update:checked', 'click'],
  computed: {
    icon() {
      return this.checked ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle';
    },
    iconSize() {
      return this.checked ? 13 : 9;
    },
  },
  methods: {
    onIconClicked() {
      if (!this.disabled) {
        this.$emit('update:checked', !this.checked);
        this.$emit('click');
      }
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-checkbox {
  width: max-content;
  height: 19px;
  color: @textColor;



  .cx-checkbox__label {
    font-size: @appFontSize;
    color: @textColorLowlight;
    padding-left: 8px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: @textColor;
    }

  }
  .cx-checkbox__icon {
    padding-left: 0;
    width: 10px;
  }

  &:hover {
    cursor: pointer;
    .cx-checkbox__label {
       cursor: pointer;
    }
  }

  &[disabled] {
    cursor: default;
    color: @textColorDisabled;
  }

  &.cx-checkbox--small {
    .cx-checkbox__icon {
      padding-left: 2px;
      width: 10px;
    }
  }

  &.cx-checkbox--checked {
     .cx-checkbox__label {
       color: @textColorHighlight;
    }
  }
}

</style>