<template>
  <div class="cx-link" :disabled="isDisabled || null">
    <span class="cx-link__text" @click="handleClick">
      {{ text }}
    </span>
  </div>
</template>

<script>
/**
 * A simple link component, that emits click event
 */
export default {
  name: 'CxLink',
  components: {
  },
  data() {
    return {
      isHovered: false,
    };
  },
  props: {
    isDisabled: Boolean, // todo
    text: String,
  },
  emits: ['click'],
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit('click', this.$el);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../common';

.cx-link {
  display: inline;
  color: @linkColor;
  font-size: @appFontSize;
}
.cx-link:hover {
  color: @linkColorHighlight;
  cursor: pointer;
}

.cx-link[disabled] {
  color: @textColorDisabled;
  cursor: default;
}
</style>