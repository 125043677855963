const alertStoreModule = {
  state: {
    alert: null,
  },
  getters: {
    alert(state) {
      return state.alert;
    }
  },
  mutations: {
    setAlert(state, alert) {
      state.alert = alert;
    },
  },
  actions: {
    /**
     * Show alert component to the user
     * @param {Alert} alert
     */
    async displayAlert(context, alert) {
      if (!alert) return;
      context.commit('setAlert', alert);
    },
    /**
     * Hide alert
     */
    async hideAlert(context) {
      if (!alert) return;
      context.commit('setAlert', {});
    },
  }
};

export default alertStoreModule;