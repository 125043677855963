<template>
  <div class="cx-drill-hole-status-filter">
    <cx-multiselect-bar
      :value="selectedDrillHoleStatuses"
      @onSelectedChange="onSelectedChangeHandler"
      @onReset="onResetHandler"/>
  </div>
</template>

<script>
import CxMultiselectBar from '@/components/CxMultiselectBar';

export default {
  name: 'CxDrillHoleStatusFilter',

  components: {
    CxMultiselectBar
  },

  props: {
    selectedDrillHoleStatuses: Array
  },

  emits: ['changeSelectedDrillHoleStatus', 'clearSelectedDrillHoleStatuses'],

  methods: {
    onResetHandler() {
      this.$emit('clearSelectedDrillHoleStatuses');
    },
    onSelectedChangeHandler(payload) {
      this.$emit('changeSelectedDrillHoleStatus', payload);
    }
  }
};
</script>
