import { trimStart } from 'lodash';
import { LicenceTypes } from '@/domain/LicenceTypes.enum';

// this service keeps the licence object, not to keep it in the store
// and adds additional logic, dependent on environment variables
//
const licenceService = (() => {
  let appLicence = {};
  const awarenessLicences = Object.values(LicenceTypes).filter(l => l.includes('awareness'));
  return {
    setLicence(payload: Record<LicenceTypes, boolean>) {
      appLicence = payload;
      if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_LICENCES) {
        // Overwrite licences for local development
        const licences = process.env.VUE_APP_LICENCES.split(',').map(p => trimStart(p));
        const localLicences = Object.values(LicenceTypes).reduce((accumulator, licence) => { accumulator[licence] = licences.includes(licence); return accumulator; }, {});
        appLicence = localLicences;
      }

      if (appLicence && appLicence[LicenceTypes.Demo]) {
        // demo licence grants access to all apps
        const allLicencesOn = Object.values(LicenceTypes).reduce((accumulator, licence) => { accumulator[licence] = true; return accumulator; }, {});
        appLicence = allLicencesOn;
      }
    },
    hasEarthmovingLicence(): Boolean {
      if (!appLicence) return false;
      return appLicence[LicenceTypes.Earthmoving];
    },
    hasStabilizationLicence(): Boolean {
      if (!appLicence) return false;
      return appLicence[LicenceTypes.Drilling];
    },
    includesAwarenessLicence(availableLicences: string[]): Boolean {
      return availableLicences.some(licence => awarenessLicences.includes(licence as LicenceTypes));
    },
    hasAwarenessLicence(): Boolean {
      if (!appLicence) return false;
      const appLicenceKeys = Object.keys(appLicence);
      const fetchedRemoteLicences = appLicenceKeys.filter(key => !!appLicence[key]);
      return this.includesAwarenessLicence(fetchedRemoteLicences);
    },
    isGridPoc(): Boolean {
      if (process.env.VUE_APP_GRID_POC) return process.env.VUE_APP_GRID_POC === 'true';
      return false;
    },
    hasCookieConsent(): Boolean {
      if (process.env.VUE_APP_COOKIE_CONSENT) return process.env.VUE_APP_COOKIE_CONSENT === 'true';
      return false;
    },
    isChineseDistribution(): Boolean {
      if (process.env.VUE_APP_CHINA) return process.env.VUE_APP_CHINA === 'true';
      return false;
    },
    getMapboxUrl(mapId: string): string {
      const mapboxUrl = (extension, accessToken) => `https://api.mapbox.${extension}/styles/v1/mapbox/${mapId}/tiles/512/{z}/{x}/{y}?access_token=${accessToken}`;
      const token = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
      const tokenChina = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
      return this.isChineseDistribution() ? mapboxUrl('com', tokenChina) : mapboxUrl('com', token);
    }
  };
})();
export default licenceService;