import * as Cesium from 'cesium';

/** Wrapper class for the primitve picked by Cesium's scene pick api. */
export class PickedFeature {
  /**
   * Create a new overlay instance.
   * Classes extending this one will have the following methods:
   *
   * applySelectStyle() { }
   * applyHoverStyle() { }
   * restoreOriginalStyle() { }
   * restoreDefaultStyle() { }
   * overlayData() { }
   * position() { }
   * name() { }
   * type() { }
   * uuid() { }
   *
   * @param {Cesium.Viewer} viewer: the Cesium Viewer instance.
   */
  constructor(viewer) {
    this.viewer = viewer;
    this.screenPositionHandler = null;
  }

  get uuid() {
    throw new Error('Define a uuid getter in implementation.');
  }

  /**
   * @returns {Cartesian3}
   */
  get position() {
    throw new Error('Define a position getter in implementation.');
  }

  applySelectStyle() {
    throw new Error('Define applySelectStyle() in implementation.');
  }
  applyHoverStyle() {
    throw new Error('Define applyHoverStyle() in implementation.');
  }
  restoreOriginalStyle() {
    throw new Error('Define restoreOriginalStyle() in implementation.');
  }
  restoreDefaultStyle() {
    throw new Error('Define restoreDefaultStyle() in implementation.');
  }

  select(handler) {
    if (handler) this._addScreenPositionChangeHandler(handler, 'select');
    this.applySelectStyle();
  }

  deselect(handler) {
    if (handler) this._removeScreenPositionChangeHandler(handler, 'select');
    this.restoreDefaultStyle();
  }

  hover(handler, isSelected, isSingleHover) {
    if (handler) {
      if (!isSingleHover || !isSelected) {
        this._addScreenPositionChangeHandler(handler, 'hover');
      } else {
        this._removeScreenPositionChangeHandler(handler, 'hover');
      }
    }
    this.applyHoverStyle();
  }

  dehover(handler) {
    this._removeScreenPositionChangeHandler(handler, 'hover');
    this.restoreOriginalStyle();
  }

  _addScreenPositionChangeHandler(handler, type) {
    if (!this.screenPositionChangeHandler) {
      this.screenPositionChangeHandler = () => {
        const { canvasPosition } = this;
        if (!this.cachedCanvasPosition ||
          this.canvasPosition && (this.cachedCanvasPosition.x !== canvasPosition.y && this.cachedCanvasPosition.y !== canvasPosition.y)) {
          handler({ id: this.uuid, type, position: canvasPosition });
        }
        this.cachedCanvasPosition = canvasPosition;
      };
      this.viewer.scene.preRender.addEventListener(this.screenPositionChangeHandler);
    }
  }
  _removeScreenPositionChangeHandler(handler, type) {
    if (handler && this.screenPositionChangeHandler) {
      this.viewer.scene.preRender.removeEventListener(this.screenPositionChangeHandler);
      delete this.screenPositionHandler;
      handler({ id: this.uuid, type, position: null });
    }
  }

  /**
   * Get the data used for the Details Panel.
   * TODO: move this to the feature PropertyBag?
   * @returns {object} the data used for the select overlay.
   */
  get detailsPanelData() {
    return {
      id: this.uuid || 'N/A',
      name: this.name || 'N/A',
      type: this.type || 'N/A',
    };
  }

  /**
   * Gets the canvas (screen) position of the feature.
   * @returns {*}
   */
  get canvasPosition() {
    const { position } = this;
    const canvasPosition = position ?
      this.viewer.scene.cartesianToCanvasCoordinates(position) : null;
    return canvasPosition ? {
      x: canvasPosition.x,
      y: this.viewer.canvas.clientHeight - canvasPosition.y,
    } : null;
  }

  /**
   * Get the feature's Cesium.Cartographic position with terrain height at current time.
   * @returns {*|Cesium.Cartographic}
   */
  terrainCartographicPosition() {
    let result;
    if (this.position) {
      result = Cesium.Cartographic.fromCartesian(this.position);
      const height = this.viewer.scene.globe.getHeight(result);
      if (height > result.height) result.height = height;
    }
    return result;
  }
}
