import * as Cesium from 'cesium';
import { PickedFeature } from './pickedFeature';
import { excludePatterns } from '@/utils/stringOperations';
import { HoleDrillPileStatus } from '@/domain/hole/HoleDrillPileStatus.enum';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';

const styles = {
  select: {
    point: {
      color: Cesium.Color.fromAlpha(Cesium.Color.LIME, 1.0),
      pixelSize: 10,
    },
    hole: {
      scale: 1.2,
      [StabilizationTypes.DrillHole]: {
        [HoleDrillPileStatus.Log]: { image: 'drill-green-selected.png' },
        [HoleDrillPileStatus.Entry]: { image: 'drill-yellow-selected.png' },
        [HoleDrillPileStatus.Paused]: { image: 'drill-yellow-selected.png' },
        [HoleDrillPileStatus.Start]: { image: 'drill-yellow-selected.png' },
        [HoleDrillPileStatus.End]: { image: 'drill-green-selected.png' },
        [HoleDrillPileStatus.Failed]: { image: 'drill-red-selected.png' },
        [HoleDrillPileStatus.Continue]: { image: 'drill-yellow-selected.png' },
      },
      [StabilizationTypes.PileHole]: {
        [HoleDrillPileStatus.Log]: { image: 'pile-green-selected.png' },
        [HoleDrillPileStatus.Entry]: { image: 'pile-yellow-selected.png' },
        [HoleDrillPileStatus.Paused]: { image: 'pile-yellow-selected.png' },
        [HoleDrillPileStatus.Start]: { image: 'pile-yellow-selected.png' },
        [HoleDrillPileStatus.End]: { image: 'pile-green-selected.png' },
        [HoleDrillPileStatus.Failed]: { image: 'pile-red-selected.png' },
        [HoleDrillPileStatus.Continue]: { image: 'pile-yellow-selected.png' },
      },
    }
  },
  hover: {
    point: {
      color: Cesium.Color.fromAlpha(Cesium.Color.WHITE, 1.0),
      pixelSize: 10,
    },
    hole: {
      scale: 1.2,
      [StabilizationTypes.DrillHole]: {
        [HoleDrillPileStatus.Log]: { image: 'drill-green-hover.png' },
        [HoleDrillPileStatus.Entry]: { image: 'drill-yellow-hover.png' },
        [HoleDrillPileStatus.Paused]: { image: 'drill-yellow-hover.png' },
        [HoleDrillPileStatus.Start]: { image: 'drill-yellow-hover.png' },
        [HoleDrillPileStatus.End]: { image: 'drill-green-hover.png' },
        [HoleDrillPileStatus.Failed]: { image: 'drill-red-hover.png' },
        [HoleDrillPileStatus.Continue]: { image: 'drill-yellow-hover.png' },
      },
      [StabilizationTypes.PileHole]: {
        [HoleDrillPileStatus.Log]: { image: 'pile-green-hover.png' },
        [HoleDrillPileStatus.Entry]: { image: 'pile-yellow-hover.png' },
        [HoleDrillPileStatus.Paused]: { image: 'pile-yellow-hover.png' },
        [HoleDrillPileStatus.Start]: { image: 'pile-yellow-hover.png' },
        [HoleDrillPileStatus.End]: { image: 'pile-green-hover.png' },
        [HoleDrillPileStatus.Failed]: { image: 'pile-red-hover.png' },
        [HoleDrillPileStatus.Continue]: { image: 'pile-yellow-hover.png' },
      },
    },
  },
};

export class PickedPrimitive extends PickedFeature {
  constructor(viewer, feature, uid) {
    super(viewer);
    this.uid = uid;
    this.primitive = feature;
    this.saveDefaultStyle();
  }

  get _id() {
    return this.uid || this.primitive.id;
  }

  /**
   * Save the unhovered/unselected style from the primitive.
   */
  saveDefaultStyle() {
    if (this.isPoint) {
      this.defaultStyle = {
        point: {
          color: { ...this.primitive.color },
          pixelSize: this.primitive.pixelSize,
        },
      };
    } else if (this.isHole) {
      this.defaultStyle = {
        hole: {
          // holes selected from the OE are not only billboards...
          image: this.primitive.image || this.primitive.billboard.image,
          scale: this.primitive.scale || this.primitive.billboard.scale,
        },
      };
    }
  }

  /**
   * Apply appropriate style depending on hovering/selected state.
   * @param style
   */
  applyStyle(style) {
    if (this.isPoint) {
      Object.assign(this.primitive, style.point);
    } else if (this.isHole) {
      // since the default hole style is cached from the object, no use in creating the keyed one
      const holeStyle = style.hole.image ? style.hole : style.hole[this.type][this.status];
      holeStyle.scale = style.hole.scale;
      // holes selected from the OE are not only billboards...
      Object.assign(this.primitive.billboard || this.primitive, holeStyle);
    }
  }
  applySelectStyle() {
    this.applyStyle(styles.select);
  }
  applyHoverStyle() {
    this.applyStyle(styles.hover);
  }
  restoreDefaultStyle() {
    this.restoreOriginalStyle();
  }
  restoreOriginalStyle() {
    this.applyStyle(this.defaultStyle);
  }
  get position() {
    let result = this.primitive.position || this.primitive._rtcCenter || this._id.position;
    if (result instanceof Cesium.ConstantPositionProperty) {
      result = result.getValue(Cesium.JulianDate.now());
    }
    return result;
  }
  get name() {
    return this._id.name;
  }
  get type() {
    return this._id.cxType;
  }
  get uuid() {
    return this._id.uuid;
  }
  get status() {
    return this._id.status;
  }
  get isPoint() {
    return this.type === 'point';
  }
  get isHole() {
    return Object.values(StabilizationTypes).includes(this.type);
  }
  get pointCode() {
    const excludedPatterns = ['-', '--', '---'];
    return excludePatterns(this._id.name.code, excludedPatterns);
  }
}
