import { parseISO } from 'date-fns';
import { mapMeasurementSystem } from './measurementUnit.mapper';
import { ProjectMetadata } from '@/domain/ProjectMetadata';
import { ProjectAccounts } from '@/domain/ProjectAccounts';
import { WaffleFlags } from '@/domain/WaffleFlags.enum';

export interface ProjectMetadataResponse {
  licenses: Record<string, boolean>,
  timestamp: string,
  measurement_unit: { value: number, label: string }
}

const mapProjectMetadata = async (data: ProjectMetadataResponse): Promise<ProjectMetadata> => {
  return {
    projectStartDate: data.timestamp ? parseISO(data.timestamp) : null,
    licenses: data.licenses,
    measurementSystem: mapMeasurementSystem(data.measurement_unit.label),
    unitValue: data.measurement_unit.value
  };
};

const mapProjectAccounts = async (data: {
  uuid: string,
  name: string,
  waffle_flags: Record<WaffleFlags, boolean>,
  project: { coordinate_system: any }
}): Promise<ProjectAccounts> => {
  return {
    uuid: data.uuid,
    name: data.name,
    coordinateSystem: data.project.coordinate_system,
    waffleFlags: data.waffle_flags
  };
};

export const projectMapper = {
  mapProjectMetadata,
  mapProjectAccounts,
};
