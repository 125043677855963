<template>
  <div class="cx-toggle-button" @click="toggle" :class="{'disabled' : disabled}">
    <div class="cx-toggle-button__hitbox" :class="{ checked: state }">
      <span class="cx-toggle-button__hitbox--label" :class="{ checked: state }">{{ label }}</span>
      <span class="cx-toggle-button__hitbox--count" :class="{ checked: state }" v-if="typeof count === 'number'">{{ count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxToggleButton',

  props: {
    state: Boolean,
    label: String,
    count: Number,
    onToggle: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.onToggle();
    }
  }
};
</script>

<style lang="less" scoped>
    @import '../common';

    .cx-toggle-button.disabled {
      cursor: default;
      .cx-toggle-button__hitbox {
        background-color: @toggleBtnInactiveBGColor;
         &--label {
            color: @textColorLowlight;
            padding: 0 2px;
            opacity: 0.6;
            &.checked { opacity: 0.6; }
          }
          &:hover { .cx-toggle-button__hitbox--label { opacity: 0.6; } }
        }
    }

    .cx-toggle-button {
        cursor: pointer;
        border-radius: @inputBorderRadius;
        width: 100%;
        font-size: @appFontSize;

        &__hitbox {
            position: relative;
            top: 0;
            background-color: @toggleBtnInactiveBGColor;
            .size(100%, @inputHeight);
            border-radius: @inputBorderRadius;
            .flex(row, center, center);

            &--label {
                color: @textColorBase;
                padding: 0 2px;
                opacity: 0.6;

                &.checked {
                    opacity: 0.8;
                }
            }

            &--count {
                color: @textColorBase;
                padding: 0 2px;
                opacity: 0.3;

                &.checked {
                    opacity: 0.4;
                }
            }

            &.checked {
                background-color: @toggleBtnActiveBGColor;
            }

            &:hover {
                .cx-toggle-button__hitbox--label {
                    opacity: 0.8;
                }
            }
        }
    }
</style>