import datetimehelper from '@/utils/datetimehelper';
import { awarenessEventFactory } from '@/domain/awareness/AwarenessEventFactory';
import { DetectedZoneFilterValue } from '@/domain/awareness/enums/DetectedZoneFilterValue.enum';
import { AlarmTypeFilterValue } from '@/domain/awareness/enums/AlarmTypeFilterValue.enum';
import { AlarmTypeFilterDisplayValue } from '@/domain/awareness/enums/AlarmTypeFilterDisplayValue.enum';
import { DetectedZoneFilterDisplayValue } from '@/domain/awareness//enums/DetectedZoneFilterDisplayValue.enum';
import { AwarenessEvent } from '@/domain/awareness/AwarenessEvent';
import { AwarenessEventValue } from '@/domain/awareness/enums/AwarenessEventValue.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { AwarenessEventPosition } from '@/domain/awareness/enums/AwarenessEventPosition.enum';


export interface AwarenessEventResponse {
  anchor_id: number | null,
  detected_zone: DetectedZoneFilterValue,
  distance: number | null,
  event_id: string,
  event_info: string | null
  location: {coordinates: number[], type: string}
  name: AlarmTypeFilterValue,
  opponent_id: number | null,
  pos: AwarenessEventPosition | null,
  timestamp: Date | null,
  unit_name: string | null,
  unit_type: UnitTypes,
  uuid: string,
  value: AwarenessEventValue | null,
}

export interface AwarenessEventResponseData {
  count: number,
  next_page: number,
  previous_page: number,
  result: AwarenessEventResponse[]
}



/**
 * Maps API-derived value of alarm type to its UI display value.
 * @param {String} alarmTypeResponseValue
 * @returns {String}
 */

const mapAlarmType = (alarmTypeResponseValue:AlarmTypeFilterValue):AlarmTypeFilterDisplayValue | undefined => {
  const filterKeys = Object.keys(AlarmTypeFilterValue);
  const alarmTypeKey = filterKeys.find(key => AlarmTypeFilterValue[key] === alarmTypeResponseValue);
  return (alarmTypeKey) ? AlarmTypeFilterDisplayValue[alarmTypeKey] : undefined;
};

/**
 * Maps API-derived value of detected zone to its UI display value.
 * @param {String} detectedZoneResponseValue
 * @returns {String}
 */
const mapDetectedZone = (detectedZoneResponseValue:DetectedZoneFilterValue):DetectedZoneFilterDisplayValue | undefined => {
  const filterKeys = Object.keys(DetectedZoneFilterValue);
  const detectedZoneKey = filterKeys.find(key => DetectedZoneFilterValue[key] === detectedZoneResponseValue);
  return (detectedZoneKey) ? DetectedZoneFilterDisplayValue[detectedZoneKey] : undefined;
};

const mapAwarenessEvent = (event:AwarenessEventResponse):AwarenessEvent => {
  const { uuid } = event;
  const name = mapAlarmType(event.name);
  const unitType = event.unit_type;
  const detectedZone = mapDetectedZone(event.detected_zone);
  return awarenessEventFactory({
    uuid,
    name,
    unitType,
    detectedZone
  });
};

const mapAwarenessEventDetails = (event:AwarenessEventResponse):AwarenessEvent => {
  const { value, distance, uuid } = event;
  const name = mapAlarmType(event.name);
  const timestamp = datetimehelper.mapDate(event.timestamp);
  const detectedZone = mapDetectedZone(event.detected_zone);
  const [longitude, latitude, altitude] = event.location.coordinates;
  const unitName = event.unit_name;
  const unitType = event.unit_type;
  const position = event.pos;
  const anchorId = event.anchor_id;
  const opponentId = event.opponent_id;

  return awarenessEventFactory({
    uuid, // required
    name, // required
    value, // required
    timestamp, // required
    detectedZone, // required
    longitude,
    latitude,
    height: altitude,
    unitName,
    unitType,
    position, // optional
    distance, // optional
    anchorId, // optional
    opponentId, // optional
  });
};

const mapAwarenessEvents = (data:AwarenessEventResponseData):{
  events:AwarenessEvent[],
  count:number,
  totalCount:number,
  exceedsPerformanceLimit:boolean,
} => ({
  events: data.result.map(r => mapAwarenessEvent(r)),
  count: data.result.length,
  totalCount: data.count,
  exceedsPerformanceLimit: data.result.length < data.count
});

const awarenessEventMapper = { mapAwarenessEvents, mapAwarenessEvent, mapAwarenessEventDetails, mapAlarmType, mapDetectedZone };
export default awarenessEventMapper;
