import { toDate, subHours } from 'date-fns';
import _ from 'lodash';
import datetimehelper from '@/utils/datetimehelper';
import licenceService from '@/services/licence.service';
import calcService from './point.service';
import detailsRefmodelChartModule from './detailsRefmodel.chart.store';
import detailsRefmodelShellModule from './detailsRefmodel.shell.store';
import detailsRefmodelOverlayModule from './detailsRefmodel.overlay.store';
import { fetchPointDataStoreMixin } from '@/store/detailsModule/fetchPointData.store.mixin';
import { withCanCalculateVolumes, withCanBeInitialSurface } from '@/domain/entityMixins/refmodelBehaviors';
import { withColor } from '@/domain/entityMixins';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const helpers = {
  getDefaultStartTime(now) {
    return subHours(now, 24);
  },
  generateIntervals(startTime, projectStartTime, now) {
    startTime = startTime || helpers.getDefaultStartTime(now);
    if (startTime < projectStartTime) {
      startTime = projectStartTime;
    }
    const intervals = datetimehelper.calculateTimeIntervals(now, startTime);
    return intervals;
  },
};


const detailsRefmodelModule = {
  state: {
    ...detailsRefmodelChartModule.state,
    ...detailsRefmodelShellModule.state,
    ...detailsRefmodelOverlayModule.state,
    ...fetchPointDataStoreMixin.state,
    selectedRefModel: null,
    // list of all volumes calculated for selected time range
    volumes: [],
    // id of selected set
    selectedSet: null,
    // selected range uuids
    selectedRange: null,
    // selected initial model uuid
    selectedInitialModel: null,
    // initial cut/fill values
    initialVolumes: { cutVolume: null, fillVolume: null, error: null },
    // start of the calculations time range, if null, the default is 24h from 'now'
    startDate: null,
    // chart unit for intervals - hours, days, months, years
    intervalUnit: 'hours',
    // toggle this to get the current now or now in the exact date
    now: null,
    actionUid: null,
    surfaceUUID: null,
    cutFillSurfaceUUID: null,
    progressSurfaceUUID: null,
    pointSetUUID: null,
  },
  mutations: {
    ...detailsRefmodelShellModule.mutations,
    ...detailsRefmodelOverlayModule.mutations,
    ...fetchPointDataStoreMixin.mutations,
    setSelectedRefmodel(state, payload) {
      state.selectedRefModel = payload;
    },
    setVolumes(state, payload) {
      state.volumes = payload;
    },
    setSelectedSet(state, payload) {
      state.selectedSet = payload;
    },
    setSelectedRange(state, payload) {
      if (payload) state.selectedRange = { from: payload.from, to: payload.to };
      else state.selectedRange = null;
    },
    setSelectedInitialModel(state, payload) {
      state.selectedInitialModel = payload;
    },
    setInitialVolumes(state, payload) {
      state.initialVolumes = payload;
    },
    setNow(state, payload) {
      state.now = payload;
    },
    setStartDate(state, payload) {
      state.startDate = payload;
    },
    setIntervalUnit(state, payload) {
      state.intervalUnit = payload;
    },
    setSurface(state, uuid) {
      state.surfaceUUID = uuid;
    },
    setCutFillSurface(state, uuid) {
      state.cutFillSurfaceUUID = uuid;
    },
    setProgressSurface(state, uuid) {
      state.progressSurfaceUUID = uuid;
    },
    setPointSet(state, uuid) {
      state.pointSetUUID = uuid;
    },
  },
  getters: {
    ...detailsRefmodelChartModule.getters,
    ...detailsRefmodelShellModule.getters,
    ...detailsRefmodelOverlayModule.getters,
    ...fetchPointDataStoreMixin.getters,
    selectedSet(state) {
      return state.selectedSet;
    },
    pointSet: state => (uuid) => {
      const selectedPointSet = uuid ? state.volumes.map(c => c.pointSet).find(set => set.uuid === uuid) : null;
      if (!selectedPointSet) return null;
      const set = { ...selectedPointSet };
      set.points = set.points.map(withColor);
      return set;
    },
    selectedVolumeData(state) {
      if (!state.selectedSet) return null;
      return state.volumes.find(v => v.uuid === state.selectedSet.uuid);
    },
    selectedPointSet(state, getters) {
      const { selectedSet } = state;
      return selectedSet ? getters.pointSet(selectedSet.uuid) : null;
    },
    selectedRangePointSets(state, getters) {
      const { selectedRange } = getters;
      return {
        from: selectedRange ? getters.pointSet(selectedRange.from.uuid) : null,
        to: selectedRange ? getters.pointSet(selectedRange.to.uuid) : null,
      };
    },
    initialModels(state, getters, rootState, rootGetters) {
      return rootGetters['app/allModels'].map(e => withCanBeInitialSurface(e)).filter(e => e.canBeInitialSurface).sort((a, b) => a.name.localeCompare(b.name));
    },
    overlayControlsEnabled(state) {
      return !state.showNoData && (state.selectedSet || state.selectedRange) && !state.showSpinner;
    },
    selectedInitialModel(state) {
      return state.selectedInitialModel;
    },
    selectedRange(state) {
      if (!state.selectedRange) return null;
      return {
        from: state.volumes.find(v => v.uuid === state.selectedRange.from.uuid),
        to: state.volumes.find(v => v.uuid === state.selectedRange.to.uuid)
      };
    },
    startDate(state) {
      const date = state.startDate || subHours(new Date(Date.now()), 24);
      return toDate(date);
    },
    now(state) {
      const date = state.now || new Date(Date.now());
      return toDate(date);
    },
    selectedRefModel(state) {
      return state.selectedRefModel;
    },
    isRefModelVisible(state, getters, rootState) {
      let result = false;
      if (getters.selectedRefModel) result = !rootState.map.hiddenRefmodels.includes(getters.selectedRefModel.uuid);
      return result;
    },
    getRefModelOpacity(state, getters, rootState, rootGetters) {
      const opacities = rootGetters['map/refmodelOpacities'];
      const selectedRefModelOpacity = opacities[getters.selectedRefModel.uuid];
      return selectedRefModelOpacity;
    },

    surfaceUUID(state) {
      return state.surfaceUUID;
    },
    cutFillSurfaceUUID(state) {
      return state.cutFillSurfaceUUID;
    },
    progressSurfaceUUID(state) {
      return state.progressSurfaceUUID;
    },
    pointSetUUID(state) {
      return state.pointSetUUID;
    }
  },
  cancelableActions: {
    async setData(context, { cancelToken }) {
      if (licenceService.hasEarthmovingLicence()) {
        const projectStartTime = context.rootGetters['app/projectStartDate'];
        const { projectUuid } = context.rootGetters;
        const { now, startDate, selectedRefModel, selectedInitialModel } = context.getters;
        const intervals = helpers.generateIntervals(startDate, projectStartTime, now);
        if (!withCanCalculateVolumes(selectedRefModel).canCalculateVolumes) return;
        await context.dispatch('setDataLicence', {
          projectUuid,
          refmodelUuid: selectedRefModel.uuid,
          initialModelUuid: selectedInitialModel,
          projectStartTime,
          intervals,
          cancelToken
        });
      }
    }
  },
  actions: {
    ...detailsRefmodelChartModule.actions,
    ...detailsRefmodelShellModule.actions,
    ...detailsRefmodelOverlayModule.actions,
    ...fetchPointDataStoreMixin.actions,
    // handles reference model selection from the map or Object Explorer
    async handleRefModelSelected(context, refmodel) {
      try {
        // cancel all ongoing actions for the selected refmodel
        context.dispatch('cancelable/cancelActions', { sourceUid: refmodel.uuid }, { root: true });
        context.commit('setSelectedRefmodel', refmodel);
        context.commit('setSelectedInitialModel', null);
        await context.dispatch('clearData');

        const now = new Date(Date.now());
        const startDate = subHours(now, 24);
        context.commit('setNow', now);
        context.commit('setStartDate', startDate);
        await context.dispatch('setData', { sourceUid: refmodel.uuid });
      } catch (e) {
        context.dispatch('notifications/error', e, { root: true });
      }
    },
    async handleRefModelDeselected(context) {
      context.dispatch('cancelable/cancelActions', { sourceUid: context.getters.selectedRefModel.uuid }, { root: true });
      await context.dispatch('clearOverlays');
      context.commit('setSelectedRefmodel', null);
      await context.dispatch('clearData');
      context.commit('setSelectedInitialModel', null);
    },
    async handleInitialModelSelection(context, initialModelUuid) {
      try {
        if (context.state.selectedInitialModel === initialModelUuid) {
          return;
        }
        context.commit('setSelectedInitialModel', initialModelUuid);
        await context.dispatch('clearData', false);
        await context.dispatch('setData', { sourceUid: context.getters.selectedRefModel.uuid });
      } catch (e) {
        context.dispatch('notifications/error', e, { root: true });
      }
    },
    async handeChangeChartRange(context, { startDate }) {
      try {
        await context.dispatch('clearData');
        context.commit('setStartDate', startDate);
        await context.dispatch('setData', { sourceUid: context.getters.selectedRefModel.uuid });
      } catch (e) {
        context.dispatch('notifications/error', e, { root: true });
      }
    },
    async clearData(context, clearStartDate = true) {
      await context.dispatch('removePoints');
      await context.dispatch('setSelectedSet', null);
      context.commit('setVolumes', []);
      await context.dispatch('switchCutFillProgressDetails', 'cutfill');
      await context.commit('setSelectedRange', null);
      if (context.getters.isProgressSurfaceActive) context.dispatch('reloadProgressSurface');
      if (clearStartDate) context.commit('setStartDate', null);
      context.commit('setInitialVolumes', null);
    },
    async setSelectedSet(context, payload) {
      const { selectedSet } = context.state;
      const { isSurfaceActive, isSurfaceLoading, isCutFillActive, isCutFillLoading, isShowPointsActive } = context.getters;
      const newUuid = payload ? payload.uuid : null;
      const oldUuid = selectedSet ? selectedSet.uuid : null;
      context.commit('setSelectedSet', payload);
      if (oldUuid !== newUuid) {
        if (isSurfaceActive || isSurfaceLoading) context.dispatch('reloadSurface');
        if (isCutFillActive || isCutFillLoading) context.dispatch('reloadCutFill');
        if (isShowPointsActive) context.dispatch('reloadPoints');
      }
    },
    async handleCameraFlyToRefmodel(context) {
      if (!context.getters.selectedRefModel) return;
      await context.dispatch('map/setCameraToEntity', context.getters.selectedRefModel.uuid, { root: true });
    },
    async setDataLicence(context, { projectUuid, refmodelUuid, initialModelUuid, projectStartTime, intervals, cancelToken }) {
      const measurementSystem = context.rootGetters['app/measurementSystem'];
      context.commit('setSpinnerValue', 0);
      try {
        const result = await calcService.calculate(
          projectUuid,
          refmodelUuid,
          initialModelUuid,
          intervals.intervals,
          projectStartTime,
          measurementSystem,
          (progress) => {
            context.commit('setSpinnerValue', _.round(progress * 100));
          },
          cancelToken
        );
        if (cancelToken.canceled) return;
        context.commit('setIntervalUnit', intervals.unit);
        if (result) {
          // display warning if there are errors or no volumes
          const allError = result.calculations.every(c => c.isErrorous);
          const noVolumes = result.calculations.every(c => !c.isPlottable);
          if (allError || noVolumes) {
            context.dispatch('notifications/warning', {
              message: $gettext('Unable to display progress chart - cannot calculate volumes for selected reference model.')
            }, { root: true });
          }
          // set initial volumes, chart volumes and add points to the map
          context.commit('setInitialVolumes', result.initialVolume);
          context.commit('setVolumes', result.calculations);
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Error has occured - can\'t calculate volumes') }, { root: true });
      }
    },
    async showPointGrid(context) {
      const { selectedRefModel } = context.getters;
      context.dispatch('showGridData', selectedRefModel);
    }
  },


};

export default detailsRefmodelModule;