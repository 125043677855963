<template>
  <div class="cx-object-explorer" :class="applyClass.wrapper">
    <cx-object-explorer-control
      :active="isActive"
      :disabled="isDisabled"
      :toggle="toggleObjectExplorer"/>
    <cx-object-explorer-body
      v-show="isActive"
      :class="applyClass.list"
      :objects="objectExplorerEntities"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxObjectExplorerControl from './CxObjectExplorerControl';
import CxObjectExplorerBody from './CxObjectExplorerBody';

export default {
  name: 'CxObjectExplorer',
  components: {
    CxObjectExplorerControl,
    CxObjectExplorerBody
  },
  mixins: [CxAnimationMixin],
  computed: {
    ...mapGetters('shell', ['isObjectExplorerOpened', 'isFilterPanelOpened']),
    ...mapGetters('map', ['objectExplorerEntities']),
    applyClass() {
      const animateShowPanel = this.applyAnimations('showFilterPanel');
      const animateCollapsePanel = this.applyAnimations('collapseFilterPanel');
      const animateOpenList = this.applyAnimations('openObjectList');
      return {
        wrapper: {
          [animateShowPanel]: this.isFilterPanelOpened,
          [animateCollapsePanel]: !this.isFilterPanelOpened,
          'filter-panel-opened': this.isFilterPanelOpened,
        },
        list: {
          [animateOpenList]: this.isActive
        }
      };
    },
    isActive() {
      return !this.isDisabled && this.isObjectExplorerOpened;
    },
    isDisabled() {
      return !this.objectExplorerEntities.length;
    }
  },
  methods: {
    ...mapMutations('shell', ['setIsObjectExplorerOpened']),
    toggleObjectExplorer() {
      if (!this.isDisabled) this.setIsObjectExplorerOpened(!this.isObjectExplorerOpened);
    },
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-object-explorer {
  z-index: @objectExplorerZI;
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: @upperOverlaysSpace;
  left: @objectExplorerFilterPanelCollapsePosition;
  .flex(column, flex-start, flex-start);
  font-size: @appFontSize;
  font-weight: @appFontWeight;

  &.filter-panel-opened {
    left: @objectExplorerFilterPanelOpenPosition;
  }

  &.cx-object-explorer--animate--show {
    transition: left 360ms linear;
    left: @objectExplorerFilterPanelOpenPosition;
  }
  &.cx-object-explorer--animate--collapse {
    transition: left 200ms linear 20ms;
    left: @objectExplorerFilterPanelCollapsePosition;
  }
}
</style>
