import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const notificationsModule = {
  state: {
    alert: {},
    snackbar: {}
  },
  getters: {
    alert(state) {
      return state.alert;
    },
    snackbar(state) {
      return state.snackbar;
    }
  },
  mutations: {
    setAlert(state, alert) {
      state.alert = { ...alert };
    },
    setSnackbar(state, snackbar) {
      state.snackbar = { ...snackbar };
    },
  },
  actions: {
    showAlert(context, { message, type }) {
      let text = $gettext('Error Occured');
      if (message) text = message;
      const alert = {
        type,
        text
      };
      context.commit('setAlert', alert);
    },
    showSnackbar(context, message) {
      const snack = {
        type: 'error',
        text: message
      };
      context.commit('setSnackbar', snack);
    },
    clearAlert(context) {
      context.commit('setAlert', {});
    },
    error(context, payload) {
      if (process.env.NODE_ENV === 'development' && payload.exception) {
        // eslint-disable-next-line no-console
        console.error(payload.exception);
      }
      context.dispatch('showAlert', { message: payload.message, type: 'error' });
    },
    warning(context, payload) {
      context.dispatch('showAlert', { message: payload.message, type: 'warning' });
    },
    notify(context, payload) {
      context.dispatch('showAlert', { message: payload.message, type: 'notify' });
    },
    alertSuccess(context, payload) {
      context.dispatch('showAlert', { message: payload.message, type: 'success' });
    }
  }
};

export default notificationsModule;
