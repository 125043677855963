<template>
  <div class="cx-unit-type-filter">
    <cx-combobox
      :items="unitTypes"
      :value="selectedUnitTypes"
      :input="changeSelectedUnitTypes"
      :label="label"
      :disabled="disabled"
      :loading="loading"
      isChips
      isTextInput
      isDropdown
      ref="chips"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import CxCombobox from '@/components/cxCombobox/CxCombobox';

export default {
  name: 'CxUnitTypeFilter',
  components: {
    CxCombobox
  },
  props: {
    availableUnitTypes: Array,
    selectedUnitTypes: Array,
    changeSelectedUnitTypes: Function,
    disabled: Boolean,
    loading: Boolean,
  },
  computed: {
    unitTypes() {
      return this.availableUnitTypes.map((t) => {
        return {
          id: t.id,
          name: t.name,
          isChipSelected: this.selectedUnitTypes.includes(t.id)
        };
      });
    },
    label() {
      return this.$gettext('Unit Types');
    },
  },
  methods: {
    /**
     * Clears combobox/chips textbox.
     */
    clearChipsInput() {
      this.$refs.chips.onReset();
    }
  }
};
</script>