import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';

const detailsRefmodelModulePlugin = (store) => {
  store.subscribeAction(({ type, payload }) => {
    switch (type) {
      // when selection changes
      case 'shell/handleEntitySelection': {
        const { newValue, oldValue } = payload;
        const isOldRefmodel = oldValue ? Object.values(RefmodelTypes).includes(oldValue.entityType) : false;
        const isNewRefmodel = newValue ? Object.values(RefmodelTypes).includes(newValue.entityType) : false;
        // deselect old one and select new one
        if (isOldRefmodel && isNewRefmodel) {
          store.dispatch('detailsRefmodel/handleRefModelDeselected', { newValue, oldValue }, { root: true })
            .then(() => store.dispatch('detailsRefmodel/handleRefModelSelected', newValue, { root: true }));
        } else if (isOldRefmodel) {
          store.dispatch('detailsRefmodel/handleRefModelDeselected', { newValue, oldValue }, { root: true });
        } else if (isNewRefmodel) {
          store.dispatch('detailsRefmodel/handleRefModelSelected', newValue, { root: true });
        }
        break;
      }
    }
  });
};

export default detailsRefmodelModulePlugin;