import _ from 'lodash';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';

const iconHelper = {
  getIcon(type) {
    if (!_.isString(type)) return null;
    if (type === PointSetType) return 'cx_as_built';
    if (type === SurfacelogType) return 'cx_points';
    if (type === DrillHoleSetType) return 'radio_button_unchecked';
    return null;
  },
};

export default iconHelper;