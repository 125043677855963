import api from '@/http/api';
import { format } from 'date-fns';
import { getFileDownloadError } from '@/domain/fileDownload.error';

const detailsAwarenessEventSetModule = {
  state: {
    // uuids of sets with clusterization switched on
    clusteredSets: [],
    // uuid of sets for which we display the heatmap
    heatmapDisplayed: []
  },
  getters: {
    isClustered: (state) => (uuid) => !!state.clusteredSets.find(s => s === uuid),
    isHeatmapDisplayed: (state) => (uuid) => !!state.heatmapDisplayed.find(s => s === uuid),
  },
  mutations: {
    setClusterizationForSet(state, { uuid, shouldCluster }) {
      if (shouldCluster) state.clusteredSets = [...state.clusteredSets, uuid];
      else state.clusteredSets = state.clusteredSets.filter(u => u !== uuid);
    },
    setHeatmapForSet(state, { uuid, show }) {
      if (show) state.heatmapDisplayed = [...state.heatmapDisplayed, uuid];
      else state.heatmapDisplayed = state.heatmapDisplayed.filter(u => u !== uuid);
    }
  },
  actions: {
    async toggleClustering(context, { uuid, shouldCluster }) {
      context.commit('setClusterizationForSet', { uuid, shouldCluster });
      await context.dispatch('map/toggleAwarenessEventSetClustering', { uuid, shouldCluster }, { root: true });
    },
    async toggleHeatmap(context, { uuid, show }) {
      context.commit('setHeatmapForSet', { uuid, show });
      await context.dispatch('map/toggleAwarenessEventSetHeatmap', { uuid, show }, { root: true });
    },
    async exportEvents(context, { eventSet }) {
      try {
        const { projectUuid } = context.rootGetters;
        const { originTimeRange, originFilters } = eventSet;
        const startTime = originTimeRange.from.toISOString();
        const endTime = originTimeRange.to.toISOString();
        const extension = 'csv';
        const fileName = `ConX_Awareness_Events_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        await api.exportAwarenessEvents(
          projectUuid,
          startTime,
          endTime,
          originFilters,
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      }
    },
    async downloadPowerBiTemplate(context) {
      try {
        context.commit('setIsExportLoading', true);
        const { projectUuid } = context.rootGetters;
        await api.fetchPoweBiTemplate(projectUuid);
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExportLoading', false);
      }
    }
  }

};

export default detailsAwarenessEventSetModule;