import { PointSetType } from '@/domain/pointSet/PointSetType';
import { PointSet } from '@/domain/pointSet/PointSet';
import { Point } from '@/domain/point/Point';
import { EarthmovingPoint } from '@/domain/point/EarthmovingPoint';
import { StabilizationPoint } from '@/domain/point/StabilizationPoint';
import { FilterModel } from '@/domain/filters/FilterModel';
import { OriginTimeRange } from '@/domain/OriginTimeRange';
import { gettext } from '@/translations/gettext.setup';

const { $ngettext } = gettext;

const createPointSet = ({
  uuid,
  name = null,
  points,
  totalCount,
  originFilters,
  originTimeRange
}: {
  uuid: string,
  name: string | null,
  points: EarthmovingPoint[] | StabilizationPoint[] | Point[],
  totalCount: number,
  originFilters: FilterModel[] | null,
  originTimeRange: OriginTimeRange | null
}): PointSet => {
  const defaultName = $ngettext('point', 'points', points.length);
  const pointSet = {
    entityType: PointSetType,
    uuid,
    name: name || `${points.length} ${defaultName}`,
    points: [...points],
    count: points.length,
    totalCount,
    originFilters,
    originTimeRange
  };
  return pointSet;
};

export { createPointSet };
