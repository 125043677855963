import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';


const canBeInitialSurfaceMap = {
  [RefmodelTypes.DefaultRefmodelType]: true,
  [RefmodelTypes.TerrainModelType]: true,
  [RefmodelTypes.HelperFileType]: false,
  [RefmodelTypes.CoordinateFileType]: false,
  [RefmodelTypes.RoadLineType]: false,
  [RefmodelTypes.StringlineModelType]: false,
  [RefmodelTypes.VolumeDescriptionModelType]: false,
  [RefmodelTypes.CrossFallModelType]: false,
  [RefmodelTypes.DrillPattern]: false
};
const canCalculateVolumesMap = {
  [RefmodelTypes.DefaultRefmodelType]: true,
  [RefmodelTypes.TerrainModelType]: true,
  [RefmodelTypes.StringlineModelType]: true,
  [RefmodelTypes.VolumeDescriptionModelType]: true,
  [RefmodelTypes.CrossFallModelType]: true,
  [RefmodelTypes.HelperFileType]: false,
  [RefmodelTypes.CoordinateFileType]: false,
  [RefmodelTypes.RoadLineType]: false,
  [RefmodelTypes.DrillPattern]: false
};

export { canBeInitialSurfaceMap, canCalculateVolumesMap };

