<template>
  <div class="cx-navigation-item">
    <div class="cx-navigation-item__bar" v-show="isActive"></div>
    <cx-toggle
      :icon="icon"
      :iconSize="22"
      @click="navigate"
      :active="isActive"
      :disabled="disabled"
      :tooltip="tooltip"/>
  </div>
</template>

<script>
import CxToggle from '@/components/CxToggle';

export default {
  name: 'CxFilterItem',
  components: {
    CxToggle
  },
  props: {
    filterItem: String,
    onNavigated: Function,
    isActive: Boolean,
    icon: String,
    disabled: Boolean,
    tooltip: { type: [String, Object] },
  },
  methods: {
    navigate() {
      if (!this.disabled) this.onNavigated(this.filterItem);
    }
  }
};
</script>


<style lang="less">
@import '../../common';

.cx-navigation-item {
  display: flex;
  height: @filterNavigationItemBarHeight;
  margin-top: 15px;
  margin-bottom: 15px;

  .cx-navigation-item__bar {
    background-color: @activeFilterColor;
    .size(@filterNavigationItemBarWidth, @filterNavigationItemBarHeight);
    display: inline-block;
    position: absolute;
    box-shadow: @activeFilterBoxShadow;
  }

  .cx-toggle {
    width: 100%;
  }
}

</style>