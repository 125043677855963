<template>
  <cx-overlay-controls v-if="hasStabilizationLicence || hasEarthmovingLicence">
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.drillPattern" icon="apps"
        :title="translations.drillPattern" @toggle="toggleDrillPattern"/>
      <cx-overlay-controls-item
        class="cx-drill-pattern-labels-control" v-bind="overlayState.drillPatternLabels"
        icon="place"
        :title="translations.drillPatternLabels" left="-56"
        @toggle="toggleDrillPatternLabels"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.holes2D" icon="adjust"
        :title="translations.holes2D" @toggle="toggleHoles2D"/>
      <cx-overlay-controls-item
        v-bind="overlayState.holes3D" icon="filter_tilt_shift"
        :title="translations.holes3D" @toggle="toggleHoles3D"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.asBuilt" icon="cx_as_built" :title="translations.asBuilt"
        @toggle="toggleAsBuilt"/>
    </cx-overlay-controls-group>
  </cx-overlay-controls>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CxOverlayControls from '@/app/detailsPanel/common/overlayControls/CxOverlayControls';
import CxOverlayControlsGroup from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsGroup';
import CxOverlayControlsItem from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsItem';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';

export default {
  name: 'CxDrillPatternOverlayControls',
  components: {
    CxOverlayControls,
    CxOverlayControlsGroup,
    CxOverlayControlsItem
  },
  mixins: [CxLicenceMixin],
  data() {
    return {
      loading: {
        holes2D: false,
        holes3D: false,
        asBuilt: false,
        drillPattern: false,
        drillPatternLabels: false
      }
    };
  },
  watch: {
    selectedDrillPattern(newDrillPattern, oldDrillPattern) {
      if (newDrillPattern !== oldDrillPattern) this.setAsBuiltCount(0);
    }
  },
  computed: {
    ...mapGetters('detailsDrillPattern', [
      'selectedDrillPattern',
      'showingHoles2D',
      'showingHoles3D',
      'showingAsBuilts',
      'drillPatternVisible',
      'isDrillPatternLabelsVisible',
      'holesVisible',
      'asBuiltCount'
    ]),
    ...mapGetters('map', ['maxLoadablePointsCount', 'wouldExceedPerformanceLimit']),
    overlayState() {
      return {
        holes2D: {
          active: !!this.showingHoles2D,
          loading: this.loading.holes2D
        },
        holes3D: {
          enabled: this.showingHoles2D,
          active: !!this.showingHoles3D,
          loading: this.loading.holes3D
        },
        asBuilt: {
          enabled: !!this.showingAsBuilts || !this.wouldExceedPerformanceLimit(this.asBuiltCount),
          active: !!this.showingAsBuilts,
          loading: this.loading.asBuilt
        },
        drillPatternLabels: {
          active: this.isDrillPatternLabelsVisible,
          loading: this.loading.drillPatternLabels,
          enabled: this.drillPatternVisible
        },
        drillPattern: {
          active: this.drillPatternVisible,
          loading: this.loading.drillPattern
        }
      };
    },
    translations() {
      return {
        drillPattern: this.$gettext('Drill Pattern'),
        drillPatternLabels: this.$gettext('Visualize Drill Pattern Details'),
        holes2D: this.$gettext('Holes'),
        holes3D: this.$gettext('3D Holes'),
        asBuilt: this.getAsBuiltLabel()
      };
    }
  },
  methods: {
    ...mapActions('detailsDrillPattern', ['toggleHoles2DVisibility', 'toggleHoles3DVisibility', 'toggleAsBuiltVisibility', 'toggleDrillPatternVisibility', 'toggleDrillPatternLabelsVisibility']),
    ...mapMutations('detailsDrillPattern', ['setAsBuiltCount']),
    toggleHoles2D() {
      this.loading.holes2D = true;
      this.toggleHoles2DVisibility().then(() => {
        this.loading.holes2D = false;
      });
    },
    toggleHoles3D() {
      this.loading.holes3D = true;
      this.toggleHoles3DVisibility().then(() => {
        this.loading.holes3D = false;
      });
    },
    toggleAsBuilt() {
      this.loading.asBuilt = true;
      this.toggleAsBuiltVisibility().then(() => {
        this.loading.asBuilt = false;
      });
    },
    toggleDrillPattern() {
      this.loading.drillPattern = true;
      this.toggleDrillPatternVisibility().then(() => {
        this.loading.drillPattern = false;
      });
    },
    toggleDrillPatternLabels() {
      this.loading.drillPatternLabels = true;
      this.toggleDrillPatternLabelsVisibility().then(() => {
        this.loading.drillPatternLabels = false;
      });
    },
    getAsBuiltLabel() {
      if (!this.overlayState.asBuilt.enabled) {
        const title = this.$gettext('Unable to load %{count} points because they exceed the performance limit (%{max}).');
        return this.$gettextInterpolate(title, { count: this.asBuiltCount, max: this.maxLoadablePointsCount });
      }
      return this.$gettext('As Built');
    }
  }
};
</script>

<style lang="less">
</style>