import { mapGetters } from 'vuex';
import { filterPanelTypes, filterPointPanelTypes, detailsPanelTypes } from '@/store/shellModule/shell.store';

const animations = {
  filterPanel: {
    drawer: 'cx-filter-panel--animate--drawer',
    filter1: 'cx-filter-panel--animate--1',
    filter2: 'cx-filter-panel--animate--2',
    filter3: 'cx-filter-panel--animate--3',
    filter4: 'cx-filter-panel--animate--4',
    filter5: 'cx-filter-panel--animate--5',
    filter6: 'cx-filter-panel--animate--6',
    action: 'cx-filter-panel--animate--action-buttons'
  },
  detailsPanel: {
    handle: 'cx-details-panel--animate--handle',
    content: 'cx-details-panel--animate--content',
    overlayControls: 'cx-details-panel--animate--overlay-controls',
    avatar: 'cx-details-panel--animate--avatar',
    title: 'cx-details-panel--animate--title-panel',
    relatedEntities: 'cx-details-panel--animate--related-entities-panel',
    mainMetadata: 'cx-details-panel--animate--main-data-panel',
    analysis: 'cx-details-panel--animate--analysis-panel'
  },
  objectExplorer: {
    showFilterPanel: 'cx-object-explorer--animate--show',
    collapseFilterPanel: 'cx-object-explorer--animate--collapse',
    openObjectList: 'cx-object-explorer--animate--open-list',
    addNewItem: 'cx-object-explorer--animate--add-item'
  },
  avatar: {
    image: 'cx-avatar--animate--img',
    overlay: 'cx-avatar--animate--overlay',
    location: 'cx-avatar--animate--location'
  },
  legend: {
    showDetailsPanel: 'cx-legend--animate--emerge',
    collapseDetailsPanel: 'cx-legend--animate--submerge',
    content: 'cx-legend--animate--content'
  },
  directSearch: {
    input: 'cx-direct-search--animate--input',
    dropdown: 'cx-direct-search--animate--dropdown'
  },
  mapToolbar: {
    content: 'cx-map-toolbar--animate--content',
  }
};

const CxAnimationMixin = {
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    ...mapGetters('shell', ['animationSwitch', 'isFilterPanelOpened', 'isDetailsPanelOpened']),
    isAnimationReady() {
      if (this.componentName.includes('Filter')) return this.isFilterPanelOpened && this.isMounted;
      if (this.componentName.includes('Details')) return this.isDetailsPanelOpened && this.isMounted;
      return this.isMounted;
    },
    componentName() {
      return this.$options.name;
    }
  },
  methods: {
    getAnimations(component) {
      const {
        isFilterPanelTransitions,
        isDetailsPanelTransitions,
        isObjectExplorerTransitions,
        isAvatarTransitions,
        isLegendTransitions,
        isDirectSearchTransitions,
        isMapToolbarTransitions
      } = this.animationSwitch;
      if (isFilterPanelTransitions && ((component === 'CxFilterPanel') || Object.values(filterPanelTypes).includes(component) || Object.values(filterPointPanelTypes).includes(component))) return animations.filterPanel;
      if (isDetailsPanelTransitions && ((component === 'CxDetailsPanel') || Object.values(detailsPanelTypes).includes(component))) return animations.detailsPanel;
      if (isObjectExplorerTransitions && ((component === 'CxObject') || (component === 'CxObjectExplorer'))) return animations.objectExplorer;
      if (isAvatarTransitions && (component === 'CxAvatar')) return animations.avatar;
      if (isLegendTransitions && (component === 'CxLegend')) return animations.legend;
      if (isDirectSearchTransitions && (component === 'CxDirectSearch')) return animations.directSearch;
      if (isMapToolbarTransitions && (component === 'CxMapToolbar')) return animations.mapToolbar;
      return null;
    },
    applyAnimations(element) {
      const componentAnimations = this.getAnimations(this.componentName);
      return componentAnimations ? componentAnimations[element] : '';
    }
  },
  mounted() {
    this.isMounted = true;
  },
};

export default CxAnimationMixin;
