<template>
  <div class="cx-metadata-panel">
    <div v-for="item of metadata" :key="`cx-metadata-panel-${item.fieldName}`" class="item">
      <cx-vertical-property
        class="cx-vertical-property--margin"
        :fieldName="item.fieldName"
        :value="item.value"
        :unit="item.unit"/>
    </div>
  </div>
</template>

<script>
import CxVerticalProperty from '@/components/CxVerticalProperty';

export default {
  name: 'CxMetadataDataPanel',
  components: {
    CxVerticalProperty
  },
  props: {
    // metadata fields in format { fieldName: '', value: '', unit: '' }
    metadata: Array
  },
  computed: {
  }

};

</script>

<style lang="less">
@import '../../../common';

.cx-metadata-panel {
  flex: 0 1 auto;
  margin-left: 20px;
  margin-top: @detailsTitleContentHeight + 13px;
  width: auto;
  max-width: @detailsMPointMetadataPanelWidth;
  max-height: @detailsMPointMetadataPanelHeight;
  flex-wrap: wrap;
  &.nolicence {
    max-height: @detailsMPointMetadataPanelHeighNoLicence;
  }
  font-size: @appFontSize;
  .flex(column);

  .fieldName {
    color: @textColorLowlight;
  }
  .value {
    color: @textColor;
  }
  .item {
    width: 150px;
  }

  .cx-vertical-property--margin {
    margin-right: 40px;
    margin-bottom: 20px;
  }
  .cx-metadata-panel:nth-child(n+2) {
    margin-left: 20px;
  }
}
</style>