<template>
  <div class="cx-refmodel-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon icon="cx_ref_model" class="cxu-color-highlight" :class="iconClassName" :size="12"/>
      <span class="map-overlay__title__name">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxReferenceModelTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {
    iconClassName() {
      if (!this.hoveredEntity) return '';
      return 'map-overlay__title__icon';
    },
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.name;
    },
  },
};
</script>

<style lang="less">

</style>