<template>
  <div class="cx-point-codes-filter">
    <cx-combobox
      :items="pointCodes"
      :value="selectedPointCodes"
      :input="changeSelectedPointCodes"
      :label="label"
      :disabled="disabled"
      :loading="loading"
      isChips
      isTextInput
      isDropdown
      ref="chips"/>
  </div>
</template>

<script>
import CxCombobox from '@/components/cxCombobox/CxCombobox';

export default {
  name: 'CxPointCodesFilter',
  components: {
    CxCombobox
  },
  props: {
    availablePointCodes: Array,
    selectedPointCodes: Array,
    changeSelectedPointCodes: Function,
    disabled: Boolean,
    loading: Boolean,
  },
  computed: {
    pointCodes() {
      return this.availablePointCodes.map((t) => {
        return {
          id: t.point_code,
          name: `${t.point_code} - ${t.point_code_name}`,
          isChipSelected: this.selectedPointCodes.includes(t.point_code)
        };
      });
    },
    label() {
      return this.$gettext('Surface log');
    },
  },
  methods: {
    /**
     * Clears combobox/chips textbox.
     */
    clearChipsInput() {
      this.$refs.chips.onReset();
    }
  }
};
</script>