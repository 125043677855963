<template>
  <cx-overlay-controls>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item v-bind="overlayState.clustered" icon="mdi-dots-hexagon" :title="translations.clustering" @toggle="handleToggleClustering"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item v-bind="overlayState.heatmap" icon="mdi-map-outline" :title="translations.heatmap" @toggle="handleToggleHeatmap"/>
    </cx-overlay-controls-group>
  </cx-overlay-controls>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxOverlayControls from '@/app/detailsPanel/common/overlayControls/CxOverlayControls';
import CxOverlayControlsGroup from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsGroup';
import CxOverlayControlsItem from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsItem';

export default {
  name: 'CxAwarenessOverlayControls',
  components: {
    CxOverlayControls,
    CxOverlayControlsGroup,
    CxOverlayControlsItem,
  },
  data() {
    return {
      clusteredLoading: false,
      heatmapLoading: false
    };
  },
  props: {
    selectedSetUuid: { type: String }
  },
  computed: {
    ...mapGetters('detailsAwarenessEventSet', ['isClustered', 'isHeatmapDisplayed']),
    clustered() {
      return !!this.isClustered(this.selectedSetUuid);
    },
    heatmap() {
      return !!this.isHeatmapDisplayed(this.selectedSetUuid);
    },
    overlayState() {
      return {
        clustered: {
          enabled: !this.clusteredLoading,
          active: this.clustered,
          loading: this.clusteredLoading
        },
        heatmap: {
          enabled: !this.heatmapLoading,
          active: this.heatmap,
          loading: this.heatmapLoading
        }
      };
    },
    translations() {
      return {
        clustering: this.clustered ? this.$gettext('Decluster') : this.$gettext('Cluster'),
        heatmap: this.heatmap ? this.$gettext('Hide Heatmap') : this.$gettext('Show Heatmap'),
      };
    },
  },
  methods: {
    ...mapActions('detailsAwarenessEventSet', ['toggleClustering', 'toggleHeatmap']),
    async handleToggleClustering() {
      this.clusteredLoading = true;
      await this.toggleClustering({ uuid: this.selectedSetUuid, shouldCluster: !this.clustered });
      this.clusteredLoading = false;
    },
    async handleToggleHeatmap() {
      this.heatmapLoading = true;
      await this.toggleHeatmap({ uuid: this.selectedSetUuid, show: !this.heatmap });
      this.heatmapLoading = false;
    }
  }
};
</script>

<style lang="less">
</style>