<template>
  <div class="cx-direct-search-dropdown" @mouseleave="hideTooltip()">
    <div
      class="cx-direct-search-dropdown__suggestions"
      v-if="suggestions.length || !isSuggestions">
      <div
        class="cx-direct-search-dropdown__suggestions__item"
        v-for="(suggestion, sIndex) in suggestions"
        :key="sIndex"
        :class="{ active: isActive(suggestion) }"
        @click="onClick(suggestion)"
        @keyup="onKeyUp"
        @mouseenter="showTooltip(suggestion)"
        @mouseleave="hideTooltip"
        v-cx-tooltip="{
          text: suggestion.displayName,
          show: isShowTooltip(suggestion),
          position: 'top-left',
          maxWidth: 150,
        }">
        <div class="cx-direct-search-dropdown__suggestions__item__name">
          <div class="cx-direct-search-dropdown__suggestions__item__name__parts">
            <span
              class="cx-direct-search-dropdown__suggestions__item__name__parts__part"
              :class="[letter.match ? 'highlight' : 'no-highlight']"
              v-for="(letter, lIndex) in renderName(suggestion)"
              :key="lIndex">
              {{ letter.char }}
            </span>
          </div>
          <span class="cx-direct-search-dropdown__suggestions__item__name__path">{{ renderPath(suggestion) }}</span>
        </div>
        <cx-icon class="cx-direct-search-dropdown__suggestions__item__icon cxu-color-highlight" :icon="displayIcon(suggestion)" :size="17"/>
      </div>
      <div
        class="cx-direct-search-dropdown__no-results"
        v-if="!isSuggestions">
        <span class="cx-direct-search-dropdown__no-result__text">{{ zeroResultText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { splitStringIntoCharHighlights } from '@/utils/stringOperations';
import iconHelper from '@/viewModel/iconHelper';
import CxIcon from '@/components/CxIcon';
import { withIcon, withFullPath } from '@/domain/entityMixins';

export default {
  name: 'CxDirectSearchDropdown',

  components: {
    CxIcon
  },

  props: {
    input: String,
    suggestions: Array,
    activeSuggestion: Object,
    isSuggestions: Boolean,
    onSuggestionClick: Function,
    onKeyUp: Function,
  },

  data() {
    return {
      hoveredSuggestion: null,
      isTooltip: false
    };
  },

  computed: {
    currentSearch() {
      return this.input.trim();
    }
  },

  methods: {
    // Checks if the current suggestion element is active
    isActive(suggestion) {
      return suggestion === this.activeSuggestion;
    },

    // Checks if the current suggestion element is hovered
    isHovered(suggestion) {
      if (!this.suggestions.length) return false;
      return suggestion === this.hoveredSuggestion;
    },

    isShowTooltip(suggestion) {
      return this.isTooltip && this.isHovered(suggestion);
    },

    // Passes appropriate path to type icon for suggestion list
    displayIcon(suggestion) {
      const { entityType, icon } = withIcon(suggestion);
      if (!_.isString(entityType)) return 'public';
      return icon || iconHelper.getIcon(entityType);
    },

    renderName(suggestion) {
      return splitStringIntoCharHighlights(this.currentSearch, suggestion.displayName);
    },

    renderPath(suggestion) {
      return withFullPath(suggestion, 16).fullPath;
    },

    onClick(suggestion) {
      this.hideTooltip();
      this.onSuggestionClick(suggestion);
    },

    showTooltip(suggestion) {
      if (suggestion.displayName.length < 25) return;
      if (!this.isTooltip) this.isTooltip = true;
      this.hoveredSuggestion = suggestion;
      this.tooltipText = suggestion.displayName;
    },

    hideTooltip() {
      this.isTooltip = false;
      this.hoveredSuggestion = null;
      this.tooltipText = '';
    },
  },

  unmounted() {
    this.hideTooltip();
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-direct-search-dropdown {
  position: absolute;
  top: @inputHeight;
  border-radius: 2px;
  background-color: @directSearchSuggestionsBGColor;
  width: @directSearchSuggestionsWidth;

  .cx-direct-search-dropdown__suggestions {
    .flex(column, flex-start, stretch);
    padding: 5px 0;

    .cx-direct-search-dropdown__suggestions__item {
      .flex(row, space-around, center);
      text-align: left;
      min-height: @inputHeight;
      width: 100%;
      cursor: pointer;
      user-select: none;
      padding: 8px 5px 8px 17px;
      position: relative;

      &:hover, &.active {
        background-color: @directSearchHoverActiveBGColor;
        border-radius: @inputBorderRadius;
        margin: 0 5px;
        width: 95.633187773%;
        padding: 8px 0 8px @inputSpace;
      }

      .cx-direct-search-dropdown__suggestions__item__name {
        width: 80%;
        padding-right: @inputSpace;

        .cx-direct-search-dropdown__suggestions__item__name__parts {
          .cut-overflow();

          .cx-direct-search-dropdown__suggestions__item__name__parts__part {

            &.highlight {
              color: @textColorBase;
            }

            &.no-highlight {
              color: @textColor;
            }
          }
        }

        .cx-direct-search-dropdown__suggestions__item__name__path {
          color: @textColorDisabled;
        }
      }

      .cx-direct-search-dropdown__suggestions__item__icon {
        margin-right: @inputSpace;
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .cx-direct-search-dropdown__no-results {
    padding: 5px 21px;
    user-select: none;
    color: @textColorBase;
    opacity: 0.3;
  }
}
</style>