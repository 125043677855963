import { parseISO } from 'date-fns';
import http from '@/http/axios.instance';
import mapper from './point.mapper';
import { filterMapper } from './filter.mapper';
import { downloadFile } from '@/http/common/download.service';
import { FilterModel } from '@/domain/filters/FilterModel';
import { PointSet } from '@/domain/pointSet/PointSet';

const filterMeasurePoints = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: FilterModel[],
  includeQueryParams: boolean,
  metadataFields: string[]
): Promise<PointSet> => {
  const mappedFilters = filterMapper.map(filters);
  const asBuiltPointUrl =
    `/api/ui2/v1/projects/${projectUuid}/asbuilt/points/?from_time=${startTime}&to_time=${stopTime}`;

  const params = {
    filters: mappedFilters,
    include_query_params: includeQueryParams,
    metadata_fields: [] as string[]
  };
  if (metadataFields) {
    params.metadata_fields = metadataFields;
  }

  const asBuiltResponse = await http.post(asBuiltPointUrl, params);
  const pointSetTimeRange = { from: parseISO(startTime), to: parseISO(stopTime) };
  const pointSet = mapper.mapPoints(asBuiltResponse.data, filters, pointSetTimeRange);
  return pointSet;
};

const getActiveDays = async (projectUuid: string): Promise<Date[]> => {
  const url = `/api/ui2/v1/projects/${projectUuid}/asbuilt/activedays/`;
  const response = await http.get(url);
  return response.data.map(parseISO);
};

const exportMeasuredPoints = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: FilterModel[],
  fileName: string,
  fileFormat: string,
  includeQueryParams = true,
  metadataFields = ['all']
): Promise<void> => {

  const mappedFilters = filterMapper.map(filters);
  const asBuiltPointUrl =
    `/api/ui2/v1/projects/${projectUuid}/asbuilt/points/?from_time=${startTime}&to_time=${stopTime}&filename=${fileName}&format=${fileFormat}`;

  const params = {
    filters: mappedFilters,
    include_query_params: includeQueryParams,
    metadata_fields: ['all']
  };
  if (metadataFields) {
    params.metadata_fields = metadataFields;
  }
  await http.post(asBuiltPointUrl, params, { responseType: 'blob' }).then(response => downloadFile(response.data, fileName));
};

const getPointMetadataFilterValues = async (projectUuid: string, fieldName: string) => {
  const pointMetadataFilterValuesUrl = `/api/ui2/v1/projects/${projectUuid}/asbuilt/points/values/?field=${fieldName}`;
  const asBuiltResponse = await http.post(pointMetadataFilterValuesUrl);
  return asBuiltResponse.data;
};

const pointApi = {
  filterMeasurePoints,
  getActiveDays,
  exportMeasuredPoints,
  getPointMetadataFilterValues
};
export default pointApi;
