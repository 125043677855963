import { fromUnixTime, addSeconds } from 'date-fns';
import _extend from 'lodash/assignIn';

/**
 * Represents a CZML Cartographic value.
 */
class Cartographic {
  constructor(longitude, latitude, height, time = null) {
    _extend(this, {
      time,
      longitude,
      latitude,
      height
    });
  }
  value() {
    let result = [];
    if (this.time) result.push(this.time);
    result = result.concat([this.longitude, this.latitude, this.height]);
    return result;
  }
}

class UnitQuaternion {
  constructor(heading, pitch, roll, yaw) {
    _extend(this, {
      heading,
      pitch,
      roll,
      yaw
    });
  }
  value() {
    return [this.heading, this.pitch, this.roll, this.yaw];
  }
}

/**
 * Represents a CZML custom UnitActivity value translated to Cesium.TimeInterval.
 */
class UnitActivity {
  constructor(timestamp) {
    this.timestamp = timestamp;
  }
  value() {
    return {
      interval: this.getInterval(),
      isActive: true
    };
  }

  getInterval() {
    const startDate = fromUnixTime(this.timestamp);
    const endDate = addSeconds(startDate, 300);
    return `${startDate.toISOString()}/${endDate.toISOString()}`;
  }
}
export { Cartographic, UnitQuaternion, UnitActivity };