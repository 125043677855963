import * as d3 from 'd3';
import _ from 'lodash';
import { differenceInHours } from 'date-fns';
import D3ChartAxis from './D3ChartAxis';
import D3ChartSelectionLayer from './D3ChartSelectionLayer';
import D3ChartHoverLayer from './D3ChartHoverLayer';
import D3ChartEventLayer from './D3ChartEventLayer';
import D3ChartRangeSelectionLayer from './D3ChartRangeSelectionLayer';
import calc from './calc.helper';

const CHART_SVG_SELECTOR = '.chart__barChart';

function moveToBack() {
  function move() {
    // execution context is a selected node
    const { firstChild } = this.parentNode;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  }
  // execution context is selection
  return this.each(move);
}

function moveToFront() {
  function move() {
    // execution context is a selected node
    this.parentNode.appendChild(this);
  }
  // execution context is selection
  return this.each(move);
}

d3.selection.prototype.moveToBack = moveToBack;
d3.selection.prototype.moveToFront = moveToFront;

export default class D3BarChart {

  // Bool - if true, svg with barchart canvas was attached to a given DOM element
  _initialized = false;
  get initialized() {
    return this._initialized;
  }
  _isRanged;
  // contains data needed to plot the chart
  _chartData;
  _plotDomains; // { x: y: }

  // contains a DOM element that we want to attach chart to
  _domElement;
  // Id of a hovered element in data
  _hoverId = null;
  // Id of a selected element in data
  _selectionId = null;
  _fromElement = null;
  _toElement = null;

  // callback - will be called when bar or range selected
  _onDataSelected;

  // VISUAL DATA
  _style = { size: {}, margin: {}, barWidth: 7 }

  // D3 ELEMENTS
  _svg;
  _selectionLayer;
  _rangeSelectionLayer;
  _hoverLayer;
  _axis;

  constructor(margin, props, onDataSelected, isRanged = false) {
    this._style.margin = { ...margin };
    this._props = props;
    this._onDataSelected = onDataSelected;
    this._isRanged = isRanged;
  }

  /**
   * Initialize the D3BarChart, attaches initial svg layers to the selected DOM element
   * @param {} size
   */
  initialize(domElement, size) {
    if (!domElement) throw new Error('Cannot initialize D3BarChart - domElement must be defined');
    this._domElement = domElement;
    this._style.size = { ...size };
    this._initializeChart();
  }

  /**
   * Updates data that will be rendered on the chart
   * @param {} data
   */
  updateData(data, plotRanges) {
    this._chartData = data;
    this._plotDomains = plotRanges;
  }

  /**
   * Will plot the chart
   * @param {*} data - stacked data
   * @param {Array} domainX - domain X for the data
   */
  plot(data = null) {
    if (data) this.updateData(data);
    if (!this._chartData) throw new Error('No data to plot');
    const { _plotDomains, _chartData } = this;
    this._axis.plot(_chartData, _plotDomains);
    this._plotSeries(this._chartData);

    if (!this._isRanged) this._selectionLayer.plot(this._axis.scaleX, this._axis.scaleY);
    if (this._isRanged) this._rangeSelectionLayer.plot(this._axis.scaleX, this._axis.scaleY, this._chartData);

    this._hoverLayer.plot(this._chartData, this._axis.scaleX, this._axis.scaleY);
    this._eventLayer.plot(this._chartData, this._axis.scaleX, this._axis.scaleY);

    if (this._isRanged) this._onRangeSelected(_.head(this._chartData[0]), _.last(this._chartData[0]));
    if (!this._isRanged) this._onBarSelected(_.last(this._chartData[0]));
  }

  /**
   * Creates main chart area
   */
  _initializeChart() {
    const { size } = this._style;
    const { _style } = this;
    this._svg = d3.select(CHART_SVG_SELECTOR)
      .append('svg:svg')
      .attr('width', '100%')
      .attr('height', size.height)
      .append('g');
    this._selectionLayer = new D3ChartSelectionLayer(this._svg, _style);
    this._rangeSelectionLayer = new D3ChartRangeSelectionLayer(this._svg, _style, this._onRangeSelected.bind(this));
    this._axis = new D3ChartAxis(this._svg, _style);
    this._hoverLayer = new D3ChartHoverLayer(this._svg, _style);
    const dragEvents = this._initializeDragEventHandlers();
    const eventHandlers = {
      click: this._onBarSelected.bind(this),
      mouseover: this._onBarHovered.bind(this),
      mouseout: this._onBarDehovered.bind(this),
    };
    if (this._isRanged) {
      eventHandlers.click = this._onBarRangedSelected.bind(this);
      eventHandlers.dragStarted = (event, data) => dragEvents.dragStarted(event, data);
      eventHandlers.dragg = (event, data, element) => dragEvents.drag(event, data, element);
      eventHandlers.draggEnd = (event, data, element) => dragEvents.dragEnd(event, data, element);
    }
    this._eventLayer = new D3ChartEventLayer(this._svg, _style, eventHandlers);
    this._initialized = true;
  }


  _initializeDragEventHandlers() {
    let dragElementType = '';
    let currentDragPosition = {};
    const self = this;
    const dragEvents = self._rangeSelectionLayer.DRAG_INIT();

    return {
      dragStarted(event, data) {
        if (data.data.uuid === self.fromElement.data.uuid) { dragElementType = 'from'; }
        if (data.data.uuid === self.toElement.data.uuid) { dragElementType = 'to'; }
        if (dragElementType) dragEvents.dragStarted();// self._rangeSelectionLayer.dragStarted(data, dragElementType);
      },
      drag(event, data, element) {
        const elementToDiff = dragElementType === 'from' ? self.toElement : self.fromElement;
        const dateDiff = differenceInHours(element.d.data.timestamp, elementToDiff.data.timestamp);
        if (dragElementType === 'from' && dateDiff < 0) {
          currentDragPosition = element;
          dragEvents.dragFromHandle(element.d, self.toElement);
          dragEvents.dragArea(element.d, self.toElement);
        }
        if (dragElementType === 'to' && dateDiff > 0) {
          currentDragPosition = element;
          dragEvents.dragToHandle(self.fromElement, element.d);
          dragEvents.dragArea(self.fromElement, element.d);
        }
      },
      dragEnd(event, data) {
        dragEvents.dragEnd(data, event, currentDragPosition);
        if (currentDragPosition) {
          if (dragElementType === 'from') self._onRangeSelected(currentDragPosition.d, self.toElement);
          if (dragElementType === 'to') self._onRangeSelected(self.fromElement, currentDragPosition.d);
        }
        dragElementType = null;
        currentDragPosition = null;
      }
    };


  }

  /**
   * Plot cut/fill series
   */
  _plotSeries(data) {
    const colors = ['rgba(45,156,207,0.7)', 'rgba(245,98,98,0.7)'];
    const classes = [
      'chart__barChart--series__fill',
      'chart__barChart--series__cut'
    ];
    const { barWidth, margin } = this._style;
    const { scaleY, scaleX } = this._axis;
    // create g element to keep each series
    this._svg.selectAll('.series')
      .data(data)
      .enter()
      .append('g')
      .attr('class', (d, i) => { return `series ${classes[i]}`; })
      .attr('fill', (d, i) => { return colors[i]; });
    const seriesElement = this._svg.selectAll('.series');

    const transition = d3.transition()
      .ease(d3.easeExp)
      .duration(500);

    const x = d => calc.calcX1(scaleX, d.data.date, barWidth);
    const yStart = d => calc.calcY(scaleY, d[0], margin.bottom);
    const yEnd = d => calc.calcY(scaleY, d[1], margin.bottom);
    const barHeight = (d) => { return yStart(d) - yEnd(d); };

    // create bars
    const bars = seriesElement.selectAll('.chart__barChart--bar').data(d => d);
    bars
      .attr('x', d => x(d))
      .attr('height', 0)
      .attr('y', d => yStart(d))
      .attr('id', d => `bar--chart${d.data.uuid}`)
      .transition(transition)
      .attr('height', d => barHeight(d))
      .attr('y', d => yEnd(d));

    bars.enter()
      .append('rect')
      .attr('class', 'chart__barChart--bar')
      .attr('id', d => `bar--chart${d.data.uuid}`)
      .attr('width', () => { return barWidth; })
      .attr('x', d => x(d))
      .attr('height', 0)
      .attr('y', d => yStart(d))
      .transition(transition)
      .attr('height', d => barHeight(d))
      .attr('y', d => yEnd(d));

    bars.exit().remove();
  }

  _onBarSelected(element, index) {
    if (!element) return;
    const { data } = element;
    if (data.uuid === this._selectionId) return;
    this._selectionId = data.uuid;
    this._onDataSelected(index, { ...data });
    this._selectionLayer.select(element);
  }
  _onBarHovered(element) {
    if (this._selectionId === element.data.uuid) return;
    this._hoveredId = element.data.uuid;
    this._hoverLayer.hover(element);
  }
  _onBarDehovered(element) {
    this._hoveredId = null;
    this._hoverLayer.dehover(element);
  }
  _onRangeSelected(fromElement, toElement, index) {
    if (!fromElement || !toElement) return;
    this._eventLayer.removeDraggable(this.fromElement);
    this._eventLayer.removeDraggable(this.toElement);
    this._eventLayer.applyDraggable(fromElement);
    this._eventLayer.applyDraggable(toElement);
    this.fromElement = fromElement;
    this.toElement = toElement;
    const fromData = fromElement.data;
    const toData = toElement.data;
    this._onDataSelected(index, { from: fromData, to: toData });
    this._rangeSelectionLayer.selectRange(fromElement, toElement);
  }
  _onBarRangedSelected(element, index) {
    if (!element) return;
    const toDelta = Math.abs(differenceInHours(element.data.timestamp, this.toElement.data.timestamp));
    const fromDelta = Math.abs(differenceInHours(element.data.timestamp, this.fromElement.data.timestamp));
    if (toDelta === 0 || fromDelta === 0) return;
    if (toDelta >= fromDelta) {
      this._onRangeSelected(element, this.toElement, index);
    } else this._onRangeSelected(this.fromElement, element, index);

  }

}