export default {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 3]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        mainAxis: true
      }
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom-start', 'top-start']
      }
    }
  ]
};