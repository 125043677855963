<template>
  <cx-simple-popup
    class="cx-message-popup"
    :isOpened="isOpened"
    :width="690"
    :height="250"
    :offsetRatio="{ x: 0.5, y: 0.4 }"
    :popupId="popupId"
    :handleClosePopup="promptPopupClosing">
    <template #title>
      <cx-message-popup-title
        :unit="selectedUnit"
        :status="messageStatus"
        :showWarning="showWarningScreen"/>
    </template>
    <div class="cx-message-popup-body">
      <cx-message-form
        :canType="isUnsent"
        :message="message"
        :showWarning="showWarningScreen"
        @validation="validateMessage"
        @input="setMessage"/>
    </div>
    <cx-message-popup-actions
      :canSend="canSend"
      :awaiting="isAwaiting"
      :acknowledged="isAcknowledged"
      :failed="isFailed"
      :showWarning="showWarningScreen"
      @send="sendMessage"
      @cancel="cancelPopupClosing"
      @close="promptPopupClosing"/>
  </cx-simple-popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { AwarenessMessageStatus } from '@/domain/awareness/enums/AwarenessMessageStatus.enum';
import CxSimplePopup from '@/components/CxSimplePopup';
import CxMessagePopupTitle from './CxMessagePopupTitle';
import CxMessageForm from './CxMessageForm';
import CxMessagePopupActions from './CxMessagePopupActions';
import { AwarenessMessageErrorStatuses } from '@/domain/awareness/enums/AwarenessMessageErrorStatuses.enum';

export default {
  name: 'CxMessagePopup',
  components: {
    CxSimplePopup,
    CxMessagePopupTitle,
    CxMessageForm,
    CxMessagePopupActions
  },
  props: {
    popupId: { type: String, default: 'popup-awareness-message' },
    isOpened: { type: Boolean }
  },
  data() {
    return {
      isInputValid: true,
      isPopupClosing: false
    };
  },
  computed: {
    ...mapGetters('detailsUnit', [
      'selectedUnit',
      'isMessagePopupOpened',
      'message',
      'messageStatus'
    ]),
    isUnsent() { return this.messageStatus === AwarenessMessageStatus.Unsent; },
    isSending() { return this.messageStatus === AwarenessMessageStatus.Sending; },
    isSent() { return this.messageStatus === AwarenessMessageStatus.Sent; },
    isReceived() { return this.messageStatus === AwarenessMessageStatus.Received; },
    isAcknowledged() { return this.messageStatus === AwarenessMessageStatus.Acknowledged; },
    isAwaiting() { return this.isSending || this.isSent || this.isReceived; },
    isFailed() { return Object.values(AwarenessMessageErrorStatuses).includes(this.messageStatus); },
    showWarningScreen() { return this.isAwaiting && this.isPopupClosing; },
    canSend() {
      const hasInput = !!this.message.length;
      const isInputValid = !!this.isInputValid;
      return hasInput && isInputValid;
    }
  },
  methods: {
    ...mapMutations('detailsUnit', ['setMessage']),
    ...mapActions('popup', ['closePopup']),
    ...mapActions('detailsUnit', [
      'sendMessage',
      'confirmMessageReception',
      'confirmMessageAcknowledgement',
      'finalizeMessagePopup'
    ]),
    validateMessage(isInputValid) {
      this.isInputValid = isInputValid;
    },
    cancelPopupClosing() {
      this.isPopupClosing = false;
    },
    promptPopupClosing() {
      if (!this.isAwaiting) this.closePopup(this.popupId);
      if (this.isPopupClosing) {
        this.isPopupClosing = false;
        this.closePopup(this.popupId);
      } else {
        this.isPopupClosing = true;
      }
    }
  },
  unmounted() {
    this.finalizeMessagePopup();
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-message-popup {
  .cx-message-popup-body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @inputPadding 0;
  }

  .cx-message-popup-actions {
    width: 100%;
    display: flex;
    justify-content: right;
  }
}
</style>