import axios from 'axios';
import * as Cesium from 'cesium';

export const getSurfacelogBbox = async (wms) => {
  const capabilities = `${wms}/?service=wms&version=1.1.1&request=GetCapabilities`;
  const capabilitiesXML = await axios.get(capabilities);
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(capabilitiesXML.data, 'text/xml');
  const bbox = xmlDoc.getElementsByTagName('LatLonBoundingBox')[1];
  const boundingRectangle = {
    north: parseFloat(bbox.getAttribute('maxy')),
    south: parseFloat(bbox.getAttribute('miny')),
    east: parseFloat(bbox.getAttribute('maxx')),
    west: parseFloat(bbox.getAttribute('minx')),
  };
  const cesiumRectangle = Cesium.Rectangle.fromDegrees(boundingRectangle.west, boundingRectangle.south, boundingRectangle.east, boundingRectangle.north);
  return cesiumRectangle;
};

export const getWmsLayerList = async (wms) => {
  const capabilities = `${wms}/?service=wms&version=1.1.1&request=GetCapabilities`;
  const capabilitiesXML = await axios.get(capabilities);
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(capabilitiesXML.data, 'text/xml');
  const layers = xmlDoc.getElementsByTagName('Name');
  const layersList = [];
  layers.forEach(e => { layersList.push(e.innerHTML); });
  return layersList;
};

export const wmsApi = { getSurfacelogBbox, getWmsLayerList };
