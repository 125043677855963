import Vue from 'vue';
import FilterUnitViewModel from './filterUnitViewModel';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const filterUnitsModule = {
  state: {
    units: [],
    isQueryOngoing: false,
  },
  getters: {
    units(state) {
      return state.units;
    },
    isQueryOngoing(state) {
      return state.isQueryOngoing;
    }
  },
  mutations: {
    setUnits(state, units) {
      state.units = units;
    },
    setIsQueryOngoing(state, isQueryOngoing) {
      state.isQueryOngoing = isQueryOngoing;
    }
  },
  actions: {
    /**
     * Initializes unit collection and sets
     * @property {Boolean} isResultSelected
     * to be reactive in terms of Vue reactivity system.
     * @param {*} context
     * @param {*} availableUnits collection the view model is made of.
     */
    async initializeUnitFilters(context, availableUnits) {
      const language = gettext.current;
      const units = availableUnits.map(u => new FilterUnitViewModel(u, language));
      context.commit('setUnits', units);
    },
    /**
     * Selects given results.
     * @param {*} context
     * @param {Array<String>} selectedResultIds represent results to be selected.
     */
    async changeSelectedFilterResults(context, selectedResultIds) {
      context.getters.units.forEach((i) => { i.isResultSelected = i.isResultSelectable && selectedResultIds.includes(i.id); });
    },
    /**
     * Clears the filters by showing deselecting all the results.
     * @param {*} context
     */
    async clearFilters(context) {
      context.dispatch('changeSelectedFilterResults', []);
    },
    /**
     * Applies selected units to be loaded on the map
     * @constant {Array} selectedUnits list of unit uuids selected from the filtering panel
     * @param {*} context
     */
    async applyUnits(context) {
      try {
        context.commit('setIsQueryOngoing', true);
        const selectedUnits = context.getters.units
          .filter(i => i.isResultSelected)
          .map(i => i.data);
        await context.dispatch('map/addEntities', { entities: selectedUnits }, { root: true });
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Selected units cannot be rendered.') }, { root: true });
      } finally {
        context.commit('setIsQueryOngoing', false);
        context.dispatch('clearFilters');
      }
    }
  }
};

export default filterUnitsModule;
