<template>
  <div class="cx-drill-analysis-panel-point">
    <div class="cx-drill-analysis-panel-point__items">
      <cx-vertical-property
        class="cx-vertical-property--margin"
        :fieldName="point.drillPileStatus.displayValue"
        :value="point.drillPileDepth.value"
        :unit="point.drillPileDepth.unit"/>
      <cx-vertical-property :fieldName="translations.time" :value="point.logTime"/>
      <cx-horizontal-property-panel :items="items"/>
      <cx-horizontal-property-panel :items="items2"/>
    </div>
  </div>
</template>

<script>
import CxVerticalProperty from '@/components/CxVerticalProperty';
import CxHorizontalPropertyPanel from '@/components/CxHorizontalPropertyPanel';
import { PointViewModel } from '../pointViewModel';

export default {
  name: 'CxHoleAnalysisPanelPoint',
  components: {
    CxVerticalProperty,
    CxHorizontalPropertyPanel
  },
  props: {
    // point to display
    item: Object
  },
  computed: {
    point() {
      if (!this.item) return null;
      return new PointViewModel(this.item);
    },
    translations() {
      return {
        time: this.$gettext('Timestamp')
      };
    },
    items() {
      return [this.point.northing, this.point.easting, this.point.height];
    },
    items2() {
      return [this.point.latitude, this.point.longitude, this.point.ellipsoidHeight];
    }
  },

};

</script>

<style lang="less">
@import '../../../../common';

.cx-drill-analysis-panel-point {
  .flex(row);

  .cx-drill-analysis-panel-point__items {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 90px 1fr 1fr 1fr;

    .cx-vertical-property--margin {
      margin-right: 40px;
      margin-bottom: 20px;
    }
  }
}
</style>