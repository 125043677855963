import licenceService from '@/services/licence.service';
import unitMapper from '@/http/unit.mapper';
import panelTypes from '@/domain/panelTypes';
import { sanitizeStringValue } from '@/utils/stringOperations';
import { createDefaultPoint, createStabilizationPoint, createEarthmovingPoint } from '@/domain/point/PointFactory';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { createPointSet } from '@/domain/pointSet/PointSetFactory';
import { v4 as uuidv4 } from 'uuid';
import { ICONSolutionTypes } from '@/domain/ICONSolutionTypes.enum';
import { EarthmovingPoint } from '@/domain/point/EarthmovingPoint';
import { StabilizationPoint } from '@/domain/point/StabilizationPoint';
import { Point } from '@/domain/point/Point';
import { FilterModel } from '@/domain/filters/FilterModel';
import { OriginTimeRange } from '@/domain/OriginTimeRange';
import { ApiPoint } from '@/http/types/ApiPoint';
import { PointSet } from '@/domain/pointSet/PointSet';

interface PointsData {
  count?: number,
  from_time?: string,
  measuredPoints: ApiPoint[],
  to_time?: string,
  total_count: number
}

//* *
// * It will return the first field that is number or if none is -
// * a first non-falsy field
// * @param {*} field1
// * @param {*} field2
// */
const mapConditionally = (field1: any, field2: any) => {
  if (Number.isFinite(field1)) return field1;
  if (Number.isFinite(field2)) return field2;
  return field1 || field2;
};

const mapPointName = (point: ApiPoint): string => {
  if (point.equipment_type === panelTypes.mc1) return sanitizeStringValue(mapConditionally(point.log_name, point.LogName));
  if (point.equipment_type === panelTypes.icon3d) return sanitizeStringValue(point.point_name);
  if (point.equipment_type === panelTypes.iconSite) return sanitizeStringValue(point.pointID);
  if (point.equipment_type === panelTypes.geoPad) return sanitizeStringValue(point.pointID);
  if (point.equipment_type === panelTypes.captivate) {
    if (point.pointID) return sanitizeStringValue(`${sanitizeStringValue(point.pointID)} ${mapConditionally(point.PointCode, point.point_code)}`);
    return sanitizeStringValue(`${mapConditionally(point.PointCode, point.point_code)}`);
  }
  return '';
};

const mapPointUnitTypeByApplication = (application: ICONSolutionTypes): UnitTypes => {
  if (application === ICONSolutionTypes.ApplicationSurveyType) return UnitTypes.FieldCrewType;
  if (application === ICONSolutionTypes.ApplicationExcavatorType) return UnitTypes.ExcavatorType;
  return UnitTypes.FieldCrewType;
};

const mapPointUnitType = (apiPoint: ApiPoint): UnitTypes => {
  if (apiPoint.equipment_type === ICONSolutionTypes.EquipmentType) return mapPointUnitTypeByApplication(apiPoint.application);
  return unitMapper.mapUnitTypeToEntityType(apiPoint.unit_type || apiPoint.UnitType);
};

const mapPoint = (apiPoint: ApiPoint): EarthmovingPoint | StabilizationPoint | Point => {
  const uuid = apiPoint._id;
  const pointName = mapPointName(apiPoint);
  const logTime = apiPoint.log_time;
  const lastUpdatedAt = apiPoint.last_updated_at;
  const { latitude, longitude } = apiPoint;
  const ellipsoidHeight = mapConditionally(apiPoint.ellipsoid_height, apiPoint.EllipsoidHeight);
  const northing = apiPoint.Northing;
  const easting = apiPoint.Easting;
  const height = mapConditionally(apiPoint.height, apiPoint.Height);
  const referenceModelName = mapConditionally(apiPoint.reference_model, apiPoint.ReferenceModel);
  const unitName = mapConditionally(apiPoint.unit_account_name, apiPoint.Unit);
  const gnsscq3d = apiPoint.GNSSCQ3D;
  const coordinateSystem = apiPoint.coordinate_system;
  const equipmentType = apiPoint.equipment_type;
  const unitType = mapPointUnitType(apiPoint);
  const isAutoLogged = apiPoint.APLNoSync === 1;
  const isDiscarded = apiPoint.is_discarded;
  const jobName = apiPoint.job_name;
  let mappedPoint: EarthmovingPoint | StabilizationPoint | Point | null = null;
  if (unitType === UnitTypes.DrillerType || unitType === UnitTypes.PilerType) {
    mappedPoint = createStabilizationPoint({
      uuid,
      pointName,
      logTime,
      lastUpdatedAt,
      latitude,
      longitude,
      ellipsoidHeight,
      northing,
      easting,
      height,
      referenceModelName,
      unitType,
      unitName,
      gnsscq3d,
      coordinateSystem,
      equipmentType,
      isAutoLogged,
      isDiscarded,
      drillPileStatus: apiPoint.DrillPileStatus,
      heightOffsetType: apiPoint.HeightOffsetType,
      heightOffset: apiPoint.HeightOffset,
      sideDistance: apiPoint.SideDistance,
      targetHoleAngle: apiPoint.TargetHoleAngle,
      targetHoleHeading: apiPoint.TargetHoleHeading,
      station: apiPoint.Station,
      actualToolAngle: apiPoint.ActualToolAngle,
      actualToolHeading: apiPoint.ActualToolHeading,
      toolDiameter: apiPoint.ToolDiameter,
      drillPileDepth: apiPoint.DrillPileDepth,
      pileHeight: apiPoint.PileHeight,
      drillPileDepthSource: apiPoint.DrillPileDepthSource,
      panelSerialNumber: apiPoint.PanelSerialNumber,
      pointCode: mapConditionally(apiPoint.PointCode, apiPoint.point_code),
      sequenceNumber: apiPoint.sequence_number,
      comment: apiPoint.comment
    });
  } else if (licenceService.hasEarthmovingLicence()) {
    mappedPoint = createEarthmovingPoint({
      uuid,
      pointName,
      logTime,
      lastUpdatedAt,
      latitude,
      longitude,
      ellipsoidHeight,
      northing,
      easting,
      height,
      referenceModelName,
      unitName,
      unitType,
      gnsscq3d,
      coordinateSystem,
      equipmentType,
      isAutoLogged,
      isDiscarded,
      deltaHeight: apiPoint.HeightDistance,
      jobName,
      pointCode: mapConditionally(apiPoint.PointCode, apiPoint.point_code),
      comment: apiPoint.comment
    });
  } else {
    mappedPoint = createDefaultPoint({
      uuid,
      pointName,
      logTime,
      lastUpdatedAt,
      latitude,
      longitude,
      ellipsoidHeight,
      northing,
      easting,
      height,
      referenceModelName,
      unitName,
      unitType,
      gnsscq3d,
      coordinateSystem,
      equipmentType,
      isAutoLogged,
      isDiscarded,
      jobName,
      pointCode: mapConditionally(apiPoint.PointCode, apiPoint.point_code),
      comment: apiPoint.comment
    });
  }
  return mappedPoint;
};

const mapPoints = (pointsData: PointsData, originFilters: FilterModel[] | null, originTimeRange: OriginTimeRange | null): PointSet => {
  const { measuredPoints, total_count } = pointsData;
  return createPointSet({
    uuid: uuidv4(),
    name: null,
    points: measuredPoints.map(p => mapPoint(p)),
    totalCount: total_count || measuredPoints.length, // Defaulting to point set length if total_count unavailable
    originFilters,
    originTimeRange
  });
};

const asbuiltMapper = {
  mapPoints,
  mapPointName,
  mapPointUnitTypeByApplication,
  mapPointUnitType
};
export default asbuiltMapper;
