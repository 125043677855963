<template>
  <div class="cx-point-in-time-metrics">
    <cx-metrics-panel>
      <template #title>
        {{ title }}
      </template>
      <template #subtitle>
        {{ subtitle.from }} <i class="material-icons cx-value-arrow"> arrow_forward </i> {{ subtitle.to }}
      </template>
      <template #content>
        <div class="cx-point-in-time-metrics__delta">
          <!-- CUT DELTA -->
          <cx-delta-panel
            v-if="cutDelta"
            :previusValue="cutDelta.previousValue"
            :currentValue="cutDelta.currentValue"
            :deltaValue="cutDelta.deltaValue"
            :trend="cutDelta.trend"
            :unit="cutDelta.unit"
            :totalValue="cutDelta.totalValue"
            :deltaLabel="translations.cut"
            :deltaColor="colorSchema.cut"/>

          <!-- FILL DELTA -->
          <cx-delta-panel
            v-if="fillDelta"
            class="cx-point-in-time-metrics__delta--fill"
            :previusValue="fillDelta.previousValue"
            :currentValue="fillDelta.currentValue"
            :deltaValue="fillDelta.deltaValue"
            :trend="fillDelta.trend"
            :unit="fillDelta.unit"
            :totalValue="fillDelta.totalValue"
            :deltaLabel="translations.fill"
            :deltaColor="colorSchema.fill"/>
        </div>
      </template>

      <!-- FOOTER -->
      <div v-if="totals">
        <slot name="footer">
          <cx-total :left="totals.totalValues">
            <template #unit_left><cx-measure-unit/></template>
            <span v-translate>Progress/Total</span>
            <template #right_upper>
              <cx-delta
                :values="totals.deltaValues"
                :trend="totals.trend"
                :style="{color: 'white'}"
                unit="m">
              </cx-delta>
            </template>
            <template #right_lower><span>right_lower">{{ totals.percentage }}</span></template>
          </cx-total>
        </slot>
      </div>
    </cx-metrics-panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CxDelta from './components/CxDelta';
import CxTotal from './components/CxTotal';
import CxMeasureUnit from './components/CxMeasureUnit';
import CxMetricsPanel from './CxMetricsPanel';
import CxDeltaPanel from './CxDeltaPanel';
import datetimehelper from '@/utils/datetimehelper';


const calculateDeltaTend = (delta) => {
  if (delta > 0) return 'downwards';
  if (delta < 0) return 'upwards';
  return 'const';
};

export default {
  name: 'CxPointInTimeMetrics',
  components: {
    CxMetricsPanel,
    CxDelta,
    CxTotal,
    CxDeltaPanel,
    CxMeasureUnit
  },
  computed: {
    ...mapGetters('detailsRefmodel', [
      'selectedVolumesDelta'
    ]),
    translations() {
      return {
        cut: this.$gettext('Cut / Total Cut'),
        fill: this.$gettext('Fill / Total Fill')
      };
    },
    title() {
      return this.$gettext('Cut & Fill Progress');
    },
    subtitle() {
      if (!this.selectedVolumesDelta) { return ''; }
      const from = datetimehelper.format(this.selectedVolumesDelta.fromSelectedVolume.timestamp, datetimehelper.LOCALE_DATE_TIME_FORMAT);
      const to = datetimehelper.format(this.selectedVolumesDelta.toSelectedVolume.timestamp, datetimehelper.LOCALE_DATE_TIME_FORMAT);
      return {
        from,
        to
      };
    },
    cutDelta() {
      if (!this.selectedVolumesDelta) { return null; }

      const {
        cutFrom,
        cutTo,
        cutDelta,
        cutTarget
      } = this.selectedVolumesDelta;

      return {
        totalValue: cutTarget,
        deltaValue: cutDelta,
        currentValue: cutTo,
        previousValue: cutFrom,
        trend: calculateDeltaTend(cutDelta),
        unit: 'm'
      };
    },
    fillDelta() {

      if (!this.selectedVolumesDelta) { return null; }

      const {
        fillFrom,
        fillTo,
        fillDelta,
        fillTarget
      } = this.selectedVolumesDelta;
      return {
        totalValue: fillTarget,
        deltaValue: fillDelta,
        currentValue: fillTo,
        previousValue: fillFrom,
        trend: calculateDeltaTend(fillDelta),
        unit: 'm'
      };
    },
    totals() {
      if (!this.selectedVolumesDelta) { return null; }
      const {
        totalTarget,
        totalDelta,
        fillTo,
        fillFrom,
        cutTo,
        cutFrom,
        completionPercent
      } = this.selectedVolumesDelta;
      return {
        deltaValues: [(cutFrom + fillFrom), (cutTo + fillTo)],
        totalValues: [totalDelta, totalTarget],
        percentage: completionPercent,
        trend: calculateDeltaTend((cutFrom + fillFrom) - (cutTo + fillTo)),
      };
    },
    colorSchema() {
      return {
        cut: '#f56262b3',
        fill: '#2d9ccfb3',
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-point-in-time-metrics {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
}

.cx-point-in-time-metrics {
 .cx-value-arrow {
    font-size: @microFontSize;
  }
 }
</style>