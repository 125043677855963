import translationService from '@/services/translation.service';

/**
 * Model keeping available dynamic filters
 */
export const metadataFilterOptionFactory = {
  /**
   * Returns new filter option
   * @param {String} fieldName name of the field
   * @param {String} dataType text or number
   */
  create(fieldName, dataType) {
    const displayName = translationService.get(fieldName);
    return {
      fieldName,
      dataType,
      displayName
    };
  }
};
