import { parseISO } from 'date-fns';
import translationService from '@/services/translation.service';
import { numberFormatter, numberComparator } from '@/components/agGrid/agGridFormatters';
import datetimehelper from '@/utils/datetimehelper';

const dateFormatter = (params) => {
  return datetimehelper.format(parseISO(params.value), datetimehelper.LOCALE_DATE_FORMAT);
};

const translateFormatter = (params) => {
  return translationService.get(params.value);
};

/**
 * Defines all fields for grid
 * compatible with export?
 */
const createColumnDefinitions = () => {
  const fieldDefinition = [
    { field: 'pointCode' },
    { field: 'easting', valueFormatter: numberFormatter(3), comparator: numberComparator },
    { field: 'northing', valueFormatter: numberFormatter(3), comparator: numberComparator },
    { field: 'height', valueFormatter: numberFormatter(3), comparator: numberComparator },
    { field: 'longitude', valueFormatter: numberFormatter(6), comparator: numberComparator },
    { field: 'latitude', valueFormatter: numberFormatter(6), comparator: numberComparator },
    { field: 'ellipsoidHeight', valueFormatter: numberFormatter(3), comparator: numberComparator },
    { field: 'gnsscq3d' },
    { field: 'logTime', valueFormatter: dateFormatter },
    { field: 'panelSerialNumber' },
    { field: 'referenceModelName' },
    { field: 'heightOffsetType', valueFormatter: translateFormatter },
    { field: 'deltaHeight', valueFormatter: numberFormatter(2), comparator: numberComparator },
    { field: 'sideDistance' },
    { field: 'station' },
    { field: 'drillPileStatus' },
    { field: 'targetHoleAngle' },
    { field: 'actualToolAngle' },
    { field: 'targetHoleHeading' },
    { field: 'actualToolHeading' },
    { field: 'toolDiameter' },
    { field: 'drillPileDepth' },
    { field: 'drillPileDepthSource', valueFormatter: translateFormatter },
    { field: 'unitName' },
    { field: 'unitType', valueFormatter: translateFormatter },
    { field: 'sequenceNumber' },
    { field: 'equipmentType' },

  ];
  return fieldDefinition;
};

export { createColumnDefinitions };
