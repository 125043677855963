<template>
  <div class="cx-export-asbuild-component">
    <cx-menu-button
      :items="items"
      @click="exportPoints"
      :disabled="disabled"
      icon="cx_download"
      :displayDottIcon="false"
      :flat="true"
      :width="200"
      menuHorizontalPosition="left"
      :loading="loading"/>
  </div>
</template>

<script>
import CxMenuButton from '@/components/menuComponents/CxMenuButton';

export default {
  name: 'CxExportAsbuildComponent',
  components: {
    CxMenuButton
  },
  props: {
    disabled: Boolean,
    exportPoints: Function,
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items() {
      return [
        { name: this.$gettext('download data as csv file'), value: 'csv' },
        { name: this.$gettext('download data as json file'), value: 'json' },
        { name: this.$gettext('download data as geo file'), value: 'geo' },
      ];
    },
  }
};

</script>

<style lang="less">

.cx-export-asbuild-component {

 .cx-menu-button {
    margin-right: 20px;
    margin-top: 20px;
  }

}

</style>