<template>
  <div class="cx-title-panel">
    <div
      class="cx-title-panel__title-content__title"
      v-cx-tooltip="{
        text: title,
        show: showTooltip,
        position: 'top-left',
        maxWidth: 400,
      }">
      <cx-icon :icon="titleIcon" :size="13" :class="statusClass" v-if="titleIcon"/>
      <span
        @mouseenter="showTooltip=true" @mouseleave="showTooltip=false"
        class="cx-title-panel__title-content__title__text"
        @click="onTitleClicked" :style="titleCursorStyle">
        {{ title }}
      </span>
      <slot @mouseenter="showTooltip=true" @mouseleave="showTooltip=false" name="title-slot"></slot>
      <cx-spinner v-if="isLoading" :size="20" :width="2" class="cx-title-panel__title-content__title__proggress"/>
    </div>
    <div
      class="cx-title-panel__subtitle" :class="{'noStatus': !statusClass }"
      @mouseenter="showSubtitleTooltip=true" @mouseleave="showSubtitleTooltip=false"
      v-cx-tooltip="{
        text: subtitleIconTooltip,
        show: showSubtitleTooltip,
      }">
      <cx-icon
        v-if="subtitleIcon"
        class="cxu-color-faded"
        :icon="subtitleIcon"
        :size="16"/>
      <span>{{ subtitle }}</span>
      <slot name="subtitle-slot"></slot>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import CxSpinner from '@/components/CxSpinner.vue';

export default {
  name: 'CxTitlePanel',
  components: {
    CxIcon,
    CxSpinner
  },
  data() {
    return {
      showTooltip: false,
      showSubtitleTooltip: false
    };
  },
  props: {
    title: [String, Number],
    titleClickHandler: {
      type: Function,
      default: null
    },
    subtitle: String,
    subtitleIcon: String,
    subtitleIconTooltip: String,
    statusClass: {
      type: String,
      default: null,
    },
    tooltipText: {
      type: String,
      default: null
    },
    titleIcon: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleCursorStyle() {
      const result = { cursor: 'default' };
      if (this.titleClickHandler) result.cursor = 'pointer';
      return result;
    }
  },
  methods: {
    onTitleClicked(...args) {
      if (this.titleClickHandler) this.titleClickHandler(args);
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-title-panel {
  font-size: @h2FontSize;
  font-weight: @lightFontWeight;
  color: @textColorHighlight;

  .cx-title-panel__title-content__title {
    word-break: break-all;
    .cut-overflow();
  }

  .cx-title-panel__title-content__title__proggress {
    margin-left: 10px;
  }

  span {
    padding-left: 8px;
    vertical-align: middle;
  }

  .cx-icon {
    display: inline-block;

    &.on-grade {
      i { color: @onGradeColor; }
    }

    &.cut {
      i { color: @cutColor; }
    }

    &.fill {
      i { color: @fillColor; }
    }

    &.fill {
      i { color: @fillColor; }
    }

    &.drillpile-green {
      i { color: @drillpileGreen; }
    }

    &.drillpile-yellow {
      i { color: @drillpileYellow; }
    }

    &.drillpile-red {
      i { color: @drillpileRed; }
    }
  }

  .cx-title-panel__subtitle {
    margin-left: 10px;
    font-size: @h3FontSize;
    font-weight: @appFontWeight;
    .flex(row, flex-start, flex-start);
    color: @textColor;

    span {
      padding-top: 2px;
      font-size: @appFontSize;
      white-space: normal;
      display: inline;
      max-width: @detailsSubTitleCaptionWidth;
      // vertical-align: text-top;
    }

    .cx-title-panel__subtitle__icon {
      align-self: baseline;
    }

    &.noStatus {
      margin-left: 10px;
    }
  }
}


</style>