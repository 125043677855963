<template>
  <div class="cx-map-base-layer-picker">
    <h3 class="cx-map-base-layer-picker__title">{{ translations.imagery }}</h3>
    <div class="cx-map-base-layer-picker__layers">
      <div
        class="cx-map-base-layer-picker__layers__item"
        v-for="(layer, key) in available" :key="key"
        @click="onSelection(layer)">
        <img class="cx-map-base-layer-picker__layers__item__icon" :class="isSelected(layer)" :src="layer.iconUrl"/>
        <span class="cx-map-base-layer-picker__layers__item__subtitle" :class="isSelected(layer)">
          {{ layer.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxMapBaseLayerPicker',
  props: {
    available: Array,
    selected: Object,
    onSelection: Function
  },
  computed: {
    translations() {
      return {
        imagery: this.$gettext('Imagery')
      };
    }
  },
  methods: {
    isSelected(layer) {
      return this.selected === layer ? 'active' : '';
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-base-layer-picker {
  .size(@mapBaseLayerPickerWidth, auto);
  .flex(column, flex-start, flex-start);
  position: absolute;
  top: 37px;
  right: -5px;
  cursor: default;
  background-color: @overlayControlBackgroundColorInactive;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;
  padding: 20px;

  .cx-map-base-layer-picker__title {
    color: @textColorDisabled;
    font-size: @h3FontSize;
    font-weight: @headerFontWeight;
  }

  .cx-map-base-layer-picker__layers {
    .size(100%, auto);
    .flex(row, space-between, flex-start);
    flex-wrap: wrap;
    margin-top: 20px;

    .cx-map-base-layer-picker__layers__item {
      .size(@mapBaseLayerItemWith, auto);
      .flex(column, flex-start, center);
      max-width: @mapBaseLayerItemWith;

      .cx-map-base-layer-picker__layers__item__icon {
        border-radius: @inputBorderRadius;
        cursor: pointer;
        opacity: 0.7;

        &.active {
          opacity: 1;
        }
      }

      .cx-map-base-layer-picker__layers__item__subtitle {
        text-align: center;
        word-break: break-word;
        margin: @inputSpace 0 @inputSpace 0;
        color: @textColorLowlight;

        &.active {
          color: @textColorHighlight;
        }
      }

      &:hover {
        .cx-map-base-layer-picker__layers__item__icon {
          &:not(.active) {
            opacity: 0.9;
          }
        }

        .cx-map-base-layer-picker__layers__item__subtitle {
          &:not(.active) {
            color: @textColor;
          }
        }
      }
    }
  }
}
</style>