<template>
  <div class="cx-metadata-range-filter">
    <div class="cx-metadata-range-filter__container" id="metadata-range-filter">
      <cx-input
        :precision="8"
        :modelValue="filter.values[0]"
        :label="fromLabel"
        :type="filter.dataType"
        :validationRules="fromValueRules"
        class="filter-multi-value"
        ref="valueFrom"
        @update:modelValue="handleFromValueChanged">
      </cx-input>
      <cx-input
        :precision="8"
        :modelValue="filter.values[1]"
        :label="toLabel"
        :type="filter.dataType"
        :validationRules="toValueRules"
        class="filter-multi-value-end"
        ref="valueTo"
        @update:modelValue="handleToValueChanged">
      </cx-input>
    </div>
    <cx-button flat icon="close" @click="removeItem"/>
  </div>
</template>

<script>
import CxInput from '@/components/CxInput';
import CxButton from '@/components/CxButton';
import _ from 'lodash';
import validatonRules from '@/components/validationRules';
import { helpers, maxLength } from '@vuelidate/validators';

const { required, cxFloat } = validatonRules();

export default {
  name: 'CxMetadataRangeFilter',
  components: {
    CxInput,
    CxButton
  },
  props: {
    // DynamicFilterRangeVM object
    filter: Object,
    // function to be called to remove the filter
    onRemoveFilter: Function,
    // index of applied filter element
    index: Number,
    // called when filter value changed { filter, values<Array> }
    onValueChange: Function,
  },
  mounted() {
    // focus on the value field when mounted
    const el = this.$refs.valueFrom;
    const inp = el.$el.querySelector('input');
    if (inp) {
      this.$nextTick(() => inp.focus());
    }
  },
  computed: {
    valueFrom() {
      return this.filter.values[0];
    },
    valueTo() {
      return this.filter.values[1];
    },
    fromLabel() {
      const translated = this.$gettext('%{metadataName} from');
      const interpolated = this.$gettextInterpolate(translated, { metadataName: this.filter.displayName });
      return interpolated;
    },
    toLabel() {
      const translated = this.$gettext('%{metadataName} to');
      const interpolated = this.$gettextInterpolate(translated, { metadataName: this.filter.displayName });
      return interpolated;
    },
    fromValueRules() {
      return {
        modelValue: {
          $autoDirty: true,
          required,
          maxLength: helpers.withMessage(this.$gettext('Max 10 characters'), maxLength(10)),
          cxFloat,
          fromGreater: helpers.withMessage(this.$gettext('Please provide a valid range'), (value) => {
            const toValue = this.filter.values[1];
            if (_.isNil(toValue) || _.isNil(value)) return true;
            const fromValueNumber = parseFloat(value);
            const toValueNumber = parseFloat(toValue);
            if (Number.isNaN(fromValueNumber) || Number.isNaN(toValueNumber)) return false;
            if (fromValueNumber > toValueNumber) {
              return false;
            }
            return true;
          })
        }
      };
    },
    toValueRules() {
      return {
        modelValue: {
          $autoDirty: true,
          required,
          maxLength: helpers.withMessage(this.$gettext('Max 10 characters'), maxLength(10)),
          cxFloat,
          toLess: helpers.withMessage(this.$gettext('Please provide a valid range'), (value) => {
            const fromValue = this.filter.values[0];
            if (_.isNil(fromValue) || _.isNil(value)) return true;
            const fromValueNumber = parseFloat(fromValue);
            const toValueNumber = parseFloat(value);
            if (fromValueNumber > toValueNumber) {
              return false;
            }
            return true;
          })
        }
      };
    },
  },
  methods: {
    removeItem() {
      this.onRemoveFilter(this.filter);
    },
    handleFromValueChanged(val) {

      this.onValueChange({ filter: this.filter, values: [Number(val), Number(this.filter.values[1])] });
    },
    handleToValueChanged(val) {

      this.onValueChange({ filter: this.filter, values: [Number(this.filter.values[0]), Number(val)] });
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-metadata-range-filter {
  text-align: left;
  display: flex;
  margin-top: 10px;

  .cx-icon {
    i {
      font-size: @majorFontSize;
    }
  }

  .cx-metadata-range-filter__container {
    display: flex;
    flex: 1 1 auto;

    &--multi-value {
      .cx-select {
        width: 100%;
      }
    }
  }
}

.filter-multi-value {
  flex: 1 1 auto;
  margin-right: 5px;
}

.filter-multi-value-end {
  flex: 1 1 auto;
  margin-left: 5px;
}
</style>