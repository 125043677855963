import { createStore } from 'vuex';
import appStore from '@/store/appModule/app.store';
import filterUnitsModule from '@/store/filterModule/units/filterUnits.store';
import filterRefmodelModule from '@/store/filterModule/refmodels/filterRefmodel.store';
import filterPointModule from '@/store/filterModule/points/filterPoint.store';
import filterMeasuredPointModule from '@/store/filterModule/points/modules/filterMeasuredPoint.store';
import filterDrillHoleModule from '@/store/filterModule/points/modules/filterDrillHole.store';
import filterAwarenessEventModule from '@/store/filterModule/awarenessEvents/filterAwarenessEvent.store';
import detailsUnitModule from '@/store/detailsModule/unit/detailsUnit.store';
import detailsRefmodelModule from '@/store/detailsModule/refmodels/detailsRefmodel.store';
import detailsSurfacelogModule from '@/store/detailsModule/surfacelog/detailsSurfacelog.store.ts';
import detailsPointModule from '@/store/detailsModule/point/detailsPoint.store';
import detailsPointSetModule from '@/store/detailsModule/pointSet/detailsPointSet.store';
import detailsHoleSetModule from '@/store/detailsModule/holeSet/detailsHoleSet.store';
import detailsHoleModule from '@/store/detailsModule/hole/detailsHole.store';
import detailsDrillPatternModule from '@/store/detailsModule/drillPattern/detailsDrillPattern.store';
import detailsAwarenessEventSetModule from '@/store/detailsModule/awarenessEventSet/detailsAwarenessEventSet.store';
import detailsAwarenessEventModule from '@/store/detailsModule/awarenessEvent/detailsAwarenessEvent.store';
import mapStoreModule from '@/store/mapModule/map.store';
import viewerStoreModule from '@/store/mapModule/map.viewer.store';
import { shellStoreModule } from '@/store/shellModule/shell.store';
import notificationsModule from '@/store/notifications.store';
import { cancelableStoreModule, registerCancelableModule } from '@/store/cancelable.store';
import pointGridModule from '@/store/pointGridModule/pointGrid.store';
import popupStoreModule from '@/store/popup.store';
import alertStoreModule from '@/store/alert.store';
import { sosStoreModule } from './store/sosModule/sos.store';
import filterSurfacesModule from './store/filterModule/surfaces/filter.surfaces.store';

import mapStorePlugin from '@/store/mapModule/map.store.plugin';
import detailsRefmodelModulePlugin from '@/store/detailsModule/refmodels/detailsRefmodel.store.plugin';
import detailsPointSetModulePlugin from '@/store/detailsModule/pointSet/detailsPointSet.store.plugin';
import detailsPointModulePlugin from '@/store/detailsModule/point/detailsPoint.store.plugin';
import detailsHoleModulePlugin from '@/store/detailsModule/hole/detailsHole.store.plugin';
import trackingPlugin from '@/tracking/tracking.plugin';
import saveStatePlugin from '@/store/mapModule/save.state.plugin';

const storeConfig = {
  plugins: [
    mapStorePlugin,
    detailsRefmodelModulePlugin,
    detailsPointSetModulePlugin,
    detailsPointModulePlugin,
    detailsHoleModulePlugin,
    trackingPlugin,
    saveStatePlugin
  ],
  modules: {
    app: {
      namespaced: true,
      ...appStore,
    },
    filterUnits: {
      namespaced: true,
      ...filterUnitsModule
    },
    filterRefmodel: {
      namespaced: true,
      ...filterRefmodelModule
    },
    filterSurfaces: {
      namespaced: true,
      ...filterSurfacesModule
    },
    filterPoints: {
      namespaced: true,
      ...filterPointModule,
      modules: {
        filterMeasuredPoint: {
          namespaced: true,
          ...filterMeasuredPointModule
        },
        filterDrillHole: {
          namespaced: true,
          ...filterDrillHoleModule
        }
      }
    },
    filterAwarenessEvents: {
      namespaced: true,
      ...filterAwarenessEventModule
    },
    map: {
      namespaced: true,
      ...registerCancelableModule(mapStoreModule, 'map'),
      modules: {
        viewer: {
          namespaced: true,
          ...viewerStoreModule
        }
      }
    },
    detailsUnit: {
      namespaced: true,
      ...detailsUnitModule
    },
    detailsRefmodel: {
      namespaced: true,
      ...registerCancelableModule(detailsRefmodelModule, 'detailsRefmodel'),
    },
    detailsSurfacelog: {
      namespaced: true,
      ...detailsSurfacelogModule
    },
    detailsPoint: {
      namespaced: true,
      ...registerCancelableModule(detailsPointModule, 'detailsPoint'),
    },
    detailsPointSet: {
      namespaced: true,
      ...detailsPointSetModule
    },
    detailsHole: {
      namespaced: true,
      ...registerCancelableModule(detailsHoleModule, 'detailsHole'),
    },
    detailsAwarenessEventSet: {
      namespaced: true,
      ...detailsAwarenessEventSetModule
    },
    detailsAwarenessEvent: {
      namespaced: true,
      ...detailsAwarenessEventModule
    },
    notifications: {
      namespaced: true,
      ...notificationsModule
    },
    shell: {
      namespaced: true,
      ...shellStoreModule
    },
    detailsHoleSet: {
      namespaced: true,
      ...detailsHoleSetModule
    },
    detailsDrillPattern: {
      namespaced: true,
      ...detailsDrillPatternModule
    },
    cancelable: {
      namespaced: true,
      ...cancelableStoreModule
    },
    pointGrid: {
      namespaced: true,
      ...registerCancelableModule(pointGridModule, 'pointGrid'),
    },
    popup: {
      namespaced: true,
      ...popupStoreModule
    },
    alert: {
      namespaced: true,
      ...alertStoreModule
    },
    sos: {
      namespaced: true,
      ...sosStoreModule
    }
  },
  state: {
    parentContext: {
      project_uuid: null
    },
    selectedContent: 1,
    apiInitialized: false,
  },
  getters: {
    selectedContent(state) {
      return state.selectedContent;
    },
    projectUuid(state) {
      return state.parentContext.project_uuid;
    }
  },
  mutations: {
    setParentContext(state, parentContext) {
      state.parentContext = { ...parentContext };
    },
    setSelectedContent(state, contentId) {
      state.selectedContent = contentId;
    },
    setApiInitialized(state, { isInitialized }) {
      state.apiInitialized = isInitialized;
    }
  },
  actions: {
    displaySelectedContent(context, contentId) {
      context.commit('setSelectedContent', contentId);
    },
    async initializeApp(context, parentContext) {
      context.commit('setParentContext', parentContext);
      context.commit('setApiInitialized', true);
      await context.dispatch('app/fetchAccountData', { root: true });
      await context.dispatch('app/fetchProjectMetadata', { root: true });
      await context.dispatch('map/initialize3dBaseLayers', { root: true });
    }
  }
};

const storeFactory = (config = storeConfig) => createStore(config);

export { storeConfig, storeFactory };
