<template>
  <div class="cx-metadata-filters">
    <div class="cx-metadata-filters__wrapper">
      <div class="cx-metadata-filters__wrapper__items">
        <div
          class="cx-metadata-filters__wrapper__items__item"
          v-for="(filter, index) in addedMetadataFilters"
          :key="filter.uuid">
          <cx-metadata-single-filter
            v-if="filter.type === 'single'"
            :filter="filter"
            :index="index"
            :onRemoveFilter="removeMetadataFilter"
            :onValueChange="changeMetadataFilterValues"
            :onComparatorChange="changeMetadataFilterComparators"/>
          <cx-metadata-range-filter
            v-if="filter.type === 'range'"
            :filter="filter"
            :index="index"
            :onRemoveFilter="removeMetadataFilter"
            :onValueChange="changeMetadataFilterValues"/>
          <cx-metadata-chips-filter
            v-if="filter.type === 'chips'"
            :filter="filter"
            :index="index"
            :label="filter.displayName"
            :onRemoveFilter="removeMetadataFilter"
            :onValueChange="changeMetadataFilterValues"/>
        </div>
      </div>
    </div>
    <cx-metadata-filter-add
      class="cx-metadata-filters__add"
      :label="label"
      :addFilter="addMetadataFilter"
      :availableFilters="availableMetadataFilters"
      :usedFilters="addedMetadataFilters"/>
  </div>
</template>

<script>
import CxMetadataFilterAdd from './CxMetadataFilterAdd';
import CxMetadataSingleFilter from './CxMetadataSingleFilter';
import CxMetadataRangeFilter from './CxMetadataRangeFilter';
import CxMetadataChipsFilter from './CxMetadataChipsFilter';

export default {
  name: 'CxMetadataFilters',

  components: {
    CxMetadataFilterAdd,
    CxMetadataSingleFilter,
    CxMetadataRangeFilter,
    CxMetadataChipsFilter
  },
  props: {
    // filters that are available to be added
    availableMetadataFilters: Array,
    // filters that are already added
    addedMetadataFilters: Array,
    // should be called to add metadata filter
    addMetadataFilter: Function,
    // should be called to remove metadata filter
    removeMetadataFilter: Function,
    // should be called to change value of metadata filter
    changeMetadataFilterValues: Function,
    // should be called to change comparator of metadata filter
    changeMetadataFilterComparators: Function
  },
  computed: {
    label() {
      return this.$gettext('Select Metadata Filters');
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-metadata-filters {

  .cx-metadata-filters__wrapper {
    .cx-metadata-filters__wrapper__items {

      .cx-metadata-filters__wrapper__items__item {
        .size(100%, auto);
        min-height: @filterMPointEnumerativeHeight;

        .cx-dynamic-filter {
          margin-top: 10px;
        }

        .cx-enumerative-filter {
          .cx-options {
            max-height: 196px;
          }
        }
      }
    }
  }

  .cx-metadata-filters__add {
    margin-top: 10px;
  }
}
</style>