<template>
  <div
    class="cx-related-entities-badge"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    v-cx-tooltip="{
      ...tooltipOptions,
      position: 'top-left',
      maxWidth: 400,
    }">
    <cx-button icon="mdi-eye" flat square variantDark :disabled="true"></cx-button>
    <div class="cx-related-entities-badge__text"><div class="cx-related-entities-badge__text__content"><slot></slot></div></div>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import CxTooltipMixin from '@/components/CxTooltipMixin';

export default {
  name: 'CxRelatedEntitiesBadge',
  components: {
    CxButton
  },
  mixins: [CxTooltipMixin]
};
</script>

<style lang="less">
@import '../../../common';
@relatedEntitiesBaseColor: rgb(45, 50, 61);
@relatedEntitiesTextColor: rgb(55, 61, 72);

.cx-related-entities-badge {
  background-color: @relatedEntitiesBaseColor;
  color: @textColorHighlight;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;
  display: flex;
  flex-direction: row;

  .cx-button {
    margin-top: 0 !important;
    padding-right: 0;
    flex: 0;
    background-color: @relatedEntitiesBaseColor;
    color: fade(@textColorBase, 20%);
  }

  .cx-related-entities-badge__text {
    background-color: @relatedEntitiesTextColor;
    border-radius: 0 @inputBorderRadius @inputBorderRadius 0;
    overflow: hidden;
    flex: 1;

    .cx-related-entities-badge__text__content {
      padding: 9px 16px 7px 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

</style>