import _ from 'lodash';
import * as Cesium from 'cesium';

/**
 * State representation and dispatchable actions for drill patterns.
 */
const drillPatternStore = {
  state: {
    labels: {},
    /**
     * A collection of 2D viewport-aligned text labels positioned next to each drill point.
     */
    labelCollection(viewer) {
      if (!this._labels) {
        const { scene } = viewer;
        this._labels = scene.primitives.add(new Cesium.PrimitiveCollection({ scene }));
      }
      return this._labels;
    },
    /**
     * A collection of loaded drill patterns.
     */
    drillPatterns: {},
    drillPatternCollection(viewer) {
      if (!this._drillPatternCollection) {
        this._drillPatternCollection = viewer.scene.primitives.add(new Cesium.PrimitiveCollection());
      }
      return this._drillPatternCollection;
    },
  },
  actions: {
    /**
     * Load a collection of primitives representing the drill patern.
     * @param context
     * @param uuid {string}: The drill pattern uuid.
     * @param name {string}: The drill pattern name.
     * @param drillPatternData {Array}: An array of coordinates and names of drill pattern points.
     * @param show {Boolean}: The visibility of the drill pattern.
     */
    async loadDrillPattern(context, { uuid, name, drillPatternData, show }) {
      if (_.isEmpty(drillPatternData)) return;
      const { self } = context;
      const extendedDrillPatternData = await self.getTerrainPositions(drillPatternData);
      const drillPatternGeometries = self.createDrillPatternGeometries(uuid, extendedDrillPatternData);
      const drillPattern = await self.createDrillPatternPrimitive(drillPatternGeometries, show);
      const drillPatternCenter = await self.calculateDrillPatternCenter(extendedDrillPatternData);
      Object.assign(drillPattern, {
        id: {
          uuid,
          name,
          cxType: 'drill-pattern',
          position: drillPatternCenter,
          boundingSphere: Cesium.BoundingSphere.fromPoints(extendedDrillPatternData.map(drillPoint => drillPoint.clampedPosition))
        }
      });
      self.state.drillPatterns[uuid] = drillPattern;
    },
    async removeDrillPattern(context, { uuid }) {
      const { self } = context;
      const { state, rootState } = self;
      const { viewer } = rootState;
      const drillPattern = state.drillPatterns[uuid];
      state.drillPatternCollection(viewer).remove(drillPattern);
      delete state.drillPatterns[uuid];
    },
    async showDrillPatterns(context, { show, hideOther = false }) {
      const { self } = context;
      const { drillPatterns } = self.state;
      Object.entries(drillPatterns).forEach(([uuid, model]) => {
        model.show = show.includes(uuid) || (model.show && !hideOther);
      });
    },
    async hideDrillPatterns(context, { hide, showOther = false }) {
      const { self } = context;
      const { drillPatterns } = self.state;
      Object.entries(drillPatterns).forEach(([uuid, model]) => {
        model.show = !hide.includes(uuid) && (model.show || showOther);
      });
    },
    /**
     * Creates labels with log names over drill pattern.
     * Adds Cesium.LabelCollection to the viewer.
     * @param context
     */
    async showDrillPatternLabels(context, uuid) {
      const { self } = context;
      const { state, rootState } = self;
      const { drillPatterns } = state;
      const { viewer } = rootState;
      const { scene } = viewer;
      if (!drillPatterns[uuid]) return;
      const positions = drillPatterns[uuid].geometryInstances;
      if (!positions.length) return;
      const labelArray = positions.map(p => self.createDrillPointLabel(p.id.name, p.id.position));
      const labels = new Cesium.LabelCollection({ scene });
      labelArray.forEach(l => labels.add(l));
      state.labels[uuid] = state.labelCollection(viewer).add(labels);
    },
    /**
     * Removes labels with log names over drill pattern.
     * Removes Cesium.LabelCollection from the viewer.
     * @param context
     */
    async hideDrillPatternLabels(context, uuid) {
      const { state, rootState } = context.self;
      const { viewer } = rootState;
      state.labelCollection(viewer).remove(state.labels[uuid]);
    },
  },
  methods: {
    /**
     * Create Geometry Instances representing drill pattern points.
     * @param drillPatternId {string}: The drill pattern id.
     * @param drillPatternData {Array}: An array of coordinates and names of drill pattern points.
     * @returns {Array}: An array of geometries representing drill pattern points.
     */
    createDrillPatternGeometries(drillPatternId, drillPatternData) {
      const result = drillPatternData.map((drillPoint) => {
        const position = drillPoint.clampedPosition;
        return [
          new Cesium.GeometryInstance({
            id: { uuid: drillPatternId, position, name: drillPoint.name, cxType: 'drill-pattern' },
            geometry: new Cesium.CircleGeometry({
              id: { uuid: drillPatternId, position, name: drillPoint.name, cxType: 'drill-pattern' },
              center: position,
              radius: 0.20,
              height: drillPoint.clampedHeight + 0.01,
            }),
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.WHITE.withAlpha(0.7))
            }
          }),
          new Cesium.GeometryInstance({
            id: { uuid: drillPatternId, position, name: drillPoint.name, cxType: 'drill-pattern' },
            geometry: new Cesium.CircleGeometry({
              id: { uuid: drillPatternId, position, name: drillPoint.name, cxType: 'drill-pattern' },
              center: position,
              radius: 0.3,
              height: drillPoint.clampedHeight,
            }),
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.BLACK.withAlpha(0.7))
            }
          }),
        ];
      });
      return _.flatMap(result);
    },
    /**
     * Create a Primitive representig the drill pattern.
     * @param drillPatternGeometries {Array}: An array of geometries representing drill pattern points.
     * @param show {Boolean}: The visibility of the drill pattern.
     * @returns {Primitive}: The Primitive representig the drill pattern.
     */
    async createDrillPatternPrimitive(drillPatternGeometries, show) {
      const { state } = this;
      const { viewer } = this.rootState;
      const drillPatternPrimitive = new Cesium.Primitive({
        geometryInstances: drillPatternGeometries,
        show,
        interleave: true,
        releaseGeometryInstances: false,
        appearance: new Cesium.PerInstanceColorAppearance({
          flat: true,
          translucent: false,
          renderState: {
            lineWidth: Math.min(2.0, viewer.scene.maximumAliasedLineWidth)
          }
        })
      });
      const result = state.drillPatternCollection(viewer).add(drillPatternPrimitive);
      await drillPatternPrimitive.readyPromise;
      return result;
    },
    /**
     * Calculate the center of the drill pattern geometry bounding box.
     * @param drillPatternData {Array}: An array of coordinates and names of drill pattern points.
     * @returns {Cesium.Cartesian3}: The drill pattern position.
     */
    async calculateDrillPatternCenter(drillPatternData) {
      const { viewer } = this.rootState;
      const positions = drillPatternData.map(drillPoint => drillPoint.clampedPosition);
      const bound = Cesium.AxisAlignedBoundingBox.fromPoints(positions);
      const center = Cesium.Cartographic.fromCartesian(bound.center);
      const [clampedCenter] = await Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, [center]);
      return Cesium.Cartographic.toCartesian(clampedCenter);
    },
    /**
     * Gets the positions of all points in the drill pattern projected onto terrain.
     * @param drillPatternData {Array}: An array of coordinates and names of drill pattern points.
     * @returns {Promise<*>}: The drill pattern data with terrain heights added.
     */
    async getTerrainPositions(drillPatternData) {
      const { viewer } = this.rootState;
      const positions = drillPatternData.map(drillPoint => Cesium.Cartographic.fromDegrees(
        Number(drillPoint.longitude),
        Number(drillPoint.latitude),
        Number(drillPoint.elevation)
      ));
      const clampedPositions = await Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, positions);
      const positionZip = _.zip(drillPatternData, clampedPositions);
      return positionZip.map(([drillPoint, clampedPosition]) => Object.assign(drillPoint, {
        clampedPosition: Cesium.Cartographic.toCartesian(clampedPosition),
        clampedHeight: clampedPosition.height
      }));
    },
    /**
     * Create a labelling text bound to {DrillPattern} representation.
     * @param {String || Number} logName {DrillPattern}'s name.
     * @param {Cesium.Cartesian3} clampedPosition position of the target.
     * @returns {Cesium.Label}
     */
    createDrillPointLabel(logName, clampedPosition) {
      return {
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 250),
        eyeOffset: new Cesium.Cartesian3(0.0, 0.33, 0.0),
        fillColor: Cesium.Color.WHITE,
        font: '15px sans-serif',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        outlineWidth: 3,
        position: clampedPosition,
        scaleByDistance: new Cesium.NearFarScalar(0, 2.0, 400, 0.0),
        show: true,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        text: logName.toString(),
        verticalOrigin: Cesium.VerticalOrigin.BASELINE,
      };
    },
  },
};

export default drillPatternStore;