import licenceService from '@/services/licence.service';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const imageryLayers = [
  {
    iconUrl: 'imagery/mapbox-satellite.png',
    mapId: 'satellite-v9',
    name() {
      return $gettext('Satellite');
    },
    tooltip() {
      return $gettext('Mapbox Satellite');
    }
  },
  {
    iconUrl: 'imagery/mapbox-dark.png',
    mapId: 'dark-v10',
    name() {
      return $gettext('Dark');
    },
    tooltip() {
      return $gettext('Mapbox Dark');
    }
  },
  {
    iconUrl: 'imagery/mapbox-light.png',
    mapId: 'light-v10',
    name() {
      return $gettext('Light');
    },
    tooltip() {
      return $gettext('Mapbox Light');
    }
  },
  {
    iconUrl: 'imagery/mapbox-outdoors.png',
    mapId: 'outdoors-v11',
    name() {
      return $gettext('Outdoors');
    },
    tooltip() {
      return $gettext('Mapbox Outdoors');
    }
  },
  {
    iconUrl: 'imagery/mapbox-terrain.png',
    mapId: 'satellite-streets-v11',
    name() {
      return $gettext('Satellite Streets');
    },
    tooltip() {
      return $gettext('Mapbox Satellite Streets');
    }
  },
  {
    iconUrl: 'imagery/mapbox-streets.png',
    mapId: 'streets-v11',
    name() {
      return $gettext('Streets');
    },
    tooltip() {
      return $gettext('Mapbox Streets');
    }
  },
];

const imageryLayersChina = [
  {
    iconUrl: 'imagery/mapbox-dark.png',
    mapId: 'dark-zh-v1',
    name() {
      return $gettext('Dark');
    },
    tooltip() {
      return $gettext('Mapbox Dark');
    }
  },
  {
    iconUrl: 'imagery/mapbox-light.png',
    mapId: 'light-zh-v1',
    name() {
      return $gettext('Light');
    },
    tooltip() {
      return $gettext('Mapbox Light');
    }
  },
  {
    iconUrl: 'imagery/mapbox-streets.png',
    mapId: 'streets-zh-v1',
    name() {
      return $gettext('Streets');
    },
    tooltip() {
      return $gettext('Mapbox Streets');
    }
  }
];

const usedImageryLayers = licenceService.isChineseDistribution() ? imageryLayersChina : imageryLayers;

export default usedImageryLayers;