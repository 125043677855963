import _ from 'lodash';
import * as Cesium from 'cesium';
import { HoleDrillPileStatus } from '@/domain/hole/HoleDrillPileStatus.enum';
import { Hole } from '@/3dapi/entities/hole';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { measurementUnits, convert } from '@/utils/measurementUnitHelper';

const statusBillboardMap = {
  [StabilizationTypes.DrillHole]: {
    [HoleDrillPileStatus.Log]: 'drill-green.png',
    [HoleDrillPileStatus.Entry]: 'drill-yellow.png',
    [HoleDrillPileStatus.Paused]: 'drill-yellow.png',
    [HoleDrillPileStatus.Start]: 'drill-yellow.png',
    [HoleDrillPileStatus.End]: 'drill-green.png',
    [HoleDrillPileStatus.Failed]: 'drill-red.png',
    [HoleDrillPileStatus.Continue]: 'drill-yellow.png',
  },
  [StabilizationTypes.PileHole]: {
    [HoleDrillPileStatus.Log]: 'pile-green.png',
    [HoleDrillPileStatus.Entry]: 'pile-yellow.png',
    [HoleDrillPileStatus.Paused]: 'pile-yellow.png',
    [HoleDrillPileStatus.Start]: 'pile-yellow.png',
    [HoleDrillPileStatus.End]: 'pile-green.png',
    [HoleDrillPileStatus.Failed]: 'pile-red.png',
    [HoleDrillPileStatus.Continue]: 'pile-yellow.png',
  },
  getBillboard(holeType, holeStatus) {
    return this[holeType][holeStatus];
  }
};

const alpha = 0.9;
const statusColorMap = {
  [HoleDrillPileStatus.Log]: Cesium.Color.GREEN.withAlpha(alpha),
  [HoleDrillPileStatus.Entry]: Cesium.Color.GREEN.withAlpha(alpha),
  [HoleDrillPileStatus.Paused]: Cesium.Color.YELLOW.withAlpha(alpha),
  [HoleDrillPileStatus.Start]: Cesium.Color.GREEN.withAlpha(alpha),
  [HoleDrillPileStatus.End]: Cesium.Color.GREEN.withAlpha(alpha),
  [HoleDrillPileStatus.Failed]: Cesium.Color.RED.withAlpha(alpha),
  [HoleDrillPileStatus.Continue]: Cesium.Color.YELLOW.withAlpha(alpha),
  getColor(holeStatus) {
    return this[holeStatus];
  }
};

/**
 * State representation and dispatchable actions for drill holes and piles.
 */
const holesStore = {
  state: {
    holes: {},
    holeSets: {
      setDefault(uuid) {
        this[uuid] = this[uuid] || new Set();
        return this[uuid];
      }
    },
    /**
     * A collection of 2D representations of drill holes.
     */
    billboards(viewer) {
      if (!this._billboards) {
        const { primitives } = viewer.scene;
        this._billboards = primitives.add(new Cesium.BillboardCollection({
          blendOption: Cesium.BlendOption.TRANSLUCENT
        }));
      }
      return this._billboards;
    },
    /**
     * A collection of polyline 3D representations of drill holes.
     */
    lines(viewer) {
      if (!this._lines) {
        const { primitives } = viewer.scene;
        this._lines = primitives.add(new Cesium.PolylineCollection());
      }
      return this._lines;
    },
    /**
     * A collection of cirle polyline representations of drill holes.
     */
    circles(viewer) {
      if (!this._circles) {
        const { primitives } = viewer.scene;
        this._circles = primitives.add(new Cesium.PrimitiveCollection());
      }
      return this._circles;
    }
  },
  actions: {
    /**
     * Load holes into the 3D environment.
     * @param context: The Vue context.
     * @param sets {DrillHoleSet[]}: The hole sets to be loaded.
     * @param show {boolean}: If true, automatically displays holes using their 2D representation.
     */
    async loadHoles(context, { sets, show }) {
      const { self } = context;
      const { state } = self;
      if (!sets) return;
      if (show === true) show = { '2D': true, '3D': false };

      await Promise.all(sets.map(async holeSet => {
        state.holeSets.setDefault(holeSet.uuid);
        const holesAndPositions = await self.getTerrainPositions(holeSet);
        const offset3D = self.calculate3DOffset(context, holesAndPositions);
        await Promise.all(holesAndPositions.map(async holeAndPosition => {
          const [holeData, clampedPosition] = holeAndPosition;
          const hole = state.holes[holeData.uuid];
          if (holeData.uuid && !hole) {
            const newHole = self.createHole(context, holeData, clampedPosition, offset3D);
            newHole.showHide2D(!!show['2D']);
            newHole.showHide3D(!!show['3D']);
            self.addHoleToSet(newHole, holeSet.uuid);
          } else if (hole) {
            self.addHoleToSet(hole, holeSet.uuid);
          }
        }));
      }));
    },
    async removeHoles(context, { setIds }) {
      const { state } = context.self;
      setIds.forEach(setId => {
        const holeSet = state.holeSets[setId];
        holeSet.forEach(hole => {
          context.self.removeHole(hole.uid, setId);
        });
      });
    },
    /**
     * Show/Hide holes on the viewer.
     * @param context
     * @param holeIds: Ids of the holes to show/hide.
     * @param {*} show: Information about the drill hole set show modes. Default is {'2D': false, '3D': false}.
     * @param {Boolean} show.2D: Show the 2D hole representation.
     * @param {Boolean} show.3D: Show the 3D hole representation.
     */
    async showHideHoles(context, { holeIds, show }) {
      if (show === true) show = { '2D': true, '3D': false };
      if (!show) show = { '2D': false, '3D': false };
      holeIds.forEach(holeId => {
        const hole = context.self.state.holes[holeId];
        if (hole) {
          hole.showHide2D(!!show['2D']);
          hole.showHide3D(!!show['3D']);
        }
      });
    },
  },
  methods: {
    convertValueForCesium(baseLengthUnit, value) {
      return convert(value).from(baseLengthUnit).to('m');
    },
    /**
     * Gets the positions of all holes in the hole set projected onto terrain.
     * @param holeSet: A set of drill/pile holes.
     * @returns {Promise<void>}: An array of drill/pile holes zipped with their positions projected onto terrain.
     */
    async getTerrainPositions(holeSet) {
      const { viewer } = this.rootState;
      const setPositions = holeSet.drillHoles.map(h => Cesium.Cartographic.fromDegrees(Number(h.displayLongitude2D), Number(h.displayLatitude2D), 0));
      await Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, setPositions);
      return _.zip(holeSet.drillHoles, setPositions);
    },
    /**
     * Calculates the deepest hole with terrain tolerance of the endpoint.
     * @param holesAndPositions {Array}: A zip of hole data and clamped terrain position.
     * @returns {Number}: The deepest hole depth used for offsetting the 3D hole set above ground.
     */
    calculate3DOffset(context, holesAndPositions) {
      const baseLengthUnit = context.rootGetters['app/measurementSystem'].baseLengthUnit.name;
      const holeDepths = holesAndPositions.map((holeAndPosition) => {
        const [hole, position] = holeAndPosition;
        const lowestPointHeight = _.min(hole.pointSet.points.map(p => {
          const { ellipsoidHeight } = p;
          if (baseLengthUnit !== measurementUnits.m.name) {
            return this.convertValueForCesium(baseLengthUnit, ellipsoidHeight);
          }
          return ellipsoidHeight;
        }));
        const highestPointHeight = _.max(hole.pointSet.points.map(p => {
          const { ellipsoidHeight } = p;
          if (baseLengthUnit !== measurementUnits.m.name) {
            return this.convertValueForCesium(baseLengthUnit, ellipsoidHeight);
          }
          return ellipsoidHeight;
        }));
        const deltaSurface = highestPointHeight - position.height;
        let result = position.height - lowestPointHeight;
        if (deltaSurface) result += deltaSurface;
        return result > 0 ? result : 0;
      });
      return _.max(holeDepths);
    },
    /**
     * Creates the 3D and 2D representations of DrillHole data and adds them to the map.
     * @param holeData {Object[]} drillHole or pileHole entityType see holeFactory
     * @param clampedPosition {Cartographic}
     * @param offset3D {Number}
     * @returns {Hole}
     */
    createHole(context, holeData, clampedPosition, offset3D) {
      const baseLengthUnit = context.rootGetters['app/measurementSystem'].baseLengthUnit.name;
      const { state } = this;
      const { viewer } = this.rootState;
      const billboard = state.billboards(viewer).add(this.createHole2DBillboard(holeData, clampedPosition));
      const umbrellaHeight = _.max(holeData.pointSet.points.map(p => {
        const { ellipsoidHeight } = p;
        if (baseLengthUnit !== measurementUnits.m.name) {
          return this.convertValueForCesium(baseLengthUnit, p.ellipsoidHeight);
        }
        return ellipsoidHeight;
      })) - _.min(holeData.pointSet.points.map(p => {
        const { ellipsoidHeight } = p;
        if (baseLengthUnit !== measurementUnits.m.name) {
          return this.convertValueForCesium(baseLengthUnit, p.ellipsoidHeight);
        }
        return ellipsoidHeight;
      }));
      let lines = this.createHole3DLines(holeData, clampedPosition, umbrellaHeight, offset3D);
      let circles = this.createHole3DCircles(holeData, clampedPosition, umbrellaHeight, offset3D);
      lines = lines.map(line => state.lines(viewer).add(line));
      circles = circles.map(circle => state.circles(viewer).add(circle));
      return new Hole(holeData, billboard, lines, circles);
    },
    /**
     * Adds a specified hole to the specified hole set.
     * @param hole {Hole}: A data object representing a hole billboard to add to a set.
     * @param holeSetId: The id of the hole set to add to.
     */
    addHoleToSet(hole, holeSetId) {
      if (!this.state.holes[hole.uid]) {
        this.state.holes[hole.uid] = hole;
      }
      hole.addSet(holeSetId);
      this.state.holeSets[holeSetId].add(hole);
    },
    /**
     * Removes a specified hole from the specified hole set.
     * If a hole does not have any sets attached, remove it from the holes state.
     * @param holeId: The id of the hole to remove.
     * @param holeSetId: The id of the hole set to remove from.
     */
    removeHole(holeId, holeSetId) {
      const hole = this.state.holes[holeId];
      hole.removeSet(holeSetId);
      this.state.holeSets[holeSetId].delete(hole);
      if (!hole.belongsToSet) {
        this.state.billboards().remove(hole.billboard);
        this.state.lines().remove(hole.line);
        this.state.circles().remove(hole.circle);
        delete this.state.holes[holeId];
      }
    },
    /**
     * Create an id object for the specified hole.
     * @param holeData {DrillHole}
     * @param clampedPosition {Cartographic}
     * @param type {string}: Custom type - overrides holeData.entityType.
     * @returns {Object} The id object for the specified hole.
     */
    createHolePrimitiveId(holeData, clampedPosition, type = undefined) {
      const cartesianPosition = Cesium.Cartographic.toCartesian(clampedPosition);
      return {
        uuid: holeData.uuid,
        cxType: type || holeData.entityType,
        holeSets: new Set(),
        status: holeData.status,
        name: `${holeData.entityType} Point / ${holeData.status}`,
        logTime: holeData.logTime ? holeData.logTime : 'N/A',
        position: cartesianPosition,
      };
    },
    /**
     * Create a 2D Billboard primitive representing a hole.
     * @param holeData {DrillHole}
     * @param clampedPosition {Cartographic}: The cartographic position of the hole.
     * @returns {*}: A data object that can be used in Cesium.BillboardCollection#add.
     */
    createHole2DBillboard(holeData, clampedPosition) {
      const primitiveId = this.createHolePrimitiveId(holeData, clampedPosition);
      return {
        id: primitiveId,
        show: false,
        position: primitiveId.position,
        image: statusBillboardMap.getBillboard(holeData.entityType, holeData.status),
        scale: 1.0,
        color: new Cesium.Color(1.0, 1.0, 1.0, 1.2),
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
      };
    },
    /**
     * Create the polyline part of the 3D hole representation.
     * The geometry is initially set to show: false.
     * @param holeData {DrillHole}
     * @param clampedPosition {Cartographic}: The cartographic position of the hole.
     * @param umbrellaHeight {Number}: The height of the 3D umbrella.
     * @param offset3D {Number}: The deepest hole offset from the surface.
     */
    createHole3DLines(holeData, clampedPosition, umbrellaHeight, offset3D) {
      const { viewer } = this.rootState;
      const primitiveId = this.createHolePrimitiveId(holeData, clampedPosition, `${holeData.entityType}3D`);
      const coords = [clampedPosition.longitude, clampedPosition.latitude];

      const top = Cesium.Cartesian3.fromRadians(...coords, clampedPosition.height + offset3D);
      const middle = Cesium.Cartesian3.fromRadians(...coords, clampedPosition.height + offset3D - umbrellaHeight);
      const bottom = Cesium.Cartesian3.fromRadians(...coords, clampedPosition.height);

      const topPositions = [top, middle];
      const topMaterial = new Cesium.Material({ fabric: { type: 'Color', uniforms: { color: statusColorMap.getColor(primitiveId.status) } } });
      const bottomPositions = [middle, bottom];
      const bottomMaterial = Cesium.Material.fromType('PolylineDash');

      bottomMaterial.uniforms.color = Cesium.Color.WHITE;
      const line = (positions, material) => ({
        id: { position: primitiveId.position, ...primitiveId },
        show: false,
        positions,
        material,
        width: Math.min(2.0, viewer.scene.maximumAliasedLineWidth)
      });
      return [line(topPositions, topMaterial), line(bottomPositions, bottomMaterial)];
    },
    /**
     * Create the circle outline part of the 3D hole representation.
     * The geometry is initially set to show: false.
     * @param holeData {DrillHole}
     * @param clampedPosition {Cartographic} - The cartographic position of the hole.
     * @param umbrellaHeight {Number}: The height of the 3D umbrella.
     * @param offset3D {Number}: The deepest hole offset from the surface.
     */
    createHole3DCircles(holeData, clampedPosition, umbrellaHeight, offset3D) {
      const { viewer } = this.rootState;
      const primitiveId = this.createHolePrimitiveId(holeData, clampedPosition, `${holeData.entityType}3D`);
      const topHeight = clampedPosition.height + offset3D;
      const topColor = Cesium.ColorGeometryInstanceAttribute.fromColor(statusColorMap.getColor(primitiveId.status));
      const bottomHeight = topHeight - umbrellaHeight;
      const bottomColor = Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.WHITE);

      const circleOptions = (height, color) => ({
        id: { position: primitiveId.position, ...primitiveId },
        geometry: new Cesium.CircleOutlineGeometry({
          id: { position: primitiveId.position, ...primitiveId },
          center: primitiveId.position,
          radius: 0.7,
          height,
        }),
        attributes: { color }
      });

      const topCircle = new Cesium.GeometryInstance(circleOptions(topHeight, topColor));
      const bottomCircle = new Cesium.GeometryInstance(circleOptions(bottomHeight, bottomColor));

      return [new Cesium.Primitive({
        geometryInstances: [topCircle, bottomCircle],
        show: false,
        appearance: new Cesium.PerInstanceColorAppearance({
          flat: true,
          translucent: false,
          renderState: {
            lineWidth: Math.min(2.0, viewer.scene.maximumAliasedLineWidth)
          }
        })
      })];
      // adding one primitive with geometries in bulk may or may not be more performant
      // viewer.scene.primitives.add(new Cesium.Primitive({
      //   geometryInstances: circles,
      //   appearance: new Cesium.PerInstanceColorAppearance({
      //     flat: true,
      //     renderState: {
      //       lineWidth: Math.min(2.0, viewer.scene.maximumAliasedLineWidth)
      //     }
      //   })
      // }));
    },
  }
};

export default holesStore;
