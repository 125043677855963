<template>
  <div class="cx-popper">
    <div class="cx-popper-content"><slot></slot></div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
/**
 * A wrapper for any HTML element or Vue component, which has to behave like an overlay,
 * respecting viewport boundaries and overflow conditions in HTML structure.
 */
export default {
  name: 'CxPopper',
  props: {
    /**
     * @property {Element} reference represents relatively positioned parent context
     */
    reference: {
      type: Element,
    },
    /**
     * @property {Object} options pass configs, see: https://popper.js.org/
     */
    options: {
      type: Object,
      requiered: false
    }
  },
  data() {
    return {
      popperInstance: null,
    };
  },
  methods: {
    /** Creates popper instance */
    initializePopper(reference, element, options) {
      const parentElement = this.reference ? this.reference : this.$parent.$el;
      this.popperInstance = createPopper(parentElement, element, options);
    },
    /** Updates popper instance on parent's DOM changes.
     * In order to update the popper dynamically:
     * - add $ref to the popper in the parent component,
     * - use Vue lifecycle hook updated() to fire the method on each update.
     */
    updatePopper() {
      this.popperInstance.update();
    },
    /** Removes popper instance on parent destroy */
    destroyPopper() {
      this.popperInstance.destroy();
    }
  },
  mounted() {
    this.initializePopper(this.reference, this.$el, this.options);
  },
  beforeUnmount() {
    this.destroyPopper();
  },
};
</script>
<style lang="less">
@import '../common';

.cx-popper {
  z-index: @cxMenuZI;
}
.cx-popper-content {
  border-radius: 4px;
  max-height: inherit;
  max-width: inherit;
}

</style>
