import _ from 'lodash';
import * as Cesium from 'cesium';
import { PickedFeature } from './pickedFeature';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';

export const styles = {
  select: {
    model: {
      active: {
        color: Cesium.Color.fromAlpha(Cesium.Color.GREEN, 1.0),
        colorBlendAmount: 0.6,
        colorBlendMode: Cesium.ColorBlendMode.HIGHLIGHT,
      },
      inactive: {
        color: Cesium.Color.fromAlpha(Cesium.Color.GREEN, 0.3),
        colorBlendAmount: 0.0,
        colorBlendMode: Cesium.ColorBlendMode.REPLACE
      }
    },
    point: {
      color: Cesium.Color.fromAlpha(Cesium.Color.LIME, 1.0),
      pixelSize: 10,
    },
  },
  hover: {
    model: {
      active: {
        color: Cesium.Color.fromAlpha(Cesium.Color.WHITE, 1.0),
        colorBlendAmount: 0.6,
        colorBlendMode: Cesium.ColorBlendMode.MIX,
      },
      inactive: {
        color: Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.3),
        colorBlendAmount: 0.0,
        colorBlendMode: Cesium.ColorBlendMode.REPLACE
      }
    },
    point: {
      color: Cesium.Color.fromAlpha(Cesium.Color.WHITE, 1.0),
      pixelSize: 10,
    }
  },
  default: {
    model: {
      active: {
        color: undefined,
        colorBlendAmount: 1.0,
        colorBlendMode: Cesium.ColorBlendMode.HIGHLIGHT
      },
      inactive: {
        color: new Cesium.Color(0.2, 0.2, 0.2, 0.3),
        colorBlendAmount: 0.0,
        colorBlendMode: Cesium.ColorBlendMode.REPLACE
      }
    }
  }
};

export class PickedEntity extends PickedFeature {
  constructor(viewer, feature) {
    super(viewer);
    this.entity = feature;
    const featureModelStyle = {
      color: _.get(this.entity, 'model.color'),
      colorBlendAmount: _.get(this.entity, 'model.colorBlendAmount'),
      colorBlendMode: _.get(this.entity, 'model.colorBlendMode')
    };
    this.originalStyle = {
      model: {
        active: featureModelStyle,
        inactive: featureModelStyle
      },
      point: {
        color: _.get(this.entity, 'point.color'),
        pixelSize: _.get(this.entity, 'point.pixelSize'),
      }
    };
  }

  applyStyle(style) {
    const { activity } = this;
    if (_.isObject(this.entity.model)) {
      this.entity.model.color = style.model[activity].color;
      this.entity.model.colorBlendAmount = style.model[activity].colorBlendAmount;
      this.entity.model.colorBlendMode = style.model[activity].colorBlendMode;
    }
    if (_.isObject(this.entity.point)) {
      this.entity.point.color = style.point.color;
      this.entity.point.pixelSize = style.point.pixelSize;
    }
  }
  applySelectStyle() {
    this.applyStyle(styles.select, this.activity);
  }
  applyHoverStyle() {
    this.applyStyle(styles.hover, this.activity);
  }

  /**
   * Restores Entities style to the default one - the entity's "untouched" style.
   * Useful for entities that change style depending on other params e.g. Units and Activity.
   */
  restoreDefaultStyle() {
    this.applyStyle(styles.default, this.activity);
  }
  /**
   * Restores Entities style to the original one - the entity's "unhovered" style.
   * Useful for entities that change style depending on user interaction e.g. when hovering/dehovering.
   */
  restoreOriginalStyle() {
    this.applyStyle(this.originalStyle, this.activity);
  }

  get position() {
    let result = null;
    const { currentTime } = this.viewer.clock;
    const { position, properties } = this.entity;
    const { cxInitialPosition, cxClampedPosition } = properties;
    if (position || cxInitialPosition || cxClampedPosition) {
      const dynamicPosition = position ? position.getValue(currentTime) : null;
      const staticPosition = cxInitialPosition ? cxInitialPosition.getValue() : null;
      const clampedPosition = cxClampedPosition ? cxClampedPosition.getValue() : null;
      result = clampedPosition || dynamicPosition || staticPosition;
    }
    return result;
  }

  get name() {
    return this.entity.name;
  }

  get type() {
    if (this.entity.properties.cxType) return this.entity.properties.cxType.getValue();
    return null;
  }

  get uuid() {
    return this.entity.id;
  }

  get isUnit() {
    return this.entity.properties.hasProperty('cxType') && Object.values(UnitTypes).includes(this.entity.properties.cxType.getValue());
  }

  get activity() {
    if (this.isUnit) {
      const { currentTime } = this.viewer.clockViewModel;
      return this.entity.properties.cxActivity.getValue(currentTime) ? 'active' : 'inactive';
    }
    return null;
  }
}
