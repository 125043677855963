<template>
  <cx-simple-popup :isOpened="isOpened" :offsetRatio="{ y: 0.45, x: 0.5}" :popupId="popupId" class="popup-awareness-kpi" :showCloseButton="false">
    <template #title>{{ translations.title }}</template>

    <!-- KPI template  -->
    <div class="cx-simple-popup-container">
      <cx-kpi-cards/>
    </div>
    <template #actions></template>
  </cx-simple-popup>
</template>

<script>
import CxSimplePopup from '@/components/CxSimplePopup';
import CxKpiCards from './CxKpiCards';

export default {
  name: 'CxKpiPopup',
  components: {
    CxSimplePopup,
    CxKpiCards
  },
  props: {
    popupId: { type: String, default: 'popup-awareness-kpi' },
    isOpened: { type: Boolean }
  },
  computed: {
    translations() {
      return {
        title: this.$gettext('KPI Dashboard')
      };
    },
  },
  methods: {
  }
};

</script>

<style lang="less">
@import '../../../common';


.popup-awareness-kpi {

  .cx-popup__header__content__title {
    padding-left: 20px;
    padding-top: 20px;
  }

  .kpi-content {
    height: 500px;
  }

}

</style>