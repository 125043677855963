<template>
  <div class="cx-options">
    <cx-option
      v-for="item in items"
      :key="item.id"
      :item="item"
      :label="label"
      :isSelected="isSelected(item)"
      :isActive="isActive(item)"
      :input="input"/>
    <span v-if="!items.length">{{ translations.noResults }}</span>
  </div>
</template>

<script>
import CxOption from './CxOption';

export default {
  name: 'CxOptions',
  components: {
    CxOption
  },
  props: {
    items: Array,
    value: Array,
    label: String,
    activeItem: Object,
    input: Function,
  },
  computed: {
    translations() {
      return { noResults: this.$gettext('No results...') };
    }
  },
  methods: {
    isActive(item) {
      if (!item || !this.activeItem) return false;
      return item.id === this.activeItem.id;
    },
    isSelected(item) {
      return this.value.includes(item.id);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-options {
  background-color: @dropdownBackgroundColor;
  border-radius: @inputBorderRadius;
  padding: @inputPadding;
  box-shadow: @dropdowBoxShadow;
  overflow-y: auto;
  overflow-x: hidden;

  span {
    font-size: @appFontSize;
    color: @textColorDisabled;
    padding: 4px @inputPadding @inputPadding;
  }
}
</style>