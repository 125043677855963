<template>
  <div class="cx-measurement-date-filter">
    <cx-date-time-picker
      v-model="startTime"
      :allowFutureDates="true"
      placeholder="From"
      :minDate="projectStartDay"
      :maxDate="endTime"
      :events="events"
      :disabled="disabled"
      :loading="loading"/>
    <span class="cx-measurement-date-filter__separator">-</span>
    <cx-date-time-picker
      v-model="endTime"
      :allowFutureDates="true"
      placeholder="To"
      :minDate="startTime"
      :events="events"
      :disabled="disabled"
      :loading="loading"/>
  </div>
</template>

<script>
import CxDateTimePicker from '@/components/datePickers/CxDateTimePicker.vue';

export default {
  name: 'CxTimeRangeFilter',

  components: {
    CxDateTimePicker
  },

  props: {
    startDate: Date,
    changeStartDate: Function,
    endDate: Date,
    changeEndDate: Function,
    projectStartDay: Date,
    events: { type: Array, default: () => [] },
    disabled: { type: Boolean },
    loading: { type: Boolean }
  },

  computed: {
    startTime: {
      get() {
        return this.startDate;
      },
      set(value) {
        this.changeStartDate(value);
      }
    },

    endTime: {
      get() {
        return this.endDate;
      },
      set(value) {
        this.changeEndDate(value);
      }
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-measurement-date-filter {
  .flex(row, flex-start, center);
  margin: @inputSpace 0 40px;

  .cx-measurement-date-filter__separator {
    padding: 0 @inputSpace;
  }
}
</style>
