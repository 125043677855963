import api from '@/http/api';
import { isValid, toDate, subDays, startOfDay } from 'date-fns';
import translationService from '@/services/translation.service';
import licenceService from '@/services/licence.service';
import waffleFlagService from '@/services/waffleFlag.service';
import { Alert, AlertMessage } from '@/components/cxAlert/Alert';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

// we tag all things along, we make a quick tag search
// should we make a map uuid: thing (?)

// all data that are local to the whole app
const appStore = {
  state: {
    // Array<Object> all reference models and units available in the project
    entities: [],
    projectName: null,
    projectStartDate: null,
    measurementSystem: null,
    projectInitialized: false,
    appProjectReady: false,
    mapImagery: true,
    mapTerrain: true,
    accountData: null
  },
  getters: {
    projectName(state) {
      return state.projectName;
    },
    projectStartDate(state) {
      if (!state.projectStartDate) return null;
      return toDate(state.projectStartDate);
    },
    projectStartDay(state) {
      if (!state.projectStartDate) return null;
      return startOfDay(subDays(state.projectStartDate, 1));
    },
    entities(state) {
      return state.entities;
    },
    allModels(state) {
      return state.entities.filter(e => Object.values(RefmodelTypes).includes(e.entityType));
    },
    allUnits(state) {
      return state.entities.filter(e => Object.values(UnitTypes).includes(e.entityType));
    },
    measurementSystem(state) {
      return state.measurementSystem;
    },
    projectInitialized(state) {
      return state.projectInitialized;
    },
    appProjectReady(state) {
      return state.appProjectReady;
    },
    mapImagery(state) {
      return state.mapImagery;
    },
    mapTerrain(state) {
      return state.mapTerrain;
    },
    accountData(state) {
      return state.accountData;
    }
  },
  mutations: {
    setProjectName(state, name) {
      state.projectName = name;
    },
    setProjectStartDate(state, startDate) {
      if (isValid(startDate)) state.projectStartDate = toDate(startDate);
      else throw TypeError('Invalid project start date');
    },
    addEntities(state, entities) {
      state.entities = state.entities.concat(entities);
    },
    setMeasurementSystem(state, measurementSystem) {
      state.measurementSystem = measurementSystem;
    },
    setProjectInitialized(state, isInitialized = true) {
      state.projectInitialized = isInitialized;
    },
    setAppProjectReady(state, isProjectReady) {
      state.appProjectReady = isProjectReady;
    },
    setMapImagery(state, mapImagery) {
      state.mapImagery = mapImagery;
    },
    setMapTerrain(state, mapTerrain) {
      state.mapTerrain = mapTerrain;
    },
    setAccountData(state, accountData) {
      state.accountData = accountData;
    }
  },
  actions: {
    async fetchAccountData(context) {
      try {
        const account = await api.getAccountData();
        context.commit('setAccountData', account);
      } catch (e) {
        const translation = $gettext('An error occured during application initialization, please try again later');
        context.dispatch('notifications/error', { exception: e, message: translation }, { root: true });
      }
    },
    async fetchProjectMetadata(context) {
      try {
        const account = context.state.accountData;
        translationService.setLanguage(account.profile.language);

        const { coordinateSystem, name, uuid: accountId, waffleFlags } = await api.getProjectAccounts(context.rootGetters.projectUuid);
        if (coordinateSystem) {
          context.commit('setProjectName', name);
          // fetch project start moment and licence data
          const metadata = await api.getProjectMetadata(context.rootGetters.projectUuid);
          context.commit('setProjectStartDate', metadata.projectStartDate);
          licenceService.setLicence(metadata.licenses);
          waffleFlagService.setWaffleFlags(accountId, waffleFlags);
          context.commit('setMeasurementSystem', metadata.measurementSystem);
          context.commit('setProjectInitialized');
          context.dispatch('shell/initializeFilteringPanel', null, { root: true });
          context.dispatch('filterPoints/fetchPointMetadataHistograms', null, { root: true });
          context.dispatch('filterAwarenessEvents/initializeFilters', null, { root: true });
          // fetch units goes at very beginning, as we want to load the app - calculate the initial position
          // as fast as possible
          const unitsPromise = context.dispatch('fetchUnits');
          const refmodelPromise = context.dispatch('fetchAllModels');
          // project is initialized when language, licence and metadata is fetched
          await Promise.all([unitsPromise, refmodelPromise]);
          await context.dispatch('map/restoreMapState', {}, { root: true });

        } else {
          const subtitleMsg = $gettext('Coordinate system is missing.');
          const configurationURL = `<a href='${window.location.origin}/frontend/site#/ict/account/${accountId}/project/settings/edit' target="_parent">${$gettext('here.')}</a>`;
          const coodinateSystemMsg = `${$gettext('Please define coordinate system')} ${configurationURL}`;
          const alert = new Alert($gettext('Application cannot be used'), subtitleMsg, [AlertMessage.createErrorMessage(coodinateSystemMsg)], false);
          context.dispatch('alert/displayAlert', alert, { root: true });
        }
      } catch (e) {
        const translation = $gettext('An error occured during application initialization, please try again later');
        context.dispatch('notifications/error', { exception: e, message: translation }, { root: true });
      } finally {
        context.commit('setAppProjectReady', true);
      }
    },
    async fetchUnits(context) {
      const units = await api.getUnits(this.state.parentContext.project_uuid);
      context.commit('addEntities', units.units);
      // loading czml to Cesium
      context.dispatch('map/loadUnitsFromCZML', units.czml, { root: true });
      context.dispatch('filterUnits/initializeUnitFilters', units.units, { root: true });
    },
    async fetchAllModels(context) {
      const referenceModels = await api.getReferenceModels(context.rootGetters.projectUuid);
      const dataSources = await api.getDataSources(context.rootGetters.projectUuid);
      const allModels = [...referenceModels, ...dataSources];
      context.commit('addEntities', allModels);
      context.dispatch('map/initializeRefmodelGeocoderData', allModels, { root: true });
      context.dispatch('filterRefmodel/initializeRefModelFilters', allModels, { root: true });
    },
  },
};

export default appStore;