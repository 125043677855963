<template>
  <div class="cx-mp-related-entities-panel">
    <slot name="preContent"></slot>
    <cx-related-entities-badge :tooltip="refmodelName" v-if="showRefmodel">{{ refmodelName || translated.notAvailableCaption }}</cx-related-entities-badge>
    <cx-related-entities-badge :tooltip="unitName" v-if="showUnit">{{ unitName || translated.notAvailableCaption }}</cx-related-entities-badge>
    <cx-related-entities-badge :tooltip="pointName" v-if="showPoint">{{ pointName || translated.notAvailableCaption }}</cx-related-entities-badge>
    <cx-button
      variantDark
      v-if="showPointsDataVisible"
      @click="onShowPointsData"
      :disabled="!onShowPointsData"
      :loading="showPointsDataLoading">
      {{ translated.pointButton }}
    </cx-button>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import CxRelatedEntitiesBadge from './CxRelatedEntitiesBadge';

export default {
  name: 'CxRelatedEntitiesPanel',
  components: {
    CxButton,
    CxRelatedEntitiesBadge
  },
  props: {
    refmodelName: String,
    unitName: String,
    showRefmodel: Boolean,
    showUnit: {
      type: Boolean,
      default: false,
    },
    showPoint: {
      type: Boolean,
      default: false,
    },
    onShowPointsData: {
      type: Function,
      default: null
    },
    showPointsDataVisible: {
      type: Boolean,
      default: false
    },
    showPointsDataLoading: {
      type: Boolean,
      default: false
    },
    pointName: String,
    isRefmodelDisabled: {
      type: Boolean,
      default: false
    },
    isUnitDisabled: {
      type: Boolean,
      default: false
    },
    isPointNameDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translated() {
      return {
        pointButton: this.$gettext('Show Points Data'),
        notAvailableCaption: this.$gettext('no data')
      };
    },
  },

};

</script>

<style lang="less">
@import '../../../common';

.cx-mp-related-entities-panel {
  width: @detailsRelatedEntitiesWidth;

  .cx-button:not(:first-child)  {
    margin-top: 14px;
  }
  .cx-related-entities-badge:not(:first-child) {
    margin-top: 14px;
  }
  .cx-button {
    width: 100%;
  }
}
</style>