import messageApi from '@/http/awareness/awarenessMessage.api';

const sosStoreModule = {
  state: {
    displayErrorScreen: false,
    spinSendButton: false
  },
  getters: {
    displayErrorScreen(state) {
      return state.displayErrorScreen;
    },
    spinSendButton(state) {
      return state.spinSendButton;
    },
  },
  mutations: {
    setDisplayErrorScreen(state, displayErrorScreen) {
      state.displayErrorScreen = displayErrorScreen;
    },
    setSpinSendButton(state, spinSendButton) {
      state.spinSendButton = spinSendButton;
    },
  },
  actions: {
    async sendSos(context) {
      try {
        context.commit('setSpinSendButton', true);
        await messageApi.sendSos(context.rootGetters.projectUuid);
      } catch (e) {
        context.commit('setDisplayErrorScreen', true);
      } finally {
        context.commit('setSpinSendButton', false);
      }
    }
  }
};

export { sosStoreModule };