import * as Cesium from 'cesium';
import mapConstants from '@/3dapi/map.constants';
import { gettext } from '@/translations/gettext.setup';
import { measurementUnits, convert } from '@/utils/measurementUnitHelper';

const { $gettext } = gettext;

const pointColorMap = {
  default: Cesium.Color.WHITE,
  cut: Cesium.Color.fromCartesian4(Cesium.Cartesian4.fromArray(mapConstants.cutFillDefault.cutColor)),
  fill: Cesium.Color.fromCartesian4(Cesium.Cartesian4.fromArray(mapConstants.cutFillDefault.fillColor)),
  unknown: Cesium.Color.WHITE,
  discarded: Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.2),
  'on-grade': Cesium.Color.fromCartesian4(Cesium.Cartesian4.fromArray(mapConstants.cutFillDefault.gradeColor)),
  'drillpile-green': Cesium.Color.fromBytes(90, 242, 153, 255),
  'drillpile-yellow': Cesium.Color.fromBytes(255, 231, 48, 255),
  'drillpile-red': Cesium.Color.fromBytes(252, 110, 91, 255),
  'drillpile-unknown': Cesium.Color.WHITE,
  getColor(color) {
    return this[color] || this.default;
  }
};

const pointsStore = {
  state: {
    /**
     * This PrimitiveCollection holds all the point primitives.
     * In the future it might be reasonable to add moar collections like this one
     * for more efficient rendering.
     */
    pointCollection(viewer) {
      if (!this._pointCollection) {
        const { primitives } = viewer.scene;
        this._pointCollection = primitives.add(new Cesium.PointPrimitiveCollection());
      }
      return this._pointCollection;
    },
    points: {},
    pointSets: {},
  },
  actions: {
    /**
     * Loads as built points, conditionally shows them on the map
     * and updated their inner data for point we alreadt have
     * @param context
     * @param pointSets {PointSet[]}: The sets of points to add/update.
     * @param show {Boolean}: If true points will be automatially shown on the map.
     * @param contextColor {Boolean}: If true, points will have color determined from their context.
     * @returns {Promise<boolean>} The promise that will resolve when cesium finishes viusalizing the points.
     */
    async loadPoints(context, { pointSets, show, useContextColor = false }) {
      const { self } = context;
      const { viewer } = self.rootState;
      const { state } = self;
      if (!pointSets) return false;

      const pointCollection = state.pointCollection(viewer);
      await Promise.all(pointSets.map(async (pointSet) => {
        state.pointSets[pointSet.uuid] = state.pointSets[pointSet.uuid] || new Set();
        await Promise.all(pointSet.points.map(async (point) => {
          const pointPrimitive = state.points[point.uuid];
          if (point.uuid && !pointPrimitive) {
            const newPoint = pointCollection.add(self.createPointPrimitive(context, point, show, useContextColor));
            self.addPointToSet(newPoint, pointSet.uuid);
          } else if (pointPrimitive) {
            self.addPointToSet(pointPrimitive, pointSet.uuid);
            if (show) pointPrimitive.show = true;
          }
        }));
      }));
      context.dispatch('map/updatePerformancePointCount', { pointCount: state.pointCollection(viewer).length }, { root: true });
      return true;
    },

    /**
     * Removes all points, that are in the poinst list
     * @param {Array} points list of point uuids to be removed from cesium map
     */
    async removePoints(context, { points }) {
      const { state } = context.self;
      const { viewer } = context.self.rootState;
      if (!points) return;
      await Promise.all(points.map(async (pointId) => {
        const point = state.points[pointId];
        if (point) {
          state.pointCollection().remove(point);
          delete state.points[pointId];
          point.id.pointSets.forEach(uuid => state.pointSets[uuid].delete(point));
        }
      }));
      context.dispatch('map/updatePerformancePointCount', { pointCount: state.pointCollection(viewer).length }, { root: true });
    },

    /**
     * Shows or hide specific points
     * @param {*} context
     * @param {*} pointUuids - the list of point uuids to show or hide
     * @param {*} show - if true, the point will be show, will be hidden otherwise
     */
    async showHideAsBuiltPoint(context, { points, show }) {
      points.forEach(point => {
        const pointPrimitive = context.self.state.points[point];
        if (pointPrimitive) pointPrimitive.show = show;
      });
    },
    async colorPoints(context, { pointColors, color }) {
      const { state } = context.self;
      Object.entries(pointColors).forEach(([uuid, pointColor]) => {
        const point = state.points[uuid];
        const newColor = pointColorMap.getColor(color || pointColor);
        if (point && newColor) point.color = newColor;
      });
    }
  },
  methods: {
    /**
     * Adds a specified hole to the specified hole set.
     * @param point {PointPrimitive}: A data object representing a hole billboard to add to a set.
     * @param pointSetId: The id of the hole set to add to.
     */
    addPointToSet(point, pointSetId) {
      const { state } = this;
      if (!state.points[point.id.uuid]) {
        state.points[point.id.uuid] = point;
      }
      point.id.pointSets.add(pointSetId);
      state.pointSets[pointSetId].add(point);
    },
    createPointPrimitive(context, point, show, contextColor) {
      const baseLengthUnit = context.rootGetters['app/measurementSystem'].baseLengthUnit.name;
      let color = contextColor ? pointColorMap.getColor(point.color) : pointColorMap.default;
      let outlineColor = color.darken(1.0, new Cesium.Color());
      let { ellipsoidHeight } = point;
      if (baseLengthUnit !== measurementUnits.m.name) {
        ellipsoidHeight = convert(point.ellipsoidHeight).from(baseLengthUnit).to('m');
      }
      if (point.isDiscarded) {
        color = pointColorMap.discarded;
        outlineColor = Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.3);
      }
      return {
        id: {
          uuid: point.uuid,
          name: point.name,
          unit: point.unitName ? point.unitName : $gettext('no data'),
          logTime: point.logTime ? point.logTime : $gettext('no data'),
          cxType: 'point',
          pointSets: new Set(),
          color: point.color,
        },
        show,
        position: Cesium.Cartesian3.fromDegrees(
          Number(point.longitude),
          Number(point.latitude),
          Number(ellipsoidHeight)
        ),
        color,
        outlineColor,
        outlineWidth: 0.7,
        pixelSize: 5,
      };
    },
  },
  events: {
  }
};

export default pointsStore;