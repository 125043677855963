import _ from 'lodash';
import { formatISO } from 'date-fns';
import api from '@/http/api';
import { createFilter } from '@/domain/filters/filterModelFactory';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;
const SOURCE_UID = 'pointGrid-fetchigData';

const getDrillpatternFilterArgs = (context, drillpattern) => {
  const referenceModelFilter = createFilter(FilterModelNames.RefmodelUuid, [drillpattern.uuid]);

  return {
    filters: [referenceModelFilter],
    startTime: formatISO(context.rootGetters['app/projectStartDate']),
    stopTime: formatISO(new Date(Date.now())),
  };
};

const getHoleFilterArgs = (context, hole) => {
  const { setUid } = hole;
  const { originTimeRange } = context.rootGetters['map/loadedDHSets'].find(s => s.uuid === setUid);
  if (!originTimeRange) throw new Error('Can\'t Export Hole Data - Origin Time Range not found');

  const uuids = hole.pointSet.points.map(p => p.uuid);
  const uuidFilter = createFilter(FilterModelNames.Id, uuids);
  return {
    filters: [uuidFilter],
    startTime: formatISO(originTimeRange.from),
    stopTime: formatISO(originTimeRange.to),
  };
};

const getHoleSetFilterArgs = (context, holeSet) => {
  const uuids = _.flatMap(holeSet.drillHoles, h => h.pointSet.points.map(p => p.uuid));
  const uuidFilter = createFilter(FilterModelNames.Id, uuids);

  return {
    startTime: formatISO(holeSet.originTimeRange.from),
    stopTime: formatISO(holeSet.originTimeRange.to),
    filters: [uuidFilter],
  };
};

const getPointSetFilterArgs = (context, pointSet) => {
  return {
    startTime: formatISO(pointSet.originTimeRange.from),
    stopTime: formatISO(pointSet.originTimeRange.to),
    filters: pointSet.originFilters
  };
};
const pointGridModule = {
  state: {
    isGridVisible: false,
    gridRows: null,
    gridSubtitle: null
  },
  getters: {
    isGridVisible(state) {
      return state.isGridVisible;
    },
    gridRows(state) {
      return state.gridRows;
    },
    gridSubtitle(state) {
      return state.gridSubtitle;
    }
  },
  mutations: {
    setIsGridVisible(state, isGridVisible) {
      state.isGridVisible = isGridVisible;
    },
    setGridRows(state, gridRows) {
      state.gridRows = gridRows;
    },
    setGridSubtitle(state, gridSubtitle) {
      state.gridSubtitle = gridSubtitle;
    }
  },
  cancelableActions: {
    /**
     *
     * @param {*} context
     * @param {*} entity
     */
    async fetchPoints(context, { entity, cancelToken }) {
      try {
        let args = null;
        if (Object.values(RefmodelTypes).includes(entity.entityType)) args = getDrillpatternFilterArgs(context, entity);
        if (Object.values(StabilizationTypes).includes(entity.entityType)) args = getHoleFilterArgs(context, entity);
        if (entity.entityType === DrillHoleSetType) args = getHoleSetFilterArgs(context, entity);
        if (entity.entityType === PointSetType) args = getPointSetFilterArgs(context, entity);

        if (!args) throw new Error('Cannot create filters for entity - unknown entity type: ', entity);
        if (cancelToken.canceled) return;
        const pointSet = await api.filterMeasurePoints(
          context.rootGetters.projectUuid,
          args.startTime,
          args.stopTime,
          args.filters,
          true,
          ['all']
        );
        if (!pointSet) throw Error('fetching points - no result');
        if (!pointSet.count) context.dispatch('notifications/notify', { message: $gettext('No points have been fetched') }, { root: true });
        if (!cancelToken.canceled) {
          context.commit('setGridRows', pointSet.points);
          const pointCountPrefix = $gettext('Total Point Count:');
          const pointCount = `${pointCountPrefix} ${pointSet.totalCount}`;
          context.commit('setGridSubtitle', pointCount);
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Error occured while fetching points data') }, { root: true });
      }
    }
  },
  actions: {
    /**
     * Shows a grid popup component on a screen and starts fetching points metadata
     * @param {} context
     * @param {*} entity
     */
    async showPointGrid(context, entity) {
      try {
        if (!entity || !entity.uuid || !entity.entityType) throw new Error(`entity cannot be empty and must have uuid and entityType: ${entity}`,);
        context.commit('setIsGridVisible', true);
        await context.dispatch('fetchPoints', { entity, sourceUid: SOURCE_UID });
      } catch (e) {
        context.dispatch('notifications/error', { message: $gettext('Error occured while fetching points data') }, { root: true });
      }
    },
    async hidePointGrid(context) {
      context.dispatch('cancelable/cancelActions', { sourceUid: SOURCE_UID }, { root: true });
      context.commit('setGridRows', null);
      context.commit('setIsGridVisible', false);
    }
  }
};

export default pointGridModule;