<template>
  <div class="cx-horizontal-property">
    <div class="cx-horizontal-property__field-name">{{ fieldName }}</div>
    <div class="cx-horizontal-property__value" :class="applyClass">
      <span class="cx-horizontal-property__value__body">{{ filteredValue }}</span>
      <span class="cx-horizontal-property__value__unit" v-if="isValue && unit">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import noDataFilter from './filters/noData.filter';

export default {
  name: 'CxHorizontalProperty',
  props: {
    fieldName: String,
    value: [String, Number],
    unit: String,
    truncate: String
  },
  computed: {
    isValue() {
      return !isNil(this.value) && this.value !== 'no data';
    },
    applyClass() {
      return {
        'cx-horizontal-property__value--no-data': !this.isValue,
        'cx-horizontal-property__value--truncate-start': this.truncate === 'start'
      };
    },
    filteredValue() {
      return noDataFilter(this.value);
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-horizontal-property {
  display: flex;
  font-size: @appFontSize;

  .cx-horizontal-property__field-name {
    color: @textColorLowlight;
    white-space: nowrap;
  }

  .cx-horizontal-property__value {
    margin-left: 8px;
    color: @textColor;

    &.cx-horizontal-property__value--no-data {
      color: @textColorLowlight;
    }

    &.cx-horizontal-property__value--truncate-start {
      .cut-overflow();
      direction: rtl;
    }

    .cx-horizontal-property__value__unit {
      color: @textColorLowlight;
      margin-left: 2px;
    }
  }
}
</style>