import http from '@/http/axios.instance';
import mapper from '@/http/histogram.mapper';
import { Histograms } from '@/domain/Histograms';

interface HistogramsResponseData {
  measured_points_fields: {
    count: number,
    histograms: { [key: string]: { type: string, display_name: string }}
  }
}

const getMetadataHistogram = async (projectUuid: string): Promise<{ pointFields: Histograms | []; stabilizationFields: Histograms | []; }> => {
  const url =
    `/api/ui2/v1/projects/${projectUuid}/asbuilt/histograms/`;
  const response = await http.get(url);
  const histograms: HistogramsResponseData = { ...response.data };
  const mappedHistograms = mapper.mapHistograms(histograms);
  return mappedHistograms;
};

const histogramApi = {
  getMetadataHistogram
};
export default histogramApi;