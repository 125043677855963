<template>
  <div class="cx-timeline">
    <div class="cx-timeline__controls">
      <i
        class="material-icons cx-timeline__control"
        @mousedown="decrementMultiplier"
        @mouseup="stopIncrementingMultiplier"
        @mouseleave="stopIncrementingMultiplier">
        fast_rewind
      </i>
      <div class="cx-timeline__multiplier">x {{ multiplier }}</div>
      <i
        class="material-icons cx-timeline__control"
        @mousedown="incrementMultiplier"
        @mouseup="stopIncrementingMultiplier"
        @mouseleave="stopIncrementingMultiplier">
        fast_forward
      </i>
    </div>
    <div class="cx-timeline__controls">
      <i class="material-icons cx-timeline__control" @click="jumpToStart">
        skip_previous
      </i>
      <i
        v-if="!isPlaying" class="material-icons cx-timeline__control cx-timeline__control-play"
        @click="clickTimelinePlay">
        play_arrow
      </i>
      <i
        v-if="isPlaying" class="material-icons cx-timeline__control cx-timeline__control-play"
        @click="clickTimelinePause">
        pause
      </i>
      <i class="material-icons cx-timeline__control" @click="jumpToEnd"> skip_next </i>
    </div>
    <div
      ref="timelineBar" class="cx-timeline__controls cx-timeline__bar"
      @click="handleBarInteraction($event)" @mousedown="handleBarMouseDown($event)">
      <div class="cx-timeline__bar__timerange">
        <div ref="timelineBarPast" class="cx-timeline__bar__timerange--past" :style="pastBarStyle"></div>
        <div class="cx-timeline__bar__timerange--marker"></div>
      </div>
    </div>
    <Teleport v-if="mountTeleport" to="#map">
      <cx-date-time-picker
        class="cx-timeline__datepicker"
        v-if="currentTime"
        v-model="currentTime"
        :minDate="startDate"
        :maxDate="endDate"
        :enableTimePicker="true"
        :autoApply="true"
        :keepActionRow="true"
        :closeOnAutoApply="false"
        @onApply="handleApply"
        :hideOnClickOutside="false"
        @toggleDatePickerOpen="onToggleDatePickerOpen">
        <template #activator>
          <div>
            <div
              class="cx-timeline__datepicker__label"
              :class="{'cx-timeline__datepicker__label--active': isDatePickerOpened }" v-if="currentDateLabel">
              <div class="cx-timeline__datepicker__label__date">{{ currentDateLabel.date }}</div>
              <div class="cx-timeline__datepicker__label__time">{{ currentDateLabel.time }}</div>
            </div>
          </div>
        </template>
      </cx-date-time-picker>
    </Teleport>
  </div>
</template>

<script>
import { differenceInMilliseconds, addMilliseconds, isValid, isEqual, toDate } from 'date-fns';
import { mapGetters, mapActions } from 'vuex';
import datetimehelper from '@/utils/datetimehelper';
import CxDateTimePicker from '@/components/datePickers/CxDateTimePicker.vue';

export default {
  name: 'CxTimeline',
  components: { CxDateTimePicker },
  data() {
    return {
      originalTime: null,
      isDatePickerOpened: false,
      multplierIncrementStep: 1,
      multiplierIncrementor: false,
      multiplierInterval: 200,
      componentMounted: false,
      datePickerWidth: 290,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      popperOptions: {
        placement: 'bottom',
        modifiers: [
          { name: 'offset', options: { offset: [0, 5] } },
        ]
      },
      mountTeleport: false
    };
  },
  computed: {
    ...mapGetters('map', ['isAnimating', 'multiplier', 'currentDate', 'startDate', 'endDate']),
    isPlaying() {
      return this.isAnimating;
    },
    totalMilliseconds() {
      return differenceInMilliseconds(new Date(Date.now()), this.startDate);
    },
    progressPercentage() {
      let percentage = 0;
      if (!!this.startDate && !!this.currentDate) {
        const partialMilliseconds = differenceInMilliseconds(this.currentDate, this.startDate);
        percentage = (partialMilliseconds / this.totalMilliseconds * 100).toFixed(2);
      }
      return percentage;
    },
    pastBarStyle() {
      return {
        width: `${this.progressPercentage}%`
      };
    },
    currentTime: {
      get() {
        return this.currentDate;
      },
      set(value) {
        this.setTime(value);
      }
    },
    currentDateLabel() {
      if (!isValid(this.currentDate)) return null;
      const label = datetimehelper.format(this.currentDate, datetimehelper.LOCALE_DATE_TIME_FORMAT);
      const labelChunks = label.split(' ');
      return {
        date: labelChunks[0],
        time: labelChunks[1]
      };
    },
  },
  methods: {
    ...mapActions('map', ['clickTimelinePlay', 'clickTimelinePause', 'trackTimelineBarClick', 'setTime', 'multiplyAnimationSpeed', 'jumpToStart', 'jumpToEnd']),
    incrementMultiplier() {
      this.startIncrementingMultiplier(this.multplierIncrementStep);
    },
    decrementMultiplier() {
      this.startIncrementingMultiplier(-this.multplierIncrementStep);
    },
    onToggleDatePickerOpen(value) {
      this.isDatePickerOpened = value;
      if (value) {
        this.originalTime = toDate(this.currentTime);
      } else if (!isEqual(this.currentTime, this.originalTime)) {
        this.currentTime = this.originalTime;
      }
    },
    startIncrementingMultiplier(step) {
      if (!this.multiplierIncrementor) {
        this.multiplyAnimationSpeed(this.multiplier + step);
        this.multiplierIncrementor = setInterval(
          () => this.multiplyAnimationSpeed(this.multiplier + step),
          this.multiplierInterval,
        );
      }
    },
    stopIncrementingMultiplier() {
      clearInterval(this.multiplierIncrementor);
      this.multiplierIncrementor = false;
    },
    calculateVW() {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    },
    releaseTimelineBar(event) {
      event.preventDefault();
      document.removeEventListener('mousemove', this.handleBarInteraction);
      document.removeEventListener('mouseup', this.releaseTimelineBar);
      this.trackTimelineBarClick();
    },
    handleBarMouseDown(event) {
      event.preventDefault();
      this.handleBarInteraction(event);
      document.addEventListener('mousemove', this.handleBarInteraction);
      document.addEventListener('mouseup', this.releaseTimelineBar);
    },
    /**
     * Transforms the relatvie clicked timeline bar position into a datetime within range.
     */
    handleBarInteraction(event) {
      const { left, right } = this.$refs.timelineBar.getBoundingClientRect();
      const clickedX = Math.min(Math.max(event.clientX, left), right);
      const partial = clickedX - left;
      const total = this.$refs.timelineBar.clientWidth;
      const percentage = (partial / total * 100).toFixed(2);
      this.calculateDateFromPercentage(percentage);
    },
    /**
     * Calculates a datetime based on percentage with the start/stop timeline range.
     */
    calculateDateFromPercentage(percentage) {
      const partialMilliseconds = percentage * this.totalMilliseconds / 100;
      const result = addMilliseconds(toDate(this.startDate), partialMilliseconds);
      this.setTime(result);
    },
    handleApply() {
      this.originalTime = this.currentTime;
      this.trackTimelineBarClick();
    }
  },
  /**
   * Attaches an event listener used to recalulate calendar's position on window resize.
   */
  created() {
    window.addEventListener('resize', this.calculateVW);
  },
  mounted() {
    this.mountTeleport = true;
  },
  /**
   * Removes an event listener used to recalulate calendar's position on window resize.
   */
  unmounted() {
    window.removeEventListener('resize', this.calculateVW);
  },
};
</script>

<style scoped lang="less">
@import '../../common';


.cx-timeline {
  .flex(row, space-between, center);
  color: @textColor;
  flex-grow: 1;

  &__datepicker {
    width: auto;
  }

  &__bar {
    .flex(row, space-between, center);
    flex-grow: 1;
    justify-content: flex-start !important;
    height: 24px;
    cursor: pointer;

    &__timerange {
      .flex(row, space-between, center);
      flex-grow: 1;
      justify-content: flex-start !important;
      height: 4px;
      border-radius: @inputBorderRadius;
      background-color: @grayBtnColor2;
      margin-bottom: 1px;

      &:before {
        position: relative;
        content: '';
        top: -5px;
        right: 500px;
        left: inherit;
        bottom: -5px;
        outline: 1px solid red;
        z-index: 40;
      }

      &--past {
        height: 4px;
        border-radius: @inputBorderRadius;
        background-color: @turquoiseAccentColorLowlight;

      }

      &--marker {
        height: 8px;
        width: 8px;
        border-radius: @inputBorderRadius;
        background-color: @turquoiseAccentColor;
      }
    }
  }

  &__datepicker {
    position: fixed;
    top: 37.5px;
    left: calc(50vw - 0.5 * 115px);
    z-index: @topCalendarZI;

    &__label {
      width: 115px;
      .flex(row, space-evenly, center);
      color: @baseBackgroundColor;
      font-size: @appFontSize;
      height: 32px;
      font-weight: 600;
      background-color: @turquoiseAccentColor;
      padding: 10px 5px 8px 5px;
      border-radius: 45px;

      &:hover {
        cursor: pointer;
        background-color: @turquoiseAccentColorHighlight;
      }

      &__date {
        font-weight: normal;
      }

      &--active {
        background-color: @turquoiseAccentColorDatePickerActive;
      }
    }

    &--date {
      font-size: @appFontSize;
      color: @textColorHighlight;
      margin-left: 5px;
    }
  }

  &__controls {
    .flex(row, space-between, center);
    margin-left: 25px;
  }

  &__multiplier {
    width: 38px;
    text-align: center;
    font-size: @appFontSize;
  }

  &__control {
    font-size: 16px;

    &:hover {
      color: @textColorHighlight;
      text-shadow: 0 0 3px @textColorHighlight;
      cursor: pointer;
    }

    &--disabled {
      color: @textColorDisabled;

      &:hover {
        color: @textColorDisabled;
        text-shadow: none;
        cursor: default;
      }
    }

    &-play {
      width: 38px;
      text-align: center;
      font-size: 20px;
    }
  }
}
</style>