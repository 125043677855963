import mapConstants from '../map.constants';
import { convert } from '@/utils/measurementUnitHelper';


// From gltf uniform schema https://github.com/KhronosGroup/glTF/blob/master/extensions/2.0/Khronos/KHR_techniques_webgl/schema/technique.uniform.schema.json todo extend me if needed
const gltfUniformType =
{
  INT: 5124,
  FLOAT: 5126,
  FLOAT_VEC2: 35664,
  FLOAT_VEC3: 35665,
  FLOAT_VEC4: 35666,
  BOOL: 35670
};

const primitiveMode = {
  POINTS: 0,
  LINES: 1,
  LINE_LOOP: 2,
  LINE_STRIP: 3,
  TRIANGLES: 4
};

const gltfExtensions =
{
  cesiumRTC: 'CESIUM_RTC',
  khrTechniqueWebGL: 'KHR_techniques_webgl'
};

const shaderInfo =
    {
      slopeMap:
          {
            gradientTexture: 'grad-slope.png',
          },
      heightMap:
          {
            gradientTexture: 'grad-heightmap.png',
          },
      default:
          {
            shaderUrl: {
              fragmentShader: 'defaultFS.glsl',
              vertexShader: 'defaultVS.glsl',
            },
            indexInModelFile: null
          },
      cutFill:
          {
            shaderUrl: {
              fragmentShader: 'cutFillMapFS.glsl',
              vertexShader: 'cutFillMapVS.glsl'
            },
            indexInModelFile: null
          },
      fill:
          {
            shaderUrl: {
              fragmentShader: 'fillFS.glsl',
              vertexShader: 'cutFillMapVS.glsl'
            },
            indexInModelFile: null
          },
      cut:
          {
            shaderUrl: {
              fragmentShader: 'cutFS.glsl',
              vertexShader: 'cutFillMapVS.glsl'
            },
            indexInModelFile: null
          },
      points_lines:
          {
            shaderUrl: {
              fragmentShader: 'defaultFS_points_lines.glsl',
              vertexShader: 'defaultVS_points_lines.glsl'
            },
            indexInModelFile: null
          },

    };

const details =
  {
    addExtension(gltfData, extension) {
      if (!gltfData) { return; }
      const extensionName = gltfExtensions[extension];
      if (!extensionName) {
        // console.warn('AddExtension: no extension with such name: %s', extensionName);
        return;
      }
      const extensionArray = gltfData.extensionsUsed;
      if (!extensionArray) {
        gltfData.extensionsUsed = [extensionName];
        return;
      }
      const elem = extensionArray.find(name => name === extensionName);
      if (!elem) {
        extensionArray.push(extensionName);
      }
    },

    addElement(gltfData, name, value) {
      if (!gltfData[name]) {
        gltfData[name] = [value];
      } else {
        gltfData[name].push(value);
      }
      return gltfData[name].length - 1;
    },

    addTexture(gltfData, imageUrl, indexSampler) {
      const imageValue =
      {
        uri: imageUrl
      };
      const indexImage = this.addElement(gltfData, 'images', imageValue);
      const textureValue =
      {
        sampler: indexSampler,
        source: indexImage
      };
      return this.addElement(gltfData, 'textures', textureValue);
    },

    addDefaultSampler(gltfData) {
      const defaultSamplerValue =
      {
        magFilter: 9729, // Linear
        minFilter: 9987, // Linear mip map
        wrapS: 33071, // Clamp to edges
        wrapT: 33071
      };
      return this.addElement(gltfData, 'samplers', defaultSamplerValue);
    },

    getDefaultShaderUniforms(gltfData, measurementSystem, minMaxHeight) {
      const extraUniforms = [
        { name: 'u_defaultColor', value: mapConstants.shaderDefault.surfaceColor, type: 'FLOAT_VEC4' },
        { name: 'u_useContour', value: mapConstants.shaderDefault.useContour, type: 'BOOL' },
        { name: 'u_useSlopeMap', value: false, type: 'BOOL' },
        { name: 'u_useHeightMap', value: false, type: 'BOOL' },
        { name: 'u_toleranceValue', value: convert(mapConstants.shaderDefault.contourDefault.tolerance).from(measurementSystem.baseLengthUnit).to('m'), type: 'FLOAT' },
        { name: 'u_rampValue', value: convert(mapConstants.shaderDefault.contourDefault.rampValue).from(measurementSystem.baseLengthUnit).to('m'), type: 'FLOAT' },
        { name: 'u_stepSize', value: convert(mapConstants.shaderDefault.contourDefault.stepSize).from(measurementSystem.baseLengthUnit).to('m'), type: 'FLOAT' },
        { name: 'u_contourColor', value: mapConstants.shaderDefault.contourDefault.contourColor, type: 'FLOAT_VEC4' }];
      if (minMaxHeight) extraUniforms.push({ name: 'u_range', value: minMaxHeight, type: 'FLOAT_VEC2' });
      return extraUniforms;
    },
    addDefaultShader(gltfData, samplerIndex, measurementSystem, minMaxHeight) {
      const slopeGradUrl = shaderInfo.slopeMap.gradientTexture;
      const slopeTexId = this.addTexture(gltfData, slopeGradUrl, samplerIndex);
      const heightGradUrl = shaderInfo.heightMap.gradientTexture;
      const heightTexId = this.addTexture(gltfData, heightGradUrl, samplerIndex);
      const textures = [
        { name: 'u_slopeMap', id: slopeTexId },
        { name: 'u_heightMap', id: heightTexId }
      ];
      const extraUniforms = this.getDefaultShaderUniforms(gltfData, measurementSystem, minMaxHeight);
      const defaultMaterialIndex = this.addShader(gltfData, 'DefaultSurface', {
        fragmentShader: shaderInfo.default.shaderUrl.fragmentShader,
        vertexShader: shaderInfo.default.shaderUrl.vertexShader,
        textures,
        extraUniforms
      });
      shaderInfo.default.indexInModelFile = defaultMaterialIndex;
    },
    addPointsLinesShader(gltfData) {
      const extraUniforms = [{
        name: 'u_defaultColor',
        value: mapConstants.shaderDefault.linesColor,
        type: 'FLOAT_VEC4'
      }];
      const defaultMaterialIndex = this.addShader(gltfData, 'DefaultPointsLine', {
        fragmentShader: shaderInfo.points_lines.shaderUrl.fragmentShader,
        vertexShader: shaderInfo.points_lines.shaderUrl.vertexShader,
        extraUniforms
      });
      shaderInfo.points_lines.indexInModelFile = defaultMaterialIndex;
    },
    addFillShader(gltfData, nodeName) {
      const defaultMaterialIndex = this.addShader(gltfData, 'Fill', {
        fragmentShader: shaderInfo.fill.shaderUrl.fragmentShader,
        vertexShader: shaderInfo.fill.shaderUrl.vertexShader,
        nodeName
      });
      shaderInfo.fill.indexInModelFile = defaultMaterialIndex;
    },
    addCutShader(gltfData, nodeName) {
      const defaultMaterialIndex = this.addShader(gltfData, 'Cut', {
        fragmentShader: shaderInfo.cut.shaderUrl.fragmentShader,
        vertexShader: shaderInfo.cut.shaderUrl.vertexShader,
        nodeName
      });
      shaderInfo.cut.indexInModelFile = defaultMaterialIndex;
    },
    getCutFillShaderUnitforms() {
      const uniforms = [
        { name: 'u_tolerance', value: mapConstants.cutFillDefault.tolerance, type: 'FLOAT' },
        { name: 'u_gradeColor', value: mapConstants.cutFillDefault.gradeColor, type: 'FLOAT_VEC4' },
        { name: 'u_cutColor', value: mapConstants.cutFillDefault.cutColor, type: 'FLOAT_VEC4' },
        { name: 'u_fillColor', value: mapConstants.cutFillDefault.fillColor, type: 'FLOAT_VEC4' }];
      return uniforms;
    },
    addCutFillShader(gltfData) {
      const extraUniforms = this.getCutFillShaderUnitforms();
      const heightMaterialIndex = this.addShader(gltfData, 'Fill', {
        fragmentShader: shaderInfo.cutFill.shaderUrl.fragmentShader,
        vertexShader: shaderInfo.cutFill.shaderUrl.vertexShader,
        extraUniforms
      });
      shaderInfo.cutFill.indexInModelFile = heightMaterialIndex;
    },
    checkBB(gltfData) { // workaround as Cesium does not allow to have models with all position accessors min max equal
      const arrayAccessorBoundingBox = [];
      if (!gltfData.meshes) { return; }
      // For each mesh we get the accessor's index holding the attributename
      gltfData.meshes.forEach((mesh) => {
        if (mesh.primitives[0].attributes.POSITION !== undefined) { // we only support one primitive
          const indexAttribute = mesh.primitives[0].attributes.POSITION;
          arrayAccessorBoundingBox.push(indexAttribute);
        }
      });
      const oneMillimeter = 0.001;
      const numIndices = arrayAccessorBoundingBox.length;
      // eslint-disable-next-line no-unreachable-loop
      for (let index = 0; index < numIndices; index += 1) {
        const accessorIndex = arrayAccessorBoundingBox[index];
        const minBB = gltfData.accessors[accessorIndex].min;
        const maxBB = gltfData.accessors[accessorIndex].max;
        if (minBB[0] === maxBB[0] &&
            minBB[1] === maxBB[1] &&
            minBB[2] === maxBB[2]) {
          // if the min max are equal we displace the mx by one millimeter
          gltfData.accessors[accessorIndex].max[0] = gltfData.accessors[accessorIndex].min[0] + oneMillimeter;
          return;
        } return;
      }
    },
    // The Polaris gltf exporter passes [Point.H, Normal.H] in gltf file as texcoord_0 attribute
    // so we need to fetch these values
    getDefaultTechnique(programIndex, isNorthenHemisphere) {
      const technique = {
        program: programIndex,
        attributes: {
          a_normal: {
            semantic: 'NORMAL'
          },
          a_position: {
            semantic: 'POSITION'
          },
          a_texcoord0: {
            semantic: 'TEXCOORD_0'
          }
        },
        uniforms: {
          u_light0Transform: {
            semantic: 'CESIUM_RTC_MODELVIEW',
            node: 1,
            type: 35676
          },
          u_modelViewMatrix: {
            semantic: 'CESIUM_RTC_MODELVIEW', // Model view extension associated to RTC extension
            type: 35676
          },
          u_normalMatrix: {
            semantic: 'MODELVIEWINVERSETRANSPOSE',
            type: 35676
          },
          u_projectionMatrix: {
            semantic: 'PROJECTION',
            type: 35676
          },
          u_northenHemisphere: {
            value: isNorthenHemisphere,
            type: gltfUniformType.BOOL
          }
        }
      };
      return technique;
    },

    addTechnique(gltfData, technique, textures) {
      if (textures && textures.length) {
        textures.forEach((texture) => {
          const textureName = texture.name;
          technique.uniforms[textureName] = {
            type: 35678,
            value: {
              index: texture.id // texture
            }
          };
        });
      }
      const techniqueIndex =
      this.addElement(gltfData.extensions.KHR_techniques_webgl, 'techniques', technique);
      return techniqueIndex;
    },

    addProgramShader(gltfData, vertexShader, fragmentShader) {
      const fsShader =
        {
          type: 35632,
          uri: fragmentShader
        };
      const vsShader =
      {
        type: 35633,
        uri: vertexShader
      };
      const fsShaderIndex =
        this.addElement(gltfData.extensions.KHR_techniques_webgl, 'shaders', fsShader);
      const vsShaderIndex =
        this.addElement(gltfData.extensions.KHR_techniques_webgl, 'shaders', vsShader);
      const program =
        {
          fragmentShader: fsShaderIndex,
          vertexShader: vsShaderIndex
        };
      const programIndex =
        this.addElement(gltfData.extensions.KHR_techniques_webgl, 'programs', program);
      return programIndex;
    },
    initKHRExtensionDictionary(gltfData) {
      if (!gltfData.extensions.KHR_techniques_webgl) {
        gltfData.extensions.KHR_techniques_webgl = {
          programs: [],
          shaders: [],
          techniques: []
        };
      }
    },

    addExtraUniforms(technique, extraUniforms) {
      const newUniforms = [];
      if (extraUniforms) {
        extraUniforms.forEach((uniform) => {
          if (!!uniform.type && !!gltfUniformType[uniform.type] && !!uniform.name && uniform.value !== undefined) {
            const uniformName = uniform.name;
            const uniformType = gltfUniformType[uniform.type];
            const uniformValue = uniform.value;
            technique.uniforms[uniformName] = {
              type: uniformType,
              value: uniformValue
            };
            newUniforms.push({ uniformValue, uniformName });
          }
        });
      }
      return newUniforms;
    },

    addNewMaterial(gltfData, name, newUniforms, techniqueIndex) {
      const material =
          {
            name,
            alphaMode: 'BLEND',
            extensions: {
              KHR_techniques_webgl: {
                technique: techniqueIndex,
                values: {}
              }
            }
          };
      newUniforms.forEach((uniform) => {
        material.extensions.KHR_techniques_webgl.values[uniform.uniformName] = uniform.uniformValue;
      });
      const materialIndex = this.addElement(gltfData, 'materials', material);
      return materialIndex;
    },

    addShader(gltfData, materialName, {
      vertexShader, fragmentShader, textures, extraUniforms
    }) {
      this.initKHRExtensionDictionary(gltfData);
      const programIndex = this.addProgramShader(gltfData, vertexShader, fragmentShader);
      const isNorthenHemisphere = gltfData.extensions.CESIUM_RTC.center[2] > 0.0;
      const technique = this.getDefaultTechnique(programIndex, isNorthenHemisphere);
      const techniqueIndex = this.addTechnique(gltfData, technique, textures);
      const newUniforms = this.addExtraUniforms(technique, extraUniforms);
      return this.addNewMaterial(gltfData, materialName, newUniforms, techniqueIndex);
    },

    setMaterial(gltfData, materialIndex, { nodeName, primitiveMode_ }) {
      gltfData.meshes.forEach((mesh) => {
        if (!nodeName || mesh.name === nodeName) {
          mesh.primitives.forEach((primitive) => {
            if (primitive.mode === primitiveMode_) { primitive.material = materialIndex; }
          });
        }
      });
    },

    initDefaultMaterials(gltfData) {
      details.setMaterial(
        gltfData,
        shaderInfo.default.indexInModelFile,
        { primitiveMode_: primitiveMode.TRIANGLES }
      );
      details.setMaterial(
        gltfData,
        shaderInfo.points_lines.indexInModelFile,
        { primitiveMode_: primitiveMode.POINTS }
      );
      details.setMaterial(
        gltfData,
        shaderInfo.points_lines.indexInModelFile,
        { primitiveMode_: primitiveMode.LINE_STRIP }
      );
    }
  };

export const gltfUtils = {
  /**
   * This function initializes ref model by add slope map a default shader a height map shader,
   * adding as well extensions required by cesium to display our models
   * @param gltfData
   */
  initReferenceModel(gltfData, measurementSystem, minMaxHeight) {
    // First addExtensions
    details.addExtension(gltfData, 'cesiumRTC');
    details.addExtension(gltfData, 'khrTechniqueWebGL');
    gltfData.materials = []; // workaround for conflict khr_webgl and pbr
    const samplerIndex = details.addDefaultSampler(gltfData);
    details.checkBB(gltfData); // workaround to address the fact Cesium needs gltf models with min max attribute that are not equal
    details.addDefaultShader(gltfData, samplerIndex, measurementSystem, minMaxHeight);
    details.addPointsLinesShader(gltfData);
    details.initDefaultMaterials(gltfData);
  },

  initDiffModel(gltfData) {
    details.addExtension(gltfData, 'cesiumRTC');
    details.addExtension(gltfData, 'khrTechniqueWebGL');
    gltfData.materials = []; // workaround for conflict khr_webgl and pbr
    details.addCutFillShader(gltfData);
    details.setMaterial(gltfData, shaderInfo.cutFill.indexInModelFile, { primitiveMode: primitiveMode.TRIANGLES });
  },

  initCutFillModel(gltfData) {
    details.addExtension(gltfData, 'cesiumRTC');
    details.addExtension(gltfData, 'khrTechniqueWebGL');
    gltfData.materials = []; // workaround for conflict khr_webgl and pbr
    details.addFillShader(gltfData);
    details.addCutShader(gltfData);
    details.setMaterial(gltfData, shaderInfo.fill.indexInModelFile, {
      primitiveMode_: primitiveMode.TRIANGLES,
      nodeName: 'Fill'
    });
    details.setMaterial(gltfData, shaderInfo.cut.indexInModelFile, {
      primitiveMode_: primitiveMode.TRIANGLES,
      nodeName: 'Cut_Fill_Map'
    });
  },

  initAsBuiltSurface(gltfData) {
    details.addExtension(gltfData, 'cesiumRTC');
  },
};