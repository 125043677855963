<template>
  <div
    class="cx-details-panel"
    :class="applyClass"
    v-if="projectInitialized">
    <transition :name="applyAnimations('handle')">
      <cx-details-panel-handle
        v-show="selectedEntity"
        :isOpened="isDetailsPanelOpened"
        :isReduced="isDetailsPanelReduced"
        :onClickHandle="setIsDetailsPanelOpened"/>
    </transition>
    <transition :name="applyAnimations('content')">
      <div
        class="cx-details-panel__content"
        :class="applyClass"
        v-if="isDetailsPanelOpened && selectedEntity">
        <component :is="detailsPanelType"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxDetailsPanelHandle from './CxDetailsPanelHandle';
import CxRefModelDetailsPanel from '@/app/detailsPanel/refmodel/CxRefModelDetailsPanel';
import CxUnitDetailsPanel from '@/app/detailsPanel/unit/CxUnitDetailsPanel';
import CxPointSetDetailsPanel from '@/app/detailsPanel/pointSet/CxPointSetDetailsPanel';
import CxMeasuredPointDetailsPanel from '@/app/detailsPanel/point/CxMeasuredPointDetailsPanel';
import CxHoleDetailsPanel from '@/app/detailsPanel/hole/CxHoleDetailsPanel';
import CxHoleSetDetailsPanel from '@/app/detailsPanel/holeSet/CxHoleSetDetailsPanel';
import CxDrillPatternDetailsPanel from '@/app/detailsPanel/drillPattern/CxDrillPatternDetailsPanel';
import CxAwarenessEventSetDetailsPanel from '@/app/detailsPanel/awareness/CxAwarenessEventSetDetailsPanel';
import CxAwarenessEventDetailsPanel from '@/app/detailsPanel/awareness/CxAwarenessEventDetailsPanel';
import CxSurfaceLogDetailsPanel from '@/app/detailsPanel/surfacelog/CxSurfaceLogDetailsPanel';

export default {
  name: 'CxDetailsPanel',
  components: {
    CxDetailsPanelHandle,
    CxRefModelDetailsPanel,
    CxUnitDetailsPanel,
    CxPointSetDetailsPanel,
    CxMeasuredPointDetailsPanel,
    CxHoleDetailsPanel,
    CxHoleSetDetailsPanel,
    CxDrillPatternDetailsPanel,
    CxAwarenessEventSetDetailsPanel,
    CxAwarenessEventDetailsPanel,
    CxSurfaceLogDetailsPanel
  },
  mixins: [CxAnimationMixin],
  computed: {
    ...mapGetters('shell', ['isDetailsPanelOpened', 'detailsPanelType', 'isDetailsPanelReduced']),
    ...mapGetters('app', ['projectInitialized']),
    ...mapGetters('map', ['selectedEntity']),
    applyClass() {
      return this.isDetailsPanelReduced ? 'cx-details-panel--reduced' : '';
    }
  },
  methods: {
    ...mapMutations('shell', ['setIsDetailsPanelOpened']),
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-details-panel {

  &.cx-details-panel--small {
    top: calc(100vh - @detailsReducedHeight - @detailsHandleOverlayMargin);
  }

  .cx-details-panel__content {
    .size(calc(100vw - @filterMenuWidth), @detailsHeight);
    z-index: @detailsZI;
    position: fixed;
    bottom: 0px;
    margin-left: @filterMenuWidth;

    &.cx-details-panel--reduced {
      height: @detailsReducedHeight;
    }
  }
}
</style>