<template>
  <div class="cx-object-explorer-body">
    <cx-object-explorer-header/>
    <div class="cx-object-explorer-body__content">
      <div class="cx-object-explorer-body__content__objects">
        <cx-object v-for="object in objects" :key="`oe-${object.uuid}`" :object="object"/>
      </div>
    </div>
  </div>
</template>

<script>
import CxObject from './CxObject';
import CxObjectExplorerHeader from './CxObjectExplorerHeader';

export default {
  name: 'CxObjectExplorerBody',
  components: {
    CxObjectExplorerHeader,
    CxObject
  },
  props: {
    objects: Array
  },
};
</script>

<style lang="less">
    @import '../../common';

    .cx-object-explorer-body {
        max-height: 82vh;
        margin-top: 8px;
        pointer-events: auto;
        border-radius: @inputBorderRadius;
        background-color: @objectExplorerOpenedBGColor;
        background-size: cover;
        .flex(column, flex-start, stretch);
        display: inline-flex;

        .cx-object-explorer-header {
          .size(100%, auto);
          padding: 10px 7px;
        }

        .cx-object-explorer-body__content {
            overflow-y: scroll;
            overflow-x: hidden;
            margin: 5px 0 15px;
            padding: 0 10px;

            .cx-object-explorer-body__content__objects {
              .flex(column, flex-start, stretch);
            }
        }
    }
</style>
