import { flatMap } from 'lodash';
import api from '@/http/api';
import { getFields, getStabilizationFields } from '@/utils/pointFieldLicenceHelper';
import saveStateService from './save.sate.service';
import { withColor } from '@/domain/entityMixins';
import { parseISO } from 'date-fns';
import { createSurfacelog } from '@/domain/surfacelog/SurfacelogFactory.ts';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const restorePointSetHelper = async (context, pointSet, parentUUidMixin) => {
  const resultSet = await api.filterMeasurePoints(
    context.rootGetters.projectUuid,
    pointSet.originTimeRange.from,
    pointSet.originTimeRange.to,
    pointSet.originFilters,
    true,
    getFields()
  );
  if (!resultSet.count) throw new Error('Empty point set - could not restore');
  if (pointSet.parentUuid) {
    // TODO: we assume that if a point set has a parent
    // it must be a point set for a drillpattern
    // and so we need to add color to fetched points
    // We will need to refacor this together with map entities handling
    // and find a coherent way to hadle child entities and asbuilds colors
    resultSet.points = resultSet.points.map(withColor);
  }
  return parentUUidMixin(resultSet);
};


const restoreHoleSetHelper = async (context, holeSet, parentUUidMixin) => {
  const restoredHoleSet = await api.filterHoles(
    context.rootGetters.projectUuid,
    holeSet.originTimeRange.from,
    holeSet.originTimeRange.to,
    holeSet.originFilters,
    true,
    getStabilizationFields()
  );
  if (!restoredHoleSet.count) throw new Error('Empty hole set - could not restore');
  return parentUUidMixin(restoredHoleSet);
};

const restoreAwarenessEventSetHelper = async (context, eventSet, parentUUidMixin) => {
  const restoredEventSet = await api.filterAwarenessEvents(
    context.rootGetters.projectUuid,
    eventSet.originTimeRange.from,
    eventSet.originTimeRange.to,
    eventSet.originFilters,
    ['all']
  );
  if (!restoredEventSet.count) throw new Error('Empty awareness event set - could not restore');
  restoredEventSet.uuid = eventSet.uuid;
  return parentUUidMixin(restoredEventSet);
};

const restoreSurfaceLog = async (context, surfaceLog, parentUUidMixin) => {
  const { projectUuid } = context.rootGetters;
  const { refmodels, units, unitTypes, pointCodes } = surfaceLog.originFilters;
  const { from, to } = surfaceLog.originTimeRange;
  const { cellSize, lengthUnit } = surfaceLog;
  const result = await api.filterSurface(
    projectUuid,
    refmodels,
    parseISO(from),
    parseISO(to),
    units,
    pointCodes
  );
  const restoredSurfacelog = result ? createSurfacelog({
    name: surfaceLog.name,
    refmodelPaths: refmodels,
    pointCodes,
    startDate: parseISO(from),
    endDate: parseISO(to),
    units,
    unitTypes,
    uuid: surfaceLog.uuid,
    deltaHeightLegend: surfaceLog.deltaHeightLegend,
    cellSize,
    lengthUnit
  }) : null;
  context.commit('detailsSurfacelog/setDeltaHeightLegend', { layerUuid: surfaceLog.uuid, legend: surfaceLog.deltaHeightLegend }, { root: true });

  return parentUUidMixin(restoredSurfacelog);
};

const mapPersistentState = {
  getters: {
    /**
     * Return object representing state of entities on the map
     * @param {} state
     * @param {*} getters
     */
    mapState(state, getters) {
      const manageableEntities = [...state.loadedUnits, ...state.loadedRefmodels, ...getters.persistentSets, ...getters.pesistentDHSets, ...getters.loadedAwarenessEventSets, ...getters.loadedSurfacelogs];
      const children = flatMap(flatMap(Object.keys(state.parentEnityMap), k => state.parentEnityMap[k]), o => o);
      const topLevelEntities = manageableEntities.filter(e => !children.includes(e.uuid));
      const oeEntities = [];
      topLevelEntities.forEach((entity) => {
        oeEntities.push({
          uuid: entity.uuid,
          name: entity.name,
          entityType: entity.entityType,
          originFilters: entity.originFilters,
          originTimeRange: entity.originTimeRange,
          parentUuid: null
        });

        if (entity.entityType === SurfacelogType) {
          const surfacelogOeEntity = oeEntities.find(o => o.uuid === entity.uuid);
          surfacelogOeEntity.deltaHeightLegend = entity.deltaHeightLegend;
          surfacelogOeEntity.cellSize = entity.cellSize;
          surfacelogOeEntity.lengthUnit = entity.lengthUnit;
        }

        const entityChildren = state.parentEnityMap[entity.uuid] || [];
        entityChildren.forEach((childUid) => {
          const childEntity = manageableEntities.find(e => e.uuid === childUid);
          if (childEntity) {
            oeEntities.push({
              uuid: childEntity.uuid,
              name: childEntity.name,
              entityType: childEntity.entityType,
              originFilters: childEntity.originFilters,
              originTimeRange: childEntity.originTimeRange,
              parentUuid: entity.uuid
            });
          }
        });
      });
      const { hiddenEntities } = getters;
      const { selectedEntity } = getters;
      const selected = selectedEntity ? { uuid: selectedEntity.uuid, entityType: selectedEntity.entityType } : null;
      return { oeEntities, hiddenEntities, selectedEntity: selected };
    },
  },
  actions: {
    /**
     * Restores state of entities on the map
     * @param {} context
     * @param {Object} mapState Object object representing state of entities on the map
     * {      uuid: childEntity.uuid,
              name: entity.name,
              entityType: childEntity.entityType,
              originFilters: childEntity.originFilters,
              originTimeRange: childEntity.originTimeRange,
              parentUuid: entity.uuid
            }
     */
    async restoreMapState(context) {
      try {
        const mapState = saveStateService.restoreAppState(context.rootGetters.projectUuid);
        if (!mapState || !mapState.oeEntities) return undefined;
        // restore top level entities
        const topLevelEntities = mapState.oeEntities.filter(e => !e.parentUuid);
        await context.dispatch('restoreEntities', { mapState, entities: topLevelEntities });
        // restore children
        const childEntities = mapState.oeEntities.filter(e => e.parentUuid);
        await context.dispatch('restoreEntities', { mapState, entities: childEntities });
        // restore selected entity
        if (mapState.selectedEntity) context.dispatch('setEntitySelected', { uuid: mapState.selectedEntity.uuid, type: mapState.selectedEntity.entityType });
      } catch (error) {
        const message = $gettext('There was an error while restoring application state');
        context.dispatch('notifications/error', { error, message }, { root: true });
      }
    },
    async restoreEntities(context, { mapState, entities }) {
      if (!entities || !entities.length) return;

      // entnties that were not found in the current project
      const missingEntities = [];
      // entnties found in the current project, that will be restored
      const entitiesToRestore = [];
      // array to keep promises for fetching point sets and hole sets
      const promises = [];

      // helpers
      const addParentUuid = (entity, parentUuid) => {
        if (!entity) return null;
        return { entity, parentUuid };
      };
      const addParentUuidMixin = (parentUuid) => {
        return (entity) => addParentUuid(entity, parentUuid);
      };
      const collectMissingEntities = (entity, foundEntity) => {
        if (!foundEntity) missingEntities.push({ name: entity.name, type: entity.entityType });
        else entitiesToRestore.push(foundEntity);
      };

      // dependant on entity type - try to find it in store or fetch data
      entities.forEach((entity) => {
        if (Object.values(RefmodelTypes).includes(entity.entityType) || Object.values(UnitTypes).includes(entity.entityType)) collectMissingEntities(entity, addParentUuid(context.rootGetters['app/entities'].find(u => u.uuid === entity.uuid), entity.parentUuid));
        if (entity.entityType === PointSetType) promises.push(restorePointSetHelper(context, entity, addParentUuidMixin(entity.parentUuid)));
        if (entity.entityType === DrillHoleSetType) promises.push(restoreHoleSetHelper(context, entity, addParentUuidMixin(entity.parentUuid)));
        if (entity.entityType === AwarenessEventSetType) promises.push(restoreAwarenessEventSetHelper(context, entity, addParentUuidMixin(entity.parentUuid)));
        if (entity.entityType === SurfacelogType) promises.push(restoreSurfaceLog(context, entity, addParentUuidMixin(entity.parentUuid)));
      });
      // handle missing entities - warning
      if (missingEntities.length) {
        const message = `${$gettext('Unable to restore objects:')} ${missingEntities.map(v => v.name).join(', ')}`;
        context.dispatch('notifications/warning', { message }, { root: true });
      }

      // wait until all sets are fetched and store results
      const setResults = await Promise.all(promises.map(p => p.catch(e => { return { status: 'rejected', error: e }; })));
      setResults.filter(r => r.status !== 'rejected').forEach(r => entitiesToRestore.push(r));
      // notify about set fetching errors
      if (setResults.some(r => r.status === 'rejected')) {
        const message = $gettext('Unable to restore sets - there was an error while fetching data');
        context.dispatch('notifications/error', { message }, { root: true });
      }

      // restore all found entities
      const resultPromises = entitiesToRestore.map(e => { return context.dispatch('addEntities', { entities: [e.entity], parentUid: e.parentUuid, options: { showOnMap: !mapState.hiddenEntities.includes(e.entity.uuid), restore: true, useContextColor: true } }); });
      const addEntitiesResults = await Promise.all(resultPromises.map(p => p.catch(e => { return { status: 'rejected', error: e }; })));
      // handle restore errors
      if (addEntitiesResults.filter(e => e).some(r => r.status === 'rejected')) {
        const message = $gettext('There was an error while restoring application state');
        context.dispatch('notifications/error', { message }, { root: true });
      }
    }
  },
};

export default mapPersistentState;