import api from '@/http/api';
import { convert } from '@/utils/measurementUnitHelper';
import licenceService from '@/services/licence.service';
import { SurfacelogSignalSldStyle } from '@/domain/surfacelog/SurfacelogSignalSldStyle.enum';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';

const mapSurfacelogStore = {
  state: {
    loadedSurfacelogs: [],
    hiddenSurfacelogs: [],
    cellSizesTooledge: { m: [0.25, 0.5, 1], ft: [1, 2, 3] },
    exportExtensions: [{ name: 'LandXML', value: 'landxml' }, { name: 'GeoTIFF', value: 'geotiff' }]
  },
  getters: {
    cellSizesTooledge(state) {
      const sizes = state.cellSizesTooledge;
      return sizes;
    },
    loadedSurfacelogs(state) {
      return state.loadedSurfacelogs;
    },
    hiddenSurfacelogs(state) {
      return state.hiddenSurfacelogs;
    },
    exportExtensions(state) {
      return state.exportExtensions;
    }
  },
  mutations: {
    addSurfacelogs(state, surfacelogs) {
      state.loadedSurfacelogs = [...state.loadedSurfacelogs, ...surfacelogs];
    },
    updateSurfacelog(state, { surfacelogId, wmsUrl, signals }) {
      const surfacelog = state.loadedSurfacelogs.find(e => e.uuid === surfacelogId);
      const index = state.loadedSurfacelogs.indexOf(surfacelog);
      state.loadedSurfacelogs[index] = { ...surfacelog, wmsUrl, availableSignals: Object.keys(signals), signalsKPI: signals };
      state.loadedSurfacelogs = [...state.loadedSurfacelogs];
    },
    removeSurfacelogSignals(state, surfacelogId) {
      const surfacelog = state.loadedSurfacelogs.find(e => e.uuid === surfacelogId);
      const index = state.loadedSurfacelogs.indexOf(surfacelog);
      state.loadedSurfacelogs[index] = { ...surfacelog, wmsUrl: null, availableSignals: [], signalsKPI: {} };
      state.loadedSurfacelogs = [...state.loadedSurfacelogs];
    },
    removeSurfaceLog(state, uuid) {
      state.loadedSurfacelogs = state.loadedSurfacelogs.filter(r => r.uuid !== uuid);
    },
    hideSurfaceLog(state, uuid) {
      state.hiddenSurfacelogs.push(uuid);
    },
    hideSurfaceLogs(state, uuids) {
      state.hiddenSurfacelogs = [...state.hiddenSurfacelogs, ...uuids];
    },
    showSurfaceLog(state, uuid) {
      state.hiddenSurfacelogs = state.hiddenSurfacelogs.filter(r => r.uuid === uuid);
    },
    showSurfaceLogs(state, uuids) {
      state.hiddenSurfacelogs = state.hiddenSurfacelogs.filter(uuid => !uuids.includes(uuid));
    }
  },

  actions: {
    // -------------------- surfacelogs ----------------------------
    /**
         * @param {*} context
         * @param {*} entities SurfaceLogs to load
         * @returns {Boolean} true if all entities has been loaded sucesfully. False otherwise
         */
    async loadSurfaceLogs(context, { entities, showOnMap }) {
      const surfacelog = entities[0];
      const convertedCellSize = convert(surfacelog.cellSize).withPrecision(2).from(context.rootGetters['app/measurementSystem'].baseLengthUnit.name).to('m');
      const result = await api.getSurfacelogVisualization(context.rootGetters.projectUuid, surfacelog.startDate, surfacelog.endDate, surfacelog.refmodelPaths, surfacelog.pointCodes, convertedCellSize, surfacelog.units);
      const wmsUrl = result.url;
      const signalsKPI = result.signals;
      surfacelog.availableSignals = (licenceService.hasEarthmovingLicence()) ? Object.keys(signalsKPI) : [];
      const singleRefModel = surfacelog.refmodelPaths.length === 1;
      const hasPointCodes = surfacelog.pointCodes.length !== 0;
      const isOutsideRefModelAvailable = !hasPointCodes && singleRefModel && surfacelog.availableSignals.some(e => e === SurfacelogSignalTypes.OutsideReferenceModel) && surfacelog.refmodelPaths.length !== 1;
      surfacelog.signalsKPI = signalsKPI;
      surfacelog.wmsUrl = wmsUrl;
      let signal = null;
      let styleSLD = null;
      if (licenceService.hasEarthmovingLicence()) {
        signal = SurfacelogSignalTypes.Height;
        styleSLD = SurfacelogSignalSldStyle.H;
      } else {
        signal = SurfacelogSignalTypes.NoLicence;
        styleSLD = SurfacelogSignalSldStyle.NoLicence;
      }
      await context.dispatch('viewer/addSurfacelogLayer', { layerUuid: surfacelog.uuid, url: wmsUrl, showOnMap, signal, signalsKPI, styleSLD, isOutsideRefModelVisible: isOutsideRefModelAvailable, deltaHeightLegend: surfacelog.deltaHeightLegend });
      context.commit('addSurfacelogs', entities);
      if (!showOnMap) context.commit('hideSurfaceLogs', entities.map(r => r.uuid));
    },
    /**
         * Removes surfacelog from the loaded list
         * If was selected, it is deselected
         * If was in the hidden list, it is removed from there
         * @param {Object} entity surfacelog to deselect
         */
    async unloadSurfaceLog(context, entity) {
      // remove from loadedSurfacelogs
      context.commit('removeSurfaceLog', entity.uuid);
      // remove from hidden if so
      context.commit('showSurfaceLog', entity.uuid);
      // remove from selected and hide them
      context.dispatch('viewer/removeSurfacelogLayer', { layerUuid: entity.uuid });
      // remove from detailsSurfacelog store
      context.commit('detailsSurfacelog/removeSurfacePanelState', entity.uuid, { root: true });

    },

    /**
         * Displays surfacelogs from the list
         * and displays an alert if one or more of logs cant be rendered
         * if SurfaceLogList is empty all surfacelogs
         * @param {Array} SurfaceLogList list of surfacelogs to display
         * @param {Boolean} syncObjectExplorer if true, hiddenSurfacelogs will be updated
         * @param {Boolean} useSelected if true SurfaceLogList param is ignored, and the action is
         * executed on the selected surfacelog if available
         */
    async mapShowSurfaceLogs(context, { surfaceLogList, syncObjectExplorer = true, useSelected = false }) {
      let surfaceLogs = surfaceLogList;
      if (useSelected === true
        && context.getters.selectedEntity
        && SurfacelogType === context.getters.selectedEntity.entityType) {
        surfaceLogs = [context.getters.selectedEntity];
      }
      if (syncObjectExplorer) {
        context.commit('showSurfaceLogs', surfaceLogs.map(r => r.uuid));
      }
      context.dispatch('viewer/toggleSurfacelogLayer', { layerUuid: surfaceLogList[0].uuid, showOnMap: true });
    },
    /**
         * Hides all SurfaceLogs from the list and updated the hiddenSurfacelogs variable
         * @param {Array} SurfaceLogList list of rfmodels to hide
         * @param {Boolean} syncObjectExplorer if true, hiddenSurfacelogs list will be updated
         * @param {Boolean} useSelected if true SurfaceLogList param is ignored, and the action is
         * executed on the selected surfacelog if available
         */
    async mapHideSurfaceLogs(context, { surfaceLogList, syncObjectExplorer = true, useSelected = false }) {
      let surfaceLogs = surfaceLogList || [];
      if (useSelected === true
        && context.getters.selectedEntity
        && SurfacelogType === context.getters.selectedEntity.entityType) {
        // eslint-disable-next-line no-unused-vars
        surfaceLogs = [context.getters.selectedEntity];
      }
      if (syncObjectExplorer) {
        context.commit('hideSurfaceLogs', surfaceLogList.map(r => r.uuid));
      }
      const surfaceLogsIds = surfaceLogs.map(m => m.uuid);
      context.dispatch('viewer/toggleSurfacelogLayer', { layerUuid: surfaceLogsIds[0], showOnMap: false });
    },
    async changeSurfaceLogSignal(context, { uuid, signal, styleSLD, isOutsideRefModelVisible, deltaHeightLegend }) {
      const { signalsKPI, wmsUrl } = context.getters.loadedSurfacelogs.find(e => e.uuid === uuid);
      context.dispatch('viewer/changeSurfacelogSignal', { layerUuid: uuid, signal, styleSLD, isOutsideRefModelVisible, signalsKPI, wmsUrl, deltaHeightLegend });
    },

    /**
         * Checks if there is a selectedEntity and if the entity has same same id as given
         * it will be deselected
         * @param {*} id the id of a entity to deselect
         */
    async deselectSurfaceLogs(context, { uuids }) {
      if (context.state.selectedEntity && uuids.find(f => context.state.selectedEntity.uuid === f)) {
        await context.dispatch('viewer/deselect');
      }
    },
  }
};

export default mapSurfacelogStore;
