<template>
  <div class="cx-point-view">
    <transition :name="applyAnimations('filter1')">
      <div v-show="isFilterPanelOpened">
        <cx-tab-control
          :tabs="views"
          :onTabSelected="selectTab"
          :selectedTabId="selectedTabId">
        </cx-tab-control>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { filterPointPanelTypes } from '@/store/shellModule/shell.store';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin.js';
import CxTabControl from '@/components/CxTabControl';

export default {
  name: 'CxAsBuiltFilterContainer',
  components: {
    CxTabControl
  },
  mixins: [CxLicenceMixin, CxAnimationMixin, CxWaffleFlagMixin],
  computed: {
    ...mapGetters('shell', ['isFilterPanelOpened', 'filterPointPanelContent']),
    filterPointViews() {
      return [
        {
          id: '1',
          title: this.$gettext('Measured Points'),
          component: filterPointPanelTypes.point
        },
        {
          id: '2',
          title: this.$gettext('Surfaces'),
          component: filterPointPanelTypes.surface
        },
        {
          id: '3',
          title: this.$gettext('Holes'),
          component: filterPointPanelTypes.hole
        }
      ];
    },
    views() {
      let results = this.filterPointViews;
      if (!this.hasStabilizationLicence) results = results.filter(v => v.component !== filterPointPanelTypes.hole);
      return results;
    },
    selectedTabId() {
      return this.views.find((v) => { return this.filterPointPanelContent === v.component; }).id;
    }
  },
  methods: {
    ...mapMutations('shell', ['setFilterPointPanelContent']),
    selectTab(selectedTab) {
      this.setFilterPointPanelContent(selectedTab.component);
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-point-view {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: @inputSpace 10px 100px 0;
  font-size: @appFontSize;

  .cx-filter-results-wrapper {
    flex: 1 0;
    overflow: hidden;
  }

  .cx-point-view__title {
    .flex(row, flex-start, flex-start);
  }


  .cx-menu-button {
    margin: auto;
    margin-right: 0px;
  }

  .cx-select {
    min-height: @inputHeight;
  }
}
</style>