const mapTimelineStore = {
  actions: {
    clickTimelinePlay(context) {
      context.dispatch('animate', true);
    },
    clickTimelinePause(context) {
      context.dispatch('animate', false);
    },
    trackTimelineBarClick() {
      // Subscribed by ui-tracking
    }
  }
};

export default mapTimelineStore;