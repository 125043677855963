<template>
  <cx-overlay-controls>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.surfaceLog" icon="cx_points" :title="translations.surfaceLog"
        @toggle="toggleSurfacelogVisibility"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.outsideRefModel" icon="cx_outside_refmodel"
        :title="translations.outsideRefModel" @toggle="toggleOutsideReferenceModelVisibility"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group v-if="this.hasEarthmovingLicence">
      <cx-overlay-controls-item
        v-for="signal in availableSignals" :key="signal.name" :loading="signal.loading"
        :enabled="signal.enabled" :active="selectedEntity.signal == signal.symbol" :title="signal.title"
        @toggle="toggleSignal(signal.symbol)">
        <template #item-legend>
          <cx-scale-legend
            :scale="signal.scale" :gradientImage="signal.gradientImage"
            v-if="selectedEntity.signal == signal.symbol && signal.symbol !== 'dH'">
            <template #title>{{ signal.title }}</template>
            <template #unit>{{ signal.units }}</template>
          </cx-scale-legend>
          <cx-dh-scale-legend
            :scale="signal.scale"
            v-if="selectedEntity.signal == signal.symbol && signal.symbol === 'dH'">
            <template #title>{{ signal.title }}</template>
            <template #unit>{{ signal.units }}</template>
          </cx-dh-scale-legend>
        </template>
        {{ signal.symbol }}
      </cx-overlay-controls-item>
    </cx-overlay-controls-group>
  </cx-overlay-controls>
</template>

<script>
import CxOverlayControls from '@/app/detailsPanel/common/overlayControls/CxOverlayControls';
import CxOverlayControlsGroup from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsGroup';
import CxOverlayControlsItem from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsItem';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import { SurfacelogSignalSldStyle } from '@/domain/surfacelog/SurfacelogSignalSldStyle.enum';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { mapActions, mapGetters } from 'vuex';
import CxScaleLegend from './CxScaleLegend.vue';
import heightLegendImage from '../../../../public/controls/h-legend-surfacelog.png';
import deltaHeightLegendimage from '../../../../public/controls/dH-legend-surfacelog.png';
import PCLegendImage from '../../../../public/controls/PC-legend-surfacelog.png';
import CxDhScaleLegend from '@/app/detailsPanel/surfacelog/CxDhScaleLegend';

export default {
  name: 'CxSurfaceLogOverlayControls',
  components: {
    CxDhScaleLegend,
    CxOverlayControls,
    CxOverlayControlsGroup,
    CxOverlayControlsItem,
    CxScaleLegend
  },
  mixins: [CxLicenceMixin],
  props: {
    selectedSurfaceLogUuid: {
      type: String,
    },
    isORMAvailable: {
      type: Boolean,
    },
    isDeltaHeightAvailable: {
      type: Boolean,
    }
  },
  data() {
    return {
      surfacelogLoading: false,
      outsideRefModelLoading: false,
      isOutsideRefModelActive: true,
      heightLoading: false,
      deltaHeightLoading: false,
      isHeightDisplayed: false,
      currentSignalDisplayed: SurfacelogSignalTypes.DeltaHeight,
      heightLegendImage,
      deltaHeightLegendimage,
      PCLegendImage
    };
  },
  computed: {
    ...mapGetters('map', ['hiddenEntities', 'selectedEntity']),
    ...mapGetters('app', ['measurementSystem']),
    ...mapGetters('detailsSurfacelog', ['getDeltaHeightLegend', 'isDeltaHeightCalculated']),
    translations() {
      return {
        surfaceLog: this.$gettext('Surface'),
        outsideRefModel: this.$gettext('Show/Hide area outside reference model'),
        deltaHeight: this.$gettext('Height difference'),
        height: this.$gettext('Height'),
        passcount: this.$gettext('Passcount')
      };
    },
    deselectedSignal() {
      return {
        signal: (this.hasEarthmovingLicence) ? SurfacelogSignalTypes.Default : SurfacelogSignalTypes.NoLicence,
        style: (this.hasEarthmovingLicence) ? SurfacelogSignalSldStyle.Default : SurfacelogSignalSldStyle.NoLicence
      };
    },
    isSurfacelogVisible() {
      return !this.hiddenEntities.some(e => e === this.selectedSurfaceLogUuid);
    },
    isOutsideRefModelAvailable() {
      return this.isORMAvailable && this.selectedEntity.availableSignals.includes(SurfacelogSignalTypes.OutsideReferenceModel);
    },
    heightLegend() {
      const rangemin = Math.round((this.selectedEntity.signalsKPI.H.min_value + Number.EPSILON) * 100) / 100;
      const rangemax = Math.round((this.selectedEntity.signalsKPI.H.max_value + Number.EPSILON) * 100) / 100;
      const heightLegendData = [];
      heightLegendData.push(this.tf(rangemax));
      heightLegendData.push(this.tf(rangemin + 3 * (rangemax - rangemin) / 4));
      heightLegendData.push(this.tf(rangemin + 2 * (rangemax - rangemin) / 4));
      heightLegendData.push(this.tf(rangemin + 1 * (rangemax - rangemin) / 4));
      heightLegendData.push(this.tf(rangemin));
      return heightLegendData;
    },
    deltaHeightLegend() {
      return this.getDeltaHeightLegend(this.selectedEntity.uuid);
    },
    passcountLegend() {
      // needs more UI inputs
      const signalindexOpts = {
        passcountTarget: 3,
        passcountExtra: 2,
      };
      const input = {
        passcount_target: signalindexOpts.passcountTarget,
        passcount_extra: signalindexOpts.passcountExtra,
      };

      const passcountLegendData = [];
      passcountLegendData.push(`${input.passcount_target + input.passcount_extra + 1}-`);
      if (input.passcount_extra === 1) {
        passcountLegendData.push(input.passcount_target + 1);
      } else if (input.passcount_extra > 1) {
        passcountLegendData.push(`${input.passcount_target + 1}-${input.passcount_target + input.passcount_extra}`);
      }
      passcountLegendData.push(input.passcount_target);
      if (input.passcount_target > 1) {
        passcountLegendData.push(input.passcount_target - 1);
      }

      if (input.passcount_target > 3) {
        passcountLegendData.push(`1 - ${input.passcount_target - 2}`);
      } else if (input.passcount_target === 3) {
        passcountLegendData.push('1');
      }
      return passcountLegendData;

    },
    overlayState() {
      return {
        surfaceLog: {
          enabled: true,
          loading: this.surfacelogLoading,
          active: this.isSurfacelogVisible
        },
        outsideRefModel: {
          enabled: this.isOutsideRefModelAvailable,
          loading: this.outsideRefModelLoading,
          active: this.selectedEntity.isOutsideRefModel
        },
        signals: [
          {
            name: 'Height',
            symbol: SurfacelogSignalTypes.Height,
            enabled: this.selectedEntity.availableSignals.includes(SurfacelogSignalTypes.Height),
            loading: false,
            title: this.translations.height,
            units: this.measurementSystem.baseLengthUnit.abbr(),
            scale: this.heightLegend,
            gradientImage: this.heightLegendImage
          },
          {
            name: 'DeltaHeight',
            symbol: SurfacelogSignalTypes.DeltaHeight,
            enabled: this.isDeltaHeightAvailable,
            loading: false,
            units: this.measurementSystem.baseLengthUnit.abbr(),
            title: this.translations.deltaHeight,
            scale: this.deltaHeightLegend,
            gradientImage: this.deltaHeightLegendimage
          },
          {
            name: 'Passcount',
            symbol: SurfacelogSignalTypes.Passcount,
            enabled: this.selectedEntity.availableSignals.includes(SurfacelogSignalTypes.Passcount),
            loading: false,
            units: 'n',
            title: this.translations.passcount,
            scale: this.passcountLegend,
            gradientImage: this.PCLegendImage
          },
        ]
      };
    },
    availableSignals() {
      return this.overlayState.signals;
    },
  },
  methods: {
    ...mapActions('map', ['setEntityVisibility', 'changeSurfaceLogSignal']),

    toggleSurfacelogVisibility() {
      this.setEntityVisibility({
        uuid: this.selectedSurfaceLogUuid,
        isVisible: !this.isSurfacelogVisible
      }).then(() => {
        this.surfacelogLoading = false;
      });
    },
    async toggleOutsideReferenceModelVisibility() {
      this.selectedEntity.isOutsideRefModel = !this.selectedEntity.isOutsideRefModel;
      this.outsideRefModelLoading = true;
      if (this.selectedEntity.signal !== null && this.hasEarthmovingLicence) {
        await this.changeSurfaceLogSignal({
          uuid: this.selectedSurfaceLogUuid,
          signal: this.selectedEntity.signal,
          styleSLD: SurfacelogSignalSldStyle[this.selectedEntity.signal.toUpperCase()],
          isOutsideRefModelVisible: this.selectedEntity.isOutsideRefModel,
          deltaHeightLegend: this.selectedEntity.deltaHeightLegend
        });
        this.outsideRefModelLoading = false;
      } else {
        await this.changeSurfaceLogSignal({ uuid: this.selectedSurfaceLogUuid, signal: this.deselectedSignal.signal, styleSLD: this.deselectedSignal.style, isOutsideRefModelVisible: this.selectedEntity.isOutsideRefModel, deltaHeightLegend: this.selectedEntity.deltaHeightLegend });
        this.outsideRefModelLoading = false;
      }
    },
    async toggleSignal(selected) {
      if (this.selectedEntity.signal !== selected) {
        this.selectedEntity.signal = selected;
        await this.changeSurfaceLogSignal({ uuid: this.selectedSurfaceLogUuid, signal: selected, styleSLD: selected, isOutsideRefModelVisible: this.selectedEntity.isOutsideRefModel, deltaHeightLegend: this.deltaHeightLegend });
      } else {
        this.selectedEntity.signal = null;
        await this.changeSurfaceLogSignal({ uuid: this.selectedSurfaceLogUuid, signal: this.deselectedSignal.signal, styleSLD: this.deselectedSignal.style, isOutsideRefModelVisible: this.selectedEntity.isOutsideRefModel, deltaHeightLegend: this.deltaHeightLegend });
      }
    },
    tf(num) {
      return Number(`${Math.round(`${num}e2`)}e-2`).toFixed(2);
    },
  }
};
</script>

<style lang="less">
@import '../../../common';
</style>