<template>
  <div class="cx-icon cxu-center-v">
    <i :class="appliedClass" v-if="icon && isCxIcon" :style="appliedStyle"></i>
    <i class="material-icons" v-if="icon && !isCxIcon && !isMdiIcon" :style="appliedStyle">{{ icon }}</i>
    <i
      class="mdi"
      :class="icon"
      v-if="icon && isMdiIcon"
      :style="appliedStyle"></i>
  </div>
</template>

<script>

/**
 * Displays mdi-icon, bootstrap meterial icon or custom cx icon
 */

export default {
  name: 'CxIcon',
  props: {
    icon: { type: String, required: true },
    size: {
      type: [Number, String],
      default: 12
    }
  },
  computed: {
    isCxIcon() {
      return !!this.icon && this.icon.startsWith('cx_');
    },
    isMdiIcon() {
      return !!this.icon && this.icon.startsWith('mdi-');
    },
    appliedStyle() {
      const style = {};
      if (this.size) {
        style.fontSize = `${this.size}px`;
        style.lineHeight = `${this.size}px`;
        style.height = `${this.size}px`;
      }
      return style;
    },
    appliedClass() {
      if (this.isCxIcon) {
        return {
          'cx-icon-font': true,
          [this.icon]: true
        };
      }
      return null;
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-icon {
  color: inherit;
  display: inline-block;

  .material-icons {
    vertical-align: middle;
  }
}
</style>