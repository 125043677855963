/**
 * A collection of zoom level definitions used to set camera behavior for each level of map zoom.
 * @property {String} id zoom level identifier
 * @property {Array<Number>} range of zoom with minimum and maximum altitude
 * @property {Number} multiplier of camera's step rate towards next zoom altitude
 */
const zoomLevels = [
  {
    id: '0',
    range: [20000000, Infinity],
    multiplier: 5000000
  },
  {
    id: '1',
    range: [5000000, 20000000],
    multiplier: 2500000
  },
  {
    id: '2',
    range: [2000000, 5000000],
    multiplier: 500000
  },
  {
    id: '3',
    range: [500000, 2000000],
    multiplier: 250000
  },
  {
    id: '4',
    range: [200000, 500000],
    multiplier: 50000
  },
  {
    id: '5',
    range: [50000, 200000],
    multiplier: 25000
  },
  {
    id: '6',
    range: [20000, 50000],
    multiplier: 5000
  },
  {
    id: '7',
    range: [5000, 20000],
    multiplier: 2500
  },
  {
    id: '8',
    range: [1000, 5000],
    multiplier: 500
  },
  {
    id: '9',
    range: [500, 1000],
    multiplier: 125
  },
  {
    id: '10',
    range: [200, 500],
    multiplier: 50
  },
  {
    id: '11',
    range: [100, 200],
    multiplier: 12
  },
  {
    id: '12',
    range: [0, 100],
    multiplier: 5
  }
];

export default zoomLevels;