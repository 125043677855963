<template>
  <div class="cx-toggle-switch">
    <div
      class="cx-toggle-switch__switch"
      @click="toggle">
      <input type="checkbox" :checked="checked" :disabled="disabled"/>
      <span class="cx-toggle-switch__slider" :class="{'disabled' : disabled}"></span>
    </div>
    <label class="cx-toggle-switch__label" v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CxToggleSwitch',
  props: {
    checked: Boolean,
    label: String,
    onToggle: Function,
    disabled: { type: Boolean, default: false }
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.onToggle();
    }
  }
};
</script>



<style lang="less">
@import '../common';

.cx-toggle-switch {

  .cx-toggle-switch__switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .cx-toggle-switch__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: @baseInputColor;
      transition: .4s;
      border-radius: 30px;

      &.disabled {
        background-color: @textColorDisabled;
        cursor: default;
      }
    }

    .cx-toggle-switch__slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: @textColorLowlight;
      transition: .4s;
      border-radius: 50%;

    }
    .cx-toggle-switch__slider.disabled:before {
      background-color: @textColorDisabled;
    }

    input:checked + .cx-toggle-switch__slider:not(.disabled) {
      background-color: @blueBtnColor
    }
    input:checked + .cx-toggle-switch__slider:before {
      transform: translateX(16px);
    }

  }

  .cx-toggle-switch__label {
    padding-left: 5px;
    position: relative;
    top: 5px;
    color: @textColor;
  }

}


</style>