<template>
  <div class="cx-stats-panel">
    <div class="cx-stats-panel__upper">
      <div class="cx-stats-panel__upper__avatar">
        <slot name="avatar"></slot>
      </div>
      <div class="cx-stats-panel__upper__title" :class="applyClass">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="cx-stats-panel__lower">
      <div class="cx-stats-panel__lower__related-entities">
        <slot name="relatedEntities"></slot>
      </div>
      <div class="cx-stats-panel__lower__main-metadata">
        <slot name="mainMetadata"></slot>
      </div>
      <div class="cx-stats-panel__lower__secondary-metadata">
        <slot name="secondaryMetadata"></slot>
      </div>
      <slot name="customContent"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxStatsPanel',
  props: {
    isAvatar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    applyClass() {
      return {
        'cx-stats-panel__upper__title--avatar': this.isAvatar
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-stats-panel {
  height: 100%;
  background-color: @baseBackgroundColor;
  width: 650px;

  .cx-stats-panel__upper {
    height: @detailsTitleContentHeight;
    .flex(column, flex-start, stretch);

    .cx-stats-panel__upper__title {
      margin-left: 55px;
      margin-top: 31px;

      &.cx-stats-panel__upper__title--avatar {
        margin-left: 301px;
      }
    }
  }

  .cx-stats-panel__lower {
    .size(100%, calc(100% - @filterMenuWidth)); // minus widht of side panel
    .flex(row, flex-start, stretch);
    margin-top: 13px;

    .cx-stats-panel__lower__related-entities {
      .size(auto, 100%);
      margin-left: 50px;
    }

    .cx-stats-panel__lower__main-metadata {
      flex: 0 0 auto; //set according to the panel we are in
      .flex(row, flex-start, stretch);
      flex-wrap: wrap;
      height: 100%;
      margin-left: 56px;

      .cx-mp-main-data-panel {
        margin-right: 0px;
        &.nolicence {
          max-height: @detailsNoLicenceHeight;
          margin-right: 40px;
        }
      }
    }
    .cx-stats-panel__lower__secondary-metadata {
      flex: 0 0 auto; //set according to the panel we are in
      .flex(row, flex-start, stretch);
      flex-wrap: wrap;
      height: 100%;
      margin-left: 10px;
    }
  }
}
</style>