import _ from 'lodash';
import { sanitizeStringValue } from '@/utils/stringOperations';


/**
 * Represent a number value ready to display
 */
export class NumberValue {

  // raw value passed to the object
  _value;
  // sanitized value - number or Nan
  _processedValue;
  _unit;
  _roundPrecision;

  constructor(value, unit, roundPrecision = -1) {
    this._value = value;
    this._processedValue = parseFloat(sanitizeStringValue(value));
    this._unit = unit;
    this._roundPrecision = roundPrecision;
  }

  /**
   * Returns string containing rounded number and a unit
   * If number is Nan returns empty string
   */
  get displayValue() {
    if (Number.isNaN(this._processedValue)) return '';
    let resultValue = this._processedValue;
    if (this._roundPrecision > -1) {
      resultValue = _.round(this._processedValue, this._roundPrecision);
    }
    if (this._unit) return `${resultValue} ${this._unit}`;
    return `${resultValue}`;
  }

  /**
   * Return processed and rounded value or null if number is Nan
   */
  get value() {
    if (Number.isNaN(this._processedValue)) return null;
    return this._roundPrecision > -1 ? _.round(this._processedValue, this._roundPrecision) : this._processedValue;
  }

  /*
  * Returns unit or empty string if number is Nan
  */
  get unit() {
    if (Number.isNaN(this._processedValue)) return '';
    return this._unit;
  }

}