<template>
  <div class="cx-map-instruction">
    <div
      class="cx-map-instruction__item"
      v-for="(item, key) of instruction.content"
      :key="key">
      <img
        class="cx-map-instruction__item__icon"
        :src="item.iconUrl"/>
      <div class="cx-map-instruction__item__content">
        <span class="cx-map-instruction__item__content__action">{{ item.action() }}</span>
        <span class="cx-map-instruction__item__content__hint">{{ item.hint() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxMapInstruction',

  props: {
    instruction: Object,
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-instruction {
  .flex(column, flex-start, flex-start);

  .cx-map-instruction__item {
    .size(100%, auto);
    .flex(row, flex-start, center);
    padding-bottom: @inputSpace;

    .cx-map-instruction__item__icon {
      .size(@mapInstructionItemIconSize, @mapInstructionItemIconSize);
      color: @textColorHighlight;
    }

    .cx-map-instruction__item__content {
      .flex(column, center, flex-start);
      width: @mapInstructionDescriptionWidth;
      padding-left: 10px;

      .cx-map-instruction__item__content__action {
        color: @textColor;
      }

      .cx-map-instruction__item__content__hint {
        color: @textColorLowlight;
      }
    }
  }
}
</style>