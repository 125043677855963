import _ from 'lodash';
import { formatISO, toDate, subDays, startOfDay, endOfDay, startOfMinute, format } from 'date-fns';
import api from '@/http/api';
import { DynamicFilterExcludedFields } from '@/domain/point/DynamicFilterExcludedFields.enum';
import FilterMeasuredPointViewModel from './filterMeasuredPointViewModel';
import { PointPerformanceLimitExceeded } from '@/store/mapModule/map.points.errors';
import { metadataFilterStoreMixin } from '@/store/filterModule/metadataFilter.store.mixin';
import { metadataFilterFactory } from '@/store/filterModule/metadataFilterFactory';
import { createFilter } from '@/domain/filters/filterModelFactory.ts';
import { getFieldsForFilters } from '@/utils/pointFieldLicenceHelper';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const filtersToExcludeFromMetadata = ['unit', 'unit_account_name', 'unit_type', 'reference_model', 'date', 'time', 'aPLNoSync'];

const filterMeasuredPointModule = {
  state: {
    ...metadataFilterStoreMixin.state,
    isLoading: false,
    isExportLoading: false,
    // todo: this is hardcoded end date to take
    // all the mock points for the mock model
    // change in the future
    endDate: null,
    startDate: null,
    selectedRefModel: null,
    selectedUnitTypes: [],
    // list of unit uuids selected by the implicit unit name filter
    selectedUnitUuids: [],
    queryOngoing: false,
    resultPointSet: [],
    metadataFields: [],
    isLoadedPointsLimitExceeded: false,
    includeAutologPoints: false,
    includeDiscardedPoints: false
  },
  getters: {
    ...metadataFilterStoreMixin.getters,
    /**
     * All filters to apply
     */
    filters(state, getters) {
      const filters = [];
      if (state.selectedUnitTypes.length) filters.push(createFilter(FilterModelNames.UnitType, state.selectedUnitTypes));
      if (state.selectedUnitUuids.length) filters.push(createFilter(FilterModelNames.UnitUuid, state.selectedUnitUuids));
      if (state.selectedRefModel) filters.push(createFilter(FilterModelNames.RefmodelUuid, [state.selectedRefModel.value]));
      filters.push(createFilter(FilterModelNames.APLNoSync, [state.includeAutologPoints]));
      filters.push(createFilter(FilterModelNames.DiscardedPoints, [state.includeDiscardedPoints]));
      return [...filters, ...getters.addedMetadataFilters.map(f => f.getModel())];
    },
    isLoading(state) {
      return state.isLoading;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    selectedRefModel(state) {
      return state.selectedRefModel;
    },
    selectedUnitTypes(state) {
      return state.selectedUnitTypes;
    },
    selectedUnitNames(state, getters, rootState, rootGetters) {
      const units = rootGetters['app/allUnits'];
      return units.filter(u => state.selectedUnitUuids.includes(u.uuid));
    },
    availableUnitNames(state, getters, globalState, globalGetters) {
      const units = globalGetters['app/allUnits'];
      return units.filter((u) => {
        if (state.selectedUnitTypes.length === 0 || state.selectedUnitTypes[0] === '') return true;
        return state.selectedUnitTypes.includes(u.entityType);
      });
    },
    queryOngoing(state) {
      return state.queryOngoing;
    },
    resultPointSet(state) {
      return state.resultPointSet;
    },
    isExportLoading(state) {
      return state.isExportLoading;
    },
    isPartialPointSet(state) {
      const [pointSet] = state.resultPointSet;
      return pointSet ? pointSet.data.count !== pointSet.data.totalCount : false;
    },
    isLoadedPointsLimitExceeded(state) {
      return state.isLoadedPointsLimitExceeded;
    },
    includeAutologPoints(state) {
      return state.includeAutologPoints;
    },
    includeDiscardedPoints(state) {
      return state.includeDiscardedPoints;
    }
  },
  mutations: {
    ...metadataFilterStoreMixin.mutations,
    changeQueryOngoing(state, payload) {
      state.queryOngoing = payload;
    },
    changeResultPointSet(state, pointSet) {
      state.resultPointSet = pointSet;
    },
    changeSelectedRefModel(state, refModel) {
      if (refModel) {
        state.selectedRefModel = refModel;
        state.selectedRefModel.value = refModel.uuid;
      } else {
        state.selectedRefModel = null;
      }
    },
    changeSelectdUnitTypes(state, types) {
      state.selectedUnitTypes = types;
    },
    changeUnitNames(state, value) {
      state.selectedUnitUuids = value;
    },
    clearFilters(state) {
      const now = new Date(Date.now());
      state.selectedRefModel = null;
      state.selectedUnitTypes = [];
      state.selectedUnitUuids = [];
      state.addedMetadataFilters = [];
      state.startDate = startOfDay(subDays(now, 1));
      state.endDate = endOfDay(now);
      state.resultPointSet = [];
      state.includeDiscardedPoints = false;
      state.includeAutologPoints = false;
    },
    changeStartDate(state, date) {
      state.startDate = toDate(date);
    },
    changeEndDate(state, date) {
      state.endDate = toDate(date);
    },
    setIsExportLoading(state, isExportLoading) {
      state.isExportLoading = isExportLoading;
    },
    setIsLoadedPointsLimitExceeded(state, isLoadedPointsLimitExceeded) {
      if (_.isBoolean(isLoadedPointsLimitExceeded)) state.isLoadedPointsLimitExceeded = isLoadedPointsLimitExceeded;
    },
    setStartDate(state, date) {
      state.startDate = date;
    },
    setEndDate(state, date) {
      state.endDate = date;
    },
    setIncludeAutologPoints(state, includeAutologPoints) {
      state.includeAutologPoints = includeAutologPoints;
    },
    setIncludeDiscardedPoints(state, includeDiscardedPoints) {
      state.includeDiscardedPoints = includeDiscardedPoints;
    }
  },
  actions: {
    ...metadataFilterStoreMixin.actions,
    async initializeFilters(context, fields) {
      const now = new Date(Date.now());
      context.dispatch('initializeMetadataFilters', { fields, excludedFilters: filtersToExcludeFromMetadata });
    },
    changeSelectedUnitTypes(context, types) {
      if (types.length > 0) {
        const unitNames = context.state.selectedUnitUuids.filter((u) => {
          const unit = context.rootGetters['app/allUnits'].find(i => i.uuid === u);
          return (types.includes(unit.entityType));
        });
        context.commit('changeUnitNames', unitNames);
      }
      context.commit('changeSelectdUnitTypes', types);
    },
    async searchMeasuredPoints(context) {
      context.commit('setIsLoadedPointsLimitExceeded', false);
      context.commit('changeResultPointSet', []);
      const { projectUuid } = context.rootGetters;
      const { startDate, endDate, filters, includeDiscardedPoints } = context.getters;
      const includeQueryParams = true;
      const pointSet = await api.filterMeasurePoints(
        projectUuid,
        formatISO(startDate),
        formatISO(endDate),
        filters,
        includeQueryParams,
        getFieldsForFilters(),
        includeDiscardedPoints
      );

      const language = gettext.current;
      const resultPointSet = new FilterMeasuredPointViewModel(pointSet, language, false);
      context.commit('changeResultPointSet', [resultPointSet]);
      return pointSet.totalCount;
    },
    displayMeasuredPoints(context) {
      context.commit('setIsLoadedPointsLimitExceeded', false);
      context.commit('changeQueryOngoing', true);
      try {
        const [appliedPointSet] = context.getters.resultPointSet;
        if (appliedPointSet.data.count > 0) {
          context.dispatch('checkPointLimitExceeded', appliedPointSet.data.count).then((isPointLimitExceeded) => {
            const isPartialPointSet = appliedPointSet.data.count !== appliedPointSet.data.totalCount;
            if (isPointLimitExceeded || isPartialPointSet) context.commit('setIsLoadedPointsLimitExceeded', true);
            else context.dispatch('applyPointSet', { pointSet: appliedPointSet.data });
          });
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: '' }, { root: true });
      } finally {
        context.commit('changeQueryOngoing', false);
      }
    },
    async checkPointLimitExceeded(context, numberOfPoints) {
      const alreadyLoadedPoints = context.rootGetters['map/performancePointCount'];
      const maxLoadablePoints = context.rootGetters['map/maxLoadablePointsCount'];
      const pointsAfterLoadCount = numberOfPoints + alreadyLoadedPoints;
      return pointsAfterLoadCount > maxLoadablePoints;
    },
    async applyPointSet(context, { pointSet }) {
      try {
        if (!pointSet) return;
        await context.dispatch('map/addEntities', { entities: [pointSet] }, { root: true });
      } catch (error) {
        if (error instanceof PointPerformanceLimitExceeded) context.commit('setIsLoadedPointsLimitExceeded', true);
        else {
          context.dispatch('notifications/error', { exception: error, message: $gettext('Selected point set cannot be rendered.') }, { root: true });
        }
      }
    },
    async exportPoints(context, extension) {
      try {
        const { projectUuid } = context.rootGetters;
        const [appliedPointSet] = context.getters.resultPointSet;
        const { originTimeRange, originFilters } = appliedPointSet.data;
        const fileName = `ConX_Asbuilts_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;
        context.commit('setIsExportLoading', true);
        await api.exportMeasuredPoints(
          projectUuid,
          formatISO(originTimeRange.from),
          formatISO(originTimeRange.to),
          originFilters,
          fileName,
          extension
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExportLoading', false);
      }
    },
    async addMetadataFilter(context, data) {
      const { projectUuid } = context.rootGetters;
      const { filter, isRange } = data;
      const { fieldName, displayName, dataType } = filter;

      let result = null;
      // create ranged filter
      if (isRange) result = metadataFilterFactory.createRangeFilter(fieldName, displayName, dataType);
      // create single-value filter
      else if (Object.values(DynamicFilterExcludedFields).includes(fieldName)) result = metadataFilterFactory.createSingleFilter(fieldName, displayName, dataType);
      else {
        // check what values are available and create multi-value filter
        const availableValues = await context.dispatch('fetchGetPointMetadataFilterValues', { projectUuid, fieldName });
        if (availableValues) result = metadataFilterFactory.createChipsFilter(fieldName, displayName, dataType, availableValues);
        else result = metadataFilterFactory.createSingleFilter(fieldName, displayName, dataType);
      }
      context.commit('addMetadataFilter', result);
    },
    async fetchGetPointMetadataFilterValues(context, { projectUuid, fieldName }) {
      try {
        return await api.getPointMetadataFilterValues(projectUuid, fieldName);
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: e.message }, { root: true });
        return null;
      }
    }
  },
};

export default filterMeasuredPointModule;