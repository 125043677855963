import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';

const avatarMap = {
  [PointTypes.StabilizationPointType]: 'avatars/mpoint.png',
  [PointTypes.EarthmovingPointType]: 'avatars/mpoint.png',
  [PointTypes.DefaultPointType]: 'avatars/mpoint.png',
  [PointSetType]: null,
  [StabilizationTypes.DrillHole]: 'avatars/drill-hole-avatar.png',
  [StabilizationTypes.PileHole]: 'avatars/pile-avatar.png',
  [DrillHoleSetType]: null,
  [RefmodelTypes.DrillPattern]: 'avatars/pattern1-avatar.jpg',

  // model icons
  [RefmodelTypes.HelperFileType]: 'avatars/refmod.png',
  [RefmodelTypes.DefaultRefmodelType]: 'avatars/refmod.pngl',
  [RefmodelTypes.CoordinateFileType]: 'avatars/refmod.pngl',
  [RefmodelTypes.TerrainModelType]: 'avatars/refmod.png',
  [RefmodelTypes.RoadLineType]: 'avatars/refmod.png',
  [RefmodelTypes.StringlineModelType]: 'avatars/refmod.png',
  [RefmodelTypes.VolumeDescriptionModelType]: 'avatars/refmod.png',
  [RefmodelTypes.CrossFallModelType]: 'avatars/refmod.png',

  // unit icons
  [UnitTypes.DozerType]: 'avatars/bulldozer.png',
  [UnitTypes.GraderType]: 'avatars/grader.png',
  [UnitTypes.ExcavatorType]: 'avatars/excavator.png',
  [UnitTypes.ExcavatorWheeledType]: 'avatars/excavator-wheeled.png',
  [UnitTypes.BackhoeType]: 'avatars/backhoe.png',
  [UnitTypes.WheelLoaderType]: 'avatars/wheel-loader.png',
  [UnitTypes.HaulTruckType]: 'avatars/dump-truck.png',
  [UnitTypes.FieldCrewType]: 'avatars/surveyor.png',
  [UnitTypes.BaseStationType]: 'avatars/base-station.png',
  [UnitTypes.DrillerType]: 'avatars/driller.png',
  [UnitTypes.PilerType]: 'avatars/piler.png',
  [UnitTypes.RollerType]: 'avatars/roller.png',
  [UnitTypes.SnowGroomerType]: 'avatars/snow-groomer.png',
  [UnitTypes.SlipformPaverType]: 'avatars/slipform-paver.png',
  [UnitTypes.PlacerSpreaderType]: 'avatars/placer-spreader.png',
  [UnitTypes.CurbAndGutterType]: 'avatars/curb-and-gutter.png',
  [UnitTypes.MillingType]: 'avatars/milling.png',
  [UnitTypes.PaverType]: 'avatars/asphalt-paver.png',
  [UnitTypes.TrimmerType]: 'avatars/trimmer.png',
  [UnitTypes.Vehicle]: 'avatars/pa10.png',
  [UnitTypes.CTLSkidSteer]: 'avatars/ctl-skid-steer.png',
  [UnitTypes.DefaultUnitType]: 'avatars/default.png'
};

const withAvatar = (entity) => {
  if (!Object.hasOwnProperty.call(entity, 'entityType')) return entity;
  const { entityType } = entity;
  const avatar = avatarMap[entityType];
  return { ...entity, avatar };
};

export { withAvatar };