<template>
  <div class="licence-content cx-earthmoving-licence">
    <div class="content--border">
      <!-- LAYOUT WHEN NO LICENCE -->
      <div class="cx-licence-popup__licence-text" v-if="!hasLicence">
        <div class="cx-licence-popup__licence-text__title" v-translate>ConX Earthmoving Productivity includes</div>
        <div class="cx-licence-popup__licence-text__elements">
          <cx-licence-bullet-item :text="translations.cutFill"/>
          <cx-licence-bullet-item :text="translations.progress"/>
          <cx-licence-bullet-item :text="translations.measuredPoints"/>
          <cx-licence-bullet-item :text="translations.heightSlope"/>
        </div>
        <div class="cx-licence-popup__licence-text__note" v-translate>Please contact your local Leica dealer to purchase new license and activate Earthmoving Productivity features.</div>
      </div>
      <!-- LAYOUT WITH LICENCE -->
      <div class="cx-licence-popup__licence-text" v-if="hasLicence">
        <div class="cx-licence-popup__licence-text__title" v-translate>ConX Earthmoving Productivity licence</div>
        <div class="cx-licence-popup__licence-text__note" v-translate>Please contact your local Leica dealer to extend Earthmoving Productivity Licence</div>
      </div>
    </div>
    <div class="licence-img" :class="{'with-licence': hasLicence}"></div>
    <div class="licence-tick" v-if="hasLicence">
      <cx-icon icon="done" class="cxu-color-base" :size="20"/>
    </div>
  </div>
</template>

<script>
import CxLicenceBulletItem from './CxLicenceBulletItem';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxEarthmovingLicence',
  components: {
    CxLicenceBulletItem,
    CxIcon
  },
  props: {
    hasLicence: { type: Boolean, default: false }
  },
  computed: {
    translations() {
      return {
        heightSlope: this.$gettext('Height, Slope and Contour Map'),
        cutFill: this.$gettext('Cut & Fill Analysis and Mapping'),
        progress: this.$gettext('Real-Time Volumetric Progress Analysis'),
        measuredPoints: this.$gettext('Measured Points and Logged Surface Analysis')
      };
    }
  },
};
</script>

<style lang="less">
@import '../../../common';

.cx-earthmoving-licence {
  position: relative;

  .licence-img {
    position: absolute;
    .size(@cxLicenceFalseImgSize, @cxLicenceFalseImgSize);
    bottom: 20px;
    background-image: url('../../../../public/licencing/earthmoving-box.png');
    background-size: contain;
    &.with-licence {
        bottom: 10px;
        .size(@cxLicenceTrueImgSize, @cxLicenceTrueImgSize);
        left: 10px;
    }
  }

  .licence-tick {
      right: -15px;
      bottom: 40px;
      .size(@cxLicenceTickSize, @cxLicenceTickSize);
  }
}

</style>
