<template>
  <div class="cx-pie-chart" :id="id">
  </div>
</template>

<script>
import SvgPieChart from './pieChart';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CxPieChart',
  props: {
    plotData: Array,
    seriesClass: String,
    type: String
  },
  data() {
    return {
      id: `cx-pie-chart-${uuidv4()}`,
      svgChart: null,
      size: { width: 66, height: 66 },
      margin: {
        top: 0, right: 0, bottom: 0, left: 0
      },
      radius: 33,
      innerRadius: 30,
    };
  },
  computed: {
    elementId() {
      return `#${this.id}`;
    },
  },
  methods: {
    getChart() {
      if (!this.svgChart) {
        this.svgChart = new SvgPieChart(
          this.elementId,
          this.size,
          this.margin,
          this.radius,
          this.innerRadius,
          this.seriesClass,
          this.type
        );
      }
      return this.svgChart;
    },
    updateData() {
      if (this.svgChart) {
        this.svgChart.updateData(this.plotData);
        this.svgChart.render();
      }
    }
  },
  watch: {
    plotData: {
      handler(newValue) {
        if (!newValue || newValue.length < 1) return;
        this.updateData();
      }
    }
  },
  mounted() {
    this.getChart();
    this.updateData();
  }
};
</script>

<style lang="less">
@import '../../../../../common';

.series__cut {
  .slice_0 {
      fill: @cutColor;
    &:hover {
      fill: @cutHighlightColor;
    }
  }
  .percentage--text {
    fill: @cutColor;
  }
}

.series__fill {
  .slice_1 {
      fill: @fillColor;
    &:hover {
      fill: @fillHighlightColor;
    }
  }
  .percentage--text {
    fill: @fillColor;
  }
}


.slice {
   fill: @inputBackground
}


.percentage--text {
  font-size: 1.29rem;
  font-weight: @appFontWeight;
}
  .cx-pie-chart {
    height: @detailsRefModelPieChartHeight;
  }

</style>