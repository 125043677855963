import translationService from '@/services/translation.service';
import { getUnit, createValue } from '@/viewModel/valueFactory';


const fieldSymbols = {
  latitude: 'φ',
  longitude: 'λ',
  ellipsoidHeight: 'h',
  northing: 'N',
  easting: 'E',
  height: 'H'
};

/**
 * Returns a field object
 * @param {String} fieldName name of the field to define
 * @param {String} unit unit that field will have. If left empty function will attempt to get default unit for that field
 * @returns {Object} Returns { field, fieldName, unit, symbol }
 */
export const defineField = (fieldName, unit = null) => {
  return {
    field: fieldName,
    fieldName: translationService.get(fieldName),
    unit: unit || getUnit(fieldName),
    symbol: translationService.get(fieldSymbols[fieldName]),
  };
};

/**
 * Returns objest that represents a field that is ready to display
 * It's translated with sanitized, rounded value with measurement unit (if number)
 * @param {} entity - object that should consist fieldName property
 * @param {*} fieldName - field to display. If can't find such object on the object an error will be thrown
 */
export const createField = (entity, fieldName) => {
  const field = defineField(fieldName);
  const value = createValue(entity, fieldName, field.unit);
  return {
    fieldName: field.fieldName,
    symbol: field.symbol,
    value: value.value,
    displayValue: value.displayValue,
    unit: value.unit
  };
};

/**
 * Mix in this method into your view model for prettier api
 * @param {Object} entity object that will be used for creating fields
 */
export const createFieldMixin = (entity) => {
  return (fieldName) => createField(entity, fieldName);
};