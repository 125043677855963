<template>
  <div class="cx-multi-message-wrapper">
    <cx-multi-message
      v-if="isOpen"
      :minimized="isMinimized"
      :showClosePrompt="showClosePrompt"
      @send="send"
      @acknowledge="acknowledge"
      @close="close"
      @cancel="cancel"/>
    <cx-toggle
      icon="mdi-email-outline"
      :active="isOpen && !isMinimized"
      :loading="false"
      :iconSize="20"
      @click="toggle"
      :tooltip="tooltip"/>
  </div>
</template>

<script>
import CxMultiMessage from './CxMultiMessage';
import CxToggle from '@/components/CxToggle';

export default {
  name: 'CxMultiMessageWrapper',
  components: {
    CxMultiMessage,
    CxToggle
  },
  data() {
    return {
      isOpen: false,
      isMinimized: false,
      awaitingAcknowledgement: false,
      showClosePrompt: false
    };
  },
  computed: {
    tooltip() {
      return {
        offsetLeft: -92,
        text: this.$gettext('Send message to multiple machines')
      };
    }
  },
  methods: {
    toggle() {
      if (!this.isOpen) {
        this.isOpen = true;
      } else {
        this.isMinimized = !this.isMinimized;
      }
    },
    close() {
      if (!this.awaitingAcknowledgement) {
        this.showClosePrompt = false;
        this.isOpen = false;
      } else if (this.showClosePrompt) {
        this.showClosePrompt = false;
        this.awaitingAcknowledgement = false;
        this.isOpen = false;
      } else {
        this.showClosePrompt = true;
      }
    },
    cancel() {
      this.showClosePrompt = false;
    },
    send() {
      this.awaitingAcknowledgement = true;
    },
    acknowledge() {
      this.awaitingAcknowledgement = false;
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-multi-message-wrapper {
  position: relative;
  bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;

  .cx-multi-message {
    cursor: default;
    width: 560px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    background-color: @filterActionPanelBGColor;
    border-radius: @inputBorderRadius;
    padding: 15px 20px;
  }

  .cx-toggle {
    background-color: @overlayControlBackgroundColor;
    padding: 5px;
    border-radius: @inputBorderRadius;

    &:hover:not([disabled]):not(.cx-button--loading) {
      background-color: @overlayControlBackgroundColor !important;
    }
  }
}
</style>