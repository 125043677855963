<template>
  <div class="cx-hole-set-details-panel" v-if="this.selectedHoleSet">
    <!-- TITLE -->
    <cx-stats-panel>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="title"
            :titleClickHandler="onTitleClicked"/>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel
            v-if="isAnimationReady"
            :showPoint="true"
            :pointName="pointText"
            :showRefmodel="true"
            :refmodelName="refmodelText"
            :onShowPointsData="showPointsData"
            :showPointsDataLoading="isFetchingPoints"
            :showPointsDataVisible="isGridPoc"/>
        </transition>
      </template>
      <template #mainMetadata>
      </template>
    </cx-stats-panel>
    <div class="cx-cmn-dp__analysisPanel">
      <cx-export-asbuild-component
        :exportPoints="(selectedExtension) => exportPoints(selectedExtension.value)" :loading="isExporting"
        :isOpened="isAnimationReady"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxStatsPanel from '../common/CxStatsPanel';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxExportAsbuildComponent from '../common/CxExportAsbuildComponent';
import CxTitlePanel from '../common/CxTitlePanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';

export default {
  name: 'CxHoleSetDetailsPanel',
  components: {
    CxRelatedEntitiesPanel,
    CxStatsPanel,
    CxExportAsbuildComponent,
    CxTitlePanel
  },
  mixins: [CxAnimationMixin, CxLicenceMixin],
  computed: {
    ...mapGetters('detailsHoleSet', ['selectedHoleSet', 'isExporting', 'isFetchingPoints']),
    pointText() {
      if (!this.selectedHoleSet) return '';
      const pointCount = this.selectedHoleSet.drillHoles.flatMap(h => h.points).length;
      const translated = this.$ngettext('%{ n } Measured Point', '%{ n } Measured Points', pointCount);
      const interpolated = this.$gettextInterpolate(translated, { n: pointCount });
      return interpolated;
    },
    refmodelText() {
      if (!this.selectedHoleSet) return '';
      const count = new Set(this.selectedHoleSet.drillHoles.flatMap(h => h.pointSet.points.referenceModelName)).size;
      const translated = this.$ngettext('%{ n } Reference Model', '%{ n } Reference Model', count);
      const interpolated = this.$gettextInterpolate(translated, { n: count });
      return interpolated;
    },
    title() {
      if (!this.selectedHoleSet) return '';
      const count = this.selectedHoleSet.drillHoles.length;
      const translated = this.$ngettext('%{ n } Hole', '%{ n } Holes', count);
      const interpolated = this.$gettextInterpolate(translated, { n: count });
      return interpolated;
    }
  },
  methods: {
    ...mapActions('detailsHoleSet', ['exportPoints', 'showPointsData']),
    ...mapActions('map', ['setCameraToEntity']),
    onTitleClicked() {
      this.setCameraToEntity(this.selectedHoleSet.uuid);
    },
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-hole-set-details-panel {
  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-title-panel {
    span {
      padding-left: 0px;
    }
  }

  .cx-stats-panel {
    width: @detailsSetStatsWidth;
  }

  .cx-stats-panel__upper{
    height: @detailsSetStatsUpperHeight;
  }

  .cx-stats-panel__upper--title {
    margin-left: 55px;
    margin-top: 40px;
  }
}

</style>