
const storage = {

  storeItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  restoreItem: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  checkItem: (key) => {
    return key in localStorage;
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  }
};

export default storage;