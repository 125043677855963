import { v4 as uuidv4 } from 'uuid';
import { formatISO } from 'date-fns';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { Surfacelog, SurfacelogDeltaHeightLegend } from '@/domain/surfacelog/Surfacelog';
import datetimehelper from '@/utils/datetimehelper';
import licenceService from '@/services/licence.service';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { SignalKPI } from './SignalKPI';
import { MeasurementUnit } from '@/domain/MeasurementUnit';
import { PointCodesType } from '@/domain/surfacelog/PointCodesType';

const getSurfacelogName = () => {
  return `Surface ${datetimehelper.format(Date.now(), 'dd/MM/yyyy HH:mm:ss')}`;
};

const createSurfacelog = (
  {
    name,
    refmodelPaths,
    refmodelName,
    pointCodes,
    startDate,
    endDate,
    units,
    unitTypes,
    uuid,
    signalsKPI,
    availableSignals,
    deltaHeightLegend,
    cellSize,
    lengthUnit,
    isOutsideRefModel
  }: {
    name: string | null,
    refmodelPaths: string[],
    refmodelName: string | null,
    pointCodes: PointCodesType,
    startDate: Date,
    endDate: Date,
    units: [],
    unitTypes: string[],
    uuid: string | null,
    signalsKPI: Record<string, SignalKPI>,
    availableSignals: SurfacelogSignalTypes[],
    deltaHeightLegend: SurfacelogDeltaHeightLegend | null
    cellSize: number,
    lengthUnit: MeasurementUnit,
    isOutsideRefModel?: boolean
  }
): Surfacelog => {
  const surfacelog = {
    uuid: uuid || uuidv4() as string,
    entityType: SurfacelogType,
    name: name || getSurfacelogName(),
    refmodelPaths,
    pointCodes,
    startDate: formatISO(new Date(startDate)),
    endDate: formatISO(new Date(endDate)),
    units,
    unitTypes,
    signal: licenceService.hasEarthmovingLicence() ? SurfacelogSignalTypes.Height : SurfacelogSignalTypes.Passcount,
    originTimeRange: { from: startDate, to: endDate },
    originFilters: { refmodels: refmodelPaths, units, unitTypes, pointCodes },
    signalsKPI,
    availableSignals,
    deltaHeightLegend,
    cellSize,
    lengthUnit,
    isOutsideRefModel
  };
  return surfacelog;
};

export { createSurfacelog };
