import http from '@/http/axios.instance';
import { ProjectMetadataResponse, projectMapper } from './project.mapper';
import { ProjectMetadata } from '@/domain/ProjectMetadata';
import { ProjectAccounts } from '@/domain/ProjectAccounts';

const getProjectMetadata = async (projectUuid: string): Promise<ProjectMetadata> => {
  const apiUrl = `/api/ui2/v1/projects/${projectUuid}/metadata/`;
  const response = await http.get<ProjectMetadataResponse>(apiUrl);
  return projectMapper.mapProjectMetadata(response.data);
};

const getProjectAccounts = async (projectUuid: string): Promise<ProjectAccounts> => {
  const apiUrl = `/api/accounts/v1/accounts/${projectUuid}/`;
  const response = await http.get(apiUrl);
  return projectMapper.mapProjectAccounts(response.data);
};

const projectApi = {
  getProjectMetadata,
  getProjectAccounts
};

export default projectApi;