import _ from 'lodash';
import * as Cesium from 'cesium';
import { gltfUtils } from './gltfUtils/gltfUtils';
import { VisualizationSourceType } from '@/domain/refmodels/VisualizationSourceType.enum';
import mapConstants from '@/3dapi/map.constants';

const refmodelsStore = {
  state: {
    /**
     * This PrimitiveCollection holds all the refmodel primitives.
     */
    _refmodelCollection: null,
    refmodelCollection(viewer) {
      if (!this._refmodelCollection) {
        const { primitives } = viewer.scene;
        this._refmodelCollection = primitives.add(new Cesium.PrimitiveCollection());
      }
      return this._refmodelCollection;
    },
    refmodels: {},
    refmodelvisSourceTypes: {},
  },
  methods: {
    setRefModelOpacity(alpha) {
      return Cesium.Color.fromAlpha(Cesium.Color.WHITE, parseFloat(alpha));
    }
  },
  actions: {
    /**
     * Hides all models with ids in [hide], optionally shows all others.
     * @param context
     * @param hide {[string]} - a list of ids to be hidden
     * @param showOther {boolean} - show all others?
     * @returns {Promise<void>}
     */
    async hideRefModels(context, { hide, showOther = false }) {
      const { refmodels } = context.self.state;
      Object.entries(refmodels).forEach(([uuid, model]) => {
        model.show = !hide.includes(uuid) && (model.show || showOther);
      });
    },
    async showRefModels(context, { show, hideOther = false }) {
      const { refmodels } = context.self.state;
      Object.entries(refmodels).forEach(([uuid, model]) => {
        model.show = show.includes(uuid) || (model.show && !hideOther);
      });
    },
    /**
     * Load a 3D model, using Cesiums gltf loader. Coordinates are expected to be in
     * geocentric world coordinates, returns the promise
     * @param uuid  the model uuid
     * @param gltf the model seen as a json object
     * @param minMaxHeight
     * @param show boolean to show or hide model
     */
    async loadReferenceModel(context, { uuid, name, gltf, minMaxHeight, show }) {
      const { viewer } = context.self.rootState;
      const { state } = context.self;
      const model = state.refmodels[uuid];
      if (model) { return; }
      const measurementSystem = context.rootGetters['app/measurementSystem'];
      gltfUtils.initReferenceModel(gltf, measurementSystem, minMaxHeight);
      const { scene } = viewer;
      const collection = state.refmodelCollection(viewer);
      viewer.scene.requestRenderMode = false;
      const gltfModel = collection.add(new Cesium.Model({
        id: {
          uuid,
          name,
          cxType: 'refmodel',
        },
        gltf,
        show: show || false,
        scene,
        minimumPixelSize: 128,
        shadows: Cesium.ShadowMode.RECEIVE_ONLY,
        color: context.self.setRefModelOpacity(1),
        scale: 1,
      }));
      await gltfModel.readyPromise.then((loadedModel) => {
        loadedModel.id.position = new Cesium.ConstantPositionProperty(loadedModel.boundingSphere.center);
        loadedModel.id.boundingSphere = loadedModel.boundingSphere;
      });
      viewer.scene.requestRenderMode = true;
      context.self.state.refmodels[uuid] = gltfModel;
      context.self.state.refmodelvisSourceTypes[uuid] = { cxSourceType: VisualizationSourceType.Gltf };
    },
    /**
     * Unloads a 3D gltf model from the map. Returns a boolean
     * @param {*} context
     * @param {*} uuid  of the reference model to be removed
     * @returns { Boolean } true if model was removed, false otherwise
     */
    async removeReferenceModel(context, { uuid }) {
      const { viewer } = context.self.rootState;
      const { state } = context.self;
      const collection = state.refmodelCollection(viewer);
      let result = false;
      const refmodelvisSourceType = context.self.state.refmodelvisSourceTypes[uuid];
      if (refmodelvisSourceType && refmodelvisSourceType.cxSourceType === VisualizationSourceType.Gltf) {
        // check gltf models
        let primitiveToRemove;
        for (let i = 0; i <= collection.length; i += 1) {
          const primitive = collection.get(i);
          if (_.get(primitive, 'id.uuid') === uuid) { primitiveToRemove = primitive; break; }
        }
        if (!_.isNil(primitiveToRemove)) {
          result = collection.remove(primitiveToRemove);
        }
      }
      if (refmodelvisSourceType && refmodelvisSourceType.cxSourceType === VisualizationSourceType.Url) {
        // try kml / kmz source ones
        const refmodel = context.self.state.refmodels[uuid];
        if (refmodel) {
          result = viewer.dataSources.remove(refmodel);
        }
      }
      if (result) {
        delete state.refmodels[uuid];
        delete state.refmodelvisSourceTypes[uuid];
      }
      return result;
    },

    async changeRefModelOpacity(context, { uuid, opacity }) {
      const { viewer } = context.self.rootState;
      const { state } = context.self;
      const model = state.refmodels[uuid];
      model.color = context.self.setRefModelOpacity(opacity);
      viewer.scene.requestRender();
    },
    async getRefModelOpacity(context, { uuid }) {
      const { state } = context.self;
      return state.refmodels[uuid].color.alpha;
    },
    async setDataSource(context, payload) {
      const { uuid, name, resourceUrl, show } = payload;
      const { viewer } = context.self.rootState;
      if (context.self.state.refmodels[uuid]) return { data: `Reference model: ${name} is already present on the map.`, status: 'error' };
      const klmDataSource_ =
        Cesium.KmlDataSource.load(resourceUrl, {
          camera: viewer.scene.camera,
          canvas: viewer.scene.canvas,
          clampToGround: true

        }).then();
      const source = await viewer.dataSources.add(klmDataSource_);
      source.show = show;
      context.self.state.refmodels[uuid] = source;
      context.self.state.refmodelvisSourceTypes[uuid] = { cxSourceType: VisualizationSourceType.Url };
      const properties = new Cesium.PropertyBag();
      properties.addProperty('cxType', source.name);
      const entityCol = source.entities.values;
      entityCol.forEach((entity) => {
        entity.properties = properties;
      });
      return { data: payload, status: 'ok' };
    },

    /**
     * Turns on shaders present in the parameter, turns off those absent.
     * @param context
     * @param uuid: Reference model uuid.
     * @param shaders {String[]}: A list of strings with shaders to turn on.
     */
    async toggleShader(context, { uuid, shaders }) {
      const { state } = context.self;
      const model = state.refmodels[uuid];
      const material = model.getMaterial('DefaultSurface');
      const shaderTypes = mapConstants.shaders;
      material.setValue(shaderTypes.contour, shaders.includes(shaderTypes.contour));
      material.setValue(shaderTypes.heightMap, shaders.includes(shaderTypes.heightMap));
      material.setValue(shaderTypes.slopeMap, shaders.includes(shaderTypes.slopeMap));
    },
  },
  events: {
  }
};

export default refmodelsStore;