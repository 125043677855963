<template>
  <div
    class="cx-simple-list-item" :class="{ 'cx-list-item--selected': selected, 'disabled': disabled }" @click="onClick" :disabled="disabled || null"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    v-cx-tooltip="{
      ...tooltipOptions,
      offsetTop: -3
    }">
    <slot name="selection"><div class="cx-simple-list-item__checkbox cxu-center-v"><cx-checkbox :checked="selected" v-if="!disabled"/></div></slot>
    <div class="cx-list-item__caption">
      <slot name="before-content" class="cx-list-item__caption__before-content"></slot>
      <slot>
        <div class="cx-list-item__caption__content">
          <div class="cx-list-item__caption__title">
            <slot><span :class="titleClassName">{{ item.title }}</span></slot>
          </div>
          <span class="cx-list-item__caption__subtitle" v-if="item.subtitle"><slot name="subtitle"><span :class="subtitleClassName">{{ item.subtitle }}</span></slot></span>
        </div>
      </slot>
    </div>
    <slot name="after-content"><cx-warning :warning="warningText" v-if="warning"></cx-warning></slot>
  </div>
</template>


<script>
import CxCheckbox from '@/components/CxCheckbox';
import CxWarning from '@/components/CxWarning';
import CxTooltipMixin from '@/components/CxTooltipMixin';
/**
  Renders a list item, by default uses title and subtitle to render it's contnet
  Can be in a selected or disabled state
 */
export default {
  name: 'CxListItem',
  components: {
    CxCheckbox,
    CxWarning
  },
  mixins: [CxTooltipMixin],
  props: {
    // item to display { id, title, subtitle }
    item: {
      validator(value) {
        if (!value) return false;
        if (!value.id || !value.title) return false;
        return true;
      }
    },
    // flag to display a warning icon on the item or warning text  to display with icon
    warning: { type: [Boolean, String], default: false },
    // flag for disabled state
    disabled: { type: Boolean, default: false },
    // flag selected state
    selected: { type: Boolean },

    // css
    titleClassName: { type: String },
    subtitleClassName: { type: String },
  },
  emits: ['click'],
  computed: {
    warningText() {
      return (typeof this.warning) === 'string' ? this.warning : null;
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) this.$emit('click', this.item);
    }
  },
};
</script>

<style lang="less">
@import '../../common';

.cx-simple-list-item {
 .flex(row, flex-start, center);
  padding: 7px 8px;
  background-color: @filterResultBgColor;
  color: @textColor;
  font-size: @appFontSize;
  font-weight: @appFontWeight;
  border-radius: @inputBorderRadius;
  margin: 6px 0px;
  cursor: @listCursor;
  min-height: 33px;

  &.disabled {
    cursor: default;
    pointer-events: none;
    background-color: @filterResultBgColorLowlighted;
  }

  .cx-simple-list-item__checkbox {
    width: @listCheckboxWidth;
    pointer-events: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: @filterResultBgColorHighlighted;
    color: @textColorHighlight;
  }

  &.cx-list-item--selected {
    background-color: @listItemBackgroundActive;
    color: @textColorHighlight;

    &:hover {
      color: @textColorBase;
    }
  }

  .cx-list-item__caption {
    display: flex;
    column-gap: 8px;
   .size(100%, 100%);

    .cx-list-item__caption__content {
      display: flex;
      flex-direction: column;
      .cx-list-item__caption__title {
        flex: 1 0 auto;
        padding-top: 2px;
        max-width: 360px;
        .cut-overflow();
      }
    }
  }
}

</style>