import { withCanSelect } from '@/domain/entityMixins';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';

const mepSelectionStore = {
  state: {
    selectedEntity: null,
    selectTooltipPosition: null,
  },
  getters: {
    selectedEntity(state) {
      return state.selectedEntity;
    },
    selectTooltipPosition(state, getters, rootState, rootGetters) {
      if (!state.selectedEntity) return null;
      const visibility = rootGetters['map/entityVisibility'](state.selectedEntity.uuid);
      if (!visibility || Object.values(visibility.visibilityMode).some(m => m === false)) return null;
      if (state.selectedEntity.entityType === DrillHoleSetType ||
        state.selectedEntity.entityType === PointSetType) return null;
      return state.selectTooltipPosition;
    }
  },
  mutations: {
    setSelectedEntity(state, entity) {
      state.selectedEntity = entity;
    },
    setSelectTooltipPosition(state, entity) {
      state.selectTooltipPosition = entity;
    }
  },
  actions: {
    /**
     * Handles selection from the map, changes selectedEntity store variable
     * and raise event
     * @param {String} uuid of the entity to select
     * @param {String} type of the entity to select
     * @param {Object} data additional data, that can be only computed from gltf
     */
    async handleEntitySelection(context, { selection }) {
      const oldSelectedEntity = context.getters.selectedEntity;
      // entity deselected
      if (!selection || selection.length < 1 || !selection[0]) {
        context.dispatch('shell/handleEntitySelection', { newValue: null, oldValue: oldSelectedEntity }, { root: true });
        context.commit('setSelectedEntity', null);
        return;
      }
      // TODO: modify for multiselect support
      const { uuid } = selection[0];
      // search for selected entity in loaded things
      const entity = context.getters.allLoadedEntities.find(l => l.uuid === uuid);
      // if a selecten entity is the same - do not reselect
      if (oldSelectedEntity === entity) return;
      if (!entity) {
        return;
      }
      if (!withCanSelect(entity).canSelect) return;
      context.dispatch('shell/handleEntitySelection', { newValue: entity, oldValue: oldSelectedEntity }, { root: true });
      context.commit('setSelectedEntity', entity.uuid);
    },
  }
};

export default mepSelectionStore;