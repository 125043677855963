<template>
  <div class="height-slope-map-legend">
    <div class="height-slope-map-legend__title">
      <slot name="title"></slot>
    </div>
    <div class="height-slope-map-legend__unitText">
      <slot name="unit"></slot>
    </div>
    <div class="height-slope-map-legend__gradient">
      <div class="height-slope-map-legend__gradient__scale">
        <div v-for="(item, index) in scale" :key="`height-slope-${index}`">
          {{ item }}
        </div>
      </div>
      <div class="height-slope-map-legend__gradient__dash">
        <div v-for="(item, index) in dashCharacters" :key="`height-slope-legend-${index}`">
          {{ item }}
        </div>
      </div>
      <div
        class="height-slope-map-legend__gradient__image"
        :style="this.imageCSS"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxHeightSlopeMapLegend',
  props: {
    scale: { type: Array, required: true },
    gradientImage: { type: String, required: true }
  },
  computed: {
    imageCSS() {
      return { backgroundImage: `url("${this.gradientImage}")` };
    },
    dashCharacters() {
      return Array(this.scale.length).fill('-');
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.height-slope-map-legend {
  height: @overlayControlLegendHeight;
  .flex(column, flex-start, center);

  &__title {
    pointer-events: auto;
    font-size: @microFontSize;
    padding: 9px 0 3px 0;
    color: @textColorHighlight;
  }

  &__unitText {
    pointer-events: auto;
    font-size: @microFontSize;
    padding: 0 0 10px 0;
    color: @textColorLowlight;
  }

  &__gradient {
    height: @overlayControlLegendGradientHeight;
    width: 60%;
    .flex(row, space-between, center);

    &__image {
      pointer-events: auto;
      border-radius: 2px;
      height: inherit;
      width: 12px;
      background-size: contain;
    }

    &__scale {
      font-size: @microFontSize;
      opacity: 0.5;
      list-style-type: none;
      text-align: right;
      line-height: @overlayControlLegendScaleLineHeight;
    }

    &__dash {
      font-weight: bold;
      font-size: @microFontSize;
      opacity: 0.5;
      list-style-type: none;
      text-align: right;
      line-height: @overlayControlLegendScaleLineHeight;
    }
  }
}
</style>
