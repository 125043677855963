import _ from 'lodash';
import { parseISO, intervalToDuration, differenceInDays } from 'date-fns';
import { createFieldMixin } from '@/viewModel/fieldFactory';
import { createValueMixin } from '@/viewModel/valueFactory';
import { TextValue } from '@/viewModel/textValue';
import { withIcon, withColor, withAvatar } from '@/domain/entityMixins';
import translationService from '@/services/translation.service';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export class HoleDetailsViewModel {

  _hole;
  constructor(hole) {
    this._hole = withColor(withIcon(withAvatar(hole)));
    this.createField = createFieldMixin(this._hole);
    this.createValue = createValueMixin(this._hole);
    const points = _.sortBy(this._hole.pointSet.points, ['logTime']) || [];
    this.points = points;
    this.lastPoint = _.last(points) || {};
    this.createPointValue = createValueMixin(this.lastPoint);
    this.createPointField = createFieldMixin(this.lastPoint);
  }
  get holeName() {
    return this._hole.holeName;
  }
  get holeIcon() {
    return this._hole.icon;
  }
  get avatar() {
    return this._hole.avatar;
  }
  get gnss() {
    const gnss = this.createPointValue('gnss');
    const field = $gettext('GNSS Coordinate Quality');
    const { value, unit } = gnss;
    const icon = 'gps_fixed';
    return { field, value, icon, unit };
  }
  get unitType() {
    const unitType = this.createPointValue('unitType');
    const field = $gettext('Unit Type');
    const value = unitType.displayValue;
    const { icon } = withIcon({ entityType: this.lastPoint.unitType });
    return { field, value, icon };
  }
  get unitName() {
    return this.lastPoint.unitName;
  }
  get refmodelName() {
    return this.lastPoint.referenceModelName;
  }
  get equipmentType() {
    const equipmentType = this.createPointValue('equipmentType');
    const field = $gettext('Equipment Type');
    const value = equipmentType.displayValue;
    const icon = 'info';
    return { field, value, icon };
  }
  get coordinateSystem() {
    const coordinateSystem = this.createPointValue('coordinateSystem');
    const field = $gettext('Coordinate System');
    const value = coordinateSystem.displayValue;
    const icon = 'map';
    return { field, value, icon };
  }
  // work duration in dd:hh:mm:ss format
  get duration() {
    const calculateDuration = () => {
      if (this.points.length < 2) return null;
      const interval = {
        start: parseISO(this.points[0].logTime),
        end: parseISO(this.lastPoint.logTime)
      };
      const duration = intervalToDuration(interval);
      return {
        days: differenceInDays(interval.end, interval.start),
        hours: duration.hours,
        minutes: duration.minutes,
        seconds: duration.seconds
      };
    };
    const format = (val) => {
      return val.toString().padStart(2, '0');
    };
    const duration = calculateDuration();
    const days = duration ? (`${duration.days}`).padStart(2, '0') : null;
    const field = $gettext('Duration');
    const value = days ? `${days}:${format(duration.hours)}:${format(duration.minutes)}:${format(duration.seconds)}` : null;
    const icon = 'access_time';
    return { field, value, icon };
  }
  get targetAngle() {
    return this.createPointField('targetHoleAngle');
  }
  get targetHeading() {
    return this.createPointField('targetHoleHeading');
  }
  get actualAngle() {
    return this.createPointField('actualToolAngle');
  }
  get actualHeading() {
    return this.createPointField('actualToolHeading');
  }
  get drillPileDepth() {
    return this.createPointField('drillPileDepth');
  }
  get pointCode() {
    return this.createPointField('pointCode');
  }
  get heightOffset() {
    return this.createPointField('heightOffset');
  }
  get heightOffsetType() {
    return this.createPointField('heightOffsetType');
  }
  get sideDistance() {
    return this.createPointField('sideDistance');
  }
  get toolDiameter() {
    return this.createPointField('toolDiameter');
  }
  get pileHeight() {
    return this.createPointField('pileHeight');
  }
  get drillPileDepthSource() {
    return this.createPointField('drillPileDepthSource');
  }
  get station() {
    return this.createPointField('station');
  }
  get color() {
    return this._hole.color;
  }

  get status() {
    return new TextValue(this._hole.status);
  }
  get subtitle() {
    const type = `${$gettext('Type:')} ${translationService.get(this._hole.entityType)}`;
    const status = this.status.displayValue;
    const failReason = this.pointCode.displayValue ? ` - ${this.pointCode.displayValue}` : '';
    const field = type;
    const value = `${type}, ${status}${failReason}`;
    const icon = 'mdi-flag';
    return { field, value, icon };
  }
}
