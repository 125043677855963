<template>
  <div class="cx-selector" v-click-outside="hideDropdown">
    <label class="cx-selector__label" v-if="label">{{ label }}</label>
    <div
      class="cx-selector__hitbox"
      :class="{ 'cx-selector__hitbox--disabled': isHitboxDisabled }"
      @click="toggleDropdown"
      @mouseenter="isTooltipActive = true"
      @mouseleave="isTooltipActive = false"
      v-cx-tooltip="hitboxTooltip">
      <span class="cx-selector__hitbox__selection" v-if="selectedItemName">{{ selectedItemName }}</span>
      <span class="cx-selector__hitbox__placeholder" v-else>{{ placeholder }}</span>
      <cx-icon icon="more_horiz" :size="20"/>
    </div>
    <cx-popper
      v-if="isDropdownOpened"
      :options="popperOptions">
      <cx-menu
        :isVisible="true"
        :collection="collection"
        :nameField="nameField"
        :selectedItemName="selectedItemName"
        @click="onDropdownSelection"/>
    </cx-popper>
    <cx-selector-warning :active="!!warning" :tooltipText="warning"/>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import CxPopper from '@/components/CxPopper';
import CxMenu from '@/components/menuComponents/CxMenu';
import CxIcon from '@/components/CxIcon';
import CxSelectorWarning from '@/components/menuComponents/CxSelectorWarning';
import maxSize from 'popper-max-size-modifier';
import applyMaxSize from './popperApplyMaxSize.modifier';
import sameWidth from './popperSameWidth.modifier';

export default {
  name: 'CxSelector',
  components: {
    CxMenu,
    CxPopper,
    CxIcon,
    CxSelectorWarning
  },
  props: {
    // set dropdown value
    value: [Object, String],
    label: String,
    placeholder: String,
    collection: Array,
    nameField: { type: String, required: true },
    // use valueField prop only when value prop is String
    valueField: { type: String },
    isDisabled: Boolean,
    // if true the menu is the same width as the textbox
    // otherwise it will expand depending on the content widhth
    useSameWidth: { type: Boolean, default: true },
    onSelection: Function,
    // displayed when hovering over the selector
    tooltip: { type: String, default: null },
    warning: { type: String, default: null },
    popperOptions: {
      type: Object,
      default(props) {
        const options = {
          placement: 'bottom-start',
          modifiers: [maxSize, applyMaxSize]
        };
        if (props.useSameWidth) {
          options.modifiers.push(sameWidth);
        }
        return options;
      }
    }
  },
  data() {
    return {
      isDropdownOpened: false,
      isTooltipActive: false
    };
  },
  computed: {
    selectedItemObject() {
      if (this.value && this.valueField) return this.collection.find(c => c[this.valueField] === this.value);
      return this.value;
    },
    selectedItemName() {
      if (this.value && this.nameField) return this.selectedItemObject[this.nameField];
      return null;
    },
    hitboxTooltip() {
      return {
        text: this.tooltip,
        position: 'top-left',
        show: this.isTooltipActive && this.tooltip !== null,
        offsetTop: -5
      };
    },
    isHitboxDisabled() {
      return this.isDisabled || isNil(this.collection) || !this.collection.length;
    }
  },
  methods: {
    toggleDropdown() {
      if (this.isHitboxDisabled) return;
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    hideDropdown() {
      this.isDropdownOpened = false;
    },
    onDropdownSelection(item) {
      this.hideDropdown();
      if (this.valueField) this.onSelection(item[this.valueField]);
      else this.onSelection(item);
    },
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-selector {
  .flex(column, flex-end, flex-start);
  position: relative;
  font-size: @appFontSize;
  color: @textColorLowlight;
  user-select: none;
  width: 100%;

  .cx-selector__label {
    padding: 0 0 5px 12px;
  }

  .cx-selector__hitbox {
    .flex(row, space-between, center);
    border: 1px solid @chipsBorderColor;
    border-radius: @inputBorderRadius;
    padding: 1px 12px 0;
    .size(100%, @inputHeight);
    cursor: pointer;

    &.cx-selector__hitbox--disabled {
      opacity: 0.5;
      cursor: default;
    }

    .cx-selector__hitbox__selection {
      color: @textColor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cx-selector__hitbox__placeholder {
      color: @textColorDisabled;
    }

    .cx-icon {
      color: @textColor;
    }

    &:hover:not(.cx-selector__hitbox--disabled) {
      .cx-icon{
        color: @textColorHighlight;
      }
    }
  }

  .cx-popper {
    background-color: @bgColor1;
    border-radius: @inputBorderRadius;

    ::-webkit-scrollbar-thumb {
      background-color: @scrollThumbHoverColorLight;
      box-shadow: inset -1px -1px 0px @scrollThumbHoverColorLight, inset 1px 1px 0px @scrollThumbHoverColorLight;
      border-right: 1px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: @scrollThumbColor;
      box-shadow: inset -1px -1px 0px @scrollThumbColor, inset 1px 1px 0px @scrollThumbColor;
    }
  }

  .cx-selector-warning {
    position: absolute;
    right: -10px;
    top: 15px;
    z-index: @warningZI;
  }
}
</style>