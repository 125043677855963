<template>
  <button
    class="cx-button"
    :class="{
      'cx-button--loading': loading,
      'cx-button--disabled': disabled,
      'flat': flat,
      'square': square,
      'variant-primary': variantPrimary,
      'variant-secondary': variantSecondary && !variantPrimary && !variantDark,
      'variant-dark': variantDark,
      'variant-dropdown': variantDropdown }"
    :disabled="disabled || null"
    @click="handleClick($event)"
    @mousedown="$emit('mousedown')"
    @mouseup="$emit('mouseup')"
    @mouseout="$emit('mouseout')"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    v-cx-tooltip="tooltipOptions"
    :type="type">
    <cx-spinner :size="15" :width="1" v-if="loading"/>
    <div
      class="cx-button__caption" :class="hiddenClass">
      <slot name="before-content"></slot>
      <cx-icon
        :icon="icon"
        :size="iconSize"
        :class="iconClass"
        v-if="icon"/>
      <div class="cx-button__caption__text" :class="hiddenClass" v-if="renderDefaultSlot"><slot></slot></div>
      <slot name="after-content"></slot>
    </div>
  </button>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import CxSpinner from '@/components/CxSpinner';
import CxTooltipMixin from '@/components/CxTooltipMixin';

export default {
  name: 'CxButton',
  components: {
    CxIcon,
    CxSpinner
  },
  mixins: [CxTooltipMixin],
  props: {
    // pass name of the icon to show it on the button
    icon: { type: String },
    iconSize: { type: [Number, String], default: 13 },

    // states
    disabled: { type: Boolean },
    loading: { type: Boolean, default: false },

    // HTML button type
    type: { type: String, default: 'button' },

    // true to render button without background color
    flat: { type: Boolean },
    // true to render button with smaller horizontal paddings
    square: { type: Boolean },
    // css variants
    variantPrimary: { type: Boolean },
    variantSecondary: { type: Boolean, default: true },
    variantDark: { type: Boolean },
    variantDropdown: { type: Boolean },
    iconClass: { type: [String, Object] }
  },
  emits: ['click', 'mouseup', 'mousedown', 'mouseout'],
  computed: {
    hiddenClass() {
      return {
        '--hidden': this.loading,
      };
    },
    // we use display:hidden to keep width of the button while loading
    applyClass() {
      return {
        text: {
          'cx-button__caption__text--hidden': this.loading
        },
        icon: {
          'cx-icon--hidden': this.loading
        }
      };
    },
    renderDefaultSlot() {
      const defaultSlot = this.$slots?.default;
      if (defaultSlot) {
        return true;
      }
      return false;
    }
  },
  methods: {
    handleClick(event) {
      if (this.disabled || this.loading) return;
      this.$emit('click', { el: this.$el, event });
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-button {
  font-size: @appFontSize;
  color: @textColor;
  background-color: @grayBtnColor2;
  border-radius: @inputBorderRadius;
  position: relative;
  border: none;
  border-color: transparent;
  &.flat {
    background-color: transparent;
  }

  &[disabled]  {
    background-color: fadeout(@grayBtnColor2, 30%);
    cursor: default;
    color: @textColorDisabled;
    &.flat {
      background-color: transparent;
      color: @textColorLowlight;
    }
  }
  &.cx-button--loading {
     cursor: default;
  }

  &:hover:not([disabled]):not(.cx-button--loading) {
    cursor: pointer;
    color: @textColorHighlight;
    background-color: lighten(@grayBtnColor2, 8%);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    transition-property: all, color;
    transition-duration: 0.3s, 1ms;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1), ease;
    transition-delay: 0s, 0s;
    &.flat {
      background-color: transparent;
      color: @textColorHighlight;
    }
  }

  .cx-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

 &.flat {
    .cx-button__caption {
       padding: 0;
    }
  }
  &.square {
    .cx-button__caption {
       padding: 7px 12px 7px 12px;
    }
  }
  .cx-button__caption {
    visibility: visible;
    padding: 7px 16px 7px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .cx-button__caption__text {
        padding-top: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:has(.cx-button__caption__text:empty) {
      gap: 0px;
    }
  }
  .--hidden {
    visibility: hidden;
  }
  .cx-icon {
    text-rendering: optimizeLegibility;
  }
}

@colors: {
  primary: @blueBtnColor;
  secondary: @grayBtnColor;
  dark: rgb(55, 60, 71);
  dropdown: rgba(85, 90, 96, 0.3);
}

// create color swatches for button
each(@colors, {
  .cx-button.variant-@{key} {

    &:not(.flat) {
      background-color: @value;
    }

    &[disabled]:not(.flat) {
      background-color: fadeout(@value, 10%);
    }

    &:hover:not([disabled]):not(.cx-button--loading):not(.flat) {
      background-color: lighten(@value, 8%) !important;
    }
  }
});

each(@button-colors, {
  .cx-button.variant-@{key} {

    &:not(.flat) {
      background-color: @value;
    }

    &[disabled]:not(.flat) {
      background-color: fadeout(@value, 10%);
    }

    &:hover:not([disabled]):not(.cx-button--loading):not(.flat) {
      background-color: lighten(@value, 8%) !important;
    }
  }
});
</style>