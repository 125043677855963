<template>
  <div class="cx-drill-analysis-panel">
    <div class="cx-drill-analysis--pointData">
      <div v-for="(item, index) in points" :key="`pointData${index}`" class="cx-drill-analysis--pointData__item">
        <cx-hole-analysis-panel-point :item="item"/>
      </div>
    </div>
    <div class="cx-drill-analysus--holeData">
      <div v-for="(item, index) in holeData" :key="index" class="cx-drill-analysus--holeData__item">
        <cx-vertical-property :fieldName="item.fieldName" :value="item.value" :unit="item.unit"/>
      </div>
    </div>
    <div class="cx-drill-analysus--holeData">
      <div v-for="(item, index) in holeData2" :key="index" class="cx-drill-analysus--holeData__item">
        <cx-vertical-property :fieldName="item.fieldName" :value="item.value" :unit="item.unit"/>
      </div>
    </div>
    <div class="cx-drill-analysus--holeData">
      <div v-for="(item, index) in holeData3" :key="index" class="cx-drill-analysus--holeData__item">
        <cx-vertical-property :fieldName="item.fieldName" :value="item.value" :unit="item.unit"/>
      </div>
    </div>
  </div>
</template>

<script>
import CxContextLicenceMixin from '@/mixins/CxContextLicence.mixin.js';
import CxOperationContextMixin from '@/mixins/CxOperationContext.mixin.js';
import CxVerticalProperty from '@/components/CxVerticalProperty';
import CxHoleAnalysisPanelPoint from './CxHoleAnalysisPanelPoint';

export default {
  name: 'CxHoleAnalysisPanel',
  mixins: [CxContextLicenceMixin, CxOperationContextMixin],
  components: {
    CxHoleAnalysisPanelPoint,
    CxVerticalProperty,
  },
  props: {
    hole: Object
  },
  computed: {
    points() {
      if (!this.hole) return [];
      return this.hole.points;
    },
    holeData() {
      return [
        this.hole.targetAngle,
        this.hole.targetHeading,
        this.hole.drillPileDepth,
        this.hole.drillPileDepthSource,
      ];
    },
    holeData2() {
      return [
        this.hole.actualAngle,
        this.hole.actualHeading,
        this.hole.toolDiameter,
        this.hole.heightOffset,
      ];
    },
    holeData3() {
      return [
        this.hole.heightOffsetType,
      ];
    }
  },
};

</script>

<style lang="less">
@import '../../../../common';

.cx-drill-analysis-panel {
  display: flex;
  margin: 30px 50px 30px 0px;
  flex: 1 1 auto;
  border-radius: @inputBorderRadius;
  background-color: @detailsAnalysisBGColor;
  padding: 32px 40px 32px 40px;

  .cx-drill-analysis--pointData {
    margin-right: 43px;
    overflow-y: scroll;
    padding-right: 17px;
    max-width: @detailsPointAnalysisMaxWidth;
    min-width: 400px;

    .cx-drill-analysis--pointData__item:nth-child(n+2) {
      border-top: 1px @detailsAnalysisPointDataBorder solid;
      padding-top: 14px;
    }
    .cx-drill-analysis--pointData__item {
      padding-bottom: 9px;
    }

    // ::-webkit-scrollbar-thumb:hover {
    //   background: @detailsAnalysisScrollThumbHoverColor;
    // }
  }

  .cx-drill-analysus--holeData {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    flex-wrap: wrap;
    //margin-left: 30px;
    max-height: @detailsHoleAnalysisMaxWidth;

    .cx-drill-analysus--holeData__item {
      flex: 1 1 50px;
    }

    .cx-vertical-property {
      margin: 0 50px 5px 0;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: @detailsAnalysisScrollThumbColor;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background: @detailsAnalysisScrollThumbHoverColor;
  }

  ::-webkit-scrollbar {
    width: @detailsHoleAnalysisScrollbarWidth;
  }

}

</style>