import { PointSetType } from '@/domain/pointSet/PointSetType';

const detailsPointSetModulePlugin = (store) => {
  store.subscribeAction(({ type, payload }) => {
    switch (type) {
      case 'shell/handleEntitySelection': {
        const { oldValue } = payload;
        if (oldValue && oldValue.entityType === PointSetType) {
          store.dispatch('detailsPointSet/handlePointSetDeselection', oldValue.uuid, { root: true });
          break;
        }
      }
    }
  });
};

export default detailsPointSetModulePlugin;