export enum DynamicFilterExcludedFields {
  CenterLineDistance = 'CenterLineDistance',
  GNSSCQ3D = 'GNSSCQ3D',
  Group = 'Group',
  HeightDistance = 'HeightDistance',
  DrillPileDepth = 'DrillPileDepth',
  LogName = 'LogName',
  PanelSerialNumber = 'PanelSerialNumber',
  SideDistance = 'SideDistance',
  SideReference = 'SideReference',
  Station = 'Station'
}