<template>
  <div class="cx-point-in-time-chart">
    <cx-bar-chart
      id="chart__barChart__point_in_time"
      :plotData="volumeSeries"
      :onVolumeSelected="handleSelectedRange"
      :isRange="true"
      :intervals="intervals"
      :domain="domain"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import CxBarChart from './components/barChart/CxBarChart.vue';

export default {
  name: 'CxPointInTimeChart',
  components: {
    CxBarChart,
  },
  computed: {
    ...mapGetters('detailsRefmodel', [
      'intervals',
      'volumeSeries',
      'selectedVolume',
      'domain'
    ])
  },
  methods: {
    ...mapMutations('detailsRefmodel', ['setTimeInverval']),
    ...mapActions('detailsRefmodel', ['getVolumes', 'selectRange']),
    handleSelectedRange(range) {
      this.throttled(range);
    }
  },
  created() {
    this.throttled = _.throttle(this.selectRange, 1500);
  }
};
</script>

<style lang="less">

.cx-point-in-time-chart {
  flex: 1 0 auto;
}

</style>