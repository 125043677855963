<template>
  <div class="cx-legend__item">
    <cx-icon :icon="icon" :class="[`cx-legend__item__icon--${iconClass}`]"/>
    <div class="cx-legend__item--desc">{{ description }}</div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxLegendItem',
  components: {
    CxIcon
  },
  props: {
    description: String,
    icon: String,
    iconClass: String,
  }
};
</script>

<style scoped lang="less">
@import '../../common';

.cx-legend__item {
  .flex(row, flex-start, center);
  .size(100%, @mapLegendItemHeight);

  &--desc {
    height: 14px;
    padding-left: 10px;
  }

  &--last {
    margin-bottom: 10px;
  }


  &__icon {
    &--complete {
      font-size: @customIconSize;
      color: @drillpileGreen;
    }

    &--paused {
      font-size: @customIconSize;
      color: @drillpileYellow;
    }

    &--failed {
      font-size: @customIconSize;
      color: @drillpileRed;
    }

    &--neutral {
      font-size: @minorFontSize;
      color: @textColorBase;
    }

    &--discarded-point {
      font-size: @legendUnitIconSize;
      color: rgba(255,255,255,0.2);
    }

    &--cut {
      font-size: @pointIconSize;
      color: @cutColor;
    }

    &--fill {
      font-size: @pointIconSize;
      color: @fillColor;
    }

    &--on-target {
      font-size: @pointIconSize;
      color: @onGradeColor;
    }
    &--active-unit {
      font-size: @legendUnitIconSize;
      color: @drillpileYellow;
    }
    &--inactive-unit {
      font-size: @legendUnitIconSize;
      color: @textColorDisabled;
    }
    &--awareness-red {
      font-size: @legendUnitIconSize;
      color: @redZone
    }
    &--awareness-yellow {
      font-size: @legendUnitIconSize;
      color: @yellowZone
    }
    &--awareness-green {
      font-size: @legendUnitIconSize;
      color: @greenZone
    }
    &--awareness-cabin {
      font-size: @legendUnitIconSize;
      color: @cabinZone
    }
    &--awareness-nid {
      font-size: @legendUnitIconSize;
      color: @nidZone
    }
    &--awareness-undefined {
      font-size: @legendUnitIconSize;
      color: @undefinedZone
    }
  }
}
</style>