const trackingPlugin = (store) => {

  store.subscribe((mutation, state) => {
    const vuexEvent = new CustomEvent('VUEX_EVENT', { detail: { ...mutation, state } });
    window.dispatchEvent(vuexEvent);
  });

  store.subscribeAction((action, state) => {
    const vuexEvent = new CustomEvent('VUEX_EVENT', { detail: { ...action, state } });
    window.dispatchEvent(vuexEvent);
  });

};

export default trackingPlugin;