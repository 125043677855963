import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const truncatePath = (path, maxChars) => {
  const rootFolder = $gettext('All Files/');
  if (path === '/' || !path) return rootFolder;
  if (path.charAt(0) === '/') path = path.substring(1);
  if (path.length <= maxChars) return `${rootFolder}${path}`;

  // full path is too long - need to truncate it
  const chunks = path.split('/').reverse();
  if (chunks.length < 2) return `${rootFolder}${path}`;
  const result = chunks.reduce((truncPath, chunk, index) => {
    const acc = `/${chunk}${truncPath}`;
    // last chunk is always included, no matter how long it is
    if (acc.length >= maxChars && index !== 0) return truncPath;
    return acc;
  }, '');
  return `${rootFolder}...${result}`;
};


const withFullPath = (entity, maxChars = 50) => {
  let fullPath = null;
  if (Object.hasOwnProperty.call(entity, 'path')) {
    fullPath = truncatePath(entity.path, maxChars);
  }
  return { ...entity, fullPath };
};

export { withFullPath };