import { NumberComparators } from '@/domain/filters/NumberComparators.enum';
import { Operators } from './Operators.enum';
import { FilterModel } from './FilterModel';

/**
 * Creates filter model object
 */
const createFilter =
  (fieldName: string, values: [] = [], filterComparators: string[] | null = null, operator: Operators = Operators.OR): FilterModel => {
    return {
      fieldName,
      values,
      comparators: filterComparators || values.map(() => NumberComparators.Equals),
      operator,
    };
  };

export {
  createFilter
};