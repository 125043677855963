<template>
  <div class="cx-menu" v-if="isVisible">
    <div
      class="cx-menu__item"
      v-for="(item, index) in collection"
      :key="index"
      @click="onItemClicked(item)">
      <span
        class="cx-menu__item__name"
        :class="{ 'cx-menu__item__name--highlight': selectedItemName === item[nameField], 'disabled': item.disabled }">
        {{ item[nameField] }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CxMenu',
  props: {
    isVisible: Boolean,
    collection: Array,
    nameField: String,
    selectedItemName: String
  },
  emits: ['click'],
  methods: {
    onItemClicked(item) {
      if (this.isDisabled || item.disabled) return;
      this.$emit('click', item);
    }
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-menu {
  background-color: @bgColor1;
  border: 1px solid @bgColor1;
  border-radius: @inputBorderRadius;

  .cx-menu__item {
    .flex(column, flex-start, flex-start);
    padding: 5px;

    .cx-menu__item__name  {
      width: 100%;
      padding: 5px 7px;
      border-radius: @inputBorderRadius;
      color: @textColor;
      text-align: left;
      cursor: pointer;
      .cut-overflow();

      &:not(.cx-menu__item__name--highlight) {
        &:hover:not(.disabled) {
          background-color: @inputBackgroundHover;
          color: @textColorHighlight;
        }
      }

      &.disabled {
        color: @textColorLowlight;
        cursor: default;
      }

      &--highlight {
        background-color: @dropdownActiveItem;
      }
    }
  }
}
</style>