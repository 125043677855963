<template>
  <div class="cx-delta-panel">
    <div class="cx-detla--label">
      <i class="material-icons"> details </i> {{ deltaLabel }}
    </div>
    <div>
      <div class="cx-detla--value">
        <span>{{ deltaValue }}</span>
        <span class="cx-delta--value__total" :style="{ color: deltaColor}"> / {{ totalValue }}</span>
      </div>
      <div class="cx-detla--arrow" :style="{ color: deltaColor}">
        <cx-delta :values="deltaValues" :trend="trend" :unit="unit"/>
      </div>
    </div>
    <div class="detla__progress">
      <cx-progress-bar :plotData="plotData" :color="deltaColor"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CxProgressBar from './components/CxProgressBar';
import CxDelta from './components/CxDelta';

export default {
  name: 'CxDeltaPanel',
  components: {
    CxProgressBar,
    CxDelta,
  },
  props: {
    previusValue: Number,
    currentValue: Number,
    deltaValue: Number,
    trend: String,
    unit: String,
    totalValue: [Number, String],
    deltaLabel: String,
    label: String,
    deltaColor: String
  },
  computed: {
    deltaValues() {
      return [this.previusValue, this.currentValue];
    },
    plotData() {

      let value = this.currentValue;
      const { previusValue } = this;
      let total = this.totalValue;
      let delta = this.deltaValue;
      if (total === 0) { total = 1; }
      if (!total || !value || !previusValue) return null;
      // if job done is greater than the initial work to be done
      // the progress should be full
      if (this.previusValue > this.totalValue
        || Math.abs(this.deltaValue) > this.totalValue
        || this.currentValue > this.totalValue) {
        value = total;
        delta = 0;
      }
      return {
        total: _.isNumber(total) ? total : Number.MAX_SAFE_INTEGER,
        series: [
          {
            label: this.label,
            value: _.isNumber(value) && !_.isNil(value) ? value : 0,
            delta: _.isNumber(delta) && !_.isNil(delta) ? delta : 0,
          }
        ]
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-delta-panel {
  margin-top: 20px;
  line-height: 1.5;
}

.cx-detla--label {
  font-size: @appFontSize;
  color: @textColorDisabled;
  i {
    font-size: @appFontSize;
    transform: rotate(180deg);
    vertical-align: text-top;
  }
 }
 .cx-detla--value {
    font-size: @appFontSize;
    display: inline-block;
  }
  .cx-detla--arrow {
    display: inline-block;
    float: right;

  }

</style>