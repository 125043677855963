<template>
  <div class="cx-app">
    <cx-loading-screen/>
    <cx-home/>
    <cx-toast v-if="Object.keys(alert).length"/>
    <cx-direct-search/>
    <div class="cx-legend-and-sos-button-container" :class="applyClass">
      <cx-multi-message-wrapper v-if="hasAwarenessLicence"/>
      <cx-sos-button v-if="hasAwarenessLicence"/>
      <cx-legend/>
    </div>
    <cx-map-toolbar/>
    <cx-map-navigation/>
    <cx-sos-popup popupId="popup-awareness-sos" :isOpened="isSosPopupOpen" v-if="isSosPopupOpen"/>
    <cx-message-popup popupId="popup-awareness-message" :isOpened="isMessagePopupOpened" v-if="isMessagePopupOpened"/>
    <cx-kpi-popup popupId="popup-awareness-kpi" :isOpened="isKpiPopupOpen" v-if="isKpiPopupOpen"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import handleParentContext from '@/utils/parentContext/parentContextHandler';
import CxToast from './app/notifications/CxToast';
import CxDirectSearch from './app/directSearch/CxDirectSearch';
import CxMapToolbar from './app/mapControls/CxMapToolbar';
import CxMapNavigation from './app/mapControls/CxMapNavigation';
import CxSosButton from './app/awareness/sos/CxSosButton.vue';
import CxMultiMessageWrapper from '@/app/awareness/multiMessage/CxMultiMessageWrapper';
import CxSosPopup from './app/awareness/sos/CxSosPopup.vue';
import CxMessagePopup from './app/awareness/message/CxMessagePopup.vue';
import CxLegend from '@/app/legend/CxLegend';
import CxLoadingScreen from '@/app/CxLoadingScreen';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxKpiPopup from '@/app/awareness/kpi/CxKpiPopup';
import CxHome from '@/app/shell/CxHome';

export default {
  components: {
    CxHome,
    CxLegend,
    CxToast,
    CxDirectSearch,
    CxMapToolbar,
    CxMapNavigation,
    CxLoadingScreen,
    CxSosButton,
    CxSosPopup,
    CxMessagePopup,
    CxKpiPopup,
    CxMultiMessageWrapper,
  },
  mixins: [CxAnimationMixin, CxWaffleFlagMixin, CxLicenceMixin],
  created() {
    const { projectUuid, mapTerrain, mapImagery, animations } = this.getUrlParams();
    if (mapTerrain === 'false') this.setMapTerrain(false);
    if (mapImagery === 'false') this.setMapImagery(false);
    if (animations === 'false') this.setUseAnimations(false);

    handleParentContext(this.initializeApp, projectUuid);
  },
  computed: {
    ...mapGetters('shell', ['isDetailsPanelOpened', 'isDetailsPanelReduced']),
    ...mapGetters('popup', ['isOpened']),
    ...mapGetters('notifications', ['alert', 'snackbar']),
    applyClass() {
      const animateShowPanel = this.applyAnimations('showDetailsPanel');
      const animateCollapsePanel = this.applyAnimations('collapseDetailsPanel');
      return {
        'cx-legend-and-sos-button-container--raised': this.isDetailsPanelOpened,
        'cx-legend-and-sos-button-container--reduced': this.isDetailsPanelOpened && this.isDetailsPanelReduced,
        [animateShowPanel]: this.isDetailsPanelOpened,
        [animateCollapsePanel]: !this.isDetailsPanelOpened
      };
    },
    isKpiPopupOpen() {
      return this.isOpened('popup-awareness-kpi');
    },
    isSosPopupOpen() {
      return this.isOpened('popup-awareness-sos');
    },
    isMessagePopupOpened() {
      return this.isOpened('popup-awareness-message');
    }
  },
  methods: {
    ...mapMutations('app', ['setMapTerrain', 'setMapImagery']),
    ...mapMutations('shell', ['setUseAnimations']),
    ...mapActions(['initializeApp']),
    getUrlParams() {
      const paramsToCheck = ['projectUuid', 'mapTerrain', 'mapImagery', 'animations'];

      function getParameterByName(name, url) {
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }

      return paramsToCheck.reduce((acc, item) => {
        acc[item] = getParameterByName(item, window.location.href);
        return acc;
      }, {});
    }
  }
};
</script>

<style lang="less">
@import './common';

 * {
    box-sizing: border-box;
  }

  html {
    overflow-y: hidden;
    margin: 0;
    //todo: create global styles

    body {
      background-color: @appBodyBackgroundColor;
      margin: 0;
      #app {
        font-family: @appFontFamily;
        font-weight: @appFontWeight;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: @appBodyTextColor;
        box-sizing: border-box;
        margin: 0;
        .flex(row, space-around, stretch);
        padding: 0;
        margin: 0;
      }

      // in case debug container is turned on
      // make sure it's not covered by imagery controls
      .cesium-performanceDisplay-defaultContainer {
        top: 100px;
      }

      .cx-direct-search {
        z-index: @directSearchZI;
      }

      .cx-legend-and-sos-button-container {
        z-index: @legendAndMessagingZI;
        position: fixed;
        bottom: 30px;
        right: @inputSpace;
        width: @mapLegendWidth;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &.cx-legend-and-sos-button-container--raised {
          bottom: @detailsHeight + 8px;
        }

        &.cx-legend-and-sos-button-container--reduced {
          bottom: @detailsReducedHeight + 8px;
        }

      }
    }
  }

  .cx-app {
    line-height: 1.5;
    flex: 1 1 auto;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    position: relative;
  }

  button, input, select, textarea {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font: inherit;
  }

  optgroup {
    font: inherit;
  }
  * {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
  }
</style>
