import { parseISO } from 'date-fns';
import { NumberValue } from '@/viewModel/numberValue';
import { TextValue } from '@/viewModel/textValue';
import datetimehelper from '@/utils/datetimehelper';
import appStore from '../store/appModule/app.store';

export const units = {
  meters: 'm',
  feet: 'ft.',
  degrees: '°',
  no: ''
};

const lengthValues = [
  'gnss',
  'gnsscq3d',
  'drillPileDepth',
  'deltaHeight',
  'northing',
  'easting',
  'height',
  'ellipsoidHeight',
  'heightOffset',
  'sideDistance',
  'toolDiameter',
  'pileHeight',
  'cutFill'
];

const degreesValues = [
  'targetHoleAngle',
  'latitude',
  'longitude',
  'targetHoleHeading',
  'actualToolAngle',
  'actualToolHeading',
];

const fieldsDefinitions = {
  targetHoleAngle: (entity, unit) => new NumberValue(entity.targetHoleAngle, unit),
  gnss: (entity, unit) => new NumberValue(entity.gnss, unit),
  gnsscq3d: (entity, unit) => new NumberValue(entity.gnsscq3d, unit, 6),
  drillPileDepth: (entity, unit) => new NumberValue(entity.drillPileDepth, unit, 2),
  equipmentType: (entity) => new TextValue(entity.equipmentType, false, false),
  unitType: (entity) => new TextValue(entity.unitType, true, true),
  deltaHeight: (entity, unit) => new NumberValue(entity.deltaHeight, unit, 2),
  northing: (entity, unit) => new NumberValue(entity.northing, unit, 3),
  easting: (entity, unit) => new NumberValue(entity.easting, unit, 3),
  latitude: (entity, unit) => new NumberValue(entity.latitude, unit, 6),
  longitude: (entity, unit) => new NumberValue(entity.longitude, unit, 6),
  height: (entity, unit) => new NumberValue(entity.height, unit, 3),
  ellipsoidHeight: (entity, unit) => new NumberValue(entity.ellipsoidHeight, unit, 3),
  coordinateSystem: (entity) => new TextValue(entity.coordinateSystem, false, false),
  heightOffset: (entity, unit) => new NumberValue(entity.heightOffset, unit, 6),
  sideDistance: (entity, unit) => new NumberValue(entity.sideDistance, unit, 3),
  targetHoleHeading: (entity, unit) => new NumberValue(entity.targetHoleHeading, unit, 6),
  actualToolAngle: (entity, unit) => new NumberValue(entity.actualToolAngle, unit, 6),
  actualToolHeading: (entity, unit) => new NumberValue(entity.actualToolHeading, unit, 6),
  toolDiameter: (entity, unit) => new NumberValue(entity.toolDiameter, unit),
  pileHeight: (entity, unit) => new NumberValue(entity.pileHeight, unit, 6),
  logTime: (entity) => {
    const errorResult = { value: 'Invalid date', displayValue: 'Invalid date' };
    if (!entity.logTime) return errorResult;
    try {
      return {
        displayValue: `${datetimehelper.format(parseISO(entity.logTime), datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
        value: `${datetimehelper.format(parseISO(entity.logTime), datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
        _value: entity.logTime,
      };
    } catch (e) {
      if (e instanceof RangeError) return errorResult;
      throw e;
    }
  },
  lastUpdatedAt: (entity) => {
    const errorResult = { value: 'Invalid date', displayValue: 'Invalid date' };
    if (!entity.lastUpdatedAt) return null;
    try {
      return {
        displayValue: `${datetimehelper.format(parseISO(entity.lastUpdatedAt), datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
        value: `${datetimehelper.format(parseISO(entity.lastUpdatedAt), datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
        _value: entity.lastUpdatedAt,
      };
    } catch (e) {
      if (e instanceof RangeError) return errorResult;
      throw e;
    }
  },
  panelSerialNumber: (entity) => new TextValue(entity.panelSerialNumber, false, false),
  station: (entity) => new TextValue(entity.station, false, false),
  cutFill: (entity, unit) => new NumberValue(entity.cutFill, unit, 2),
  uuid: (entity) => new TextValue(entity.uuid, false, false),
  pointCode: (entity) => new TextValue(entity.pointCode, true, false)
};

/**
 * Returns default unit for value or null
 * @param {String} fieldName Name of the field
 */
export const getUnit = (fieldName) => {
  const baseLengthUnit = appStore.getters.measurementSystem(appStore.state).baseLengthUnit.abbr();
  const lengthUnit = lengthValues.includes(fieldName) ? baseLengthUnit : null;
  const degreeUnit = degreesValues.includes(fieldName) ? units.degrees : null;
  return lengthUnit || degreeUnit || units.no;
};

/**
 * Returns TextValue or NumberValue object created from a field
 * of the entity.
 * If a field is not present at the object, throws an error
 * @param {} entity - entity object
 * @param {*} fieldName - field of the entity object to display
 * @param {String} unit - unit for the field, if not present default unit will be used
 */
export const createValue = (entity, fieldName, unit = null) => {
  const computedUnit = unit || getUnit(fieldName);
  if (fieldsDefinitions[fieldName]) return fieldsDefinitions[fieldName](entity, computedUnit);

  // if (!entity[fieldName] && entity[fieldName] !== 0) throw new Error(`Can't create a display representation for point entity - unknown field: ${fieldName}`);
  // console.warn(`TextValue created by default for ${fieldName}: ${entity[fieldName]}`);
  return new TextValue(entity[fieldName], true, false);
};

export const createValueMixin = (object) => {
  return (fieldName, unit) => createValue(object, fieldName, unit);
};
