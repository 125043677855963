<template>
  <div class="cx-live-mode-button">
    <cx-button
      :class="{'cx-live-mode-button--live': isLive}"
      flat
      :tooltip="{
        position: 'bottom-center',
        offsetTop: -4,
        text: isLive ? translations.tooltipTextOff : translations.tooltipTextOn
      }"
      :loading="isLocationHistoryLoading"
      @click="toggleLiveMode">
      <span class="cx-live-mode-button--text">{{ translations.buttonText }}</span>
    </cx-button>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CxLiveModeButton',

  components: {
    CxButton
  },
  data() {
    return {
      liveTimerId: null,
      liveTimeoutDuration: 5000
    };
  },
  computed: {
    ...mapGetters('map', ['isLive', 'loadedUnits', 'startDate', 'endDate', 'isLocationHistoryLoading']),
    translations() {
      return {
        tooltipTextOn: this.$gettext('Start Live Mode'),
        tooltipTextOff: this.$gettext('Return to Timeline Mode'),
        buttonText: this.$gettext('Live'),
      };
    }
  },
  methods: {
    ...mapActions('map', ['setTimeRange', 'jumpToEnd', 'mapLoadUnitLocations']),
    ...mapMutations('map', ['setIsLive']),
    toggleLiveMode() {
      this.setIsLive(!this.isLive);
      if (this.isLive) this.turnLiveModeOn();
      else this.turnLiveModeOff();
    },
    turnLiveModeOn() {
      const self = this;
      this.liveTimerId = setTimeout(async function refreshLocationHistory() {
        const dateTo = new Date(Date.now());
        await self.mapLoadUnitLocations({ units: self.loadedUnits, dateFrom: self.endDate, dateTo });
        self.setTimeRange({ startDate: self.startDate, endDate: dateTo });
        self.jumpToEnd();
        self.liveTimerId = setTimeout(refreshLocationHistory, self.liveTimeoutDuration);
      });
    },
    turnLiveModeOff() {
      clearTimeout(this.liveTimerId);
    },
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-live-mode-button {
  .cx-button {
    color: @baseBackgroundColor;
    padding: 0 10px;
    background-color: @turquoiseAccentColor;
    border-radius: @inputBorderRadius;

    &:hover:not([disabled]):not(.cx-button--loading) {
      color: @baseBackgroundColor;
      background-color: @turquoiseAccentColorHighlight !important;
    }

    .cx-spinner {
      color: @baseBackgroundColor;
    }
  }
  .cx-button.cx-live-mode-button--live {
    color: @appBodyTextColor;
    background-color: @highlightRedColor !important;

    &:hover:not([disabled]):not(.cx-button--loading) {
      color: @appBodyTextColor;
      background-color: @highlightRedColor60 !important;
    }

    .cx-spinner {
      color: @appBodyTextColor;
    }
  }
}

</style>