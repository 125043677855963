import { helpers, required, integer, maxLength } from '@vuelidate/validators';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const validationRules = () => {

  const cxFloat = (value) => {
    return helpers.regex(value, /^[-]?\d+\.?\d*$/) && !Number.isNaN(parseFloat(value));
  };

  const cxGreaterThan = (minimum) => (value) => {
    return parseFloat(value) > parseFloat(minimum);
  };
  const cxLessThan = (maximum) => (value) => {
    return parseFloat(value) < parseFloat(maximum);
  };

  return {
    required: helpers.withMessage($gettext('is required'), required),
    integer: helpers.withMessage($gettext('must be a whole number'), integer),
    cxFloat: helpers.withMessage($gettext('must be a number'), cxFloat),
    maxLength: (max) => (helpers.withMessage($gettext('must be shorter'), maxLength(max))),
    cxGreaterThan: (minimum) => {
      const text = $gettext('must be greater than');
      const message = `${text} ${minimum}`;
      return helpers.withMessage(message, cxGreaterThan(minimum));
    },
    cxLessThan: (maximum) => {
      const text = $gettext('must be less than');
      const message = `${text} ${maximum}`;
      return helpers.withMessage(message, cxLessThan(maximum));
    }
  };
};

export default validationRules;