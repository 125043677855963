<template>
  <div class="cx-map-toolbar">
    <div
      class="cx-map-toolbar__button cx-map-toolbar__button--home"
      @mouseenter="isTooltipHome = true"
      @mouseleave="isTooltipHome = false; isHomeButtonHeld = false"
      @mousedown="isHomeButtonHeld = true"
      @mouseup="isHomeButtonHeld = false"
      v-cx-tooltip="{
        text: homeTooltip,
        show: isTooltipHome,
        offsetTop: -10
      }">
      <cx-button
        class="cx-map-toolbar__button--home__icon"
        :class="isHomeButtonActive"
        icon="home"
        @click="flyHome"
        :iconSize="20"
        flat/>
    </div>
    <div
      class="cx-map-toolbar__button cx-map-toolbar__button--info-box"
      @mouseenter="showInfoBoxTooltip"
      @mouseleave="isTooltipInfoBox = false"
      v-cx-tooltip="{
        text: infoBoxTooltip,
        show: isTooltipInfoBox,
        offsetTop: -10
      }">
      <cx-button
        flat
        class="cx-map-toolbar__button--info-box__icon"
        :class="isInfoBoxActive"
        icon="mdi-help-circle"
        @click="toggleInfoBox"
        :iconSize="20"/>
      <keep-alive>
        <transition :name="applyAnimations('content')">
          <cx-map-info-box
            v-if="isInfoBoxVisible"
            :instructions="navigationInstructions"/>
        </transition>
      </keep-alive>
    </div>
    <div
      class="cx-map-toolbar__button cx-map-toolbar__button--screenshot"
      @mouseenter="isTooltipScreenshot = true"
      @mouseleave="isTooltipScreenshot = false; isScreenshotButtonHeld = false"
      @mousedown="isScreenshotButtonHeld = true"
      v-cx-tooltip="{
        text: screenshotTooltip,
        show: isTooltipScreenshot,
        offsetTop: -10
      }">
      <cx-button
        flat
        class="cx-map-toolbar__button--screenshot__icon"
        :class="isScreenshotButtonActive"
        icon="image"
        @click="takeScreenshot"
        :iconSize="20"/>
    </div>
    <div class="cx-map-toolbar__button cx-map-toolbar__button--base-layer-picker">
      <img
        class="cx-map-toolbar__button--base-layer-picker__icon"
        :class="isBaseLayerPickerActive"
        :src="baseLayerPicker.iconUrl"
        @click="toggleBaseLayerPicker"
        @mouseenter="isTooltipBaseLayerPicker = true"
        @mouseleave="isTooltipBaseLayerPicker = false"
        v-cx-tooltip="{
          text: baseLayerPicker.tooltip,
          show: isTooltipBaseLayerPicker,
          offsetTop: -7,
          offsetLeft: -30
        }"/>
      <keep-alive>
        <transition :name="applyAnimations('content')">
          <cx-map-base-layer-picker
            v-if="isBaseLayerPickerVisible"
            :available="availableBaseLayers"
            :selected="selectedBaseLayer"
            :onSelection="handleBaseLayerSelection"/>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxButton from '@/components/CxButton';
import CxMapInfoBox from './CxMapInfoBox';
import CxMapBaseLayerPicker from './CxMapBaseLayerPicker';
import defaultBaseLayerIconUrl from '../../../public/imagery/mapbox-satellite.png';

export default {
  name: 'CxMapToolbar',

  components: {
    CxButton,
    CxMapInfoBox,
    CxMapBaseLayerPicker
  },

  mixins: [CxAnimationMixin],

  data() {
    return {
      isHomeButtonHeld: false,
      isScreenshotButtonHeld: false,
      isInfoBoxVisible: false,
      isBaseLayerPickerVisible: false,
      isTooltipHome: false,
      isTooltipInfoBox: false,
      isTooltipScreenshot: false,
      isTooltipBaseLayerPicker: false,
    };
  },

  computed: {
    ...mapGetters('map', ['navigationInstructions', 'availableBaseLayers', 'selectedBaseLayer']),

    baseLayerPicker() {
      return this.selectedBaseLayer ? {
        iconUrl: this.selectedBaseLayer.iconUrl,
        tooltip: this.selectedBaseLayer.tooltip
      } : {
        iconUrl: defaultBaseLayerIconUrl,
        tooltip: this.$gettext('Mapbox Satellite')
      };
    },

    isHomeButtonActive() {
      return this.isHomeButtonHeld ? 'active' : '';
    },

    isInfoBoxActive() {
      return this.isInfoBoxVisible ? 'active' : '';
    },

    isBaseLayerPickerActive() {
      return this.isBaseLayerPickerVisible ? 'active' : '';
    },

    isScreenshotButtonActive() {
      return this.isScreenshotButtonHeld ? 'active' : '';
    },
    homeTooltip() { return this.$gettext('View Home'); },
    screenshotTooltip() { return this.$gettext('Take Screenshot'); },
    infoBoxTooltip() { return this.$gettext('Navigation Instructions'); }
  },

  methods: {
    ...mapActions('map', ['flyHome', 'handleBaseLayerSelection', 'captureScreenshot']),

    toggleInfoBox() {
      this.isBaseLayerPickerVisible = false;
      this.isInfoBoxVisible = !this.isInfoBoxVisible;
    },

    toggleBaseLayerPicker() {
      this.isInfoBoxVisible = false;
      this.isBaseLayerPickerVisible = !this.isBaseLayerPickerVisible;
    },

    showInfoBoxTooltip(e) {
      if (!e.fromElement || !e.fromElement.className) return;
      this.isTooltipInfoBox = true;
    },

    takeScreenshot() {
      this.captureScreenshot();
    },
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-toolbar {
  .size(@mapToolbarWidth, @inputHeight);
  .flex(row, space-around, center);
  position: absolute;
  top: @upperOverlaysSpace;
  right: @mapToolbarRightSpace;
  background-color: @overlayControlBackgroundColorInactive;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;
  padding: 0 0 0 3px;
  z-index: @mapToolbarZI;

  .cx-map-toolbar__button {
    cursor: pointer;
    position: relative;
  }

  .cx-map-toolbar__button--home {
    .size(@mapControlDefaultSize, @mapControlDefaultSize);
    .flex(row, center, center);

    .cx-map-toolbar__button--home__icon {
      width: @mapControlDefaultSize;
      color: @textColorDisabled;

      &:hover {
        &:not(.active) {
          color: @textColor !important;
        }
      }

      &.active {
        color: @textColorHighlight;
      }
    }
  }

  .cx-map-toolbar__button--info-box {
    .size(@mapControlDefaultSize, @mapControlDefaultSize);
    .flex(row, center, center);

    .cx-map-toolbar__button--info-box__icon {
      color: @textColorDisabled;
      width: @mapControlDefaultSize;

      &:hover {
        &:not(.active) {
          color: @textColor !important;
        }
      }

      &.active {
        color: @textColorHighlight;
      }
    }
  }
  .cx-map-toolbar__button--screenshot {
    .size(@mapControlDefaultSize, @mapControlDefaultSize);
    .flex(row, center, center);

    .cx-map-toolbar__button--screenshot__icon {
      color: @textColorDisabled;
      width: @mapControlDefaultSize;

      &:hover {
        &:not(.active) {
          color: @textColor !important;
        }
      }

      &.active {
        color: @textColorHighlight;
      }
    }
  }

  .cx-map-toolbar__button--base-layer-picker {
    .flex(column, center, center);

    .cx-map-toolbar__button--base-layer-picker__icon {
      .size(@mapControlBaseLayerSize, @mapControlBaseLayerSize);
      opacity: 0.7;
      border-radius: @inputBorderRadius;

      &:hover {
        &:not(.active) {
          opacity: 0.9;
        }
      }

      &.active {
        opacity: 1;
      }
    }
  }
}
</style>