<template>
  <cx-slope-height-map-legend :scale="slopeScale" :gradientImage="gradientImage">
    <template #title>Slope Map</template>
    <template #unit>Degrees</template>
  </cx-slope-height-map-legend>
</template>

<script>
import CxSlopeHeightMapLegend from '@/app/detailsPanel/refmodel/components/CxSlopeHeightMapLegend';
import gradientImage from '../../../../public/controls/grad-slope-v-mini.png';

export default {
  name: 'CxSlopeMapLegend',
  components: {
    CxSlopeHeightMapLegend,
  },
  data() {
    return {
      slopeScale: ['75°', '60°', '45°', '30°', '15°', '0°'],
      gradientImage
    };
  },
};
</script>
