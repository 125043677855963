<template>
  <div class="cx-metrics-unit">
    <span class="cx-display-number-unit">
      {{ units }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CxMeasureUnit',
  components: {
  },
  props: {
    unit: String,
    pow: {
      type: [Number, String],
      default: 3
    },
    className: [String, Object]
  },
  computed: {
    ...mapGetters('app', ['measurementSystem']),
    units() {
      if (this.pow === 3) return this.measurementSystem.baseVolumeUnit.abbr();
      if (this.pow === 2) return this.measurementSystem.baseAreaUnit.abbr();
      return this.unit;
    }
  }
};
</script>

<style scoped lang="less">
@import '../../../../common';

.cx-metrics-unit {
  display: inline;
}


</style>