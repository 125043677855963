const mapClockStore = {
  state: {
    isLive: false,
    isAnimating: false,
    multiplier: 5,
    currentDate: null,
    startDate: null,
    endDate: null,
  },
  getters: {
    isLive(state) {
      return state.isLive;
    },
    isAnimating(state) {
      return state.isAnimating;
    },
    multiplier(state) {
      return state.multiplier;
    },
    currentDate(state) {
      return state.currentDate;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
  },
  mutations: {
    setIsLive(state, isLive) {
      state.isLive = isLive;
    },
    setIsAnimating(state, isAnimating) {
      state.isAnimating = isAnimating;
    },
    setAnimationMultiplier(state, multiplier) {
      state.multiplier = multiplier;
    },
    setCurrentDate(state, currentDate) {
      state.currentDate = currentDate;
    },
    setStartDate(state, startDate) {
      state.startDate = startDate;
    },
    setEndDate(state, endDate) {
      state.endDate = endDate;
    },
    setTimeRange(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    }
  },
  actions: {
    async initializeClock(context, { startDate, endDate = new Date(), multiplier = 5 }) {
      await context.dispatch('viewer/initializeClock');
      await context.dispatch('setTimeRange', { startDate, endDate });
      await context.dispatch('setTime', startDate);
      await context.dispatch('multiplyAnimationSpeed', multiplier);
    },
    async animate(context, shouldAnimate) {
      context.dispatch('viewer/animate', shouldAnimate).then(() => context.commit('setIsAnimating', shouldAnimate));
    },
    async multiplyAnimationSpeed(context, multiplier) {
      context.dispatch('viewer/multiplyAnimationSpeed', multiplier).then(() => context.commit('setAnimationMultiplier', multiplier));
    },
    /**
     * Update current moment from from a 3D map signal.
     *
     * Does not notify 3D map about update.
     *
     * @param context
     * @param currentDate {Date}
     * @returns {Promise<void>}
     */
    async updateCurrentDateFromMapTick(context, currentDate) {
      context.commit('setCurrentDate', currentDate);
    },
    /**
     * Set the current time in the map store and 3D api.
     * @param context
     * @param time
     * @returns {Promise<void>}
     */
    async setTime(context, time) {
      context.dispatch('viewer/setTime', time);
      context.commit('setCurrentDate', time);
    },
    /**
     * Sets Cesium's clock view model timerange.
     *
     * @param context
     * @param startDate {Date} - Time range startDate
     * @param endDate {Date} - Time range endDate
     */
    async setTimeRange(context, { startDate, endDate }) {
      context.dispatch('viewer/setTimeRange', { startDate, endDate });
      context.commit('setTimeRange', { startDate, endDate });
    },
    async jumpToStart(context) {
      const time = context.getters.startDate;
      await context.dispatch('setTime', time);
    },
    async jumpToEnd(context) {
      const time = context.getters.endDate;
      await context.dispatch('setTime', time);
      await context.dispatch('animate', false);
    },
  }
};

export default mapClockStore;