<template>
  <div class="cx-refmodel-view">
    <transition :name="applyAnimations('filter1')">
      <cx-filter-title
        v-show="isAnimationReady"
        :title="translations.title"/>
    </transition>
    <transition :name="applyAnimations('filter2')">
      <cx-ref-model-name-filters
        v-show="isAnimationReady"
        :items="refModels"
        :input="changeNameFilterOutput"
        ref="nameFilter"/>
    </transition>
    <transition :name="applyAnimations('filter3')">
      <div class="cx-refmodel-view__results">
        <cx-dynamic-list
          multiselect
          v-show="isAnimationReady"
          :items="listItems"
          :selectedItems="selectedItems"
          :disabledItems="disabledItems"
          @update:selectedItems="handleSelectionChanged"
          :header="translations.header"
          :itemSize="37">
          <template #default="{ item, selected, disabled}">
            <cx-list-item
              :item="item"
              :selected="selected"
              :disabled="disabled"
              :warning="item.warning"
              :tooltip="item.tooltip">
              <template #before-content="{}">
                <cx-icon
                  :icon="item.icon"
                  :size="15"
                  class="cxu-color-faded">
                </cx-icon>
              </template>
            </cx-list-item>
          </template>
          <template #header-append="{}">
            <cx-warning :warning="translations.warning" v-if="displayWarning"/>
          </template>
          <template #placeholder>
            <cx-label :label="translations.noResults" class="cx-point-view__filters__result-list__placeholder"/>
          </template>
          <template #items-append>
            <div class="cx-refmodel-view__results-gap"></div>
          </template>
        </cx-dynamic-list>
      </div>
    </transition>
    <transition :name="applyAnimations('action')">
      <cx-filter-action-panel
        v-show="isAnimationReady"
        :onClearFiltersClicked="handleClearFilters"
        :onApplyClicked="applyRefModels"
        :isDisabled="!isSomeResultSelected"
        :queryOngoing="isQueryOngoing"/>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxFilterTitle from '../common/CxFilterTitle';
import CxRefModelNameFilters from './CxRefModelNameFilters';
import CxFilterActionPanel from '../common/CxFilterActionPanel';
import CxDynamicList from '@/components/list/CxDynamicList';
import CxWarning from '@/components/CxWarning';
import CxListItem from '@/components/list/CxListItem';
import CxIcon from '@/components/CxIcon';
import CxLabel from '@/components/CxLabel';

export default {
  name: 'CxFilterRefModelView',
  components: {
    CxFilterTitle,
    CxRefModelNameFilters,
    CxDynamicList,
    CxFilterActionPanel,
    CxWarning,
    CxListItem,
    CxIcon,
    CxLabel
  },
  mixins: [CxAnimationMixin],
  computed: {
    ...mapGetters('filterRefmodel', ['refModels', 'isQueryOngoing']),
    listItems() {
      return this.refModels.map(r => ({
        id: r.id,
        title: r.name,
        warning: r.isResultWarning,
        icon: r.icon,
        tooltip: r.tooltip,
      }));
    },
    selectedItems() {
      return this.refModels.filter(m => m.isResultSelected);
    },
    disabledItems() {
      return this.refModels.filter(m => !m.isResultSelectable);
    },
    isSomeResultSelected() {
      return this.refModels.some(i => i.isResultSelected);
    },
    displayWarning() {
      return this.refModels.some(i => !!i.isResultWarning);
    },
    translations() {
      return {
        title: this.$gettext('Reference Models'),
        header: this.$gettext('Results'),
        warning: this.$gettext('Large reference models'),
        noResults: this.$gettext('No results')
      };
    }
  },
  methods: {
    ...mapActions('filterRefmodel', ['changeNameFilterOutput', 'changeSelectedFilterResults', 'applyRefModels', 'clearFilters']),
    handleClearFilters() {
      this.clearFilters();
      this.$refs.nameFilter.clearChipsInput();
    },
    handleSelectionChanged(selectedItems) {
      this.changeSelectedFilterResults(selectedItems.map(i => i.id));
    }
  },
};
</script>

<style lang="less">
@import '../../../common';

.cx-refmodel-view {
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  padding: @inputSpace 10px 0 0;
  font-size: @appFontSize;

  display: flex;
  flex-direction: column;

  .cx-refmodel-view__results {
    padding-top: 25px;
    flex: 1 0;
    overflow: hidden;
    .cx-refmodel-view__results-gap {
      height: 100px;
    }
  }
}

</style>