import http from '@/http/axios.instance';
import { Coordinates, volumeMapper, VolumeChartResponse, ChartProgress, DiffModelResponse } from './calculation.mapper';
import { reverseMapMeasurementUnit } from './measurementUnit.mapper';
import { MeasurementSystem } from '@/domain/MeasurementSystem';
import { ChartVolumes } from '@/domain/point/ChartVolumes';

interface SurfaceFromPointsResponse {
  result: {
    surface: {
      calc_type: string,
      error: string,
      results: any[]
    }
  },
  result_type: string
}

interface VolumeDiff {
  error: string,
  output: any[],
  report: {
    cut_area_2d: number,
    cut_area_3d: number,
    cut_volume: number,
    fill_area_2d: number,
    fill_area_3d: number,
    fill_volume: number,
    total_area_2d: number,
    total_area_3d: number
  },
  timestamp: Date
}

const getSurfaceFromPoints = async (projectUuid: string, points: Coordinates[]): Promise<SurfaceFromPointsResponse> => {
  const calculationAPI = `/api/ui2/v1/projects/${projectUuid}/volume-calculation/`;
  const dummyTimestamp = '2018-11-30T10:03:04Z'; // not used for that function
  const pointNehList = volumeMapper.mapPointsFromStandardToNeh(points);
  const data = { timestamp: dummyTimestamp, points: pointNehList };
  const payload = {
    calculation: 'surface',
    data: [data],
    include_model: true
  };
  const surfaceFromPointsResponse = await http.post(calculationAPI, payload);
  if (!surfaceFromPointsResponse
      || !surfaceFromPointsResponse.data
      || !surfaceFromPointsResponse.data.result) {
    throw new Error('No surface returned!');
  }
  const { error, results } = surfaceFromPointsResponse.data.result.surface;
  if (error || results[0].error) {
    throw new Error(surfaceFromPointsResponse.data.result.error);
  }
  if (!results[0].output) { throw new Error('No surface returned!'); }
  return surfaceFromPointsResponse.data;
};

const getDiffModelFromPointsAndModel = async (projectUuid: string, modelUuid: string, points: Coordinates[]): Promise<VolumeDiff> => {
  const calculationAPI = `/api/ui2/v1/projects/${projectUuid}/volume-calculation/`;
  const pointNehList = volumeMapper.mapPointsFromStandardToNeh(points);
  const dummyTimestamp = '2018-11-30T10:03:04Z'; // not used for that function
  const data = { timestamp: dummyTimestamp, points: pointNehList };
  const payload = {
    calculation: 'diffmodel',
    data: [data],
    timestamp: dummyTimestamp,
    diff_refmodel: modelUuid,
    points: pointNehList,
    include_model: true
  };
  const diffModeResponse = await http.post<DiffModelResponse>(calculationAPI, payload);
  const volumeDiff = volumeMapper.mapVolumeDiffResponse(diffModeResponse.data);
  return volumeDiff.output;
};

const pointToPointDiff = async (
  projectUuid: string,
  fromPoints: Coordinates[],
  toPoints: Coordinates[],
  from: Date,
  to: Date): Promise<VolumeDiff> => {

  const calculationAPI = `/api/ui2/v1/projects/${projectUuid}/volume-calculation/`;

  const payload = {
    calculation: 'diffmodel',
    data: [{
      timestamp: from,
      points: volumeMapper.mapPointsFromStandardToNeh(fromPoints)
    },
    {
      timestamp: to,
      points: volumeMapper.mapPointsFromStandardToNeh(toPoints)
    }],
    include_model: true,
  };
  const diffModeResponse = await http.post<DiffModelResponse>(calculationAPI, payload);
  const volumeDiff = volumeMapper.mapVolumeDiffResponse(diffModeResponse.data);
  return volumeDiff.output;
};

const volumeChartAsync = async (
  projectUuid: string,
  refModelUuid: string,
  initialModelUUid: string,
  intervals: Date[],
  measurementSystem: MeasurementSystem): Promise<ChartVolumes | ChartProgress> => {
  const url = `api/ui2/v1/projects/${projectUuid}/volume-chart-async/`;
  const payload = {
    ref_model: refModelUuid,
    intervals,
    initial_model: '',
    area_unit: '',
    volume_unit: ''
  };

  if (initialModelUUid) {
    payload.initial_model = initialModelUUid;
  }
  if (measurementSystem) {
    payload.area_unit = reverseMapMeasurementUnit(measurementSystem.baseAreaUnit);
    payload.volume_unit = reverseMapMeasurementUnit(measurementSystem.baseVolumeUnit);
  }
  const response = await http.post<VolumeChartResponse>(url, payload);
  return volumeMapper.mapAsyncChartVolumes(response.data);
};

const volumeChartProgress = async (projectUuid: string, data: VolumeChartResponse): Promise<ChartVolumes | ChartProgress> => {
  const url = `api/ui2/v1/projects/${projectUuid}/volume-chart-async-progress/`;
  const payload = {
    ...data
  };
  const response = await http.post<VolumeChartResponse>(url, payload);
  const mappedResponse = volumeMapper.mapAsyncChartVolumes(response.data);
  return mappedResponse;
};

const calculationApi = {
  getSurfaceFromPoints,
  getDiffModelFromPointsAndModel,
  pointToPointDiff,
  volumeChartAsync,
  volumeChartProgress
};
export default calculationApi;
