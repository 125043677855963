import * as d3 from 'd3';

export default class SvgProgressBar {

  constructor(elementId, size, margin, cssClass, color) {
    this.size = { ...size };
    this.margin = { ...margin };
    this.elementId = elementId;
    this.cssClass = cssClass;
    this.createProgressBar();
    this.colorSchema = {
      cut: '#f56262b3',
      fill: '#2d9ccfb3',
    };
    this.color = color;
  }

  createProgressBar() {
    const svg = d3.select(`#${this.elementId}`)
      .append('svg')
      .attr('width', this.size.width)
      .attr('height', this.size.height)
      .append('g')
      .attr('class', this.cssClass);
    this.svg = svg;

    const barArea = this.svg
      .append('g')
      .attr('class', 'main-ar');
    barArea
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.size.width)
      .attr('height', this.size.height)
      .attr('fill', '#333843')
      .attr('rx', 3.5)
      .attr('ry', 3.5);
    this.barArea = barArea;
  }

  // total: 100, series: [{value, delta, label, styleName}]
  updateData(data) {
    this.rawData = data;
    const stackedTable = [];
    data.series.reduce((prev, current) => {

      let currentValue = current.value;
      if (current.delta) { currentValue += Math.abs(current.delta); }

      stackedTable.push({
        topline: data.total - prev.value || data.total,
        baseline: data.total - (prev.value + currentValue),
        baselineDelta: data.total - (prev.value + currentValue),
        deltaValue: current.delta,
        value: currentValue,
        data: current
      });
      return { value: prev.value + current.value, label: current.label };
    }, { value: 0, label: '', delta: 0 });
    this.data = stackedTable;
    this.generateXAxis();
  }

  generateXAxis() {
    if (!this.data) throw new Error('Data must exist');
    // data in percentage
    const scaleX = d3.scaleLinear()
      .domain([0, this.rawData.total])
      .range([0, this.size.width]);
    this.scaleX = scaleX;
  }

  render() {
    const transition = d3.transition()
      .ease(d3.easeExp)
      .duration(900);

    const { data } = this;
    const series = this.barArea.selectAll('.progress-serie').data(data);

    series.enter()
      .append('rect')
      .attr('class', (d) => {
        let resultClass = 'progress-serie';
        if (d.data.styleName) {
          resultClass += ` ${d.data.styleName}`;
        }
        return resultClass;
      })
      .attr('y', 0)
      .attr('height', this.size.height)
      .attr('rx', 3.5)
      .attr('ry', 3.5)
      .attr('fill', this.color)
      .transition(transition)
      .attr('x', d => this.scaleX(d.baseline))
      .attr('width', d => this.scaleX(d.value));

    series
      .attr('y', 0)
      .attr('fill', this.color)
      .transition(transition)
      .attr('x', d => this.scaleX(d.baseline))
      .attr('width', d => this.scaleX(d.value));

    // delta
    series.enter()
      .append('rect')
      .attr('class', d => (d.deltaValue < 0 ? 'delta negative-delta' : 'delta positive-delta'))
      .attr('fill', (d) => {
        if (d.deltaValue > 0) { return '#ffffff99'; }
        return this.color;
      })
      .transition(transition)
      .attr('x', d => this.scaleX(d.baseline))
      .attr('width', (d) => {
        if (!d.deltaValue) return 0;
        return this.scaleX(Math.abs(d.deltaValue));
      });

    const deltas = this.barArea.selectAll('.delta').data(data);
    deltas
      .attr('fill', (d) => {
        if (d.deltaValue > 0) { return '#ffffff99'; }
        return this.color;
      })
      .transition(transition)
      .attr('x', d => this.scaleX(d.baseline))
      .attr('width', (d) => {
        if (!d.deltaValue) return 0;
        return this.scaleX(Math.abs(d.deltaValue));
      });


    series.exit().remove();
  }
}