<template>
  <div class="cx-awareness-event-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon :icon="icon" :class="iconClassName" :size="20"/>
      <span class="map-overlay__title__name">{{ title }}</span>
    </div>
    <div class="map-overlay__subtitles">
      <span class="map-overlay__subtitles__unit">{{ unitType }}</span>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { withColor, withIcon } from '@/domain/entityMixins';

export default {
  name: 'CxHoleTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {

    iconClassName() {
      if (!this.hoveredEntity) return '';
      const coloredEntity = withColor(this.hoveredEntity);
      return `map-overlay__title__icon ${coloredEntity.color}`;
    },
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.name;
    },
    unitType() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.unitType;
    },
    icon() {
      if (!this.hoveredEntity) return '';
      return withIcon(this.hoveredEntity).icon;
    }
  },
};
</script>

<style lang="less">
@import '../../common';

.cx-awareness-event-tooltip {

  .map-overlay__subtitles {
    margin-left: 10px;
  }

}

</style>