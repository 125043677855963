<template>
  <div class="cx-simple-list">
    <div class="cx-simple-list__content" v-if="!empty && !loading">
      <!-- HEADER -->
      <div class="cx-simple-list__content__header">
        <div class="header">
          <cx-checkbox :checked="allChecked" :disabled="disabled" v-if="multiselect" @click="handleSelectAllClicked" class="cx-simple-list__content__header__selectAll"/>
          <cx-label :label="header" v-if="header"/>
        </div>
        <slot name="header-append"><div class="header__append"></div></slot>
      </div>
      <!-- ITEMS -->
      <slot name="item-panel" :isItemSelected="isItemSelected" :isItemDisabled="isItemDisabled" :toggleSelection="toggleSelection">
        <div class="cx-simple-list__content__items">
          <div @click="toggleSelection(item)" v-for="item in items" :key="item.id">
            <slot :item="item" :selected="isItemSelected(item.id)" :disabled="isItemDisabled(item.id)">
              <cx-list-item
                :item="item"
                :selected="isItemSelected(item.id)"
                :disabled="isItemDisabled(item.id)"
                :tooltip="item.tooltip"
                :class="itemClass"
                :titleClassName="itemTitleClassName"
                :subtitleClassName="itemSubtitleClassName"/>
            </slot>
          </div>
        </div>
      </slot>
    </div>
    <slot name="loading">
      <div class="cx-simple-list__loading" v-if="loading">
        <cx-spinner :loadingText="translations.loading"/>
      </div>
    </slot>
    <slot name="placeholder" v-if="empty && !loading"><div class="cx-simple-list__placeholder">{{ translations.placeholder }}</div></slot>
  </div>
</template>

<script>
import CxSpinner from '@/components/CxSpinner';
import CxListItem from '@/components/list/CxListItem';
import CxCheckbox from '@/components/CxCheckbox';
import CxLabel from '@/components/CxLabel';
/**
  Renders list of items
  By default allow single selection
  Pass multiselect prop to turn on multi selection support
 */
export default {
  name: 'CxList',
  components: {
    CxSpinner,
    CxListItem,
    CxCheckbox,
    CxLabel
  },
  props: {
    // items to display in the list
    // needs to be an object with at least id property { id }
    items: { validator(value) {
      if (!value || !Array.isArray(value)) return false;
      if (value.some(v => !v.id)) return false;
      return true;
    } },
    // set to true to allow select multiple items
    multiselect: { type: Boolean, default: false },
    // array of selected items
    selectedItems: { type: Array, default: () => { return []; } },
    // array of disabled items
    disabledItems: { type: Array, default: () => { return []; } },

    // flag for disabled state
    disabled: { type: Boolean },
    // text to display when list is empty
    placeholder: { type: String, default: null },
    // flag for loading state
    loading: { type: Boolean },
    // text to display in the header
    header: { type: String },
    itemClass: { type: [String, Object] },
    itemTitleClassName: { type: String },
    itemSubtitleClassName: { type: String },
  },
  emits: ['update:selectedItems'],
  computed: {
    empty() {
      return !this.items || !this.items.length || this.items.length < 1;
    },
    selectedIds() {
      return this.selectedItems.map(i => i.id);
    },
    disabledIds() {
      return this.disabledItems.map(i => i.id);
    },
    selectableItems() {
      return this.items.filter(i => !this.disabledIds.includes(i.id));
    },
    allChecked() {
      return !!this.selectedItems.length && this.selectableItems.length === this.selectedItems.length;
    },
    translations() {
      return {
        placeholder: this.placeholder === null ? this.$gettext('No data') : this.placeholder,
        loading: this.$gettext('Loading'),
      };
    }
  },
  methods: {
    toggleSelection(item) {
      const { disabledIds, selectedIds, disabled, multiselect, selectedItems } = this;
      const itemId = item.id;

      if (disabled || disabledIds.includes(itemId)) return;
      let newSelection;
      const isSelected = selectedIds.includes(itemId);
      if (multiselect) newSelection = selectedIds.includes(itemId) ? selectedItems.filter(i => i.id !== itemId) : [...selectedItems, item];
      else newSelection = isSelected ? [] : [item];
      this.$emit('update:selectedItems', newSelection);
    },
    isItemSelected(itemId) {
      return this.selectedIds.includes(itemId);
    },
    isItemDisabled(itemId) {
      return this.disabled || this.disabledIds.includes(itemId);
    },
    handleSelectAllClicked() {
      if (!this.allChecked) this.$emit('update:selectedItems', this.selectableItems);
      else this.$emit('update:selectedItems', []);
    }
  },
};
</script>

<style lang="less">
@import '../../common';


.cx-simple-list {
  display: flex;
  flex-direction: column;
  @margin: 8px;
  width: 100%;
  height: 100%;

  .cx-simple-list-item {
    margin-bottom: 5px;
  }

  .cx-simple-list__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .cx-simple-list__content__items {
      overflow-y: scroll;
    }
  }

  .cx-simple-list__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .cx-spinner {
      margin: auto;
    }
  }

  .cx-simple-list__placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: @textColorDisabled;
  }

  // HEADER
  .cx-simple-list__content__header {
    display: flex;
    // to accomodate for the scroll
    margin-right: 23px;
    margin-left:@margin;
    margin-bottom: @margin;

    .header {
      display: flex;
      flex: 1 1 auto;
      min-height: 20px;
      .cxu-center-v();


      .cx-checkbox {
        width: 10px;
        height: 15px;
        margin-right: @margin;
      }
    }
    .header-append {
      flex: 0 0 auto;
      display: flex;
      margin-bottom: @margin;
      .cxu-center-v();
    }
  }
}

</style>