/** Detected zone as displayed on the UI.
 * In case of Detected zone, some UI display values differ from filter values.
 * Do not use this enum for API communication.
 */
export enum DetectedZoneFilterDisplayValue {
  RedZone = 'Close',
  YellowZone = 'Near',
  GreenZone = 'Far',
  CabinZone = 'Cabin',
  NotInDangerZone = 'Not in danger',
}