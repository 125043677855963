import * as d3 from 'd3';
import _ from 'lodash';

export default class D3ChartAxis {

  scaleX;
  scaleY;


  _axisMargin = { left: 15, right: 40, top: 10 };
  _yAxis;
  _xAxis;
  _size;
  _margin;
  _svg;

  constructor(svg, style) {
    this._svg = svg;
    this._size = style.size;
    this._margin = style.margin;
  }


  plot(data, domain) {
    this._generateXAxis(data, domain.x);
    this._plotXAxis();
    this._generateYAxis(data, domain.y);
    this._plotYAxis();
  }


  _generateXAxis(data, domainX) {
    if (!data) throw new Error('Cannot generate axis - data is null or empty');
    const rangeX = [this._margin.left, this._size.width - this._margin.right];

    const scaleX = d3.scaleTime()
      .domain([_.head(domainX).timestamp, _.last(domainX).timestamp])
      .range([rangeX[0], rangeX[1]]);

    let tickDomain = [...domainX];
    if (domainX.length > 20) tickDomain = tickDomain.filter((f, i) => i % 3 === 0);
    const ticks = tickDomain.map(f => f.timestamp);

    const xAxis = d3.axisBottom()
      .tickValues(ticks)
      .tickFormat((f, d) => {
        return tickDomain[d].label;
      })
      .tickPadding(0)
      .scale(scaleX);

    this.scaleX = scaleX;
    this._xAxis = xAxis;
  }

  _plotXAxis() {
    const transform = this._size.height - this._margin.bottom;
    this._svg.append('g')
      .call(this._xAxis)
      .attr('transform', `translate(0, ${transform})`)
      .attr('class', 'xAxis')
      .moveToBack();
  }

  _generateYAxis(data, domainY, tickCount = 4) {
    if (!data) throw new Error('Cannot generate axis - data is null or empty');
    const rangeY = [this._size.height, this._margin.bottom + this._axisMargin.top];

    // we are sligtly increasing the maximum volume to have some space
    // between top of the bar and gridlines
    const maxVol = Math.ceil(domainY.maxVolume + (0.1 * domainY.maxVolume));
    const step = maxVol / tickCount;
    const dataRange = [0, step, 2 * step, 3 * step, maxVol];

    const scaleY = d3.scaleLinear()
      .domain([0, maxVol])
      .range(rangeY);

    const yAxis = d3.axisRight()
      .tickSize(-this._size.width + this._axisMargin.left) // gridlines
      .tickValues(dataRange)
      .scale(scaleY);
    this.scaleY = scaleY;
    this._yAxis = yAxis;
  }

  _plotYAxis() {
    this._svg.append('g')
      .call(this._yAxis)
      .attr('transform', `translate(${this._size.width - this._axisMargin.right}, -${this._margin.bottom})`)
      .attr('class', 'yAxis')
      .moveToBack();
  }

}