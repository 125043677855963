import { convert, metricMeasureSystem, usCustomaryUnitsSystem, baseUnits, measurementSystemNames } from '@/utils/measurementUnitHelper';
import { MeasurementUnit } from "@/domain/MeasurementUnit"
import { MeasurementSystem } from '@/domain/MeasurementSystem';
import { SurfacelogVisualizerMeasurementUnit } from '@/domain/surfacelog/SurfacelogVisualizerMeasurementUnit.enum';

/**
 * Maps the unit name from the backend to the measurement system used in UI
 * @param {String} unitName
 * @returns {Object} metricMeasureSystem | usCustomaryUnitsSystems
 */
const mapMeasurementSystem = (unitName: string): MeasurementSystem | null => {
  if (!unitName) return null;
  const name = unitName.toLowerCase();
  if (name === 'meters') return metricMeasureSystem;
  if (name === 'meter') return metricMeasureSystem;
  if (name === 'metric') return metricMeasureSystem;
  if (name === 'us customary') return usCustomaryUnitsSystem;
  if (name === 'us customary units') return usCustomaryUnitsSystem;
  if (name === 'us survey feet') return usCustomaryUnitsSystem;
  if (name === 'us_customary') return usCustomaryUnitsSystem;
  return null;
};

/**
 * Maps the measurement system used in UI back to the string used by backend
 * @param {Object} measurementSystem
 */
const reverseMapMeasurementSystem = (measurementSystem: MeasurementSystem): string => {
  if (measurementSystem === metricMeasureSystem) return 'METRIC';
  if (measurementSystem === usCustomaryUnitsSystem) return 'US_CUSTOMARY';
  throw new Error(`Cannot reverse map measure system: ${measurementSystem}`);
};

/**
 * Maps and/or converts value from original measurement system to the desired one
 * @param {Number} value value in meters send from the backend
 * @param {Number} originalValue original value in original unit send from the backend
 * @param {Object} originalUnit original unit used to save the value
 * @param {Object} targetUnit unit we want to convert to, based on the used measurement system
 */

const mapFieldWithUnit = (
  value: string,
  originalValue: string,
  originalUnit: MeasurementUnit,
  targetUnit: MeasurementUnit,
  precision: number | null = null
) => {
  if ((baseUnits as MeasurementUnit[]).includes(targetUnit)) return value;
  if (originalUnit === targetUnit) return originalValue;
  return convert(value).withPrecision(precision).from(originalUnit).to(targetUnit);
};

/**
 * Check what measurement system a value is in and converts it to target unit when needed
 * @param {Number} value value to convert
 * @param {Object} originalUnit orignal unit of the value
 * @param {Object} targetUnit unit to convert to from the metric system
 */
const mapFieldToMetricSystem = (
  value: string,
  originalUnit: MeasurementUnit,
  targetUnit: MeasurementUnit
) => {
  if ((baseUnits as MeasurementUnit[]).includes(originalUnit)) return value;
  if (targetUnit.system !== measurementSystemNames.metric) throw new Error('Target unit needs to be in a metric system');
  return convert(value).from(originalUnit).to(targetUnit);
};

/**
 * Maps unit to it's name required by the backend
 * @param {Object} unit to map
 */
const reverseMapMeasurementUnit = (unit: MeasurementUnit): string => {
  if (unit.name === 'm') return SurfacelogVisualizerMeasurementUnit.METERS;
  if (unit.name === 'ft-us') return SurfacelogVisualizerMeasurementUnit.US_SURVEY_FEET;
  if (unit.name === 'm2') return 'square_meter';
  if (unit.name === 'yd2') return 'square_yard';
  if (unit.name === 'm3') return 'cubic_meter';
  if (unit.name === 'yd3') return 'cubic_yard';
  throw new Error('Cannot reverse map the unit');
};

export {
  mapMeasurementSystem,
  reverseMapMeasurementSystem,
  mapFieldWithUnit,
  mapFieldToMetricSystem,
  reverseMapMeasurementUnit
};