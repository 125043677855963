import { createApp } from 'vue';
import App from './App.vue';
import { storeFactory } from '@/root';
import { gettext } from './translations/gettext.setup';


import { CxTooltipDirective } from '@/components/tooltip/CxTooltipDirective';

import CxMeasuredPointFilters from './app/filterPanel/asbuilts/CxMeasuredPointFilters.vue';
import CxDrillHoleFilters from './app/filterPanel/asbuilts/CxDrillHoleFilters.vue';
import CxSurfaceFilters from './app/filterPanel/asbuilts/CxSurfaceFilters.vue';
import vClickOutside from 'v-click-outside';
import VueDatePicker from '@vuepic/vue-datepicker';

import '@mdi/font/css/materialdesignicons.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/material-icons';
import './main.less';

const app = createApp(App);
app.use(storeFactory());
app.use(gettext);

const { bind, unbind } = vClickOutside.directive;
app.directive('click-outside', {
  mounted(el, binding) {
    bind(el, { value: binding.value });
  },
  beforeUnmount(el) {
    unbind(el);
  },
});

app.directive('cx-tooltip', CxTooltipDirective);

app.component('CxMeasuredPointFilters', CxMeasuredPointFilters);
app.component('CxDrillHoleFilters', CxDrillHoleFilters);
app.component('CxSurfaceFilters', CxSurfaceFilters);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');
