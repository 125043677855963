<template>
  <div class="cx-map-navigation">
    <cx-map-compass :compassDirection="compassDirection" :reorientMap="reorientMap"/>
    <cx-map-zoom :zoomIn="zoomIn" :zoomOut="zoomOut"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxMapCompass from './CxMapCompass';
import CxMapZoom from './CxMapZoom';

export default {
  name: 'CxMapNavigation',

  components: {
    CxMapCompass,
    CxMapZoom
  },

  computed: {
    ...mapGetters('map', ['compassDirection'])
  },

  methods: {
    ...mapActions('map', ['zoomIn', 'zoomOut', 'reorientMap'])
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-navigation {
  .size(@mapNavigationWidth, @mapNavigationHeight);
  .flex(column, flex-start, center);
  z-index: @mapNavigationZI;
  position: absolute;
  top: @mapNavigationUpperSpace;
  right: @inputSpace;
  pointer-events: none;

  .cx-map-zoom {
    margin-top: 15px;
  }
}
</style>