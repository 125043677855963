import contextLicenceService from '@/services/contextLicence.service';

const CxContextLicenceMixin = {
  computed: {
    allowStabilization() {
      return contextLicenceService(this.context).allowStabilization;
    },
    allowEarthmoving() {
      return contextLicenceService(this.context).allowEarthmoving;
    },
    restrictAll() {
      return contextLicenceService(this.context).restrictAll;
    }
  },
};

export default CxContextLicenceMixin;