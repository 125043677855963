import { isString, isPlainObject, isNil } from 'lodash';

/**
 *  Mix into the component to add tooltip that can be a simple string or a tooltip options object
    See cx-tooltip to see possible options
 */
const CxTooltipMixin = {
  props: {
    // must be a string or an object with minimum of text value { text: 'tooltip' }
    tooltip: {
      validator(value) {
        return isNil(value) || isString(value) || (isPlainObject(value) && value.text !== undefined);
      }
    }
  },
  computed: {
    // use to feed cx-tooltip directive
    tooltipOptions() {
      if (!this.tooltip) {
        return null;
      }
      if (isString(this.tooltip)) {
        return {
          text: this.tooltip,
        };
      }

      return {
        ...this.tooltip,
      };
    }
  }
};

export default CxTooltipMixin;