import { keyBy, isNil, isBoolean } from 'lodash';
import { downloadFileFromUrl } from '@/http/common/download.service';
import { format } from 'date-fns';
import mapFilterStore from './map.refmodels.store';
import mapUnitsStore from './map.units.store';
import mapPointsStore from './map.points.store';
import mapSelectionStore from './map.selection.store';
import mapHoverStore from './map.hover.store';
import mapToolbarStore from './map.toolbar.store';
import mapDrillHoleStore from './map.drillHole.store';
import mapDrillPatternStore from './map.drillpattern.store';
import mapClockStore from '@/store/mapModule/map.clock.store';
import mapTimelineStore from '@/store/mapModule/map.timeline.store';
import objectExolorerStore from './map.objectExplorer.store';
import surfaceStore from './map.surface.store';
import mapPersistentState from './map.persistent.state';
import mapAwarenessEventStore from './map.event.store';
import mapSurfacelogStore from './map.surfacelog.store';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';

const mapStoreModule = {
  state: {
    ...mapFilterStore.state,
    ...mapUnitsStore.state,
    ...mapPointsStore.state,
    ...mapSelectionStore.state,
    ...mapHoverStore.state,
    ...mapToolbarStore.state,
    ...mapDrillHoleStore.state,
    ...mapDrillPatternStore.state,
    ...mapClockStore.state,
    ...objectExolorerStore.state,
    ...surfaceStore.state,
    ...mapPersistentState.state,
    ...mapAwarenessEventStore.state,
    ...mapSurfacelogStore.state,
    // map that keeps points lists
    // keyed by the set id
    // that are available to be displayed on the map
    processUnitCzmlQueue: [],
    unitCZMLLoaded: false,
    selectedEntity: null,
    // keeps parent with it's all children
    parentEnityMap: {},
    lastFramesPerSecond: {
      fps: null,
      warn: false
    },
    isLoadingTiles: true
  },
  getters: {
    ...mapFilterStore.getters,
    ...mapUnitsStore.getters,
    ...mapPointsStore.getters,
    ...mapSelectionStore.getters,
    ...mapHoverStore.getters,
    ...mapToolbarStore.getters,
    ...mapDrillHoleStore.getters,
    ...mapDrillPatternStore.getters,
    ...mapClockStore.getters,
    ...objectExolorerStore.getters,
    ...surfaceStore.getters,
    ...mapPersistentState.getters,
    ...mapAwarenessEventStore.getters,
    ...mapSurfacelogStore.getters,
    selectedEntity(state, getters) {
      return getters.allLoadedEntities.find(e => e.uuid === state.selectedEntity);
    },
    hiddenEntities(state) {
      return [...state.hiddenRefmodels, ...state.hiddenPointSets, ...state.hiddenUnits, ...state.hiddenDrillHolesSets, ...state.hiddenAwarenessEventSets, ...state.hiddenSurfacelogs];
    },
    /**
     * All entities loaded to the map, also not persistent ones
     */
    allLoadedEntities(state, getters) {
      return [
        ...state.loadedUnits,
        ...getters.allPoints,
        ...state.loadedRefmodels,
        ...getters.allSets,
        ...getters.loadedDHSets,
        ...getters.loadedDH,
        ...getters.loadedAwarenessEventSets,
        ...getters.loadedAwarenessEvents,
        ...getters.loadedSurfacelogs
      ];
    },
    /**
     * entities selectable from the map
     */
    manageableEntities(state, getters) {
      return [
        ...state.loadedUnits,
        ...state.loadedRefmodels,
        ...getters.persistentSets,
        ...getters.pesistentDHSets,
        ...getters.persistentPoints,
        ...getters.loadedDH,
        ...getters.loadedAwarenessEventSets,
        ...getters.loadedAwarenessEvents,
        ...getters.loadedSurfacelogs
      ];
    },
    /**
     * All children of a given entity
     * @param uuid - of entity to find children for
     * @param entityType - if provided, child list will be filtered by entityType
     */
    entityChildren: (state, getters) => (uuid, entityType) => {
      const children = state.parentEnityMap[uuid] || [];
      const typedChildren = children.flatMap(c => getters.allLoadedEntities.filter(l => l.uuid === c));
      if (entityType) {
        return typedChildren.filter(c => c.entityType === entityType);
      }
      return typedChildren;
    },
    /**
     * Checks visibility of the entity by uuid
     * @param uuid - uuid of entity to check the visibility for
     * @returns {} - { entity, visibilityMode }, visibilityMode is an object with { default: bool } or
     * in case of drill hole set { 2D: bool, 3D: bool }
    */
    entityVisibility: (state, getters) => (uuid) => {
      const entity = getters.allLoadedEntities.find(e => e.uuid === uuid);
      if (!entity) return null;
      if (entity.entityType === DrillHoleSetType) {
        const visibilityMode = state.holeVisibilityMode[uuid] || { '2D': false, '3D': false };
        return { entity, visibilityMode };
      }
      if (Object.values(PointTypes).includes(entity.entityType)) {
        return { entity, visibilityMode: { default: getters.visiblePoints.find(vp => vp.uuid === uuid) } };
      }
      if (Object.values(StabilizationTypes).includes(entity.entityType)) {
        return { entity, visibilityMode: { default: getters.visibleDH.find(vp => vp.uuid === uuid) } };
      }
      if (getters.hiddenEntities.find(e => e === uuid)) return { entity, visibilityMode: { default: false } };
      return { entity, visibilityMode: { default: true } };
    },
    lastFramesPerSecond(state) {
      return state.lastFramesPerSecond;
    },
    isLoadingTiles(state) {
      return state.isLoadingTiles;
    }
  },
  mutations: {
    ...mapFilterStore.mutations,
    ...mapUnitsStore.mutations,
    ...mapPointsStore.mutations,
    ...mapSelectionStore.mutations,
    ...mapHoverStore.mutations,
    ...mapToolbarStore.mutations,
    ...mapDrillHoleStore.mutations,
    ...mapDrillPatternStore.mutations,
    ...mapClockStore.mutations,
    ...objectExolorerStore.mutations,
    ...surfaceStore.mutations,
    ...mapPersistentState.mutations,
    ...mapAwarenessEventStore.mutations,
    ...mapSurfacelogStore.mutations,
    setUnitCzmlLoaded(state, payload) {
      state.unitCZMLLoaded = payload;
    },
    setShowRefmodelPoints(state, payload) {
      state.showRefModelPoints = payload;
    },
    addToParentEntityMap(state, { parent, children }) {
      const otherChildren = state.parentEnityMap[parent] || [];
      state.parentEnityMap = { ...state.parentEnityMap, [parent]: [...otherChildren, ...children.map(e => e.uuid)] };
    },
    removeFromParentEntnityMap(state, { parent, child }) {
      const newChildren = state.parentEnityMap[parent].filter(c => c !== child);
      if (newChildren.length < 1) {
        delete state.parentEnityMap[parent];
        state.parentEnityMap = { ...state.parentEnityMap };
      } else {
        state.parentEnityMap = { ...state.parentEnityMap, [parent]: newChildren };
      }
    },
    setLastFramesPerSecond(state, { fps, warn }) {
      state.lastFramesPerSecond.fps = Number.isFinite(fps) ? fps : null;
      state.lastFramesPerSecond.warn = isBoolean(warn) ? warn : state.lastFramesPerSecond.warn;
    },
    setIsLoadingTiles(state, isLoadingTiles) {
      state.isLoadingTiles = isLoadingTiles;
    }
  },
  cancelableActions: {
    ...mapFilterStore.cancelableActions,
    ...mapUnitsStore.cancelableActions,
    ...mapPointsStore.cancelableActions,
    ...mapSelectionStore.cancelableActions,
    ...mapHoverStore.cancelableActions,
    ...mapToolbarStore.cancelableActions,
    ...mapDrillHoleStore.cancelableActions,
    ...mapDrillPatternStore.cancelableActions,
    ...objectExolorerStore.cancelableActions,
    ...surfaceStore.cancelableActions,
    ...mapPersistentState.cancelableActions,
    ...mapAwarenessEventStore.cancelableActions,
    ...mapSurfacelogStore.cancelableActions
  },
  actions: {
    ...mapFilterStore.actions,
    ...mapUnitsStore.actions,
    ...mapPointsStore.actions,
    ...mapSelectionStore.actions,
    ...mapHoverStore.actions,
    ...mapToolbarStore.actions,
    ...mapDrillHoleStore.actions,
    ...mapDrillPatternStore.actions,
    ...mapClockStore.actions,
    ...mapTimelineStore.actions,
    ...objectExolorerStore.actions,
    ...surfaceStore.actions,
    ...mapPersistentState.actions,
    ...mapAwarenessEventStore.actions,
    ...mapSurfacelogStore.actions,
    /**
     * Check wether entity with uuid is a manageableEntity.
     * Only manageable entities can be selected by clicking them on the map.
     * @param context
     * @param uuid - The entity's uuid.
     * @returns {Promise<boolean>} - Returns true if entinty is manageable.
     */
    async isEntityManageable(context, uuid) {
      const keyedManageables = keyBy(context.getters.manageableEntities, 'uuid');
      return !!keyedManageables[uuid];
    },
    async initializeViewer(context) {
      await context.dispatch('viewer/initialize');
    },

    /**
     * Adds entities to the map
     * @param {Array} entities list of entities that needs to be added to the map.
     * @param {String} parentUid Uuid of a parent of entities added to the map. If null - entities will be root ones
     * @param {Object} options Additional parameters { showOnMap, isPersistent, useContextColor }
     */
    async addEntities(context, {
      entities,
      parentUid,
      options,
    }) {
      if (!entities || entities.length < 1) return false;
      if (parentUid && !context.getters.allLoadedEntities.find(e => e.uuid === parentUid)) return false;
      const unitsToAdd = entities.filter(e => Object.values(UnitTypes).includes(e.entityType));
      const refmodelsToAdd = entities.filter(e => Object.values(RefmodelTypes).includes(e.entityType) && e.entityType !== RefmodelTypes.DrillPattern);
      const drillpatternsToAdd = entities.filter(e => e.entityType === RefmodelTypes.DrillPattern);
      const pointSetsToAdd = entities.filter(e => e.entityType === PointSetType);
      const holeSetsToAdd = entities.filter(e => e.entityType === DrillHoleSetType);
      const awarenessEventsToAdd = entities.filter(e => e.entityType === AwarenessEventSetType);
      const surfaceLogsToAdd = entities.filter(e => e.entityType === SurfacelogType);
      // add all items to the parent map
      if (parentUid) {
        context.commit('addToParentEntityMap', { parent: parentUid, children: entities });
      }

      // open object explorer
      context.commit('shell/setIsObjectExplorerOpened', true, { root: true });

      const showOnMap = options && !isNil(options.showOnMap) ? options.showOnMap : true;
      const isPersistent = options && !isNil(options.isPersistent) ? options.isPersistent : true;
      const useContextColor = options && !isNil(options.useContextColor) ? options.useContextColor : false;

      let result = true;
      if (unitsToAdd.length) result = result && await context.dispatch('loadUnits', { entities: unitsToAdd, showOnMap });
      if (refmodelsToAdd.length) {
        result = result && await context.dispatch('loadRefModels', { entities: refmodelsToAdd, showOnMap });
        refmodelsToAdd.forEach(e => { context.commit('map/setRefModelOpacity', { uuid: e.uuid, opacity: 1 }, { root: true }); });
      }

      if (pointSetsToAdd.length) {
        result = result && await context.dispatch('loadPoints', {
          sets: pointSetsToAdd,
          showOnMap,
          isPersistent,
          useContextColor
        });
      }
      if (holeSetsToAdd.length) result = result && await context.dispatch('loadDHSets', { sets: holeSetsToAdd, showOnMap, isPersistent, });
      if (drillpatternsToAdd.length) result = result && await context.dispatch('loadDrillPatterns', { entities: drillpatternsToAdd, showOnMap });
      if (awarenessEventsToAdd.length) result = result && await context.dispatch('loadAwarenessEvents', { entities: awarenessEventsToAdd, showOnMap });
      if (surfaceLogsToAdd.length) result = result && await context.dispatch('loadSurfaceLogs', { entities: surfaceLogsToAdd, showOnMap });
      return result;
    },


    /**
     * Shows or hides given entity
     * @param {*} context
     * @param {String} uuid: uuid of the entity to toggle visibility
     * @param {Boolean} isVisible: if true show entity, if false
     * @param {*} options: if showing/hiding requires additional params, they are passed here
     */
    async setEntityVisibility(context, { uuid, isVisible, options }) {
      if (!uuid) { throw new Error('uuid is required parameter'); }
      const entity = context.getters.allLoadedEntities.find(f => f.uuid === uuid);
      if (!entity) { throw new Error(`Can't select: ${uuid}. It does not exist in loaded collection`); }
      const type = entity.entityType;
      if (Object.values(UnitTypes).includes(type)) {
        if (isVisible) {
          await context.dispatch('mapShowUnits', { show: [{ uuid }] });
        } else {
          await context.dispatch('mapHideUnits', { hide: [{ uuid }] });
        }
      } else if (type === PointSetType) {
        if (isVisible) {
          await context.dispatch('showPoints', { setId: uuid });
        } else {
          await context.dispatch('hidePoints', { setIds: [uuid] });
        }
      } else if (type === DrillHoleSetType) {
        if (isVisible) {
          await context.dispatch('showDHSet', { setId: uuid, show: options });
        } else {
          await context.dispatch('hideDHSet', { setId: uuid });
        }
      } else if (type === RefmodelTypes.DrillPattern) {
        if (isVisible) {
          await context.dispatch('showDrillPattern', { entities: [entity] });
        } else {
          await context.dispatch('hideDrillPatterns', { entities: [entity] });
        }
      } else if (Object.values(RefmodelTypes).includes(type) && type !== RefmodelTypes.DrillPattern) {
        if (isVisible) {
          await context.dispatch('mapShowRefmodels', { refModelList: [entity] });
        } else {
          await context.dispatch('mapHideRefModels', { refModelList: [entity] });
        }
      } else if (type === AwarenessEventSetType) {
        if (isVisible) {
          await context.dispatch('showAwarenessEventSet', { uuid });
        } else {
          await context.dispatch('hideAwarenessEventSet', { uuid });
        }
      } else if (type === SurfacelogType) {
        if (isVisible) {
          await context.dispatch('mapShowSurfaceLogs', { surfaceLogList: [entity] });
        } else {
          await context.dispatch('mapHideSurfaceLogs', { surfaceLogList: [entity] });
        }
      } else { throw new Error('Can\'t change entity visibility - unrecognized entity type'); }
    },
    async setEntityHovered(context, { uuid, type }) {
      // todo: dispatch metod to hover given entity on the map
      // this is just temp, until we have the right method in cesium
      // for now the selection on map will not be synced
      context.dispatch('handleEntityHover', { uuid, type });
    },
    /**
     * Removes given entity and also all it's direct children
     * @param {*} uuid of entity to remove
     */
    async removeEntityFamily(context, { uuids }) {
      await Promise.all(uuids.map(async (uuid) => {
        if (!uuid) { throw new Error('uuid is a required parameters'); }
        const entity = context.getters.allLoadedEntities.find(f => f.uuid === uuid);
        if (!entity) { throw new Error(`Can't remove ${uuid} family as it does not exist in loaded collection`); }

        // remove all children it entity has them
        const children = context.state.parentEnityMap[uuid];
        if (children) {
          children.forEach((p) => {
            context.dispatch('removeEntity', { uuid: p });
            context.commit('removeFromParentEntnityMap', { parent: uuid, child: p });
          });
        }

        const childParentMap = Object.keys(context.state.parentEnityMap)
          .map(k => context.state.parentEnityMap[k].flatMap((c) => { return { child: c, parent: k }; }))
          .flatMap(i => i)
          .reduce((previousValue, currentValue) => {
            if (!currentValue.child) return {};
            return {
              ...previousValue,
              [currentValue.child]: currentValue.parent
            };
          }, {});

        // if entity is a child itself - remove it from the parent entity map
        const parentUuid = childParentMap[uuid];
        if (parentUuid) {
          context.commit('removeFromParentEntnityMap', { parent: parentUuid, child: uuid });
        }

        // remove the entity itself
        await context.dispatch('removeEntity', { uuid });
      }));
    },
    /**
     * Removes entity from the map
     * @param {String} uuid of entity to remove
     * @param {String} type of entity to remove
     */
    async removeEntity(context, { uuid }) {
      if (!uuid) { throw new Error('uuid is a required parameters'); }
      const entity = context.getters.allLoadedEntities.find(f => f.uuid === uuid);
      if (!entity) { throw new Error(`Can't remove ${uuid} as it does not exist in loaded collection`); }
      const { entityType } = entity;
      if (Object.values(UnitTypes).includes(entityType)) {
        await context.dispatch('deselectUnits', { uuids: [uuid] });
        await context.dispatch('unloadUnit', { entity });
      } else if (Object.values(RefmodelTypes).includes(entityType) && entityType !== RefmodelTypes.DrillPattern) {
        await context.dispatch('deselectRefmodels', { uuids: [uuid] });
        await context.dispatch('unloadRefModel', { entity });
      } else if (entityType === PointSetType) {
        await context.dispatch('deselectPointsAndPointSets', { uuids: [uuid] });
        await context.dispatch('unloadPoints', { setUuids: [uuid] });
      } else if (entityType === DrillHoleSetType) {
        await context.dispatch('deselectDH', { setId: uuid });
        await context.dispatch('unloadDHSet', { setId: uuid });
      } else if (entityType === RefmodelTypes.DrillPattern) {
        await context.dispatch('deselectDrillPattern', { uuids: [uuid] });
        await context.dispatch('unloadDrillPattern', { entity });
      } else if (entityType === AwarenessEventSetType) {
        await context.dispatch('deselectAwarenessEventSet', { uuids: [uuid] });
        await context.dispatch('removeAwarenessEvents', { entities: [entity] });
      } else if (entityType === SurfacelogType) {
        await context.dispatch('deselectSurfaceLogs', { uuids: [uuid] });
        await context.dispatch('unloadSurfaceLog', entity);
      }
    },
    /**
     * Will move map's camera to the entity of given uuid
     * @param {String} uuid of entity to move map's camera to
    */
    async setCameraToEntity(context, uuid) {
      context.dispatch('viewer/flyToEntity', { uuid });
    },
    /*
     * Will move map's camera to given geographic object
     * @param {Object} geographicObject to move map's camera to
    */
    async setCameraToGeographicObject(context, geographicObject) {
      context.dispatch('viewer/flyToGeographicObject', { geographicObject });
    },
    async captureScreenshot(context) {
      const dataUrl = await context.dispatch('viewer/getMapPNG');
      const projectName = context.rootGetters['app/projectName'];
      const filename = `conx-3d-${projectName}-${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.png`;
      downloadFileFromUrl(dataUrl, filename);
    }
  }
};

export default mapStoreModule;