import licenceService from '@/services/licence.service';
import { OperationContext } from '@/domain/OperationContext.enum';

/**
 * Checks current context and licence and returns true if productivity licence features should be enabled
 * and false otherwise
 */
const contextLicenceService = (context: OperationContext): {
  drilling: boolean,
  earthmoving: boolean
} | {
  allowStabilization: boolean,
  allowEarthmoving: boolean,
  restrictAll: boolean
} => {

  /**
   * Point is in drilling context and we have drilling licence
   */
  const allowStabilization = (): boolean => {
    if (context === OperationContext.Stabilization && licenceService.hasStabilizationLicence()) return true;
    return false;
  };

  /**
   * Point is in earthmoving context and we have earthmoving licence
   */
  const allowEarthmoving = (): boolean => {
    if (context === OperationContext.Earthmoving && licenceService.hasEarthmovingLicence()) return true;
    return false;
  };

  /**
   * restrict all access
   */
  const restrictAll = (): boolean => {
    return !allowStabilization() && !allowEarthmoving();
  };

  if (!context) return { drilling: false, earthmoving: false };
  return { allowStabilization: allowStabilization(), allowEarthmoving: allowEarthmoving(), restrictAll: restrictAll() };
};

export default contextLicenceService;
