<template>
  <cx-simple-popup :isOpened="isLicencePopupOpened" :offsetRatio="{ y: 0.4, x: 0.5}" :popupId="popupId">
    <template #title><div v-translate>Welcome to ConX 3D</div></template>
    <cx-earthmoving-licence :hasLicence="hasEarthmovingLicence"/>
    <cx-stabilization-licence :hasLicence="hasStabilizationLicence"/>
  </cx-simple-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import CxSimplePopup from '@/components/CxSimplePopup';
import CxEarthmovingLicence from '@/app/shell/licence/CxEarthmovingLicence';
import CxStabilizationLicence from '@/app/shell/licence/CxStabilizationLicence';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';

export default {
  name: 'CxLicencePopup',
  components: {
    CxSimplePopup,
    CxEarthmovingLicence,
    CxStabilizationLicence
  },
  props: {
    popupId: { type: String, default: 'popup-cx-licence' }
  },
  computed: {
    ...mapGetters('popup', ['isOpened']),
    isLicencePopupOpened() {
      if (!this.isOpened) return false;
      return this.isOpened[this.popupId];
    }
  },
  mixins: [CxLicenceMixin],
};
</script>

<style lang="less">
  @import '../../common';

.licence-content {
  width: 100%;
  max-width: @cxLicenceContentMaxWidth;
}

.content--border {
  background: @popupBackgroundColor;
  border-radius: @inputBorderRadiusRounded;
  margin-top: 16px;
  width: calc(100% - 30px);
  margin-left: 30px;
  padding-bottom: 33px;
}

.cx-licence-popup__licence-text__title {
  font-size: @h3FontSize;
  color: @textColorHighlight;
}
.cx-licence-popup__licence-text__elements {
   margin-top: 14px;
   color: @textColor;
   div {
     padding-top: 5px;
   }
   span {
     font-size: @appFontSize;
     padding-left: 9px;

   }
}

.cx-licence-popup__licence-text {
  padding-top: 30px;
  margin-left: 120px;
}

.cx-licence-popup__licence-text__note {
  color: @textColorLowlight;
  font-size: @appFontSize;
  padding-top: 21px;
  padding-right: 60px;
}

.cx-licence-popup__actions {
  margin-left: 260px;
  flex: 1;
  margin-top: 10px;

  .checkbox__icon--big i {
    font-size: 15px;
  }
  .checkbox__icon--small i {
    font-size: 15px;
  }
}

.licence-tick {
  background-color: @licenceTickBGColor;
  position: absolute;
  border-radius: 50%;

  .cx-icon {
    margin: auto;
    .size(@cxLicencePopupIconSize, @cxLicencePopupIconSize);
    margin-top: 15px;
  }
}

</style>
