import _ from 'lodash';
import * as Cesium from 'cesium';
import { PickedEntity } from './entityPicking/pickedEntity';
import { PickedPrimitive } from './entityPicking/pickedPrimitive';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';


const pickerStore = {
  state: {
    hoveringFeatures: {},
    selectedFeatures: {},
  },
  actions: {
    /**
     * Adds custom hover and select listeners to Cesium's `screenSpaceEventHandler`.
     * @param context
     * @returns {Promise<void>}
     */
    async handlePicking(context) {
      const { viewer } = context.self.rootState;
      // Get default left click handler for when a feature is not picked on left click
      const clickHandler = viewer.screenSpaceEventHandler.getInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // Handle hovering
      viewer.screenSpaceEventHandler.setInputAction((movement) => {
        const pickedFeature = viewer.scene.pick(movement.endPosition);
        const wrappedFeature = context.self._createPickedFeature(pickedFeature);
        if (wrappedFeature && wrappedFeature.uuid) {
          context.self.hover({ [wrappedFeature.uuid]: wrappedFeature });
        } else {
          context.self.hover({});
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // Handle selecting
      viewer.screenSpaceEventHandler.setInputAction((movement) => {
        const pickedFeature = viewer.scene.pick(movement.position);
        context.self.dehover(null);
        const wrappedFeature = context.self._createPickedFeature(pickedFeature);
        if (wrappedFeature && wrappedFeature.uuid) {
          context.dispatch('map/isEntityManageable', wrappedFeature.uuid, { root: true }).then((isManageable) => {
            if (isManageable) {
              context.self.select({ [wrappedFeature.uuid]: wrappedFeature });
            }
          });
        } else if (!pickedFeature) {
          context.self.select({});
          clickHandler(movement);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    /**
     * Dispatched when a selected or hovering feature changes it's canvas position.
     * @param context
     * @param id
     * @param type
     * @param position
     * @returns {Promise<void>}
     */
    async notifyOverlayPositionChange(context, { /* id, */ type, position }) {
      context.dispatch('map/showTooltip', { position, type }, { root: true });
    },
    /**
     * Deselect map features by uuid. Deselects all features if `uuids` is not passed.
     * @param context
     * @param uuids {string[]} - An array of uuids.
     * @returns {Promise<void>}
     */
    async deselect(context, uuids) {
      context.self.deselect(uuids);
    },
    async hoverMapEntity(context, hover) {
      if (!hover) {
        context.dispatch('map/handleEntityHover', {}, { root: true });
      } else {
        context.dispatch(
          'map/handleEntityHover',
          { uuid: hover.id, data: hover.data },
          { root: true }
        );
      }
    },
    /**
     * Dispatches feature selection handlers.
     * @param context
     * @param selectedEntities
     * @param returnBag
     * @returns {Promise<void>}
     */
    async selectMapEntity(context, { selectedEntities, returnBag = {} }) {
      if (!selectedEntities || selectedEntities.length < 1) {
        context.dispatch('map/handleEntitySelection', {}, { root: true });
      } else {
        context.dispatch(
          'map/handleEntitySelection',
          {
            selection: selectedEntities.map((s) => { return { uuid: s.id, type: s.type, data: s.data }; }),
            returnBag
          },
          { root: true }
        );
      }
    },
    /**
     * Hover map features based on UI interaction.
     * @param context
     * @param uuids {string[]} - An array of uuids.
     * @returns {Promise<void>}
     */
    async hoverById(context, uuids) {
      const hoverTargets = uuids.reduce(async (targets, uuid) => {
        const resolvedTargets = await targets;
        const entity = await context.dispatch('findEntity', uuid);
        const picked = context.self._createPickedFeature(entity);
        if (picked) resolvedTargets[uuid] = picked;
        return resolvedTargets;
      }, Promise.resolve({}));
      context.self.hover(hoverTargets);
    },
    /**
     * Select map features based on UI interaction.
     * @param context
     * @param uuids {string[]} - An array of uuids.
     * @param returnBag
     * @returns {Promise<void>}
     */
    async selectById(context, { uuids }) {
      const addFeatureToTargets = (feature, targets) => {
        const pickedFeature = context.self._createPickedFeature(feature);
        if (pickedFeature) targets[pickedFeature.uuid] = pickedFeature;
      };
      const selectTargets = await uuids.reduce(async (targets, uuid) => {
        const resolvedTargets = await targets;
        const mapFeature = await context.dispatch('findEntity', uuid);
        if (_.isSet(mapFeature)) mapFeature.forEach(feature => addFeatureToTargets(feature, resolvedTargets)); // a point set
        else addFeatureToTargets(mapFeature, resolvedTargets); // anything else
        return resolvedTargets;
      }, Promise.resolve({}));

      context.self.select(selectTargets, false);
    },
  },
  events: {},
  methods: {
    /**
     * Handles custom hoveringFeatures functionality based on Cesium's `Viewer.Scene.pick()`.
     * @param hoverTargets {Object} -
     * An object of `Cesium.Primitive` keyed with the features' uuids.
     */
    hover(hoverTargets) {
      const { scene } = this.rootState.viewer;
      const { hoveringFeatures, selectedFeatures } = this.state;
      const targets = { ...hoveringFeatures, ...hoverTargets };
      const isSingleHover = Object.keys(hoverTargets).length === 1;
      const positionHandler = isSingleHover ? this._createPositionHandler() : null;
      _.forOwn(targets, (feature, uuid) => {
        if (hoveringFeatures[uuid] && hoverTargets[uuid]) {
          this.dispatch('hoverMapEntity', feature.detailsPanelData);
        } else if (!hoveringFeatures[uuid]) {
          hoveringFeatures[uuid] = feature;
          // do not attach a listener if the feature is selected unless multiple selection
          const isSingleSelected = !!selectedFeatures[uuid] && Object.keys(selectedFeatures).length === 1;
          feature.hover(positionHandler, isSingleSelected, isSingleHover);
          this.dispatch('hoverMapEntity', feature.detailsPanelData);
          scene.requestRender();
        } else if (!hoverTargets[uuid]) {
          this.dehover([uuid]);
          scene.requestRender();
        }
      });
    },
    /**
     * Removes hoveringFeatures from features by their uuid.
     * @param uuids - An array of uuids.
     * @param dispatchAction - If true, dispatches 'map/hoverMapEntity'
     */
    dehover(uuids) {
      const deselection = uuids || Object.keys(this.state.hoveringFeatures);
      const { hoveringFeatures } = this.state;
      const positionHandler = this._createPositionHandler();
      deselection.forEach((key) => {
        const dehovering = hoveringFeatures[key];
        dehovering.dehover(positionHandler);
        delete hoveringFeatures[key];
      });
      this.dispatch('hoverMapEntity');
    },
    /**
     * Handles custom hoveringFeatures functionality based on Cesium's `Viewer.Scene.pick()`.
     * @param selectTargets {Object} -
     * @param returnBag {Object}
     * An object of `Cesium.Primitive` keyed with the features' uuids.
     */
    select(selectTargets, selectionNotify = true) {
      const { scene } = this.rootState.viewer;
      const { selectedFeatures } = this.state;
      const targets = { ...selectedFeatures, ...selectTargets };
      const isSingleSelect = Object.values(selectTargets).length === 1;
      const positionHandler = isSingleSelect ? this._createPositionHandler() : null;
      const selectionData = [];
      _.forOwn(targets, (feature, uuid) => {
        if (selectedFeatures[uuid] && selectTargets[uuid]) {
          selectedFeatures[uuid].select(positionHandler);
          selectionData.push(selectedFeatures[uuid].detailsPanelData);
          scene.requestRender();
        } else if (!selectedFeatures[uuid]) {
          selectionData.push(this._addToSelection(uuid, feature, positionHandler));
          scene.requestRender();
        } else if (!selectTargets[uuid]) {
          this._removeFromSelection(uuid, positionHandler);
          scene.requestRender();
        }
      });
      if (selectionNotify) this.dispatch('selectMapEntity', { selectedEntities: selectionData });
    },
    /**
     * Deselects map features by uuid.
     * @param uuids
     */
    deselect(uuids) {
      const { selectedFeatures } = this.state;
      const selectTargets = uuids ? _.omit(selectedFeatures, uuids) : {};
      this.select(selectTargets);
    },
    _addToSelection(uuid, feature, positionHandler) {
      const { selectedFeatures } = this.state;
      selectedFeatures[uuid] = feature;
      feature.select(positionHandler);
      return feature.detailsPanelData ? feature.detailsPanelData : {};
    },
    _removeFromSelection(uuid, positionHandler) {
      const { selectedFeatures } = this.state;
      const target = selectedFeatures[uuid];
      target.deselect(positionHandler);
      delete selectedFeatures[uuid];
    },
    /**
     * Wraps Cesium.Primitive in a data accessor object.
     * @param viewer
     * @param feature
     * @returns {PickedFeature} - A data accessor object for the primitive.
     */
    _createPickedFeature(feature) {
      let result = null;
      if (!feature) return result;

      const { viewer } = this.rootState;

      const isUnit = (feat) => {
        return feat instanceof Cesium.Entity &&
          feat.properties.hasProperty('cxType') &&
          Object.values(UnitTypes).includes(feat.properties.cxType.getValue());
      };

      const isAwarenessEvent = (entity) => {
        return entity instanceof Cesium.Entity &&
          entity.properties.hasProperty('cxType') &&
          entity.properties.cxType.getValue() === 'awarenessEvent';
      };

      if (feature.id && (isUnit(feature.id) || isAwarenessEvent(feature.id))) { // Entity was picked from the map
        result = new PickedEntity(viewer, feature.id);
      } else if (isUnit(feature)) { // Entity was picked from object explorer
        result = new PickedEntity(viewer, feature);
      } else if (isAwarenessEvent(feature)) {
        result = new PickedEntity(viewer, feature);
      } else if (['point', 'refmodel', 'drillHole', 'pileHole', 'drill-pattern'].includes(_.get(feature, 'id.cxType'))) {
        // map || object explorer
        result = new PickedPrimitive(viewer, feature.primitive || feature, feature.id || feature.primitive.id);
      }
      return result;
    },
    /**
     * Create a handler function that will dispatch an appropriate action on canvas position change of selected item.
     * @returns {function}
     * @private
     */
    _createPositionHandler() {
      return _.partial(this.dispatch, 'notifyOverlayPositionChange');
    },
  },
};

export default pickerStore;