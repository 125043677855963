
import { PointDrillPileStatus } from '@/domain/point/PointDrillPileStatus.enum';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { CUT_FILL_TOLERANCE } from '@/utils/constants';
import { isNil } from 'lodash';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DetectedZoneFilterDisplayValue } from '@/domain/awareness//enums/DetectedZoneFilterDisplayValue.enum';

const stabilizationColors = {
  [PointDrillPileStatus.Ok]: 'drillpile-grey',
  [PointDrillPileStatus.Log]: 'drillpile-green',
  [PointDrillPileStatus.End]: 'drillpile-green',
  [PointDrillPileStatus.Start]: 'drillpile-yellow',
  [PointDrillPileStatus.Entry]: 'drillpile-yellow',
  [PointDrillPileStatus.Paused]: 'drillpile-yellow',
  [PointDrillPileStatus.Continue]: 'drillpile-yellow',
  [PointDrillPileStatus.Failed]: 'drillpile-red'
};

const awarenessColors = {
  [DetectedZoneFilterDisplayValue.RedZone]: 'awareness-red',
  [DetectedZoneFilterDisplayValue.YellowZone]: 'awareness-yellow',
  [DetectedZoneFilterDisplayValue.GreenZone]: 'awareness-green',
  [DetectedZoneFilterDisplayValue.CabinZone]: 'awareness-cabin',
  [DetectedZoneFilterDisplayValue.NotInDangerZone]: 'awareness-nid',
};

const getCutFillColor = (deltaHeight) => {
  if (isNil(deltaHeight)) return 'unknown';
  const heightDistanceDelta = Math.abs(deltaHeight);
  if (heightDistanceDelta <= CUT_FILL_TOLERANCE) return 'on-grade';
  if (deltaHeight >= 0 && heightDistanceDelta > CUT_FILL_TOLERANCE) return 'cut';
  if (deltaHeight < 0 && heightDistanceDelta > CUT_FILL_TOLERANCE) return 'fill';
  return 'unknown';
};


const withColor = (entity) => {
  if (!entity || !Object.hasOwnProperty.call(entity, 'entityType') || !entity.entityType) return entity;

  const { entityType, drillPileStatus, deltaHeight, status, detectedZone } = entity;
  let color = null;

  if (Object.values(StabilizationTypes).includes(entityType)) color = stabilizationColors[status] || 'drillpile-unknown';
  else if (entityType === PointTypes.EarthmovingPointType) color = getCutFillColor(deltaHeight);
  else if (entityType === PointTypes.StabilizationPointType) color = stabilizationColors[drillPileStatus] || 'drillpile-unknown';
  else if (entityType === AwarenessEventType) color = awarenessColors[detectedZone] || 'awareness-undefined';
  return { ...entity, color };
};

export { withColor };