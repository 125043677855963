<template>
  <div class="cx-overlay" :class="applyClass">
    <div class="cx-overlay__controls">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'CxOverlayControls',
  computed: {
    ...mapGetters('shell', ['isDetailsPanelOpened', 'isDetailsPanelReduced']),
    applyClass() {
      return {
        'cx-overlay--raised': this.isDetailsPanelOpened,
        'cx-overlay--reduced': this.isDetailsPanelReduced
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-overlay {
  pointer-events: none;
  user-select: none;
  position: fixed;
  right: 189px;
  .flex(column, flex-end, flex-end);
  color: @textColorBase;
  font-size: @appFontSize;
  opacity: 0.9;

  &.cx-overlay--raised {
    bottom: 378px;
  }

  &.cx-overlay--reduced {
    bottom: 378px;
  }

  .cx-overlay__controls {
    height: @overlayControlHeight;
    z-index: @overlayControlsItemsZI;
    .flex(row, space-between, flex-end);
  }
}
</style>