<template>
  <div class="cx-sos-button">
    <cx-button
      flat
      :tooltip="{
        offsetLeft: -42,
        text: tooltip.text
      }"
      @click="sendSos">
      <span class="cx-sos-button--text">SOS</span>
    </cx-button>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import { mapActions } from 'vuex';

export default {
  name: 'CxSosButton',

  components: {
    CxButton
  },

  computed: {
    tooltip() {
      return {
        text: this.$gettext('Send SOS'),
      };
    }
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    sendSos() {
      this.openPopup('popup-awareness-sos');
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-sos-button {
  position: relative;
  bottom: 8px;

  .cx-button {
    width: 30px;
    height: 30px;
    padding: 5px 4px;
    background-color: @errorAlertColor;
    border-radius: @inputBorderRadius;

    &:hover:not([disabled]):not(.cx-button--loading) {
      background-color: @errorAlertColor !important;
    }
  }

}

</style>