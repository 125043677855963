<template>
  <div class="cx-label">
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CxLabel',
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "../common";

.cx-label {
  font-size: @h3FontSize;
  font-weight: 100;
  color: @textColorLowlight;
}
</style>