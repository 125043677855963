<template>
  <cx-button
    flat
    :icon="icon"
    :iconSize="iconSize"
    class="cx-toggle"
    :class="{
      'cx-toggle--active': active,
      'cx-toggle--loading': loading,
      'cx-toggle--disabled': disabled,
      'cx-toggle-variant-primary': variantPrimary,
      'cx-toggle-variant-secondary': variantSecondary && !variantPrimary }"
    @click="handleClick"
    :disabled="disabled"
    :loading="loading"
    :tooltip="tooltipOptions">
    <slot></slot>
  </cx-button>
</template>

<script>
import CxButton from '@/components/CxButton';

export default {
  name: 'CxToggle',
  components: {
    CxButton
  },
  props: {
    icon: { type: String },
    iconSize: { type: Number, default: 12 },
    active: { type: Boolean, required: true },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    tooltip: { type: [String, Object] },

    // css
    variantPrimary: { type: Boolean, default: true },
    variantSecondary: { type: Boolean },
  },
  emits: ['update:active', 'click'],
  computed: {
    tooltipOptions() {
      if (!this.tooltip) return null;
      if (this.tooltip.text) return { ...this.tooltip, offsetTop: -5 };
      return { text: this.tooltip, offsetTop: -5 };
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit('update:active', !this.active);
      this.$emit('click');
    },
  }
};
</script>

<style lang="less">
@import '../common';

.cx-toggle {

  // VARIANT-PRIMARY
  &.cx-button.cx-toggle-variant-primary {
    color: @textColor;
    &[disabled] {
      color: @textColorDisabled;
    }
    &:hover:not(.cx-toggle--active):not([disabled]) {
      color: @highlightRedColor;
    }
    &.cx-toggle--active:not([disabled]) {
      color: @highlightRedColor;
    }
  }

  // VARIANT-SECONDARY
  &.cx-button.cx-toggle-variant-secondary {
    color: @textColor;
    &[disabled] {
      color: @textColorDisabled;
    }
    &:hover:not(.cx-toggle--active):not([disabled]) {
      color: @textColorHighlight;
    }
    &.cx-toggle--active:not([disabled]) {
      color: @textColorHighlight;
    }
  }
}

</style>