<template>
  <div
    class="cx-initial-surface-selector"
    v-if="hasEarthmovingLicence">
    <cx-selector
      :label="translations.label"
      :placeholder="translations.placeholder"
      :collection="initialModels"
      nameField="name"
      valueField="uuid"
      :dropdownHeight="182"
      :onSelection="(selection) => { onInitialModelSelected(selection); isTooltipActive = false }"
      :value="selectedInitialModel"
      :isDisabled="isDisabled"
      :tooltip="translations.tooltipText"
      :warning="initialSurfaceSelectorWarning"
      :popperOptions="refModelSelectPopperOptions"/>
  </div>
</template>

<script>
import CxSelector from '@/components/menuComponents/CxSelector';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import maxSize from 'popper-max-size-modifier';
import applyMaxSize from '@/components/menuComponents/popperApplyMaxSize.modifier';
import sameWidth from '@/components/menuComponents/popperSameWidth.modifier';


export default {
  name: 'CxInitialSurfaceSelector',
  components: { CxSelector },
  mixins: [CxLicenceMixin],
  props: {
    initialModels: {
      type: Array,
      default: () => []
    },
    onInitialModelSelected: {
      type: Function,
      required: true
    },
    selectedInitialModel: {
      type: [Object, String]
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return {
        label: this.$gettext('Initial Surface'),
        placeholder: this.$gettext('No Initial Surface'),
        tooltipText: this.$gettext('Update Initial Surface')
      };
    },
    initialSurfaceSelectorWarning() {
      if (!this.isDisabled && !this.selectedInitialModel) return this.translations.tooltipText;
      return null;
    },
    refModelSelectPopperOptions() {
      return {
        placement: 'bottom-start',
        modifiers: [
          maxSize,
          applyMaxSize,
          sameWidth,
          {
            name: 'flip',
            options: {
              flipVariations: false,
              fallbackPlacements: [],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: false,
            },
          },
          { name: 'offset', options: { offset: [0, 3] } }
        ],
      };

    }

  }
};
</script>

<style lang="less">

.cx-initial-surface-selector {

  .cx-popper-content {
    overflow-y: auto;
  }
}
</style>