import * as Cesium from 'cesium';
import { cartographicCalculations } from './cartographics/cartographicCalculations';

const geocoderStore = {
  state: {
    refmodelGeocoderData: [],
    unitGeocoderData: []
  },
  actions: {
    async initializeGeocoder(context) {
      const { state } = context.self;
      const { viewer } = context.self.rootState;
      state.ionGeocoderService = new Cesium.IonGeocoderService({ scene: viewer.scene });
    },
    // callled after reference model fetched from the service
    initializeRefmodelGeocoderData(context, refmodelData) {
      context.self.state.refmodelGeocoderData = refmodelData.map((rm) => {
        return {
          id: rm.uuid,
          name: rm.name,
          path: rm.path ? `/${rm.path}` : '/',
          entityType: rm.entityType
        };
      });
    },
    initializeUnitGeocoderData(context) {
      const { currentTime } = context.self.rootState.viewer.clock;
      if (!context.self.rootState.units.units) return;
      context.self.state.unitGeocoderData = context.self.rootState.units.units.map((u) => {
        return {
          id: u.id,
          name: u.name,
          entityType: u.properties.cxType.getValue(),
          destination: u.position.getValue(currentTime)
        };
      });
    },
    async geocode(context, input) {
      const { state } = context.self;

      // prepare data for geocoding - should be done when units are loaded
      await context.self.actions.initializeUnitGeocoderData(context);
      const customGeocoderPromise = context.self.geocode(input, [...state.refmodelGeocoderData, ...state.unitGeocoderData]);
      const ionGeocoderPromise = state.ionGeocoderService.geocode(input);
      const result = await Promise.all([customGeocoderPromise, ionGeocoderPromise]);
      return [...result[0], ...result[1]];
    },
    /**
     * Makes camera look at location geocoded and chosen via direct search
     * @param {*} location
     */
    async lookAtGeocodedLocation(context, location) {
      const { camera } = context.self.rootState.viewer.scene;
      const duration = 0;
      const destination = cartographicCalculations.calculateOffset(location.destination);
      camera.flyTo({ destination, ...context.self.rootState.flyToDefaultOptions(), duration });
    }
  },
  methods: {
    /**
     * The method is provided by @interface Cesium.GeocoderService
     *  and receives text query in order to return geocodable search suggestions.
     * @param {String} query The query to be sent to the geocoder service
     * @returns {Promise} The object contains name displayed below the
     * searchbox and rectangular offset of that object, to which camera
     * will fly after selecting this result   */
    geocode(query, objects) {
      // Filters items comparing item names with given query
      const filterByText = (text, item) => {
        const searched = text.toLowerCase();
        const name = item.name.toLowerCase();
        return searched !== '' && name.indexOf(searched) > -1;
      };

      // Returns search list of objects with names passing the query test
      const searchList = objects.filter((object) => {
        return filterByText(query, object);
      });

      const searchResults = [];
      // Fills up an array of result suggestions to be displayed under Cesium searchbox
      searchList.forEach((object) => {
        const { id, entityType } = object;
        const displayName = object.name;
        const path = object.path ? object.path : null;
        searchResults.push({ id, entityType, displayName, path });
      });
      return searchResults.length ? searchResults : [];
    }
  }
};

export default geocoderStore;