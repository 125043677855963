<template>
  <div class="cx-multiselect-bar">
    <div class="cx-multiselect-bar__items">
      <cx-checkbox
        v-for="(item, index) in value"
        :key="index"
        :label="item.label"
        :checked="item.isSelected"
        @update:checked="checkedHandler(index, $event)"
        labelClass="cx-multiselect-bar__label"/>
    </div>
    <cx-reset-button
      :onReset="onReset"
      :disabled="isAllUnselected"/>
  </div>
</template>

<script>
import CxCheckbox from '@/components/CxCheckbox';
import CxResetButton from '@/components/CxResetButton';

export default {
  name: 'CxMultiselectBar',

  components: {
    CxResetButton,
    CxCheckbox
  },

  props: {
    value: Array
  },

  emits: ['onSelectedChange', 'onReset'],

  computed: {
    isAllUnselected() {
      return !this.value.some(i => i.isSelected);
    },
  },

  methods: {
    checkedHandler(index, value) {
      this.$emit('onSelectedChange', { index, value });
    },
    onReset() {
      this.$emit('onReset');
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-multiselect-bar {
  .flex(row, center, center);
  width: 100%;

  .cx-multiselect-bar__items {
    .size(100%, @inputHeight);
    .flex(row, space-around, center);
    border-radius: @inputBorderRadius;
    font-size: @appFontSize;
    background-color: @inputBackground;
    margin-right: 10px;
    .cx-multiselect-bar__label {
      padding-top: 1px;
    }
  }
}
</style>
