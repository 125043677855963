import http from '@/http/axios.instance';
import { AccountData } from '@/http/types/AccountData';

const getAccountData = async (): Promise<AccountData> => {
  const apiUrl = '/api/accounts/v1/active/';
  const response = await http.get(apiUrl);
  return response.data;
};

const accountApi = {
  getAccountData,
};

export default accountApi;