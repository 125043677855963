<template>
  <div class="cx-overlay__controls--group__items--item-container">
    <div class="cx-overlay__controls--group__items--item-legend">
      <slot name="item-legend"></slot>
    </div>
    <div
      class="cx-overlay__controls--group__items--item"
      @click="enabled && !loading && $emit('toggle')"
      @mouseenter="isTooltipActive = true"
      @mouseleave="isTooltipActive = false"
      :class="{enabled: enabled && !loading}"
      v-cx-tooltip="{
        text: title,
        show: isTooltipActive,
        offsetTop: -3,
      }">
      <cx-toggle
        :icon="icon"
        :iconSize="18"
        :active="active"
        :disabled="!enabled"
        :loading="loading"
        class="variant-overaly">
        <slot></slot>
      </cx-toggle>
    </div>
  </div>
</template>

<script>
import CxToggle from '@/components/CxToggle';

export default {
  name: 'CxOverlayControlsItem',
  components: {
    CxToggle
  },
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    left: {
      type: String,
      required: false,
      default: '-15'
    }
  },
  emits: ['toggle'],
  data() {
    return {
      isTooltipActive: false,
    };
  },
  computed: {
    isCxIcon() {
      return this.icon.startsWith('cx_');
    },
  },
  methods: {},
};
</script>

<style lang="less">
@import '../../../../common';

.cx-overlay__controls--group__items--item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-color: @overlayControlBackgroundColor;

  &:not(:has(.cx-overlay__controls--group__items--item-legend:empty)) {
    &:first-child {
      border-top-left-radius: 0;
    }
    &:last-child {
      border-top-right-radius: 0;
    }
  }

  &:only-child:not(:has(.cx-overlay__controls--group__items--item-legend:empty)) {
      .cx-overlay__controls--group__items--item {
        width: @overlayControlLegendWidth;
      }
  }

  &:first-child {
    align-items: flex-start;
    border-bottom-left-radius: @inputBorderRadius;
    border-top-left-radius: @inputBorderRadius;
  }

  &:last-child {
    align-items: flex-end;
    border-bottom-right-radius: @inputBorderRadius;
    border-top-right-radius: @inputBorderRadius;
  }


  .cx-overlay__controls--group__items--item-legend {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 100%;
    background-color: @overlayControlBackgroundColor;
    border-radius: 3px 3px 0px 0px;
  }
}


.cx-overlay__controls--group__items--item {
  .flex(row, space-around, center);
  .size(@overlayControlItemWidth, @overlayControlHeight);
  position: relative;
  cursor: default;
  pointer-events: auto;
  background-size: cover;

  &.enabled {
    cursor: pointer;
  }

  .cx-status-icon {
    padding: 0px;
  }

  // VARIANT-OVERLAY
  &.cx-button.cx-toggle-variant-overlay {
    color: @textColor;
    &[disabled] {
      color: @textColorDisabled;
    }
    &:hover:not(.cx-toggle--active):not([disabled]) {
      color: @textColorHighlight;
    }
    &.cx-toggle--active:not([disabled]) {
      color: @highlightRedColor;
    }
  }
}
</style>