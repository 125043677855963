import translationService from '@/services/translation.service';
import { gettext } from '@/translations/gettext.setup';

import { withIcon, withAvatar } from '@/domain/entityMixins';
import { metadataWarning } from '@/domain/units/UnitFactory';

const { $gettext } = gettext;


export class UnitDetailsViewModel {

  _unit;
  constructor(unit) {
    this._unit = withAvatar(withIcon(unit));
  }

  get avatar() {
    return this._unit.avatar;
  }

  get name() {
    return this._unit.name;
  }

  get subtitle() {
    const { icon } = this._unit;
    const field = $gettext('Unit Type');
    return {
      field,
      value: translationService.get(this._unit.entityType),
      icon
    };
  }

  /**
   * @return an active reference model used by an unit. The output depends on equipment used.
   * Normally, this is a single active reference model name, some integrators return multiple, coma-concatenated values though.
   * In such an edge case, a first (primary) reference model is treated as an active one.
   * In a different case, 'No reference model selected' warning is returned by API instead of null value.
   */
  get activeModel() {
    const { activeRefmodelName } = this._unit;
    const field = $gettext('Active Reference Model');
    const result = {
      field,
      value: !activeRefmodelName ? $gettext('No active reference model') : activeRefmodelName,
      icon: (activeRefmodelName === metadataWarning) ? 'warning' : 'cx_ref_model'
    };
    return result;
  }

  get note() {
    const { note } = this._unit;
    const field = $gettext('Additional Notes');
    return {
      field,
      value: note || $gettext('No additional notes'),
      icon: 'insert_drive_file'
    };
  }
}