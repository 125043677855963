import { DirectiveBinding, Directive, createVNode, render, VNode } from 'vue';
import CxTooltip from './CxTooltip.vue';
import { TooltipProps } from './TooltipProps';

const calculateAbsoluteOffset = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

type TooltipDirective = Directive<HTMLElement, TooltipProps> & { handlerMap: WeakMap<HTMLElement, { mouseOverHandler: () => void, mouseLeaveHandler: () => void, tooltip: VNode }> }

export const CxTooltipDirective: TooltipDirective = {

  // keeps map of element: registered mouse handlers to unsubcribe from them when the parent component is unmouted
  handlerMap: new WeakMap(),

  mounted: (el: HTMLElement, binding: DirectiveBinding) => {
    let tooltip = createVNode(CxTooltip, binding.value);

    const handleMouseOver = () => {
      if (!tooltip?.props?.show && tooltip?.props?.text && !binding.value.disabled) {
        const offset = calculateAbsoluteOffset(el);
        const props = { ...binding.value, show: true, parentElement: el, top: offset.top, left: offset.left };
        tooltip = createVNode(CxTooltip, props);
        render(tooltip, el);
      }
    };

    const handleMouseLeave = () => {
      if (tooltip?.props?.show) {
        tooltip = createVNode(CxTooltip, { ...binding.value, show: false, parentElement: el });
        render(tooltip, el);
      }
    };

    CxTooltipDirective.handlerMap.set(el, { mouseOverHandler: handleMouseOver, mouseLeaveHandler: handleMouseLeave, tooltip });
    el.addEventListener('mouseover', handleMouseOver);
    el.addEventListener('mouseleave', handleMouseLeave);
  },
  updated: (el: HTMLElement, binding: DirectiveBinding) => {
    const offset = calculateAbsoluteOffset(el);
    const props = { ...binding.value, show: binding.value?.show || false, parentElement: el, top: offset.top, left: offset.left };
    const tooltip = createVNode(CxTooltip, props);
    render(tooltip, el);
  },

  unmounted: (el: HTMLElement) => {

    const mouseOverHandler = CxTooltipDirective.handlerMap.get(el)?.mouseOverHandler;
    if (mouseOverHandler) el.removeEventListener('mouseover', mouseOverHandler);

    const mouseLeaveHandler = CxTooltipDirective.handlerMap.get(el)?.mouseLeaveHandler;
    if (mouseLeaveHandler) el.removeEventListener('mouseleave', mouseLeaveHandler);

    // remove tooltip from DOM
    render(null, el);

    CxTooltipDirective.handlerMap.delete(el);
  }
};

