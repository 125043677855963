export class Alert {

  title;
  subtitle;
  messages;
  isOpened;
  closeable;

  constructor(title, subtitle, messages, closeable = true) {
    this.title = title;
    this.subtitle = subtitle;
    this.messages = messages;
    this.isOpened = true;
    this.closeable = closeable;
  }

}

export class AlertMessage {

  messageType;
  message;
  icon;

  constructor(message, messageType, icon) {
    this.message = message;
    this.icon = icon;
    this.messageType = messageType;
  }

  static createErrorMessage(message) {
    return new AlertMessage(message, 'error', 'mdi-close-circle');
  }
  static createAlertMessage(message) {
    return new AlertMessage(message, 'alert', 'mdi-alert-circle');
  }

}