import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';
import { AlarmTypeFilterDisplayValue } from '@/domain/awareness/enums/AlarmTypeFilterDisplayValue.enum';

const iconMap = {
  [PointTypes.EarthmovingPointType]: 'cx_as_built',
  [PointTypes.StabilizationPointType]: 'cx_as_built',
  [PointTypes.DefaultPointType]: 'cx_as_built',
  [PointSetType]: 'cx_as_built',
  [StabilizationTypes.DrillHole]: 'adjust',
  [StabilizationTypes.PileHole]: 'radio_button_checked',
  [DrillHoleSetType]: 'radio_button_unchecked',
  [RefmodelTypes.DrillPattern]: 'apps',
  [AwarenessEventType]: 'warning',
  [AwarenessEventSetType]: 'warning',

  // model icons
  [RefmodelTypes.HelperFileType]: 'build',
  [RefmodelTypes.DefaultRefmodelType]: 'cx_ref_model',
  [RefmodelTypes.CoordinateFileType]: 'cx_ref_model',
  [RefmodelTypes.TerrainModelType]: 'cx_ref_model',
  [RefmodelTypes.RoadLineType]: 'cx_ref_model',
  [RefmodelTypes.StringlineModelType]: 'cx_ref_model',
  [RefmodelTypes.VolumeDescriptionModelType]: 'cx_ref_model',
  [RefmodelTypes.CrossFallModelType]: 'cx_ref_model',

  // unit icons
  [UnitTypes.DozerType]: 'cx_bulldozer',
  [UnitTypes.GraderType]: 'cx_grader',
  [UnitTypes.ExcavatorType]: 'cx_excavator',
  [UnitTypes.ExcavatorWheeledType]: 'cx_excavator_wheeled',
  [UnitTypes.BackhoeType]: 'cx_backhoe',
  [UnitTypes.WheelLoaderType]: 'cx_wheel_loader',
  [UnitTypes.HaulTruckType]: 'cx_haul_truck',
  [UnitTypes.FieldCrewType]: 'cx_field_crew',
  [UnitTypes.BaseStationType]: 'cx_base_station',
  [UnitTypes.DrillerType]: 'cx_driller',
  [UnitTypes.PilerType]: 'cx_piler',
  [UnitTypes.RollerType]: 'cx_roller',
  [UnitTypes.SnowGroomerType]: 'cx_snow_groomer',
  [UnitTypes.SlipformPaverType]: 'cx_slipform_paver',
  [UnitTypes.PlacerSpreaderType]: 'cx_placer_spreader',
  [UnitTypes.CurbAndGutterType]: 'cx_curb_and_gutter',
  [UnitTypes.MillingType]: 'cx_trimmer_milling',
  [UnitTypes.PaverType]: 'cx_asphalt_paver',
  [UnitTypes.TrimmerType]: 'cx_trimmer_milling',
  [UnitTypes.Vehicle]: 'place',
  [UnitTypes.CTLSkidSteer]: 'cx_unit',
  [UnitTypes.DefaultUnitType]: 'cx_unit',
};

const awarenessIconMap = {
  [AlarmTypeFilterDisplayValue.TagAlarm]: 'cx_tag_alarm',
  [AlarmTypeFilterDisplayValue.VehicleAlarm]: 'cx_vehicle_alarm',
  [AlarmTypeFilterDisplayValue.PanicAlarm]: 'cx_panic_alarm',
  [AlarmTypeFilterDisplayValue.Obstacle]: 'cx_obstacle',
  [AlarmTypeFilterDisplayValue.Overspeed]: 'cx_overspeed',
  [AlarmTypeFilterDisplayValue.TagOk]: 'cx_panic_alarm_cancelled',
  [AlarmTypeFilterDisplayValue.LowBattery]: 'cx_low_battery',
  [AlarmTypeFilterDisplayValue.CollisionAlarm]: 'cx_collision',
  // TODO: add avoidance icon when we have it available:
  [AlarmTypeFilterDisplayValue.AvoidanceAlarm]: 'cx_obstacle',
  [AlarmTypeFilterDisplayValue.AvoidanceZone]: 'cx_avoidance_zone',
  [AlarmTypeFilterDisplayValue.PersonAlarm]: 'cx_tag_alarm'
};
const withIcon = (entity) => {
  if (Object.hasOwnProperty.call(entity, 'icon')) return entity;
  const { entityType } = entity;
  let icon = null;
  if (entityType === AwarenessEventType) icon = awarenessIconMap[entity.name] || 'warning';
  else icon = iconMap[entityType];
  return { ...entity, icon };
};

export { withIcon };