const mapStorePlugin = (store) => {
  // listen to actions
  store.subscribe(({ type, payload }) => {
    switch (type) {
      case 'app/setProjectStartDate': {
        store.dispatch('map/initializeClock', { startDate: payload }, { root: true });
        break;
      }
      case 'setApiInitialized': {
        store.dispatch('map/initialize3dBaseLayers', { root: true });
        break;
      }
    }
  });

  store.subscribeAction({
    before: ({ type, payload }) => {
      switch (type) {
        case 'shell/notifyMapMounted': {
          store.dispatch('map/initializeViewer');
          break;
        }
        case 'map/viewer/loadDrillPattern': {
          store.dispatch('map/addToLoadingMapObjects', payload.uuid, { root: true });
          break;
        }
      }
    },
    after: ({ type, payload }) => {
      switch (type) {
        case 'map/viewer/loadDrillPattern':
          store.dispatch('map/removeFromLoadingMapObjects', payload.uuid, { root: true });
          break;
        case 'map/viewer/hideRefModels':
        case 'map/viewer/showRefModels':
        case 'map/viewer/removeReferenceModel':
        case 'map/viewer/toggleShader':
        case 'map/viewer/hideSurface':
        case 'map/viewer/removeSurface':
        case 'map/viewer/loadPoints':
        case 'map/viewer/removePoints':
        case 'map/viewer/showHideAsBuiltPoint':
        case 'map/viewer/colorPoints':
        case 'map/viewer/removeDrillPattern':
        case 'map/viewer/showDrillPatterns':
        case 'map/viewer/hideDrillPatterns':
        case 'map/viewer/showDrillPatternLabels':
        case 'map/viewer/hideDrillPatternLabels':
        case 'map/viewer/loadHoles':
        case 'map/viewer/removeHoles':
        case 'map/viewer/showHideHoles':
        case 'map/viewer/removeSurfaceLog':
        case 'map/viewer/showSurfaceLogs':
        case 'map/viewer/hideSurfaceLogs':
          store.dispatch('map/viewer/requestRender', { root: true });
          break;
        default:
          break;
      }
    },
  });
};

export default mapStorePlugin;