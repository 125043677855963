<template>
  <div class="cx-message-form">
    <cx-text-area
      v-if="!showWarning"
      :disabled="!canType"
      :placeholder="translations.placeholder"
      :validationRules="validationRules"
      immediatelyShowErrors
      :onValidated="(isValid) => $emit('validation', isValid)"
      v-model="messageForm"/>
    <span v-if="showWarning" class="cx-message-form__warning">{{ translations.warning }}</span>
  </div>
</template>

<script>
import CxTextArea from '@/components/CxTextArea';
import validationRules from '@/components/validationRules';

const { maxLength } = validationRules();

export default {
  name: 'CxMessageForm',
  components: { CxTextArea },
  props: {
    canType: { type: Boolean, required: true },
    message: { type: String, required: true },
    showWarning: { type: Boolean, required: true }
  },
  emits: ['validation', 'input'],
  data() {
    return {
      validationRules: {
        modelValue: {
          $autoDirty: true,
          rule: maxLength(250)
        }
      }
    };
  },
  computed: {
    messageForm: {
      get() {
        return this.message;
      },
      set(message) {
        this.$emit('input', message);
      }
    },
    translations() {
      return {
        placeholder: this.$gettext('Type your message'),
        warning: this.$gettext('Message status will be lost.')
      };
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-message-form {
  width: 100%;
  height: 100%;

  .cx-message-form__warning {
    padding-left: 60px;
  }
}
</style>