<template>
  <div class="cx-reference-model-filter">
    <cx-combobox
      id="reference-model-filter"
      :items="sortedRefmodels"
      :value="selectedRefModelUuid"
      :input="onRefModelChanged"
      :label="label"
      :isDropdown="true"
      :isTextInput="true"
      :isChips="true"
      :multiselect="false"
      variant="autocomplete">
    </cx-combobox>
  </div>
</template>

<script>
import CxCombobox from '@/components/cxCombobox/CxCombobox.vue';
import { withFullPath } from '@/domain/entityMixins';

export default {
  name: 'CxReferenceModelFilters',

  components: {
    CxCombobox
  },

  props: {
    refmodels: Array,
    selectedRefModel: Object,
    changeSelectedRefModel: Function
  },

  computed: {
    sortedRefmodels() {
      const refmodels = this.refmodels.map(withFullPath).map((m) => {
        return {
          id: m.uuid,
          name: m.name,
          subtitle: m.fullPath
        };
      });
      return [...refmodels].sort((a, b) => a.name.localeCompare(b.name));
    },
    selectedRefModelUuid() {
      return this.selectedRefModel ? [this.selectedRefModel.uuid] : [];
    },
    label() {
      return this.$gettext('Reference Model');
    },
  },

  methods: {
    onRefModelChanged(value) {
      const selectedRefModel = this.refmodels.find(rm => rm.uuid === value[0]);
      this.changeSelectedRefModel(selectedRefModel);
    }
  },
};
</script>
