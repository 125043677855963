import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const getFileDownloadError = (error) => {
  const errorString = JSON.stringify(error);
  const downloadErrorMessage = $gettext('Error occurred while downloading a file');
  const excessErrorMessage = $gettext('Cannot download more than 30000 points');
  return {
    exception: errorString,
    message: errorString.includes('400') ? excessErrorMessage : downloadErrorMessage
  };
};

export { getFileDownloadError };