export const downloadFile = (data, filename) => {
  // creates a new link with Blob data
  // puts it in the document and clicks it
  // in order to initialize download
  // afterwards, removes the link
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadFileFromUrl = (url, filename) => {
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
};