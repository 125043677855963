<template>
  <div class="cx-surfacelog-details-panel">
    <!-- OVERLAY CONTROLS -->
    <transition :name="applyAnimations('overlayControls')">
      <cx-surface-log-overlay-controls
        :selectedSurfaceLogUuid="selectedSurfaceLog.uuid"
        :isDeltaHeightAvailable="isDeltaHeightAvailable"
        :isORMAvailable="isSingleReferenceModelFilter"
        v-if="isAnimationReady"/>
    </transition>
    <!-- STATUS PANEL -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady" source="avatars/surface.svg" :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyToSurfaceLog"/>
        </transition>
      </template>
      <template #title>
        <!-- TITLE -->
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady" :title="selectedSurfaceLog.name" :subtitle="translations.surface"
            subtitleIcon="cx_points" :subtitleIconTooltip="translations.surface">
          </cx-title-panel>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel>
            <template #preContent>
              <cx-surface-log-comparison-section
                v-if="isAnimationReady && hasEarthmovingLicence && isSurfaceToModelComparisonEnabled"/>
            </template>
          </cx-related-entities-panel>
        </transition>
      </template>
      <!--MAIN METADATA -->
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady" :mainData="mainData" :title="translations.usedFilters"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <cx-surfacelog-cut-fill-analysis
      v-if="hasEarthmovingLicence && isDeltaHeightAvailable && showSurfacelogVolumeCalculations"
      :isNonZeroDeltaHeight="isNonZeroDeltaHeight"/>
    <!-- LICENCE -->
    <transition :name="applyAnimations('analysis')">
      <cx-licence-panel v-if="!hasEarthmovingLicence"/>
      <cx-dh-legend-configurator v-else-if="showDhLegendConfigurator"/>
    </transition>
    <div class="cx-surfacelog-details-panel__export-button" :class="exportButtonClass">
      <cx-menu-button
        icon="cx_download" :items="exportExtensions" :displayDottIcon="false" :flat="true" :width="200"
        :loading="exporting" menuHorizontalPosition="left" @click="(extension) => exportSurfaceLog(extension.value)">
      </cx-menu-button>
    </div>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import round from 'lodash/round';
import { mapGetters, mapActions } from 'vuex';
import CxAvatar from '../common/CxAvatar';
import CxStatsPanel from '../common/CxStatsPanel';
import CxTitlePanel from '@/app/detailsPanel/common/CxTitlePanel';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxLicencePanel from '@/app/detailsPanel/common/CxLicencePanel';
import CxSurfaceLogOverlayControls from './CxSurfaceLogOverlayControls.vue';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import { withIcon } from '@/domain/entityMixins';
import datetimehelper from '@/utils/datetimehelper';
import CxMenuButton from '../../../components/menuComponents/CxMenuButton.vue';
import { surfacelogApi } from '@/http/surfacelog.api';
import { formatISO, format } from 'date-fns';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { SurfacelogType } from '@/domain/surfacelog/SurfacelogType';
import CxSurfaceLogComparisonSection from './CxSurfaceLogComparisonSection.vue';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';
import CxDhLegendConfigurator from './CxDhLegendConfigurator';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { convert } from '@/utils/measurementUnitHelper';
import CxSurfacelogCutFillAnalysis from './CxSurfacelogCutFillAnalysis.vue';

export default {
  name: 'CxSurfaceLogDetailsPanel',
  components: {
    CxAvatar,
    CxStatsPanel,
    CxTitlePanel,
    CxMainDataPanel,
    CxRelatedEntitiesPanel,
    CxLicencePanel,
    CxSurfaceLogOverlayControls,
    CxMenuButton,
    CxSurfaceLogComparisonSection,
    CxDhLegendConfigurator,
    CxSurfacelogCutFillAnalysis
  },
  mixins: [CxAnimationMixin, CxLicenceMixin, CxWaffleFlagMixin],
  data() {
    return {
      exporting: false,
    };
  },
  computed: {
    ...mapGetters('shell', ['isDetailsPanelReduced', 'isDetailsPanelOpened']),
    ...mapGetters('map', ['selectedEntity', 'cellSizesTooledge', 'exportExtensions']),
    ...mapGetters('app', ['measurementSystem', 'allModels']),
    ...mapGetters('detailsSurfacelog', ['selectedRefmodelId', 'isDeltaHeightCalculated']),
    ...mapGetters(['projectUuid']),
    translations() {
      return {
        surface: this.$gettext('Type: Surface'),
        cellSize: this.$gettext('Cell size'),
        exportError: this.$gettext('Export failed. Try again or contact support'),
        usedFilters: this.$gettext('Used Filters'),
        date: this.$gettext('Date and time'),
      };
    },
    selectedSurfaceLog() {
      if (this.selectedEntity && this.selectedEntity.entityType === SurfacelogType) {
        return this.selectedEntity;
      }
      return null;
    },
    showDhLegendConfigurator() {
      return this.selectedSurfaceLog.signal === SurfacelogSignalTypes.DeltaHeight && this.showSurfacelogDeltaHeightLegendConfigurationUi2;
    },
    cellSizes() {
      return this.cellSizesTooledge;
    },
    exportButtonClass() {
      if (!this.hasEarthmovingLicence) return 'cx-surfacelog-details-panel__export-button--no-licence';
      return '';
    },
    displayCellSize() {
      const { lengthUnit, cellSize } = this.selectedSurfaceLog;
      const projectLenghtUnit = this.measurementSystem.baseLengthUnit.name;
      const convertedCellSize = (lengthUnit.name !== projectLenghtUnit) ? convert(cellSize).withPrecision(2).from(lengthUnit.name).to(projectLenghtUnit) : cellSize;
      return convertedCellSize;
    },
    selectedRefmodel() {
      const refmodelId = this.selectedRefmodelId(this.selectedSurfaceLog.uuid);
      return this.allModels.find(model => model.uuid === refmodelId);
    },
    isSurfaceToModel() {
      if (this.selectedRefmodel) {
        return this.isDeltaHeightCalculated(this.selectedRefmodel.uuid, this.selectedSurfaceLog.uuid);
      }
      return false;
    },
    isSingleReferenceModelFilter() {
      return this.selectedSurfaceLog.originFilters.refmodels.length === 1;
    },
    isDeltaHeightAvailable() {
      return (this.isSurfaceToModel || this.isSingleReferenceModelFilter) && this.isNonZeroDeltaHeight && this.selectedSurfaceLog.availableSignals.includes(SurfacelogSignalTypes.DeltaHeight);
    },
    isNonZeroDeltaHeight() {
      // even though zero delta height is the life goal of an excavator operator, we are considering it Outside Reference Model for now
      const { signalsKPI } = this.selectedSurfaceLog;
      return signalsKPI.dH && (round(signalsKPI.dH.min_value, 5) !== 0 || round(signalsKPI.dH.max_value, 5) !== 0);
    },
    mainData() {
      const { refmodels, units, pointCodes } = this.selectedSurfaceLog.originFilters;
      let { unitTypes } = this.selectedSurfaceLog.originFilters;
      const { originTimeRange } = this.selectedSurfaceLog;
      const result = [
        {
          value: `${datetimehelper.format(originTimeRange.from, datetimehelper.LOCALE_DATE_TIME_FORMAT)} 
          - ${datetimehelper.format(originTimeRange.to, datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
          icon: 'access_time',
          showTooltip: true,
          tooltipText: `${this.translations.date}`
        },
      ];

      if (refmodels) {
        result.push({
          value: refmodels.join(', '),
          icon: withIcon({ entityType: RefmodelTypes.DefaultRefmodelType }).icon || '',
          showTooltip: true
        });
      }

      if (pointCodes && pointCodes.length) {
        const pointCodesToDisplay = { value: pointCodes.map(p => `${p.point_code} - ${p.point_code_name}`).join(', '), icon: 'cx_points', showTooltip: true };
        result.push(pointCodesToDisplay);
      }

      result.push({
        value: `${this.translations.cellSize}: ${this.displayCellSize} ${this.measurementSystem.baseLengthUnit.abbr()}`,
        icon: 'cx_cellsize',
        showTooltip: true,
        tooltipText: `${this.translations.cellSize}`
      });

      if (unitTypes && unitTypes.length) {
        const unitTypesToDisplay = { values: [] };
        unitTypes.forEach(ut => {
          unitTypesToDisplay.values.push({
            value: ut,
            icon: withIcon({ entityType: ut }).icon || ''
          });
        });
        result.push(unitTypesToDisplay);
      } else if (units && units.length) {
        unitTypes = uniqBy(units, 'type').map(u => (u.type));
        const groupedUnits = unitTypes.map(ut => {
          return {
            type: ut,
            unitsText: units.filter(unit => { return unit.type === ut; })
              .map(u => (u.name))
              .reduce((prev, curr) => { return `${prev}, ${curr}`; })
          };
        });
        const unitsToDisplay = { values: [] };
        groupedUnits.forEach(group => {
          unitsToDisplay.values.push({
            value: group.unitsText,
            icon: withIcon({ entityType: group.type }).icon || ''
          });
        });
        result.push(unitsToDisplay);
      }
      return result;
    },
  },
  methods: {
    ...mapActions('map', ['setCameraToEntity']),
    ...mapActions('notifications', ['error']),

    async handleCameraFlyToSurfaceLog() {
      await this.setCameraToEntity(this.selectedSurfaceLog.uuid);
    },
    async exportSurfaceLog(extension) {
      this.exporting = true;
      try {
        const { refmodels, units, pointCodes } = this.selectedSurfaceLog.originFilters;
        const { from, to } = this.selectedSurfaceLog.originTimeRange;
        const { cellSize } = this.selectedSurfaceLog;
        const refmodelPaths = refmodels;
        let targetReferenceModelPath;
        if (this.isSurfaceToModel) {
          const { path, name } = this.selectedRefmodel;
          targetReferenceModelPath = `${path}/${name}`;
        }

        const convertedCellSize = convert(cellSize)
          .withPrecision(2)
          .from(this.measurementSystem.baseLengthUnit.name)
          .to('m');

        await surfacelogApi.exportSurfacelog({
          projectUuid: this.projectUuid,
          output: extension,
          startDate: formatISO(from),
          endDate: formatISO(to),
          refmodelPaths,
          targetReferenceModelPath,
          cellSize: convertedCellSize,
          units,
          pointCodes,
          measurementUnit: this.measurementSystem.baseLengthUnit,
          fileName: `ConX_Surfacelog_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.xml`
        });
      } catch (error) {
        this.error({ message: this.translations.exportError });
      } finally {
        this.exporting = false;
      }
    },
  }
};
</script>

<style lang="less">
@import '../../../common';


.cx-surfacelog-details-panel {

  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-stats-panel {
    width: @detailsSurfaceLogStatsPanelWidth;
  }

  .cx-surfacelog-details-panel__export-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
    margin-left: auto;

    .cx-surfacelog-details-panel__irc-export-button {
      .cx-button {
        background-color: inherit;
      }
    }

    &--no-licence {
      margin-left: 0;
    }
  }

  .cx-dh-legend-configurator+.cx-surfacelog-details-panel__export-button {
    margin-left: 30px;
  }

}
</style>