import { withIcon } from '@/domain/entityMixins';

export default class FilterEntityViewModel {

  _entity;
  _currentLocale;
  _entityType;
  constructor(entity, locale) {
    this._entity = withIcon(entity);
    this._currentLocale = locale;
  }

  get id() {
    return this._entity.uuid;
  }

  get name() {
    return this._entity.name;
  }

  get icon() {
    return this._entity.icon;
  }

  get data() {
    return this._entity;
  }

  get isResultVisible() {
    return this._isResultVisible;
  }

  set isResultVisible(resultVisibilityState) {
    this._isResultVisible = resultVisibilityState;
  }

  get isResultSelectable() {
    return true;
  }

  get isResultSelected() {
    return this._isResultSelected;
  }

  set isResultSelected(resultSelectionState) {
    this._isResultSelected = resultSelectionState;
  }
}