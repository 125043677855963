<template>
  <cx-slope-height-map-legend :scale="heightScale" :gradientImage="gradientImage">
    <template #title>{{ translations.legendTitle }}</template>
    <template #unit>{{ measurementSystem.baseLengthUnit.abbr() }}</template>
  </cx-slope-height-map-legend>
</template>

<script>
import { mapGetters } from 'vuex';
import CxSlopeHeightMapLegend from '@/app/detailsPanel/refmodel/components/CxSlopeHeightMapLegend';
import gradientImage from '../../../../public/controls/grad-heightmap-v-mini.png';

export default {
  name: 'CxHeightMapLegend',
  components: {
    CxSlopeHeightMapLegend,
  },
  data() {
    return {
      gradientImage
    };
  },
  computed: {
    ...mapGetters('app', ['measurementSystem']),
    heightScale() {
      const refModel = this.$store.getters['detailsRefmodel/selectedRefModel'];
      const minMaxHeight = refModel.convertMinMaxHeight(this.measurementSystem.baseLengthUnit);
      const numValues = 6;
      const numStep = numValues - 1;
      const stepSize = (minMaxHeight[1] - minMaxHeight[0]) / numStep;
      const numDigits = stepSize < 1 ? 1 : 0; // if step is big enough, no floating part
      const arrayValues = [minMaxHeight[1].toFixed(numDigits)];
      for (let index = 1; index < numStep; index += 1) {
        arrayValues.push((-index * stepSize + minMaxHeight[1]).toFixed(numDigits));
      }
      arrayValues.push(minMaxHeight[0].toFixed(numDigits));
      return arrayValues;
    },
    translations() {
      return {
        legendTitle: this.$gettext('Height Map')
      };
    }
  }
};
</script>
