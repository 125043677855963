import { VisualizationSourceType } from '@/domain/refmodels/VisualizationSourceType.enum';
import api from '@/http/api';
import _ from 'lodash';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const mapDrillPatternStore = {
  state: {
    drillPatternLabelMap: {}
  },
  getters: {
    isDrillPatternLabelsActive: state => (uuid) => {
      const isLabelsActive = state.drillPatternLabelMap[uuid];
      return !!isLabelsActive;
    }
  },
  mutations: {
    changeLabelsActive(state, { uuid, isActive }) {
      const newLabelMap = { ...state.drillPatternLabelMap };
      newLabelMap[uuid] = isActive;
      state.drillPatternLabelMap = newLabelMap;
    },
    removeLabelMap(state, uuid) {
      state.drillPatternLabelMap = _.omit(state.drillPatternLabelMap, [uuid]);
    }
  },
  actions: {
    /**
     * @param {Array} entities collection of drillpattern object to load on the map
     * @returns {Boolean} true if all entities has been loaded sucesfully
     */
    async loadDrillPatterns(context, { entities, showOnMap }) {
      const drillPatternsToAdd = entities
        .filter(e => !context.getters.loadedRefmodels.find(l => l.uuid === e.uuid))
        .filter(r => r.visSourceType === VisualizationSourceType.DrillPatternJson);
      const drillPatternList = drillPatternsToAdd.map(rm => context.dispatch('fetchDrillPatternJson', { drillPattern: rm, showOnMap }));
      const drillPatternData = await Promise.all(drillPatternList);
      const validatedDrillPatternData = drillPatternsToAdd.filter(dp => drillPatternData.some(i => i.uuid === dp.uuid && i.status === 'ok'));
      if (!showOnMap) context.commit('hideRefModels', entities.map(r => r.uuid));
      context.commit('addLoadedRefmodels', validatedDrillPatternData);
      return drillPatternData.every(d => d.status === 'ok') && drillPatternsToAdd.length;
    },
    async fetchDrillPatternJson(context, { drillPattern, showOnMap }) {
      const { uuid, name } = drillPattern;
      try {
        const drillPatternData = await api.getDrillPatternJson(uuid);
        if (_.isEmpty(drillPatternData)) throw new Error(`${name}: does not contain any point data`);
        context.dispatch('viewer/loadDrillPattern', {
          uuid,
          name,
          drillPatternData,
          show: showOnMap
        });
        return { uuid, data: drillPatternData, status: 'ok' };
      } catch (e) {
        await context.dispatch('notifications/error', {
          exception: e,
          message: `${$gettext('An error occured while fetching drill pattern:')} ${name}`
        }, { root: true });
        return { uuid, data: e, status: 'error' };
      }
    },
    async showDrillPattern(context, { entities, syncObjectExplorer = true }) {
      if (syncObjectExplorer) context.commit('showRefModels', entities.map(r => r.uuid));
      context.dispatch('viewer/showDrillPatterns', { show: entities.map(m => m.uuid), });
    },
    async hideDrillPatterns(context, { entities, syncObjectExplorer = true }) {
      if (syncObjectExplorer) context.commit('hideRefModels', entities.map(r => r.uuid));
      context.dispatch('viewer/hideDrillPatterns', { hide: entities.map(m => m.uuid), });
      // if we hide the drillpattern, we also want to hide it's labels
      entities.forEach((dp) => {
        if (context.getters.isDrillPatternLabelsActive(dp.uuid)) {
          context.dispatch('toggleDrillPatternLabels', { uuid: dp.uuid });
        }
      });
    },
    async unloadDrillPattern(context, { entity }) {
      // remove from loadedRefmodels
      context.commit('removeRefModelFromLoaded', entity.uuid);
      if (context.state.drillPatternLabelMap[entity.uuid]) { await context.dispatch('viewer/hideDrillPatternLabels', entity.uuid); }
      context.commit('removeLabelMap', entity.uuid);
      context.dispatch('viewer/removeDrillPattern', { uuid: entity.uuid });
    },
    async deselectDrillPattern(context, { uuids }) {
      context.dispatch('deselectRefmodels', { uuids });
    },
    /**
     * Toggles labels over a drillpattern
     * @param {String} uuid - of a drillpattern to toggle laels for
     */
    async toggleDrillPatternLabels(context, { uuid }) {
      const currentVisibilityStatus = context.state.drillPatternLabelMap[uuid];
      context.commit('changeLabelsActive', { uuid, isActive: !currentVisibilityStatus });
      if (currentVisibilityStatus) { await context.dispatch('viewer/hideDrillPatternLabels', uuid); } else { await context.dispatch('viewer/showDrillPatternLabels', uuid); }
    }
  }
};

export default mapDrillPatternStore;