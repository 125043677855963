import { Hole } from '@/domain/hole/Hole';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { PointSet } from '@/domain/pointSet/PointSet';
import { HoleDrillPileStatus } from '@/domain/hole/HoleDrillPileStatus.enum';

const createDrillHole = ({
  uuid,
  holeName,
  logTime,
  status,
  referenceModelName,
  displayLatitude2D,
  displayLongitude2D,
  pointSet,
  setUid
}: {
  uuid: string,
  holeName: string,
  logTime: string | null,
  status: HoleDrillPileStatus,
  referenceModelName: string,
  displayLatitude2D: number,
  displayLongitude2D: number,
  pointSet: PointSet,
  setUid: string
}): Hole => {
  return {
    entityType: StabilizationTypes.DrillHole,
    uuid,
    holeName,
    logTime,
    status,
    referenceModelName,
    displayLatitude2D,
    displayLongitude2D,
    pointSet,
    setUid
  };
};

const createPileHole = ({
  uuid,
  holeName,
  logTime,
  status,
  referenceModelName,
  displayLatitude2D,
  displayLongitude2D,
  pointSet,
  setUid
}: {
  uuid: string,
  holeName: string,
  logTime: string | null,
  status: HoleDrillPileStatus,
  referenceModelName: string,
  displayLatitude2D: number,
  displayLongitude2D: number,
  pointSet: PointSet,
  setUid: string
}): Hole => {
  return {
    entityType: StabilizationTypes.PileHole,
    uuid,
    holeName,
    logTime,
    status,
    referenceModelName,
    displayLatitude2D,
    displayLongitude2D,
    pointSet,
    setUid
  };
};

export { createDrillHole, createPileHole };