import { measurementUnits, baseUnits, } from './definitions';
import { isNil, round } from 'lodash';

/**
 * Converts value from one measurement system to the other
 * NOTE: for now at least one value must be in the base ststem (measurementUnits.length.m)
 */
export const convert = (value) => {
  let _originalUnit;
  let _precision;

  const getUnit = (unitName) => {
    return measurementUnits[unitName];
  };
  const fromBaseToUnit = (val, unit) => {
    return unit ? unit.toBase * val : null;
  };
  const fromUnitToBase = (val, unit) => {
    if (baseUnits.includes(unit)) return val;
    return unit ? (1.0 / unit.toBase) * val : null;
  };
  if (isNil(value)) throw new Error('Value must be defined');


  const to = (targetUnit) => {
    if (!targetUnit) throw new Error('Target unit must be defined');
    let _targetUnit = targetUnit;
    if (typeof targetUnit === 'string' || targetUnit instanceof String) _targetUnit = getUnit(targetUnit);
    if (!_targetUnit) throw new Error(`Unknown unit: ${targetUnit}`);
    // cannot convert between different measures, f.ex. area -> speed
    if (_targetUnit.measure !== _originalUnit.measure) throw new Error(`Unsupported conversion between measures ${_originalUnit.abbr()} -> ${_targetUnit.abbr()}`);
    if (_originalUnit === _targetUnit) {
      if (isNil(_precision)) return value;
      return round(value, _precision);
    }
    if (baseUnits.includes(_originalUnit)) return isNil(_precision) ? fromBaseToUnit(value, _targetUnit) : round(fromBaseToUnit(value, _targetUnit), _precision);
    if (baseUnits.includes(_targetUnit)) return isNil(_precision) ? fromUnitToBase(value, _originalUnit) : round(fromUnitToBase(value, _originalUnit), _precision);
    throw new Error(`Unsupported convertion - at least one of the units must be a base unit ${baseUnits.join(', ')}`);
  };
  const from = (originalUnit) => {
    if (!originalUnit) throw new Error('Original unit must be defined');
    if (typeof originalUnit === 'string' || originalUnit instanceof String) _originalUnit = getUnit(originalUnit);
    else _originalUnit = originalUnit;
    if (!_originalUnit) throw new Error(`Unknown unit: ${originalUnit}`);
    return { to };
  };
  const withPrecision = (precision) => {
    if (precision < 0) throw new Error('Precision must be a positive number');
    _precision = precision;
    return { from };
  };
  return {
    from,
    withPrecision
  };
};