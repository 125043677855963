<template>
  <div class="cx-transparency-control">
    <span class="cx-transparency-control__title">{{ title }}</span>
    <div class="cx-transparency-control__value-box">
      <div class="cx-transparency-control__value">{{ opacityText }}</div>
    </div>
    <input
      type="range"
      :value="opacity"
      min="0"
      max="1"
      step="0.1"
      @input="handleInputChange"/>
  </div>
</template>

<script>
export default {
  name: 'CxTransparencyControl',
  emits: ['changeOpacity'],
  props: {
    title: String,
    opacity: Number
  },
  computed: {
    opacityText() {
      const percentValue = this.opacity * 100;
      return `${percentValue}%`;
    }
  },
  methods: {
    handleInputChange(e) {
      let { target } = e;
      if (e.target.type !== 'range') target = document.getElementById('range');
      const { min, max } = target;
      const val = target.value;
      target.style.backgroundSize = `${(val - min) * 100 / (max - min)}% 100%`;
      this.$emit('changeOpacity', parseFloat(e.target.value));
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-transparency-control {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  padding: 12px;
  gap: 8px;

  input[type=range] {
    -webkit-appearance: none;
    width: 120px;
    margin-top: 10px;
    height: 2px;
    background: @baseInputColor;
    background-image: linear-gradient(@inputProgressColor, @inputProgressColor);
    background-size: 100%;
    background-repeat: no-repeat;
}
  input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: @inputProgressColor;
      cursor: pointer;
  }

    input[type=range]::-webkit-slider-runnable-track  {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
    }

    input[type=range]::-moz-range-progress {
      background-color: @inputProgressColor;
    }

    input[type=range]::-moz-range-track {
      background-color: @baseInputColor;
    }

    input[type=range]::-moz-range-thumb {
      border: 0px solid @inputProgressColor;
      border-radius: 15px;
      background: @inputProgressColor;
      cursor: pointer;
    }

  .cx-transparency-control__value-box {
    border: 1px solid @textColorBase;
    padding: 3px;
    width: 30px;
    font-size: 9px;
    text-align: center;
  }
}
</style>