import licenceService from '@/services/licence.service';
import navigationInstructions from './map.instructions';
import imageryLayers from './map.layers';
import zoomLevels from './map.zoomLevels';
import _ from 'lodash';
import * as Cesium from 'cesium';
import { cartographicCalculations } from './cartographics/cartographicCalculations';
import mapConstants from './map.constants';

const mapToolbarStore = {
  state: {
    baseLayerPickerViewModel: null
  },
  actions: {
    async initializeInfoBox(context) {
      const instructions = navigationInstructions.map((i) => {
        return {
          ...i,
          content: i.content.map((c) => {
            return {
              ...c,
              iconUrl: Cesium.buildModuleUrl(c.iconUrl)
            };
          })
        };
      });
      context.dispatch('map/initializeNavigationInstructions', instructions, { root: true });
    },
    async initializeBaseLayerPicker(context, { loadImagery, selectedImageryName }) {
      const { viewer } = context.self.rootState;
      let imageries;
      let selectedImagery;

      if (loadImagery) {
        imageries = context.self.getImageryViewModels();
        selectedImagery = imageries.find(i => i.name === selectedImageryName) || imageries[0];
      }

      // wait until the selected one is ready from the map.store
      context.state.baseLayerPickerViewModel = new Cesium.BaseLayerPickerViewModel({
        globe: viewer.scene.globe,
        imageryProviderViewModels: imageries,
        selectedImageryProviderViewModel: selectedImagery
      });
      context.dispatch('map/baseLayersInitialized', { layers: imageries, selectedLayer: selectedImagery }, { root: true });
    },
    /**
     * Initializes map compass by adding event listener
     * firing a compass-updating method on change of Cesium camera.
     * @param context
     */
    async initializeCompass(context) {
      const { rootState, onCameraDirectionChanged } = context.self;
      const { camera } = rootState.viewer.scene;
      camera.changed.addEventListener(onCameraDirectionChanged);
    },
    async flyHome(context) {
      const { viewer, destination, flyToDefaultOptions } = context.self.rootState;
      if (!destination || !flyToDefaultOptions()) return;
      viewer.scene.camera.flyTo({ destination, ...flyToDefaultOptions() });
    },
    async updateBaseLayer(context, payload) {
      context.state.baseLayerPickerViewModel.selectedImagery = payload;
    },
    /**
     * Updates map compass current direction.
     * @param {*} context
     * @param {Number} payload angle between North (0/360 Degrees) and given point,
     * increasing eastward, starting from cardinal point of North.
     */
    async updateCompassDirection(context, payload) {
      context.dispatch('map/changeCompassDirection', payload, { root: true });
    },
    /**
     * Uses current height to acquire new zoom multiplier and basing on it, zooms the map in.
     * @param {*} context
     */
    async zoomIn(context) {
      const { rootState, getZoomMultiplier } = context.self;
      const { camera } = rootState.viewer.scene;
      const { height } = camera.positionCartographic;
      const zoomMultiplier = getZoomMultiplier(height);
      camera.zoomIn(zoomMultiplier);
    },
    /**
     * Uses current height to acquire new zoom amount multiplier and basing on it, zooms the map out.
     * @param {*} context
     */
    async zoomOut(context) {
      const { rootState, getZoomMultiplier } = context.self;
      const { camera } = rootState.viewer.scene;
      const { height } = camera.positionCartographic;
      const zoomMultiplier = getZoomMultiplier(height);
      camera.zoomOut(zoomMultiplier);
    },
    /**
     * Reorients the camera towards cardinal direction of North (0 degrees) with an overhead view.
     * @param {*} context
     * @constant {Number} currentCameraHeight [meters] camera height before firing reorienting fly method.
     * @constant {Number} terrainPositionHeight [meters] terrain height right in the camera geographic location.
     * @constant {Number} centralRayVerticalPosition is a fraction describing ratio between central camera ray height
     * and the inner window height.
     * @constant {Object<Cesium.Cartographic>} reorientFarPosition is a geographic location of central camera ray
     * intersecting point with the terrain.
     * If camera is over the terrain level and is looking towards the ground limited by the ellipsoid horizon,
     * the final overhead view will base on the geographical coordinates of camera ray intersecting point,
     * calculated basing on the extent of camera's pitch - the lower is the angle between the camera view ray
     * and the ellipsoid surface, the farther will fly the camera towards the reorientation location.
     * If camera is under the terrain level or is looking up toward the sky,
     * the final overhead view will base on the geographical coordinates (longitude, latitude) of the camera.
     */
    async reorientMap(context) {
      const { viewer } = context.self.rootState;
      const { globe, camera } = viewer.scene;
      const { ellipsoid } = globe;
      const { positionCartographic, heading } = camera;
      const isMapOriented = context.self.isCompassDirectionNorth(heading);
      if (!isMapOriented) {
        const { cameraDefaultHeight, cameraFlyToDefaultOptions } = mapConstants;
        const flyToOptions = { ...cameraFlyToDefaultOptions };
        const currentCameraHeight = positionCartographic.height;
        const terrainPositionHeight = await context.dispatch('getTerrainHeight', { ...positionCartographic });
        const isCameraAboveTerrain = currentCameraHeight > terrainPositionHeight;
        const centralRayVerticalPosition = cartographicCalculations.getCentralRayVerticalPosition(camera);
        const reorientFarPosition = isCameraAboveTerrain ? cartographicCalculations.getViewCenterRayIntersectingPoint(centralRayVerticalPosition, camera, ellipsoid) : null;
        if (reorientFarPosition) {
          const reorientFarPositionTerrainHeight = await context.dispatch('getTerrainHeight', { ...reorientFarPosition });
          const reorientFarPositionHeightRaised = reorientFarPositionTerrainHeight + cameraDefaultHeight;
          const reorientFarPositionRaised = {
            ...reorientFarPosition,
            height: reorientFarPositionHeightRaised
          };
          flyToOptions.destination = cartographicCalculations.getCartesian3FromCartographic(reorientFarPositionRaised, ellipsoid);
        } else {
          const terrainPositionHeightRaised = terrainPositionHeight + cameraDefaultHeight;
          const terrainPositionCartographicRaised = {
            ...positionCartographic,
            height: terrainPositionHeightRaised
          };
          flyToOptions.destination = cartographicCalculations.getCartesian3FromCartographic(terrainPositionCartographicRaised, ellipsoid);
        }
        camera.flyTo(flyToOptions);
      }
      return null;
    },
  },
  methods: {
    getImageryLayer(payload) {
      const { name, tooltip, mapId } = payload;
      const iconUrl = Cesium.buildModuleUrl(payload.iconUrl);
      return new Cesium.ProviderViewModel({
        name,
        tooltip,
        iconUrl,
        creationFunction() {
          return new Cesium.UrlTemplateImageryProvider({
            url: licenceService.getMapboxUrl(mapId),
            maximumLevel: 20,
          });
        }
      });
    },
    getImageryViewModels() {
      return imageryLayers.map((layer) => {
        return this.getImageryLayer({
          name: layer.name(),
          tooltip: layer.tooltip(),
          iconUrl: layer.iconUrl,
          mapId: layer.mapId
        });
      });
    },
    /**
     * Is fired on every change of Cesium camera and uses camera heading representing
     * a horizontal World direction in Radians, which are changed to degrees,
     * in order to fire compass direction-updating action.
     */
    onCameraDirectionChanged() {
      const { heading } = this.rootState.viewer.scene.camera;
      const direction = Cesium.Math.toDegrees(heading);
      this.dispatch('updateCompassDirection', direction);
    },
    /**
     * Gets zoom step multiplier basing on a predefined set of zoom levels, including height ranges
     * and respective rate of camera's step between previous and next zoom levels.
     * @param {*} height is a current Cesium camera's altitude and is tested against height ranges.
     * @returns {Number} camera multiplier which is a new rate of camera's step between previous
     * and next zoom levels.
     */
    getZoomMultiplier(height) {
      const { defaultZoomAmount } = this.rootState.viewer.scene.camera;
      const zoomLevel = zoomLevels.find(zL => _.inRange(height, ...zL.range));
      return zoomLevel ? zoomLevel.multiplier : defaultZoomAmount;
    },
    /**
     * Checks if the map is well oriented
     * @param {Number} heading [radians] geographic direction of camera's view center ray
     */
    isCompassDirectionNorth(heading) {
      return heading === Cesium.Math.TWO_PI;
    }
  }
};

export default mapToolbarStore;