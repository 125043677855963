import { JulianDate, ClockRange } from 'cesium';

const clockStore = {
  state: {
    startDate: null,
    endDate: null,
  },
  actions: {
    /**
     * Initializes Cesium's clock component and registers map store communication.
     *
     * @param context
     */
    async initializeClock(context) {
      const { self } = context;
      const { viewer } = self.rootState;
      const { clockViewModel } = viewer;
      clockViewModel.clock.onTick.addEventListener(() => {
        const { canAnimate, shouldAnimate } = clockViewModel;
        if (canAnimate && shouldAnimate) context.dispatch('notifyCurrentDate');
      });
      clockViewModel.clock.onStop.addEventListener(() => {
        context.dispatch('map/animate', false, { root: true });
      });
    },
    /**
     * Notify of currentTime updates via map/updateCurrentDateFromMapTick
     */
    async notifyCurrentDate(context) {
      const { currentTime } = context.self.rootState.viewer.clockViewModel;
      const currentDate = JulianDate.toDate(currentTime);
      context.dispatch('map/updateCurrentDateFromMapTick', currentDate, { root: true });
    },
    /**
     * Sets date and time on Cesium's clockViewModel.
     * @param context
     * @param time {Date} - The time to set as current Cesium time
     */
    async setTime(context, time) {
      const { viewer } = context.self.rootState;
      const { clockViewModel } = viewer;
      clockViewModel.currentTime = JulianDate.fromDate(time);
      clockViewModel.synchronize();
    },
    /**
     * Sets Cesium's clock view model timerange.
     *
     * @param context
     * @param startDate {Date} - The clock's start time
     * @param endDate {Date} - The clock's stop time
     */
    async setTimeRange(context, { startDate, endDate }) {
      const { state } = context.self;
      const { viewer } = context.self.rootState;
      const { clockViewModel } = viewer;
      state.startDate = startDate;
      state.endDate = endDate;
      const startTime = JulianDate.fromDate(startDate);
      const stopTime = JulianDate.fromDate(endDate);
      clockViewModel.startTime = startTime;
      clockViewModel.stopTime = stopTime;
      clockViewModel.clockRange = ClockRange.CLAMPED;
    },
    /**
     * Sets the animiation multiplier on Cesium's clockViewModel.
     *
     * @param context
     * @param multiplier
     * @returns {Promise<void>}
     */
    async multiplyAnimationSpeed(context, multiplier) {
      const { viewer } = context.self.rootState;
      viewer.clockViewModel.multiplier = multiplier;
    },
    /**
     * Toggles Cesium's clockViewModel animation.
     *
     * @param context
     * @param shouldAnimate
     * @returns {Promise<void>}
     */
    async animate(context, shouldAnimate) {
      const { viewer } = context.self.rootState;
      viewer.clockViewModel.shouldAnimate = shouldAnimate;
    },
  },
  methods: {}
};

export default clockStore;