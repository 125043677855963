import { computed, ref, onMounted } from 'vue';
import { filterPanelTypes, filterPointPanelTypes, detailsPanelTypes } from '@/store/shellModule/shell.store';
import { useStore } from 'vuex';
import { useVuex } from '@vueblocks/vue-use-vuex';

const animations = {
  filterPanel: {
    drawer: 'cx-filter-panel--animate--drawer',
    filter1: 'cx-filter-panel--animate--1',
    filter2: 'cx-filter-panel--animate--2',
    filter3: 'cx-filter-panel--animate--3',
    filter4: 'cx-filter-panel--animate--4',
    filter5: 'cx-filter-panel--animate--5',
    filter6: 'cx-filter-panel--animate--6',
    action: 'cx-filter-panel--animate--action-buttons'
  },
  detailsPanel: {
    handle: 'cx-details-panel--animate--handle',
    content: 'cx-details-panel--animate--content',
    overlayControls: 'cx-details-panel--animate--overlay-controls',
    avatar: 'cx-details-panel--animate--avatar',
    title: 'cx-details-panel--animate--title-panel',
    relatedEntities: 'cx-details-panel--animate--related-entities-panel',
    mainMetadata: 'cx-details-panel--animate--main-data-panel',
    analysis: 'cx-details-panel--animate--analysis-panel'
  },
  objectExplorer: {
    showFilterPanel: 'cx-object-explorer--animate--show',
    collapseFilterPanel: 'cx-object-explorer--animate--collapse',
    openObjectList: 'cx-object-explorer--animate--open-list',
    addNewItem: 'cx-object-explorer--animate--add-item'
  },
  avatar: {
    image: 'cx-avatar--animate--img',
    overlay: 'cx-avatar--animate--overlay',
    location: 'cx-avatar--animate--location'
  },
  legend: {
    showDetailsPanel: 'cx-legend--animate--emerge',
    collapseDetailsPanel: 'cx-legend--animate--submerge',
    content: 'cx-legend--animate--content'
  },
  directSearch: {
    input: 'cx-direct-search--animate--input',
    dropdown: 'cx-direct-search--animate--dropdown'
  },
  mapToolbar: {
    content: 'cx-map-toolbar--animate--content',
  }
};

export default function useAnimation(componentName) {
  const store = useStore();
  const shellStore = useVuex('shell', store);
  const isMounted = ref(false);

  const {
    animationSwitch,
    isFilterPanelOpened,
    isDetailsPanelOpened
  } = shellStore.useGetters([
    'animationSwitch',
    'isFilterPanelOpened',
    'isDetailsPanelOpened',
  ]);

  const isAnimationReady = computed(() => {
    if (componentName.includes('Filter')) return isFilterPanelOpened.value && isMounted.value;
    if (componentName.includes('Details')) return isDetailsPanelOpened.value && isMounted.value;
    return isMounted.value;
  });

  // Methods
  const getAnimations = (component) => {
    const {
      isFilterPanelTransitions,
      isDetailsPanelTransitions,
      isObjectExplorerTransitions,
      isAvatarTransitions,
      isLegendTransitions,
      isDirectSearchTransitions,
      isMapToolbarTransitions
    } = animationSwitch.value;
    if (isFilterPanelTransitions && ((component === 'CxFilterPanel') || Object.values(filterPanelTypes).includes(component) || Object.values(filterPointPanelTypes).includes(component))) return animations.filterPanel;
    if (isDetailsPanelTransitions && ((component === 'CxDetailsPanel') || Object.values(detailsPanelTypes).includes(component))) return animations.detailsPanel;
    if (isObjectExplorerTransitions && ((component === 'CxObject') || (component === 'CxObjectExplorer'))) return animations.objectExplorer;
    if (isAvatarTransitions && (component === 'CxAvatar')) return animations.avatar;
    if (isLegendTransitions && (component === 'CxLegend')) return animations.legend;
    if (isDirectSearchTransitions && (component === 'CxDirectSearch')) return animations.directSearch;
    if (isMapToolbarTransitions && (component === 'CxMapToolbar')) return animations.mapToolbar;
    return null;
  };

  const applyAnimations = (element) => {
    const componentAnimations = getAnimations(componentName);
    return componentAnimations ? componentAnimations[element] : '';
  };

  onMounted(() => {
    isMounted.value = true;
  });

  return {
    // Data
    isMounted,
    // Computed
    isAnimationReady,
    // Methods
    getAnimations,
    applyAnimations
  };
}