import { parseISO, formatISO } from 'date-fns';
import httpAwareness from '@/http/awareness.axios.instance';
import httpGeosever from '@/http/geoserver.axios.instance';
import { awarenessEventSetFactory } from '@/domain/awareness/AwarenessEventSetFactory';
import { filterMapper } from '@/http/filter.mapper';
import { downloadFile } from '@/http/common/download.service';
import awarenessEventMapper from './awarenessEvent.mapper';
import axios from 'axios';
import { AwarenessSetOriginFilters } from '@/domain/awareness/AwarenessSetOriginFilters';
import { AwarenessEventSet } from '@/domain/awareness/AwarenessEventSet';
import { AwarenessEvent } from '@/domain/awareness/AwarenessEvent';
import { AwarenessGeojson } from '@/domain/awareness/AwarenessGeojson';


/**
 * This limits the amount of events to be queried from the backend in a single api call.
 * This is due to the awareness service performance constraints.
 * @type {number}
 */
const EVENT_PERFORMANCE_LIMIT: number = 10000;

const fetchAwarenessEvent = async ({ projectUuid, uuid }:{
    projectUuid: string,
    uuid: string}):Promise<AwarenessEvent> => {
  const url = `/projects/${projectUuid}/events/${uuid}`;
  const response = await httpAwareness.get(url);
  return awarenessEventMapper.mapAwarenessEventDetails(response.data);
};

const filterAwarenessEvents = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: AwarenessSetOriginFilters[],
  metadataFields: string[]
):Promise<AwarenessEventSet> => {
  const fromTime = encodeURIComponent(startTime);
  const toTime = encodeURIComponent(stopTime);
  const mappedFilters = filterMapper.map(filters);
  const performanceQuery = `page=${0}&size=${EVENT_PERFORMANCE_LIMIT}`;
  const awarenessEventUrl = `/projects/${projectUuid}/events?from_time=${fromTime}&to_time=${toTime}&${performanceQuery}`;
  const params = {
    filters: mappedFilters,
    metadata_fields: metadataFields
  };
  const awarenessEventResponse = await httpAwareness.post(awarenessEventUrl, params);
  const events = awarenessEventMapper.mapAwarenessEvents(awarenessEventResponse.data);

  return awarenessEventSetFactory({
    count: events.count,
    totalCount: events.totalCount,
    exceedsPerformanceLimit: events.exceedsPerformanceLimit,
    events: events.events,
    originFilters: filters,
    originTimeRange: { from: parseISO(startTime), to: parseISO(stopTime) }
  });
};

const getEventsVisualizationGeojson = async (
  eventSet:AwarenessEventSet,
  projectUuid:string
):Promise<AwarenessGeojson> => {
  const fromTime = encodeURIComponent(formatISO(eventSet.originTimeRange.from));
  const toTime = encodeURIComponent(formatISO(eventSet.originTimeRange.to));
  const mappedFilters = filterMapper.map(eventSet.originFilters);
  const awarenessEventUrl = `/projects/${projectUuid}/events/visualize?from_time=${fromTime}&to_time=${toTime}`;
  const params = {
    filters: mappedFilters,
  };
  const awarenessEventResponse = await httpAwareness.post(awarenessEventUrl, params);
  return awarenessEventResponse.data;
};

const getWmsUrl = async (projectUuid: string):Promise<string> => {
  const authorizingProxyUrl = `/${projectUuid}/authorize/`;
  const authorizingProxyResponse = await httpGeosever.get(authorizingProxyUrl);
  return authorizingProxyResponse.data;
};

const exportAwarenessEvents = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: AwarenessSetOriginFilters[],
  fileName: string,
  fileFormat: string,
  metadataFields: string[] = ['all']
): Promise<void> => {
  const mappedFilters = filterMapper.map(filters);
  const awarenessEventUrl = `/projects/${projectUuid}/events?from_time=${startTime}&to_time=${stopTime}&filename=${fileName}&format=${fileFormat}`;
  const params = {
    filters: mappedFilters,
    metadata_fields: metadataFields
  };
  await httpAwareness.post(awarenessEventUrl, params, { responseType: 'blob' }).then(response => downloadFile(response.data, fileName));
};

const fetchPoweBiTemplate = async ():Promise<void> => {
  const url = './ConX_Awareness_Events_template.pbit';
  await axios.get(url, { responseType: 'blob' }).then(response => downloadFile(response.data, 'ConX_Awareness_Events_template.pbit'));
};

const awarenessEventApi = { filterAwarenessEvents, exportAwarenessEvents, getEventsVisualizationGeojson, fetchAwarenessEvent, getWmsUrl, fetchPoweBiTemplate };
export default awarenessEventApi;