import _ from 'lodash';
import { differenceInMinutes } from 'date-fns';
import { enGB } from 'date-fns/locale';
import datetimehelper from '@/utils/datetimehelper';

const numberFormatter = (precision = 2) => (params) => {
  if (_.isNil(params.value) || !_.isNumber(params.value)) return '';
  const rounded = _.round(params.value, precision);
  return rounded.toFixed(precision);
};

const numberComparator = (valueA, valueB) => {
  if (!_.isNumber(valueA) && !_.isNumber(valueB)) return 0;
  if (!_.isNumber(valueA)) return -1;
  if (!_.isNumber(valueB)) return 1;
  return valueA - valueB;
};

const dateFormatter = (params) => {
  if (!params.value) return '';
  return datetimehelper.format(params.value, datetimehelper.LOCALE_DATE_TIME_FORMAT, { locale: enGB });
};

const dateComparator = (valueA, valueB) => {
  if (_.isNil(valueA) && _.isNil(valueB)) return 0;
  if (valueA && !valueB) return 1;
  if (!valueA && valueB) return -1;
  return differenceInMinutes(valueA, valueB);
};

const caseInsensitiveStringComparator = (valueA, valueB) => {
  if (_.isNil(valueA) && _.isNil(valueB)) return 0;
  if (_.isNil(valueA)) return -1;
  if (_.isNil(valueB)) return 1;

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

export { numberFormatter, numberComparator, dateFormatter, dateComparator, caseInsensitiveStringComparator };