
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { Point } from '@/domain/point/Point';
import { EarthmovingPoint } from '@/domain/point/EarthmovingPoint';
import { StabilizationPoint } from '@/domain/point/StabilizationPoint';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';

const createDefaultPoint = ({
  uuid,
  pointName,
  logTime,
  lastUpdatedAt,
  latitude,
  longitude,
  ellipsoidHeight,
  northing,
  easting,
  height,
  referenceModelName,
  unitName,
  unitType,
  gnsscq3d,
  coordinateSystem,
  equipmentType,
  isAutoLogged,
  isDiscarded,
  jobName,
  pointCode,
  comment
}: {
  uuid: string,
  pointName: string,
  logTime: string,
  lastUpdatedAt: string | null,
  latitude: string | number,
  longitude: string | number,
  ellipsoidHeight: number,
  northing?: string | number,
  easting?: string | number,
  height: string | number,
  referenceModelName?: string,
  unitName: string,
  unitType: UnitTypes,
  gnsscq3d?: string | number,
  coordinateSystem?: string,
  equipmentType: string,
  isAutoLogged?: boolean
  isDiscarded?: boolean,
  jobName?: string
  pointCode?: string,
  comment: string | null
}): Point => {
  return {
    entityType: PointTypes.DefaultPointType,
    uuid,
    pointName,
    logTime,
    lastUpdatedAt,
    latitude,
    longitude,
    ellipsoidHeight,
    northing,
    easting,
    height,
    referenceModelName,
    unitName,
    unitType,
    gnsscq3d,
    coordinateSystem,
    equipmentType,
    isAutoLogged,
    isDiscarded,
    jobName,
    pointCode,
    comment
  };
};

const createStabilizationPoint = ({
  uuid,
  pointName,
  logTime,
  lastUpdatedAt,
  latitude,
  longitude,
  ellipsoidHeight,
  northing,
  easting,
  height,
  referenceModelName,
  unitName,
  unitType,
  gnsscq3d,
  coordinateSystem,
  equipmentType,
  isAutoLogged,
  isDiscarded,
  drillPileStatus,
  heightOffsetType,
  heightOffset,
  sideDistance,
  targetHoleAngle,
  targetHoleHeading,
  station,
  actualToolAngle,
  actualToolHeading,
  toolDiameter,
  drillPileDepth,
  pileHeight,
  drillPileDepthSource,
  panelSerialNumber,
  pointCode,
  sequenceNumber,
  comment
}: {
  uuid: string,
  pointName: string,
  logTime: string,
  lastUpdatedAt: string | null,
  latitude: string | number,
  longitude: string | number,
  ellipsoidHeight: number,
  northing?: string | number,
  easting?: string | number,
  height: string | number,
  referenceModelName?: string,
  unitName: string,
  unitType: UnitTypes,
  gnsscq3d?: string | number,
  coordinateSystem?: string,
  equipmentType: string,
  isAutoLogged?: boolean,
  isDiscarded?: boolean,
  drillPileStatus?: string,
  heightOffsetType?: string,
  heightOffset?: string | number,
  sideDistance?: string | number,
  targetHoleAngle?: string | number,
  targetHoleHeading?: string | number,
  station?: string | number,
  actualToolAngle?: string | number,
  actualToolHeading?: string | number,
  toolDiameter?: string | number,
  drillPileDepth?: string,
  pileHeight?: number,
  drillPileDepthSource?: string,
  panelSerialNumber?: string | number,
  pointCode?: string,
  sequenceNumber?: number,
  comment: string | null
}): StabilizationPoint => {
  return {
    entityType: PointTypes.StabilizationPointType,
    uuid,
    pointName,
    logTime,
    lastUpdatedAt,
    latitude,
    longitude,
    ellipsoidHeight,
    northing,
    easting,
    height,
    referenceModelName,
    unitName,
    unitType,
    gnsscq3d,
    coordinateSystem,
    equipmentType,
    isAutoLogged,
    isDiscarded,
    drillPileStatus,
    heightOffsetType,
    heightOffset,
    sideDistance,
    targetHoleAngle,
    targetHoleHeading,
    station,
    actualToolAngle,
    actualToolHeading,
    toolDiameter,
    drillPileDepth,
    pileHeight,
    drillPileDepthSource,
    panelSerialNumber,
    pointCode,
    sequenceNumber,
    comment
  };
};

const createEarthmovingPoint = ({
  uuid,
  pointName,
  logTime,
  lastUpdatedAt,
  latitude,
  longitude,
  ellipsoidHeight,
  northing,
  easting,
  height,
  referenceModelName,
  unitName,
  unitType,
  gnsscq3d,
  equipmentType,
  coordinateSystem,
  isAutoLogged,
  isDiscarded,
  deltaHeight,
  jobName,
  pointCode,
  comment
}: {
  uuid: string,
  pointName: string,
  logTime: string,
  lastUpdatedAt: string | null,
  latitude: string | number,
  longitude: string | number,
  ellipsoidHeight: number,
  northing?: string | number,
  easting?: string | number,
  height?: string | number,
  referenceModelName?: string,
  unitName?: string,
  unitType: UnitTypes,
  gnsscq3d?: string | number,
  coordinateSystem?: string,
  equipmentType: string,
  isAutoLogged?: boolean,
  isDiscarded?: boolean,
  deltaHeight: number,
  jobName?: string
  pointCode?: string,
  comment?: string | null
}): EarthmovingPoint => {
  return {
    entityType: PointTypes.EarthmovingPointType,
    uuid,
    pointName,
    logTime,
    lastUpdatedAt,
    latitude,
    longitude,
    ellipsoidHeight,
    northing,
    easting,
    height,
    referenceModelName,
    unitName,
    unitType,
    gnsscq3d,
    equipmentType,
    coordinateSystem,
    isAutoLogged,
    isDiscarded,
    deltaHeight,
    jobName,
    pointCode,
    comment
  };
};

export {
  createDefaultPoint,
  createStabilizationPoint,
  createEarthmovingPoint
};