<template>
  <div class="cx-refmodel-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon icon="apps" class="map-overlay__title__icon cxu-color-highlight" :size="12"/>
      <span class="map-overlay__title__name">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxDrillpatternTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.name;
    },
  },
};
</script>
