<template>
  <div class="cx-drill-details-panel" v-if="drillPattern">
    <!-- OVERLAY CONTROLS -->
    <transition :name="applyAnimations('overlayControls')">
      <cx-drill-pattern-overlay-controls v-if="isAnimationReady"/>
    </transition>
    <!-- TITLE -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            :source="drillPattern.avatar"
            :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyTo"/>
        </transition>
      </template>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="drillPattern.name"
            :subtitle="drillPattern.subtitle.value"
            :subtitleIcon="drillPattern.subtitle.icon"
            :subtitleIconTooltip="drillPattern.subtitle.field"/>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel
            v-if="isAnimationReady"
            :showPoint="true"
            :pointName="pointText"
            :showRefmodel="false"
            :onShowPointsData="showPointsData"
            :showPointsDataLoading="isFetchingPoints"
            :showPointsDataVisible="isGridPoc"/>
        </transition>
      </template>
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <div class="cx-cmn-dp__analysisPanel">
      <cx-export-asbuild-component
        :exportPoints="(selectedExtension) => exportPoints(selectedExtension.value)"
        :loading="isExporting"
        :isOpened="isDetailsPanelOpened"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DrillPatternDetailsViewModel } from './drillPatternViewModel';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxAvatar from '../common/CxAvatar';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxStatsPanel from '../common/CxStatsPanel';
import CxExportAsbuildComponent from '../common/CxExportAsbuildComponent';
import CxDrillPatternOverlayControls from '@/app/detailsPanel/drillPattern/CxDrillPatternOverlayControls';
import CxTitlePanel from '../common/CxTitlePanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';

export default {
  name: 'CxDrillPatternDetailsPanel',
  components: {
    CxAvatar,
    CxRelatedEntitiesPanel,
    CxMainDataPanel,
    CxStatsPanel,
    CxExportAsbuildComponent,
    CxDrillPatternOverlayControls,
    CxTitlePanel
  },
  mixins: [CxLicenceMixin, CxAnimationMixin],
  computed: {
    ...mapGetters('shell', ['isDetailsPanelReduced', 'isDetailsPanelOpened']),
    ...mapGetters('detailsDrillPattern', ['selectedDrillPattern', 'isExporting', 'pointSet', 'unitCount', 'isFetchingPoints']),
    drillPattern() {
      if (!this.selectedDrillPattern) return null;
      return new DrillPatternDetailsViewModel(this.selectedDrillPattern);
    },
    mainData() {
      if (!this.selectedDrillPattern) return [];
      const { user, updatedTime, createdTime } = this.drillPattern;
      const result = [
        {
          field: user.field,
          value: user.value,
          icon: user.icon
        },
        {
          field: updatedTime.field,
          value: updatedTime.value,
          icon: updatedTime.icon
        },
        {
          field: createdTime.field,
          value: createdTime.value,
          icon: createdTime.icon
        }
      ];
      return result;
    },
    pointText() {
      if (!this.selectedDrillPattern || !this.pointSet) return '';
      const pointCount = this.pointSet.points.length;
      const translated = this.$ngettext('%{ n } Measured Point', '%{ n } Measured Points', pointCount);
      const interpolated = this.$gettextInterpolate(translated, { n: pointCount });
      return interpolated;
    }
  },
  methods: {
    ...mapActions('detailsDrillPattern', [
      'handleCameraFlyTo',
      'exportPoints',
      'showPointsData'
    ]),
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-export-asbuild-component {
  margin: 0px 0px auto auto;
}

.cx-drill-details-panel {
  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;
  .cx-title-panel--title-content__title--title {
    max-width: @detailsTitleCaptionWidth;
  }
}

</style>