<template>
  <div
    class="cx-toast" :style="computedStyle"
    :class="[alertContent.type]">
    <cx-icon icon="error_outline" :size="14" class="cx-toast-info_icon"/>
    <div class="cx-toast-text">{{ alertContent.text }}</div>
    <cx-button flat icon="close" :iconSize="14" @click="clearAlert"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxIcon from '@/components/CxIcon';
import CxButton from '@/components/CxButton';

export default {
  name: 'CxToast',
  components: {
    CxButton,
    CxIcon
  },
  data() {
    return {
      size: { width: 0 },
    };
  },

  mounted() {
    this.size.width = this.$el.clientWidth;
  },

  methods: {
    ...mapActions('notifications', { clearAlert: 'clearAlert' })
  },

  computed: {
    ...mapGetters('notifications', { alertContent: 'alert' }),
    computedStyle() {
      return {
        left: `calc(0.5 * 100vw - 0.5 * ${this.size.width}px)`
      };
    }
  },

  watch: {
    alertContent() {
      this.$nextTick(() => { this.size.width = this.$el.clientWidth; });
    }
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-toast {
  z-index: @alertZI;
  position: absolute;
  top: 65px;
  z-index: 10;
  background-color: @errorAlertColor;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  max-width: 600px;
  min-height: 30px;
  border-radius: 17px;
  font-size: 12px;
  padding: 8px 10px 7px 10px;
  display: flex;
  color: @textColor;

  &.warning {
    background-color: @warningAlertColor !important;
    .cx-alert-text { color: @textColor;}
  }
  &.notify {
    background-color: @notifyAlertColor !important;
  }
  &.success {
    background-color: @successAlertColor !important;
    .cx-alert-text { color: @textColor;}
  }

  .cx-toast-info_icon {
    height: 16px;
  }

  .cx-toast-text {
    padding-right: 7px;
    padding-left: 7px;
    text-overflow: ellipsis;
    word-break: none;
    overflow: hidden;
    color: @textColorHighlight;
  }
}
</style>