import { RefModel } from '@/domain/refmodels/RefModel';
import { SurfacePanelState } from './SurfacePanelState';
import { addHours } from 'date-fns';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { SurfacelogSignalSldStyle } from '@/domain/surfacelog/SurfacelogSignalSldStyle.enum';
import { SurfacelogDeltaHeightLegend } from '@/domain/surfacelog/Surfacelog';
import { cloneDeep } from 'lodash';
import { convert } from '@/utils/measurementUnitHelper';

interface State {
  panelState: SurfacePanelState
}

const TTL_IN_HOURS = 12;

const convertDhLegend = (legend, from, to) => {
  const map = legend.map((interval) => {
    return {
      color: interval.color,
      value: convert(interval.value)
        .withPrecision(2)
        .from(from)
        .to(to)
    };
  }).sort((a, b) => b.value - a.value);
  return map;
};

const detailsSurfacelogModule = {
  state: {
    panelState: {},
    defaultDeltaHeightLegend: [
      { value: 0.70, color: '#00008B' },
      { value: 0.50, color: '#0000FF' },
      { value: 0.30, color: '#ADD8E6' },
      { value: 0.10, color: '#008080' },
      { value: -0.10, color: '#008000' },
      { value: -0.30, color: '#FFFF00' },
      { value: -0.50, color: '#FFA500' },
      { value: -0.70, color: '#ff7300' },
      { value: -0.71, color: '#FF0000' },
    ],
    deltaHeightLegend: {}
  },
  mutations: {
    setSelectedRefmodelId(state, payload: { surfaceId: string, refModelId?: string }): void {
      const panelState = { ...state.panelState };
      panelState[payload.surfaceId].selectedRefmodelId = payload.refModelId ? payload.refModelId : null;
      state.panelState = panelState; // because of reactivity we assign whole object copy to state property
    },
    removeSurfacePanelState(state, surfaceId: string): void {
      delete state.panelState[surfaceId];
    },
    setDeltaHeightCalculation(state, { refModelId, surfaceId, wmsUrl, signals }: { refModelId: string, surfaceId: string, wmsUrl: string, signals: string[] }): void {
      const panelState = { ...state.panelState };
      panelState[surfaceId].calculatedDH[refModelId] = { wmsUrl, signals, expirationDate: addHours(Date.now(), TTL_IN_HOURS) };
      state.panelState = panelState;
    },
    setDeltaHeightLegend(state, { layerUuid, legend }: { layerUuid: string, legend: SurfacelogDeltaHeightLegend }): void {
      const deltaHeightLegend = { ...state.deltaHeightLegend };
      deltaHeightLegend[layerUuid] = cloneDeep(legend);
      state.deltaHeightLegend = deltaHeightLegend;
    },
  },
  getters: {
    refModels(state: State, getters: any, rootState: any, rootGetters: any): RefModel {
      return rootGetters['app/allModels'];
    },
    surfacePanelState: (state) => (surfaceId: string): { selectedRefmodelId: string, calculationDH: { [key: string]: string } } => {
      if (!state.panelState[surfaceId]) {
        state.panelState[surfaceId] = {
          selectedRefmodelId: null,
          calculatedDH: {},
        };
      }
      return state.panelState[surfaceId];
    },
    getDeltaHeightLegend: (state, getters, context, rootGetters) => (layerUuid: string | null): SurfacelogDeltaHeightLegend => {
      return layerUuid && state.deltaHeightLegend[layerUuid] ? convertDhLegend(cloneDeep(state.deltaHeightLegend[layerUuid]), 'm', rootGetters['app/measurementSystem'].baseLengthUnit.name) : convertDhLegend(cloneDeep(state.defaultDeltaHeightLegend), 'm', rootGetters['app/measurementSystem'].baseLengthUnit.name);
    },
    getDeltaHeightCalculation: (state, getters) => (refModelId: string, surfaceId: string): { wmsUrl: string, signals: string[], expirationDate: Date } | null => {
      if (getters.surfacePanelState(surfaceId).calculatedDH[refModelId]) {
        return getters.surfacePanelState(surfaceId).calculatedDH[refModelId];
      }
      return null;
    },
    isDeltaHeightCalculated: (state, getters) => (refModelId: string, surfaceId: string): boolean => {
      if (getters.surfacePanelState(surfaceId).calculatedDH[refModelId]) {
        return getters.surfacePanelState(surfaceId).calculatedDH[refModelId].expirationDate - Date.now() > 0;
      }
      return false;
    },
    isDeltaHeightCalculationExpired: (state, getters) => (refModelId: string, surfaceId: string): boolean => {
      if (getters.surfacePanelState(surfaceId).calculatedDH[refModelId]) {
        return getters.surfacePanelState(surfaceId).calculatedDH[refModelId].expirationDate - Date.now() <= 0;
      }
      return false;
    },
    selectedRefmodelId: (state, getters) => (surfaceId: string) => {
      return getters.surfacePanelState(surfaceId).selectedRefmodelId;
    }
  },
  actions: {
    setSelectedRefmodelId(context, payload: { surfaceId: string, refModelId?: string }): void {
      context.commit('setSelectedRefmodelId', payload);
      if (payload.refModelId && context.getters.isDeltaHeightCalculationExpired(payload.refModelId, payload.surfaceId)) {
        context.commit('map/removeSurfacelogSignals', payload.surfaceId, { root: true });
      }
    },
    changeSurfacelogLegend(context, payload: { layerUuid, legend }) {
      const convertedLegend = convertDhLegend(cloneDeep(payload.legend), context.rootGetters['app/measurementSystem'].baseLengthUnit.name, 'm');
      context.commit('setDeltaHeightLegend', { layerUuid: payload.layerUuid, legend: convertedLegend });
      context.dispatch('map/changeSurfaceLogSignal', { uuid: payload.layerUuid, signal: SurfacelogSignalTypes.DeltaHeight, styleSLD: SurfacelogSignalSldStyle.DH, deltaHeightLegend: convertedLegend }, { root: true });
    },
  }
};

export default detailsSurfacelogModule;