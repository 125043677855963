import http from '@/http/axios.instance';
import mapper from './refmodel.mapper';
import licenceService from '@/services/licence.service';
import { DrillPatternJson, DrillPatternResponse, RefmodelResponse } from './refmodelApi.types';
import { File } from '@/domain/refmodels/File.types';

const getDrillPatternIds: (project_uuid: string) => Promise<string[]> =
  async (project_uuid) => {
    let result: string[] = [];
    if (licenceService.hasStabilizationLicence()) {
      const url = `/api/ui2/v1/projects/${project_uuid}/refmodel/stabilization/?state=1&skip_pagination=true&format=json&type=REFMODEL&extension__in=.DWG&extension__in=.DXF&extension__in=.xml&extension__in=.GEO&extension__in=.KOF&extension__in=.IRD`;
      const response = await http.get(url);
      result = response.data.map(r => r.uuid);
    }
    return result;
  };


const mapReferenceModels: (refmodels: RefmodelResponse[], drillPatternIds: string[]) => (File)[] =
(refmodels, drillpatternIds) => {
  const drillPratterns = refmodels
    .filter(r => drillpatternIds.includes(r.uuid))
    .map(mapper.mapDrillPattern);
  const refmodelEntities = refmodels
    .filter(r => !drillpatternIds.includes(r.uuid))
    .map(r => mapper.mapRefmodelToEntity(r));
  return [
    ...drillPratterns,
    ...refmodelEntities
  ];
};

/**
 * Fetch ReferenceModel files
 * @param {string} project_uuid
 */
const getReferenceModels = async (project_uuid) => {
  const referenceModelsUrl = `/api/one/v1/projects/${project_uuid}/data/?state=1&skip_pagination=true&format=json&type=REFMODEL&extension__in=.DWG&extension__in=.LMD&extension__in=.TRM&extension__in=.MBS&extension__in=.DXF&extension__in=.xml&extension__in=.GEO&extension__in=.L3D&extension__in=.KOF&extension__in=.CFM&extension__in=.LIN&extension__in=.IRD&refmod_meta_data=1`;
  const referenceModelsResponse = await http.get<RefmodelResponse[]>(referenceModelsUrl);
  const referenceModels = referenceModelsResponse.data;
  const drillPatternIds = await getDrillPatternIds(project_uuid); // we only ask for drill ids if we have a licence
  return mapReferenceModels(referenceModels, drillPatternIds);
};

/**
 * Fetch KML and KMZ files
 * @param {string} project_uuid
 */
const getDataSources:(project_uuid: string) => Promise<RefmodelResponse> =
  async (project_uuid) => {
    const dataSourcesUrl = `/api/one/v1/projects/${project_uuid}/data/?skip_pagination=true&format=json&extension__in=.KMZ&extension__in=.KML&refmod_meta_data=1`;
    const dataSourceResponse = await http.get(dataSourcesUrl);
    const dataSources = dataSourceResponse.data;
    const mappedDataSources = dataSources.map(mapper.mapDataSourceToEntity);
    return mappedDataSources;
  };

/**
 * Fetches the GLTF, that will be passed to Cesium to display reference model on the 3D map
 * @param {string} uuid
 * @returns GLTF model
 */
const getReferenceModelGltf: (uuid: string) => Promise<any> =
async (uuid) => {
  const modelConvertrUrl = `/api/one/v1/mapdata/${uuid}/?3DModel=1`;
  const model3DResponse = await http.get(modelConvertrUrl);
  if (model3DResponse.data === undefined) return model3DResponse; // We want to keep the json object containing the error
  if (model3DResponse.data.data === undefined) return model3DResponse.data; // Pretty usual case (cached model)
  return model3DResponse.data.data; // This is in case the model has not been cached
};

/**
 * Fetches json that discribes a drillpattern used to present it on the map
 * @param uuid
 * @returns DrillPatterJson
 */
const getDrillPatternJson: (uuid: string) => Promise<DrillPatternJson> =
  async (uuid) => {
    const url = `/api/one/v1/mapdata/${uuid}/?asDrillPlan=1`;
    const response = await http.get<DrillPatternResponse>(url);
    return mapper.mapDrillPatternJson(uuid, response.data);
  };

const refmodelApi = {
  getReferenceModels,
  getDataSources,
  getReferenceModelGltf,
  getDrillPatternJson
};

export default refmodelApi;