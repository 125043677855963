<template>
  <div class="cx-delta">
    <i v-if="trend === 'upwards'" class="material-icons cx-trend-arrow"> arrow_upward </i>
    <i v-if="trend === 'downwards'" class="material-icons cx-trend-arrow"> arrow_downward </i>
    <i v-if="trend === 'const'" class="material-icons cx-trend-constant">brightness_1</i>
    <span class="cx-delta__value" v-for="(value, index) in values" :key="index">
      <span v-if="index !== 0">
        <i class="material-icons cx-value-arrow"> arrow_forward </i>
      </span>
      <cx-display-number :value="value" :useUnit="index === values.length - 1" className="cx-delta"/>
    </span>
  </div>
</template>

<script>
import CxDisplayNumber from './CxDisplayNumber';
/**
 * @prop trend {String}: 'upwards' | 'downwards' | 'const'
 */
export default {
  name: 'CxDelta',
  props: {
    values: Array,
    unit: {
      type: String,
      default: 'm'
    },
    trend: {
      type: String,
      required: true
    },
  },
  components: {
    CxDisplayNumber
  },
};
</script>

<style lang="less" scoped>
@import '../../../../common';

  .cx-delta {
    text-align: right;
    font-size: @appFontSize;
  }
  .cx-trend-arrow {
    vertical-align: text-bottom;
    font-size: 14px;
    color:@textColor;
  }
  .cx-trend-constant {
    vertical-align: center;
    font-size: 8px;
    color:@textColor;
    margin-right: 2px;
  }
  .cx-value-arrow {
    vertical-align: center;
    font-size: @microFontSize;
  }

  .cx-delta {
    color: inherit;
  }

</style>