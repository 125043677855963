<template>
  <div class="cx-map-tooltip map-overlay" :style="computedStyle" v-if="tooltipPosition && tooltipComponent">
    <component :is="tooltipComponent" :hoveredEntity="entity"/>
  </div>
</template>

<script>
import CxPointTooltip from './CxPointTooltip';
import CxUnitTooltip from './CxUnitTooltip';
import CxHoleTooltip from './CxHoleTooltip';
import CxReferenceModelTooltip from './CxReferenceModelTooltip';
import CxDrillpatternTooltip from './CxDrillpatternTooltip';
import CxAwarenessEventTooltip from './CxAwarenessEventTooplip';
import { AwarenessEventType } from '@/domain/awareness/AwarenessEventType';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';

export default {
  name: 'CxMapTooltip',
  components: {
    CxPointTooltip,
    CxUnitTooltip,
    CxHoleTooltip,
    CxReferenceModelTooltip,
    CxDrillpatternTooltip,
    CxAwarenessEventTooltip
  },
  props: {
    // domain entity
    entity: Object,
    // { top, bottom }
    tooltipPosition: Object
  },
  computed: {
    tooltipComponent() {
      if (!this.entity) return null;
      if (Object.values(PointTypes).includes(this.entity.entityType)) return 'CxPointTooltip';
      if (Object.values(UnitTypes).includes(this.entity.entityType)) return 'CxUnitTooltip';
      if (Object.values(StabilizationTypes).includes(this.entity.entityType)) return 'CxHoleTooltip';
      if (Object.values(RefmodelTypes).includes(this.entity.entityType) && this.entity.entityType !== RefmodelTypes.DrillPattern) return 'CxReferenceModelTooltip';
      if (this.entity.entityType === RefmodelTypes.DrillPattern) return 'CxDrillpatternTooltip';
      if (this.entity.entityType === AwarenessEventType) return 'CxAwarenessEventTooltip';
      return null;
    },
    computedStyle() {
      if (!this.tooltipPosition) return '';
      return {
        bottom: `${this.tooltipPosition.bottom}px`,
        left: `${this.tooltipPosition.left}px`,
      };
    },
  },
};
</script>

<style lang="less">
  @import '../../common';

  .cx-map-tooltip {
    position: absolute;
  }

  // Map Tooltip
  .map-overlay {
    white-space: pre;
    font-family: @appFontFamily, sans-serif;
    text-align: left;
    pointer-events: none;
    background-color: @tooltipBGColor;
    border-radius: @inputBorderRadiusRounded;
    padding: @inputSpace 16px 14px 16px;
    color: @textColorHighlight;
    max-width: @cxTooltipMaxWidth;
    word-break: break-all;
    white-space: normal;
    margin: 0 0 5px 5px;

    .map-overlay__title {
      display: flex;

      .map-overlay__title__icon {
        flex: 1 0 auto;
        padding-right: 5px;

        i {
          font-size: @microFontSize;
        }

        &.cut {
          i {
            color: @cutColor
          }
        }

        &.fill {
          i {
            color: @fillColor
          }
        }

        &.on-grade {
          i {
            color: @onGradeColor
          }
        }

        &.drillpile-yellow {
          i {
            color: @drillpileYellow
          }
        }

        &.drillpile-red {
          i {
            color: @drillpileRed
          }
        }

        &.drillpile-green {
          i {
            color: @drillpileGreen
          }
        }
      }

      .map-overlay__title__name {
        font-weight: @appFontWeight;
        font-size: @majorFontSize;
      }
    }

    .map-overlay__subtitles {
      .flex(column, flex-start, flex-start);
      font-size: @minorFontSize;
      color: @textColor;
      vertical-align: bottom;
      padding-left: 17px;

      &.hide {
        display: none;
      }
    }
  }
</style>