import { formatISO } from 'date-fns';
import api from '@/http/api';
import { createFilter } from '@/domain/filters/filterModelFactory';
import { PointTypes } from '@/domain/point/PointTypes.enum';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const SOURCE_UUID = 'point-details-panel-source';

const detailsPointModule = {
  state: {
    // available tabs
    analysisTabs: null,
    // analysis tab that is currently active
    activeAnalysisTab: null,
    // object that consist of all metadata that point might have in db, dependant on licence
    selectedPoint: null
  },
  mutations: {
    setActiveAnalysisTab(state, payload) {
      state.activeAnalysisTab = payload;
    },
    setSelectedPoint(state, point) {
      state.selectedPoint = point;
    }
  },
  getters: {
    selectedEntity(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (selectedEntity && Object.values(PointTypes).includes(selectedEntity.entityType)) {
        return selectedEntity;
      }
      return null;
    },
    selectedPoint(state, getters) {
      if (!getters.selectedEntity) return null;
      if (!state.selectedPoint) return getters.selectedEntity;
      return state.selectedPoint;
    },
    availableAnalysisTabs(state) {
      return state.analysisTabs;
    },
    activeAnalysisTab(state) {
      return state.activeAnalysisTab;
    },
    isFetchingData(sate, getters, rootState, rootGetters) {
      if (!getters.selectedPoint) return false;
      return rootGetters['cancelable/isLoading']({ actionType: 'detailsPoint/fetchPointData', sourceUid: SOURCE_UUID });
    }
  },
  cancelableActions: {
    /**
     * Fetches all available metadata for given point id
     * @param {} context
     * @param {*} cancelToken
     */
    async fetchPointData(context, { cancelToken }) {
      const { selectedEntity } = context.getters;
      const { projectUuid } = context.rootGetters;
      const projectStartDate = context.rootGetters['app/projectStartDate'];
      const endDate = new Date(Date.now());
      const pointIdFilter = createFilter(FilterModelNames.Id, [selectedEntity.uuid]);

      if (cancelToken.canceled) return;
      try {
        const pointSet = await api.filterMeasurePoints(
          projectUuid,
          formatISO(projectStartDate),
          formatISO(endDate),
          [pointIdFilter],
          true,
          ['all']
        );
        const selectedPointMetadata = (pointSet && pointSet.points && pointSet.points.length) ? pointSet.points[0] : null;
        context.commit('setSelectedPoint', selectedPointMetadata);
      } catch (e) {
        context.dispatch('notifications/error', {
          exception: e,
          message: $gettext('Error has occured - can\'t fetch point metadata')
        }, { root: true });
      }

    },
  },
  actions: {
    async handleCameraFlyToPoint(context) {
      await context.dispatch('map/setCameraToEntity', context.getters.selectedPoint.uuid, { root: true });
    },
    // handle selection of details point store
    async handlePointSelection(context) {
      // clear point metadata, not to display old data to the user
      context.commit('setSelectedPoint', null);
      // cancel all actions that are curently fetching any point data and disregard it's results
      await context.dispatch('cancelable/cancelActions', { sourceUid: SOURCE_UUID }, { root: true });
      // start fetching new data - display spinner
      await context.dispatch('fetchPointData', { sourceUid: SOURCE_UUID });
    }
  },

};

export default detailsPointModule;