import userSettingsService from '@/services/userSettings.service';

const mapToolbarStore = {
  state: {
    navigationInstructions: [],
    availableBaseLayers: [],
    selectedBaseLayer: null,
    compassDirection: 0
  },
  getters: {
    navigationInstructions(state) {
      return state.navigationInstructions;
    },
    availableBaseLayers(state) {
      return state.availableBaseLayers;
    },
    selectedBaseLayer(state) {
      return state.selectedBaseLayer;
    },
    compassDirection(state) {
      return state.compassDirection;
    }
  },
  mutations: {
    setNavigationInstructions(state, payload) {
      state.navigationInstructions = payload;
    },
    setAvailableBaseLayers(state, payload) {
      state.availableBaseLayers = payload;
    },
    setSelectedBaseLayer(state, payload) {
      state.selectedBaseLayer = payload;
    },
    /**
     * Sets compass needle direction.
     * @param {*} state
     * @param {Number} payload compass needle direction in Degrees
     */
    setCompassDirection(state, payload) {
      state.compassDirection = payload;
    }
  },
  actions: {
    async initializeNavigationInstructions(context, payload) {
      context.commit('setNavigationInstructions', payload);
    },
    async initialize3dBaseLayers(context) {
      let selectedImageryName = null;
      const loadImagery = context.rootState.app.mapImagery;
      try {
        if (loadImagery) selectedImageryName = userSettingsService.restoreBaseLayer();
      } catch (error) {
        context.dispatch('notifications/error', { exception: error, message: error.message }, { root: true });
      } finally {
        context.dispatch('viewer/initializeBaseLayerPicker', { loadImagery, selectedImageryName });
      }
    },
    async baseLayersInitialized(context, payload) {
      context.commit('setAvailableBaseLayers', payload.layers);
      context.commit('setSelectedBaseLayer', payload.selectedLayer);
    },
    async flyHome(context) {
      await context.dispatch('viewer/flyHome');
    },
    async handleBaseLayerSelection(context, payload) {
      await context.dispatch('viewer/updateBaseLayer', payload);
      context.commit('setSelectedBaseLayer', payload);
      try {
        userSettingsService.storeBaseLayer(payload.name);
      } catch (error) {
        context.dispatch('notifications/error', { exception: error, message: error.message }, { root: true });
      }
    },
    async changeCompassDirection(context, payload) {
      context.commit('setCompassDirection', payload);
    },
    /**
     * Zooms the map camera in.
     * @param {*} context
     */
    async zoomIn(context) {
      await context.dispatch('viewer/zoomIn');
    },
    /**
     * Zoom the map camera out.
     * @param {*} context
     */
    async zoomOut(context) {
      await context.dispatch('viewer/zoomOut');
    },
    /**
     * Reorients the map towards cardinal North direction.
     * @param {*} context
     */
    async reorientMap(context) {
      await context.dispatch('viewer/reorientMap');
    }
  }
};

export default mapToolbarStore;