import shaderTypes from '@/common/shaderTypes';

const detailsRefmodelOverlayModule = {
  state: {
  },
  getters: {
    // POINTS
    isShowPointsActive(state) {
      return !!state.pointSetUUID;
    },
    // CONTOUR
    isContourActive(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      const shaders = rootGetters['map/refmodelShaders'][getters.selectedRefModel.uuid];
      return !!shaders && shaders.includes(shaderTypes.contour);
    },
    // SURFACE
    isSurfaceActive(state) {
      return !!state.surfaceUUID;
    },
    isSurfaceLoading(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      return rootGetters['cancelable/isLoading']({
        actionType: 'map/loadSurface',
        sourceUid: getters.selectedRefModel.uuid
      });
    },
    // CUT-FILL
    isCutFillActive(state) {
      return !!state.cutFillSurfaceUUID;
    },
    isCutFillLoading(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      return rootGetters['cancelable/isLoading']({
        actionType: 'map/loadCutFillSurface',
        sourceUid: getters.selectedRefModel.uuid
      });
    },
    // HEIGHT MAP
    isHeightMapActive(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      const shaders = rootGetters['map/refmodelShaders'][getters.selectedRefModel.uuid];
      return !!shaders && shaders.includes(shaderTypes.heightMap);
    },
    // SLOPE MAP
    isSlopeMapActive(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      const shaders = rootGetters['map/refmodelShaders'][getters.selectedRefModel.uuid];
      return !!shaders && shaders.includes(shaderTypes.slopeMap);
    },
    // PROGRESS ANALYSIS SURFACE
    isProgressSurfaceActive(state) {
      return !!state.progressSurfaceUUID;
    },
    isProgressSurfaceLoading(state, getters, rootState, rootGetters) {
      if (!getters.selectedRefModel) return false;
      return rootGetters['cancelable/isLoading']({
        actionType: 'map/loadDiffSurface',
        sourceUid: getters.selectedRefModel.uuid
      });
    },
  },
  mutations: {
  },
  actions: {
    // CLEAT ALL OVERLAYS
    async clearOverlays(context) {
      await context.dispatch('changeSurfaceActive', { isActive: false });
      await context.dispatch('changeCutFillActive', { isActive: false });
      await context.dispatch('changeProgressSurfaceActive', { isActive: false });
      await context.dispatch('changeShowPointsActive', { isActive: false });
    },
    // REFMODEL
    async changeReferenceModelActive(context, { isActive }) {
      const { selectedRefModel } = context.state;
      await context.dispatch('map/setEntityVisibility', {
        uuid: selectedRefModel.uuid,
        isVisible: isActive
      }, { root: true });
    },
    // SURFACE
    async loadSurface(context) {
      await context.dispatch('cancelRefmodelActions');
      const { selectedRefModel, selectedPointSet } = context.getters;
      const surfaceUUID = await context.dispatch('map/loadSurface', {
        points: selectedPointSet.points,
        sourceUid: selectedRefModel.uuid
      }, { root: true });
      context.commit('setSurface', surfaceUUID);
      return surfaceUUID;
    },
    async removeSurface(context) {
      await context.dispatch('map/removeSurface', { uuid: context.getters.surfaceUUID }, { root: true });
      context.commit('setSurface', null);
    },
    async changeRefModelOpacity(context, { opacity }) {
      await context.dispatch('map/changeRefModelOpacity', { uuid: context.state.selectedRefModel.uuid, opacity }, { root: true });
    },
    async changeSurfaceActive(context, { isActive }) {
      const { isSurfaceActive, isSurfaceLoading } = context.getters;
      if (isSurfaceLoading) return;
      if (isSurfaceActive === isActive) return;
      if (isActive) {
        await context.dispatch('removeCutFillSurface');
        await context.dispatch('loadSurface');
      } else await context.dispatch('removeSurface');
    },
    async reloadSurface(context) {
      const { surfaceUUID, selectedPointSet } = context.getters;
      if (surfaceUUID) await context.dispatch('removeSurface');
      if (selectedPointSet) await context.dispatch('loadSurface');
    },
    // CUT-FILL DIFF MODEL
    async loadCutFillSurface(context) {
      await context.dispatch('cancelRefmodelActions');
      const { selectedRefModel, selectedPointSet } = context.getters;
      const surfaceId = await context.dispatch('map/loadCutFillSurface', {
        points: selectedPointSet.points,
        modelUuid: selectedRefModel.uuid,
        sourceUid: selectedRefModel.uuid
      }, { root: true });
      context.commit('setCutFillSurface', surfaceId);
      return surfaceId;
    },
    async removeCutFillSurface(context) {
      await context.dispatch('map/removeSurface', { uuid: context.getters.cutFillSurfaceUUID }, { root: true });
      context.commit('setCutFillSurface', null);
    },
    async changeCutFillActive(context, { isActive }) {
      const { isCutFillActive, isCutFillLoading } = context.getters;
      if (isCutFillLoading) return;
      if (isCutFillActive === isActive) return;
      if (isActive) {
        await context.dispatch('removeSurface');
        await context.dispatch('loadCutFillSurface');
      } else await context.dispatch('removeCutFillSurface');
    },
    async reloadCutFill(context) {
      const { cutFillSurfaceUUID, selectedPointSet } = context.getters;
      if (cutFillSurfaceUUID) await context.dispatch('removeCutFillSurface');
      if (selectedPointSet) await context.dispatch('loadCutFillSurface');
    },
    // PROGRESS ANALYSIS SURFACE
    async loadProgressSurface(context) {
      await context.dispatch('cancelRefmodelActions');
      const { selectedRefModel, selectedRange, selectedRangePointSets } = context.getters;
      const surfaceId = await context.dispatch('map/loadDiffSurface', {
        pointsFrom: selectedRangePointSets.from.points,
        pointsTo: selectedRangePointSets.to.points,
        refmodelUuid: selectedRefModel.uuid,
        fromTime: selectedRange.from.timestamp,
        toTime: selectedRange.to.timestamp,
        sourceUid: selectedRefModel.uuid
      }, { root: true });
      context.commit('setProgressSurface', surfaceId);
    },
    async removeProgressSurface(context) {
      const { progressSurfaceUUID } = context.getters;
      await context.dispatch('map/removeSurface', { uuid: progressSurfaceUUID }, { root: true });
      context.commit('setProgressSurface', null);
    },
    async changeProgressSurfaceActive(context, { isActive }) {
      const { isProgressSurfaceActive } = context.getters;
      if (isProgressSurfaceActive === isActive) return;
      if (isActive) await context.dispatch('loadProgressSurface');
      else await context.dispatch('removeProgressSurface');
    },
    async reloadProgressSurface(context) {
      const { progressSurfaceUUID, selectedRange } = context.getters;
      if (progressSurfaceUUID) await context.dispatch('removeProgressSurface');
      if (selectedRange) await context.dispatch('loadProgressSurface');
    },
    // SHADERS
    async toggleContourActive(context) {
      const shaders = [shaderTypes.contour];
      await context.dispatch('map/toggleShader', { uuid: context.state.selectedRefModel.uuid, shaders }, { root: true });
    },
    async toggleHeightMapActive(context) {
      const shaders = [shaderTypes.heightMap];
      if (context.getters.isSlopeMapActive) shaders.push(shaderTypes.slopeMap);
      await context.dispatch('map/toggleShader', { uuid: context.state.selectedRefModel.uuid, shaders }, { root: true });
    },
    async toggleSlopeMapActive(context) {
      const shaders = [shaderTypes.slopeMap];
      if (context.getters.isHeightMapActive) shaders.push(shaderTypes.heightMap);
      await context.dispatch('map/toggleShader', { uuid: context.state.selectedRefModel.uuid, shaders }, { root: true });
    },
    // POINTS
    async changeShowPointsActive(context, { isActive }) {
      const { isShowPointsActive } = context.getters;
      if (isShowPointsActive === isActive) return;
      if (isActive) {
        await context.dispatch('loadPoints');
      } else {
        await context.dispatch('removePoints');
      }
    },

    /**
     * Calls map to show points from given setId and color them cutfill
     * @param {*} setId will show and color points included in the given set
     */
    async loadPoints(context) {
      const pointSet = context.getters.selectedPointSet;
      await context.dispatch('map/addEntities', {
        entities: [pointSet],
        options: { showOnMap: true, isPersistent: false }
      }, { root: true });
      await context.dispatch('map/colorPointSets', { sets: [pointSet.uuid], useColor: true }, { root: true });
      context.commit('setPointSet', pointSet.uuid);
    },
    /**
     * Calls map to hide points from given point set and color them neutral
     * @param {*} setId will hide and color points included in the given set
     */
    async removePoints(context) {
      const { pointSetUUID } = context.getters;
      const sets = pointSetUUID ? [pointSetUUID] : [];
      await context.dispatch('map/colorPointSets', { sets, useColor: false }, { root: true });
      await context.dispatch('map/removeEntityFamily', { uuids: sets }, { root: true });
      context.commit('setPointSet', null);
    },
    async reloadPoints(context) {
      const { pointSetUUID, selectedSet } = context.getters;
      if (pointSetUUID) await context.dispatch('removePoints');
      if (selectedSet) await context.dispatch('loadPoints');
    },
    async cancelRefmodelActions(context) {
      const { uuid } = context.getters.selectedRefModel;
      await context.dispatch('cancelable/cancelActions', { sourceUid: uuid }, { root: true });
    }
  }
};

export default detailsRefmodelOverlayModule;