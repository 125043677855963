<template>
  <div class="cx-legend">
    <div class="cx-legend--opener cx-legend--title" @click="open">
      <div v-translate>Legend</div>
      <cx-icon v-if="isOpen" :size="18" icon="expand_more"/>
      <cx-icon v-else :size="18" icon="expand_less"/>
    </div>
    <transition :name="applyAnimations('content')">
      <div v-if="isOpen" class="cx-legend__content">
        <cx-legend-item v-for="item in legendItems.generic" :key="item.icon + item.iconClass" v-bind="item"/>
        <div class="cx-legend__section--earthmoving" v-if="hasEarthmovingLicence">
          <div class="cx-legend--title" v-translate>Earthmoving</div>
          <cx-legend-item v-bind="item" v-for="item in legendItems.earthmoving" :key="item.icon + item.iconClass"/>
        </div>
        <div class="cx-legend__section--stabilization" v-if="hasStabilizationLicence">
          <div class="cx-legend--title" v-translate>Drilling & Stabilization</div>
          <cx-legend-item v-bind="item" v-for="item in legendItems.stabilization.piling" :key="item.icon + item.iconClass"/>
          <div class="cx-legend__separator"></div>
          <cx-legend-item v-bind="item" v-for="item in legendItems.stabilization.drilling" :key="item.icon + item.iconClass"/>
        </div>
        <div class="cx-legend__section--stabilization" v-if="hasAwarenessLicence">
          <div class="cx-legend--title" v-translate>Awareness</div>
          <cx-legend-item v-bind="item" v-for="item in legendItems.awareness" :key="item.icon + item.iconClass"/>
        </div>
        <div class="cx-legend__section--units">
          <div class="cx-legend--title" v-translate>Units</div>
          <cx-legend-item v-bind="item" v-for="item in legendItems.units" :key="item.icon + item.iconClass"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin.js';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxIcon from '@/components/CxIcon';
import CxLegendItem from '@/app/legend/CxLegendItem';

export default {
  name: 'CxLegend',
  components: {
    CxLegendItem,
    CxIcon
  },
  mixins: [CxLicenceMixin, CxAnimationMixin, CxWaffleFlagMixin],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    legendItems() {
      return {
        generic: [
          { description: this.$gettext('Measured Point'), icon: 'lens', iconClass: 'neutral' },
          { description: this.$gettext('Deleted Point'), icon: 'lens', iconClass: 'discarded-point' }
        ],
        units: [
          { description: this.$gettext('Online'), icon: 'cx_unit', iconClass: 'active-unit' },
          { description: this.$gettext('Offline'), icon: 'cx_unit', iconClass: 'inactive-unit' },
        ],
        earthmoving: [
          { description: this.$gettext('Cut Point'), icon: 'lens', iconClass: 'cut' },
          { description: this.$gettext('Fill Point'), icon: 'lens', iconClass: 'fill' },
          { description: this.$gettext('On Target Point'), icon: 'lens', iconClass: 'on-target' },
        ],
        stabilization: {
          piling: [
            { description: this.$gettext('Completed Pile'), icon: 'radio_button_checked', iconClass: 'complete' },
            { description: this.$gettext('Pile in Progress'), icon: 'radio_button_checked', iconClass: 'paused' },
            { description: this.$gettext('Failed Pile'), icon: 'radio_button_checked', iconClass: 'failed' },
          ],
          drilling: [
            { description: this.$gettext('Completed Hole'), icon: 'adjust', iconClass: 'complete' },
            { description: this.$gettext('Hole in Progress'), icon: 'adjust', iconClass: 'paused' },
            { description: this.$gettext('Failed Hole'), icon: 'adjust', iconClass: 'failed' },
          ],
        },
        awareness: [
          { description: this.$gettext('Close Zone'), icon: 'lens', iconClass: 'awareness-red' },
          { description: this.$gettext('Near Zone'), icon: 'lens', iconClass: 'awareness-yellow' },
          { description: this.$gettext('Far Zone'), icon: 'lens', iconClass: 'awareness-green' },
          { description: this.$gettext('Cabin Zone'), icon: 'lens', iconClass: 'awareness-cabin' },
          { description: this.$gettext('Not in Danger Zone'), icon: 'lens', iconClass: 'awareness-nid' },
          { description: this.$gettext('Undefined Zone'), icon: 'lens', iconClass: 'awareness-undefined ' }
        ]
      };
    }
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style scoped lang="less">
@import '../../common';

.cx-legend {
  .flex(column, center, flex-start);
  padding: 0 15px;
  color: @textColorBase;
  background-color: @overlayControlBackgroundColor;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;
  width: 100%;
}

.cx-legend--opener {
  &:hover {
    cursor: pointer;
    color: @textColorHighlight;
  }
}

.cx-legend--title {
  .size(100%, @mapLegendTitleHeight);
  .flex(row, space-between, center);
  color: @textColorLowlight;
}

.cx-legend__content {
  width: 100%;
  padding-bottom: 8px;
}

.cx-legend__separator {
  height: @mapLegendSepatatorHeight;
}

</style>