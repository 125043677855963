<template>
  <div class="chart__barChart">
  </div>
</template>

<script>
import _ from 'lodash';
import * as d3 from 'd3';
import D3BarChart from './D3BarChart';

export default {
  name: 'CxBarChart',
  props: {
    plotData: Array,
    intervals: Array,
    onVolumeSelected: Function,
    isRange: Boolean,
    domain: Array,
    rerender: Object
  },
  data() {
    return {
      svgChart: null,
      size: { width: 530, height: 230 },
      margin: {
        top: 10, right: 70, bottom: 55, left: 45
      },
    };
  },
  mounted() {
    if (!this.plotData || this.plotData.length < 1) return;
    this.getChart();
    this.updateData();
  },
  computed: {
    startDate() {
      if (this.intervals.length < 1) return Date.now();
      return this.intervals[0];
    },
    endDate() {
      if (this.intervals.length < 2) return Date.now();
      return this.intervals[this.intervals.length - 1];
    },
    stackedData() {
      const stackedFunction = d3.stack()
        .keys(['fill', 'cut']);
      const stackedData = stackedFunction(this.plotData);
      return stackedData;
    },
    dataRange() {
      const volumes = this.plotData.map(s => s.cut + s.fill);
      const minVolume = _.min(volumes) || 0;
      const maxVolume = _.max(volumes) || 0;
      return { minVolume, maxVolume };
    },
  },
  methods: {
    onDataSelected(index, data) {
      this.onVolumeSelected(data);
    },
    getChart() {
      if (!this.svgChart) {
        this.svgChart = new D3BarChart(this.margin, {}, this.onDataSelected, this.isRange);
      }
      if (!this.svgChart.initialized) {
        this.size.width = this.$el.clientWidth;
        this.svgChart.initialize(this.$el, this.size);
      }
      return this.svgChart;
    },
    updateData() {
      if (this.svgChart && this.svgChart.initialized) {
        this.svgChart.updateData(this.stackedData, { y: this.dataRange, x: this.domain });
        this.svgChart.plot();
      }
    }
  },
  watch: {
    rerender: {
      handler() {
        // todo: set selected range and item in the update data method
        if (!this.svgChart || !this.svgChart.initialized) {
          this.getChart();
          this.updateData();
        }
      }
    }
  }
};
</script>

<style lang="less">
@import '../../../../../common';

.event-area-item {
  &.hover {
    cursor: pointer;
  }
  &.hoverDrag {
    cursor: w-resize;
  }
}

.chart__barChart--series__cut {
  .hover, .selected  {
    fill: @cutSelectColor;
  }
}

.chart__barChart--series__fill {
  .hover, .selected {
    fill: @fillHighlightColor;
  }

}

.chart__barChart--bar {
  rx: 4;
  ry: 4;
}


.selection-layer, .range-selection-layer, .hover-layer {
  .select-handle_line, .hover-handle_line {
    stroke: rgba(255,255,255, 0.6);
    stroke-width: 1;
    z-index: -1;
  }
  .select-handle_caption1, .hover-handle_caption1 {
    fill: @textColorHighlight;
    rx: 8;
    ry: 8;
  }
  .select-handle_caption1_text, .select-handle_caption2_text, .hover-handle_caption1_text, .hover-handle_caption2_text {
    text-anchor: middle;
    color: @inputBackground;
    font-size: @microFontSize;
    font-weight: 500;
  }
  .select-handle_caption2, .hover-handle_caption2 {
      fill: rgba(255,255,255, 0.6);
      rx: 8;
      ry: 8;
  }

  .rangeselection--layer__handle {
    &.dragging {
      line {
        stroke: rgba(255,255,255, 0.6);
        stroke-width: 2;
        z-index: -1;
      }
      .rangeselection--layer__bubble {
        fill: rgba(255,255,255, 1);
      }
    }
    line {
      stroke: rgba(255,255,255, 0.4);
      stroke-width: 1;
      z-index: -1;
    }
    .rangeselection--layer__bubble {
      fill: @textColorHighlight;
      rx: 8;
      ry: 8;
    }
  }
}

.range-area {
  fill: rgba(255,255,255, 0.05);
}
.range-area-handle {
  opacity: 0.4;
}
.range-area-handle:hover {
  cursor: move;
  opacity: 0.8;
}

.xAxis {
  line {
    stroke: transparent;
  }
  text {
    fill: rgba(255,255,255, 0.3);
  }
  path {
    stroke: transparent;
  }
}

.yAxis {
    path {
    stroke: transparent;
  }

  .tick {
    line {
      stroke: rgba(255,255,255, 0.1);
    }
    text {
      fill: rgba(255,255,255, 0.3);
    }
  }
}

</style>