import * as Cesium from 'cesium';
import { formatISO } from 'date-fns';

const eventsStore = {
  state: {
    eventDataSourceMap: {},
    events: {},
    eventsHeatmap: {},
    removeEventHandler: {},
    boundingSphere: {},
  },
  methods: {
    addEvent(state, event) {
      state.events[event.properties.uuid.getValue()] = event;
    },
    isHeatmapVisible(state) {
      return (Object.keys(state.eventsHeatmap).length === 0);
    },
    async setHeatmapLayer(setUuid, viewer, state, geoserverUrl, cql) {
      const baseWidth = viewer.canvas.width;
      const baseHeight = viewer.canvas.height;
      const pitch = viewer.camera.pitch * (180 / Math.PI);
      const wmsWidth = Math.round(-90 * baseWidth / pitch).toString();
      const wmsHeight = Math.round(-90 * baseHeight / pitch).toString();
      const computedRectangle = viewer.camera.computeViewRectangle(viewer.scene.globe.ellipsoid, new Cesium.Rectangle());
      const wmsBBOX = `${Cesium.Math.toDegrees(computedRectangle.west)},${Cesium.Math.toDegrees(computedRectangle.south)},${Cesium.Math.toDegrees(computedRectangle.east)},${Cesium.Math.toDegrees(computedRectangle.north)}`;
      const singleTileRectangle = Cesium.Rectangle.fromDegrees(Cesium.Math.toDegrees(computedRectangle.west), Cesium.Math.toDegrees(computedRectangle.south), Cesium.Math.toDegrees(computedRectangle.east), Cesium.Math.toDegrees(computedRectangle.north));
      const geoserverProxyUrl = new URL('wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=awarenessWorkspace:awareness_event&exceptions=application/vnd.ogc.se_inimage&SRS=EPSG:4326', geoserverUrl);
      geoserverProxyUrl.searchParams.append('CQL_FILTER', cql);
      geoserverProxyUrl.searchParams.append('STYLES', 'heatmap');
      geoserverProxyUrl.searchParams.append('WIDTH', wmsWidth);
      geoserverProxyUrl.searchParams.append('HEIGHT', wmsHeight);
      geoserverProxyUrl.searchParams.append('BBOX', wmsBBOX);
      viewer.imageryLayers.remove(state.eventsHeatmap[setUuid]);
      state.eventsHeatmap[setUuid] = await new Cesium.ImageryLayer(new Cesium.SingleTileImageryProvider({
        url: geoserverProxyUrl.toString(),
        rectangle: singleTileRectangle,
      }));
      viewer.imageryLayers.add(state.eventsHeatmap[setUuid]);
      viewer.scene.screenSpaceCameraController.enableTilt = (Object.keys(state.eventsHeatmap).length === 0);
    },
    removeHeatmapLayer(setUuid, viewer, state) {
      if (state.eventsHeatmap[setUuid]) {
        viewer.imageryLayers.remove(state.eventsHeatmap[setUuid], true);
        delete state.eventsHeatmap[setUuid];
        state.removeEventHandler[setUuid]();
        viewer.scene.screenSpaceCameraController.enableTilt = this.isHeatmapVisible(state);
      }
    }
  },
  actions: {
    async loadEvents(context, { setUuid, geojson, showOnMap }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const getIconPNG = (color) => {
        const detectedZoneIcon = {
          GreenZone: 'icons/awarenessEvents/GreenZone-dot.png',
          YellowZone: 'icons/awarenessEvents/YellowZone-dot.png',
          RedZone: 'icons/awarenessEvents/RedZone-dot.png',
          CabinZone: 'icons/awarenessEvents/CabinZone-dot.png',
          NotInDangerZone: 'icons/awarenessEvents/NotinDangerZone-dot.png',
          Undefined: 'icons/awarenessEvents/UndefinedZone-dot.png'
        };
        return detectedZoneIcon[color];
      };
      const getTerrainPositions = async (eventDataSource) => {
        const dataSourcePositions = eventDataSource.map(e => e.position);
        const cartesianPositions = dataSourcePositions.map(h => Cesium.Cartographic.fromCartesian(h.getValue()));
        await Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, cartesianPositions);
        return cartesianPositions;
      };

      viewer.scene.requestRenderMode = false;
      const eventDataSource = await viewer.dataSources.add(Cesium.GeoJsonDataSource.load(geojson));
      // we do not want to show awareness events until we calculate and set clamped position
      eventDataSource.show = false;
      const terrainPositions = await getTerrainPositions(eventDataSource.entities.values);
      eventDataSource.entities.values.forEach((entity, index) => {
        const terrainPos = terrainPositions[index];
        entity.properties.addProperty('cxClampedPosition', Cesium.Cartographic.toCartesian(terrainPos));
        entity.properties.addProperty('cxType', 'awarenessEvent');
        entity.position.setValue(Cesium.Cartographic.toCartesian(terrainPos));
        entity.billboard = {
          image: getIconPNG(entity.properties.detected_zone),
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          width: 15,
          height: 15
        };
        self.addEvent(state, entity);
      });
      eventDataSource.show = showOnMap;
      state.eventDataSourceMap[setUuid] = eventDataSource;
      viewer.scene.requestRenderMode = true;
      state.boundingSphere[setUuid] = Cesium.BoundingSphere.fromPoints(state.eventDataSourceMap[setUuid]._entityCollection._entities._array.map((e) => e._position._value));
    },
    toggleEventSetVisibility(context, { setUuid, showOnMap }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      const eventSet = state.eventDataSourceMap[setUuid];
      eventSet.show = showOnMap;
      scene.requestRender();
    },
    async removeEventSet(context, { setUuid }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      const eventSet = state.eventDataSourceMap[setUuid];
      viewer.dataSources.remove(eventSet, true);
      self.removeHeatmapLayer(setUuid, viewer, state);
      viewer.scene.screenSpaceCameraController.enableTilt = self.isHeatmapVisible(state);
      scene.requestRender();
    },
    async toggleAwarenessEventSetClustering(context, { setUuid, shouldCluster }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      const eventSet = state.eventDataSourceMap[setUuid];
      const clusterCircleIcon = (clusterLength) => {
        const iconPerClusterSize = {
          1: 'icons/awarenessEvents/clusters/100x100-1.png',
          2: 'icons/awarenessEvents/clusters/100x100-1.png',
          3: 'icons/awarenessEvents/clusters/100x100.png',
          4: 'icons/awarenessEvents/clusters/130x130.png',
          5: 'icons/awarenessEvents/clusters/150x150.png',
          6: 'icons/awarenessEvents/clusters/150x150.png',
        };
        return iconPerClusterSize[clusterLength];
      };
      eventSet.clustering.enabled = shouldCluster;
      eventSet.clustering.pixelRange = 100;
      eventSet.clustering.minimumClusterSize = 2;
      eventSet.clustering.clusterEvent.addEventListener((clusteredEntities, cluster) => {
        cluster.label.font = '20px Roboto';
        cluster.label.fillColor = Cesium.Color.fromCssColorString('#ffffff');
        cluster.label.outlineColor = Cesium.Color.fromCssColorString('#3F3F43');
        cluster.label.outlineWidth = 3;
        cluster.label.text = clusteredEntities.length.toLocaleString();
        cluster.label.style = Cesium.LabelStyle.FILL_AND_OUTLINE;
        cluster.label.verticalOrigin = Cesium.VerticalOrigin.CENTER;
        cluster.label.horizontalOrigin = Cesium.HorizontalOrigin.CENTER;
        cluster.label.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND;
        cluster.billboard.show = true;
        cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
        cluster.billboard.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND;
        cluster.billboard.image = clusterCircleIcon(clusteredEntities.length.toString().length);
        cluster.billboard.disableDepthTestDistance = 1;
      });
      scene.requestRender();
    },
    async toggleAwarenessEventSetHeatmap(context, { setUuid, show, filters, geoserverUrl }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;

      const cqlQuery = (awarenessFilters) => {
        const fieldNameDb = {
          AlarmType: 'name',
          DetectedZone: 'detected_zone',
          Value: 'value',
          UnitUuid: 'unit_uuid',
          UnitType: 'unit_type'
        };
        const startTime = formatISO(awarenessFilters.originTimeRange.from);
        const stopTime = formatISO(awarenessFilters.originTimeRange.to);
        const timeRangeCql = `timestamp AFTER ${startTime} AND timestamp BEFORE ${stopTime}`;
        const filtersToCql = awarenessFilters.originFilters.map(f => `${fieldNameDb[f.fieldName]} in ( ${f.values.map(v => `'${v}'`)} )`);
        const cql = [...filtersToCql, timeRangeCql].join(' and ');
        return cql;
      };
      if (show) {
        viewer.camera.flyToBoundingSphere(state.boundingSphere[setUuid], {
          complete: () => {
            viewer.camera.percentageChanged = 0.001;
            const query = cqlQuery(filters);
            state.removeEventHandler[setUuid] = viewer.camera.moveEnd.addEventListener(() => self.setHeatmapLayer(setUuid, viewer, state, geoserverUrl, query));
          },
          offset: Cesium.HeadingPitchRoll.fromDegrees(0, -90)
        });
      } else {
        self.removeHeatmapLayer(setUuid, viewer, state);
      }
    }
  },
};
export default eventsStore;