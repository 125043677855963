<template>
  <div
    class="cx-display-number" :class="className" @mouseover="showTooltip=true" @mouseleave="showTooltip=false"
    v-cx-tooltip="{
      text: value,
      show: showTooltip,
    }">
    <span class="cx-display-number-value">{{ value }}</span>
    <cx-measure-unit :pow="pow" v-if="useUnitComputed"/>
  </div>
</template>

<script>
import _ from 'lodash';
import CxMeasureUnit from './CxMeasureUnit';

export default {
  name: 'CxDisplayNumber',
  components: {
    CxMeasureUnit
  },
  props: {
    value: [Number, String],
    unit: String, // if passed the unit will be used instead of the default one
    useUnit: {
      type: Boolean, // if true, the unit will be added
      default: true
    },
    pow: {
      type: [Number, String],
      default: 3
    },
    className: [String, Object],
    useTooltip: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      showTooltip: false
    };
  },
  computed: {
    useUnitComputed() {
      return this.useUnit && this.value && _.isNumber(this.value);
    }
  }
};
</script>

<style scoped lang="less">
@import '../../../../common';

.cx-display-number {
  display: inline;
  color: @textColor;
  font-size: @h3FontSize;
}


</style>