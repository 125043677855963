<template>
  <div class="cx-analysis--point-set__content">
    <div class="cx-analysis--point-set__content__input" v-if="hasEarthmovingLicence">
      <div class="cx-analysis--point-set__content__input__dropdowns">
        <cx-selector
          :label="translations.refmodelLabel"
          :placeholder="translations.placeholder"
          :collection="allModels"
          nameField="name"
          :dropdownHeight="211"
          :onSelection="handleSelectRefmodel"
          :value="selectedRefModel"/>
        <cx-selector
          isDisabled
          :label="translations.initialSurfaceLabel"
          :placeholder="translations.placeholder"
          :collection="allModels"
          nameField="name"
          :dropdownHeight="143"
          :onSelection="handleSelectInitialModel"/>
      </div>
      <div class="cx-analysis--point-set__content__input__buttons">
        <cx-button
          variantDark
          icon="map"
          :loading="isSurfaceLoading"
          @click="handleGenerateSurface">
          {{ translations.generateSurfaceCaption }}
        </cx-button>
        <cx-button
          variantDark
          icon="gradient"
          :loading="isDiffModelLoading"
          @click="handleShowDiffModel"
          :disabled="!selectedRefModel">
          {{ translations.showDiffmodelCaption }}
        </cx-button>
      </div>
    </div>
    <div class="cx-analysis--point-set__content__charts">
      <cx-button
        class="cx-analysis--point-set-show-points-button"
        variantDark
        @click="showPointGrid"
        :loading="isFetchingPoints"
        v-if="isGridPoc">
        {{ translations.showPointsDataCaption }}
      </cx-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxSelector from '@/components/menuComponents/CxSelector';
import CxButton from '@/components/CxButton';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';

export default {
  name: 'CxPointSetAnalysisCutFill',
  mixins: [CxLicenceMixin],
  components: {
    CxSelector,
    CxButton
  },

  computed: {

    ...mapGetters('map', ['selectedEntity']),
    ...mapGetters('app', ['allModels']),
    ...mapGetters('detailsPointSet', ['selectedPointSet', 'selectedRefModel', 'isShowSurface', 'isShowDiffModel', 'isSurfaceLoading', 'isDiffModelLoading', 'isFetchingPoints']),

    translations() {
      return {
        refmodelLabel: this.$gettext('Reference Model'),
        initialSurfaceLabel: this.$gettext('Initial Surface'),
        placeholder: this.$gettext('None'),
        generateSurfaceCaption: this.isShowSurface ? this.$gettext('Remove Generated Surface') : this.$gettext('Generate Surface'),
        showDiffmodelCaption: this.isShowDiffModel ? this.$gettext('Hide Difference Model') : this.$gettext('Show Difference Model'),
        showPointsDataCaption: this.$gettext('Show Points Data')
      };
    },
    metadata() {
      if (!this.selectedEntity) return {};
      const selectedSet = this.selectedEntity;
      const translated = this.$ngettext('%{n} point', '%{n} points', selectedSet.count);
      return {
        description: this.$gettextInterpolate(translated, { n: selectedSet.count })
        // `${selectedSet.count} points`
      };
    },
  },

  methods: {
    ...mapActions('detailsPointSet', ['handleRefmodelSelection', 'toggleDisplaySurface', 'toggleDisplayDiffModel', 'showPointGrid']),

    handleSelectRefmodel(model) {
      this.handleRefmodelSelection(model);
    },

    handleSelectInitialModel(/* model */) {
      // TODO: Bind action
    },

    handleGenerateSurface() {
      this.toggleDisplaySurface({ show: !this.isShowSurface });
    },

    handleShowDiffModel() {
      if (!this.selectedRefModel) return;
      this.toggleDisplayDiffModel({
        show: !this.isShowDiffModel,
        refmodelUuid: this.selectedRefModel.uuid
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../common';

.cx-analysis--point-set__content {
  .size(100%, calc(100% - @detailsAnalysisTabsHeight));
  .flex(row, flex-start, flex-start);
  background-color: @detailsContentBackgroundColor;

  .cx-analysis--point-set__content__input {
    .size(@detailsPointSetAnalysisInputWidth, auto);
    .flex(column, flex-start, flex-start);

    .cx-analysis--point-set__content__input__dropdowns {
      .flex(column, space-between, flex-start);
      .size(@detailsPointSetAnalysisInputWidth, @detailsPointSetAnalysisDropdownsHeight);
    }

    .cx-analysis--point-set__content__input__dropdowns /deep/ .cx-popper-content {
      overflow-y: auto;
    }

    ::-webkit-scrollbar-thumb {
      height: 40px;
    }

    .cx-analysis--point-set__content__input__buttons {
      margin-top: 8px;
      gap: 2px;
      .flex(column, flex-end, flex-start);
      .size(@detailsPointSetAnalysisInputWidth, @detailsPointSetAnalysisButtonsHeight);
      border-radius: @inputBorderRadius;

      .cx-button:not(:first-child)  {
        margin-top: 10px;
      }
    }
  }

  .cx-analysis--point-set__content__charts {
    .size(90%, 100%);
    margin: 0 50px 0 30px;
    display: flex;
    align-items: flex-end;

    // TODO: Remove once every element ready
    &__placeholder {
      .size(95%, @detailsPointSetAnalysisPlaceholderHeight);
      background: @detailsPlaceholderBgColor;
      border-radius: @inputBorderRadius;
    }
    .cx-analysis--point-set-show-points-button {
      margin-bottom: 85px;
    }
    .cx-button {
      width: 200px;
    }
  }

  .cx-export-asbuild-component {
    margin-top: -80px;
  }

  .cx-button {
    width: 200px;
  }
}
</style>