<template>
  <div class="cx-cumulative-chart">
    <cx-bar-chart
      id="chart__barChart"
      :plotData="volumeSeries"
      :onVolumeSelected="handleSelectBar"
      :isRange="false"
      :intervals="intervals"
      :domain="domain"/>
  </div>
</template>

<script>
import CxBarChart from './components/barChart/CxBarChart.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';

export default {
  name: 'CxCumulativeChart',
  components: {
    CxBarChart,
  },
  computed: {
    ...mapGetters('detailsRefmodel', [
      'intervals',
      'volumeSeries',
      'selectedVolume',
      'domain',
    ]),
  },
  methods: {
    ...mapMutations('detailsRefmodel', ['setTimeInverval']),
    ...mapActions('detailsRefmodel', ['getVolumes', 'selectBar']),
    handleSelectBar(bar) {
      this.throttled(bar);
    },
  },
  created() {
    this.throttled = _.throttle(this.selectBar, 1500);
  }
};
</script>

<style lang="less">

.cx-cumulative-chart {
  flex: 1 0 auto;
}

</style>