import { UnitMessage } from '@/domain/awareness/enums/UnitMessage.type';

export type SendMessageResponseItem = {
  unit_id: string,
  message_uuid: string
}
export type SendMessageResponse = {
  messages: SendMessageResponseItem[]
}

const mapMessage: (message: SendMessageResponseItem) => UnitMessage
= (message) => {
  const unitId = message.unit_id;
  const messageId = message.message_uuid;
  return { unitId, messageId };
};

const mapMessages: (data: SendMessageResponse) => UnitMessage[] =
  data => data.messages.map(mapMessage);


const awarenessMessageMapper = { mapMessages };
export default awarenessMessageMapper;
