<template>
  <div class="cx-no-data">
    <div class="cx-no-data__image"></div>
    <span class="cx-no-data__text" v-translate>There is no measured points assigned</span>
    <span class="cx-no-data__text" v-translate>for this Reference Model</span>
  </div>
</template>

<script>

export default {
  name: 'CxNoDta',
};

</script>

<style lang="less">
@import '../../../common';

.cx-no-data {
  height: 100%;
  .flex(column, center, center);
  border: 1px solid @detailsNoDataBorderColor;
  border-radius: @inputBorderRadius;

  .cx-no-data__image {
    background-image: url('../../../../public/no-data.svg');
    background-size: contain;
    opacity: 0.2;
    margin-bottom: 30px;
    .size(@detailsNoDataImgSize, @detailsNoDataImgSize);
  }

  .cx-no-data__text {
    opacity: 0.3;
  }
}

</style>