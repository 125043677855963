import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const measurementSystemNames = {
  metric: 'Metric',
  usCustomaryUnits: 'UsCustomaryUnits',
  imperial: 'Imperial'
};

const measurementUnits = {
  m: {
    name: 'm',
    abbr: () => $gettext('m'),
    system: measurementSystemNames.metric,
    toBase: 1,
    measure: 'length'
  },
  km: {
    name: 'km',
    abbr: () => $gettext('km'),
    system: measurementSystemNames.metric,
    toBase: 1000,
    measure: 'length'
  },
  'ft-us': {
    name: 'ft-us',
    abbr: () => $gettext('ft'),
    system: measurementSystemNames.usCustomaryUnits,
    toBase: 3.2808333333465,
    measure: 'length'
  },
  yd: {
    name: 'yd',
    abbr: () => $gettext('yd'),
    system: measurementSystemNames.usCustomaryUnits,
    toBase: 1.0936133,
    measure: 'length'
  },
  // SPEED
  kmph: {
    name: 'kmph',
    abbr: () => $gettext('kmph'),
    system: measurementSystemNames.metric,
    toBase: 1,
    measure: 'speed'
  },
  mph: {
    name: 'mph',
    abbr: () => $gettext('mph'),
    system: measurementSystemNames.imperial,
    toBase: 0.621371192,
    measure: 'speed'
  },
  // AREA
  m2: {
    name: 'm2',
    abbr: () => $gettext('m²'),
    system: measurementSystemNames.metric,
    toBase: 1,
    measure: 'area'
  },
  ft2: {
    name: 'ft2',
    abbr: () => $gettext('ft²'),
    system: measurementSystemNames.imperial,
    toBase: 10.7639104,
    measure: 'area'
  },
  yd2: {
    name: 'yd2',
    abbr: () => $gettext('yd²'),
    system: measurementSystemNames.usCustomaryUnits,
    toBase: 1.19599005,
    measure: 'area'
  },
  // VOLUME
  m3: {
    name: 'm3',
    abbr: () => $gettext('m³'),
    system: measurementSystemNames.metric,
    toBase: 1,
    measure: 'volume'
  },
  ft3: {
    name: 'ft3',
    abbr: () => $gettext('ft³'),
    toBase: 35.314666721,
    measure: 'volume'
  },
  yd3: {
    name: 'yd3',
    abbr: () => $gettext('yd³'),
    system: measurementSystemNames.usCustomaryUnits,
    toBase: 1.30795062,
    measure: 'volume'
  },
  // MISC
  deg: {
    name: 'deg',
    abbr: () => '°',
    system: null,
    toBase: 1,
    measure: null
  }
};

const baseUnits = [measurementUnits.m, measurementUnits.kmph, measurementUnits.m2, measurementUnits.m3, measurementUnits.deg, measurementUnits.ft2];

const metricMeasureSystem = {
  name: measurementSystemNames.metric,
  baseLengthUnit: measurementUnits.m,
  baseSpeedUnit: measurementUnits.kmph,
  baseAreaUnit: measurementUnits.m2,
  baseVolumeUnit: measurementUnits.m3
};
const usCustomaryUnitsSystem = {
  name: measurementSystemNames.usCustomaryUnits,
  baseLengthUnit: measurementUnits['ft-us'],
  baseSpeedUnit: measurementUnits.mph,
  baseAreaUnit: measurementUnits.yd2,
  baseVolumeUnit: measurementUnits.yd3
};
const baseMeasurementSystem = metricMeasureSystem;

export {
  measurementUnits,
  measurementSystemNames,
  metricMeasureSystem,
  usCustomaryUnitsSystem,
  baseMeasurementSystem,
  baseUnits
};