<template>
  <div class="licence-content cx-stabilization-licence">
    <div class="content--border">
      <!-- LAYOUT WHEN NO LICENCE -->
      <div class="cx-licence-popup__licence-text" v-if="!hasLicence">
        <div class="cx-licence-popup__licence-text__title" v-translate>ConX – Drilling and Stabilization Productivity Includes</div>
        <div class="cx-licence-popup__licence-text__elements">
          <cx-licence-bullet-item :text="translations.visualization"/>
          <cx-licence-bullet-item :text="translations.status"/>
          <cx-licence-bullet-item :text="translations.detailed"/>
          <cx-licence-bullet-item :text="translations.download"/>
        </div>
        <div class="cx-licence-popup__licence-text__note" v-translate>Please contact your local Leica dealer to purchase new license and activate Drilling and Stabilization Productivity features.</div>
      </div>
      <!-- LAYOUT WITH LICENCE -->
      <div class="cx-licence-popup__licence-text" v-if="hasLicence">
        <div class="cx-licence-popup__licence-text__title" v-translate>ConX Stabilization Productivity licence</div>
        <div class="cx-licence-popup__licence-text__note" v-translate>Please contact your local Leica dealer to extend Drilling and Stabilization Productivity Licence</div>
      </div>
    </div>
    <div class="stabilization-licence-img" :class="{'with-licence': hasLicence}"></div>
    <div class="licence-tick" v-if="hasLicence">
      <cx-icon icon="done" class="cxu-color-base" :size="20"/>
    </div>
  </div>
</template>

<script>
import CxLicenceBulletItem from './CxLicenceBulletItem';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxStabilizationLicence',
  components: {
    CxLicenceBulletItem,
    CxIcon
  },
  props: {
    hasLicence: { type: Boolean, default: false }
  },
  computed: {
    translations() {
      return {
        visualization: this.$gettext('Visualization of drilled holes and piles on the map'),
        status: this.$gettext('Status based coloring for monitoring progress of drill patterns'),
        detailed: this.$gettext('Detailed attributes of drilling and piling results'),
        download: this.$gettext('Download results to common file formats')
      };
    }
  },
};
</script>

<style lang="less">
@import '../../../common';

.cx-stabilization-licence {
  position: relative;

  .stabilization-licence-img {
    position: absolute;
    .size(@cxLicenceFalseImgSize, @cxLicenceFalseImgSize);
    bottom: 20px;
    background-image: url('../../../../public/licencing/stabilization-box.png');
    background-size: contain;
    &.with-licence {
        bottom: 10px;
        .size(@cxLicenceTrueImgSize, @cxLicenceTrueImgSize);
        left: 10px;
    }
  }

  .licence-tick {
      right: -15px;
      bottom: 35px;
      .size(@cxLicenceTickSize, @cxLicenceTickSize);
  }
}

</style>
