/** Awareness Message Status.
 * Only 3 statuses, i.e.: Sent, Created and Acknowledged values reflect database nomenclature.
 */
export enum AwarenessMessageStatus {
  Unsent = 'Unsent',
  Sending = 'Sending',
  Sent = 'Sent',
  Received = 'Created',
  Acknowledged = 'Acknowledged',
  SendingError = 'SendingError',
  ReceptionError = 'ReceptionError',
  AcknowledgementError = 'AcknowledgementError',
  PollingError = 'PollingError'
}