<template>
  <div class="cx-generic-popup-glass" @click.stop="{}" v-if="isGridVisible">
    <div class="cx-generic-popup" @click.stop="{}">
      <div class="cx-generic-popup__content">
        <div class="cx-generic-popup__content__subtitle">{{ gridSubtitle }}</div>
        <div class="cx-generic-popup__content__body">
          <cx-point-grid :data="gridRows"/>
        </div>
      </div>
      <div class="cx-generic-popup__actions">
        <i class="material-icons close-icon" @click.stop="close">close</i>
      </div>
    </div>
  </div>
</template>

<script>
import CxPointGrid from '@/app/detailsPanel/common/CxPointGrid/CxPointGrid';
import { mapGetters, mapActions } from 'vuex';
/**
 * A popup component with slots to display contents
 */
export default {
  name: 'CxGridPopup',
  components: {
    CxPointGrid,
  },
  methods: {
    ...mapActions('pointGrid', ['hidePointGrid']),
    close() {
      this.hidePointGrid();
    },
  },
  computed: {
    ...mapGetters('pointGrid', ['isGridVisible', 'gridRows', 'gridSubtitle'])
  },
};
</script>

<style lang="less" scoped>
@import '../../../common';

.cx-generic-popup-glass {
  .size(100vw, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  z-index: @cxPopupZI;
}

.cx-generic-popup {
  position: absolute;
  margin: auto;
  left: calc(50vw - 0.5*80vw);
  top: 20px;
  width: 80vw;
  //max-width: @cxPopupMaxSize;
  //max-height: @cxPopupMaxSize;
  height: 90vh;
  box-shadow: 0 25px 100px @popupBoxShadowColor;
  background: @baseBackgroundColor;
  border-radius: 10px;
  padding: 20px 14px 30px 30px;
  display: flex;
  flex-direction: row;

  .cx-generic-popup__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .cx-generic-popup__actions {
    position: relative;
    height: @cxPopupActionsHeight;
  }
  .close-icon {
    font-size: 15px;
    color: @textColor;
    .size(15px, 15px);
  }
  .close-icon:hover {
    color: @textColorHighlight !important;
    cursor: pointer;
  }

  .cx-generic-popup__content__title {
    color: @textColorHighlight;
    font-size: 22px;
    font-weight: @appFontWeight;
    flex: 0 0 auto;
  }
  .cx-generic-popup__content__subtitle {
    font-size: @appFontSize;
    color: @textColorLowlight;
    padding-top: 2px;
    padding-left: 12px;
    flex: 0 0 auto;
    height: 18px;
  }
  .cx-generic-popup__content__body {
    padding-top: 10px;
    font-size: @appFontSize;
    color: @textColor;
    flex: 1 0 auto;
  }
}
</style>