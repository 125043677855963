<template>
  <div id="menu" :class="filterBarCss">
    <div class="menu-item">
      <div class="navigation-group">
        <cx-filter-item
          id="cx-filter-nav--unit"
          :filterItem="unitsPanel"
          :onNavigated="changeFilterContent"
          :isActive="isFilterPanelOpened && isFilterActive(unitsPanel)"
          icon="cx_unit"
          :disabled="!projectInitialized"
          :tooltip="{
            text: translations.units,
            position: 'top-right'
          }"/>
        <cx-filter-item
          id="cx-filter-nav--ref-model"
          :filterItem="refModelPanel"
          :onNavigated="changeFilterContent"
          :isActive="isFilterPanelOpened && isFilterActive(refModelPanel)"
          icon="cx_ref_model"
          :disabled="!projectInitialized"
          :tooltip="{
            text: translations.refModels,
            position: 'top-right'
          }"/>
        <cx-filter-item
          id="cx-filter-nav--measured-point"
          :filterItem="measurePointPanel"
          :onNavigated="changeFilterContent"
          :isActive="isFilterPanelOpened && isFilterActive(measurePointPanel)"
          icon="cx_points"
          :disabled="!projectInitialized"
          :tooltip="{
            text: translations.measuredPoints,
            position: 'top-right'
          }"/>
        <cx-filter-item
          v-if="hasAwarenessLicence"
          id="cx-filter-nav--awareness-event"
          :filterItem="awarenessEventPanel"
          :onNavigated="changeFilterContent"
          :isActive="isFilterPanelOpened && isFilterActive(awarenessEventPanel)"
          icon="warning"
          :disabled="!projectInitialized"
          :tooltip="{
            text: translations.awarenessEvents,
            position: 'top-right'
          }"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filterPanelTypes } from '@/store/shellModule/shell.store';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxFilterItem from './CxFilterItem';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';

export default {
  name: 'CxFilterBar',
  mixins: [CxLicenceMixin, CxWaffleFlagMixin],
  components: {
    CxFilterItem
  },
  data() {
    return {
      analysisPanel: filterPanelTypes.analysis,
      unitsPanel: filterPanelTypes.unit,
      measurePointPanel: filterPanelTypes.measurePoint,
      refModelPanel: filterPanelTypes.refModel,
      awarenessEventPanel: filterPanelTypes.awarenessEvent
    };
  },
  computed: {
    ...mapGetters('shell', ['filterPanelContent', 'isFilterPanelOpened']),
    ...mapGetters('app', ['projectInitialized']),
    filterBarCss() {
      if (this.isFilterPanelOpened && this.filterPanelContent) return 'opened';
      return 'closed';
    },
    translations() {
      return {
        cards: this.$gettext('Analysis'),
        units: this.$gettext('Units'),
        refModels: this.$gettext('Reference Models'),
        measuredPoints: this.$gettext('As Builts'),
        awarenessEvents: this.$gettext('Awareness Events')
      };
    }
  },
  methods: {
    ...mapActions('shell', ['changeFilterContent']),
    isFilterActive(filterPanelContent) {
      return this.filterPanelContent === filterPanelContent;
    }
  }
};
</script>

<style lang="less">
@import '../../common';

#menu {
  position: fixed;
  .size(@filterMenuWidth, 100vh);
  z-index: @filterBarZI;
  top: 0px;

  &.opened {
    background-color: @filterMenuBGColorOpened;
  };

  &.closed {
    transition: background-color 0s;
    transition-delay: 200ms;
    background-color: @filterMenuBGColor;
  }
}

.navigation-group {
  padding: 50px 0;
}

.navigation-group-divider {
  .size(@filterDividerWidth, @filterDividerHeight);
  border-color: @filterDividerColor;
  margin: auto;
}

</style>