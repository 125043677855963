import { computed } from 'vue';
import licenceService from '@/services/licence.service';
import { useStore } from 'vuex';

export default function useLicence() {
  const store = useStore();
  // Computed
  const projectInitialized = computed(() => store.getters['app/projectInitialized']);

  const hasEarthmovingLicence = computed(() => {
    if (!projectInitialized.value) return false;
    return licenceService.hasEarthmovingLicence();
  });

  const hasStabilizationLicence = computed(() => {
    if (!projectInitialized.value) return false;
    return licenceService.hasStabilizationLicence();
  });

  const hasAwarenessLicence = computed(() => {
    if (!projectInitialized.value) return false;
    return licenceService.hasAwarenessLicence();
  });

  const isGridPoc = computed(() => {
    if (!projectInitialized.value) return false;
    return licenceService.isGridPoc();
  });

  const hasCookieConsent = computed(() => {
    return licenceService.hasCookieConsent();
  });

  const isChineseDistribution = computed(() => {
    return licenceService.isChineseDistribution();
  });

  return {
    hasEarthmovingLicence,
    hasStabilizationLicence,
    hasAwarenessLicence,
    isGridPoc,
    hasCookieConsent,
    isChineseDistribution
  };
}