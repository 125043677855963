<template>
  <div class="cx-refmodel-details-panel">
    <!-- OVERLAY CONTROLS -->
    <transition :name="applyAnimations('overlayControls')">
      <cx-ref-model-overlay-controls v-if="isAnimationReady" :selectedRefModelUuid="selectedRefModel?.uuid"/>
    </transition>
    <!-- STATUS PANEL -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            source="avatars/refmod.png"
            :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyToRefmodel"/>
        </transition>
      </template>
      <template #title>
        <!--TITLE & SUBTITLE -->
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="refModel && isAnimationReady"
            :title="refModel.name"
            :tooltipText="refModel.tooltip"
            :subtitle="refModel.subtitle.value"
            :subtitleIcon="refModel.subtitle.icon"
            :subtitleIconTooltip="refModel.subtitle.field"/>
        </transition>
      </template>
      <template #relatedEntities>
        <!--RELATED ENTITIES -->
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel
            v-if="isAnimationReady"
            :onShowPointsData="showPointGrid"
            :showPointsDataLoading="isFetchingPoints"
            :showPointsDataVisible="isGridPoc">
            <template #preContent>
              <cx-initial-surface-selector
                :initialModels="initialModels"
                :onInitialModelSelected="handleInitialModelSelection"
                :selectedInitialModel="selectedInitialModel"
                :isDisabled="!initialSurfaceEnabled"/>
            </template>
          </cx-related-entities-panel>
        </transition>
      </template>
      <!--MAIN METADATA -->
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!--ANALYSIS -->
    <div class="cx-cmn-dp__analysisPanel">
      <transition :name="applyAnimations('analysis')">
        <cx-ref-model-analysis-cut-fill-progress
          v-if="hasEarthmovingLicence"
          v-show="isAnimationReady"
          :isOpened="isDetailsPanelOpened"/>
      </transition>
    </div>
    <!-- LICENCE -->
    <transition :name="applyAnimations('analysis')">
      <cx-licence-panel v-if="!hasEarthmovingLicence"/>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RefModelDetailsViewModel } from './refModelViewModel';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxAvatar from '../common/CxAvatar';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import CxLicencePanel from '@/app/detailsPanel/common/CxLicencePanel';
import CxRefModelAnalysisCutFillProgress from './CxRefModelAnalysisCutFillProgress';
import CxStatsPanel from '../common/CxStatsPanel';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxInitialSurfaceSelector from './components/CxInitialSurfaceSelector';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxTitlePanel from '../common/CxTitlePanel';
import CxRefModelOverlayControls from './CxRefModelOverlayControls';

export default {
  name: 'CxRefModelDetailsPanel',
  components: {
    CxAvatar,
    CxRefModelOverlayControls,
    CxInitialSurfaceSelector,
    CxMainDataPanel,
    CxLicencePanel,
    CxStatsPanel,
    CxRelatedEntitiesPanel,
    CxTitlePanel,
    CxRefModelAnalysisCutFillProgress
  },
  mixins: [CxLicenceMixin, CxAnimationMixin],
  data() {
    return {
      isInitialModelTooltip: false,
      isNameTooltip: false,
      isPathTooltip: false
    };
  },
  computed: {
    ...mapGetters('detailsRefmodel', [
      'isShowPointsActive',
      'initialModels',
      'selectedInitialModel',
      'overlayControlsEnabled',
      'availableAnalysisTabs',
      'initialSurfaceEnabled',
      'selectedRefModel',
      'isFetchingPoints'
    ]),
    ...mapGetters('shell', ['isDetailsPanelReduced', 'isDetailsPanelOpened']),
    refModel() {
      if (!this.selectedRefModel) return null;
      return new RefModelDetailsViewModel(this.selectedRefModel);
    },
    mainData() {
      if (!this.refModel) return [];
      const { source, updatedTime, createdTime } = this.refModel;
      const result = [
        {
          field: source.field,
          value: source.value,
          icon: source.icon
        },
        {
          field: updatedTime.field,
          value: updatedTime.value,
          icon: updatedTime.icon
        },
        {
          field: createdTime.field,
          value: createdTime.value,
          icon: createdTime.icon
        }
      ];
      return result;
    },
  },
  methods: {
    ...mapActions('detailsRefmodel', ['handleInitialModelSelection', 'handleCameraFlyToRefmodel', 'showPointGrid']),
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-refmodel-details-panel {
  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-stats-panel {
    width: @detailsRefModelStatsPanelWidth;
    padding-right: 20px;
  }
}
</style>