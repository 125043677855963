import { metadataFilterOptionFactory } from '@/store/filterModule/metadataFilterOptionFactory';

export const metadataFilterStoreMixin = {
  state: {
    availableMetadataFilters: [],
    /**
     * Object contains added metadata filters with filterName as a key and filter object in data
     */
    addedMetadataFilters: {},
  },
  getters: {
    /**
     * Returns array of available metadata filters sorted alphabetically
     */
    availableMetadataFilters: (state) => {
      return state.availableMetadataFilters.sort((a, b) => a.displayName.localeCompare(b.displayName));
    },
    /**
     * Returns array made from addedMetadataFilters
     */
    addedMetadataFilters: (state) => {
      return Object.values(state.addedMetadataFilters);
    },
  },
  mutations: {
    setAvailableMetadataFilters: (state, availbleFilters) => {
      state.availableMetadataFilters = availbleFilters;
    },
    addMetadataFilter: (state, filter) => {
      const newState = { ...state.addedMetadataFilters };
      newState[filter.fieldName] = filter;
      state.addedMetadataFilters = newState;
    },
    removeMetadataFilter: (state, filter) => {
      const newState = { ...state.addedMetadataFilters };
      delete newState[filter.fieldName];
      state.addedMetadataFilters = newState;
    },
    changeMetadataFilterValues: (state, data) => {
      const { filter, values } = data;
      const newFilter = { ...filter }; // is this a new instance??
      newFilter.changeValues(values);
      state.addedMetadataFilters[filter.fieldName] = newFilter;
    },
    changeMetadataFilterComparators: (state, data) => {
      const { filter, comparators } = data;
      const newFilter = { ...filter }; // is this a new instance??
      newFilter.changeComparators(comparators);
      state.addedMetadataFilters[filter.fieldName] = newFilter;
    }
  },
  actions: {
    async initializeMetadataFilters(context, { fields, excludedFilters }) {
      const metadataFilters = [];
      const objectKeys = Object.keys(fields);
      objectKeys.forEach((filterName) => {
        if (!excludedFilters.includes(filterName)) {
          const filterOption = metadataFilterOptionFactory.create(fields[filterName].fieldName, fields[filterName].type);
          metadataFilters.push(filterOption);
        }
      });
      context.commit('setAvailableMetadataFilters', metadataFilters);
    },
  }

};