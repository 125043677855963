import _ from 'lodash';
import { defer } from '@/utils/defer';
import bootstrapper from '@/3dapi/cesium.store.bootstrapper';
import * as Cesium from 'cesium';
import units from './units.store';
import refmodels from './refmodels.store';
import drillpatterns from './drillPatterns.store';
import picker from './picker.store';
import points from './points.store';
import holes from './holes.store';
import geocoder from './geocoder.store';
import clock from './clock.store';
import surface from './surface.store';
import mapToolbar from './mapToolbar.store';
import eventsStore from './events.store';
import { cartographicCalculations } from './cartographics/cartographicCalculations';
import mapConstants from './map.constants';
import surfacelogStore from './surfacelog.store';


const cesiumStore = {
  dispatch: defer(),
  /**
   * Initializes the Cesium store and Viewer
   * @param mapContainer {string} Id of the map container div.
   * @param context {Object} The Vuex 'map/viewer' context from witch actions are dispatched.
   */
  async initialize(mapContainer, enableTerrain, context) {
    Cesium.Ion.defaultAccessToken = process.env.VUE_APP_ION_ACCESS_TOKEN;
    let worldTerrain;

    if (enableTerrain) {
      worldTerrain = Cesium.createWorldTerrain();
      await worldTerrain.readyPromise;
    }

    const viewerParams = {
      animation: false,
      shouldAnimate: false,
      baseLayerPicker: false,
      fullscreenButton: false,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      imageryProvider: false,
      navigationInstructionsInitiallyVisible: false,
      navigationHelpButton: false,
      sceneModePicker: false,
      scene3DOnly: true,
      selectionIndicator: false,
      timeline: false,
      shadows: true,
      shadowMode: Cesium.ShadowMode.ENABLED,
      terrainProvider: worldTerrain,
    };

    const { state } = this;
    const viewer = new Cesium.Viewer(mapContainer, viewerParams);
    state.viewer = viewer;
    viewer.scene.heightReference = this.state.defaultHeightReference;
    viewer.scene.globe.depthTestAgainstTerrain = false;
    viewer.scene.camera.percentageChanged = mapConstants.cameraPercentageChanged;
    state.viewerReady.resolve();
    viewer.scene.requestRenderMode = true;
    // viewer.scene.debugShowFramesPerSecond = true;
    // viewer.scene.globe.show = false;
    viewer.scene.skyBox.show = true;

    try {
      this.dispatch.resolve((action, payload) => { this.dispatch = context.dispatch(action, payload); });
    } catch (e) {
      if (e instanceof TypeError) throw new Error('The 3D map viewer can only be initialized once.');
    }
    context.dispatch('monitorFrameRate');
    context.dispatch('notifyTileLoadProgress');
  },
  modules: {
    units,
    refmodels,
    drillpatterns,
    picker,
    points,
    holes,
    geocoder,
    clock,
    mapToolbar,
    surface,
    eventsStore,
    surfacelogStore
  },
  state: {
    viewerReady: defer(),
    viewer: null,
    mapEvents: {}, // events fired on cesium store's dispatches
    defaultHeightReference: 1,
    geocoderApi: null,
    flyToDefaultOptions() {
      return {
        ...mapConstants.cameraFlyToDefaultOptions,
        offset: new Cesium.HeadingPitchRange(0.0, -1.5, 0.0), // nasty hack because Cesium plays unfair
      };
    }
  },
  actions: {
    /**
     * Render a Cesium canvas frame.
     * @param context
     * @returns {Promise<void>}
     */
    async requestRender(context) {
      const { scene } = context.self.state.viewer;
      scene.requestRender();
    },
    /**
     * Notify map store about 3D tiles loading state.
     * @param context
     * @returns {Promise<void>}
     */
    async notifyTileLoadProgress(context) {
      const { state } = context.self;
      const { globe } = state.viewer.scene;
      globe.tileLoadProgressEvent.addEventListener((event) => {
        context.commit('map/setIsLoadingTiles', event > 0, { root: true });
      });
    },
    /**
     * Enable frame rate monitoring.
     * @param context
     * @returns {Promise<void>}
     */
    async monitorFrameRate(context) {
      const { state } = context.self;
      const { viewer } = state;
      let belowThreshold = false;
      let lastFramesPerSecond;
      state.fpsMonitor = new Cesium.FrameRateMonitor({
        scene: viewer.scene,
        minimumFrameRateAfterWarmup: 20
      });
      const throttledNotifyLastFramesPerSecond = _.throttle(() => {
        context.dispatch('notifyLastFramesPerSecond', { fps: lastFramesPerSecond, belowThreshold });
      });
      setInterval(() => {
        ({ lastFramesPerSecond } = state.fpsMonitor);
        throttledNotifyLastFramesPerSecond();
      }, 5000);
      const onFrameRateThresholdChange = (fps) => {
        lastFramesPerSecond = fps;
        throttledNotifyLastFramesPerSecond.flush();
      };
      state.fpsMonitor.lowFrameRate.addEventListener((scene, fps) => {
        belowThreshold = true;
        onFrameRateThresholdChange(fps);
      });
      state.fpsMonitor.nominalFrameRate.addEventListener((scene, fps) => {
        belowThreshold = false;
        onFrameRateThresholdChange(fps);
      });
    },
    async findEntity(context, uuid) {
      const { state } = context.self;
      // search for the uuid everywhere
      // units loaded with CZML have their own datasource
      const unit = state.units.dataSource.entities.getById(uuid);
      // points loaded manually as entities
      const point = state.points.pointSets[uuid] || state.points.points[uuid];
      // holes loaded manually as entities
      const hole = state.holes.holeSets[uuid] || state.holes.holes[uuid];
      // refmodels loaded manuall as entities
      const refmodel = state.refmodels.refmodels[uuid];
      const drillpattern = state.drillpatterns.drillPatterns[uuid];
      // this is technically not an entity but a collection just like point set
      const awarenessEvent = state.eventsStore.eventDataSourceMap[uuid] || state.eventsStore.events[uuid];
      const surfacelog = state.surfacelogStore.surfaceLayers[uuid];
      return unit || point || hole || refmodel || drillpattern || awarenessEvent || surfacelog;
    },
    /**
     * Finds an entity of given id and dispatches camera movement towards the position of this entity.
     * @param context
     * @param uuid {String}: the uuid of the entity to move camera to
     */
    async flyToEntity(context, { uuid }) {
      const { state, getDestinationFromEntitySet, getEntityDestination } = context.self;
      const { currentTime } = state.viewer.clock;
      const entity = await context.dispatch('findEntity', uuid);
      if (entity) {
        if (entity instanceof Set) {
          context.dispatch('flyTo', { geocodableDestination: getDestinationFromEntitySet(entity) });
        } else if (entity instanceof Cesium.GeoJsonDataSource) {
          context.dispatch('flyTo', { geocodableDestination: entity });
        } else if (entity instanceof Cesium.ImageryLayer) {
          context.dispatch('flyTo', { geocodableDestination: entity });
        } else {
          const geocodableDestination = await getEntityDestination(entity, currentTime);
          context.dispatch('flyTo', { geocodableDestination });
        }
      }
    },
    /**
     * Extracts a destination out of a geographic object and dispatches camera movement towards the position of this object.
     * @param context
     * @param geographicObject {Object}: the geographic object derived from an external API serving search suggestions
     */
    async flyToGeographicObject(context, { geographicObject }) {
      const geocodableDestination = geographicObject.destination;
      context.dispatch('flyTo', { geocodableDestination });
    },
    /**
     * Flies the camera toward the position of a geocodable destination.
     * @param context
     * @param geocodableDestination {Object}: Cartesian3 or Rectangle destination to fly to
     */
    async flyTo(context, { geocodableDestination }) {
      const { state } = context.self;
      const { viewer } = state;
      const { camera } = viewer;
      if (geocodableDestination instanceof Cesium.Cartesian3) {
        const offset = 70;
        const destination = Cesium.Rectangle.fromCartesianArray([
          cartographicCalculations.addOffsetToCartesian(geocodableDestination, offset, offset),
          cartographicCalculations.addOffsetToCartesian(geocodableDestination, -offset, -offset)
        ]);
        camera.flyTo({ destination, ...state.flyToDefaultOptions() });
      } else if (geocodableDestination instanceof Cesium.BoundingSphere) {
        camera.flyToBoundingSphere(geocodableDestination, state.flyToDefaultOptions());
      } else if (geocodableDestination instanceof Cesium.Entity) {
        viewer.flyTo(geocodableDestination, state.flyToDefaultOptions());
      } else if (geocodableDestination instanceof Cesium.Rectangle) {
        camera.flyTo({ destination: geocodableDestination, ...state.flyToDefaultOptions() });
      } else if (geocodableDestination instanceof Cesium.GeoJsonDataSource) {
        viewer.flyTo(geocodableDestination, state.flyToDefaultOptions());
      } else if (geocodableDestination instanceof Cesium.ImageryLayer) {
        viewer.flyTo(geocodableDestination, state.flyToDefaultOptions());
      } else throw new Error('Can\'t locate object - unable to determine type of destination');
    },
    async notifyLastFramesPerSecond(context, { fps, belowThreshold }) {
      let result = fps || context.self.state.fpsMonitor.lastFramesPerSecond;
      result = Math.round(result);
      context.commit('map/setLastFramesPerSecond', { fps: result, warn: belowThreshold }, { root: true });
      return result;
    },
    async getTerrainHeight(context, position) {
      const { globe } = context.self.rootState.viewer.scene;
      const terrainQueryDetailLevel = 11;
      const [terrainPosition] = await Cesium.sampleTerrain(globe.terrainProvider, terrainQueryDetailLevel, [position]);
      return terrainPosition.height;
    },
    async getMapPNG(context) {
      const result = defer();
      const { scene } = context.self.state.viewer;
      const removeCallback = scene.postRender.addEventListener(() => {
        removeCallback();
        try {
          result.resolve(scene.canvas.toDataURL('image/png'));
        } catch (e) {
          result.reject('Unable to capture screenshot');
        }
      });
      scene.requestRender();
      return result;
    }
  },
  methods: {
    async getEntityDestination(entity, currentTime) {
      let destination;
      if (!entity || !entity.id) return null;
      const position = entity.position || entity.id.boundingSphere || entity.id.position;
      if (position instanceof Cesium.Cartesian3) {
        const { terrainProvider, scene } = this.state.viewer;
        const { getCartographicFromCartesian3, getCartesian3FromCartographic } = cartographicCalculations;
        const { ellipsoid } = scene.globe;
        const positionCartographic = getCartographicFromCartesian3(position, ellipsoid);
        const positions = await Cesium.sampleTerrainMostDetailed(terrainProvider, [positionCartographic]);
        const [entityPosition] = positions;
        destination = getCartesian3FromCartographic(entityPosition, ellipsoid);
      } else if (position instanceof Cesium.BoundingSphere) destination = position;
      else if (position instanceof Cesium.SampledPositionProperty || position instanceof Cesium.ConstantPositionProperty) destination = position.getValue(currentTime);
      return destination;
    },
    getDestinationFromEntitySet(set) {
      if (_.isEmpty(set)) return null;
      const positions = [...set].map(s => s.position);
      return Cesium.BoundingSphere.fromPoints(positions);
    },
  },
  subscribeEvent(name, handler) {
    this.events[name].subscribe(handler);
  },
  unsubscribeEvent(name, handler) {
    this.events[name].unsubscribe(handler);
  }
};

bootstrapper.bootstrap(cesiumStore);
export default cesiumStore;
