/**
 * @file Defines the classes representing CZML Packets.
 * @author Jan Wąsak
 */

import _extend from 'lodash/assignIn';
import { Property } from '@/utils/czml/property';

/**
 * Represents a CZML Packet.
 */
class Packet {
  constructor(
    id,
    name,
    delete_ = false,
    parent = null,
    description = null,
    availability = null
  ) {
    _extend(this, {
      id,
      name,
      delete_,
      parent,
      description,
      availability,
    });
  }
  assign(property) {
    if (property instanceof Property) this[property.name] = property;
    else throw new Error('Cannot assign a non-property.');
    return property;
  }
}

/**
 * Represents the first CZML Packet.
 * This Packet is called a DocumentPacket
 */
class Document {
  constructor(name, version, clock = null) {
    _extend(this, {
      id: 'document',
      name,
      version,
    });
    if (clock) {
      _extend(this, {
        clock,
      });
    }
  }
}

export { Packet, Document };