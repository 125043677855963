import api from '@/http/api';
import { getFileDownloadError } from '@/domain/fileDownload.error';


const detailsAwarenessEventModule = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async fetchEventData(context, { uuid }) {
      let result = null;
      try {
        const { projectUuid } = context.rootGetters;
        result = await api.fetchAwarenessEvent({ projectUuid, uuid });
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      }
      return result;
    }
  }

};

export default detailsAwarenessEventModule;