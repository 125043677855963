import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export const fetchPointDataStoreMixin = {
  state: {
    isFetchingPoints: false
  },
  getters: {
    isFetchingPoints(state) {
      return state.isFetchingPoints;
    }
  },
  mutations: {
    setIsFetchingPoints(state, isFetching) {
      state.isFetchingPoints = isFetching;
    }
  },
  actions: {
    async showGridData(context, entity) {
      if (entity) {
        context.commit('setIsFetchingPoints', true);
        await context.dispatch('pointGrid/showPointGrid', entity, { root: true });
        context.commit('setIsFetchingPoints', false);
      } else {
        context.dispatch('notifications/error', { message: $gettext('Cannot load points data grid') }, { root: true });
      }
    }
  }
};