<template>
  <div class="cx-hole-details-panel" v-if="selectedHole">
    <!-- TITLE -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            :source="hole.avatar"
            :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyTo"/>
        </transition>
      </template>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="hole.holeName"
            :titleIcon="hole.holeIcon"
            :statusClass="hole.color"
            :subtitle="hole.subtitle.value"
            :subtitleIcon="hole.subtitle.icon"
            :subtitleIconTooltip="hole.subtitle.field"
            :isLoading="isFetchingData"/>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel
            v-if="isAnimationReady"
            :showPoint="true"
            :pointName="measuredPointsCount"
            :showUnit="true"
            :unitName="hole.unitName"
            :showRefmodel="true"
            :refmodelName="hole.refmodelName"
            :onShowPointsData="showPointsData"
            :showPointsDataLoading="isFetchingPoints"
            :showPointsDataVisible="isGridPoc"/>
        </transition>
      </template>
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <div class="cx-cmn-dp__analysisPanel">
      <transition :name="applyAnimations('analysis')">
        <cx-hole-analysis-panel
          v-show="isAnimationReady"
          :hole="hole"
          :isOpened="isDetailsPanelOpened"/>
      </transition>
      <cx-export-asbuild-component
        :exportPoints="(selectedValue) => exportPoints(selectedValue.value)"
        :loading="isExporting"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { HoleDetailsViewModel } from './holeViewModel';
import CxAvatar from '../common/CxAvatar';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxHoleAnalysisPanel from './components/CxHoleAnalysisPanel';
import CxStatsPanel from '../common/CxStatsPanel';
import CxExportAsbuildComponent from '../common/CxExportAsbuildComponent';
import CxTitlePanel from '../common/CxTitlePanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';

export default {
  name: 'CxDrillDetailsPanel',
  components: {
    CxAvatar,
    CxRelatedEntitiesPanel,
    CxMainDataPanel,
    CxStatsPanel,
    CxHoleAnalysisPanel,
    CxExportAsbuildComponent,
    CxTitlePanel
  },
  mixins: [CxAnimationMixin, CxLicenceMixin],
  computed: {
    ...mapGetters('shell', ['isDetailsPanelReduced', 'isDetailsPanelOpened']),
    ...mapGetters('detailsHole', ['selectedHole', 'isExporting', 'isFetchingData', 'isFetchingPoints']),
    hole() {
      if (!this.selectedHole) return null;
      return new HoleDetailsViewModel(this.selectedHole);
    },
    mainData() {
      if (!this.selectedHole) return [];
      const {
        unitType,
        equipmentType,
        gnss,
        coordinateSystem,
        duration
      } = this.hole;
      const result = [
        {
          field: equipmentType.field,
          value: equipmentType.value,
          icon: equipmentType.icon
        },
        {
          field: unitType.field,
          value: unitType.value,
          icon: unitType.icon
        },
        {
          field: gnss.field,
          value: gnss.value,
          icon: gnss.icon,
          unit: gnss.unit
        },
        {
          field: coordinateSystem.field,
          value: coordinateSystem.value,
          icon: coordinateSystem.icon
        },
      ];
      if (duration) {
        result.push({
          field: duration.field,
          value: duration.value,
          icon: duration.icon
        });
      }
      return result;
    },
    measuredPointsCount() {
      if (!this.selectedHole) return '';
      const translated = this.$ngettext('%{ n } Measured Points', '%{ n } Measured Points', this.selectedHole.pointSet.count);
      return this.$gettextInterpolate(translated, { n: this.selectedHole.pointSet.count });
    }
  },
  methods: {
    ...mapActions('detailsHole', [
      'handleCameraFlyTo',
      'exportPoints',
      'showPointsData'
    ]),
  }
};

</script>


<style lang="less">
@import '../../../common';

.cx-hole-details-panel {
  height: 100%;
  background-color: @baseBackgroundColor;
  display: flex;
  .cx-stats-panel {
    width: @detailsHoleStatsWidth;
  }
}

</style>