<template>
  <div class="cx-progress-bar" :id="id">
  </div>
</template>

<script>
import SvgProgressBar from './progressBar';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CxProgressBar',
  props: {
    barId: String, // node id for the progress bar
    className: {
      type: String,
      default: 'svg-bar-class'
    },
    plotData: Object,
    color: String
  },
  data() {
    return {
      id: `cx-progress-bar-${uuidv4()}`,
      svgBar: null,
      size: { width: 250, height: 7 },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    };
  },
  mounted() {
    this.size.width = this.$el.clientWidth;
    this.svgBar = new SvgProgressBar(
      this.id,
      this.size,
      this.margin,
      this.className,
      this.color
    );
    if (this.plotData) {
      this.bar.updateData(this.plotData);
      this.bar.render();
    }
  },
  computed: {
    elementId() {
      return `#${this.id}`;
    },
    bar() {
      return this.svgBar;
    },
  },
  watch: {
    // total: 100, series: [{value, delta, label, styleName}]
    plotData: {
      immediate: true,
      handler(newValue) {
        if (!newValue || newValue.length < 1) return;
        if (this.bar == null) return;
        this.bar.updateData(this.plotData);
        this.bar.render();
      }
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.delta {
  height: @detailsProgressBarDeltaHeight;
  rx: 5;
  ry: 5;
}

.negative-delta {
  stroke: @textColor;
  stroke-linecap: round;
  stroke-width: @detailsProgressBarNegativeDeltaWidth;
}


</style>