<template>
  <div class="cx-event-set-details-panel" v-if="selectedSet">
    <!-- OVERLAY CONTROLS -->
    <transition :name="applyAnimations('overlayControls')">
      <cx-awareness-overlay-controls :selectedSetUuid="selectedSet ? selectedSet.uuid : null"/>
    </transition>
    <!-- TITLE -->
    <cx-stats-panel>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="translations.title"
            :titleClickHandler="onTitleClicked"/>
        </transition>
      </template>
      <template #mainMetadata>
      </template>
    </cx-stats-panel>
    <div class="cx-cmn-dp__analysisPanel">
      <cx-menu-button
        :text="translations.download"
        :items="[{name: 'CSV'}, {name: 'Power BI template'}]"
        class="analysisPanel__export-button"
        :loading="exporting"
        @click="handleExportEvents"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxStatsPanel from '../common/CxStatsPanel';
import CxTitlePanel from '../common/CxTitlePanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';
import CxAwarenessOverlayControls from './CxAwarenessOverlayControls.vue';
import CxMenuButton from '@/components/menuComponents/CxMenuButton';

export default {
  name: 'CxAwarenessEventSetDetailsPanel',
  components: {
    CxStatsPanel,
    CxTitlePanel,
    CxAwarenessOverlayControls,
    CxMenuButton
  },
  mixins: [CxAnimationMixin, CxLicenceMixin],
  data() {
    return {
      clusteredLoading: false,
      exporting: false
    };
  },
  computed: {
    ...mapGetters('map', ['selectedEntity']),
    selectedSet() {
      if (this.selectedEntity && this.selectedEntity.entityType === AwarenessEventSetType) return this.selectedEntity;
      return null;
    },
    translations() {
      const getTitle = () => {
        if (!this.selectedSet) return '';
        const { count } = this.selectedSet;
        const translated = this.$ngettext('%{ n } Awareness Event', '%{ n } Awareness Events', count);
        const interpolated = this.$gettextInterpolate(translated, { n: count });
        return interpolated;
      };
      return {
        title: getTitle(),
        download: this.$gettext('Save Data As...'),
      };
    },
  },
  methods: {
    ...mapActions('map', ['setCameraToEntity']),
    ...mapActions('detailsAwarenessEventSet', ['exportEvents', 'downloadPowerBiTemplate']),
    onTitleClicked() {
      this.setCameraToEntity(this.selectedEntity.uuid);
    },
    async handleExportEvents(downloadOption) {
      this.exporting = true;
      if (downloadOption.name === 'CSV') await this.exportEvents({ eventSet: this.selectedSet });
      else await this.downloadPowerBiTemplate();
      this.exporting = false;
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-event-set-details-panel {
  height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-title-panel {
    span {
      padding-left: 0px;
    }
  }

  .cx-stats-panel {
    width: @detailsSetStatsWidth;
  }

  .cx-stats-panel__upper{
    height: @detailsSetStatsUpperHeight;
  }

  .cx-stats-panel__upper--title {
    margin-left: 55px;
    margin-top: 40px;
  }

  .analysisPanel__export-button {
    margin: 20px 20px auto auto;
  }
}

</style>