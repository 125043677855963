import unitsApi from '@/http/unit.api';
import { toDate } from 'date-fns';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const mapUnitsStore = {
  state: {
    // list of units, that are available to show on the map
    loadedUnits: [],
    // units hidden from the map
    hiddenUnits: [],
    isLocationHistoryLoading: false
  },
  getters: {
    /**
     * All units available to be visiable on the map
     */
    loadedUnits(state) {
      return state.loadedUnits;
    },
    isLocationHistoryLoading(state) {
      return state.isLocationHistoryLoading;
    },
  },
  mutations: {
    addLoadedUnits(state, payload) {
      state.loadedUnits = [...state.loadedUnits, ...payload];
    },
    removeLoadedUnit(state, uuid) {
      state.loadedUnits = state.loadedUnits.filter(r => r.uuid !== uuid);
    },
    showUnits(state, uuids) {
      state.hiddenUnits = state.hiddenUnits.filter(u => !uuids.includes(u));
    },
    hideUnits(state, uuids) {
      state.hiddenUnits = state.hiddenUnits.concat(uuids);
    },
    setLocationHistoryLoading(state, isLoading) {
      state.isLocationHistoryLoading = isLoading;
    }
  },
  actions: {
    /**
     * Add units to the loadedUnits object if not already there
     * @param {*} entities - a list of entities to load on a map
     * @param {*} ids - or pass a list of ids to load on a map, entities will be retrieved first
     */
    async loadUnits(context, { entities, showOnMap }) {
      const units = entities;
      const unitsToAdd = units
        .filter(e => !context.getters.loadedUnits.find(l => l.uuid === e.uuid));
      // add units to the loadedUnits
      if (unitsToAdd.length) {
        // show units on the map
        await context.dispatch('mapLoadUnitLocations', { units: unitsToAdd });
        context.commit('addLoadedUnits', unitsToAdd);
        if (showOnMap) await context.dispatch('mapShowUnits', { show: unitsToAdd });
        if (!showOnMap) await context.dispatch('mapHideUnits', { hide: unitsToAdd });
      }
      return true;
    },
    /**
     * Unloads given unit from the map
     * @param {String} uuid of a unit to unload
     */
    async unloadUnit(context, { entity }) {
      context.commit('removeLoadedUnit', entity.uuid);
      // remove from hidden units list, if was there - we do not want to have them on the hidden list
      // as they are not loaded any more
      context.commit('showUnits', [entity.uuid]);
      // we do not really remove gltf from map entities, just hide them
      // and also deselects them
      await context.dispatch('mapHideUnits', { hide: [entity], syncObjectExplorer: false });
    },
    /**
     * Shows units on the map
     * @param {Array} unitsToShow list of units to show
     */
    async mapShowUnits(context, { show }) {
      const ids = show.map(s => s.uuid);
      context.commit('showUnits', ids);
      await context.dispatch('viewer/showUnits', { show: ids });
    },
    /**
     * Loads location history for the provided units.
     * @param context
     * @param units {Array<UnitEntity>} the list of units for which location history will be loaded.
     * @param dateFrom the date from which to query location history
     * @param dateTo the date until which to query location history
     * @returns {Promise<void>}
     */
    async mapLoadUnitLocations(context, { units, dateFrom, dateTo }) {
      context.commit('setLocationHistoryLoading', true);
      const ids = units.map(s => s.uuid);
      const start = dateFrom || toDate(context.rootGetters['app/projectStartDate']);
      const stop = dateTo || new Date(Date.now());
      const unitLocationsCZML = await Promise.allSettled(
        ids.map(uuid => unitsApi.getUnitLocationHistory(context.rootGetters.projectUuid, uuid, start, stop))
      );
      if (unitLocationsCZML.some(locationResponse => locationResponse.status === 'rejected')) {
        const message = $gettext('Error while fetching location history data');
        context.dispatch('notifications/error', { message }, { root: true });
      }

      await context.dispatch('viewer/loadUnitLocationHistory', { unitLocationsCZML: unitLocationsCZML.map(p => p.value), dateFrom, dateTo });
      context.commit('setLocationHistoryLoading', false);
    },
    /**
     * Hides units on the map
     * @param {Array} hide list of units to hide
     */
    async mapHideUnits(context, { hide, syncObjectExplorer = true }) {
      const ids = hide.map(s => s.uuid);
      if (syncObjectExplorer) {
        context.commit('hideUnits', ids);
      }
      await context.dispatch('viewer/hideUnits', { hide: ids });
    },
    /**
     * Checks if there is a selectedEntity and if the entity has same same id as given
     * it will be deselected
     * @param {*} id the id of a entity to deselect
     */
    async deselectUnits(context, { uuids }) {
      if (context.state.selectedEntity && uuids.find(f => context.state.selectedEntity.uuid === f)) {
        await context.dispatch('viewer/deselect');
      }
    },
    /**
     * Process CZML to be able to load units on the map in the future
     * @param {} context
     * @param {*} param
     */
    async loadUnitsFromCZML(context, czml) {
      context.dispatch('viewer/loadAllUnitsFromCZML', czml);
    }
  }
};

export default mapUnitsStore;