import { flatMap, without } from 'lodash';
import { withFullPath, withCanSelect, withCanFlyTo } from '@/domain/entityMixins';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';

const objectExolorerStore = {
  state: {
    // a collection of object ids with a longer TTL
    // these trigger spinners where eyes normally are
    loadingMapObjects: []
  },
  getters: {
    /**
     * Collection used to feet Object Explorer Control
     * Return data in structure {
          uuid: uuid of entity object
          name: name to be displayed in oe
          entityType: type of entity
          level: level on which it should be displayed (level 0 beeing root, level 1 beeing a child)
     * }
     * @param {} state
     * @param {*} getters
     */
    objectExplorerEntities(state, getters) {
      const manageableEntities = [...state.loadedUnits, ...state.loadedRefmodels, ...getters.persistentSets, ...getters.pesistentDHSets, ...getters.loadedAwarenessEventSets, ...getters.loadedSurfacelogs];
      const children = flatMap(flatMap(Object.keys(state.parentEnityMap), k => state.parentEnityMap[k]), o => o);
      const topLevelEntities = manageableEntities.filter(e => !children.includes(e.uuid));
      const result = [];
      // push top level entity and all it's children subsequenlty
      topLevelEntities.map((e) => withCanFlyTo(withCanSelect(withFullPath(e), 160))).forEach((entity) => {
        result.push({
          uuid: entity.uuid,
          name: entity.name,
          entityType: entity.entityType,
          type: entity.type,
          category: entity.category,
          canSelect: entity.canSelect,
          canFlyTo: entity.canFlyTo,
          level: 0,
          path: entity.fullPath,
          count: (entity.entityType === PointSetType || entity.entityType === DrillHoleSetType || entity.entityType === AwarenessEventSetType) ? entity.count : null,
        });
        const entityChildren = state.parentEnityMap[entity.uuid] || [];
        entityChildren.forEach((childUid) => {
          const childEntity = manageableEntities.find(e => e.uuid === childUid);
          if (childEntity) {
            result.push({
              uuid: childEntity.uuid,
              name: childEntity.name,
              entityType: childEntity.entityType,
              category: entity.category,
              canSelect: entity.canSelect,
              canFlyTo: entity.canFlyTo,
              level: 1,
              count: (childEntity.entityType === PointSetType || childEntity.entityType === DrillHoleSetType || entity.entityType === AwarenessEventSetType) ? childEntity.count : null,
            });
          }
        });
      });
      return result;
    },
    areMapObjectsLoading(state) {
      return state.loadingMapObjects.length > 0;
    },
    mapObjectsLoading(state) {
      return state.loadingMapObjects;
    }
  },
  mutations: {
    addToLoadingMapObjects(state, uuid) {
      state.loadingMapObjects.push(uuid);
    },
    removeFromLoadingMapObjects(state, uuid) {
      state.loadingMapObjects = without(state.loadingMapObjects, uuid);
    },
  },
  actions: {
    /**
     * Selects an entity with a given uuid. If entity is not visible, it shows it on the map
     * @param {*} uuid of entity to select
     */
    async setEntitySelected(context, { uuid, type } = {}) {
      const selection = uuid ? [{ uuid }] : [];
      let mapSelection = selection;
      // in case of point Set or hole set, let's select all points/holse that are inluded in this set
      if (type === PointSetType) mapSelection = context.state.loadedPoints[uuid].points;
      if (type === DrillHoleSetType) mapSelection = context.state.loadedDrillHoleSets[uuid].drillHoles;
      const entity = context.getters.allLoadedEntities.find(f => f.uuid === uuid && f.entityType === type);
      const deselecting = !selection.length;
      const selecting = entity && withCanSelect(entity).canSelect;
      if (deselecting || selecting) {
        context.dispatch('handleEntitySelection', { selection });
        context.dispatch('viewer/selectById', { uuids: mapSelection.map(s => s.uuid) });
        if (selecting) {
          if (Object.values(UnitTypes).includes(type)) context.dispatch('mapShowUnits', { show: [entity] });
          if (Object.values(RefmodelTypes).includes(type) && type !== RefmodelTypes.DrillPattern) context.dispatch('mapShowRefmodels', { refModelList: [entity] });
          if (type === PointSetType) context.dispatch('showPoints', { setId: uuid });
          if (type === DrillHoleSetType) context.dispatch('showDHSet', { setId: uuid });
          if (type === RefmodelTypes.DrillPattern) context.dispatch('showDrillPattern', { entities: [entity] });
          // can't select drill hole set from object explorer
        }
      }
    },
    async addToLoadingMapObjects(context, uuid) {
      context.commit('addToLoadingMapObjects', uuid);
    },
    async removeFromLoadingMapObjects(context, uuid) {
      context.commit('removeFromLoadingMapObjects', uuid);
    },
  }

};

export default objectExolorerStore;