import _ from 'lodash';
import { differenceInHours } from 'date-fns';
import * as d3 from 'd3';
import calc from './calc.helper';

const EVENT_AREA_CLASS = 'event-layer-area';
const EVENT_AREA_ITEM_CLASS = 'event-area-item';

/**
 * This layer handles events on the chart
 * Additional, transparent layer on bars is created to handle events
 */
export default class D3ChartEventLayer {

  // d3 element for the data layer
  layer;

  visualProps = {
    barWidth: null,
    size: null,
    margin: null,
    hoverAreaMargin: 0.4,
  }

  svg;
  scaleX;
  scaleY;

  chartData;
  eventHandlers;

  _supressHover = false;
  _draggableElements = [];

  constructor(svg, style, eventHandlers) {
    this.visualProps.barWidth = style.barWidth;
    this.visualProps.size = style.size;
    this.visualProps.margin = style.margin;
    this.svg = svg;
    this.eventHandlers = { ...eventHandlers };
  }

  moveToFront() {
    this.layer.moveToFront();
  }

  plot(chartData, scaleX, scaleY) {
    this.layer = this.svg
      .append('g')
      .attr('class', EVENT_AREA_CLASS);
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this._plotEventArea(chartData);
    this.chartData = chartData;
  }

  /**
   * Call to enable dragging functionality on element
   */
  applyDraggable(element) {
    if (element) {
      const item = this.layer.selectAll(`.${EVENT_AREA_ITEM_CLASS}-${element.data.uuid}`);
      item.call(d3.drag().on('start', this._handleDragStarted.bind(this)));
      item.classed('draggable', true);
      this._draggableElements.push(element);
    }
  }
  /**
   * Call to disable dragging functionality on elelement
   */
  removeDraggable(element) {
    if (element) {
      const item = this.layer.selectAll(`.${EVENT_AREA_ITEM_CLASS}-${element.data.uuid}`);
      item.call(d3.drag().on('start', null));
      item.classed('draggable', false);
      this._draggableElements = this._draggableElements.filter(e => e.data.uuid === element.data.uuid);
    }
  }


  // for each bar on the chart render the hover area that
  // is transparent, but will catch all hover events
  // make sure that hover area layer is always in front of the line area layer
  _plotEventArea(chartData) {
    const { scaleX } = this;
    const { barWidth, size, margin, hoverAreaMargin } = this.visualProps;
    const plotHoverData = chartData[1];

    const hoverMargin = barWidth * hoverAreaMargin;
    const h = (size.height - margin.top) - this.visualProps.margin.bottom;
    const x = d => calc.calcX1(scaleX, d.data.date, barWidth) - hoverMargin;
    // update text element
    const elements = this.layer.selectAll(`.${EVENT_AREA_CLASS}`).data(plotHoverData);
    const created = elements
      .enter()
      .append('g')
      .attr('class', d => `${EVENT_AREA_ITEM_CLASS} ${EVENT_AREA_ITEM_CLASS}-${d.data.uuid}`);
    created
      .append('rect')
      .attr('class', 'itemc')
      .attr('fill', 'transparent')
      .attr('width', barWidth + 2 * hoverMargin)
      .attr('height', h)
      .attr('x', d => x(d))
      .on('mouseover', this._handleMouseOver.bind(this))
      .on('mouseout', this._handleMouseOut.bind(this))
      .on('click', this._handleBarClick.bind(this))
      .attr('y', margin.top);
  }

  _handleMouseOver(event, element) {
    const item = this.layer.selectAll(`.${EVENT_AREA_ITEM_CLASS}-${element.data.uuid}`);
    if (this._draggableElements.some(e => e.data.uuid === element.data.uuid)) item.classed('hoverDrag', true);
    item.classed('hover', true);
    if (this.eventHandlers.mouseover) this.eventHandlers.mouseover(element);
  }
  _handleMouseOut(event, element) {
    const item = this.layer.selectAll(`.${EVENT_AREA_ITEM_CLASS}-${element.data.uuid}`);
    item.classed('hoverDrag', false);
    item.classed('hover', false);
    if (this.eventHandlers.mouseout && !this._supressHover) this.eventHandlers.mouseout(element);
  }
  _handleBarClick(event, element) {
    if (this.eventHandlers.click) this.eventHandlers.click(element);
  }
  _handleDragStarted(event, data) {
    if (this.eventHandlers.dragStarted) {
      this.layer.selectAll('.itemc').on('mouseover', null);
      this.eventHandlers.dragStarted(event, data);
      event.on('drag', this._handleDragg.bind(this)).on('end', this._handleDraggEnd.bind(this));
    }
  }
  _handleDragg(event, data) {
    const dragElement = this.scaleX.invert(event.x);
    const element = this._closestDate(dragElement);
    if (this.eventHandlers.dragg) this.eventHandlers.dragg(event, data, element);
  }
  _handleDraggEnd(event, data) {
    event.on('drag', null).on('end', null);
    this.layer.selectAll('.itemc').on('mouseover', this._handleMouseOver.bind(this));
    const dragElement = this.scaleX.invert(event.x);
    const element = this._closestDate(dragElement);
    if (this.eventHandlers.draggEnd) this.eventHandlers.draggEnd(event, data, element);
  }
  _closestDate(current) {
    const dateDeltas = this.chartData[1].map((d) => {
      return {
        d,
        delta: Math.abs(differenceInHours(current, d.data.date))
      };
    });
    return _.minBy(dateDeltas, d => d.delta);
  }

}