import axios from 'axios';

const params = {};
if (process.env.VUE_APP_API_URL) {
  params.baseURL = process.env.VUE_APP_API_URL;
}
const instance = axios.create(params);

instance.defaults.xsrfCookieName = process.env.VUE_APP_COOKIE_PREFIX === 'false' ? 'csrftoken' : `${process.env.VUE_APP_COOKIE_PREFIX}csrftoken`;
instance.defaults.xsrfHeaderName = 'X-CSRFToken';

export default instance;
