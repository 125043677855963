import http from '@/http/awareness.axios.instance';
import { AlarmTypeFilterValue } from '@/domain/awareness/enums/AlarmTypeFilterValue.enum';
import { DetectedZoneFilterValue } from '@/domain/awareness/enums/DetectedZoneFilterValue.enum';

interface GetKpiDataParams {
  projectUuid: string,
  fromTime: string,
  toTime: string,
  eventTypes: AlarmTypeFilterValue[],
  detectedZones: DetectedZoneFilterValue[]
}

const getKpiData: (params: GetKpiDataParams) => Promise<number> =
  async ({ projectUuid, fromTime, toTime, eventTypes = [], detectedZones = [] }) => {
    const eventTypesQuery = eventTypes.join('&event_type=');
    const detectedZonesQuery = detectedZones.join('&detected_zone=');

    const url = encodeURI(`projects/${projectUuid}/kpi_data?from_time=${fromTime}&to_time=${toTime}&event_type=${eventTypesQuery}&detected_zone=${detectedZonesQuery}`);
    const response = await http.get(url);
    return response.data as number;
  };

const kpiApi = { getKpiData };

export default kpiApi;
