<template>
  <cx-popup :isOpened="isOpened" :offset="offset" :offsetRatio="offsetRatio" :popupId="popupId" :glass="glass">
    <div class="cx-simple-popup" :style="simplePopupStyle">
      <div class="cx-popup__header">
        <div class="cx-popup__header__content">
          <div class="cx-popup__header__content__title">
            <slot name="title"></slot>
          </div>
          <div class="cx-popup__header__content__subtitle">
            <slot name="subtitle"></slot>
          </div>
        </div>
        <cx-button
          v-if="showCloseIcon"
          flat
          variantPrimary
          icon="close"
          :iconSize="15"
          @click="close"
          :disabled="closeButtonDisabled"/>
      </div>
      <div class="cx-popup__body">
        <slot></slot>
      </div>
      <div class="cx-popup__footer">
        <slot name="actions"></slot>
        <cx-button
          v-if="showCloseButton"
          class="cx-popup__footer__close-btn"
          variantSecondary
          @click="close"
          :disabled="closeButtonDisabled">
          {{ closeBtnCaption }}
        </cx-button>
      </div>
    </div>
  </cx-popup>
</template>

<script>
import CxButton from '@/components/CxButton';
import CxPopup from './CxPopup';
import { mapActions } from 'vuex';
/**
 * A popup component with slots to display contents
 */
export default {
  name: 'CxSimplePopup',
  components: {
    CxButton,
    CxPopup
  },
  props: {
    // id of the popup, must be uniqe across the application
    // not nil: popup will try to check if it is opened using popup store
    popupId: { type: String, default: null },
    isOpened: { type: Boolean }, // do a sync for updates
    showCloseButton: { // if true the close button on the bottom wil appear
      type: Boolean,
      default: true
    },
    closeButtonDisabled: {
      type: Boolean,
      default: false
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    width: Number,
    height: Number,
    offset: { type: Object, default: () => ({ y: 0, x: 0 }) },
    offsetRatio: { type: Object, default: () => ({ y: 0.5, x: 0.5 }) },
    handleClosePopup: { type: Function },
    // if true, the popup render an invisible div underneath
    // disabling all interaction with the underlying app
    glass: { type: Boolean, default: true }
  },
  emits: ['update:isOpened'],
  computed: {
    closeBtnCaption() {
      return this.$gettext('Close');
    },
    simplePopupStyle() {
      const width = this.width ? `${this.width}px` : 'auto';
      const height = this.height ? `${this.height}px` : 'auto';
      return { width, height };
    }
  },
  methods: {
    ...mapActions('popup', ['closePopup']),
    close() {
      if (this.handleClosePopup) {
        this.handleClosePopup();
      } else {
        this.$emit('update:isOpened', false);
        if (this.popupId) this.closePopup(this.popupId);
      }
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-simple-popup {
  padding: 20px 35px 30px 30px;
  display: flex;
  flex-direction: column;
  .cx-popup__header {
    flex-direction: row;
    display: flex;
    .cx-popup__header__content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      .cx-popup__header__content__title {
        color: @textColorHighlight;
        font-size: 22px;
        font-weight: @appFontWeight;
        flex: 0 0 auto;
      }
      .cx-popup__header__content__subtitle {
        font-size: @appFontSize;
        color: @textColorLowlight;
        padding-top: 2px;
        flex: 0 0 auto;
      }
    }
  }

  .cx-popup__actions {
    position: relative;
    height: @cxPopupActionsHeight;
  }
  .close-icon {
    font-size: 15px;
    color: @textColor;
    .size(15px, 15px);
  }
  .close-icon:hover {
    color: @textColorHighlight !important;
    cursor: pointer;
  }

  .cx-popup__body {
    padding-top: 10px;
    font-size: @appFontSize;
    color: @textColor;
    flex: 1 0 auto;
  }
  .cx-popup__footer {
    flex: 0 0 auto;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .cx-popup__footer__close-btn {
      flex: 0;
      margin-left: auto;
    }
  }
}

</style>