import { AwarenessEventSetType } from '@/domain/awareness/AwarenessEventSetType';
import FilterEntityViewModel from '@/store/filterModule/filterEntityViewModel';
import { gettext } from '@/translations/gettext.setup';

const { $ngettext } = gettext;

export default class FilterAwarenessEventViewModel extends FilterEntityViewModel {

  _entityType;
  constructor(entity, locale) {
    super(entity, locale);
    this._entityType = AwarenessEventSetType;
  }

  get name() {
    const { totalCount } = this._entity;
    const defaultName = $ngettext('awareness event', 'awareness events', totalCount);
    return `${totalCount} ${defaultName}`;
  }

  get isResultSelectable() {
    return false;
  }

  get isResultVisible() {
    return true;
  }
}