import { parseISO } from 'date-fns';
import http from '@/http/axios.instance';
import mapper from './hole.mapper';
import { filterMapper } from './filter.mapper';
import { downloadFile } from '@/http/common/download.service';
import { FilterModel } from '@/domain/filters/FilterModel';
import { HoleSet } from '@/domain/holeSet/HoleSet';

const filterHoles = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: FilterModel[],
  includeQueryParams: boolean,
  metadataFields: string[]
): Promise<HoleSet> => {
  const mappedFilters = filterMapper.map(filters);
  const url =
    `/api/ui2/v1/projects/${projectUuid}/asbuilt/stabilization/?from_time=${startTime}&to_time=${stopTime}`;

  const params = {
    filters: mappedFilters,
    include_query_params: includeQueryParams,
    metadata_fields: [] as string[]
  };
  if (metadataFields) {
    params.metadata_fields = metadataFields;
  }
  const response = await http.post(url, params);
  const holeSetTimeRange = { from: parseISO(startTime), to: parseISO(stopTime) };
  const holeSet = mapper.mapHoles(response.data, filters, holeSetTimeRange);
  return holeSet;
};

const exportHoles = async (
  projectUuid: string,
  startTime: string,
  stopTime: string,
  filters: FilterModel[],
  fileName: string,
  fileFormat: string,
  includeQueryParams = true,
  metadataFields = ['all']
) => {
  const mappedFilters = filterMapper.map(filters);
  const url =
    `/api/ui2/v1/projects/${projectUuid}/asbuilt/stabilization/?from_time=${startTime}&to_time=${stopTime}&filename=${fileName}&format=${fileFormat}`;

  const params = {
    filters: mappedFilters,
    include_query_params: includeQueryParams,
    metadata_fields: ['all']
  };
  if (metadataFields) {
    params.metadata_fields = metadataFields;
  }
  await http.post(url, params, { responseType: 'blob' }).then(response => downloadFile(response.data, fileName));
};

const holeApi = {
  filterHoles,
  exportHoles
};
export default holeApi;
