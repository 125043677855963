<template>
  <div class="cx-metadata-filter-add" v-if="isVisible">
    <n-cx-select
      id="metadata-filter-add-select"
      :items="filtersToSelect"
      :selectedItemIds="[selectedFieldName]"
      @update:selectedItemIds="selectedFilerChanged">
    </n-cx-select>
    <div class="add-buttons">
      <div class="add-buttons--icon">
        <cx-button
          flat
          icon="add"
          :tooltip="translations.add"
          :iconSize="15"
          @click="handleAddFilter(false)"
          :disabled="!canAdd"/>
      </div>
      <div class="add-buttons--icon">
        <cx-button
          flat
          icon="add_circle"
          :tooltip="translations.addRange"
          :iconSize="15"
          @click="handleAddFilter(true)"
          :disabled="!canAddRange"/>
      </div>
    </div>
  </div>
</template>

<script>
import NCxSelect from '@/components/NCxSelect';
import CxButton from '@/components/CxButton';

export default {
  name: 'CxMetadataFilterAdd',
  components: {
    NCxSelect,
    CxButton
  },
  props: {
    // function to call when adding a filter
    addFilter: Function,
    // filters available to add
    availableFilters: Array,
    // filters already added
    usedFilters: Array,
  },
  data: () => ({
    selectedFieldName: ''
  }),
  computed: {
    isVisible() {
      return this.availableFilters.length > 0;
    },
    filtersToSelect() {
      return this.availableFilters.map(filter => ({ id: filter.fieldName, title: filter.displayName }));
    },
    selectedItem() {
      return this.availableFilters.find(i => i.fieldName === this.selectedFieldName);
    },
    canAddRange() {
      if (!this.selectedItem) return false;
      const isFieldUsed = this.usedFilters.some(f => f.fieldName === this.selectedItem.fieldName);
      if (isFieldUsed) return false;
      if (this.selectedItem.dataType === 'number') return true;
      return false;
    },
    canAdd() {
      if (!this.selectedItem) return false;
      const isFieldUsed = this.usedFilters.some(f => f.fieldName === this.selectedItem.fieldName);
      return !isFieldUsed;
    },
    translations() {
      return {
        add: this.$gettext('Add'),
        addRange: this.$gettext('Add Range')
      };
    }
  },
  watch: {
    availableFilters: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.length > 0) {
          this.selectedFieldName = value[0].fieldName;
        }
      }
    }
  },
  methods: {
    selectedFilerChanged(value) {
      if (value.length) {
        [this.selectedFieldName] = value;
      }
    },
    handleAddFilter(isRange) {
      if (!this.selectedItem) return;
      this.addFilter({ filter: this.selectedItem, isRange });
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-metadata-filter-add {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;

  .cx-select {
    width: 100%;
  }

  .cx-popper-content {
    overflow-y: auto;
  }



  .add-icon {
    top: 10px;
    margin-right: 0px;
  }

  .add-masure-points-filters-menu {
    bottom: 20px;
    flex: 0 0 70px;
  }

  .add-buttons {
    flex: 0 0 50px;
    margin-left: 5px;
    .flex(row, space-around, center);

    .add-buttons--icon {
      flex: 0 0 20px;
      display: flex;
    }
  }

}

.add-menu {
  min-width: @filterDynamicAddMinWidth;
  text-align: left;

  .add-menu-item {
    margin: 5px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: @dynamicFilterAddBgColor;
    }
  }
}

</style>