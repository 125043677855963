import _ from 'lodash';
import { formatISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import api from '@/http/api';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

class SurfaceModel {
  uuid;
  isVisible;

  constructor(uuid) {
    this.uuid = uuid;
    this.isVisible = true;
  }
}

const mapSurfaceStore = {
  state: {
    // map of loaded surfaces: uuid - SurfaceModel
    loadedSurfaces: {},
  },
  getters: {
    loadedSurfaces() {
      return this.state.loadedSurfaces;
    },
    surface: (state) => (uuid) => {
      return state.loadedSurfaces[uuid];
    }
  },
  mutations: {
    addSurface(state, uuid) {
      state.loadedSurfaces = { ...state.loadedSurfaces, [uuid]: new SurfaceModel(uuid) };
    },
    removeSurface(state, uuid) {
      state.loadedSurfaces = _.omit(state.loadedSurfaces, [uuid]);
    },
    showSurface(state, uuid) {
      state.loadedSurfaces[uuid].isVisible = true;
    },
    hideSurface(state, uuid) {
      state.loadedSurfaces[uuid].isVisible = false;
    }
  },
  cancelableActions: {
    /**
     * Will call api to get surface generated for the given point set
     * @param {String} uuid of point set to display surface for
     * @returns uuid of newly created surface or null
     */
    async loadSurface(context, { points, cancelToken }) {
      let surfaceUUID = null;
      if (!Array.isArray(points)) throw new Error('Can\'t generate surface from given points');
      try {
        if (cancelToken.canceled) return false;
        // get surface from api
        const surface = await api.getSurfaceFromPoints(context.rootGetters.projectUuid, points);
        // add uuid to the surface
        if (cancelToken.canceled) return false;
        const gltf = surface.result.surface.results[0].output;
        // add surface to the store
        const uuid = uuidv4();
        // display it in cesium
        await context.dispatch('viewer/loadAsBuiltSurface', { uuid, gltf, show: true });
        context.commit('addSurface', uuid);
        surfaceUUID = uuid;
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Can\'t create a surface from given points') }, { root: true });
      }
      return surfaceUUID;
    },
    /**
     * Calls api to get the diff model beteen set of points and reference model
     * @param {String} pointSetUuid
     */
    async loadCutFillSurface(context, { points, modelUuid, cancelToken }) {
      let surfaceUUID = null;
      if (!Array.isArray(points)) throw new Error('Can\'t generate surface from given points');
      try {
        if (cancelToken.canceled) return surfaceUUID;
        const diffModel = await api.getDiffModelFromPointsAndModel(
          context.rootState.parentContext.project_uuid,
          modelUuid,
          points
        );
        if (cancelToken.canceled) return surfaceUUID;
        const uuid = uuidv4();
        await context.dispatch('viewer/loadCutFillSurface', { uuid, gltf: diffModel, show: true });
        context.commit('addSurface', uuid);
        surfaceUUID = uuid;
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Can\'t calculate diff model') }, { root: true });
      }
      return surfaceUUID;
    },
    async loadDiffSurface(context, { pointsFrom, pointsTo, refmodelUuid, fromTime, toTime, cancelToken }) {
      let surfaceUUID = null;
      if (!Array.isArray(pointsFrom) || !Array.isArray(pointsTo)) throw new Error('Can\'t generate surface from given points');
      try {
        if (cancelToken.canceled) return null;
        const volumeDiff = await api.pointToPointDiff(
          context.rootGetters.projectUuid,
          pointsFrom,
          pointsTo,
          formatISO(fromTime),
          formatISO(toTime)
        );
        if (cancelToken.canceled) return null;
        const cutFillMap = await api.getDiffModelFromPointsAndModel(context.rootGetters.projectUuid, refmodelUuid, pointsTo);
        // show diff model
        if (cancelToken.canceled) return null;
        const uuid = uuidv4();
        await context.dispatch('viewer/loadDiffSurface', {
          uuid,
          gltf: [volumeDiff, cutFillMap],
          show: true
        });
        context.commit('addSurface', uuid);
        surfaceUUID = uuid;
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Can\'t calculate diff model') }, { root: true });
      }
      return surfaceUUID;
    },

  },
  actions: {
    async showSurface(context, { uuid }) {
      context.commit('showSurface', uuid);
      await context.dispatch('viewer/showSurface', { uuid });
    },
    async hideSurface(context, { uuid }) {
      context.commit('hideSurface', uuid);
      await context.dispatch('viewer/hideSurface', { uuid });
    },
    async removeSurface(context, { uuid }) {
      if (uuid) {
        await context.dispatch('viewer/removeSurface', { uuid });
        await context.commit('removeSurface', { uuid });
      }
    },
  }
};

export default mapSurfaceStore;