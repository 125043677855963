<template>
  <div class="cx-main-data-panel">
    <div class="cx-main-data-panel__title" v-if="title">
      {{ title }}
    </div>
    <div v-for="(item, index) of data" :key="`cx-main-data-panel-${item.key}`" class="item cxu-center-v">
      <div class="item-content" v-if="!item.values && !item.showTooltip">
        <cx-icon :icon="item.icon" :size="15" :class="getColorScheme(item.icon)"/>
        <span :class="item.labelClass">{{ item.value }}</span>
        <span v-if="item.unit" class="cx-main-data-panel__measurement-unit">&nbsp;{{ item.unit }}</span>
      </div>
      <div class="item-content item-content--multiple-values" v-if="item.values && item.values.length">
        <div v-for="(subItem, subIndex) of item.values" :key="subIndex" class="item-content__value">
          <cx-icon :icon="subItem.icon" :size="15" :class="getColorScheme(subItem.icon)"/>
          <span :class="subItem.labelClass">{{ subItem.value }}</span>
          <span v-if="subItem.unit" class="cx-main-data-panel__measurement-unit">&nbsp;{{ subItem.unit }}</span>
        </div>
      </div>
      <div
        class="item-content item-content--tooltip"
        v-if="!item.values && item.showTooltip"
        @mouseenter="showItemsTooltip[index]=true" @mouseleave="showItemsTooltip=false"
        v-cx-tooltip="{
          text: item.tooltipText || item.value,
          show: showItemsTooltip[index],
        }">
        <cx-icon :icon="item.icon" :size="15" :class="getColorScheme(item.icon)"/>
        <span :class="item.labelClass">{{ item.value }}</span>
        <span v-if="item.unit" class="cx-main-data-panel__measurement-unit">&nbsp;{{ item.unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CxMainDataPanel',
  components: {
    CxIcon
  },
  props: {
    // empty value will be displayed in gray when there is no information. If not prvided 'no data' will be displayed instead
    // {icon, key, class, value, noDataText}
    mainData: Array,
    title: String
  },
  data() {
    return {
      showItemsTooltip: {}
    };
  },
  computed: {
    data() {
      return this.mainData.map((d) => {
        const key = uuidv4();
        if (!d.value && d.value !== 0) {
          return {
            ...d,
            value: d.noDataText || this.$gettext('no data'),
            labelClass: 'noinfo',
            key
          };
        }
        if (!d.icon) {
          return {
            ...d,
            labelClass: 'noicon',
            key
          };
        }
        return { ...d, key };
      });
    }
  },
  methods: {
    getColorScheme(icon) {
      return icon === 'warning' ? 'cxu-color-warning' : 'cxu-color-faded';
    }
  }
};

</script>

<style lang="less">
@import '../../../../common';

.cx-main-data-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 320px;

  .cx-main-data-panel__title {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
    color: @textColorLowlight;
  }

  .item {
    margin-bottom: 15px;
    margin-right: 30px;
    max-width: 100%;

    .item-content {
      display: flex;
      align-items: center;
      max-width: 100%;

      &--multiple-values {
        align-items: flex-start;
        flex-direction: column;
        max-height: 128px;
        overflow-y: auto;

        .item-content__value {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 8px;
        }
      }

      &--tooltip > span{
        word-break: break-all;
        .cut-overflow();
      }
    }
  }

  .cx-icon {

    &.customIcon {
      .size(@customIconSize, @customIconSize);
      opacity: 0.3;
    }

  }
  span {
    display: inline-block;
    font-size: @appFontSize;
    vertical-align: text-top;
    margin-left: 8px;
    color: @textColor;

     &.noicon {
      margin-left: 24px;
     }
     &.noinfo {
       color: @textColorLowlight;
     }
     &.cx-main-data-panel__measurement-unit {
       margin-left: 0px;
       color: @textColorLowlight;
       display:inline-block;
     }
  }
}

</style>