export enum UnitTypes {
  DozerType = 'BULLDOZER',
  GraderType = 'GRADER',
  ExcavatorType = 'EXCAVATOR',
  ExcavatorWheeledType = 'EXCAVATOR-WHEELED',
  BackhoeType = 'BACKHOE',
  WheelLoaderType = 'WHEEL-LOADER',
  HaulTruckType = 'DUMP-TRUCK',
  FieldCrewType = 'SURVEYOR',
  FieldCrewIconSolutionsType = 'Field Crew & iCON Solutions',
  BaseStationType = 'BASE-STATION',
  DrillerType = 'DRILLER',
  PilerType = 'PILER',
  RollerType = 'ROLLER',
  SnowGroomerType = 'SNOW-GROOMER',
  SlipformPaverType = 'SLIPFORM-PAVER',
  PlacerSpreaderType = 'PLACER-SPREADER',
  CurbAndGutterType = 'CURB-AND-GUTTER',
  MillingType = 'MILLING',
  PaverType = 'ASPHALT-PAVER',
  TrimmerType = 'TRIMMER',
  DefaultUnitType = 'UNIT',
  Vehicle = 'VEHICLE',
  CTLSkidSteer = 'CTL-SKID-STEER'

}