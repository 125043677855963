import datetimehelper from '@/utils/datetimehelper';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

export class DrillPatternDetailsViewModel {
  dateTimeFormat = 'DD.MM.YYYY HH:mm';

  _drillPattern;
  constructor(drillPattern) {
    this._drillPattern = { ...drillPattern };
  }

  get avatar() {
    return 'avatars/pattern1-avatar.jpg';
  }
  get subtitle() {
    const field = $gettext('Type: Drill Pattern');
    const value = field;
    const icon = 'apps';
    return { field, value, icon };
  }
  get name() {
    const { name } = this._drillPattern;
    return name || '';
  }
  get user() {
    const { user } = this._drillPattern;
    const field = $gettext('User');
    const caption = {
      value: user,
      noData: $gettext('no data')
    };
    const icon = 'account_circle';
    return {
      field,
      value: user ? caption.value : caption.noData,
      icon
    };
  }
  get createdTime() {
    const { createdAt } = this._drillPattern;
    const field = $gettext('Created at');
    const caption = {
      value: `${datetimehelper.format(createdAt, datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
      noData: $gettext('no data')
    };
    const icon = 'mdi-check-circle';
    return {
      field,
      value: createdAt ? caption.value : caption.noData,
      icon
    };
  }
  get updatedTime() {
    const { updatedAt } = this._drillPattern;
    const field = $gettext('Updated at');
    const caption = {
      value: `${datetimehelper.format(updatedAt, datetimehelper.LOCALE_DATE_TIME_FORMAT)}`,
      noData: $gettext('no data')
    };
    const icon = 'cached';
    return {
      field,
      value: updatedAt ? caption.value : caption.noData,
      icon
    };
  }
  get machineCount() {
    return this._drillPattern.machineAssociations ? this._drillPattern.machineAssociations.length : 0;
  }
}
