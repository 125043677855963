
const filterSurfacesModule = {
  state: {
    units: [],
    selectedRefmodelPaths: [],
    selectedPointCodes: [],
    selectedUnitTypes: [],
    selectedUnitNames: [],
    startDate: null,
    endDate: null,
    isResult: false,
    selectedCellSize: null
  },
  getters: {
    units(state) {
      return state.units;
    },
    selectedRefmodelPaths(state) {
      return state.selectedRefmodelPaths;
    },
    selectedPointCodes(state) {
      return state.selectedPointCodes;
    },
    selectedUnitTypes(state) {
      return state.selectedUnitTypes;
    },
    selectedUnitNames(state) {
      return state.selectedUnitNames;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    isResult(state) {
      return state.isResult;
    },
    selectedCellSize(state) {
      return state.selectedCellSize;
    }
  },
  mutations: {
    setUnits(state, units) {
      state.units = units;
    },
    setSelectedRefmodelPath(state, id) {
      state.selectedRefmodelPaths = id;
    },
    setSelectedUnitTypes(state, types) {
      state.selectedUnitTypes = types;
      state.selectedUnitNames = state.selectedUnitNames.filter(name => {
        let result = true;
        if (state.selectedUnitTypes.length) {
          const unit = state.units.find(u => u.name === name);
          if (!unit || !state.selectedUnitTypes.includes(unit.type)) result = false;
        }
        return result;
      });
    },
    setSelectedPointCodes(state, pointCodes) {
      state.selectedPointCodes = pointCodes;
    },
    setSelectedUnitNames(state, units) {
      state.selectedUnitNames = units;
    },
    setStartDate(state, date) {
      state.startDate = date;
    },
    setEndDate(state, date) {
      state.endDate = date;
    },
    setIsResult(state, isResult) {
      state.isResult = isResult;
    },
    setSelectedCellSize(state, cellSize) {
      state.selectedCellSize = cellSize;
    },
  },
};

export default filterSurfacesModule;