<template>
  <div class="cx-total">
    <div class="cx-total__inner cx-total__left" @mouseover="showLeftTooltip=true" @mouseleave="showLeftTooltip=false">
      <div class="cx-total__inner__upper cx-total__left__upper">
        <span v-for="(value, index) in left" :key="index">
          <span v-if="index !== 0"> / </span>
          {{ value }}
        </span>
        <span class="cx-total__inner__upper__unit">
          <slot name="unit_left"></slot>
        </span>
      </div>
      <div class="cx-total__left__lower cx-total__inner__lower">
        <slot name="left_lower"></slot>
      </div>
    </div>
    <div class="cx-total__inner cx-total__right" @mouseover="showRightTooltip=true" @mouseleave="showRightTooltip=false">
      <div class="cx-total__inner__upper cx-total__right__upper">
        <slot name="right_upper">
          {{ right }}
          <span class="cx-total__inner__upper__unit">
            <slot name="unit_right"></slot>
          </span>
        </slot>
      </div>
      <div class="cx-total__inner__lower cx-total__right__lower">
        <slot name="right_lower"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CxTotal',
  components: {
  },
  props: {
    left: Array,
    right: [Number, String],
  },
  data: () => {
    return {
      showLeftTooltip: false,
      showRightTooltip: false
    };
  },
  computed: {
    leftTooltipTitle() {
      if (!this.left) return '';
      return this.left.join(' / ');
    },
    rightTooltipTitle() {
      if (!this.right) return '';
      return this.right;
    },
  }
};
</script>

<style scoped lang="less">
@import '../../../../common';


  .cx-total {
    background-color: @inputBackground;
    overflow: auto;
    border-radius: @inputBorderRadius;
    .flex(row, space-between, stretch);
  }

  .cx-total__inner__upper {
    font-size: @appFontSize;
  }
  .cx-total__inner__lower {
    color: @textColorDisabled;
    font-size: @appFontSize;
  }

  .cx-total__inner__upper__unit {
    color: @textColorDisabled
  }

  .cx-total__right {
    align-self: flex-end;
    padding: 12px 18px 12px 6px;
  }
  .cx-total__left {
    align-self: flex-end;
    padding: 12px 6px 12px 18px;
  }

  .cx-total__right__lower{
    float: right;
  }


</style>