import { lowerFirst } from 'lodash';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { DrillPileDepthSource } from '@/domain/hole/DrillPileDepthSource.enum';
import { PointDrillPileStatus } from '@/domain/point/PointDrillPileStatus.enum';
import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';
import { AlarmTypeFilterDisplayValue } from '@/domain/awareness/enums/AlarmTypeFilterDisplayValue.enum';
import { DetectedZoneFilterDisplayValue } from '@/domain/awareness//enums/DetectedZoneFilterDisplayValue.enum';
import { AwarenessEventValue } from '@/domain/awareness/enums/AwarenessEventValue.enum';
import { AwarenessEventName } from '@/domain/awareness/enums/AwarenessEventName.enum';
import { AwarenessEventPosition } from '@/domain/awareness/enums/AwarenessEventPosition.enum';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const translationService = (() => {

  let translations: Record<string, string> | null = null;
  const setTranslations = () => {
    translations = {
      [UnitTypes.DozerType]: $gettext('Dozer'),
      [UnitTypes.GraderType]: $gettext('Grader'),
      [UnitTypes.ExcavatorType]: $gettext('Excavator'),
      [UnitTypes.ExcavatorWheeledType]: $gettext('Excavator Wheeled'),
      [UnitTypes.BackhoeType]: $gettext('Backhoe'),
      [UnitTypes.WheelLoaderType]: $gettext('Wheel Loader'),
      [UnitTypes.HaulTruckType]: $gettext('Haul Truck'),
      [UnitTypes.FieldCrewType]: $gettext('Field Crew & iCON Solutions'),
      [UnitTypes.BaseStationType]: $gettext('Base Station'),
      [UnitTypes.DrillerType]: $gettext('Driller'),
      [UnitTypes.PilerType]: $gettext('Piler'),
      [UnitTypes.RollerType]: $gettext('Roller'),
      [UnitTypes.SnowGroomerType]: $gettext('Snow Groomer'),
      [UnitTypes.SlipformPaverType]: $gettext('Slipform Paver'),
      [UnitTypes.PlacerSpreaderType]: $gettext('Placer Spreader'),
      [UnitTypes.CurbAndGutterType]: $gettext('Curb and Gutter'),
      [UnitTypes.MillingType]: $gettext('Milling'),
      [UnitTypes.PaverType]: $gettext('Asphalt Paver'),
      [UnitTypes.TrimmerType]: $gettext('Trimmer'),
      [UnitTypes.CTLSkidSteer]: $gettext('CTL/Skid-Steer'),

      [RefmodelTypes.DefaultRefmodelType]: $gettext('Reference Model'),
      [RefmodelTypes.DrillPattern]: $gettext('Drill Pattern'),
      [RefmodelTypes.HelperFileType]: $gettext('Helper File'),
      [RefmodelTypes.CoordinateFileType]: $gettext('Coordinate File'),
      [RefmodelTypes.TerrainModelType]: $gettext('Terrain Model'),
      [RefmodelTypes.RoadLineType]: $gettext('Road Line'),
      [RefmodelTypes.StringlineModelType]: $gettext('Stringline Model'),
      [RefmodelTypes.VolumeDescriptionModelType]: $gettext('Volume Description Model'),
      [RefmodelTypes.CrossFallModelType]: $gettext('Cross Fall Model'),

      [StabilizationTypes.DrillHole]: $gettext('Drill Hole'),
      [StabilizationTypes.PileHole]: $gettext('Pile Hole'),

      // metadata
      pointId: $gettext('Point Id'),
      pointID: $gettext('Point Id'),
      heightDistance: $gettext('Height Distance'),
      northing: $gettext('Northing'),
      referenceModelName: $gettext('Reference Model'),
      actualTowerHeading: $gettext('Actual Tower Heading'),
      point_code: $gettext('Point Code'),
      pointCode: $gettext('Point Code'),
      comment: $gettext('Comment'),
      wGS84Longitude: $gettext('WGS84 Longitude'),
      conXMachineName: $gettext('ConX Machine Name'),
      centerLineDistance: $gettext('Center Line Distance'),
      logTime: $gettext('Log Time'),
      reference_model_layer: $gettext('Reference Model Layer'),
      referenceModelLayer: $gettext('Reference Model Layer'),
      drillPileDepthSource: $gettext('Drill Pile Depth Source'),
      sideReference: $gettext('Side Reference'),
      drillPileDepth: $gettext('Hole Length'),
      panelSerialNumber: $gettext('Panel Serial Number'),
      heightOffset: $gettext('Height Offset'),
      activeModel: $gettext('Active Model'),
      equipment_type: $gettext('Equipment Type'),
      equipmentType: $gettext('Equipment Type'),
      unitType: $gettext('Unit Type'),
      unit_type: $gettext('Unit Type'),
      sideOffset: $gettext('Side Offset'),
      sideDistance: $gettext('Side Distance'),
      referenceModel: $gettext('Reference Model'),
      coordinate_system: $gettext('Coordinate System'),
      coordinateSystem: $gettext('Coordinate System'),
      cQHeight: $gettext('CQ Height'),
      longitude: $gettext('Longitude'),
      heightOffsetType: $gettext('Height Offset Type'),
      targetHoleAngle: $gettext('Target Hole Angle'),
      station: $gettext('Station'),
      heightReference: $gettext('Height Reference'),
      easting: $gettext('Easting'),
      toolDiameter: $gettext('Tool Diameter'),
      unitAccountUuid: $gettext('Unit Account UUID'),
      time: $gettext('Time'),
      unitName: $gettext('Unit Name'),
      unitUuid: $gettext('Unit Uuid'),
      projectAccountUuid: $gettext('Project Account UUID'),
      unit: $gettext('Unit'),
      unit_account_name: $gettext('Unit Account Name'),
      ellipsoid_height: $gettext('Ellipsoid Height'),
      ellipsoidHeight: $gettext('Ellipsoid Height'),
      wGS84Latitude: $gettext('WGS84 Latitude'),
      latitude: $gettext('Latitude'),
      actualToolAngle: $gettext('Actual Tool Angle'),
      actualToolHeading: $gettext('Actual Tool Heading'),
      drillPileStatus: $gettext('Drill Pile Status'),
      group: $gettext('Group'),
      cq3d: $gettext('CQ3D'),
      point_name: $gettext('Point Name'),
      pointName: $gettext('Point Name'),
      stationingReference: $gettext('Stationing Reference'),
      toolName: $gettext('Tool Name'),
      cq2d: $gettext('CQ2D'),
      tool_point: $gettext('Tool Point'),
      toolPoint: $gettext('Tool Point'),
      reference_model: $gettext('Reference Model'),
      date: $gettext('Date'),
      sequenceNumber: $gettext('Sequence Number'),
      sequence_number: $gettext('Sequence Number'),
      targetHoleHeading: $gettext('Target Hole Heading'),
      gNSSCQ3D: $gettext('GNSSCQ3D'),
      logName: $gettext('Log Name'),
      height: $gettext('Height'),
      cutFill: $gettext('Cut / Fill'),
      geopoint: $gettext('Geopoint'),
      pileHeight: $gettext('Pile Height'),
      deltaHeight: $gettext('Delta Height'),
      job_name: $gettext('Job Name'),

      // drill pile status translations
      [PointDrillPileStatus.Entry]: $gettext('Entry'),
      [PointDrillPileStatus.Start]: $gettext('Start'),
      [PointDrillPileStatus.End]: $gettext('End'),
      [PointDrillPileStatus.Paused]: $gettext('Paused'),
      [PointDrillPileStatus.Failed]: $gettext('Failed'),
      [PointDrillPileStatus.Continue]: $gettext('Continue'),
      [PointDrillPileStatus.Log]: $gettext('Log'),
      [PointDrillPileStatus.Ok]: $gettext('OK'),

      // drill-pile depth source
      [DrillPileDepthSource.Machine]: $gettext('Machine'),
      [DrillPileDepthSource.Manual]: $gettext('Manual'),

      // awareness
      // Alarm Type filter display values:
      [AlarmTypeFilterDisplayValue.TagAlarm]: $gettext('Tag Alarm'),
      [AlarmTypeFilterDisplayValue.VehicleAlarm]: $gettext('Vehicle Alarm'),
      [AlarmTypeFilterDisplayValue.PanicAlarm]: $gettext('Panic Alarm'),
      [AlarmTypeFilterDisplayValue.Obstacle]: $gettext('Obstacle'),
      [AlarmTypeFilterDisplayValue.Overspeed]: $gettext('Overspeed'),
      [AlarmTypeFilterDisplayValue.TagOk]: $gettext('Panic Alarm Cancelled'),
      [AlarmTypeFilterDisplayValue.LowBattery]: $gettext('Low Battery Alarm'),
      [AlarmTypeFilterDisplayValue.CollisionAlarm]: $gettext('Collision Alarm'),
      [AlarmTypeFilterDisplayValue.AvoidanceAlarm]: $gettext('Avoidance Alarm'),
      [AlarmTypeFilterDisplayValue.AvoidanceZone]: $gettext('Avoidance Zone'),
      [AlarmTypeFilterDisplayValue.PersonAlarm]: $gettext('Person Alarm'),
      // Unit Type filter values - see global definition: @/utils/translation.service.js
      [UnitTypes.Vehicle]: $gettext('Vehicle'),
      // Detected Zone filter display values:
      [DetectedZoneFilterDisplayValue.RedZone]: $gettext('Close'),
      [DetectedZoneFilterDisplayValue.YellowZone]: $gettext('Near'),
      [DetectedZoneFilterDisplayValue.GreenZone]: $gettext('Far'),
      [DetectedZoneFilterDisplayValue.CabinZone]: $gettext('Cabin'),
      [DetectedZoneFilterDisplayValue.NotInDangerZone]: $gettext('Not in danger'),
      // Awareness Event values:
      [AwarenessEventValue.AlarmDetected]: $gettext('Alarm Detected'),
      [AwarenessEventValue.AlarmAcknowledged]: $gettext('Alarm Acknowledged'),
      // Awareness Event names:
      [AwarenessEventName.TagAlarm]: $gettext('Tag Alarm'),
      // Awareness Event positions:
      [AwarenessEventPosition.Front]: $gettext('Front'),
      [AwarenessEventPosition.Rear]: $gettext('Rear'),
      [AwarenessEventPosition.Left]: $gettext('Left'),
      [AwarenessEventPosition.Right]: $gettext('Right'),
      [AwarenessEventPosition.FrontLeft]: $gettext('Front left'),
      [AwarenessEventPosition.FrontRight]: $gettext('Front right'),
      [AwarenessEventPosition.RearLeft]: $gettext('Rear left'),
      [AwarenessEventPosition.RearRight]: $gettext('Rear right'),
      [AwarenessEventPosition.Center]: $gettext('Center'),
      [AwarenessEventPosition.UndefinedPosition]: $gettext('Undefined'),

      // symbols
      φ: $gettext('φ'),
      λ: $gettext('λ'),
      h: $gettext('h'),
      N: $gettext('N'),
      E: $gettext('E'),
      H: $gettext('H'),

      // todo: add height offset type enum
    };
  };

  return {
    get: (term: string): string => {
      if (!term) return '';
      if (!translations) return term;

      const translated = translations[term] || translations[lowerFirst(term)] || term;
      return translated;
    },
    setLanguage: (languageCode: string) => {
      const language = languageCode === 'zh-hans' ? 'zh-Hans' : languageCode;
      gettext.current = language;
      setTranslations();
    }
  };
})();

export default translationService;