<template>
  <cx-overlay-controls v-if="hasEarthmovingLicence || hasStabilizationLicence">
    <cx-overlay-controls-group v-if="hasEarthmovingLicence">
      <cx-overlay-controls-item
        v-bind="overlayState.refModel"
        icon="cx_ref_model"
        :title="translations.refModel"
        @toggle="toggleRefModel"/>
      <cx-overlay-controls-item
        v-bind="overlayState.refModelOpacity"
        :title="translations.refModelOpacity"
        icon="cx_transparency"
        @toggle="toggleRefModelOpacity">
        <template #item-legend>
          <cx-transparency-control
            v-if="overlayState.refModelOpacity.active && selectedRefModel"
            :opacity="getRefModelOpacity"
            :title="translations.refModelOpacity"
            @changeOpacity="setRefModelOpacity">
          </cx-transparency-control>
        </template>
      </cx-overlay-controls-item>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group v-if="isCutFillContext && hasEarthmovingLicence">
      <cx-overlay-controls-item
        v-bind="overlayState.surface" icon="map" :title="translations.surface"
        @toggle="toggleSurface"/>
      <cx-overlay-controls-item
        v-bind="overlayState.cutFill" icon="gradient" :title="translations.cutFill"
        @toggle="toggleCutFill"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group v-if="isProgressAnalysisContext && hasEarthmovingLicence">
      <cx-overlay-controls-item
        v-bind="overlayState.progressSurface" icon="gradient" :title="translations.cutFill"
        @toggle="toggleProgressSurface"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.contour" icon="radio_button_checked" :title="translations.contour"
        @toggle="toggleContour"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <template #legend>
        <cx-height-map-legend v-if="isRefModelVisible && isHeightMapActive"></cx-height-map-legend>
        <cx-slope-map-legend v-if="isRefModelVisible && isSlopeMapActive"></cx-slope-map-legend>
      </template>
      <cx-overlay-controls-item
        v-bind="overlayState.heightMap" icon="terrain" :title="translations.heightMap"
        @toggle="toggleHeightMap"/>
      <cx-overlay-controls-item
        v-bind="overlayState.slopeMap" icon="signal_cellular_4_bar"
        :title="translations.slopeMap"
        @toggle="toggleSlopeMap"/>
    </cx-overlay-controls-group>
    <cx-overlay-controls-group>
      <cx-overlay-controls-item
        v-bind="overlayState.asBuilt" icon="cx_as_built" :title="translations.asBuilt"
        @toggle="toggleAsBuilt"/>
    </cx-overlay-controls-group>
  </cx-overlay-controls>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxOverlayControls from '@/app/detailsPanel/common/overlayControls/CxOverlayControls';
import CxOverlayControlsGroup from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsGroup';
import CxOverlayControlsItem from '@/app/detailsPanel/common/overlayControls/CxOverlayControlsItem';
import CxHeightMapLegend from '@/app/detailsPanel/refmodel/CxHeightMapLegend';
import CxSlopeMapLegend from '@/app/detailsPanel/refmodel/CxSlopeMapLegend';
import CxTransparencyControl from './CxTransparencyControl.vue';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';
import { gettext } from '@/translations/gettext.setup';

export default {
  name: 'CxRefModelOverlayControls',
  components: {
    CxHeightMapLegend,
    CxSlopeMapLegend,
    CxOverlayControls,
    CxOverlayControlsGroup,
    CxOverlayControlsItem,
    CxTransparencyControl
  },
  mixins: [CxLicenceMixin],
  props: {
    selectedRefModelUuid: {
      type: String,
    }
  },
  data() {
    return {
      opacity: 1,
      isOpacityVisible: true,
      loading: {
        refModel: false,
        progressSurface: false,
        contour: false,
        heightMap: false,
        slopeMap: false,
        asBuilt: false
      }
    };
  },
  computed: {
    ...mapGetters('detailsRefmodel', [
      'analysisContent', 'overlayControlsEnabled',
      'isRefModelVisible',
      'getRefModelOpacity',
      'isSurfaceActive', 'isSurfaceLoading',
      'isCutFillActive', 'isCutFillLoading',
      'isContourActive', 'isHeightMapActive', 'isSlopeMapActive',
      'isProgressSurfaceActive', 'isProgressSurfaceLoading',
      'isShowPointsActive', 'selectedPointSet', 'selectedRefModel']),
    ...mapGetters('map', ['wouldExceedPerformanceLimit', 'maxLoadablePointsCount', 'refmodelOpacities']),
    overlayState() {
      return {
        refModelOpacity: {
          enabled: true,
          active: this.isOpacityVisible,
          loading: false
        },
        refModel: {
          enabled: true,
          active: this.isRefModelVisible,
          loading: this.loading.refModel
        },
        progressSurface: {
          enabled: this.overlayControlsEnabled,
          active: this.isProgressSurfaceActive,
          loading: this.isProgressSurfaceLoading,
        },
        surface: {
          enabled: !this.isCutFillLoading && this.overlayControlsEnabled,
          active: this.isSurfaceActive,
          loading: this.isSurfaceLoading
        },
        cutFill: {
          enabled: !this.isSurfaceLoading && this.overlayControlsEnabled,
          active: this.isCutFillActive,
          loading: this.isCutFillLoading
        },
        contour: {
          enabled: this.isRefModelVisible,
          active: this.isContourActive,
          loading: this.loading.contour
        },
        heightMap: {
          enabled: this.isRefModelVisible,
          active: this.isHeightMapActive,
          loading: this.loading.heightMap
        },
        slopeMap: {
          enabled: this.isRefModelVisible,
          active: this.isSlopeMapActive,
          loading: this.loading.slopeMap
        },
        asBuilt: {
          enabled: this.isShowPointsActive || this.canLoadPoints,
          active: this.isShowPointsActive,
          loading: this.loading.asBuilt
        }
      };
    },
    isCutFillContext() {
      return this.analysisContent === 'cutfill';
    },
    isProgressAnalysisContext() {
      return this.analysisContent === 'progress';
    },
    canLoadPoints() {
      return this.selectedPointSet ? !this.wouldExceedPerformanceLimit(this.selectedPointSet.count) : false;
    },
    translations() {
      return {
        refModel: this.$gettext('Reference model'),
        refModelOpacity: this.$gettext('Transparency'),
        surface: this.$gettext('Surface'),
        cutFill: this.$gettext('Cut/Fill'),
        contour: this.$gettext('Contour'),
        heightMap: this.$gettext('Height Map'),
        slopeMap: this.$gettext('Slope Map'),
        asBuilt: this.getAsBuiltLabel()
      };
    }
  },
  methods: {
    ...mapActions('detailsRefmodel', [
      'changeReferenceModelActive', 'changeSurfaceActive', 'changeProgressSurfaceActive', 'changeCutFillActive',
      'toggleContourActive', 'toggleHeightMapActive', 'toggleSlopeMapActive', 'changeShowPointsActive', 'changeRefModelOpacity']),
    setRefModelOpacity(opacity) {
      this.opacity = opacity;
      this.changeRefModelOpacity({ opacity });
    },
    toggleRefModelOpacity() {
      this.isOpacityVisible = !this.isOpacityVisible;
    },
    toggleRefModel() {
      this.loading.refModel = true;
      this.changeReferenceModelActive({ isActive: !this.overlayState.refModel.active })
        .then(() => { this.loading.refModel = false; });
    },
    toggleSurface() {
      this.changeSurfaceActive({ isActive: !this.isSurfaceActive });
    },
    toggleProgressSurface() {
      this.loading.progressSurface = true;
      this.changeProgressSurfaceActive({ isActive: !this.overlayState.progressSurface.active })
        .then(() => { this.loading.progressSurface = false; });
    },
    toggleCutFill() {
      this.changeCutFillActive({ isActive: !this.isCutFillActive });
    },
    toggleContour() {
      this.loading.contour = true;
      this.toggleContourActive()
        .then(() => { this.loading.contour = false; });
    },
    toggleHeightMap() {
      this.loading.heightMap = true;
      this.toggleHeightMapActive()
        .then(() => { this.loading.heightMap = false; });
    },
    toggleSlopeMap() {
      this.loading.slopeMap = true;
      this.toggleSlopeMapActive()
        .then(() => { this.loading.slopeMap = false; });
    },
    toggleAsBuilt() {
      this.loading.asBuilt = true;
      this.changeShowPointsActive({ isActive: !this.isShowPointsActive })
        .then(() => { this.loading.asBuilt = false; }, () => { this.loading.asBuilt = false; });
    },
    getPointExcessWarning(count, max) {
      const title = this.$gettext('Unable to load %{ count } points because they exceed the performance limit (%{ max }).');
      return gettext.interpolate(title, { count, max });
    },
    getAsBuiltLabel() {
      if (!this.selectedPointSet || this.canLoadPoints) return this.$gettext('As Built');
      return this.getPointExcessWarning(this.selectedPointSet.count, this.maxLoadablePointsCount);
    },
  },
};
</script>
