const popupStoreModule = {
  state: {
    // id<String>: isOpened<Boolean>
    isOpened: {}
  },
  getters: {
    isOpened: (state) => (id) => {
      return !!state.isOpened[id];
    }
  },
  mutations: {
    changeIsOpened(state, { id, isOpened }) {
      state.isOpened = { ...state.isOpened, [id]: isOpened };
    }
  },
  actions: {
    openPopup(context, id) {
      context.commit('changeIsOpened', { id, isOpened: true });
    },
    closePopup(context, id) {
      context.commit('changeIsOpened', { id, isOpened: false });
    },
    togglePopup(context, id) {
      context.commit('changeIsOpened', { id, isOpened: !context.getters.isOpened(id) });
    }
  }
};
export default popupStoreModule;