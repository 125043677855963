
/**
 * Calculates set differences setA\setB
 * @param {Array} setA set of entities to sutract from
 * @param {Array} setB set of entities to subtract
 */
const subtractSets = (setA, setB) => {
  const pointsFromSetA = setA.map(p => p.uuid);
  const pointsFromSetB = setB.map(p => p.uuid);
  return pointsFromSetA.filter(p => !pointsFromSetB.includes(p));
};

const sethelper = {
  subtractSets
};

export default sethelper;