<template>
  <div id="header">
    <cx-live-mode-timeline v-if="hasAwarenessLicence"/>
    <cx-timeline v-else/>
    <cx-performance-monitor/>
  </div>
</template>

<script>
import CxPerformanceMonitor from '@/app/notifications/CxPerformanceMonitor';
import CxTimeline from '../timeline/CxTimeline';
import CxLiveModeTimeline from '@/app/timeline/CxLiveModeTimeline';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';

export default {
  name: 'CxHeader',
  components: {
    CxPerformanceMonitor,
    CxTimeline,
    CxLiveModeTimeline
  },
  mixins: [CxLicenceMixin, CxWaffleFlagMixin],
};

</script>

<style lang="less">
@import '../../common';

#header{
  .size(calc(100vw - @filterMenuWidth), @headerHeight);
  .flex(row, space-between, center);
  z-index: @headerZI;
  position: absolute;
  margin-left: @filterMenuWidth;
  background-color: @overlayControlBackgroundColorInactive;
}

.header__rangePicker {
  margin-left: 15px;
}

</style>