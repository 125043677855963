import { StabilizationTypes } from '@/domain/hole/StabilizationTypes.enum';

const detailsHoleModulePlugin = (store) => {
  store.subscribeAction(({ type, payload }) => {
    switch (type) {
      case 'shell/handleEntitySelection': {
        const { newValue } = payload;
        if (newValue && Object.values(StabilizationTypes).includes(newValue.entityType)) {
          store.dispatch('detailsHole/handleSelection', newValue.uuid, { root: true });
          break;
        }
      }
    }
  });
};

export default detailsHoleModulePlugin;