export enum AwarenessMessageStatusIcon {
  Unsent = 'mdi-email-outline',
  Sending = 'mdi-email-outline',
  Sent = 'mdi-email-outline',
  Created = 'mdi-dots-horizontal',
  Acknowledged = 'done_all',
  SendingError = 'mdi-block-helper',
  ReceptionError = 'mdi-block-helper',
  AcknowledgementError = 'mdi-block-helper',
  PollingError = 'mdi-block-helper'
}