import _ from 'lodash';
import { formatISO, subHours } from 'date-fns';
import api from '@/http/api';
import { createFilter } from '@/domain/filters/filterModelFactory';
import { FilterModelNames } from '@/domain/filters/FilterModelNames.enum';

const POLLINTERVAL = 1000;

const filterAsBuildsByRefModel = async (projectUUid, startTime, stopTime, refModelId) => {
  const referenceModelFilter = createFilter(FilterModelNames.RefmodelUuid, [refModelId]);

  const startTime8601 = formatISO(subHours(startTime, 1));
  const stopimeISO8601 = formatISO(stopTime);

  const pointSet = await api.filterMeasurePoints(
    projectUUid,
    startTime8601,
    stopimeISO8601,
    [referenceModelFilter],
    true,
    ['all']
  );
  return pointSet;
};

/**
 * Polls every X milliseconds for progress update on given tasks
 * When progress is 1 (100%) the function is completed and interval is terminated
 * @param {Object} data pyaload of last poll that we will use to ask for update
 * @param {Function} progressUpdatedCallback callback to update on the progress of the task
 * @returns {Object} result { data, error }. When function completes data contains mapped volume calculations if there was no error.
 * If any error occures data will be empty and promise will be rejected with error
 */
const pollForProgress = async (projectUUid, data, progressUpdatedCallback, cancelToken) => {

  const pollPromise = new Promise((res, rej) => {
    let result = {};
    const pollFunction = async () => {
      if (cancelToken.canceled) { res(); return; }
      try {
        result = await api.volumeChartProgress(projectUUid, data);
        if (progressUpdatedCallback) progressUpdatedCallback(result.progress);
        if (result.progress === 1) res(result);
        else setTimeout(() => { pollFunction(); }, POLLINTERVAL);
      } catch (exception) {
        result.error = exception;
        rej(result);
      }
    };
    setTimeout(() => { pollFunction(); }, POLLINTERVAL);
  });
  return pollPromise;
};


/**
 * Will calculate volumes for the given reference model, initial model and timestamps
 * @param {String} projectUUid uuid of the project
 * @param {String} refmodelUuid uuid of refmodel for calculations
 * @param {String} initialModelUuid optional
 * @param {Array} intervals array of Date objects, that describes points in time we want to do calculations.
 * @param {Date} projectStartTime start time of a project. Must be specified if initialModelUuid was set
 * @param {String} measureUnit optional. By default {'square_meter', 'cubic_meter'}
 * @param {Function} progressUpdatedCallback will be called every X time to update progress on calculations
 * @param {CancelToken} cancelToken
 */
const calculate = async (
  projectUUid,
  refmodelUuid,
  initialModelUuid,
  intervals,
  projectStartTime,
  measurementSystem,
  progressUpdatedCallback,
  cancelToken
) => {

  const calculateInitialVolumes = !!initialModelUuid;
  const requestIntervals = [...intervals.map(i => formatISO(i))];
  // if we provide initial model uuid - we add project start time
  // to the interval list to get back calculation for project start times
  // which will be initial volume calculations - estimation of work that needs to be done
  if (calculateInitialVolumes) {
    requestIntervals.unshift(formatISO(projectStartTime));
  }

  if (cancelToken.canceled) return null;
  let response = await api.volumeChartAsync(projectUUid, refmodelUuid, initialModelUuid, requestIntervals, measurementSystem);
  // if the response is not complete we poll for updates
  // every X time
  if (response.progress !== 1) {
    response = await pollForProgress(projectUUid, response.data, progressUpdatedCallback, cancelToken);
  }
  if (cancelToken.canceled) return null;
  if (!response || response.error) throw new Error('error occured during volume caluclation');

  const volumeCalculations = response.data;
  let result = null;
  // if there is no data ar all in any of result, return null;
  // sort resutls by timestamp
  let calculations = volumeCalculations;
  // initial volume is first non-zero volume in the result
  const initialVolume = { ...calculations.find(c => c.cutVolume && c.fillVolume && !c.error) };
  if (calculateInitialVolumes) {
    calculations = _.tail(calculations);
  }
  result = {
    calculations,
    initialVolume
  };
  return result;
};


const calcService = { calculate, filterAsBuildsByRefModel };
export default calcService;
