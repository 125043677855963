const getActiveItem = (collection, currentActiveItem, keyPressed) => {
  let index;
  if (!collection) return null;
  const collectionLength = collection.length;
  const currentActiveItemIndex = collection.indexOf(currentActiveItem);

  switch (keyPressed) {
    case 'ArrowUp': {
      if (currentActiveItemIndex < 0) {
        index = collectionLength - 1;
      } else {
        index = (currentActiveItemIndex - 1) < 0 ? collectionLength - 1 : currentActiveItemIndex - 1;
      }
      break;
    }
    case 'ArrowDown': {
      if (currentActiveItemIndex < 0) {
        index = 0;
      } else {
        index = (currentActiveItemIndex + 1) > collectionLength ? 0 : currentActiveItemIndex + 1;
      }
      break;
    }
    default: {
      break;
    }
  }
  return collection[index];
};

const listnavigationhelper = {
  getActiveItem
};

export default listnavigationhelper;
