import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';
import { HoleSet } from '@/domain/holeSet/HoleSet';
import { Hole } from '@/domain/hole/Hole';
import { OriginTimeRange } from '@/domain/OriginTimeRange';
import { FilterModel } from '@/domain/filters/FilterModel';
import { gettext } from '@/translations/gettext.setup';

const { $ngettext } = gettext;

const createHoleSet = ({
  uuid,
  name = null,
  holes,
  originFilters,
  originTimeRange
}: {
  uuid: string,
  name: string | null,
  holes: Hole[],
  originFilters: FilterModel[],
  originTimeRange: OriginTimeRange
}): HoleSet => {
  const defaultName = $ngettext('hole', 'holes', holes.length);
  const holeSet = {
    entityType: DrillHoleSetType,
    uuid,
    name: name || `${holes.length} ${defaultName}`,
    drillHoles: [...holes],
    count: holes.length,
    originFilters,
    originTimeRange
  };
  return holeSet;
};

export { createHoleSet };
