export enum AvailableLanguages {
  'cs' = 'Čeština',
  'da' = 'Dansk',
  'de' = 'Deutsch',
  'en' = 'English',
  'es-419' = 'Español',
  'fi' = 'Suomi',
  'fr' = 'Français',
  'it' = 'Italiano',
  'ja'= '日本語',
  'hu' = 'Magyar',
  'nl' = 'Nederlands',
  'no' = 'Norsk',
  'pl' = 'Polski',
  'pt-br' = 'Português',
  'sv' = 'Svenska',
  'ru' = 'Русский',
  'zh-Hans' = '中文',
  'ko' = '한국어'
}