<template>
  <div id="cx-loading-screen" :class="{ projectReady: appProjectReady }">
    <div id="cx-loading-spinner" v-show="!appProjectReady">
      <img :src="require('../../public/conx-logo-splash.png')"/>
      <cx-spinner :size="30" :width="3"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CxSpinner from '@/components/CxSpinner.vue';

export default {
  components: {
    CxSpinner
  },
  computed: {
    ...mapGetters('app', ['appProjectReady']),
  },
};
</script>

<style lang="less">
@import '../common';

#cx-loading-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  //background: #131519 url('../../public/conx-splash5.jpg') center center no-repeat;
  background-size: cover;
  background-color: @bgColor3;
  z-index: @cxLoadingScreenZI;
  font-size: 64px;
  // hide loading screen when project is ready
  &.projectReady {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.6s, opacity 0.6s ease-out;
  }

  #cx-loading-spinner {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 45%);
    color: @textColor;
    align-items: center;

    img {
      opacity: 0.9;
      width: 100px;
    }

    #ub-app-spinner{
      margin-top: 18px;
      margin-right: 2px;
    }
  }

}

</style>
