import FilterEntityViewModel from '../filterEntityViewModel';
import { withFullPath } from '@/domain/entityMixins';

export default class FilterRefModelViewModel extends FilterEntityViewModel {

  _entityType;
  constructor(entity, locale) {
    super(withFullPath(entity, 70), locale);
    this._entityType = entity.entityType;
    this._isChipSelected = false;
    this._isResultVisible = true;
    this._isResultSelected = false;
  }

  get isResultSelectable() {
    return !this._entity.exceedsLimits;
  }

  get tooltip() {
    return this._entity.fullPath;
  }

  get subtitle() {
    return this._entity.fullPath;
  }

  get isChipSelected() {
    return this._isChipSelected;
  }

  set isChipSelected(chipSelectionState) {
    this._isChipSelected = chipSelectionState;
  }

  get isResultWarning() {
    return this._entity.exceedsLimits;
  }
}