import pointApi from './point.api';
import holeApi from './hole.api';
import unitsApi from './unit.api';
import refmodelApi from './referenceModel/refmodel.api';
import calculationApi from './calculation.api';
import projectApi from './project.api';
import accountApi from './account.api';
import histogramApi from './histogram.api';
import awarenessEventApi from './awareness/awarenessEvent.api';
import awarenessKpiApi from './awareness/awarenessKpi.api';
import { surfacelogApi } from '@/http/surfacelog.api';

const api = {
  ...pointApi,
  ...unitsApi,
  ...refmodelApi,
  ...calculationApi,
  ...projectApi,
  ...accountApi,
  ...histogramApi,
  ...holeApi,
  ...awarenessEventApi,
  ...awarenessKpiApi,
  ...surfacelogApi
};

export default api;