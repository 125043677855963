<template>
  <cx-button
    square
    icon="close"
    :disabled="disabled"
    :iconSize="16"
    :style="{ height: height + 'px' }"
    @click="onReset"
    variantSecondary
    class="variant-reset"/>
</template>

<script>
import CxButton from '@/components/CxButton';

export default {
  name: 'CxResetButton',
  components: {
    CxButton,
  },
  props: {
    disabled: { type: Boolean },
    height: String,
    onReset: { type: Function, default: () => {} }
  }
};
</script>

<style lang="less">
@import '../common';

@resetColor: @inputBackground;

.cx-button.variant-reset {
    &:not(.flat) {
      background-color: @resetColor;
    }
    &[disabled]:not(.flat) {
      background-color: fadeout(@resetColor, 10%);
    }
    &:hover:not([disabled]):not(.cx-button--loading):not(.flat) {
      background-color: lighten(@resetColor, 8%) !important;
    }
  }
</style>
