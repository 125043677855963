/**
 * Position of awareness event.
 */
export enum AwarenessEventPosition {
  Front = 'Front',
  Rear = 'Rear',
  Left = 'Left',
  Right = 'Right',
  FrontLeft = 'Front left',
  FrontRight = 'Front right',
  RearLeft = 'Rear left',
  RearRight = 'Rear right',
  Center = 'Center',
  UndefinedPosition = 'Undefined'
}