import _ from 'lodash';
import { toDate, differenceInHours } from 'date-fns';

const calcHalfBar = barWidht => barWidht / 2.0;
const calcX1 = (scaleX, date, barWidht) => { return scaleX(date) - barWidht / 2.0; };
const calcX2 = (scaleX, date, barWidht) => { return scaleX(date) + barWidht / 2.0; };
const clcXMiddle = (scaleX, date) => { return scaleX(date); };
const calcY = (scaleY, value, margin) => scaleY(value) - margin;

const closestDate = (current, chartData) => {
  const dateDeltas = chartData[1].map((d) => {
    return {
      d,
      delta: Math.abs(differenceInHours(current, toDate(d.data.date)))
    };
  });
  return _.minBy(dateDeltas, d => d.delta);
};

const calc = {
  calcHalfBar,
  calcX1,
  calcX2,
  calcY,
  clcXMiddle,
  closestDate
};
export default calc;