<template>
  <cx-popup
    v-if="isOpened"
    :isOpened="isOpened"
    @update:isOpened="(value) => $emit('update:isOpened', value)"
    :offsetRatio="{ y: 0.5, x: 0.5}">
    <div class="cx-alert">
      <div class="cx-alert-title">
        <div class="cx-alert-title-title">{{ title }}</div>
        <div class="cx-alert-title-close" v-if="canBeClosed">
          <cx-button flat icon="close" :iconSize="14" @click="close"/>
        </div>
      </div>
      <div class="cx-alert-content">
        <div class="cx-alert-subtitle" v-if="subtitle">
          <cx-icon icon="mdi-alert-circle" :size="14"/>
          <span>{{ subtitle }}</span>
        </div>
        <div class="cx-alert-message" :class="{'cx-alert-message--error': msg.messageType === 'error' }" v-for="(msg, index) in messages" :key="index">
          <cx-icon :icon="msg.icon" :size="14"/>
          <span v-html="msg.message"></span>
        </div>
      </div>
    </div>
  </cx-popup>
</template>

<script>
import CxPopup from '@/components/CxPopup';
import CxButton from '@/components/CxButton';
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxAlert',
  components: {
    CxPopup,
    CxButton,
    CxIcon
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String
    },
    messages: {
      type: Array // of AlertMessages
    },
    canBeClosed: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:isOpened'],
  methods: {
    close() {
      this.$emit('update:isOpened', false);
    }
  }
};


</script>

<style lang="less">
@import '../../common';


.cx-alert {
  padding-bottom: 15px;

  .cx-icon {
    color: @textColorLowlight;
  }

  .cx-alert-title {
    border-radius: 10px 10px 0px 0px;
    background-color: @inputBackground;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    .cx-alert-title-title {
      padding: 20px;
      flex: 1 1 auto;
    }
    .cx-alert-title-close {
      flex: 0 0 30px;
      padding: 20px;
    }
  }

  .cx-alert-content {
    min-width: 400px;
    max-width: 500px;
    font-size: 12px;
    padding: 10px 20px;
    .cx-alert-subtitle {
      display: flex;
      padding-top: 10px;
      margin: 0px 0px 20px 0px;
      span {
        padding-left: 8px;
      }
    }
    .cx-alert-message {
      display: flex;
      align-items: center;
      background-color: @baseBackgroundColor;
      padding: 15px;
      margin-bottom: 20px;
      span {
        padding-left: 8px;
      }
      a {
        text-decoration: none !important;
        color: @linkColor
      }
      border-radius: 8px;
    }
    .cx-alert-message--error {
      .cx-icon{ i { color: @highlightRedColor } }
      color: @highlightRedColor;
      background-color: @errorBgColor;
    }
  }

}

</style>