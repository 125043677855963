<template>
  <div class="cx-point-grid">
    <ag-grid-vue
      class="ag-theme-balham"
      :columnDefs="columnDefs"
      :rowData="rowData"
      editType="fullRow"
      rowSelection="single">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { defineField } from '@/viewModel/fieldFactory';
import { createColumnDefinitions } from './cxPointGrid.viewModel';
import _ from 'lodash';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/agGridMaterialFont.css';

export default {
  name: 'CxPointGrid',
  components: {
    AgGridVue,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      columnDefs: null,
    };
  },
  computed: {
    rowData() {
      if (!this.data) return null;
      return this.data;
    },
  },
  beforeMount() {
    const fieldDefinition = createColumnDefinitions();
    const fields = fieldDefinition.map(d => defineField(d.field));
    const fieldDefs = _.zip(fieldDefinition, fields).map(z => { return { ...z[0], ...z[1] }; });
    this.fieldDefs = fieldDefs;
    this.columnDefs = fieldDefs.map(d => {
      let unit = '';
      if (d.unit) unit = `[${d.unit}]`;
      const headerName = `${d.fieldName} ${unit}`;
      return {
        field: d.field,
        headerName,
        resizable: true,
        valueFormatter: d.valueFormatter,
        suppressMovable: true,
        sortable: true,
        comparator: d.comparator
      };
    });
  },
};


</script>

<style lang="less">
@import '../../../../components/agGrid/cx-ag-grid.less';

.cx-point-grid {
  width: 100%;
  height: 100%;
}
</style>