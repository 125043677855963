<template>
  <div class="cx-multi-message" v-show="!minimized">
    <div class="cx-multi-message__header">
      <div class="cx-multi-message-title">
        <div class="cx-multi-message-title__text" v-if="!showClosePrompt">
          <span>{{ translations.titleText }}</span>
        </div>
        <div class="cx-multi-message-title__text" v-if="showClosePrompt">
          <cx-icon icon="mdi-help" :size="20"/>
          <span>{{ translations.titleWarning }}</span>
        </div>
        <div
          class="cx-multi-message-title__live-mode-warning"
          v-if="!showClosePrompt"
          v-cx-tooltip="liveWarningTooltip"
          @mouseenter="showLiveWarningTooltip=true"
          @mouseleave="showLiveWarningTooltip=false">
          <cx-icon v-if="!isLive" icon="warning" :size="21"/>
        </div>
      </div>
      <cx-button flat icon="close" :iconSize="15" @click="$emit('close')"/>
    </div>
    <div class="cx-multi-message__content" v-if="!showClosePrompt">
      <cx-text-area
        :disabled="!canType"
        :placeholder="translations.messagePlaceholder"
        :validationRules="validationRules"
        immediatelyShowErrors
        :onValidated="(isValid) => validateMessage(isValid)"
        v-model="messageForm"/>
      <div class="cx-multi-message-machine-list cxu-mt-3">
        <cx-input
          class="cx-multi-message-search"
          :placeholder="translations.searchPlaceholder"
          v-model="searchUnits"
          :disabled="!canSearch"
          appendIcon="mdi-magnify"
          :appendIconSize="17"/>
        <cx-list
          multiselect
          v-model:selectedItems="selectedUnits"
          :disabled="!canType"
          :items="unitList"
          :header="translations.selectAll">
          <template #default="{ item, selected, disabled}">
            <cx-list-item
              :item="item"
              :selected="selected"
              :disabled="disabled">
              <template #before-content>
                <cx-icon
                  :size="15"
                  class="cx-multi-message-status-icon"
                  :icon="item.icon"/>
              </template>
              <template #after-content>
                <div
                  class="cx-multi-message-status-tooltip"
                  v-cx-tooltip="getMessageStatusTooltip(item.id)"
                  @mouseenter="$set(messageStatusTooltips, item.id, true)"
                  @mouseleave="$set(messageStatusTooltips, item.id, false)">
                  <cx-spinner
                    v-if="isAwaiting(item.id)"
                    :width="1"
                    :size="15"/>
                  <cx-icon
                    v-if="isAcknowleged(item.id)"
                    :size="20"
                    class="cx-multi-message-status-icon"
                    :icon="acknowledgmentIcon"/>
                </div>
              </template>
            </cx-list-item>
          </template>
        </cx-list>
      </div>
    </div>
    <div class="cx-multi-message__content" v-if="showClosePrompt">
      <span class="cx-multi-message-prompt-text">{{ translations.contentWarning }}</span>
    </div>
    <div class="cx-multi-message__actions">
      <cx-button
        v-if="!showClosePrompt && !areAllMessagesAcknowledged"
        :loading="!canType"
        :disabled="!canSendMessage"
        variantPrimary
        @click="sendMessages">
        {{ translations.sendButtonText }}
      </cx-button>
      <cx-button
        v-if="showClosePrompt"
        variantSecondary
        @click="$emit('cancel')">
        {{ translations.cancelButtonText }}
      </cx-button>
      <cx-button @click="$emit('close')" variantSecondary>{{ translations.closeButtonText }}</cx-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import messageApi from '@/http/awareness/awarenessMessage.api';
import { withIcon } from '@/domain/entityMixins';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { AwarenessMessageStatusIcon } from '@/domain/awareness/enums/AwarenessMessageStatusIcon.enum';
import { AwarenessMessageStatus } from '@/domain/awareness/enums/AwarenessMessageStatus.enum';
import CxButton from '@/components/CxButton';
import CxTextArea from '@/components/CxTextArea';
import CxList from '@/components/list/CxList';
import CxListItem from '@/components/list/CxListItem';
import CxIcon from '@/components/CxIcon';
import CxSpinner from '@/components/CxSpinner';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';
import CxInput from '@/components/CxInput';
import validationRules from '@/components/validationRules';

const { maxLength } = validationRules();

const updateProjectKey = (target, objKey, objValue) => {
  target[objKey] = objValue;
};

export default {
  name: 'CxMultiMessage',
  components: {
    CxInput,
    CxSpinner,
    CxIcon,
    CxList,
    CxListItem,
    CxButton,
    CxTextArea
  },
  props: {
    minimized: {
      type: Boolean,
      default: false
    },
    showClosePrompt: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'cancel', 'send', 'acknowledge'],
  mixins: [CxWaffleFlagMixin],
  data() {
    return {
      searchUnits: '',
      abortController: null,
      message: '',
      isMessageValid: false,
      showLiveWarningTooltip: false,
      messageStatusTooltips: {},
      selectedUnits: [],
      messageStatuses: {},
      validationRules: {
        modelValue: {
          $autoDirty: true,
          rule: maxLength(250)
        }
      }
    };
  },
  computed: {
    ...mapGetters(['projectUuid']),
    ...mapGetters('app', ['allUnits']),
    ...mapGetters('map', ['isLive']),
    acknowledgmentIcon() {
      return AwarenessMessageStatusIcon[AwarenessMessageStatus.Acknowledged];
    },
    shouldFilterUnitList() {
      return this.searchUnits.length < 3;
    },
    selectedUnitIds() {
      return this.selectedUnits.map(u => u.uuid);
    },
    canType() {
      return !this.selectedUnitIds.some(u => {
        return this.isAcknowleged(u) || this.isAwaiting(u);
      });
    },
    canSendMessage() {
      const isMessageNotEmpty = this.message.length > 0;
      const isSomeUnitSelected = !!this.selectedUnits.length;
      return isMessageNotEmpty && this.isMessageValid && isSomeUnitSelected;
    },
    areAllMessagesAcknowledged() {
      return !this.selectedUnitIds.some(u => {
        return !this.isAcknowleged(u);
      });
    },
    canSearch() {
      const messages = Object.keys(this.messageStatuses);
      return !messages.some(key => this.messageStatuses[key] !== AwarenessMessageStatus.Unsent);
    },
    unitList() {
      return this.allUnits
        .filter(this.filterUnitsByName)
        .filter(this.filterUnitsByType)
        .map(u => ({
          ...withIcon(u),
          id: u.uuid,
          title: u.name
        }));
    },
    messageForm: {
      get() {
        return this.message;
      },
      set(message) {
        this.message = message;
      }
    },
    liveWarningTooltip() {
      return {
        text: this.translations.liveWarningText,
        show: this.showLiveWarningTooltip,
        position: 'top-left',
        maxWidth: 400,
        offsetLeft: 85
      };
    },
    translations() {
      return {
        selectAll: this.$gettext('Select all'),
        liveWarningText: this.$gettext('Unit locations are outdated'),
        sendButtonText: this.$gettext('Send'),
        cancelButtonText: this.$gettext('Cancel'),
        closeButtonText: this.$gettext('Close'),
        titleText: this.$gettext('Messages'),
        searchPlaceholder: this.$gettext('Search'),
        messagePlaceholder: this.$gettext('Type your message'),
        titleWarning: this.$gettext('Are you sure you want to close this popup?'),
        contentWarning: this.$gettext('Message status will be lost.'),
        [AwarenessMessageStatus.Sending]: this.$gettext('Sending'),
        [AwarenessMessageStatus.Sent]: this.$gettext('Waiting for reception'),
        [AwarenessMessageStatus.Received]: this.$gettext('Waiting for acknowledgement'),
        [AwarenessMessageStatus.Acknowledged]: this.$gettext('Message has been acknowledged'),
        [AwarenessMessageStatus.SendingError]: this.$gettext('Sending failed'),
        [AwarenessMessageStatus.ReceptionError]: this.$gettext('Sending failed'),
        [AwarenessMessageStatus.AcknowledgementError]: this.$gettext('Sending failed')
      };
    }
  },
  methods: {
    ...mapActions('popup', ['closePopup']),
    filterUnitsByName(u) {
      if (this.shouldFilterUnitList) return true;
      return u.name.toLowerCase().includes(this.searchUnits.toLowerCase());
    },
    filterUnitsByType(u) {
      const unmessagableTypes = [UnitTypes.Vehicle, UnitTypes.BaseStationType, UnitTypes.FieldCrewType];
      return !unmessagableTypes.includes(u.entityType);
    },
    isUnitListed(unit) {
      return this.unitList.includes(unit);
    },
    isAcknowleged(unitId) {
      return this.messageStatuses[unitId] === AwarenessMessageStatus.Acknowledged;
    },
    isAwaiting(unitId) {
      const isAwaitingSending = this.messageStatuses[unitId] === AwarenessMessageStatus.Sending;
      const isAwaitingReception = this.messageStatuses[unitId] === AwarenessMessageStatus.Sent;
      const isAwaitingAcknowledged = this.messageStatuses[unitId] === AwarenessMessageStatus.Received;
      return isAwaitingSending || isAwaitingReception || isAwaitingAcknowledged;
    },
    getMessageStatusTooltip(unitId) {
      const messageStatus = this.messageStatuses[unitId];
      if (messageStatus && this.translations[messageStatus]) {
        return {
          text: this.translations[messageStatus],
          show: this.messageStatusTooltips[unitId],
          position: 'top-left',
          maxWidth: 400,
          offsetLeft: 85
        };
      }
      return {};
    },
    async sendMessages() {
      this.$emit('send');
      this.selectedUnitIds.forEach(u => {
        updateProjectKey(this.messageStatuses, u, AwarenessMessageStatus.Sending);
      });
      const sentMessages = await messageApi.sendMessage(this.projectUuid, this.selectedUnitIds, this.messageForm);
      try {
        sentMessages.forEach(m => {
          updateProjectKey(this.messageStatuses, m.unitId, AwarenessMessageStatus.Sent);
          this.confirmMessageStatuses(m);
        });
      } catch (error) {
        sentMessages.forEach(m => {
          updateProjectKey(this.messageStatuses, m.unitId, AwarenessMessageStatus.SendingError);
        });
      }
    },
    async confirmMessageReception(message, abortController) {
      try {
        const isReceived = await messageApi.waitForMessageStatus(message.messageId, AwarenessMessageStatus.Received, abortController);
        if (isReceived) updateProjectKey(this.messageStatuses, message.unitId, AwarenessMessageStatus.Received);
      } catch (error) {
        updateProjectKey(this.messageStatuses, message.unitId, AwarenessMessageStatus.ReceptionError);
      }
    },
    async confirmMessageAcknowledgment(message, abortController) {
      try {
        const isAcknowledged = await messageApi.waitForMessageStatus(message.messageId, AwarenessMessageStatus.Acknowledged, abortController);
        if (isAcknowledged) updateProjectKey(this.messageStatuses, message.unitId, AwarenessMessageStatus.Acknowledged);
      } catch (error) {
        updateProjectKey(this.messageStatuses, message.unitId, AwarenessMessageStatus.AcknowledgementError);
      }
    },
    async confirmMessageStatuses(message) {
      this.abortController = new AbortController();
      await this.confirmMessageReception(message, this.abortController);
      await this.confirmMessageAcknowledgment(message, this.abortController);
      this.$emit('acknowledge');
    },
    initializeMessageStatuses() {
      this.unitList.forEach(u => {
        updateProjectKey(this.messageStatuses, u.id, AwarenessMessageStatus.Unsent);
      });
    },
    finalizeMessagePolling() {
      if (this.abortController) this.abortController.abort();
    },
    validateMessage(isValid) {
      this.isMessageValid = isValid;
    },
  },
  created() {
    this.initializeMessageStatuses();
  },
  unmounted() {
    this.finalizeMessagePolling();
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-multi-message {
  .cx-multi-message__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cx-multi-message-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 17px;

      .cx-multi-message-title__text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .cx-icon {
          display: flex;
          justify-content: center;
          min-height: 40px;
          min-width: 40px;
          color: #252a35;
          background-color: @textColorHighlight;
          margin-right: 12px;
          border: 1px solid transparent;
          border-radius: 50%;
        }

        span {
          font-size: 22px;
        }
      }

      .cx-multi-message-title__live-mode-warning {
        margin-left: 12px;
        color: @warningColor;
      }
    }
  }

  .cx-multi-message__content {
    height: 100%;
    border-radius: @inputBorderRadius;
    padding: 5px;
    font-size: @appFontSize;
    color: @textColor;

    .cx-text-area {
      max-height: 100px;
    }

    .cx-multi-message-prompt-text {
      padding-left: 65px;
    }

    .cx-multi-message-machine-list {
      height: 222px;

      .cx-multi-message-search {
        margin-bottom: 15px;
      }

      .cx-multi-message-status-icon {
        color: @textColorLowlight;
      }

      .cx-multi-message-status-tooltip {
        pointer-events: all;
      }
    }
  }

  .cx-multi-message__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
  }
}
</style>