<template>
  <div class="cx-menu-button" v-click-outside="hideDropdown" :disabled="disabled || null" :class="{'flat': flat}">
    <cx-button
      square
      class="cx-menu-button__button square"
      variantSecondary
      :icon="icon"
      @click="handleButtonClick"
      :loading="loading"
      :disabled="disabled">
      <span v-if="text">{{ text }}</span>
      <template #after-content><cx-icon v-if="displayDottIcon" icon="brightness_1" class="dott_icon" :size="9"/></template>
    </cx-button>
    <cx-menu
      label="selector label"
      :collection="items"
      nameField="name"
      :isVisible="isMenuOpened"
      @click="handleMenuClick"
      :class="classValue"
      :menuStyles="menuStyles"/>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';
import CxMenu from './CxMenu';
import CxIcon from '@/components/CxIcon';
/**
 * A simple button component with ability to display icon, tooltip
 * and exposing a slot for further extensibility
 * emits click event
 */
export default {
  name: 'CxMenuButton',
  components: {
    CxButton,
    CxMenu,
    CxIcon
  },
  data() {
    return {
      isMenuOpened: false,
    };
  },
  props: {
    disabled: Boolean,
    text: String,
    items: Array,
    tooltip: String,
    loading: {
      type: Boolean,
      default: false
    },
    menuPosition: {
      type: String,
      default: 'bottom' // or 'top'
    },
    menuHorizontalPosition: {
      type: String,
      default: 'right' // or 'left'
    },
    displayDottIcon: {
      type: Boolean,
      default: true
    },
    icon: String,
    className: [String, Object],
    flat: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    }
  },
  emits: ['click'],
  computed: {
    menuStyles() {
      return {
        width: this.width ? `${this.width}px` : 'auto'
      };
    },
    classValue() {
      const result = {};
      result[this.menuPosition] = true;
      result[this.menuHorizontalPosition] = true;
      return result;
    }
  },
  methods: {
    handleButtonClick() {
      if (this.isDisabled) return;
      this.isMenuOpened = !this.isMenuOpened;
    },
    handleMenuClick(item) {
      if (this.isDisabled) return;
      this.hideDropdown();
      this.$emit('click', item);
      // todo: show progress bar on the button??
    },
    hideDropdown() {
      this.isMenuOpened = false;
    },

  }
};
</script>

<style lang="less">
@import '../../common';

.cx-menu-button {
  position: relative;

  .cx-menu {
    position: absolute;
    .size(auto, auto);
    top: 40px;
    font-size: @appFontSize;
  }

  .cx-menu.top {
    top: -115px;
  }
  .cx-menu.left{
    right: 0px;
  }

  &.flat {
    .cx-button {
      background-color: inherit;
    }
  }
  .cx-button {
     i { font-size: 15px; }

    .dott_icon {
        display: flex;
        i { font-size: @microFontSize; }
        margin-left: 5px;
        vertical-align: text-top;
    }
  }

  &:hover:not([disabled]) {
    .dott_icon {
      color: @textColorHighlight;
    }
  }
}

</style>