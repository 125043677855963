import storage from '../utils/localStorage';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const baseLayerKey:string = 'cx:baseLayer';

const userSettingsService = {
  /**
   * Stores the base layer
   */
  storeBaseLayer: (selectedImagery:string):void => {
    try {
      storage.storeItem(baseLayerKey, selectedImagery);
    } catch {
      throw new Error($gettext('Could not store base layer. Local storage is full or disabled.'));
    }
  },
  /**
   * Restores the base layer
   */
  restoreBaseLayer: ():string => {
    try {
      return storage.restoreItem(baseLayerKey);
    } catch {
      throw new Error($gettext('Could not restore base layer. Local storage is full or disabled.'));
    }
  }
};

export default userSettingsService;