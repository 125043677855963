import _ from 'lodash';
import * as Cesium from 'cesium';
import { styles } from './entityPicking/pickedEntity';

const unitsStore = {
  state: {
    dataSource: null, // the CZML datasource containing units
  },
  actions: {
    /**
     * Load units as a CzmlDataSource using a czml document.
     * @param context
     * @param czmlData - A CZML document containing unit data.
     * @returns {Promise<void>}
     */
    async loadAllUnitsFromCZML(context, czmlData) {
      const { viewer } = context.self.rootState;
      const czml = JSON.parse(czmlData);
      const czmlDataSource = await Cesium.CzmlDataSource.load(czml);
      const dataSource = await viewer.dataSources.add(czmlDataSource);
      context.self.handleUnitCZMLLoaded(dataSource);
      context.dispatch('hideUnits', { hide: context.self.getUnitUUIDs() });
    },
    /**
     * Load units' location history for provided ids using the CZML endpoint from configuration.
     * @param context
     * @param unitIds - A list of unit uuids.
     * @returns {Promise<void>}
     */
    async loadUnitLocationHistory(context, { unitLocationsCZML }) {
      const { dataSource } = context.self.state;
      await unitLocationsCZML.map(lh => dataSource.process(lh));
    },
    async lookAtUnit(context, unit) {
      const { viewer } = context.self.rootState;
      if (!unit || !unit.lat || !unit.lon || !unit.altitude) return;

      const { camera } = viewer.scene;
      const { lon, lat, altitude: alt } = unit;

      const rangeOffset = 90.0;

      const newLookAtPosition = {
        lon,
        lat,
        alt,
        heading: camera.heading,
        pitch: camera.pitch,
        range: rangeOffset,
      };

      const target = Cesium.Cartesian3.fromDegrees(
        newLookAtPosition.lon,
        newLookAtPosition.lat,
        newLookAtPosition.alt,
      );
      const offset = new Cesium.HeadingPitchRange(
        newLookAtPosition.heading,
        newLookAtPosition.pitch,
        newLookAtPosition.range,
      );
      camera.lookAt(target, offset);
      camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
    },
    /**
     * Hides all units with ids in [hide], optionally shows all others.
     * @param context
     * @param hide {[string]} - a list of ids of units to be hidden
     * @param showOther {boolean} - show all others?
     * @returns {Promise<void>}
     */
    async hideUnits(context, { hide, showOther = false }) {
      const { scene } = context.self.rootState.viewer;
      const units = context.self.state.dataSource.entities.values;
      units.forEach((unit) => {
        unit.show = !hide.includes(unit.id) && (unit.show || showOther);
      });
      scene.requestRender();
    },
    async showUnits(context, { show, hideOther = false }) {
      const { scene } = context.self.rootState.viewer;
      const units = context.self.state.dataSource.entities.values;
      units.forEach((unit) => {
        unit.show = show.includes(unit.id) || (unit.show && !hideOther);
      });
      scene.requestRender();
    },
  },
  events: {},
  methods: {
    adaptUnitDisplayToStatus({ unit, status }) {
      const { hoveringFeatures, selectedFeatures } = this.rootState.picker;
      const { id } = unit;
      let action;
      if (!selectedFeatures[id] && !hoveringFeatures[id]) {
        action = 'default';
      } else {
        if (selectedFeatures[id]) action = 'select';
        if (hoveringFeatures[id]) action = 'hover';
      }
      unit.model.color = styles[action].model[status].color;
      unit.model.colorBlendAmount = styles[action].model[status].colorBlendAmount;
      unit.model.colorBlendMode = styles[action].model[status].colorBlendMode;
    },
    handleUnitCZMLLoaded(dataSource) {
      this.state.dataSource = dataSource;
      this.state.units = dataSource.entities.values;
      dataSource.entities.values.forEach((entity) => {
        this.randomizeUnitOrientation(entity);
        entity.color = Cesium.Color.WHITE;
        entity.silhouetteColor = Cesium.Color.fromAlpha(Cesium.Color.GREENYELLOW, 1.0);
        entity.definitionChanged.addEventListener((changed, property, newValue) => {
          if (property === 'position' && newValue instanceof Cesium.SampledPositionProperty) {
            changed.orientation = new Cesium.VelocityOrientationProperty(changed.position);
          }
        });
        const { clock, clockViewModel } = this.rootState.viewer;
        let activityStatus;
        clock.onTick.addEventListener(() => {
          const { currentTime } = clockViewModel;
          const isActive = entity.properties.cxActivity.getValue(currentTime);
          const newActivityStatus = isActive ? 'active' : 'inactive';
          if (newActivityStatus !== activityStatus) {
            this.adaptUnitDisplayToStatus({ unit: entity, status: newActivityStatus });
            activityStatus = newActivityStatus;
          }
        });
      });
      this.cameraFlyToUnits();
    },
    /**
     * Makes a camera to fly to the center of given units
     * @param {*} units Array of all units that we want camera to fly to
     */
    cameraFlyToUnits() {
      const { viewer } = this.rootState;
      const unitsToFlyTo = this.state.dataSource.entities.values;
      if (_.isEmpty(unitsToFlyTo)) return;
      const unitPositions = unitsToFlyTo.map((unit) => {
        // TODO: remove hardcoding once dates work
        return unit.position.getValue(Cesium.JulianDate.fromIso8601('2018-09-13T16:10:00Z'));
      });

      const rectangle = Cesium.Rectangle.fromCartesianArray(unitPositions);
      this.rootState.destination = { ...rectangle };
      const { destination } = this.rootState;
      destination.north += 0.00001;
      destination.south -= 0.00001;
      destination.east += 0.000001;
      destination.west -= 0.00001;

      // todo: check if a camrea is already in given location - if it is
      // then do not change the location
      // temp for smoother rendering
      setTimeout(() => {
        const { camera } = viewer.scene;
        camera.flyTo({ destination });
      }, 800);
    },
    randomizeUnitOrientation(entity) {
      // randomize orientation so the units look more real
      const position = entity.position.getValue(Cesium.JulianDate.now());
      const heading = Cesium.Math.toRadians(Math.floor(Math.random() * Math.floor(360)));
      const pitch = 0;
      const roll = 0;
      const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
      const orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr);
      entity.orientation = orientation;
    },
    getUnitUUIDs() {
      return this.state.dataSource.entities.values.reduce((uuids, unit) => {
        uuids.push(unit.id);
        return uuids;
      }, []);
    }
  }
};

export default unitsStore;