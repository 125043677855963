const mc1 = 'MC1';
const icon3d = 'ICON-3D';
const iconSite = 'ICON-FIELD';
const geoPad = 'GEOPAD';
const captivate = '';

const panelTypes = {
  mc1,
  icon3d,
  iconSite,
  geoPad,
  captivate
};

export default panelTypes;