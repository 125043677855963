<template>
  <div :class="{'cx-popup-glass': glass}" v-if="showPopup">
    <div class="cx-popup" :style="computedDimensions" v-if="showPopup">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CxPopup',
  components: {},
  data: () => {
    return {
      popupHeight: 0,
      popupWidth: 0,
      viewPortWidth: 0,
      viewPortHeight: 0,
    };
  },
  props: {
    // id of the popup, must be uniqe across the application
    // not nil: popup will try to check if it is opened using popup store
    popupId: { type: String },
    isOpened: { type: Boolean, default: null },
    offset: { type: Object, default: () => ({ y: 0, x: 0 }) },
    offsetRatio: { type: Object, default: () => ({ y: 0.5, x: 0.5 }) },
    // if true, the popup render an invisible div underneath
    // disabling all interaction with the underlying app
    glass: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters('popup', {
      storeIsOpen: 'isOpened'
    }),
    showPopup() {
      if (!_.isNil(this.isOpened)) return this.isOpened;
      return this.storeIsOpen(this.popupId);
    },
    computedDimensions() {
      if (!this.popupWidth || !this.popupHeight || !this.showPopup) return {};
      const top = (this.offsetRatio.y * this.viewPortHeight) - (0.5 * this.popupHeight) - this.offset.y;
      const left = (this.offsetRatio.x * this.viewPortWidth) - (0.5 * this.popupWidth) + this.offset.x;
      return {
        top: top > 0 ? `${top}px` : '0px',
        left: (left + this.popupWidth <= this.viewPortWidth) ? `${left}px` : `${this.viewPortWidth - this.popupWidth}px`
      };
    },
  },
  methods: {
    positionPopup() {
      this.viewPortWidth = document.documentElement.clientWidth;
      this.viewPortHeight = document.documentElement.clientHeight;
      this.$nextTick(() => {
        if (this.$el.childNodes[0]) {
          this.popupHeight = this.$el.childNodes[0].clientHeight;
          this.popupWidth = this.$el.childNodes[0].clientWidth;
        }
      });
    },
  },
  mounted() {
    this.throttleResize = _.throttle(this.positionPopup, 200);
    this.throttleResize();
    window.addEventListener('resize', this.throttleResize);
  },
  watch: {
    showPopup(newValue) {
      // react on popup opened
      if (newValue) {
        this.positionPopup();
      }
    }
  }
};
</script>

<style lang="less">
@import '../common';

.cx-popup-glass {
  .size(100vw, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  z-index: @cxPopupZI;
}

.cx-popup {
  position: absolute;
  margin: auto;
  box-shadow: 0 25px 100px @popupBoxShadowColor;
  background: @baseBackgroundColor;
  border-radius: 10px;
}

</style>