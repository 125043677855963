import _ from 'lodash';

// Returns indexes for a single char chain occurence of full query phrase within given full phrase
export function checkPhrase(fullPhrase, queryPhrase, startIndex) {
  let counter = 0;
  const occurence = [];

  for (let i = startIndex; i < fullPhrase.length; i += 1) {

    if (fullPhrase.charAt(i) === queryPhrase.charAt(counter)) {
      occurence.push(i);
      counter += 1;
    } else {
      counter = 0;
      break;
    }
  }

  return occurence.length === queryPhrase.length ? occurence : null;
}

// Returns indexes for any occurence of query phrase's first char within given full phrase
export function getQueryFirstCharOccurences(fullPhrase, queryPhrase) {
  const firstChar = queryPhrase.charAt(0);
  const occurences = [];

  for (let i = 0; i < fullPhrase.length; i += 1) {
    if (fullPhrase.charAt(i) === firstChar) {
      occurences.push(i);
    }
  }

  return occurences;
}

// Returns indexes for every char chain occurence of full query phrase within given full phrase
export function getQueryFullStringOccurences(fullPhrase, queryPhrase, firstCharOccurences) {
  const occurences = [];

  firstCharOccurences.forEach((charIndex) => {
    const occurence = checkPhrase(fullPhrase, queryPhrase, charIndex);
    if (occurence) {
      occurence.forEach((i) => {
        occurences.push(i);
      });
    }
  });

  return occurences.length ? occurences : null;
}

// Splits given string into characters and highlights characters compliant with the input
export function splitStringIntoCharHighlights(input, string) {
  const query = input.toLowerCase();
  const name = string.toLowerCase();

  const firstCharOccurences = getQueryFirstCharOccurences(name, query);
  const fullStringOccurences = getQueryFullStringOccurences(name, query, firstCharOccurences);
  const nameCharacters = string.split('');

  const resultDisplayName = nameCharacters.map((char, index) => {
    const match = fullStringOccurences ? fullStringOccurences.includes(index) : false;
    return { char, match };
  });

  return resultDisplayName;
}

export function areStringsMatching(string1, string2) {
  return _.lowerCase(string1) === _.lowerCase(string2);
}

export function doesStringIncludeString(fullString, subString) {
  const fullStringLowerCased = String(fullString).toLowerCase();
  const subStringLowerCased = String(subString).toLowerCase();
  return fullStringLowerCased.includes(subStringLowerCased);
}

export const sanitizeStringValue = (value) => {
  if (value === 0) return value;
  if (!value) return '';
  if (value === '-') return '';
  if (value === '--') return '';
  if (value === '---') return '';
  return value;
};

export function excludePatterns(string, patternArray) {
  return patternArray.some(pattern => pattern === string) ? '' : string;
}