import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';
import { HoleDrillPileStatus } from '@/domain/hole/HoleDrillPileStatus.enum';
import { DrillPileType } from '@/domain/point/DrillPileType.enum';
import pointMapper from './point.mapper';
import { createDrillHole, createPileHole } from '@/domain/hole/HoleFactory';
import { createHoleSet } from '@/domain/holeSet/HoleSetFactory';
import { OriginTimeRange } from '@/domain/OriginTimeRange';
import { Hole } from '@/domain/hole/Hole';
import { FilterModel } from '@/domain/filters/FilterModel';
import { HoleSet } from '@/domain/holeSet/HoleSet';
import { last } from 'lodash';
import { ApiPoint } from '@/http/types/ApiPoint';

interface ApiHole {
  DrillPileStatus: HoleDrillPileStatus,
  LogName: string,
  count: number,
  from_time: Date,
  latitude: number,
  longitude: number,
  reference_model: string,
  to_time: Date,
  type: string,
  result: ApiPoint[]
}

const drillHoleTypeMap = {
  hole: DrillPileType.Drill,
  pile: DrillPileType.Pile,
  /**
   * Map api type to drillHoleType.
   * @param apiType {string}: The value returned from api.
   * @returns {string}: The hole type.
   */
  get(apiType: string): DrillPileType {
    const result = this[apiType];
    if (!Object.values(DrillPileType).includes(result)) {
      throw new Error('hole type must be "hole" or "pile"');
    }
    return result;
  }
};

/**
 * Map api status to drillPileStatus.
 * @param apiStatus {string}: The value returned from api.
 * @returns {string}: The hole status.
 */
const drillHoleStatusMap = {
  Log: HoleDrillPileStatus.Log,
  Start: HoleDrillPileStatus.Start,
  End: HoleDrillPileStatus.End,
  Entry: HoleDrillPileStatus.Entry,
  Pause: HoleDrillPileStatus.Paused,
  Continue: HoleDrillPileStatus.Continue,
  Failed: HoleDrillPileStatus.Failed,
  get(apiType: string): HoleDrillPileStatus {
    const result = this[apiType];
    if (!Object.values(HoleDrillPileStatus).includes(result)) {
      return this.Log;
    }
    return result;
  }
};

const mapHole = (apiHole: ApiHole, setUid: string): Hole | null => {
  // TODO: uuid creation should be done on the server
  const namespace_uuid = 'fb56bd6f-9ed5-472c-8251-9da11cb8ef89';
  const joinedLogTime = apiHole.result.filter(r => r.log_time).map(r => r.log_time).join();
  const uuid = uuidv5(`${apiHole.LogName}/${apiHole.reference_model}/${joinedLogTime}`, namespace_uuid);
  const holeType = drillHoleTypeMap.get(apiHole.type);
  const holeStatus = drillHoleStatusMap.get(apiHole.DrillPileStatus);
  const pointSet = pointMapper.mapPoints({ measuredPoints: apiHole.result, total_count: apiHole.result.length }, null, null);
  const lastPoint = last(pointSet.points);
  const logTime = lastPoint ? lastPoint.logTime : null;
  let mappedHole: null | Hole = null;
  if (holeType === drillHoleTypeMap.hole) {
    mappedHole = createDrillHole({
      uuid,
      holeName: apiHole.LogName,
      logTime,
      status: holeStatus,
      referenceModelName: apiHole.reference_model,
      displayLatitude2D: apiHole.latitude,
      displayLongitude2D: apiHole.longitude,
      pointSet,
      setUid
    });
  }
  if (holeType === drillHoleTypeMap.pile) {
    mappedHole = createPileHole({
      uuid,
      holeName: apiHole.LogName,
      logTime,
      status: holeStatus,
      referenceModelName: apiHole.reference_model,
      displayLatitude2D: apiHole.latitude,
      displayLongitude2D: apiHole.longitude,
      pointSet,
      setUid
    });
  }
  return mappedHole;
};

const mapHoles = (holesData: ApiHole[], originFilters: FilterModel[], originTimeRange: OriginTimeRange): HoleSet => {
  const setUid: string = uuidv4();
  return createHoleSet({
    uuid: setUid,
    name: null,
    holes: holesData.map(h => mapHole(h, setUid)) as Hole[],
    originFilters,
    originTimeRange
  });
};

const holeMapper = {
  mapHoles,
};
export default holeMapper;
