import saveStateService from './save.sate.service';
import _ from 'lodash';

/**
 * Plugin listens on actions to save app state
 * whenever entities in OE or their visibility/selection changes
 * @param {} store
 */
const saveStatePlugin = (store) => {
  store.subscribeAction({
    after: ({ type, payload }) => {
      if ((type === 'map/addEntities' && !_.get(payload, 'options.restore'))
         || type === 'map/removeEntityFamily'
         || (type === 'map/setEntityVisibility' && !_.get(payload, 'options.restore'))
         || type === 'map/handleEntitySelection'
         || type === 'map/restoreMapState'
         || type === 'detailsSurfacelog/changeSurfacelogLegend') {
        const mapState = store.getters['map/mapState'];
        try {
          saveStateService.storeAppState(store.getters.projectUuid, mapState);
        } catch (error) {
          store.dispatch('notifications/error', { exception: error, message: error.message }, { root: true });
        }
      }
    },
  });
};
export default saveStatePlugin;