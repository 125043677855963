<template>
  <div class="cx-licence-panel">
    <cx-button flat class="cx-licence-panel__icon " icon="mdi-information" @click="openPopup('popup-cx-licence')" :iconSize="20"/>
    <div class="cx-licence-panel__info">
      <div class="cx-licence-panel__info--text">
        <span>{{ textPartOne }}</span>
        <cx-link :text="linkText" @click="openPopup('popup-cx-licence')"/>
        <span>{{ textPartTwo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CxLink from '@/components/CxLink';
import CxButton from '@/components/CxButton';
import { mapActions } from 'vuex';

export default {
  name: 'CxLicencePanel',
  components: {
    CxLink,
    CxButton
  },
  props: {
  },
  computed: {
    textPartTwo() {
      return this.$gettext('to get more from ConX!');
    },
    textPartOne() {
      return this.$gettext('There is more! You can');
    },
    linkText() {
      return this.$gettext('add solution based productivity licenses');
    }
  },
  methods: {
    ...mapActions('popup', ['openPopup'])
  },
};
</script>

<style lang="less" scoped>
@import '../../../common';

.cx-licence-panel {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  height: 100%;
  background-color: @detailsContentBackgroundColor;
  margin-left: auto;
  max-width: 712px;
  flex: 1;

  .cx-licence-panel__icon {
    margin-left: auto;
    margin-top: 5px;
    width: @cxLicencePopupIconSize;
    align-items: baseline;
  }
  .cx-licence-panel__info {
    border: 1px solid @chipsBorderColor;
    border-radius: 10px;
    color: @textColorLowlight;
    font-size: @appFontSize;
    padding: 15px;
    text-align: center;
    flex: 0;
    margin-top: auto;
    margin-bottom: 20px;
    margin-right: 20px;

    .cx-licence-panel__info--text {
      width: @cxLicenceInfoTextWidth;
      margin: auto;
    }
  }
}
</style>