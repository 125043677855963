export class Surface {
  /**
   * Creates a represenation of a surface
   * @param uid Surface uid
   * @param surfaceType [surface, cutFill, surfaceDiff]
   * @param geometry geometry instance
   */
  constructor(uid, surfaceType, geometry) {
    this.uid = uid;
    this.surfaceType = surfaceType;
    this.geometry = geometry;
  }

  id() {
    return this.uid;
  }
  geometry() {
    return this.geometry;
  }
  surfaceType() {
    return this.surfaceType;
  }
  show() {
    this.geometry.forEach((element) => {
      element.show = true;
    });
  }
  hide() {
    this.geometry.forEach((element) => {
      element.show = false;
    });
  }

}