import _ from 'lodash';
import { sanitizeStringValue } from '@/utils/stringOperations';
import translationService from '@/services/translation.service';

/**
 * Represent text value ready to display
 */
export class TextValue {

  // raw value passed to the object
  _value;
  // sanitized and translated value
  // translation will work only if value translated
  // is added to static translation service entries
  _processedValue;
  // if true value will be translated when obtained
  _doTranslate;

  constructor(value, normalize = true, doTranslate = true, sanitize = true) {
    this._value = value;
    this._doTranslate = doTranslate;
    const result = sanitize ? sanitizeStringValue(value) : value;
    this._processedValue = normalize ? _.upperFirst(`${result}`.toLowerCase()) : result;
  }

  /**
   * Return translated value or empty value if null
   */
  get displayValue() {
    if (this._doTranslate) return translationService.get(this._processedValue);
    return this._processedValue;
  }
  get value() {
    return this.displayValue;
  }

}