import { format, formatISO } from 'date-fns';
import api from '@/http/api';
import { fetchPointDataStoreMixin } from '@/store/detailsModule/fetchPointData.store.mixin';
import { getFileDownloadError } from '@/domain/fileDownload.error';
import { PointSetType } from '@/domain/pointSet/PointSetType';
import { gettext } from '@/translations/gettext.setup';

const { $gettext } = gettext;

const detailsPointSetModule = {
  state: {
    // uuid of the selected reference model to do a cut fill analysis
    selectedRefModel: null,
    // true if points are being exported
    isExporting: false,
    // uuid of surface generated from the point set
    surfaceUuid: null,
    // uuid of cut-fill generated from the point set and selected ref model
    cutFillUuid: null,
    ...fetchPointDataStoreMixin.state
  },
  getters: {
    selectedPointSet(state, getters, rootState, rootGetters) {
      const selectedEntity = rootGetters['map/selectedEntity'];
      if (selectedEntity && selectedEntity.entityType === PointSetType) {
        return selectedEntity;
      }
      return null;
    },
    selectedRefModel(state) {
      return state.selectedRefModel;
    },
    isShowSurface(state, getters, rootState, rootGetters) {
      if (!state.surfaceUuid) return false;
      const surf = rootGetters['map/surface'](state.surfaceUuid);
      if (!surf) return false;
      return surf.isVisible;
    },
    isSurfaceLoading(state, getters, rootState, rootGetters) {
      if (!getters.selectedPointSet) return false;
      return rootGetters['cancelable/isLoading']({ actionType: 'map/loadSurface', sourceUid: getters.selectedPointSet.uuid });
    },
    isShowDiffModel(state, getters, rootState, rootGetters) {
      if (!state.cutFillUuid) return false;
      const surf = rootGetters['map/surface'](state.cutFillUuid);
      if (!surf) return false;
      return surf.isVisible;
    },
    isDiffModelLoading(state, getters, rootState, rootGetters) {
      if (!getters.selectedPointSet) return false;
      return rootGetters['cancelable/isLoading']({ actionType: 'map/loadCutFillSurface', sourceUid: getters.selectedPointSet.uuid });
    },
    isExporting(state) {
      return state.isExporting;
    },
    ...fetchPointDataStoreMixin.getters
  },
  mutations: {
    setSelectedRefmodel(state, payload) {
      state.selectedRefModel = payload;
    },
    setSurface(state, uuid) {
      state.surfaceUuid = uuid;
    },
    setCutFill(state, uuid) {
      state.cutFillUuid = uuid;
    },
    setIsExporting(state, isExporting) {
      state.isExporting = isExporting;
    },
    ...fetchPointDataStoreMixin.mutations
  },
  actions: {
    ...fetchPointDataStoreMixin.actions,
    async handleRefmodelSelection(context, model) {
      const { cutFillUuid } = context.state;
      if (cutFillUuid) await context.dispatch('map/removeSurface', { uuid: cutFillUuid }, { root: true });
      context.commit('setCutFill', null);
      context.commit('setSelectedRefmodel', model);
    },

    async handlePointSetDeselection(context, uuid) {
      await context.dispatch('cancelable/cancelActions', { sourceUid: uuid }, { root: true });
      const { surfaceUuid, cutFillUuid } = context.state;
      if (surfaceUuid) await context.dispatch('map/removeSurface', { uuid: surfaceUuid }, { root: true });
      if (cutFillUuid) await context.dispatch('map/removeSurface', { uuid: cutFillUuid }, { root: true });
      context.commit('setSurface', null);
      context.commit('setCutFill', null);
      context.commit('setSelectedRefmodel', null);
    },

    async toggleDisplaySurface(context, { show }) {
      let { surfaceUuid } = context.state;
      const { isSurfaceLoading, selectedPointSet, isShowSurface } = context.getters;
      if (show !== isShowSurface && !isSurfaceLoading) {
        if (show) {
          // check if surface has already been created - if so, just show it, without calculating it again
          if (!surfaceUuid) {
            surfaceUuid = await context.dispatch('map/loadSurface', { points: selectedPointSet.points, sourceUid: selectedPointSet.uuid }, { root: true });
            context.commit('setSurface', surfaceUuid);
          } else await context.dispatch('map/showSurface', { uuid: surfaceUuid }, { root: true });
        } else {
          await context.dispatch('map/hideSurface', { uuid: surfaceUuid }, { root: true });
        }
      }
    },
    async toggleDisplayDiffModel(context, { show, refmodelUuid }) {
      try {
        let { cutFillUuid } = context.state;
        const { isDiffModelLoading, selectedPointSet, isShowDiffModel } = context.getters;
        if (show !== isShowDiffModel && !isDiffModelLoading) {
          if (show) {
            if (!cutFillUuid) {
              cutFillUuid = await context.dispatch('map/loadCutFillSurface', { points: selectedPointSet.points, modelUuid: refmodelUuid, sourceUid: selectedPointSet.uuid }, { root: true });
              context.commit('setCutFill', cutFillUuid);
            } else await context.dispatch('map/showSurface', { uuid: cutFillUuid }, { root: true });
          } else {
            await context.dispatch('map/hideSurface', { uuid: cutFillUuid }, { root: true });
          }
        }
      } catch (e) {
        context.dispatch('notifications/error', { exception: e, message: $gettext('Error occured when toggling difference model') }, { root: true });
      }
    },
    async exportPoints(context, extension) {
      try {
        context.commit('setIsExporting', true);
        const { selectedPointSet } = context.getters;
        if (!selectedPointSet.originFilters) {
          throw new Error('Can\'t export points - no origin filters set');
        }

        const name = `ConX_Asbuilts_${format(new Date(Date.now()), 'yyyy-MM-dd_hh:mm:ss')}.${extension}`;

        await api.exportMeasuredPoints(
          context.rootGetters.projectUuid,
          formatISO(selectedPointSet.originTimeRange.from),
          formatISO(selectedPointSet.originTimeRange.to),
          selectedPointSet.originFilters,
          name,
          extension,
        );
      } catch (e) {
        await context.dispatch('notifications/error', getFileDownloadError(e), { root: true });
      } finally {
        context.commit('setIsExporting', false);
      }
    },
    async showPointGrid(context) {
      const { selectedPointSet } = context.getters;
      context.dispatch('showGridData', selectedPointSet);
    }
  }
};

export default detailsPointSetModule;