<template>
  <div class="cx-map-zoom">
    <cx-button
      flat
      icon="add"
      :iconSize="16"
      :tooltip="{
        offsetLeft: -42,
        text: tooltip.zoomIn
      }"
      @click="zoomIn"/>
    <cx-button
      flat
      icon="remove"
      :iconSize="16"
      :tooltip="{
        offsetLeft: -42,
        text: tooltip.zoomOut
      }"
      @click="zoomOut"/>
  </div>
</template>

<script>
import CxButton from '@/components/CxButton';

export default {
  name: 'CxMapZoom',

  components: {
    CxButton
  },

  props: {
    zoomIn: Function,
    zoomOut: Function
  },

  computed: {
    tooltip() {
      return {
        zoomIn: this.$gettext('Zoom In'),
        zoomOut: this.$gettext('Zoom Out')
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-zoom {
  .size(@mapZoomWidth, @mapZoomHeight);
  .flex(column, space-around, center);
  background-color: @overlayControlBackgroundColor;
  border-radius: @inputBorderRadius;
  pointer-events: auto;
  padding: 5px 0;
}
</style>