<template>
  <div className="cx-ref-models-filter">
    <cx-combobox
      :items="refModels"
      :value="selectedRefModels"
      :input="changeSelectedRefModels"
      :label="label"
      :disabled="disabled"
      :loading="loading"
      isChips
      isTextInput
      isDropdown
      ref="chips"/>
  </div>
</template>

<script>
import CxCombobox from '@/components/cxCombobox/CxCombobox';

export default {
  name: 'CxRefModelsFilter',
  components: {
    CxCombobox
  },
  props: {
    availableRefModels: Array,
    selectedRefModels: Array,
    changeSelectedRefModels: Function,
    disabled: Boolean,
    loading: Boolean,
  },
  computed: {
    refModels() {
      const refModels = this.availableRefModels.map((t) => {
        return {
          id: t.id,
          name: t.title,
          isChipSelected: this.selectedRefModels.includes(t.id)
        };
      });
      return refModels;
    },
    label() {
      return this.$gettext('Reference Model');
    },
  },
  methods: {
    /**
     * Clears combobox/chips textbox.
     */
    clearChipsInput() {
      this.$refs.chips.onReset();
    }
  }
};
</script>