import { createFieldMixin } from '@/viewModel/fieldFactory';
import { createValueMixin } from '@/viewModel/valueFactory';

export class PointViewModel {

  _point;
  constructor(point) {
    this._point = point;
    this.createField = createFieldMixin(point);
    this.createValue = createValueMixin(point);
  }

  get drillPileDepth() {
    return this.createValue('drillPileDepth');
  }
  get northing() {
    const df = this.createField('northing');
    return { ...df, fieldName: df.symbol };
  }
  get easting() {
    const df = this.createField('easting');
    return { ...df, fieldName: df.symbol };
  }
  get latitude() {
    const df = this.createField('latitude');
    return { ...df, fieldName: df.symbol };
  }
  get longitude() {
    const df = this.createField('longitude');
    return { ...df, fieldName: df.symbol };
  }
  get height() {
    const df = this.createField('height');
    return { ...df, fieldName: df.symbol };
  }
  get ellipsoidHeight() {
    const df = this.createField('ellipsoidHeight');
    return { ...df, fieldName: df.symbol };
  }
  get drillPileStatus() {
    return this.createValue('drillPileStatus');
  }
  get logTime() {
    return this.createValue('logTime').displayValue;
  }
}
