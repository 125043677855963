/**
 * @file Contains definitions of CZML properties.
 * @author Jan Wąsak
 */
import _extend from 'lodash/assignIn';

import { Property } from '@/utils/czml/property';

/**
 * Represents a set of custom ConX properties.
 */
class CxProperties extends Property {
  constructor(properties) {
    super('properties');
    _extend(this.czml, properties);
  }
}
export { CxProperties };
