<template>
  <div class="cx-analysis__panel--point-in-time">
    <!-- spinner when waiting for data -->
    <div v-if="showSpinner" class="cx-analysis__panel--point-in-time__content__spinner__panel">
      <div
        class="cx-analysis__panel--point-in-time__content__spinner">
        <cx-spinner :size="95" :width="3"/>
        <div class="cx-analysis__panel--point-in-time__content__spinner__text">{{ spinnerValue }}%</div>
      </div>
    </div>
    <div class="analysis__panel--no-data" v-if="showChartEmptyError">
      <cx-no-data/>
    </div>
    <div class="cx-analysis__panel--point-in-time-chart" v-if="!showChartEmptyError && !showSpinner">
      <!-- toggle buttons -->
      <div class="cx-analysis__panel--point-in-time__upperPanel">
        <div class="cx-analysis__panel--point-in-time__switcher">
          <cx-toggle-button
            :state="cutFill"
            :label="translations.cutFill"
            :onToggle="handleToggleCutFill"
            :disabled="!showChartAndMetrics"/>
          <cx-toggle-button
            :state="progressAnalysis"
            :label="translations.progressAnalysis"
            :onToggle="handleToggleProgressAnalysis"
            :disabled="!showChartAndMetrics"/>
        </div>

        <div class="cx-analysis__panel--point-in-time__datePicker">
          <cx-date-range-picker
            v-model="cStartDate"
            :minDate="projectStartDay"
            :showQuickSelectTab="true"
            :quickSelect="quickSelectOptions"
            :freezeTo="now"
            :dateFormat="dateTimeFormat"/>
        </div>
      </div>
      <!-- chart content to render -->
      <div
        class="cx-analysis__panel--point-in-time-chart--chart"
        v-if="showChartAndMetrics">
        <keep-alive>
          <component :is="chartContent"/>
        </keep-alive>
      </div>
      <cx-chart-error text="Volume could not be calculated. Please change the date range or measure more as-built points." v-if="showChartCalculationError || showChartPlotError"/>
    </div>
    <div class="cx-analysis__panel--point-in-time-chart_metrics" v-if="showChartAndMetrics">
      <div
        class="cx-analysis__panel--point-in-time__content__metrics"
        v-if="!showSpinner">
        <keep-alive>
          <component :is="metricsContent"/>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import datetimehelper from '@/utils/datetimehelper';
import CxToggleButton from '@/components/CxToggleButton.vue';
import CxDateRangePicker from '@/components/datePickers/CxDateRangePicker.vue';
import CxPointInTimeChart from './CxPointInTimeChart';
import CxPointInTimeMetrics from './CxPointInTimeMetrics';
import CxCumulativeChart from './CxCumulativeChart';
import CxCumulativeMetrics from './CxCumulativeMetrics';
import CxNoData from './CxNoData';
import CxExportAsbuildComponent from '@/app/detailsPanel/common/CxExportAsbuildComponent';
import CxChartError from './CxChartError';
import CxSpinner from '@/components/CxSpinner.vue';
import { subHours, subDays, subMonths } from 'date-fns';

export default {
  name: 'CxRefModelAnalysisPointInTime',

  components: {
    CxToggleButton,
    CxPointInTimeChart,
    CxPointInTimeMetrics,
    CxCumulativeChart,
    CxCumulativeMetrics,
    CxDateRangePicker,
    CxNoData,
    CxExportAsbuildComponent,
    CxChartError,
    CxSpinner
  },
  data() {
    return {
      dateTimeFormat: datetimehelper.LOCALE_DATE_FORMAT
    };
  },
  computed: {
    ...mapGetters('app', ['projectStartDay', 'projectStartDate']),
    ...mapGetters('detailsRefmodel', [
      'showSpinner',
      'chartContent',
      'metricsContent',
      'startDate',
      'now',
      'showChartEmptyError',
      'spinnerValue',
      'showChartCalculationError',
      'showChartPlotError',
      'analysisContent'
    ]),
    translations() {
      return {
        cutFill: this.$gettext('Cut & Fill'),
        progressAnalysis: this.$gettext('Progress Analysis')
      };
    },
    cStartDate: {
      get() { return [this.startDate, this.now]; },
      set(value) { this.handeChangeChartRange({ startDate: value[0] }); }
    },
    quickSelectOptions() {
      return [
        { label: this.$gettext('Last 24h'), range: [subHours(new Date(), 24), new Date()] },
        { label: this.$gettext('Last 7 days'), range: [subDays(new Date(), 7), new Date()] },
        { label: this.$gettext('Last month'), range: [subMonths(new Date(), 1), new Date()] },
        { label: this.$gettext('Last 3 months'), range: [subMonths(new Date(), 3), new Date()] },
        { label: this.$gettext('Whole project'), range: [this.projectStartDate, new Date()] }
      ];
    },
    showChartAndMetrics() {
      return !this.showChartCalculationError && !this.showChartPlotError && !this.showChartEmptyError && !this.showSpinner;
    },
    cutFill() {
      return this.analysisContent === 'cutfill';
    },
    progressAnalysis() {
      return this.analysisContent === 'progress';
    }
  },
  methods: {
    ...mapActions('detailsRefmodel', [
      'switchCutFillProgressDetails',
      'handeChangeChartRange',
      // 'exportAsbuilds'
    ]),
    handleToggleCutFill() {
      this.switchCutFillProgressDetails('cutfill');
    },
    handleToggleProgressAnalysis() {
      this.switchCutFillProgressDetails('progress');
    },
  },
};

</script>

<style lang="less">
@import '../../../common';

.cx-analysis__panel--point-in-time {
  font-size: @appFontSize;
  padding-top: 50px;
  height: 100%;
  flex: 1 1 auto;
  .flex(row, flex-start, flex-start);

  .cx-analysis__panel--point-in-time__content__spinner__panel{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 100%;
    height: calc(100% - 20px);
    margin-right: 100px;
    color: @textColorHighlight;
    // border: 1px solid rgba(255,255,255,0.05);
    // border-radius: 4px;
  }
  .cx-analysis__panel--point-in-time__content__spinner__text {
    position: relative;
    top: -65px;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    height: 0px;
  }

  .cx-analysis__panel--point-in-time-chart{
    flex: 2 0 auto;
    .flex(column, flex-start, flex-start);
    height: 100%;

    .cx-analysis__panel--point-in-time__upperPanel {
      flex: 0 0 30px;
      .flex(row, flex-start, stretch);

      .cx-analysis__panel--point-in-time__switcher {
        flex: 1 0 auto;
        background-color: @bgColor3;
        border-radius: @inputBorderRadius;
        height: @inputHeight;

        .cx-toggle-button {
          display: inline-block;
          .size(auto, @inputHeight);
          border-radius: @inputBorderRadius;
          span {
            padding: 10px 15px 10px 15px;
          }
        }
        .cx-toggle-button__hitbox {
          background-color: @bgColor3;
        }
        .checked {
          &.cx-toggle-button__hitbox {
            background-color: @bgColor2;
          }
        }
      }

      .cx-analysis__panel--point-in-time__datePicker {
        flex: 1 0 165px;
        margin-left: 15px;
      }
    }

    .cx-analysis__panel--point-in-time-chart--chart {
      flex: 1 0;
      padding-top: 10px;
      width: 100%;
    }
  }

  .cx-analysis__panel--point-in-time-chart_metrics {
    flex: 0 0 340px;
  }

  .analysis__panel--no-data {
    .size(100%, 100%);
    padding-bottom: 20px;
    padding-right: 20px;
  }

  .cx-menu-button {
    margin-top: -1px;
  }
}
</style>