<template>
  <div class="cx-direct-search" :class="{ focus: input }">
    <div class="cx-direct-search__input">
      <transition :name="applyAnimations('input')">
        <div
          class="cx-direct-search__input__query__container"
          :class="{ 'opened': inputVisible }"
          v-if="inputVisible">
          <input
            class="cx-direct-search__input__query"
            :placeholder="translations.caption"
            v-model="input"
            @input="onQueryInput(input)"
            @keyup="onKeyUp"
            @click="activeSuggestion = null"
            ref="searchInput"/>
          <cx-spinner v-if="isLoading" :size="16" :width="1" class="cx-direct-search__input__query__progress"/>
        </div>
      </transition>
      <span
        class="cx-direct-search__input__icon mdi mdi-magnify"
        v-if="!input"
        @click="toggleInput"
        @mouseenter="isTooltip = true"
        @mouseleave="isTooltip = false"
        v-cx-tooltip="{
          text: searchTooltip,
          show: isTooltip,
          offsetTop: -3
        }">
      </span>
      <span
        class="cx-direct-search__input__icon mdi mdi-close-circle"
        v-if="input"
        @click="onClear"
        @mouseenter="isClearTooltip = true"
        @mouseleave="isClearTooltip = false"
        v-cx-tooltip="{
          text: searchTooltip,
          show: isClearTooltip,
          offsetTop: -3
        }"></span>
    </div>
    <transition :name="applyAnimations('dropdown')">
      <cx-direct-search-dropdown
        v-if="suggestions"
        :input="input"
        :suggestions="suggestions"
        :activeSuggestion="activeSuggestion"
        :isSuggestions="!zeroResultText"
        :onSuggestionClick="onSuggestionClick"
        :onKeyUp="onKeyUp"/>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { RefmodelTypes } from '@/domain/refmodels/RefmodelTypes.enum';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxDirectSearchDropdown from './CxDirectSearchDropdown';
import CxSpinner from '@/components/CxSpinner.vue';

export default {
  name: 'CxDirectSearch',

  components: {
    CxDirectSearchDropdown,
    CxSpinner
  },

  mixins: [CxAnimationMixin],

  data() {
    return {
      input: '',
      suggestions: [],
      previousInput: null,
      activeSuggestion: null,
      zeroResultText: '',
      throttleSuggestions: null,
      inputVisible: false,
      isTooltip: false,
      isClearTooltip: false,
      isLoading: false
    };
  },

  props: {
    geocoderServices: Object
  },

  computed: {
    ...mapGetters('app', ['entities']),
    ...mapGetters('map', ['manageableEntities']),

    translations() {
      return {
        caption: this.$gettext('Search'),
        placeholder: this.$gettext('No results...')
      };
    },
    searchTooltip() {
      if (this.isTooltip && this.inputVisible) return this.$gettext('Close Searchbox');
      if (this.isTooltip && !this.inputVisible) return this.$gettext('Open Searchbox');
      if (this.isClearTooltip) return this.$gettext('Clear Searchbox');
      return '';
    }
  },

  created() {
    this.initializeGeocoder();
    this.throttleSuggestions = _.throttle(this.fetchQueryResults, 400);
  },
  methods: {
    ...mapActions('map', ['setCameraToEntity', 'setEntityVisibility', 'setCameraToGeographicObject', 'addEntities']),
    ...mapActions('map/viewer', ['initializeGeocoder', 'geocode', 'lookAtGeocodedLocation']),
    // Passes input to the geocoder if a keystroke occurs in more than every 0.4 sec
    onQueryInput(input) {
      const query = input.trim();
      this.throttleSuggestions(query);
    },

    // Fetches the geocoder services and potentially returns results
    async fetchQueryResults(input) {
      this.previousInput = null;

      this.zeroResultText = '';
      if (!input) {
        this.suggestions = [];
        return;
      }
      if (input.length >= 3) {
        this.suggestions = await this.geocode(input);
      }
    },

    // Searches for the result basing on suggestions returned by the geocoders
    onSearch() {
      if (this.isLoading) return;
      if (this.suggestions && this.suggestions.length) {
        if (this.activeSuggestion) {
          this.input = this.activeSuggestion.displayName;
          this.flyToObject(this.activeSuggestion);
        } else {
          this.input = this.suggestions[0].displayName;
          this.flyToObject(this.suggestions[0]);
        }
      } else if (this.input && this.previousInput) {
        this.flyToObject(this.previousInput);
      } else {
        this.zeroResultText = this.translations.placeholder;
      }
    },

    // Clears searchbox
    onClear() {
      this.input = '';
      this.suggestions = [];
      this.zeroResultText = '';
      this.inputVisible = false;
    },

    // Suggestion click handler
    onSuggestionClick(suggestion) {
      this.input = suggestion.displayName;
      this.flyToObject(suggestion);
    },

    // Keystroke handler for searching
    onKeyUp(event) {
      switch (event.keyCode) {
        case 13: { // Enter
          this.onSearch();
          break;
        }
        case 38: { // ArrowUp
          if (this.suggestions.length) {
            if (!this.activeSuggestion) {
              this.activeSuggestion = this.suggestions[this.suggestions.length - 1];
            } else {
              this.activeSuggestion = (this.suggestions.indexOf(this.activeSuggestion) === 0) ? this.suggestions[this.suggestions.length - 1] : this.suggestions[this.suggestions.indexOf(this.activeSuggestion) - 1];
            }
          }

          break;
        }
        case 40: { // ArrowDown
          if (this.suggestions.length) {
            const [firstSuggestion] = this.suggestions;
            if (!this.activeSuggestion) {
              this.activeSuggestion = firstSuggestion;
            } else {
              this.activeSuggestion = (this.suggestions.indexOf(this.activeSuggestion) === this.suggestions.length - 1) ? firstSuggestion : this.suggestions[this.suggestions.indexOf(this.activeSuggestion) + 1];
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    },

    // If there is any geocoded result, flies to that result location
    async flyToObject(object) {
      this.isLoading = true;
      this.activeSuggestion = null;
      this.suggestions = null;
      this.previousInput = object;
      const { entityType } = object;
      const isEntity = !!entityType;
      if (isEntity) {
        // find entity in manageable objects - if it is already added to the map,
        // we will only fly to it and show it on the map
        const addEntity = !this.manageableEntities.find(e => e.uuid === object.id);
        let result = false;
        // otherwise we add entity to the OE
        if (addEntity) {
          let entityToAdd = { uuid: object.id, entityType: object.type };
          if (Object.values(UnitTypes).includes(entityType)
              || Object.values(RefmodelTypes).includes(entityType)) entityToAdd = this.entities.find(r => r.uuid === object.id);
          if (entityToAdd) result = await this.addEntities({ entities: [entityToAdd] });
        }
        if (!addEntity || result) {
          this.setEntityVisibility({ uuid: object.id, isVisible: true });
          this.setCameraToEntity(object.id);
        }
      } else {
        this.setCameraToGeographicObject(object);
      }
      this.isLoading = false;
    },

    toggleInput() {
      this.inputVisible = !this.inputVisible;
      this.$nextTick().then(() => { if (this.inputVisible) this.$refs.searchInput.focus(); });
    },
  }
};
</script>

<style lang="less">
@import '../../common';

.cx-direct-search {
  position: absolute;
  top: @upperOverlaysSpace;
  right: @directSearchRightSpace;
  .flex(column, flex-start, center);
  background-color: @overlayControlBackgroundColorInactive;
  color: @textColorBase;
  font-size: @appFontSize;
  border-radius: @inputBorderRadius;

  .cx-direct-search__input {
    .flex(row, flex-start, stretch);

    .cx-direct-search__input__query__container {
      width: 0;
      display: flex;
      &.opened {
        width: @directSearchWidth;
        padding: 0 @inputSpace;
      }
    }
    .cx-direct-search__input__query {
      margin-top: 0;
      height: @inputHeight;
      color: @textColor;
      flex: 1 1 auto;
      &:focus {
        outline: none;
      }
    }
    .cx-direct-search__input__query__progress {
      display: inline-block;
      flex: 0 0 auto;
      margin: auto;
      color: @textColor;
      margin-left: 10px;
    }

    .cx-direct-search__input__icon {
      font-size: @customIconSize;
      padding: 3px 8px;
      opacity: 0.3;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.focus {
  opacity: 1;
  background-color: @directSearchInputFocusBGColor;
}
</style>