import { Operators } from '../../domain/filters/Operators.enum.ts';
import { TextComparators } from '@/domain/filters/TextComparators.enum';
import { NumberComparators } from '@/domain/filters/NumberComparators.enum';

const resolveComparators = (dataType) => {
  if (dataType === 'text') return Object.values(TextComparators);
  if (dataType === 'number') return Object.values(NumberComparators);
  throw new Error(`Can't resolve filter comparators - unknown type ${dataType}`);
};

function getModel() {
  return {
    fieldName: this.fieldName,
    values: this.values,
    comparators: this.comparators,
    operator: this.operator
  };
}

/**
 * Creates a dynamic filter model
 * Use getModel() function to obtain minimal set of fields needed to apply filter
 */
export const metadataFilterFactory = {

  filterTypes: { single: 'single', range: 'range', multi: 'multi' },

  /**
   * Creates single-value dynamic filter
   */
  createSingleFilter: (fieldName, displayName, dataType, values = []) => {
    const availableComparators = resolveComparators(dataType);
    return {
      fieldName,
      dataType,
      displayName,
      type: 'single',
      values,
      availableComparators,
      comparators: [availableComparators[0]],
      operator: Operators.OR,
      getModel,
      changeValues(newValues) {
        this.values = newValues;
      },
      changeComparators(newComparators) {
        this.comparators = newComparators;
      }
    };
  },
  /**
   * Creates number-range dynamic filter
   */
  createRangeFilter: (fieldName, displayName, dataType, values = []) => {
    return {
      fieldName,
      dataType,
      displayName,
      type: 'range',
      values,
      operator: Operators.AND,
      comparators: ['>=', '<='],
      getModel,
      changeValues(newValues) {
        this.values = newValues;
      },
      changeComparators() {
        throw new Error('Cannot change comparators for range filter');
      }
    };
  },
  /**
   * Creates multi-value chips dynamic filter
   */
  createChipsFilter: (fieldName, displayName, dataType, availableValues, values = []) => {
    return {
      fieldName,
      dataType,
      displayName,
      type: 'chips',
      values,
      operator: Operators.OR,
      comparators: values.map(() => NumberComparators.Equals),
      availableValues,
      getModel,
      changeValues(newValues) {
        this.values = newValues;
        this.comparators = newValues.map(() => NumberComparators.Equals);
      },
      changeComparators() {
        throw new Error('Cannot change comparators for multi filter');
      }
    };
  },
};