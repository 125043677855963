<template>
  <div class="cx-point-tooltip" v-if="hoveredEntity">
    <div class="map-overlay__title">
      <cx-icon icon="cx_as_built" :size="16" class="map-overlay__title__icon cxu-color-highlight"/>
      <span v-if="pointCode">
        <span class="map-overlay__title__point-code-label">{{ translations.pointCode }}: </span>
        <span class="map-overlay__title__point-code">{{ pointCode }}</span>
      </span>
    </div>
    <div class="map-overlay__subtitles">
      <p v-if="unitName">
        <span class="map-overlay__subtitles__unit-label">{{ translations.unitName }}: </span>
        <span class="map-overlay__subtitles__unit">{{ unitName }}</span>
      </p>
      <p v-if="logTime">
        <span class="map-overlay__subtitles__log-time-label">{{ translations.logged }}: </span>
        <span class="map-overlay__subtitles__log-time">{{ logTime }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';
import { createValue } from '@/viewModel/valueFactory';

export default {
  name: 'CxPointTooltip',
  components: {
    CxIcon
  },
  props: {
    hoveredEntity: Object
  },
  computed: {
    title() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.name;
    },
    pointCode() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.pointCode;
    },
    unitName() {
      if (!this.hoveredEntity) return '';
      return this.hoveredEntity.unitName;
    },
    logTime() {
      if (!this.hoveredEntity) return '';
      return createValue(this.hoveredEntity, 'logTime').displayValue;
    },
    translations() {
      return {
        pointCode: this.$gettext('Point code'),
        unitName: this.$gettext('Unit name'),
        logged: this.$gettext('Logged'),
      };
    },
  },
};
</script>

<style lang="less">
  @import '../../common';

  .map-overlay__title {
    justify-content: flex-start;
    font-size: @appFontSize;
    color: @textColor;
    margin-bottom: 5px;

    .map-overlay__title__point-code {
      font-weight: @appBoldFontWeight;
    }
  }
  .map-overlay__title > .map-overlay__title__icon {
    flex: none !important;
    color: @textColor;
  }
  .map-overlay__subtitles {
    font-size: @appFontSize !important;
    padding-left: 0px !important;

    p {
      margin-bottom: 5px;
    }
  }
</style>