import _ from 'lodash';
import sethelper from '@/utils/sethelper';
import { DrillHoleSetType } from '@/domain/holeSet/DrillHoleSetType';


const mapDrillHoleStore = {
  state: {
    // all drill holes loaded on the map
    loadedDrillHoleSets: {},
    // ids of hidden drill holes
    hiddenDrillHolesSets: [],
    // drill holes not visible on object explorer
    tempDrillHoleSets: [],
    // hole set can be visualized in 2 ways - as hole billboard and as 3d hole representation
    // hole visibility mode is a map of hole set uuuid to { 2D: bool, 3D: bool } object
    holeVisibilityMode: {}
  },
  getters: {
    loadedDHSets(state) {
      const sets = Object.keys(state.loadedDrillHoleSets)
        .filter(k => state.loadedDrillHoleSets[k] !== null);
      return _.flatMap(sets, k => state.loadedDrillHoleSets[k]);
    },
    loadedDH(state) {
      const allLoadedDH = Object.keys(state.loadedDrillHoleSets)
        .filter(k => state.loadedDrillHoleSets[k] !== null);
      const allDHFlat = _.flatMap(allLoadedDH, k => state.loadedDrillHoleSets[k].drillHoles)
        .filter(p => p.uuid);
      return _.uniqBy(allDHFlat, p => p.uuid);
    },
    pesistentDHSets(state) {
      const persistentSets = Object.keys(state.loadedDrillHoleSets)
        .filter(k => state.loadedDrillHoleSets[k] !== null)
        .filter(k => !state.tempDrillHoleSets.includes(k));
      return _.flatMap(persistentSets, (k) => {
        return state.loadedDrillHoleSets[k];
      });
    },
    hiddenHoleSets(state) {
      return state.hiddenDrillHolesSets;
    },
    visibleDH(state) {
      const visibleDH = Object.keys(state.loadedDrillHoleSets)
        .filter(k => state.loadedDrillHoleSets[k] !== null)
        .filter(k => !state.hiddenDrillHolesSets.includes(k));
      const flatVisibileDH = _.flatMap(visibleDH, k => state.loadedDrillHoleSets[k].drillHoles);
      return _.uniqBy(flatVisibileDH, p => p.uuid);
    },
  },
  mutations: {
    addLoadedDHSets(state, data) {
      data.forEach((d) => {
        const newDisplayedSet = { ...state.loadedDrillHoleSets };
        newDisplayedSet[d.uuid] = d;
        state.loadedDrillHoleSets = newDisplayedSet;
      });
    },
    removeLoadedDHSets(state, setIds) {
      setIds.forEach((setId) => {
        const newDisplayedSet = { ...state.loadedDrillHoleSets };
        delete newDisplayedSet[setId];
        state.loadedDrillHoleSets = newDisplayedSet;
        state.holeVisibilityMode = _.omit(state.holeVisibilityMode, setIds);
      });
    },
    changeDHSetsVisibility(state, { setIds, isHidden2D, isHidden3D }) {
      if (isHidden2D && isHidden3D) {
        state.hiddenDrillHolesSets = state.hiddenDrillHolesSets.concat(setIds);
      } else {
        state.hiddenDrillHolesSets = state.hiddenDrillHolesSets.filter(s => !setIds.includes(s));
      }
      setIds.forEach((s) => { state.holeVisibilityMode = { ...state.holeVisibilityMode, [s]: { '2D': !isHidden2D, '3D': !isHidden3D } }; });
    },
    addTempDHSet(state, { uuids }) {
      state.tempDrillHoleSets = state.tempDrillHoleSets.concat(uuids);
    },
    removeTempDHSet(state, uuids) {
      state.tempDrillHoleSets = state.tempDrillHoleSets.filter(u => !uuids.includes(u));
    }
  },
  actions: {
    /**
     * Adds drill hole sets to loadedDHSet object
     * and displays newly added drill holes on the map
     * or hides them right away
     * @param {Array} sets list of sets to be added
     * @param {Boolean} showOnMap if false sets will be added to the hidden
     *                            set list and will not be displayes on the map
     */
    async loadDHSets(context, {
      sets,
      showOnMap,
      isPersistent = true,
    }) {
      const setUuids = sets.map(s => s.uuid);
      context.commit('addLoadedDHSets', sets);
      context.commit('changeDHSetsVisibility', { setIds: setUuids, isHidden2D: !showOnMap, isHidden3D: true });
      if (!isPersistent) {
        context.commit('addTempDHSet', { uuids: setUuids });
      }
      context.dispatch('viewer/loadHoles', { sets, show: { '2D': showOnMap } });
    },
    /**
     * Removes drill hole sets from loadedDHSet object
     * and from cesium view. If drill hole set was selected, it is deselected
     * If drill hole set id was in the hidden list, it is removed from there
     * @param {Array} setUuids list of set ids to be removed
     * @param {Promise} notifier defer to be resolved
     */
    async unloadDHSet(context, { setId, notifier }) {
      if (!setId) { return; }
      await context.dispatch('hideDHSet', { setId });
      context.commit('removeLoadedDHSets', [setId]);
      context.commit('removeTempDHSet', [setId]);
      context.commit('changeDHSetsVisibility', { setIds: [setId], isHidden2D: false, isHidden3D: false });
      context.dispatch('viewer/removeHoles', { setIds: [setId] });
      if (notifier) notifier.resolve();
    },
    /**
     * Shows a particular drill hole set on the map
     * @param context
     * @param {String} setId: Id of the drill hole set that we want to show on the map
     * @param {*} show: Information about the drill hole set show modes. Default is {'2D': true, '3D': false}.
     * @param {Boolean} show.2D: Show the 2D hole representation.
     * @param {Boolean} show.3D: Show the 3D hole representation.
     */
    async showDHSet(context, { setId, show }) {
      if (!show) show = context.state.holeVisibilityMode[setId] || { '2D': true, '3D': false };
      if (context.state.loadedDrillHoleSets[setId]) {
        const holesToShow = context.state.loadedDrillHoleSets[setId].drillHoles.map(dh => dh.uuid);
        await context.dispatch('viewer/showHideHoles', { holeIds: holesToShow, show });
        context.commit('changeDHSetsVisibility', { setIds: [setId], isHidden2D: !show['2D'], isHidden3D: !show['3D'] });
      }
    },
    /**
     * Hides a particular drill hole set on the map
     * @param {String} setId Id of the drill hole set that we want to hide
     */
    async hideDHSet(context, { setId }) {
      context.commit('changeDHSetsVisibility', { setIds: [setId], isHidden2D: true, isHidden3D: true });
      const drillHolesToHide =
        sethelper.subtractSets(
          // all hole from the set to hide
          context.state.loadedDrillHoleSets[setId].drillHoles,
          context.getters.visibleDH
        );
      await context.dispatch('viewer/showHideHoles', { holeIds: drillHolesToHide, show: false });
    },

    /**
    * Deselects drill holes, if the selected entity is included of the given set
    * @param {String} uuid  Id of the drill hole to deselect
    */
    async deselectDH(context, { setId }) {
      const { selectedEntity } = context.state;
      if (
        selectedEntity
        && selectedEntity.entityType === DrillHoleSetType
        && setId === selectedEntity.uuid) {
        context.dispatch('viewer/deselect');
      }
      const holesFromSets = context.state.loadedDrillHoleSets[setId].drillHoles;
      const allotherHoles = context.getters.visibleDH.filter(s => setId !== s.setUid);
      const holesToBeDeselected = sethelper.subtractSets(holesFromSets, allotherHoles);
      if (selectedEntity && holesToBeDeselected.includes(selectedEntity.uuid)) {
        context.dispatch('viewer/deselect');
      }
    },


  }
};

export default mapDrillHoleStore;
