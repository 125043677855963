<template>
  <div
    class="cx-map-compass"
    v-cx-tooltip="tooltip"
    @click="reorientMap"
    @mouseenter="showTooltip=true"
    @mouseleave="showTooltip=false">
    <div class="cx-map-compass__needle" :style="currentDirection">
      <div class="cx-map-compass__needle__north"></div>
      <div class="cx-map-compass__needle__south"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CxMapCompass',

  props: {
    compassDirection: Number,
    reorientMap: Function
  },

  data() {
    return {
      showTooltip: false
    };
  },

  computed: {
    /**
     * Sets rotation of map compass needle, according to actual map camera's direction.
     */
    currentDirection() {
      return { transform: `rotate(${this.compassDirection}deg)` };
    },

    tooltip() {
      return {
        text: this.$gettext('Reorient to North'),
        show: this.showTooltip,
        position: 'top-left',
        maxWidth: 400,
        offsetLeft: 85
      };
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../common';

.cx-map-compass {
  .flex(row, center, center);
  background-color: @overlayControlBackgroundColor;
  border-radius: 50%;
  pointer-events: auto;
  cursor: pointer;

  .cx-map-compass__needle {
    .size(@mapCompassDiameter, @mapCompassDiameter);
    .flex(column, center, center);
    border-radius: @mapCompassBorderRadius;

    .cx-map-compass__needle__north {
      width: 0;
      height: 0;
      border-left: @mapCompassNeedleBorderSide;
      border-right: @mapCompassNeedleBorderSide;
      border-bottom: @mapCompassNeedleBorderNorth;
    }

    .cx-map-compass__needle__south {
      width: 0;
      height: 0;
      border-left: @mapCompassNeedleBorderSide;
      border-right: @mapCompassNeedleBorderSide;
      border-top: @mapCompassNeedleBorderSouth;
    }
  }
}
</style>