import _last from 'lodash/last';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { RefModel } from '@/domain/refmodels/RefModel';
import {
  createCoordinateFile,
  createStringlineModel,
  createVolumeDescriptionModel,
  createCrossFallModel,
  createTerrainModel,
  createRoadLine,
  createDefaultRefmodel,
  createHelperFile,
  createDrillPattern
} from '@/domain/refmodels/RefmodelFactory';
import { DrillPatternJson, DrillPatternResponse, RefmodelMetadataResponse, RefmodelResponse } from './refmodelApi.types';
import datetimehelper from '@/utils/datetimehelper';
import { CoordinateFile, CrossFall, RoadLine, Stringline, TerrainModel, VolumeDescription, HelperFile, DrillPattern } from '@/domain/refmodels/File.types';

const mapDrillPatternJson: (uuid: string, apiDrillPattern: DrillPatternResponse) => DrillPatternJson =
  (uuid, apiDrillPattern) => {
    let result: DrillPatternJson = [];
    const drillPlan = apiDrillPattern.data ? apiDrillPattern.data.drill_plan :
      apiDrillPattern.drill_plan;
    if (drillPlan) {
      result = drillPlan.map(data => ({
        uuid,
        name: data.name,
        latitude: data.end_point.latitude,
        longitude: data.end_point.longitude,
        elevation: data.end_point.elevation,
      }));
    }
    return result;
  };

const mapSource: (source: {type?: string, name?: string } | null) => { sourceType: string | null, sourceName: string| null} =
(source) => {
  let sourceType: string | null = null;
  let sourceName: string | null = null;
  if (source && !_isEmpty(source)) {
    if (source.type) sourceType = source.type.toLowerCase();
    sourceName = source.name || null;
  }
  return { sourceType, sourceName };
};

const mapMetadata: (metadata: RefmodelMetadataResponse) => { gltfSize: number | null, pointCount:number | null, lineCount: number | null, surfaceCount: number | null } =
  (refmod_meta_data) => {
    let gltfSize: number | null = null;
    let pointCount: number | null = null;
    let lineCount: number | null = null;
    let surfaceCount: number | null = null;
    if (refmod_meta_data) {
      gltfSize = refmod_meta_data.gltf_size;
      pointCount = refmod_meta_data.points;
      lineCount = refmod_meta_data.lines;
      surfaceCount = refmod_meta_data.surfaces;
    }
    return { gltfSize, pointCount, lineCount, surfaceCount };
  };

const mapRefmodelBase: (refModelResponse: RefmodelResponse) => {
  uuid: string,
  name: string,
  path: string,
  createdAt: Date | null,
  updatedAt: Date | null,
  sourceType: string | null,
  sourceName: string | null,
  gltfSize: number | null,
  pointCount: number | null,
  lineCount: number | null,
  surfaceCount: number | null } =
(refModelResponse) => {
  const { sourceType, sourceName } = mapSource(refModelResponse.source);
  const { gltfSize, pointCount, lineCount, surfaceCount } = mapMetadata(refModelResponse.refmod_meta_data);
  return {
    uuid: refModelResponse.uuid,
    name: refModelResponse.name,
    path: refModelResponse.path,
    createdAt: datetimehelper.mapDate(refModelResponse.created_at),
    updatedAt: datetimehelper.mapDate(refModelResponse.updated_at),
    sourceType,
    sourceName,
    gltfSize,
    pointCount,
    lineCount,
    surfaceCount
  };
};

const mapDefaultRefmodel: (refModelResponse: RefmodelResponse) => RefModel =
  (refModelResponse) => createDefaultRefmodel({ ...mapRefmodelBase(refModelResponse) });

const mapDrillPattern: (refModelResponse: RefmodelResponse) => DrillPattern =
(refModelResponse) => createDrillPattern({ ...mapRefmodelBase(refModelResponse) });

// constructed from a DataSource kml/kmz file
// with url as a source of visualiation
const mapHelperFile: (refModelResponse: RefmodelResponse) => HelperFile =
(refModelResponse) => {
  const url = refModelResponse.resource_url ? new URL(refModelResponse.resource_url) : null;
  return createHelperFile({
    ...mapRefmodelBase(refModelResponse),
    resourceUrl: url ? `${url.pathname}${url.search}` : null
  });
};

const mapCoordinateFile: (refModelResponse: RefmodelResponse) => CoordinateFile =
(refModelResponse) => createCoordinateFile({ ...mapRefmodelBase(refModelResponse) });

const mapTerrainModel: (refModelResponse: RefmodelResponse) => TerrainModel =
(refModelResponse) => createTerrainModel({ ...mapRefmodelBase(refModelResponse) });

const mapRoadLine: (refModelResponse: RefmodelResponse) => RoadLine =
(refModelResponse) => createRoadLine({ ...mapRefmodelBase(refModelResponse) });

const mapStringlineModel: (refModelResponse: RefmodelResponse) => Stringline =
(refModelResponse) => createStringlineModel({ ...mapRefmodelBase(refModelResponse) });

const mapVolumeDescriptionModel: (refModelResponse: RefmodelResponse) => VolumeDescription =
(refModelResponse) => createVolumeDescriptionModel({ ...mapRefmodelBase(refModelResponse) });

const mapCrossFallModel: (refModelResponse: RefmodelResponse) => CrossFall =
(refModelResponse) => createCrossFallModel({ ...mapRefmodelBase(refModelResponse) });

const getExtensionFromName = (name: string) => {
  if (!name) return null;
  const splitName = name.split('.');
  const lastName = _last(splitName);
  const extension = lastName ? lastName.toLowerCase() : null;
  return extension;
};

const mapRefmodelToEntity: (refModelResponse: RefmodelResponse) => RefModel | CoordinateFile | TerrainModel | RoadLine | Stringline | VolumeDescription | CrossFall = (refmodel) => {
  const { refmod_meta_data, name } = refmodel;
  const extension = getExtensionFromName(name);
  // number of surfaces for a particular reference model is calculated on the upload
  // for compability purposes with models uploaded earlier we need to check if the surfaces is not null
  // if it is, we need to treat that model as a default one
  if (!name || !refmod_meta_data || !extension || _isNil(refmod_meta_data.surfaces)) return mapDefaultRefmodel(refmodel);

  const hasSurface = refmod_meta_data.surfaces > 0;
  if (['geo', 'kof', 'dxf', 'xml', 'dwg', 'ird'].includes(extension) && !hasSurface) return mapCoordinateFile(refmodel);
  if (['trm', 'dxf', 'dwg', 'xml'].includes(extension) && hasSurface) return mapTerrainModel(refmodel);
  if (['l3d', 'lin', 'prf', 'dvl', 'inh'].includes(extension) && !hasSurface) return mapRoadLine(refmodel);
  if (['lmd', 'xml'].includes(extension) && hasSurface) return mapStringlineModel(refmodel);
  if (extension === 'mbs' && hasSurface) return mapVolumeDescriptionModel(refmodel);
  if (['cfm', 'skv'].includes(extension) && hasSurface) return mapCrossFallModel(refmodel);
  return mapDefaultRefmodel(refmodel);
};

const mapDataSourceToEntity: (refModelResponse: RefmodelResponse) => RefModel | HelperFile =
(dataSource) => {
  const { name } = dataSource;
  const extension = getExtensionFromName(name);
  if (!name || !extension) return mapDefaultRefmodel(dataSource);
  if (['kml', 'kmz'].includes(extension)) return mapHelperFile(dataSource);
  return mapDefaultRefmodel(dataSource);
};

const refmodelMapper = {
  mapRefmodelToEntity,
  mapDrillPattern,
  mapDataSourceToEntity,
  mapDrillPatternJson,
};
export default refmodelMapper;

export const refmodelMapperTestHelper = {
  mapDefaultRefmodel,
  mapHelperFile,
  mapCoordinateFile,
  mapTerrainModel,
  mapRoadLine,
  mapStringlineModel,
  mapVolumeDescriptionModel,
  mapCrossFallModel,
  mapRefmodelToEntity,
};