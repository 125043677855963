<template>
  <div class="cx-details-panel-handle" :class="applyClass" @click="onClick">
    <div class="cx-details-panel-handle__overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'CxDetailsPanelHandle',
  props: {
    isOpened: Boolean,
    isReduced: Boolean,
    onClickHandle: Function
  },
  computed: {
    applyClass() {
      return {
        'cx-details-panel-handle--show': this.isOpened,
        'cx-details-panel-handle--collapse': !this.isOpened,
        'cx-details-panel-handle--reduced': this.isReduced,
      };
    }
  },
  methods: {
    onClick() {
      this.onClickHandle(!this.isOpened);
    }
  },
};
</script>

<style lang="less">
@import '../../common';

.cx-details-panel-handle {
  position: absolute;
  left: calc(0.5 * 100vw - 0.5 * @detailsHandleWidth);
  .size(@detailsHandleWidth, @detailsHandleOverlayHeight);
  z-index: @detailsZI;
  border-radius: 10px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    .cx-details-panel-handle {
      background-color: @detailsHandleHoverBGColor;
    }
  }

  &.cx-details-panel-handle--show {
    top: calc(100vh - @detailsHeight - @detailsHandleOverlayMargin);

    &.cx-details-panel-handle--reduced {
      top: calc(100vh - @detailsReducedHeight - @detailsHandleOverlayMargin);
    }
  }

  &.cx-details-panel-handle--collapse {
    top: calc(100vh - @detailsHandleOverlayMargin);
  }

  .cx-details-panel-handle__overlay {
    .size(@detailsHandleWidth, @detailsHandleHeight);
    background-color: @detailsHandleBGColor;
    z-index: @detailsHandleZI;
    position: absolute;
    border-radius: 10px;
    top: 5px;

    &.cx-details-panel-handle__overlay--reduced {
      height: @detailsHandleHeightSmall;
    }

    &:hover {
      background-color: @detailsHandleHoverBGColor;
    }
  }
}
</style>