import { createFilter } from '@/domain/filters/filterModelFactory.ts';

export const saveStateMapping = {

  mapStateVersion: (state) => {
    if (!state.appStateVersion) return state;
    if (state.appStateVersion !== 'v1') return state;
    const newOeEntities = state.oeEntities
      .map(e => {
        if (e.entityType === 'pointSet' || e.entityType === 'drillHoleSet') {
          const { originFilters } = e;
          const newFilters = originFilters.map(f => {
            let newFilter;
            if (!f.isMulti && !f.isRange) newFilter = f.value ? createFilter(f.fieldName, [f.value], [f.comparator], f.operator) : null;
            if (f.isRange) {
              const parsedFrom = parseFloat(f.from);
              const parsedTo = parseFloat(f.to);
              newFilter = (Number.isNaN(parsedFrom) || Number.isNaN(parsedTo)) ? null : createFilter(f.fieldName, [parsedFrom, parsedTo], ['>=', '<='], f.operator);
            }
            if (f.isMulti && !f.isRange) newFilter = f.values.length ? createFilter(f.fieldName, f.values, f.comparators, f.operator) : null;
            return newFilter;
          })
            .filter(f => !!f);

          return { ...e, originFilters: newFilters };
        }
        return e;
      });

    return { ...state, appStateVersion: process.env.VUE_APP_STATE_VERSION, oeEntities: newOeEntities };
  }
};