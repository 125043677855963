/**
 * @file Defines the base class of CZML data sources.
 * @author Jan Wąsak
 */
import _isNil from 'lodash/isNil';
import _isArrayLike from 'lodash/isArrayLike';
import _isObjectLike from 'lodash/isObjectLike';
import _isEmpty from 'lodash/isEmpty';
import { Packet, Document } from '@/utils/czml/packet';

/**
 * Represents a single CZML data source.
 */
class CZML {
  constructor(name, version, clock = null) {
    this.data = [new Document(name, version, clock)];
  }
  addPacket(packet) {
    if (packet instanceof Packet) {
      this.data.push(packet);
    } else throw new Error('Cannot add a non-packet.');
    return packet;
  }
  asCZML() {
    const result = JSON.stringify(
      this.data,
      (key, value) => {
        return (!_isNil(value) && !_isArrayLike(value) && !_isObjectLike(value)) || !_isEmpty(value) ? value : undefined;
      }
    );
    return result;
  }
}

export default CZML;