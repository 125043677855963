<template>
  <div class="cx-overlay__controls--group">
    <div class="legend"><slot name="legend"></slot></div>
    <div class="cx-overlay__controls--group__items">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CxOverlayControlsGroup',
  computed: {
    isLegendActive() {
      return true;
    }
  }
};
</script>

<style lang="less">
@import '../../../../common';

.cx-overlay__controls--group {
  pointer-events: auto;
  background-size: cover;
  margin: 0 5px 0 5px;

  &:not(:has(.legend:empty)) {
    background-color: @overlayControlBackgroundColor;
    border-radius: @inputBorderRadius;

    .cx-overlay__controls--group__items--item-container {
      background-color: transparent;
    }
  }

  &__items {
    .flex(row, space-around, center);
    height: @overlayControlHeight;
  }
}
</style>