<template>
  <div class="cx-unit-details-panel">
    <!-- TITLE -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            :source="unit.avatar"
            :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyToUnit"/>
        </transition>
      </template>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="unit.name"
            :subtitle="unit.subtitle.value"
            :subtitleIcon="unit.subtitle.icon"
            :subtitleIconTooltip="unit.subtitle.field"/>
        </transition>
      </template>
      <template #relatedEntities>
        <!--RELATED ENTITIES -->
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel v-if="isAnimationReady">
            <template #preContent>
              <cx-button
                v-if="hasAwarenessLicence"
                variantDark
                @click="() => openPopup('popup-awareness-message')">
                {{ translations.sendMessage }}
              </cx-button>
            </template>
          </cx-related-entities-panel>
        </transition>
      </template>
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <div class="default-panel">
      <transition :name="applyAnimations('analysis')">
        <cx-licence-panel v-if="isDetailsPanelReduced" v-show="isAnimationReady"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { UnitDetailsViewModel } from './unitViewModel';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxAvatar from '../common/CxAvatar';
import CxLicencePanel from '@/app/detailsPanel/common/CxLicencePanel';
import CxStatsPanel from '../common/CxStatsPanel';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxButton from '@/components/CxButton';
import CxTitlePanel from '../common/CxTitlePanel';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxOperationContextMixin from '@/mixins/CxOperationContext.mixin';
import { withContext } from '@/domain/entityMixins';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';

export default {
  name: 'CxUnitDetailsPanel',
  components: {
    CxAvatar,
    CxLicencePanel,
    CxStatsPanel,
    CxRelatedEntitiesPanel,
    CxButton,
    CxTitlePanel,
    CxMainDataPanel
  },
  mixins: [CxAnimationMixin, CxLicenceMixin, CxWaffleFlagMixin, CxOperationContextMixin],
  data() {
    return {
      isValid: false
    };
  },
  computed: {
    ...mapGetters('detailsUnit', ['availableAnalysisTabs', 'activeAnalysisTab', 'selectedUnit', 'isMessagePopupOpened']),
    ...mapGetters('shell', ['isDetailsPanelReduced']),
    unit() {
      if (!this.selectedUnit) return {};
      return new UnitDetailsViewModel(this.selectedUnit, this.$language.current);
    },
    mainData() {
      if (!this.selectedUnit) return [];
      const { activeModel, note } = this.unit;
      const result = [
        {
          field: activeModel.field,
          icon: activeModel.icon,
          value: activeModel.value,
          noDataText: this.$gettext('No active reference model')
        },
        {
          field: note.field,
          icon: note.icon,
          value: note.value,
          noDataText: this.$gettext('No additional notes')
        },
      ];
      return result;
    },
    context() {
      return this.selectedUnit ? withContext(this.selectedUnit).context : null;
    },
    translations() {
      return {
        sendMessage: this.$gettext('Send Message')
      };
    }
  },
  methods: {
    ...mapMutations('detailsUnit', ['setActiveAnalysisTab']),
    ...mapActions('detailsUnit', ['handleCameraFlyToUnit']),
    ...mapActions('popup', ['openPopup'])
  },
};

</script>

<style lang="less">
@import '../../../common';

.cx-unit-details-panel {
  height: 100%;
  display: flex;

  .cx-stats-panel {
    min-width: 500px;
  }

  .cx-title-panel {
    .customIcon {
      width: @detailsUnitTitleIconWidth;
    }
  }

  .cx-main-data-panel .item {
    .flex(row, flex-start, center);

    span {
      height: 17px;
    }
  }

  .customIcon {
    width: @detailsUnitIconWidth;
  }

  .default-panel {
    background-color: @detailsContentBackgroundColor;
    width: 100%;
    height: 100%;
  }
}

</style>