import licenceService from '@/services/licence.service';
import { mapGetters } from 'vuex';

const CxLicenceMixin = {
  computed: {
    ...mapGetters('app', ['projectInitialized']),
    hasEarthmovingLicence() {
      if (!this.projectInitialized) return false;
      return licenceService.hasEarthmovingLicence();
    },
    hasStabilizationLicence() {
      if (!this.projectInitialized) return false;
      return licenceService.hasStabilizationLicence();
    },
    hasAwarenessLicence() {
      if (!this.projectInitialized) return false;
      return licenceService.hasAwarenessLicence();
    },
    isGridPoc() {
      if (!this.projectInitialized) return false;
      return licenceService.isGridPoc();
    },
    hasCookieConsent() {
      return licenceService.hasCookieConsent();
    },
    isChineseDistribution() {
      return licenceService.isChineseDistribution();
    },
  },
};

export default CxLicenceMixin;
