import * as Cesium from 'cesium';
import { getSurfacelogBbox } from './http/wms.api';
import { SurfacelogSignalSldStyle } from '@/domain/surfacelog/SurfacelogSignalSldStyle.enum';
import { SurfacelogSignalTypes } from '@/domain/surfacelog/SurfacelogSignalTypes.enum';
import { cloneDeep } from 'lodash';

const surfacelogStore = {
  state: {
    surfaceLayers: {},
    wmsUrls: {},
    surfaceBBox: {},
    removeSurfaceLayerHandler: {},
    signalsKPI: {},
    lengthUnit: null
  },
  methods: {
    getWMSSLD(layer_name, signalKPI, styleSLD, isOutsideRefModelVisible, deltaHeightLegend) {

      const input = {
        passcount_target: 3,
        passcount_extra: 2,
      };

      const output = {
        rangemin: Math.round((signalKPI.min_value + Number.EPSILON) * 100) / 100,
        rangemax: Math.round((signalKPI.max_value + Number.EPSILON) * 100) / 100
      };

      // TODO Just refactor this one
      let sldBody = `${'<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<StyledLayerDescriptor version="1.0.0" ' +
        'xmlns="http://www.opengis.net/sld" ' +
        'xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd" ' +
        'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
        'xmlns:xlink="http://www.w3.org/1999/xlink" ' +
        'xmlns:ogc="http://www.opengis.net/ogc">\n' +
        '  <NamedLayer>\n' +
        '    <Name>'}${layer_name}</Name>\n` +
        '    <UserStyle>\n' +
        `      <Name>${layer_name}_style</Name>\n` +
        '      <FeatureTypeStyle>\n' +
        '        <Rule>\n' +
        '        <MaxScaleDenominator>1000000000000</MaxScaleDenominator>\n' +
        '          <RasterSymbolizer>\n';
      sldBody += (layer_name === 'H') ? '<ColorMap type="ramp">\n' : '<ColorMap type="intervals">\n';

      // Passcount
      const valGreenPc = input.passcount_target;
      const lblGreenPc = input.passcount_target;
      const valBluePc = (input.passcount_target + input.passcount_extra + 1);
      const lblBluePc = `${input.passcount_target + input.passcount_extra + 1} -`;



      const tf = (num) => (Number(`${Math.round(`${num}e2`)}e-2`).toFixed(2));
      // Height range
      const valueDif = tf((output.rangemax - output.rangemin) / 4);
      const isSurfaceFlat = (parseFloat(valueDif) === 0.00);

      // Height

      const valMinBase = tf(output.rangemin + (valueDif * 0));
      const valMin = (isSurfaceFlat) ? valMinBase - 1 : valMinBase;
      const valYellowH = tf(output.rangemin + (valueDif * 1));
      const valLightGreenH = tf(output.rangemin + (valueDif * 2));
      const valLightBlueH = tf(output.rangemin + (valueDif * 3));
      const valMax = tf(output.rangemin + (valueDif * 4));

      const coloursHeight = [
        { color: '#FF3333', value: valMin },
        { color: '#FFFF33', value: valYellowH },
        { color: '#33FF33', value: valLightGreenH },
        { color: '#33FFFF', value: valLightBlueH },
        { color: '#3333FF', value: valMax }
      ];
      // const no_color_interval = 0;
      const colorOutsideRefModel = '#464646';
      const colorNoLicence = '#f56262';
      const colorDefault = '#f56262';
      const colorFlat = '#33FF33';
      // Define the color interval starting with min value to max value.
      switch (styleSLD) {
        case 'PC': // Passcount from WMS
          // GREY
          if (input.passcount_target >= 3) {
            let valGreyPc = '1';
            let lblGreyPc = '1';
            if (input.passcount_target > 3) {
              valGreyPc = (input.passcount_target - 2);
              lblGreyPc = `1 - ${input.passcount_target - 2}`;
            }
            sldBody += `              <ColorMapEntry color="#B0B0B0" label="${lblGreyPc}" quantity="${valGreyPc}"/>\n`;
          }
          // YELLOW
          //
          if (input.passcount_target > 1) {
            const valYellowPc = (input.passcount_target - 1);
            const lblYellowPc = (input.passcount_target - 1);
            sldBody += `              <ColorMapEntry color="#FFFF33" label="${lblYellowPc}" quantity="${valYellowPc}.0"/>\n`;
          }
          // GREEN
          sldBody += `              <ColorMapEntry color="#33FF33" label="${lblGreenPc}" quantity="${valGreenPc}.0"/>\n`;
          // ORANGE
          if (input.passcount_extra >= 1) {
            const valOrangePc = (input.passcount_target + input.passcount_extra);
            let lblOrangePc = (input.passcount_target + input.passcount_extra);
            if (input.passcount_extra > 1) {
              lblOrangePc = `${input.passcount_target + 1} - ${input.passcount_target + input.passcount_extra}`;
            }
            sldBody += `              <ColorMapEntry color="#FFA500" label="${lblOrangePc}" quantity="${valOrangePc}.0"/>\n`;
          }
          // BLUE
          sldBody += `              <ColorMapEntry color="#0000ff" label="${lblBluePc}" quantity="${valBluePc}.0"/>\n`;
          break;
        case 'H':
          if (isSurfaceFlat) {
            // Flat surfaces
            sldBody += `              <ColorMapEntry color="${colorFlat}" quantity="${valMin}"/>\n`;
            sldBody += `              <ColorMapEntry color="${colorFlat}" quantity="${valMax}"/>\n`;
          } else {
            coloursHeight
              .forEach((element) => {
                sldBody += `              <ColorMapEntry color="${element.color}" quantity="${element.value}"/>\n`;
              });
          }
          break;
        case 'dH':
          deltaHeightLegend
            .forEach((element) => {
              const value = tf(element.value);
              sldBody += `              <ColorMapEntry color="${element.color}" quantity="${value}"/>\n`;
            });
          break;
        case 'Default':
          sldBody += `              <ColorMapEntry color="${colorDefault}"  quantity="${valMin}"/>\n`;
          sldBody += `              <ColorMapEntry color="${colorDefault}"  quantity="${valMax}"/>\n`;
          break;
        case 'noLicence':
          sldBody += `              <ColorMapEntry color="${colorNoLicence}"  quantity="${valMin}"/>\n`;
          sldBody += `              <ColorMapEntry color="${colorNoLicence}"  quantity="${valMax}"/>\n`;
          break;
        default:
      }

      sldBody += '            </ColorMap>\n' +
        '          </RasterSymbolizer>\n' +
        '        </Rule>\n' +
        '      </FeatureTypeStyle>\n' +
        '    </UserStyle>\n' +
        '  </NamedLayer>\n';
      if (isOutsideRefModelVisible) {
        sldBody += '  <NamedLayer>\n' +
          '  <Name>OUTSIDE_REFERENCE_MODEL</Name>\n' +
          '  <UserStyle> \n' +
          '    <Name>OUTSIDE_REFERENCE_MODEL_style</Name>\n' +
          '    <FeatureTypeStyle>\n' +
          '      <Rule>\n' +
          '      <MaxScaleDenominator>1000000000000</MaxScaleDenominator>\n' +
          '        <RasterSymbolizer>\n' +
          '          <ColorMap type="intervals">\n' +
          `              <ColorMapEntry color="${colorOutsideRefModel}"  quantity="0.0"/>\n` +
          `              <ColorMapEntry color="${colorOutsideRefModel}"  quantity="1.0"/>\n` +
          '          </ColorMap>\n' +
          '        </RasterSymbolizer>\n' +
          '      </Rule>\n' +
          '    </FeatureTypeStyle>\n' +
          '  </UserStyle>\n' +
          '</NamedLayer>\n';
      }

      sldBody += '</StyledLayerDescriptor>';
      return sldBody;
    },
    async setWmsLayer(url, bbox, signal, signalKPI, styleSLD, isOutsideRefModelVisible, deltaHeightLegend) {
      const selectedLayers = isOutsideRefModelVisible ? `${signal},${SurfacelogSignalTypes.OutsideReferenceModel}` : `${signal}`;
      const selectedStyles = isOutsideRefModelVisible ? `${signal}_style, ${SurfacelogSignalSldStyle.OutsideReferenceModel}_style` : `${signal}_style`;
      const deltaHeightLegendForMap = deltaHeightLegend ? deltaHeightLegend.sort((a, b) => a.value - b.value) : deltaHeightLegend;
      const wmsLayer = await new Cesium.ImageryLayer(new Cesium.WebMapServiceImageryProvider({
        url,
        layers: selectedLayers,
        rectangle: bbox,
        parameters: {
          version: '1.3.0',
          transparent: true,
          format: 'image/png',
          styles: selectedStyles,
          SLD_BODY: this.getWMSSLD(signal, signalKPI, styleSLD, isOutsideRefModelVisible, deltaHeightLegendForMap),
        }
      }));
      return wmsLayer;
    }
  },
  actions: {
    async addSurfacelogLayer(context, { layerUuid, url, showOnMap, signal, signalsKPI, styleSLD, isOutsideRefModelVisible, deltaHeightLegend }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const bbox = await getSurfacelogBbox(url);
      state.wmsUrls[layerUuid] = url;
      state.surfaceBBox[layerUuid] = bbox;
      state.signalsKPI[layerUuid] = signalsKPI;
      state.lengthUnit = context.rootGetters['app/measurementSystem'].baseLengthUnit.abbr();
      state.surfaceLayers[layerUuid] = await self.setWmsLayer(url, bbox, signal, state.signalsKPI[layerUuid][signal], styleSLD, isOutsideRefModelVisible, deltaHeightLegend);
      state.surfaceLayers[layerUuid].show = showOnMap;
      viewer.imageryLayers.add(state.surfaceLayers[layerUuid]);
    },
    async toggleSurfacelogLayer(context, { layerUuid, showOnMap }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      state.surfaceLayers[layerUuid].show = showOnMap;
      scene.requestRender();
    },
    async removeSurfacelogLayer(context, { layerUuid }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      viewer.imageryLayers.remove(state.surfaceLayers[layerUuid], true);
      delete state.surfaceLayers[layerUuid];
      scene.requestRender();
    },
    async changeSurfacelogSignal(context, { layerUuid, signal, styleSLD, isOutsideRefModelVisible, signalsKPI, wmsUrl, deltaHeightLegend }) {
      const { self } = context;
      const { rootState, state } = self;
      const { viewer } = rootState;
      const { scene } = viewer;
      state.wmsUrls[layerUuid] = wmsUrl;
      state.signalsKPI[layerUuid] = signalsKPI;
      const isVisible = state.surfaceLayers[layerUuid].show;
      viewer.imageryLayers.remove(state.surfaceLayers[layerUuid], true);
      delete state.surfaceLayers[layerUuid];
      state.surfaceLayers[layerUuid] = await self.setWmsLayer(wmsUrl, state.surfaceBBox[layerUuid], signal, signalsKPI[signal], styleSLD, isOutsideRefModelVisible, cloneDeep(deltaHeightLegend));
      state.surfaceLayers[layerUuid].show = isVisible;
      viewer.imageryLayers.add(state.surfaceLayers[layerUuid]);
      scene.requestRender();
    },
  },
};
export default surfacelogStore;
