/** Alarm type as displayed on the UI.
 * In case of Alarm type, some UI display values differ from filter values.
 * Do not use this enum for API communication.
 */
export enum AlarmTypeFilterDisplayValue {
  TagAlarm = 'Tag Alarm',
  VehicleAlarm = 'Vehicle Alarm',
  PanicAlarm = 'Panic Alarm',
  Obstacle = 'Obstacle',
  Overspeed = 'Overspeed',
  TagOk = 'Panic Alarm Cancelled',
  LowBattery = 'Low Battery Alarm',
  CollisionAlarm = 'Collision Alarm', // PA10-specific
  AvoidanceAlarm = 'Avoidance Alarm',
  AvoidanceZone = 'Avoidance Zone',
  PersonAlarm = 'Person Alarm',
  UnclassifiedAlarm = 'Unclassified Alarm'
}