<template>
  <div class="cx-licence-item">
    <cx-icon icon="navigation"/>
    <span>{{ text }}</span>
  </div>
</template>

<script>
import CxIcon from '@/components/CxIcon';

export default {
  name: 'CxLicenceBulletItem',
  components: {
    CxIcon
  },
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="less">
@import '../../../common';

.cx-licence-item {
  .cx-icon {
    color: @textColorHighlight;
    display: inline-block;
    i {
      font-size: @appFontSize;
      transform: rotate(89deg);
      vertical-align: text-top;
    }
  }
}

</style>
