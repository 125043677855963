<template>
  <div class="cx-point-set-details-panel">
    <!-- TITLE -->
    <cx-stats-panel>
      <template #title>
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="title"
            :titleClickHandler="onTitleClicked"
            :isOpened="isDetailsPanelOpened"/>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-point-set-analysis-cut-fill v-if="isAnimationReady"/>
        </transition>
      </template>
      <template #mainMetadata>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <div class="cx-cmn-dp__analysisPanel">
    </div>
    <transition :name="applyAnimations('analysis')">
      <cx-licence-panel v-if="isDetailsPanelReduced"/>
    </transition>
    <cx-export-asbuild-component
      :exportPoints="(selectedExtension) => exportPoints(selectedExtension.value)"
      :loading="isExporting"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CxLicencePanel from '@/app/detailsPanel/common/CxLicencePanel';
import CxExportAsbuildComponent from '@/app/detailsPanel/common/CxExportAsbuildComponent';
import CxPointSetAnalysisCutFill from './CxPointSetAnalysisCutFill';
import CxStatsPanel from '../common/CxStatsPanel';
import CxTitlePanel from '../common/CxTitlePanel';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin.js';
import CxLicenceMixin from '@/mixins/CxLicence.mixin.js';

export default {
  name: 'CxPointSetDetailsPanel',
  components: {
    CxLicencePanel,
    CxPointSetAnalysisCutFill,
    CxExportAsbuildComponent,
    CxStatsPanel,
    CxTitlePanel,
  },
  mixins: [CxLicenceMixin, CxAnimationMixin],
  computed: {
    ...mapGetters('detailsPointSet', ['selectedPointSet', 'isExporting']),
    ...mapGetters('shell', ['isDetailsPanelReduced', 'isDetailsPanelOpened']),
    title() {
      if (!this.selectedPointSet) return '';
      const count = this.selectedPointSet.points.length;
      const translated = this.$ngettext('%{ n } Measured Point', '%{ n } Measured Points', count);
      const interpolated = this.$gettextInterpolate(translated, { n: count });
      return interpolated;
    },
  },
  methods: {
    ...mapActions('detailsPointSet', ['exportPoints']),
    ...mapActions('map', ['setCameraToEntity']),
    onTitleClicked() {
      this.setCameraToEntity(this.selectedPointSet.uuid);
    }
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-point-set-details-panel {
 height: 100%;
  display: flex;
  background-color: @baseBackgroundColor;

  .cx-title-panel {
    span {
      padding-left: 0px;
    }
  }

  .cx-stats-panel {
    width: @detailsSetStatsWidth;
  }

  .cx-stats-panel__upper{
    height: @detailsSetStatsUpperHeight;
  }

  .cx-stats-panel__upper--title {
    margin-left: 55px;
    margin-top: 40px;
  }
}

</style>