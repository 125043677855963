import { createGettext } from 'vue3-gettext';
import translations from './translations.json';
import { AvailableLanguages } from './AvailableLanguages.enum';

export const gettext = createGettext({
  availableLanguages: AvailableLanguages,
  defaultLanguage: 'en',
  translations,
  silent: true
});

export const { $gettext } = gettext;