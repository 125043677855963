
import licenceService from '@/services/licence.service';

const minSet = ['point_code', 'PointID', 'equipment_type', 'unit_type', 'unit_account_name'];
const minFilterSetWithLicence = ['point_code', 'PointID', 'equipment_type', 'unit_type', 'unit_account_name', 'LogName'];
const minSetEarthmoving = [...minSet, 'HeightDistance', 'LogName'];
const minSetStabilization = [...minSet, 'DrillPileStatus', 'LogName'];
const minSetAll = [...minSet, ...minSetEarthmoving, ...minSetStabilization];

/**
 * Returns list of fields to query for dependant on available licence
 */
const getFields = () => {
  if (licenceService.hasEarthmovingLicence() && licenceService.hasStabilizationLicence()) return minSetAll;
  if (licenceService.hasEarthmovingLicence()) return minSetEarthmoving;
  if (licenceService.hasStabilizationLicence()) return minSetStabilization;
  return minSet;
};

const getFieldsForFilters = () => {
  if (licenceService.hasEarthmovingLicence() || licenceService.hasStabilizationLicence()) return minFilterSetWithLicence;
  return minSet;
};

/**
 * Returns only stabilization-friendly list of field to query, dependant on licence
 */
const getStabilizationFields = () => {
  if (licenceService.hasStabilizationLicence()) return minSetStabilization;
  return minSet;
};

export { getFields, getStabilizationFields, getFieldsForFilters };