<template>
  <div
    class="cx-warning"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
    v-cx-tooltip="{
      text: tooltip,
      show: showTooltip && tooltip !== undefined,
      offsetTop: -3
    }">
    <span class="cx-warning__text" v-if="warning">{{ warning }}</span>
    <cx-icon class="cxu-color-warning" icon="warning" :size="15"/>
  </div>
</template>


<script>
import CxIcon from '@/components/CxIcon';

/**
  Renders warning text and icon in a single row
 */
export default {
  name: 'CxWarning',
  components: {
    CxIcon
  },
  data() {
    return {
      showTooltip: false
    };
  },
  props: {
    // text to display as a warning
    warning: { type: String },
    // text to display as a tooltip
    tooltip: { type: String }
  },
};
</script>

<style lang="less">
@import '../common';

.cx-warning {
  display: flex;
  color: @warningColor;
  .cxu-center-v();
  span {
    color: @waringColorMuted;
    margin-right: 8px;
  }
}

</style>