/** Alarm type as reported from PA system.
 * API-wise, any string is accepted to support other, future, alarm types.
 */
export enum AlarmTypeFilterValue {
  TagAlarm = 'TagAlarm',
  VehicleAlarm = 'VehicleAlarm',
  PanicAlarm = 'PanicAlarm',
  Obstacle = 'ObstacleAlarm',
  Overspeed = 'OverspeedAlarm',
  TagOk = 'TagOk',
  LowBattery = 'LowBattery',
  CollisionAlarm = 'CollisionAlarm', // PA10-specific
  AvoidanceAlarm = 'AvoidanceAlarm',
  AvoidanceZone = 'AvoidanceZone',
  PersonAlarm = 'PersonAlarm',
  UnclassifiedAlarm = 'UnclassifiedAlarm'
}